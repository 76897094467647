import React from 'react';
import moment from 'moment-timezone';
import { Grid, Row, Col } from 'react-flexbox-grid';

import RankingPosition from './rankingPosition';
import Paginate from '../paginate';

import RankingButtons from './rankingButtons';

import { history } from '../../../routers/appRouter';

import PreferencesrData from '../../../data/preferences';

export class IntervalSelect extends React.Component {
  constructor(props) {
    super(props);

    const intervals = [{
      name: 'Todos',
      value: 'geral',
    }];

    const firstYear = PreferencesrData.yearStart;
    const lastYear = moment().year();

    for (let i = lastYear; i >= firstYear; i -= 1) {
      intervals.push({
        name: i.toString(),
        value: i,
      });
    }

    this.state = {
      page: {
        current: Number(props.match.params.page || 1),
      },
      interval: {
        current: props.match.params.interval,
        list: intervals,
      },
    };
  }

  changeState = (page, interval) => {
    const { match } = this.props;
    const { path, params } = match;

    let url = path.replace(':interval', interval).replace(':page', page);

    Object.keys(params).forEach((paramKey) => {
      url = url.replace(`:${paramKey}`, params[paramKey]);
    });

    history.push(url);
  }

  handlePageClick = (data) => {
    const { interval } = this.state;
    const { selected: pageIndex } = data;
    this.changeState(pageIndex + 1, interval.current);
  }

  handleChangeInterval = (e) => {
    const { value } = e.target;
    this.setState({ interval: value });
    this.changeState(1, value);
  }

  handleUserPosition = () => {
    const { userPosition, perPage } = this.props;
    const page = Math.floor((userPosition.position - 1) / perPage);
    this.handlePageClick({ selected: page });
  }

  render() {
    const { page, interval } = this.state;
    const {
      match, totalPages, userPosition, removeSelect, buttonTypes, onlyInterval,
    } = this.props;

    const intercalElement = (
      <div className={`filter-select interval${onlyInterval ? ' center' : ''}`}>
        <select value={interval.current} onChange={this.handleChangeInterval}>
          {interval.list.map(i => (
            <option key={i.value} value={i.value}>
              {i.name}
            </option>
          ))}
        </select>
      </div>
    );

    if (onlyInterval) return intercalElement;

    return (
      <React.Fragment>
        {userPosition && (
        <RankingPosition
          {...userPosition}
          handlePage={this.handleUserPosition}
        />
        )}
        <Grid fluid style={{ padding: 0 }}>
          <Row>
            <Col md={5} className="ranking-buttons">
              {buttonTypes && (
                <RankingButtons types={buttonTypes} match={match} />
              )}
              {!removeSelect && intercalElement}
            </Col>
            <Col md={7}>
              <Paginate
                pageCount={totalPages}
                forcePage={page.current - 1}
                onPageChange={this.handlePageClick}
              />
            </Col>
          </Row>
        </Grid>
      </React.Fragment>

    );
  }
}

export default IntervalSelect;
