import React from 'react';
import { connect } from 'react-redux';

import TeamsBadgesForm from '../../elements/teamsBadgesForm';

import { collectiblesGetBasicItems } from '../../../../app/store/actions/collectibles';
import { userTeamUpdateBadge } from '../../../../app/store/actions/userTeams';

import collectiblesData from '../../../../data/collectibles';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

export class TeamsInitiationCreationBadge extends React.Component {
  state = {
    error: '',
    badgeId: '',
  };

  async componentDidMount() {
    return this.props.getBasicItems();
  }

  onSubmit = async (e) => {
    const { badgeId } = this.state;
    const { mode } = this.props;

    e.preventDefault();

    if (!badgeId) {
      this.setState(() => ({ error: 'Selecione um escudo.' }));
    } else {
      try {
        await this.props.updateTeamBadge(mode, badgeId);
        this.props.nextStep();
      } catch (err) {
        this.setState(() => ({ error: `Erro na API: ${err}` }));
      }
    }
  }

  selectBadge(badgeId) {
    this.setState(() => ({ badgeId }));
  }

  render() {
    const { badgeId, error } = this.state;
    const { items } = this.props;

    const chests = {};
    chests[collectiblesData.basic.name] = items;

    return (
      <TutorialWrapper>
        <form onSubmit={this.onSubmit}>

          <TeamsBadgesForm
            badgeId={badgeId}
            badges={chests}
            selectBadge={badgeIdSelected => this.selectBadge(badgeIdSelected)}
          />

          {error && (
          <p className="form__error">
            {error}
          </p>
          )}

          <div>
            <button className="button normal" type="submit">
              {'Mudar Escudo'}
            </button>
          </div>
        </form>
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBasicItems: () => dispatch(collectiblesGetBasicItems()),
  updateTeamBadge: (gameMode, collectibleId) => dispatch(userTeamUpdateBadge(gameMode, collectibleId)),
});

const mapStateToProps = state => ({
  items: state.collectibles.basic || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsInitiationCreationBadge);
