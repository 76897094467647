import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import PlayersUtils from '../../../app/utils/players';

import PlayersData from '../../../data/players';

const TeamPlayerIcons = (props) => {
  const { player, forceSize } = props;

  let size = 0.7;
  if (forceSize) size = forceSize;

  const items = [];

  const hasBet = PlayersUtils.betValue(player) !== 'none';
  items.push((
    <div
      className="cateogry"
      key="bet"
      style={{
        width: `${1.5 * size}rem`,
        color: PlayersData.bet[hasBet ? 'made' : 'none'],
      }}
    >
      <FontAwesomeIcon icon={faCheckCircle} title={hasBet ? 'Apostou' : 'Não Apostou'} />
    </div>
  ));

  return (
    <div className="icons">
      {items.map(item => item)}
    </div>
  );
};

export default TeamPlayerIcons;
