import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import moment from 'moment-timezone';

import { reportsGetGeneral, reportsGetPeriod } from '../../../app/store/actions/reports';

import AdministrationReportHeader from './administrationReportHeader';

import AdministrationReportTransactionsGeneral from './transactions/administrationReportTransactionsGeneral';
import AdministrationReportTransactionsUnspent from './transactions/administrationReportTransactionsUnspent';
import AdministrationReportTransactionsMonths from './transactions/administrationReportTransactionsMonths';
import AdministrationReportTransactionsInflow from './transactions/administrationReportTransactionsInflow';
import AdministrationReportTransactionsOutflow from './transactions/administrationReportTransactionsOutflow';
import AdministrationReportTransactionsExpenses from './transactions/administrationReportTransactionsExpenses';

export class AdministrationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().endOf('month'),
    };
    this.updateDate = this.updateDate.bind(this);
  }

  async componentDidMount() {
    Promise.all([
      this.getGeneral(),
      this.getPeriod(),
    ]);
  }

  async getGeneral() {
    const { general } = this.props;
    if (!general) await this.props.getGeneral();
  }

  async getPeriod() {
    const { periods } = this.props;
    const { date } = this.state;
    const year = date.format('YYYY');
    const month = date.format('M');
    if (!periods[this.getPeriodTag()]) await this.props.getPeriod(year, month);
  }

  getPeriodTag() {
    const { date } = this.state;
    const year = date.format('YYYY');
    const month = date.format('M');
    return `${year}-${month}`;
  }

  async updateDate(date) {
    await this.setState({ date });
    await this.getPeriod();
  }

  render() {
    const {
      general, periods, inflowTypes, outflowTypes, expensesTypes,
    } = this.props;
    const { date } = this.state;

    const period = periods[this.getPeriodTag()];

    return (
      <div className="content-container">

        {!!general && general.transactions && (
          <Grid fluid>
            <Row>
              <Col md={4}>
                <AdministrationReportTransactionsGeneral months={general.transactions.months} />
                <AdministrationReportTransactionsUnspent unspent={general.transactions.unspent} />
              </Col>
              <Col md={8}>
                <AdministrationReportTransactionsMonths months={general.transactions.months} />
              </Col>
            </Row>
          </Grid>
        )}

        <AdministrationReportHeader date={date} updateDate={this.updateDate} />

        {!!period && period.transactions && period.transactions.inflow && (
          <AdministrationReportTransactionsInflow inflow={period.transactions.inflow} types={inflowTypes} />
        )}

        {!!period && period.transactions && period.transactions.outflow && (
          <AdministrationReportTransactionsOutflow outflow={period.transactions.outflow} types={outflowTypes} />
        )}

        {!!period && period.transactions && period.transactions.expenses && (
          <AdministrationReportTransactionsExpenses expenses={period.transactions.expenses} types={expensesTypes} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getGeneral: () => dispatch(reportsGetGeneral()),
  getPeriod: (year, month) => dispatch(reportsGetPeriod(year, month)),
});

const mapStateToProps = state => ({
  general: state.reports.general,
  periods: state.reports.periods,
  inflowTypes: (state.settings.transactions && state.settings.transactions.types) || [],
  outflowTypes: (state.settings.purchase && state.settings.purchase.types) || [],
  expensesTypes: (state.settings.transactions && state.settings.transactions.expenses) || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationReport);
