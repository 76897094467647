import SingleData from '../../data/gameModes/single';
import ClubData from '../../data/gameModes/club';
import NationalData from '../../data/gameModes/national';
import ManagerData from '../../data/gameModes/manager';
import CampaignData from '../../data/gameModes/campaign';

const gameModesData = {
  single: SingleData,
  club: ClubData,
  national: NationalData,
  manager: ManagerData,
  campaign: CampaignData,
};

function hasAccess(gameMode, userModeTeams, team, options = {}) {
  if (!userModeTeams) return false;
  if (!team.active) return false;

  const { id: teamId, region_tag: region } = team;
  const { roles, federationPermission, isMaster } = options;

  switch (gameMode) {
    case 'single':
    case 'manager': {
      return userModeTeams.id === teamId;
    }
    case 'club':
    case 'national': {
      if (federationPermission && (isMaster || (userModeTeams.federations || [])
        .some(federation => federation.region === region))) return true;
      if (!userModeTeams.roles || !userModeTeams.roles[teamId]) return false;
      if (!roles) return true;
      return roles.some(role => userModeTeams.roles[teamId][role]);
    }
    default: {
      return false;
    }
  }
}

function getNotifications(gameModeDetails = {}, teamId, isClosedRound, marketIsOpen) {
  const notifications = {
    proposals: 0,
    campaign: 0,
    friendly: 0,
    resignRequest: 0,
    formation: 0,
  };

  notifications.proposals = (gameModeDetails.market && gameModeDetails.market.user
    && gameModeDetails.market.user.proposals.length) || 0;

  notifications.campaign = (gameModeDetails.invitations && gameModeDetails.invitations.length) || 0;

  if (gameModeDetails.friendly_proposals) notifications.friendly = gameModeDetails.friendly_proposals;
  else if (gameModeDetails.roles && gameModeDetails.roles[teamId] && gameModeDetails.roles[teamId].friendly_proposals) {
    notifications.friendly = gameModeDetails.roles[teamId].friendly_proposals;
  }

  if (!marketIsOpen && gameModeDetails.roles && gameModeDetails.roles[teamId]
    && gameModeDetails.roles[teamId].resign_requests) {
    notifications.resignRequest = gameModeDetails.roles[teamId].resign_requests;
  }

  notifications.formation = !isClosedRound && gameModeDetails.formation ? 1 : 0;

  notifications.total = notifications.proposals + notifications.campaign
  + notifications.friendly + notifications.resignRequest + notifications.formation;

  notifications.has = notifications.total > 0;

  return notifications;
}

function getGameModeColor(mode) {
  if (!gameModesData[mode]) return;
  return gameModesData[mode].color;
}

export default {
  hasAccess,
  getNotifications,
  getGameModeColor,
  data: gameModesData,
};
