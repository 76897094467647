import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../../elements/top5/top5Section';

const TeamsStatisticsTop5Ranking = (props) => {
  const {
    score, total, wins, draws, losses, average, goals_for: goalsFor,
    goals_against: goalsAgainst, goals_difference: goalsDifference, goals_average: goalsAverage,
  } = props.data;

  return (
    <Top5Section title="Ranking">
      <Grid fluid>
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(score, 'Pontuação', 'Pontos')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(total, 'Jogos', 'Jogos')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(wins, 'Vitórias', 'Vitórias')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(draws, 'Empates', 'Empates')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(losses, 'Derrotas', 'Derrotas')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(average, 'Aproveitamento', '%', 'percentage')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(goalsFor, 'Gols Prós', 'Gols')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(goalsAgainst, 'Gols Contra', 'Gols')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(goalsDifference, 'Saldo', 'Gols')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(goalsAverage, 'Média de Gols', 'Pontos', 'average')}
          </Col>
        </Row>
      </Grid>
    </Top5Section>
  );
};

export default TeamsStatisticsTop5Ranking;
