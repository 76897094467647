import React from 'react';

import CardDefault from '../../../elements/card/default';
import PlayerUtils from '../../../../app/utils/players';

const PlayerDefault = (props) => {
  const { player, reputation, isPrime } = props;

  return (
    <CardDefault
      name={player.username}
      nameClass={PlayerUtils.usernameClass(player.username)}
      badge={{
        url: player.picture.url,
        title: player.username,
      }}
      tag={reputation}
      tagStyle={isPrime ? 'prime' : ''}
      level={player.achievements.level}
      levelStyle={PlayerUtils.betValue(player)}
      round
    />
  );
};

export default PlayerDefault;
