import React from 'react';
import { connect } from 'react-redux';

import translator from '../../../data/translator';

export const GuideCampaignDifficulties = ({ difficulties = {}, maxUsers, coop }) => {
  const types = Object.keys(difficulties).map((difficulty) => {
    const victoryResult = [];
    const drawResult = [];

    for (let i = 0; i < maxUsers; i += 1) {
      victoryResult.push(Math.ceil(coop[i] * (i + 1) * difficulties[difficulty].game.victory));
      drawResult.push(Math.ceil(coop[i] * (i + 1) * difficulties[difficulty].game.draw));
    }

    return {
      key: difficulty,
      result: {
        victory: victoryResult,
        draw: drawResult,
      },
      ...difficulties[difficulty],
    };
  });

  return (
    <React.Fragment>
      {types.map(type => (
        <div className="section" key={type.id}>
          <h4>{` ${translator.s.campaign[type.key]}`}</h4>
          <div className="section-content">
            <ul className="list">
              <li>
                <b>
                  {'Empate: '}
                </b>
                <ul className="list">
                  {type.result.draw.map((value, index) => (
                    <li>
                      {`${index + 1} Jogador${index !== 0 ? 'es' : ''}: `}
                      <b>{`${value} Pontos`}</b>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <b>
                  {'Vitória: '}
                </b>
                <ul className="list">
                  {type.result.victory.map((value, index) => (
                    <li>
                      {`${index + 1} Jogador${index !== 0 ? 'es' : ''}: `}
                      <b>{`${value} Pontos`}</b>
                    </li>
                  ))}
                </ul>
              </li>
              {type.xp && (
              <li>
                <b>
                  {'Pontos de Experiência: '}
                </b>
                {` ${type.xp}`}
              </li>
              )}
            </ul>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  difficulties: state.settings.campaigns.difficulties,
  maxUsers: state.settings.campaigns.max_users,
  coop: state.settings.campaigns.coop,
});

export default connect(mapStateToProps)(GuideCampaignDifficulties);
