import React from 'react';

const Table = (props) => {
  const {
    className, header = [], lines = [], lineClassNames = {}, noHeader,
  } = props;

  const getTdClass = (lineIndex, col) => {
    let classTdName;
    if (col.tdClassName) classTdName = col.tdClassName;

    if (lineClassNames[col.key] && lineClassNames[col.key][lineIndex] && lineClassNames[col.key][lineIndex].className) {
      classTdName += ` ${lineClassNames[col.key][lineIndex].className}`;
    }

    return classTdName;
  };

  return (
    <div className="table-responsive">
      <table className={className}>
        {!noHeader && (
        <thead>

          <tr>
            {header.map(h => !h.hidden && (
              <th key={h.key} className={h.className} colSpan={h.colspan}>
                {h.value}
              </th>
            ))}
          </tr>

        </thead>
        )}

        <tbody>
          {lines.map((line, index) => (
            <tr key={line.key} className={line.className}>
              {header.map(h => (
                <td key={h.key} className={getTdClass(index, h)}>
                  {line[h.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>

      </table>
      {lines.length === 0 && (
        <div className={`empty${className ? ` ${className}` : ''}`}>
          {'Nada encontrado'}
        </div>
      )}
    </div>
  );
};

export default Table;
