import React from 'react';

import Table from '../../../../elements/table';
import Player from '../../../../players/elements/player';

export const CampaignInProgressSettingsInvitationsTable = (props) => {
  const { users, isMaxUsers } = props;

  const header = [
    { key: 'user', value: 'Jogadores', className: 'name' },
    { key: 'options', className: 'number', tdClassName: 'tb-button' },
  ];

  const data = users.map(user => ({
    key: user.id,
    user: <Player data={user} type="table" />,
    options: (!isMaxUsers || user.sent) && (user.sent ? (
      <button className="button red" type="button" onClick={() => props.cancelInvitation(user.id)}>
        {'Cancelar'}
      </button>
    ) : (
      <button className="button" type="button" onClick={() => props.sendInvitation(user)}>
        {'Enviar'}
      </button>
    )),
  }));

  return (<Table header={header} lines={data} />);
};

export default CampaignInProgressSettingsInvitationsTable;
