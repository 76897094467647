import Promise from 'bluebird';
import moment from 'moment-timezone';

import CompetitionsRequests from '../../server/competitions';
import { addLoading, removeLoading } from './loading';

export const setStartedList = competitions => ({
  type: 'COMPETITIONS_LIST_STARTED_SET',
  competitions,
});

export const setClosedList = (year, competitions) => ({
  type: 'COMPETITIONS_LIST_CLOSED_SET',
  year,
  competitions,
});

export const cleanLists = () => ({
  type: 'COMPETITIONS_LISTS_CLEAN',
});

export const setDetails = competition => ({
  type: 'COMPETITIONS_DETAILS_SET',
  competition,
  updatedAt: moment(),
});

export const setGroup = group => ({
  type: 'COMPETITIONS_GROUP_SET',
  group,
});

export const setStatistics = (type, data, page, pages) => ({
  type: 'COMPETITIONS_STATISTICS_SET',
  statisticType: type,
  data,
  page,
  pages,
});

export const cleanStatistics = () => ({
  type: 'COMPETITIONS_STATISTICS_CLEAN',
});

export const setTypes = types => ({
  type: 'COMPETITIONS_TYPES_SET',
  types,
});

export const setPremiums = premiums => ({
  type: 'COMPETITIONS_PREMIUMS_SET',
  premiums,
});

export const cleanPremiums = () => ({
  type: 'COMPETITIONS_PREMIUMS_CLEAN',
});

export const competitionsGetLists = (gameMode, type, year) => async (dispatch) => {
  dispatch(addLoading());

  try {
    dispatch(cleanLists());
    const [started, closed] = await Promise.all([
      CompetitionsRequests.getStarted(gameMode, type),
      CompetitionsRequests.getClosed(gameMode, type, year),
    ]);
    dispatch(setStartedList(started));
    dispatch(setClosedList(year, closed));
  } finally {
    dispatch(removeLoading());
  }
};

export const competitionsGetClosedList = (gameMode, type, year) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const closed = await CompetitionsRequests.getClosed(gameMode, type, year);
    dispatch(setClosedList(year, closed));
  } finally {
    dispatch(removeLoading());
  }
};

export const competitionsGetCompetition = competitionId => async (dispatch) => {
  const competition = await CompetitionsRequests.getById(competitionId);
  dispatch(setDetails(competition.general));
  dispatch(setGroup(competition.group));
};

export const competitionsStartCompetition = competitionId => async (dispatch) => {
  dispatch(addLoading());
  dispatch(setDetails());

  try {
    await dispatch(competitionsGetCompetition(competitionId));
  } finally {
    dispatch(removeLoading());
  }
};

export const competitionsGetGroup = groupId => async (dispatch, getState) => {
  const competitionId = getState().competitions.details.general.id;
  if (competitionId) {
    const group = await CompetitionsRequests.getGroup(competitionId, groupId);
    dispatch(setGroup(group));
  }
};

export const competitionsStartGroup = groupId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await dispatch(competitionsGetGroup(groupId));
  } finally {
    dispatch(removeLoading());
  }
};

export const competitionsGetGroupByCompetition = (competitionId, groupId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const group = await CompetitionsRequests.getGroup(competitionId, groupId);
    return group;
  } finally {
    dispatch(removeLoading());
  }
};

export const competitionsGetRanking = page => async (dispatch, getState) => {
  dispatch(addLoading());

  try {
    const competitionId = getState().competitions.details.general.id;
    if (competitionId) {
      const ranking = await CompetitionsRequests.getRanking(competitionId, page);
      const pages = Math.ceil(ranking.total / ranking.limit);
      dispatch(setStatistics('ranking', ranking.data, page, pages));
    }
  } finally {
    dispatch(removeLoading());
  }
};


export const competitionsGetStrikers = page => async (dispatch, getState) => {
  dispatch(addLoading());

  try {
    const competitionId = getState().competitions.details.general.id;
    if (competitionId) {
      const strikers = await CompetitionsRequests.getStrikers(competitionId, page);
      const pages = Math.ceil(strikers.total / strikers.limit);
      dispatch(setStatistics('strikers', strikers.data, page, pages));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const competitionsRankedGames = (groupId, roundNumber) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const games = await CompetitionsRequests.getRankedGames(groupId, roundNumber);
    return games;
  } finally {
    dispatch(removeLoading());
  }
};

export const competitionsGetTypes = () => async (dispatch, getState) => {
  const competitionTypes = getState().competitions.types;

  if (!competitionTypes) {
    dispatch(addLoading());
    try {
      const types = await CompetitionsRequests.getCompetitionsTypes();
      dispatch(setTypes(types));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const competitionsGetPremiums = () => async (dispatch, getState) => {
  const competitionsPremiums = getState().competitions.premiums;

  if (!competitionsPremiums) {
    dispatch(addLoading());
    try {
      const competitions = await CompetitionsRequests.getCompetitionsPremiums();
      dispatch(setPremiums(competitions));
    } finally {
      dispatch(removeLoading());
    }
  }
};
