import React from 'react';
import { connect } from 'react-redux';

import TeamsDetailsManagementEscalationClub from './club';
import TeamsDetailsManagementEscalationManager from './manager';

const TeamsDetailsManagementEscalation = (props) => {
  switch (props.gameMode) {
    case 'club':
    case 'national':
      return (<TeamsDetailsManagementEscalationClub />);
    case 'manager':
      return (<TeamsDetailsManagementEscalationManager />);
    default:
      return (null);
  }
};

const mapStateToProps = state => ({
  gameMode: state.teams.details.gameMode,
});

export default connect(mapStateToProps)(TeamsDetailsManagementEscalation);
