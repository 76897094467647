import React from 'react';

export const GuideGeneralRegistration = () => (
  <React.Fragment>
    <div className="section">
      <h4>
        {'Usuário'}
      </h4>
      <div className="section-content">
        <p>
          {'Para realizar o cadastro é necessário  informar seu CPF e Telefone. É exigido isso para '
          + 'evitar usuários falsos que atrapalham o que o site oferece. Serão exigidos também os '
          + 'seguintes dados do usuário: nome, e-mail, gênero, data de nascimento e cidade.'}
        </p>
        <p>
          {'Caso o Usuário não tenha 18 (dezoito) anos completos, ele poderá participar do site com '
          + 'permissão de um responsável. O responsável deverá enviar um contato justificando a situação.'}
        </p>
        <p>
          {'Os Usuários que infringirem as regras poderão ser imediatamente suspensos, bem como poderão '
          + 'ter seus logins bloqueados, sem qualquer aviso prévio e a exclusivo critério do site.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Liberação após cadastro'}
      </h4>
      <div className="section-content">
        <p>
          {'Caberá à administração do Barbolão a liberação do cadastro do usuário. Algumas funcionalidades '
          + 'como alteração dos dados cadastrais e apostas serão liberadas até que sejam verificadas as '
          + 'informações do usuário.'}
        </p>
        <p>
          {'Não será possível neste período de verificação se vincular a algum Clube ou Seleção.'}
        </p>
      </div>
    </div>

  </React.Fragment>
);
export default GuideGeneralRegistration;
