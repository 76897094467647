import React from 'react';
import {
  ResponsiveContainer, PieChart, Pie, Sector, Cell,
} from 'recharts';

import chartData from '../../../data/charts';

const renderActiveShape = (title, props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + ((outerRadius + 10) * cos);
  const sy = cy + ((outerRadius + 10) * sin);
  const mx = cx + ((outerRadius + 30) * cos);
  const my = cy + ((outerRadius + 30) * sin);
  const ex = mx + ((cos >= 0 ? 1 : -1) * 22);
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text className="PieChartText" x={cx} y={cy} dy={8} textAnchor="middle" fill="#3DCC91">
        {title}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + ((cos >= 0 ? 1 : -1) * 12)} y={ey} textAnchor={textAnchor} fill="#333">
        {`${payload.name}`}
      </text>
      <text x={ex + ((cos >= 0 ? 1 : -1) * 12)} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${payload.value} (${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default class SimplePieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };

    this.onPieEnter = this.onPieEnter.bind(this);
  }

  onPieEnter(data, index) {
    this.setState(() => ({ activeIndex: index }));
  }

  render() {
    const { data, title, colors } = this.props;
    const chartColors = colors || chartData.pie.colors;

    return (
      <ResponsiveContainer>
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={chartProps => renderActiveShape(title, chartProps)}
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            labelLine={false}
            innerRadius="40%"
            outerRadius="65%"
            fill="#8884d8"
            onMouseEnter={this.onPieEnter}
          >
            {data.map((entry, index) => <Cell key={entry.name} fill={chartColors[index % chartColors.length]} />) }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
