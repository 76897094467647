import RestService from '../services/rest';

async function getGeneral() {
  return RestService.getAuthenticated('admin/reports');
}

async function getPeriod(year, month) {
  return RestService.getAuthenticated(`admin/reports/period?year=${year}&month=${month}`);
}

export default {
  getGeneral,
  getPeriod,
};
