import React from 'react';

import Steps from '../../../elements/steps/steps';

import TutorialManagerIntroduction from './introduction';
import TutorialManagerGame from './game';
import TutorialManagerActive from './active';

import TutorialGameModeActivation from '../activation';

const TutorialManager = () => (
  <Steps>
    <TutorialManagerIntroduction />
    <TutorialManagerGame />
    <TutorialGameModeActivation
      mode="manager"
      pageName="criacao"
      content={<TutorialManagerActive />}
    />
  </Steps>
);

export default TutorialManager;
