import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import MultipleCarousel from '../../../../elements/multipleCarousel';

import PlayersDetailsOverviewStatisticsDiscard from './discard';
import PlayersDetailsOverviewStatisticsValuation from './valuation';
import PlayersDetailsOverviewStatisticsAchievements from './achievements';

import PlayersDetailsOverviewStatisticsReputation from './reputation/reputation';
import PlayersDetailsOverviewStatisticsBets from './bets/bets';

const PlayersDetailsOverviewStatistics = (props) => {
  const {
    username,
    discard, valuation, achievements,
    level, bets, playerBet, generalScoreAverage, generalDifficulty,
  } = props;

  return (
    <section className="statistics">
      <div className="content-container top">
        <MultipleCarousel
          items={[
            <PlayersDetailsOverviewStatisticsDiscard discard={discard} />,
            <PlayersDetailsOverviewStatisticsValuation valuation={valuation} />,
            <PlayersDetailsOverviewStatisticsAchievements achievements={achievements} />,
          ]}
          xs={1}
          sm={1}
          md={3}
          lg={3}
          indicator
        />
      </div>
      <Grid className="content-container bottom">
        <Row between="lg">
          <Col sm={12} lg={7}>
            <PlayersDetailsOverviewStatisticsReputation reputation={level} playerBet={playerBet} />
          </Col>
          <Col sm={12} lg={4}>
            <PlayersDetailsOverviewStatisticsBets
              bets={bets}
              generalScoreAverage={generalScoreAverage}
              generalDifficulty={generalDifficulty}
              username={username}
            />
          </Col>
        </Row>
      </Grid>
    </section>
  );
};

export default PlayersDetailsOverviewStatistics;
