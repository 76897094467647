import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import translator from '../../../../data/translator';

import SimpleBarChart from '../../../elements/charts/simpleBarChart';

const AdministrationReportTransactionsOutflow = (props) => {
  const { outflow, types: outflowTypes = [] } = props;
  const { value, purchases = [] } = outflow;

  const typesComplete = outflowTypes.map((typeKey) => {
    const typeFound = purchases.find(t => t.type === typeKey);
    if (typeFound) return typeFound;
    return { type: typeKey, times: 0, value: 0 };
  });
  typesComplete.sort((a, b) => a.type > b.type);

  const dataValue = [{ name: 'Valores' }];
  const dataTimes = [{ name: 'Vezes' }];
  const bars = {};

  typesComplete.forEach((purchase) => {
    bars[purchase.type] = {
      name: translator.s.purchases[purchase.type],
    };
    dataValue[0][purchase.type] = purchase.value;
    dataTimes[0][purchase.type] = purchase.times;
  });

  return (
    <Grid fluid>
      <Row>
        <Col md={4}>
          <h3>
            {'Compras'}
          </h3>
          <h4>
            {'Valor: R$'}
            {value.toFixed(2)}
          </h4>
        </Col>

        <Col md={4}>
          <div style={{ height: '28rem' }}>
            <SimpleBarChart data={dataValue} bars={bars} />
          </div>
        </Col>

        <Col md={4}>
          <div style={{ height: '28rem' }}>
            <SimpleBarChart data={dataTimes} bars={bars} />
          </div>
        </Col>

      </Row>
    </Grid>
  );
};

export default AdministrationReportTransactionsOutflow;
