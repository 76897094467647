import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderTopPrivate from './headerTopPrivate';

export const HeaderTop = ({ isAuthenticated }) => (
  <div className="header__top">
    <div className="content-container">
      <div className="content">
        <div className="header-logo">
          <Link to="/">
            <img src="/images/barbolao_logo.png" alt="Barbolão" />
          </Link>
        </div>
        {!!isAuthenticated && <HeaderTopPrivate />}
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  isAuthenticated: state.user.id,
});

export default connect(mapStateToProps, null, null, { pure: false })(HeaderTop);
