import React from 'react';
import { connect } from 'react-redux';

import { recordsGetBets } from '../../../app/store/actions/records';
import pageTitleService from '../../../app/services/pageTitle';

import { scrollTo } from '../../../routers/appRouter';

import StatisticsUtil from '../../../app/utils/statistics';
import RecordsData from '../../../data/records';

import Player from '../../players/elements/player';

import TableAuth from '../../elements/tableAuth';
import IntervalSelect from '../../elements/statistics/intervalSelect';

export class RecordsBets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: Number(props.match.params.page || 1),
      interval: props.match.params.interval,
    };
  }

  async componentDidMount() {
    const { page, interval } = this.state;
    pageTitleService.setTitle('Apostas');
    await this.props.getBets(interval, page);
    scrollTo('menu-secondary');
  }

  render() {
    const { data, totalPages, match } = this.props;
    const { authUser, userBetsValue, userBetsPosition } = this.props;
    const page = Number(match.params.page || 1);

    const betsHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      { key: 'user', value: 'Apostas', colspan: 2 },
      { key: 'score', value: 'Pontos', tdClassName: 'high' },
      { key: 'rounds', value: 'Rodadas' },
      { key: 'average', value: 'Média' },
      { key: 'difficulty', value: 'Dificuldade' },
      { key: 'simple', value: 'A. Simples' },
      { key: 'accurate', value: 'Cravadas' },
      { key: 'bonus', value: 'Bônus' },
      { key: 'wo', value: 'WO' },
    ];

    const betsData = data.map((row, index) => ({
      key: row.user.id,
      position: index + ((page - 1) * RecordsData.bets.players_perPage) + 1,
      user: <Player data={row.user} type="table" />,
      score: row.value,
      rounds: row.total,
      average: row.bets.score.average.toFixed(2),
      difficulty: row.bets.difficulty.total.toFixed(2),
      simple: row.bets.score.types.simple,
      accurate: row.bets.score.types.accurate,
      bonus: row.bets.score.types.bonus,
      wo: row.bets.wo,
    }));

    return (
      <div className="bets-page">
        <div className="content-container margin-bottom">
          <div className="title-center-menu">
            {'Apostas'}
          </div>
          <IntervalSelect
            totalPages={totalPages}
            perPage={RecordsData.bets.players_perPage}
            match={match}
            userPosition={{
              name: authUser.username,
              picture: authUser.picture.url,
              value: userBetsValue,
              position: userBetsPosition,
              type: 'player',
            }}
          />
          <TableAuth header={betsHeader} lines={betsData} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBets: (interval, page) => dispatch(recordsGetBets(interval, page)),
});

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.match.params.page || 1;

  const interval = StatisticsUtil.getInterval(ownProps.match.params.interval);
  const ranking = (state.user.rankings || []).find(r => r.interval === interval && r.type === 'bets');

  return {
    data: state.records.bets.data[page] || [],
    totalPages: state.records.bets.total,
    authUser: state.user,
    userBetsValue: (ranking && ranking.value) || 0,
    userBetsPosition: ranking && ranking.position,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordsBets);
