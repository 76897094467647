import React from 'react';

import CardSimple from '../../elements/card/simple';

import MessagesNewForm from '../new/messagesNewForm';

export class MessagesRoomForm extends React.Component {
  sendMessage = async (messageText) => {
    await this.props.onSubmit(messageText);
  };

  render() {
    const { isActive, minMessage, maxMessage } = this.props;

    if (!isActive) return (null);

    return (
      <CardSimple>
        <MessagesNewForm
          minMessage={minMessage}
          maxMessage={maxMessage}
          onSubmit={this.sendMessage}
          canSend
          clean
        />
      </CardSimple>
    );
  }
}

export default MessagesRoomForm;
