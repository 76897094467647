import React from 'react';

import TeamsData from '../../../../data/teams';

import TeamsStatisticsConfrontationTable from './table';

import TeamsUtils from '../../../../app/utils/teams';

export class TeamsStatisticsConfrontationSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  onSearchChange = (e) => {
    const search = e.target.value.toLowerCase();
    this.setState(() => ({ search }));
  };

  render() {
    const { search } = this.state;
    const { teams } = this.props;

    let selectTeams;

    if (teams) {
      selectTeams = TeamsUtils.searchTeams(teams, search).slice(0, TeamsData.confrontation.total);
    }

    return (
      <div>
        <div>
          <input
            type="text"
            placeholder="Busque pelo nome"
            className="text-input filter-input"
            value={search}
            onChange={this.onSearchChange}
          />
        </div>

        {selectTeams && (
          <TeamsStatisticsConfrontationTable teams={selectTeams} select={this.props.select} />
        )}
      </div>
    );
  }
}

export default TeamsStatisticsConfrontationSearch;
