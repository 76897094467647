import UsersAnalyzeRequests from '../../server/usersAnalyze';
import { addLoading, removeLoading } from './loading';

export const usersAnalyzeList = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    const data = await UsersAnalyzeRequests.getList();
    return data;
  } finally {
    dispatch(removeLoading());
  }
};

export const usersAnalyzeByIp = ip => async (dispatch) => {
  dispatch(addLoading());

  try {
    const data = await UsersAnalyzeRequests.getIpDetails(ip);
    return data;
  } finally {
    dispatch(removeLoading());
  }
};
