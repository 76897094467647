import React from 'react';

import TeamsUtils from '../../../app/utils/teams';
import GameModeUtils from '../../../app/utils/gameMode';

import translator from '../../../data/translator';

import BadgeListItem from '../../elements/badgeList/badgeList';

const TeamsListTeams = (props) => {
  const {
    teams = [],
    gridInfo = {
      lg: 3, md: 3, sm: 4, xs: 6,
    },
    around,
    center,
    tagType = 'default',
  } = props;

  const getDefaultNameHover = function getTag(team) {
    if (team.user) return team.user.username;
    if (team.president) return team.president.username;
    return team.region;
  };

  const getTag = function getTag(team) {
    switch (tagType) {
      case 'gameMode':
        return {
          name: translator.s.gameModes[team.mode_type],
          color: GameModeUtils.getGameModeColor(team.mode_type),
          nameHover: TeamsUtils.getLeagueLabel(team.leagues),
          colorHover: TeamsUtils.getBackgroundColor(team),
        };
      default:
        return {
          name: TeamsUtils.getLeagueLabel(team.leagues),
          color: TeamsUtils.getLeagueColor(team.leagues),
          nameHover: getDefaultNameHover(team),
          colorHover: TeamsUtils.getBackgroundColor(team),
        };
    }
  };


  const items = teams.map(team => ({
    id: team.id,
    color: TeamsUtils.getBackgroundColor(team),
    name: team.name,
    tag: getTag(team),
    badge: team.badge.url,
    locked: false,
    mark: team.players_total,
    link: TeamsUtils.getLink(team),
  }));

  return (
    <BadgeListItem items={items} gridInfo={gridInfo} around={around} center={center} />
  );
};

export default TeamsListTeams;
