import React from 'react';
import { connect } from 'react-redux';

import TutorialWrapper from '../../elements/tutorial/tutorial';

const TutorialAccountWelcome = (props) => {
  const { firstName } = props;

  return (
    <TutorialWrapper image="account/image_1.jpg">
      <h3 className="title-center">
        {'Bem vindo!'}
      </h3>
      <p>
        {`${firstName}, sua conta está quase pronta. Agora é com a gente, iremos analisar seus dados `
          + 'para certificar de que tudo está correto. Quando tudo isso acontecer, você receberá '
          + 'um e-mail de confirmação.'}
      </p>
      <p>
        {'Mas enquanto isso, vamos completar o seu cadastro e realizar suas primeiras apostas.'}
      </p>
      <p className="text">
        {'Se após completar seu cadastro, surgir dúvidas de como funciona o site, entre em contato para que '
          + 'possamos te ajudar. Você pode também utilizar o Mural para perguntar aos outros usuários.'}
      </p>
      <button className="button normal" type="button" onClick={props.nextStep}>
        {'Continuar'}
      </button>
    </TutorialWrapper>
  );
};

const mapStateToProps = state => ({
  firstName: state.user.first_name,
});

export default connect(mapStateToProps)(TutorialAccountWelcome);
