import React from 'react';

const PlayersDetailsBetsRoundTeamNames = ({ game }) => (
  <div className="teams-names">
    {game.team_home.name}
    <span>
      {' Vs '}
    </span>
    {game.team_away.name}
  </div>
);

export default PlayersDetailsBetsRoundTeamNames;
