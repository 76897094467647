import React from 'react';
import { connect } from 'react-redux';

import { campaignsGetTypes } from '../../../app/store/actions/campaigns';

import translator from '../../../data/translator';

export class GuideCampaignCompetitions extends React.Component {
  async componentDidMount() {
    const { types } = this.props;
    if (!types) await this.props.getCampaignsTypes();
  }

  render() {
    const { types = [] } = this.props;

    const requirements = {};

    types.forEach((type) => {
      requirements[type.id] = type.requirement.map((req) => {
        const reqs = [];

        req.forEach((reqType) => {
          const typeFound = types.find(t => t.id === reqType);
          if (typeFound) reqs.push(typeFound.name);
        });

        return reqs;
      });
    });

    return (
      <React.Fragment>
        {types.map(type => (
          <div className="section" key={type.id}>
            <h4>{type.name}</h4>
            <div className="section-content">
              <ul className="list">
                {type.difficulty && (
                <li>
                  <b>
                    {'Dificuldade: '}
                  </b>
                  {` ${translator.s.campaign[type.difficulty]}`}
                </li>
                )}
                {type.phases && (
                <li>
                  <b>
                    {'Fases: '}
                  </b>
                  {type.phases.map((phase, index) => `${(index > 0 ? ' e ' : '')}${translator.s.phases[phase.type]}`)}
                </li>
                )}
                {((type.requirement && type.requirement.length) || type.subscription_required) && (
                <li>
                  <b>
                    {'Requisitos: '}
                  </b>
                  <ul className="list">
                    <li>Prime</li>
                    {requirements[type.id].map(reqTypes => (
                      <li key={reqTypes[0]}>{reqTypes.join(', ')}</li>
                    ))}
                  </ul>
                </li>
                )}
                {type.xp && (
                <li>
                  <b>
                    {'Pontos de Experiência: '}
                  </b>
                  {` ${type.xp}`}
                </li>
                )}
              </ul>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCampaignsTypes: () => dispatch(campaignsGetTypes()),
});

const mapStateToProps = state => ({
  types: state.campaigns.types,
});

export default connect(mapStateToProps, mapDispatchToProps)(GuideCampaignCompetitions);
