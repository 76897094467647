import React from 'react';

export const TutorialWrapper = ({ children, image, className = '' }) => (
  <div className={`content-container ${className}`}>
    <div className="tutorial-wrapper">
      {image && (
      <div
        className="image"
        style={{
          backgroundImage: `url('/images/tutorial/${image}')`,
        }}
      />
      )}
      <div className={`content${!image ? ' full' : ''}`}>
        {children}
      </div>
    </div>
  </div>
);

export default TutorialWrapper;
