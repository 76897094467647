import React from 'react';
import { connect } from 'react-redux';

import ValidatorHelper from '../../../app/helpers/validator';

import TutorialWrapper from '../../elements/tutorial/tutorial';

import contactSend from '../../../app/store/actions/contact';

export class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      error: {
        email: '',
        subject: '',
        message: '',
      },
    };
  }

  onNameChange = (e) => {
    const name = e.target.value;
    this.setState(() => ({ name }));
  };

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email, error: this.cleanError('email') }));
  };

  onSubjectChange = (e) => {
    const subject = e.target.value;
    this.setState(() => ({ subject, error: this.cleanError('subject') }));
  };

  onMessageChange = (e) => {
    const message = e.target.value;
    this.setState(() => ({ message, error: this.cleanError('message') }));
  };

  cleanError = (field) => {
    const { error } = this.state;
    error[field] = '';
    return error;
  };

  hasError = () => {
    const { error } = this.state;
    return Object.keys(error).filter(field => error[field] !== '').length > 0
    || Object.keys(this.state).filter(field => this.state[field] === '').length > 0;
  };

  onSubmit = async (e) => {
    const {
      error, name, email, subject, message,
    } = this.state;
    const { subjectRules = {}, messageRules = {} } = this.props;

    e.preventDefault();

    if (subject.length < subjectRules.min) {
      error.subject = `O assunto deve conter pelo menos ${subjectRules.min} caracteres. `;
    }

    if (message.length < messageRules.min) {
      error.message = `A mensagem deve conter pelo menos ${messageRules.min} caracteres. `;
    }

    if (!ValidatorHelper.isEmail(email)) {
      error.email = 'Digite um e-mail válido.';
    }

    await this.setState(() => ({ error }));

    if (!this.hasError()) {
      await this.props.sendContact(name, email, subject, message);
      this.props.nextStep();
    }
  };

  render() {
    const {
      error, name, email, subject, message,
    } = this.state;
    const { subjectRules = {}, messageRules = {} } = this.props;

    return (
      <div className="contact">
        <h3 className="title-center">
          {'Fale Conosco'}
        </h3>
        <TutorialWrapper image="contact.jpg">
          <form onSubmit={this.onSubmit}>
            <div className="section">
              <h4>
                {'Suas Informações'}
              </h4>
              <div className="section-content">
                <input
                  type="text"
                  placeholder="Digite seu nome completo"
                  value={name}
                  onChange={this.onNameChange}
                />
                <input
                  type="email"
                  placeholder="Digite seu email"
                  value={email}
                  onChange={this.onEmailChange}
                />
                <div className="error">
                  {error.email}
                </div>
              </div>
            </div>
            <div className="section">
              <h4>
                {'Assunto'}
              </h4>
              <div className="section-content">
                <input
                  type="text"
                  placeholder="Digite o assunto"
                  value={subject}
                  className="all"
                  onChange={this.onSubjectChange}
                  maxLength={subjectRules.max}
                />
                <div className="error">
                  {error.subject}
                </div>
              </div>
            </div>
            <div className="section">
              <h4>
                {'Mensagem'}
              </h4>
              <div className="section-content">
                <textarea
                  placeholder="Digite uma mensagem"
                  className="text-input contact-textarea"
                  maxLength={messageRules.max}
                  value={message}
                  onChange={this.onMessageChange}
                />
                <div className="error">
                  {error.message}
                </div>
              </div>
            </div>
            <button className="button normal" type="submit" disabled={this.hasError()}>
              {'Enviar'}
            </button>
          </form>
        </TutorialWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sendContact: (name, email, subject, message) => dispatch(contactSend(name, email, subject, message)),
});

const mapStateToProps = state => ({
  subjectRules: state.settings.contact && state.settings.contact.subject,
  messageRules: state.settings.contact && state.settings.contact.message,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
