import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import CategoriesIconsItem from './icon';

export const CategoriesIcons = ({ items }) => (
  <div className="content-container">
    <Grid fluid className="categories-icons">
      <Row center="xs">
        {items.map(item => (
          <Col
            sm={6}
            md={4}
            key={item.name}
          >
            <CategoriesIconsItem
              name={item.name}
              url={item.url}
              onClick={item.onClick}
              icon={item.icon}
              description={item.description}
              notification={item.notification}
            />
          </Col>
        ))}
      </Row>
    </Grid>
  </div>
);

export default CategoriesIcons;
