import RestService from '../services/rest';
import PlayersUtils from '../utils/players';

async function getResume() {
  const data = await RestService.getAuthenticated('level');
  data.top_users = data.top_users.map(players => PlayersUtils.completePlayer(players));
  return data;
}

async function getLevel(min, max, limit, skip) {
  const url = `level/divisions?min=${min}&max=${max}&skip=${skip}&limit=${limit}`;
  const data = await RestService.getAuthenticated(url);
  data.data = data.data.map(players => PlayersUtils.completePlayer(players));
  return data;
}

export default {
  getResume,
  getLevel,
};
