import Promise from 'bluebird';
import axios from 'axios';
import ReactGA from 'react-ga';

import AuthService from './services/auth';
import ErrorService from './services/error';

import { authenticateComplete, authenticateRefreshToken, authenticateClean } from './store/actions/authenticate';
import { activitiesSetAuthenticated, activitiesSetStarting, activitiesSetMaintenance }
  from './store/actions/activities';

import startTimers from './store/actions/timers';
import { settingsGet } from './store/actions/settings';

function initializeReactGA() {
  ReactGA.initialize('UA-112020895-1');
  ReactGA.pageview('/');
}

export const axiosInstance = axios.create();

function addAxiosinterceptor(store) {
  axiosInstance.interceptors.response.use(async (response) => {
    await store.dispatch(activitiesSetMaintenance(false));
    return response;
  }, async (err) => {
    // Network Error -> Set Maintenance
    if (err.message === 'Network Error') {
      store.dispatch(activitiesSetMaintenance(true));
      return Promise.reject(err);
    } if (err.response && err.response.data) {
      const error = err.response.data;

      // Maintenance Error -> Set Maintenance
      if (ErrorService.inMaintenance(error)) {
        store.dispatch(activitiesSetMaintenance(true));

        // Authentication Error -> Set Refresh Token
      } else if (AuthService.isAuthenticated() && ErrorService.isInvalidToken(error)) {
        const token = AuthService.get().refresh_token;
        try {
          await store.dispatch(authenticateRefreshToken(token));
          const accessToken = AuthService.get().access_token;
          err.response.config.headers.Authorization = accessToken;
          return await axiosInstance(err.response.config);
        } catch (refreshError) {
          store.dispatch(authenticateClean());
          return Promise.reject(refreshError);
        }
      }
    }
    return Promise.reject(err);
  });
}

async function authenticated(store) {
  if (AuthService.isAuthenticated()) {
    store.dispatch(activitiesSetAuthenticated(true));
    store.dispatch(activitiesSetStarting(true));
    try {
      await store.dispatch(authenticateComplete());
    } catch (err) {
      await store.dispatch(startTimers());
    } finally {
      store.dispatch(activitiesSetStarting(false));
    }
  } else {
    await store.dispatch(startTimers());
  }
}

export default async (store) => {
  addAxiosinterceptor(store);
  initializeReactGA();

  try {
    await Promise.all([
      authenticated(store),
      store.dispatch(settingsGet()),
    ]);
  } catch (err) {
    // Redirect to maintenance
  } finally {
    const launchLoader = document.getElementById('launch');
    launchLoader.classList.add('hide');
  }
};
