import React from 'react';
import { connect } from 'react-redux';

import { accountChancePassword } from '../../../app/store/actions/account';

import TutorialWrapper from '../../elements/tutorial/tutorial';

import AccountPasswordForm from './passwordForm';

export class AccountPassword extends React.Component {
  chancePassword = async (oldPassword, password) => {
    await this.props.chancePassword(oldPassword, password);
  }

  render() {
    return (
      <TutorialWrapper image="password.jpg">
        <h3 className="title-center">
          {'Senha'}
        </h3>
        <AccountPasswordForm chancePassword={this.chancePassword} />
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  chancePassword: (oldPassword, password) => dispatch(accountChancePassword(oldPassword, password)),
});


export default connect(undefined, mapDispatchToProps)(AccountPassword);
