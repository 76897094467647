import TeamsRequests from '../../server/teams';
import { addLoading, removeLoading } from './loading';
import { userGetAuth } from './user';
import { teamsGetTeam } from './teams';

export const userTeamCreate = (gameMode, name, abbr) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await TeamsRequests.createUserTeam(gameMode, name, abbr);
    return dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const userTeamUpdateBadge = (gameMode, collectibleId) => async (dispatch, getState) => {
  const { teams } = getState().user;

  if (teams && teams[gameMode]) {
    dispatch(addLoading());
    try {
      await TeamsRequests.updateTeamBadge(gameMode, collectibleId);
      return dispatch(teamsGetTeam(gameMode, teams[gameMode].name));
    } finally {
      dispatch(removeLoading());
    }
  }
};
