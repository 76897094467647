import RestService from '../services/rest';
import TeamsUtils from '../utils/teams';

async function proposals(teamId) {
  const teams = await RestService.getAuthenticated(`teams/friendly?team=${teamId}`);
  teams.received = teams.received.map(team => TeamsUtils.completeTeam(team));
  teams.sent = teams.sent.map(team => TeamsUtils.completeTeam(team));
  teams.teams = teams.teams.map(team => TeamsUtils.completeTeam(team));
  return teams;
}

async function sendProposal(teamId, teamFriendlyId) {
  return RestService.postAuthenticated('teams/friendly/send', {
    team: teamId,
    team_friendly: teamFriendlyId,
  });
}

async function cancelProposal(teamId, teamFriendlyId) {
  return RestService.postAuthenticated('teams/friendly/cancel', {
    team: teamId,
    team_friendly: teamFriendlyId,
  });
}

async function acceptProposal(teamId, teamFriendlyId) {
  return RestService.postAuthenticated('teams/friendly/accept', {
    team: teamId,
    team_friendly: teamFriendlyId,
  });
}

async function rejectProposal(teamId, teamFriendlyId) {
  return RestService.postAuthenticated('teams/friendly/reject', {
    team: teamId,
    team_friendly: teamFriendlyId,
  });
}

export default {
  proposals,
  sendProposal,
  cancelProposal,
  acceptProposal,
  rejectProposal,
};
