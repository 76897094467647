import RestService from '../services/rest';
import TeamsUtils from '../utils/teams';
import GamesUtils from '../utils/games';
import PlayersUtils from '../utils/players';
import CompetitionsUtils from '../utils/competitions';

async function getTeamsLeague(gameMode, league) {
  const teams = await RestService.getAuthenticated(`teams/${gameMode}/list/league?league=${league}`);
  teams.teams = teams.teams.map(team => TeamsUtils.completeTeam(team));
  return teams;
}

async function getTeamsFederation(gameMode) {
  const federations = await RestService.getAuthenticated(`teams/${gameMode}/list/region`);
  federations.forEach((federation) => {
    federation.teams = federation.teams.map(team => TeamsUtils.completeTeam(team));
  });
  return federations;
}

async function getTeams(gameMode) {
  const teams = await RestService.getAuthenticated(`teams/${gameMode}/names`);
  return teams.map(team => TeamsUtils.completeTeam(team));
}

async function getTeam(gameMode, name) {
  const team = await RestService.getAuthenticated(`teams/${gameMode}?name=${name}`);
  return TeamsUtils.completeTeam(team);
}

async function getTeamMarketHistoric(teamId, skip, limit) {
  const url = `teams/players/historic?team=${teamId}&limit=${limit}&skip=${skip}`;
  const historic = await RestService.getAuthenticated(url);
  historic.data.forEach((row) => { row.user = PlayersUtils.completePlayer(row.user); });
  return historic;
}

async function getTeamGames(teamId, round, skip, limit) {
  const url = `teams/games/history?team=${teamId}&round=${round}&limit=${limit}&skip=${skip}`;
  const games = await RestService.getAuthenticated(url);
  return games.map(game => GamesUtils.completeGame(game));
}

async function getTeamCompetitions(teamId) {
  const url = `teams/competitions?team=${teamId}`;
  const competitions = await RestService.getAuthenticated(url);

  ['started', 'closed'].forEach((option) => {
    const types = Object.keys(competitions[option] || {});
    types.forEach(type => competitions[option][type].forEach((competition) => {
      competition.details = CompetitionsUtils.completeCompetition(competition.details);
    }));
  });
  return competitions;
}

async function getTeamAchievements(teamId) {
  const achievements = await RestService.getAuthenticated(`teams/achievements?team=${teamId}`);
  achievements.triumphs.forEach((triumph) => {
    if (triumph.competition) {
      triumph.competition = CompetitionsUtils.completeCompetition(triumph.competition.competition);
    }
  });
  return achievements;
}

async function updateFormation(teamId, players) {
  return RestService.postAuthenticated('teams/formation', {
    team: teamId,
    players,
  });
}

async function createUserTeam(gameMode, name, abbr) {
  return RestService.postAuthenticated(`teams/${gameMode}/create`, {
    name,
    abbr,
  });
}

async function updateTeamBadge(gameMode, collectibleId) {
  return RestService.postAuthenticated(`teams/${gameMode}/badge`, {
    collectible: collectibleId,
  });
}

async function savePortal(teamId, code) {
  return RestService.postAuthenticated('teams/portal', {
    team: teamId,
    code,
  });
}

async function loadPortal(teamId) {
  return RestService.getAuthenticated(`teams/portal?team=${teamId}`);
}

export default {
  getTeamsLeague,
  getTeamsFederation,
  getTeams,
  getTeam,
  getTeamMarketHistoric,
  getTeamGames,
  getTeamCompetitions,
  getTeamAchievements,
  updateFormation,
  createUserTeam,
  updateTeamBadge,
  savePortal,
  loadPortal,
};
