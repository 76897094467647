import React from 'react';

import CardSimple from '../../elements/card/simple';
import BadgeList from '../../elements/badgeList/badgeList';
import Team from '../../teams/elements/team';
import Player from '../../players/elements/player';

import CampaignsUtils from '../../../app/utils/campaigns';
import CampaignsData from '../../../data/campaigns';

const campaignInvitationsListItem = (props) => {
  const { maxUsers, invitation } = props;
  const { type, real_team: realTeam, users = [] } = invitation;

  const blockDetails = CampaignsUtils.blockDetails(type);
  const colors = CampaignsData.difficulties_colors;

  return (
    <CardSimple>
      <BadgeList
        items={[{
          id: type.id,
          color: colors[invitation.difficulty],
          name: type.name,
          tag: {
            nameHover: blockDetails && blockDetails.name,
            colorHover: blockDetails && blockDetails.color,
          },
          badge: type.logo.url,
          mark: invitation.type.xp ? invitation.type.xp : undefined,
        }]}
        gridInfo={{ lg: 12, md: 12, sm: 12 }}
      />
      <div className="team-content">
        <div className="label">Clube:</div>
        <Team data={realTeam} type="table" />
      </div>
      <div className="team-content">
        <div className="label">
          {'Jogadores: '}
          <span>
            {users.length}
            {'/'}
            {maxUsers}
          </span>
        </div>
        {users.length > 1 ? (
          <div className="strikers">
            {users.map(player => <Player key={player.id} data={player} type="image" />)}
          </div>
        ) : (
          <Player data={users[0]} type="table" />
        )}
      </div>
      <div className="team-content height-auto">
        <button className="button normal" type="button" onClick={() => props.accept(invitation.id, invitation.name)}>
          {'Aceitar'}
        </button>
        <button className="button normal red" type="button" onClick={() => props.reject(invitation.id)}>
          {'Recusar'}
        </button>
      </div>
    </CardSimple>
  );
};

export default campaignInvitationsListItem;
