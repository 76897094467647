import React from 'react';

import TeamsDetailsManagementFriendlySearchTable from './table';

const TeamsDetailsManagementFriendlyReceived = props => (
  <div className="friendly-received">
    <div className="title-line">
      {'Propostas Recebidas'}
    </div>
    <TeamsDetailsManagementFriendlySearchTable
      teams={props.teams}
      onAccept={props.accept}
      onReject={props.reject}
    />
  </div>
);

export default TeamsDetailsManagementFriendlyReceived;
