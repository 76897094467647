import React from 'react';

import StepsButtons from '../../../elements/steps/buttons';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

const TutorialManagerIntroduction = props => (
  <TutorialWrapper image="game_modes/introduction.jpg">
    <StepsButtons {...props}>
      <h3 className="title-center">
        {'Cartola'}
      </h3>
      <p>
        {'Neste modo, você fundará um clube com o nome de sua escolha. Além de poder escolher o escudo '
        + 'que mais lhe agrade.'}
      </p>
      <p>
        {'Cada jogador no site tem um valor de mercado, alguns valem mais e outros menos, dependendo do '
        + 'desempenho nas rodadas. Você terá um valor fictício para escalar seu clube, com os jogadores do site. '
        + 'Você terá que escolher a formação para seu clube, dependendo da sua estratégia.'}
      </p>
      <p>
        {'Toda vez que um jogador valorizar durante a rodada e você o tiver escalado, seu valor fictício '
        + 'aumentará para a próxima rodada. No caso dele desvalorizar, o inverso acontece.'}
      </p>
      <p className="text">
        {'Durante o ano, o seu Clube participará de diversos campeonatos oficiais, além de campeonatos '
        + 'personalizados e premiums. Será possível, em cada rodada, marcar amistosos com outros clubes.'}
      </p>
    </StepsButtons>
  </TutorialWrapper>
);

export default TutorialManagerIntroduction;
