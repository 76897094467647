import React from 'react';

import GameClassificatory from '../elements/games/classificatory/classificatory';

import translator from '../../data/translator';

export const LiveGames = ({ gameMode, games = [] }) => (
  <div>
    <h3 className="title-center-banner">
      {translator.s.gameModes[gameMode]}
    </h3>
    {games.map(game => (
      <GameClassificatory
        key={game.game.id}
        game={game.game}
      />
    ))}

  </div>
);

export default LiveGames;
