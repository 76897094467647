import React from 'react';
import { Link } from 'react-router-dom';

import PlayerDefault from './player/default';
import PlayerGameBet from './player/gameBet';
import PlayerTable from './player/table';
import PlayerImage from './player/image';

import ReputationUtils from '../../../app/utils/reputation';
import PlayersUtils from '../../../app/utils/players';

export class Player extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      player: this.getPlayer(props.data),
      type: props.type,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ player: this.getPlayer(nextProps.data) });
  }

  getPlayer = (data) => {
    const player = { ...data };
    player.link = player.id ? Link : (<div />);
    return player;
  }

  getPlayerElement = () => {
    const { player, type } = this.state;
    const { noIcons, className, label } = this.props;

    const data = {
      player,
      reputation: label || ReputationUtils.getReputation(player.level && player.level.value).name,
      isPrime: !label && PlayersUtils.isPrime(player),
      noIcons: noIcons || label,
      className,
    };

    switch (type) {
      case 'text':
        return data.player.username;
      case 'table':
        return (<PlayerTable {...data} />);
      case 'gameBet':
        return (<PlayerGameBet {...data} />);
      case 'image':
        return (<PlayerImage {...data} />);
      default:
        return (<PlayerDefault {...data} />);
    }
  }

  render() {
    const { player } = this.state;

    if (!player) return (null);

    let PlayerElement = this.getPlayerElement();

    if (player.id && player.status !== 'excluded') {
      PlayerElement = (
        <Link to={PlayersUtils.getLink(player.username)}>
          {PlayerElement}
        </Link>
      );
    }

    return (PlayerElement);
  }
}

export default Player;
