import React from 'react';

import TeamsDetailsOverviewStatisticsRanking from './rankings/rankings';

const TeamsDetailsOverviewStatistics = (props) => {
  const { rankings = [], gameMode } = props;

  return (
    <section className="statistics">
      <div className="content-container">
        <TeamsDetailsOverviewStatisticsRanking rankings={rankings} gameMode={gameMode} />
      </div>
    </section>
  );
};

export default TeamsDetailsOverviewStatistics;
