import React from 'react';

import Table from '../../../../elements/table';
import Player from '../../../../players/elements/player';

import PlayerTableValuation from '../../../../players/table/valuation';
import PlayerTableBet from '../../../../players/table/bet';

export const TeamsDetailsManagementMarketTable = (props) => {
  const { markets, hasAction } = props;

  const header = [
    { key: 'player', value: 'Jogadores', className: 'name' },
    {
      key: 'value', value: 'Valor', className: 'number', tdClassName: 'high',
    },
    { key: 'bet', value: 'Aposta', className: 'number' },
  ];

  if (hasAction) header.push({ key: 'options', className: 'number', tdClassName: 'tb-button' });

  const data = markets.map(market => ({
    key: market.user.id,
    player: <Player data={market.user} type="table" />,
    value: <PlayerTableValuation valuation={market.user.valuation} />,
    bet: <PlayerTableBet bets={market.user.bets} />,
    options: (
      <React.Fragment>
        {!!market.available
        && (
        <button className="button blue" type="button" onClick={() => props.onHire(market.user.id)}>
          {'Contratar'}
        </button>
        )}

        {!market.available && !!market.sent
        && (
        <button className="button red" type="button" onClick={() => props.onCancel(market.user.id)}>
          {'Cancelar'}
        </button>
        )}

        {!market.available && !market.sent
        && (
        <button className="button" type="button" onClick={() => props.onSend(market.user.id)}>
          {'Enviar'}
        </button>
        )}
      </React.Fragment>
    ),
  }));

  return (<Table header={header} lines={data} />);
};

export default TeamsDetailsManagementMarketTable;
