import React from 'react';
import { connect } from 'react-redux';

import { recordsGetAchievements } from '../../../app/store/actions/records';
import pageTitleService from '../../../app/services/pageTitle';

import { scrollTo } from '../../../routers/appRouter';

import RecordsData from '../../../data/records';

import Player from '../../players/elements/player';

import TableAuth from '../../elements/tableAuth';
import IntervalSelect from '../../elements/statistics/intervalSelect';

import RecordsAchievementsTitles from './title';

export class RecordsAchievements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: Number(props.match.params.page || 1),
    };
  }

  async componentDidMount() {
    const { page } = this.state;
    pageTitleService.setTitle('Conquistas');
    await this.props.getAchievements(page);
    scrollTo('menu-secondary');
  }

  render() {
    const { players, totalPages, match } = this.props;
    const { authUser, userAchievementsValue, userAchievementsPosition } = this.props;

    const achievementsHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      { key: 'user', value: 'Descarte', colspan: 2 },
      { key: 'xp', value: 'Pontos', tdClassName: 'high' },
      { key: 'titles', value: 'Títulos' },
    ];

    const achievementsData = players.map(user => ({
      key: user.id,
      position: user.achievements.position,
      user: <Player data={user} type="table" />,
      xp: user.achievements.xp,
      titles: <RecordsAchievementsTitles titles={user.achievements.triumphs
        .filter(t => t.category === 'champion' && t.competition && t.competition.type === 'official')}
      />,
    }));

    return (
      <div className="achievements-page">
        <div className="content-container margin-bottom">
          <div className="title-center-menu">
            {'Conquistas'}
          </div>
          <IntervalSelect
            totalPages={totalPages}
            perPage={RecordsData.achievements.players_perPage}
            match={match}
            removeSelect
            userPosition={{
              name: authUser.username,
              picture: authUser.picture.url,
              value: userAchievementsValue,
              position: userAchievementsPosition,
              type: 'player',
            }}
          />
          <TableAuth header={achievementsHeader} lines={achievementsData} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAchievements: page => dispatch(recordsGetAchievements(page)),
});

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.match.params.page || 1;
  return {
    players: state.records.achievements.data[page] || [],
    totalPages: state.records.achievements.total,
    authUser: state.user,
    userAchievementsValue: (state.user.achievements && state.user.achievements.xp) || 0,
    userAchievementsPosition: state.user.achievements && state.user.achievements.position,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordsAchievements);
