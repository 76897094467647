import RankingsRequests from '../../server/rankings';
import UsersStatisticsRequests from '../../server/usersStatistics';
import ReputationRequests from '../../server/reputation';
import { addLoading, removeLoading } from './loading';

import StatisticsUtil from '../../utils/statistics';

import RecordsData from '../../../data/records';

export const cleanRecord = record => ({
  type: 'RECORDS_RECORD_CLEAN',
  record,
});

export const setBets = (interval, page, data, total) => ({
  type: 'RECORDS_BETS_SET',
  interval,
  page,
  data,
  total,
});

export const setDiscard = (page, data, total) => ({
  type: 'RECORDS_DISCARD_SET',
  page,
  data,
  total,
});

export const setAchievements = (page, data, total) => ({
  type: 'RECORDS_ACHIEVEMENTS_SET',
  page,
  data,
  total,
});

export const setTop5 = (interval, data) => ({
  type: 'RECORDS_TOP5_SET',
  interval,
  data,
});

export const setReputationResume = data => ({
  type: 'RECORDS_REPUTATION_RESUME_SET',
  data,
});

export const recordsGetBets = (intervalName, page) => async (dispatch, getState) => {
  const limit = RecordsData.bets.players_perPage;
  const skip = ((page - 1) * limit);
  const interval = StatisticsUtil.getInterval(intervalName);

  if (page === 1 || interval !== getState().records.bets.interval) dispatch(cleanRecord('bets'));

  if (!getState().records.bets.data[page]) {
    dispatch(addLoading());
    try {
      const bets = await RankingsRequests.playerBets(interval, skip, limit);
      if (bets) {
        const totalPages = Math.ceil(bets.total / limit);
        dispatch(setBets(interval, page, bets.data, totalPages));
      }
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const recordsGetDiscard = page => async (dispatch, getState) => {
  const limit = RecordsData.discard.players_perPage;
  const skip = ((page - 1) * limit);

  if (page === 1) dispatch(cleanRecord('discard'));

  if (!getState().records.discard.data[page]) {
    dispatch(addLoading());
    try {
      const discard = await RankingsRequests.playerDiscard(skip, limit);
      if (discard) {
        const totalPages = Math.ceil(discard.total / limit);
        dispatch(setDiscard(page, discard.data, totalPages));
      }
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const recordsGetAchievements = page => async (dispatch, getState) => {
  const limit = RecordsData.discard.players_perPage;
  const skip = ((page - 1) * limit);

  if (page === 1) dispatch(cleanRecord('achievements'));

  if (!getState().records.achievements.data[page]) {
    dispatch(addLoading());
    try {
      const achievements = await RankingsRequests.playerAchievements(skip, limit);
      if (achievements) {
        const totalPages = Math.ceil(achievements.total / limit);
        dispatch(setAchievements(page, achievements.data, totalPages));
      }
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const recordsGetTop5 = intervalName => async (dispatch, getState) => {
  const interval = StatisticsUtil.getIntervalTop5(intervalName);

  if (!getState().records.top5[interval]) {
    dispatch(addLoading());
    try {
      let year;
      if (interval !== 'all') year = interval;
      const data = await UsersStatisticsRequests.getTop5(year);
      dispatch(setTop5(interval, data));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const recordsGetReputationResume = () => async (dispatch, getState) => {
  if (!getState().records.reputation.resume) {
    dispatch(addLoading());
    try {
      const data = await ReputationRequests.getResume();
      dispatch(setReputationResume(data));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const recordsGetReputationLevel = (min, max, limit, skip) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await ReputationRequests.getLevel(min, max, limit, skip);
    return data;
  } finally {
    dispatch(removeLoading());
  }
};

export const recordsGetRoundRankingBets = (roundNumber, page) => async (dispatch) => {
  const limit = RecordsData.bets.players_perPage;
  const skip = ((page - 1) * limit);

  dispatch(addLoading());
  try {
    const data = await RankingsRequests.roundRankingBets(roundNumber, limit, skip);
    return { ...data, totalPages: Math.ceil(data.total / limit) };
  } finally {
    dispatch(removeLoading());
  }
};
