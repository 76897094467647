import React from 'react';

import TutorialWrapper from '../elements/tutorial/tutorial';

import AccountProfileForm from '../account/profile/profileForm';

export class RegistrationUserForm extends React.Component {
  getProfileData = async (data) => {
    const err = await this.props.saveUserData(data);
    if (err) {
      this.props.lastStep();
    }
  }

  render() {
    return (
      <TutorialWrapper image="registration/image_3.jpg">
        <h3 className="title-center">
          {'Dados Pessoais'}
        </h3>
        <p>
          {'Agora vamos passar à identificação da pessoa física do usuário. '
          + 'Lembrando que essas informações não serão divulgadas dentro do site.'}
        </p>
        <AccountProfileForm
          submitLabelButton="Criar Conta"
          getProfileData={this.getProfileData}
          previous={this.props.previousStep}
        />
      </TutorialWrapper>
    );
  }
}

export default RegistrationUserForm;
