import React from 'react';
import { connect } from 'react-redux';

import { roundsUpdateScore } from '../../../app/store/actions/rounds';

import AdministrationScoreForm from './administrationScoreForm';

export class AdministrationScoreGames extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      games: props.currentRound.games,
    };
  }

  async componentWillReceiveProps(nextProps) {
    const { currentRound, isIdle, roundUpdated } = nextProps;

    if (roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
      await this.setState({ games: currentRound.games });
    }
  }

  canScore = game => (game.status === 'in_progress' || game.status === 'finished')

  onScoreChange = (index, isHome, value) => {
    const { games } = this.state;

    value = value.replace(/\D/g, '');

    const field = (isHome) ? 'score_home' : 'score_away';
    if (value && Number(value) >= 0) {
      games[index][field] = Number(value);
    } else {
      delete games[index][field];
    }

    this.setState({ games });
  };

  onStatusChange = (index, value) => {
    const { games } = this.state;

    const oldValue = this.canScore(games[index]);

    games[index].status = value;

    if (oldValue !== this.canScore(games[index])) {
      if (oldValue) {
        games[index].score_home = undefined;
        games[index].score_away = undefined;
      } else {
        games[index].score_home = 0;
        games[index].score_away = 0;
      }
    }

    this.setState({ games });
  };

  saveResults = async () => {
    const { games } = this.state;
    await this.props.updateScore(games);
  }

  render() {
    const { games = [] } = this.state;

    return (
      <React.Fragment>
        <AdministrationScoreForm
          games={games}
          canScore={this.canScore}
          onScoreChange={this.onScoreChange}
          onStatusChange={this.onStatusChange}
        />
        <button
          type="button"
          className="button normal light-blue button-bet"
          onClick={this.saveResults}
        >
          {'Atualizar Resultados'}
        </button>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateScore: games => dispatch(roundsUpdateScore(games)),
});

const mapStateToProps = state => ({
  currentRound: state.rounds.current,
  isIdle: state.activities.idle,
  roundUpdated: state.rounds.updateAt,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationScoreGames);
