import React from 'react';

import Steps from '../../elements/steps/steps';

import ForgotPasswordForm from './form';
import ForgotPasswordSuccess from './success';

const ForgotPasswordPage = () => (
  <div className="content-container">
    <Steps>
      <ForgotPasswordForm />
      <ForgotPasswordSuccess />
    </Steps>
  </div>
);

export default ForgotPasswordPage;
