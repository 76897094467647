import React from 'react';

import Steps from '../../../elements/steps/steps';

import TeamsStatisticsConfrontationHeader from './header';

import TeamsStatisticsConfrontationFirstTeam from './firstTeam';
import TeamsStatisticsConfrontationSecondTeam from './secondTeam';
import TeamsStatisticsConfrontationInformation from './information/information';

export class TeamsStatisticsConfrontation extends React.Component {
  state = {
    firstTeam: null,
    secondTeam: null,
    data: null,
  };

  setFirstTeam = async (team) => {
    await this.setState({ firstTeam: team });
  }

  setSecondTeam = async (team) => {
    await this.setState({ secondTeam: team });
  }

  setData = async (data) => {
    await this.setState({ data });
  }

  reset = async () => {
    await this.setState({ firstTeam: null, secondTeam: null, data: null });
  }

  render() {
    const { firstTeam, secondTeam, data } = this.state;
    const { modeData } = this.props;
    const { mode } = modeData;

    return (
      <div className="team-confrontation">
        <TeamsStatisticsConfrontationHeader
          teamHome={data ? data.team_home : { team: firstTeam }}
          teamAway={data ? data.team_away : { team: secondTeam }}
        />
        <Steps noScroll>
          <TeamsStatisticsConfrontationFirstTeam
            mode={mode}
            setFirstTeam={this.setFirstTeam}
          />
          <TeamsStatisticsConfrontationSecondTeam
            mode={mode}
            firstTeam={firstTeam}
            setSecondTeam={this.setSecondTeam}
          />
          <TeamsStatisticsConfrontationInformation
            mode={mode}
            firstTeam={firstTeam}
            secondTeam={secondTeam}
            data={data}
            setData={this.setData}
            reset={this.reset}
          />
        </Steps>
      </div>
    );
  }
}

export default TeamsStatisticsConfrontation;
