import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faShieldAlt } from '@fortawesome/free-solid-svg-icons';

import Player from '../../../../../players/elements/player';

const TeamsCompetitionsDetailsInformationPlayOffPenalties = ({ home = {}, away = {} }) => {
  let kickers = [];

  const getPlayerElement = player => (player ? <Player data={player} type="text" /> : 'Campanha');

  if (home.goalkeeper) {
    kickers = home.kickers.map((kicker, index) => ({
      key: index + 1,
      className: '',
      home: {
        ...kicker,
        text: getPlayerElement(kicker.user),
      },
      away: {
        ...away.kickers[index],
        text: getPlayerElement(away.kickers[index].user),
      },
    }));


    kickers.unshift({
      key: 0,
      className: ' goalkeeper',
      icon: faShieldAlt,
      home: {
        ...home.goalkeeper,
        text: getPlayerElement(home.goalkeeper.user),
      },
      away: {
        ...away.goalkeeper,
        text: getPlayerElement(away.goalkeeper.user),
      },
    });
  } else {
    kickers = home.kickers.map((kicker, index) => ({
      key: index + 1,
      className: '',
      home: {
        goal: kicker.goal,
        text: index === 10 ? 'Campanha' : `Jogo ${10 - index}`,
      },
      away: {
        goal: away.kickers[index].goal,
        text: index === 10 ? 'Campanha' : `Jogo ${10 - index}`,
      },
    }));
  }

  return (
    <div className={`penalties${home.goalkeeper ? ' whith-goalkeeper' : ''}`}>
      {kickers.map(serie => (
        <div
          className={`
            serie${serie.className}
            ${serie.home.goal ? ' home-goal' : ''}
            ${serie.away.goal ? ' away-goal' : ''}
          `}
          key={serie.key}
        >
          <div className="kicker">
            {serie.home.text}
          </div>
          <div className="value">
            {serie.home.value}
          </div>
          <div className="icon">
            <FontAwesomeIcon icon={serie.icon || faFutbol} />
          </div>
          <div className="icon">
            <FontAwesomeIcon icon={serie.icon || faFutbol} />
          </div>
          <div className="value">
            {serie.away.value}
          </div>
          <div className="kicker">
            {serie.away.text}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamsCompetitionsDetailsInformationPlayOffPenalties;
