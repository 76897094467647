export default {
  pie: {
    colors: ['#3DCC91', '#FFB366', '#FF7373'],
  },
  line: {
    colors: ['#8884d8', '#3DCC91', '#FFB366', '#FF7373'],
  },
  bar: {
    colors: ['#8884d8', '#82ca9d', '#ffc658', '#FF7373'],
  },
  radar: {
    colors: ['#8884d8', '#82ca9d', '#ffc658'],
  },
};
