export default {
  types: {
    score: 'Apostas',
    achievements_official: 'Conquistas Oficiais',
    achievements_custom: 'Conquistas Premiums',
    achievements_premium: 'Conquistas Personalizadas',
    achievements_vip: 'Conquistas Primes',
    achievements_ranked: 'Conquistas Rankeadas',
    games_official: 'Jogos Oficiais',
    games_friendly: 'Jogos Amistosos',
    games_custom: 'Jogos Personalizados',
    games_premium: 'Jogos Premiums',
    games_ranked: 'Jogos Rankeados',
  },
};
