import React from 'react';
import { connect } from 'react-redux';

import { accountUpdateUser } from '../../../app/store/actions/account';

import TutorialWrapper from '../../elements/tutorial/tutorial';

import AccountProfileForm from './profileForm';

export class AccountProfile extends React.Component {
  updateUser = async (data) => {
    await this.props.updateUser(data);
  }

  render() {
    const {
      firstName, lastName, gender, location, birth,
    } = this.props;

    return (
      <TutorialWrapper image="registration/image_3.jpg">
        <h3 className="title-center">
          {'Dados Pessoais'}
        </h3>
        <AccountProfileForm
          firstName={firstName}
          lastName={lastName}
          gender={gender}
          location={location}
          birth={birth}
          submitLabelButton="Salvar"
          getProfileData={this.updateUser}
        />
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(accountUpdateUser(data)),
});

const mapStateToProps = state => ({
  firstName: state.user.first_name,
  lastName: state.user.last_name,
  gender: state.user.gender,
  location: state.user.location && state.user.location.name,
  birth: state.user.birth,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile);
