import React from 'react';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';

import { scrollTo } from '../../../routers/appRouter';

import pageTitleService from '../../../app/services/pageTitle';

import ReputationUtils from '../../../app/utils/reputation';
import ReputationData from '../../../data/reputation';

import Paginate from '../../elements/paginate';
import BadgeListItem from '../../elements/badgeList/badgeList';

import { recordsGetReputationResume, recordsGetReputationLevel } from '../../../app/store/actions/records';

import RecordsReputationTable from './table';

export class RecordsReputation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      pages: 0,
    };
  }

  async componentDidMount() {
    pageTitleService.setTitle('Reputação');
    await this.props.getResume();
    scrollTo('menu-secondary');
  }

  setLevel = async (name, level, page) => {
    const skip = (page - 1) * ReputationData.limit;
    const data = await this.props.getLevel(level.min, level.max, ReputationData.limit, skip);
    const pages = Math.ceil(data.total / ReputationData.limit);
    await this.setState({
      players: data.data, name, page, pages, level,
    });
    scrollTo('reputatio-table');
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    const { name, level } = this.state;

    await this.setLevel(name, level, pageIndex + 1);
  };

  render() {
    const {
      name, players, page, pages,
    } = this.state;
    const { levels = [], topPlayers = [] } = this.props;

    const items = levels.map((level) => {
      const reputation = ReputationUtils.getReputation(level.max);

      return {
        id: level.min,
        color: reputation.color,
        name: reputation.baseName,
        tag: {
          nameHover: `${level.total} Jogadores`,
          colorHover: reputation.color,
        },
        badge: reputation.picture,
        onClick: () => this.setLevel(reputation.baseName, level, 1),
      };
    });

    const gridInfo = {
      lg: 3, md: 3, sm: 4, xs: 6,
    };

    const title = name ? `Jogadores ${name}` : 'Melhores Jogadores';

    return (
      <div className="content-container margin-bottom reputation">
        <div className="title-center">
          {'Reputação'}
        </div>
        <div className="levels">
          <BadgeListItem items={items} gridInfo={gridInfo} firstOffset={1} center />
        </div>
        <Element name="reputatio-table" />
        <RecordsReputationTable players={players || topPlayers} title={title} />
        <Paginate
          pageCount={pages}
          forcePage={page - 1}
          onPageChange={this.handlePageClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getResume: () => dispatch(recordsGetReputationResume()),
  getLevel: (min, max, limit, skip) => dispatch(recordsGetReputationLevel(min, max, limit, skip)),
});

const mapStateToProps = state => ({
  levels: state.records.reputation.resume && state.records.reputation.resume.levels,
  topPlayers: state.records.reputation.resume && state.records.reputation.resume.top_users,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordsReputation);
