const initialState = {
  availables: [],
  suggestion: {},
  users: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ROUNDS_BETS_AVAILABLES_SUGGESTION_SET':
      return {
        ...state,
        availables: action.availables,
        suggestion: { ...state.suggestion, ...action.suggestion },
      };
    case 'ROUNDS_BETS_USER_BET_SET':
      if (!state.users[action.userId]) state.users[action.userId] = {};
      state.users[action.userId][action.roundNumber] = action.bet;
      return state;
    default:
      return state;
  }
};
