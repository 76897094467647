import React from 'react';

import CardTable from '../../../elements/card/table';
import TeamUtils from '../../../../app/utils/teams';
import TeamPlayerIcons from '../playerIcons';

const TeamTable = (props) => {
  const { team, className = '', noIcons } = props;

  let tag;
  if (team.continent) {
    tag = (team.state ? `${team.state} - ` : '') + (team.country ? `${team.country}/` : '') + team.continent;
  } else if (!team.user) {
    tag = team.region;
  } else if (noIcons) {
    tag = team.user.username;
  } else {
    tag = (
      <React.Fragment>
        <div className="label">
          {team.user.username}
        </div>
        <TeamPlayerIcons player={team.user} />
      </React.Fragment>
    );
  }

  return (
    <CardTable
      className={className}
      name={team.name}
      nameClass={TeamUtils.nameClass(team.name)}
      badge={{
        url: team.badge.url,
        title: team.name,
      }}
      tag={tag}
    />
  );
};

export default TeamTable;
