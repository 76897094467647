import React from 'react';

import ImageLoad from '../image/imageLoad';

const CardTable = ({
  name, className, tag, tagStyle, badge, level, levelStyle, round, nameClass = '',
}) => (
  <div className={`card-all card-table${className ? ` card-${className}` : ''}`} key={name}>
    <div className={`badge${(round) ? ' round' : ''}`}>
      <ImageLoad
        src={badge.url}
        alt={badge.name}
        placeholder="/images/team_placeholder.png"
      />
      {level && (
      <div className={`level${levelStyle ? ` ${levelStyle}` : ''}`}>
        {level}
      </div>
      )}
    </div>
    <div className="texts">
      <div className={`name ${nameClass}`}>
        {name}
      </div>
      <div className={`tag ${tagStyle || ''}`}>
        {tag}
      </div>
    </div>
  </div>
);

export default CardTable;
