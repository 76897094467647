import TeamsStatisticsRequests from '../../server/teamsStatistics';
import { addLoading, removeLoading } from './loading';

import StatisticsUtil from '../../utils/statistics';

import StatisticsData from '../../../data/statistics';

export const setTop5 = (gameMode, interval, data) => ({
  type: 'TEAMS_STATISTICS_TOP5_SET',
  gameMode,
  interval,
  data,
});

export const cleanRanking = (gameMode, ranking) => ({
  type: 'TEAMS_STATISTICS_RANKING_CLEAN',
  gameMode,
  ranking,
});

export const setRanking = (gameMode, ranking, interval, page, data, total) => ({
  type: 'TEAMS_STATISTICS_RANKING_SET',
  gameMode,
  ranking,
  interval,
  page,
  data,
  total,
});

export const teamsStatisticsGetTop5 = (gameMode, intervalName) => async (dispatch, getState) => {
  const interval = StatisticsUtil.getIntervalTop5(intervalName);

  if (!getState().teamsStatistics.top5[gameMode] || !getState().teamsStatistics.top5[gameMode][interval]) {
    dispatch(addLoading());
    try {
      let year;
      if (interval !== 'all') year = interval;
      const data = await TeamsStatisticsRequests.getTop5(gameMode, year);
      dispatch(setTop5(gameMode, interval, data));
    } finally {
      dispatch(removeLoading());
    }
  }
};


export const teamsStatisticsGetRanking = (
  gameMode, intervalName, page, rankingType, method, extras,
) => async (dispatch, getState) => {
  const limit = StatisticsData.rankings.perPage;
  const skip = ((page - 1) * limit);
  const interval = StatisticsUtil.getInterval(intervalName);

  const rankingInterval = getState().teamsStatistics[rankingType][gameMode]
    && getState().teamsStatistics[rankingType][gameMode].interval;

  if (page === 1 || interval !== rankingInterval) dispatch(cleanRanking(gameMode, rankingType));

  const ranking = getState().teamsStatistics[rankingType][gameMode];

  if (!ranking || !ranking.data[page]) {
    dispatch(addLoading());
    try {
      const response = await method(gameMode, interval, skip, limit, extras);
      if (response) {
        const totalPages = Math.ceil(response.total / limit);
        dispatch(setRanking(gameMode, rankingType, interval, page, response.data, totalPages));
      }
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsStatisticsGetScore = (gameMode, interval, page) => async (dispatch) => {
  const method = TeamsStatisticsRequests.getScore;
  dispatch(teamsStatisticsGetRanking(gameMode, interval, page, 'score', method));
};

export const teamsStatisticsGetGames = (gameMode, type, interval, page) => async (dispatch) => {
  const method = TeamsStatisticsRequests.getGames;
  dispatch(teamsStatisticsGetRanking(gameMode, interval, page, 'games', method, { type }));
};

export const teamsStatisticsGetStrikers = (gameMode, interval, page) => async (dispatch) => {
  const method = TeamsStatisticsRequests.getStrikers;
  dispatch(teamsStatisticsGetRanking(gameMode, interval, page, 'strikers', method));
};

export const teamsStatisticsGetAchievements = (gameMode, type, interval, page) => async (dispatch) => {
  const method = TeamsStatisticsRequests.getAchievements;
  dispatch(teamsStatisticsGetRanking(gameMode, interval, page, 'achievements', method, { type }));
};
