import React from 'react';
import { connect } from 'react-redux';
import { scrollTo } from '../../../../routers/appRouter';
import { teamsGetCompetitions } from '../../../../app/store/actions/teams';

import CompetitionsPremiumsBanner from '../../../elements/competitions/premiumsBanner';

import TeamsDetailsCompetitionsItems from './teamsDetailsCompetitionsItems';

export class TeamsDetailsCompetitions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { competitions } = this.props;
    if (!competitions) await this.props.getCompetitions();
    else this.updateData(competitions);
    scrollTo('menu-secondary');
  }

  async componentWillReceiveProps(nextProps) {
    const { started } = this.state;
    const { competitions } = nextProps;
    if (!started) this.updateData(competitions);
  }

  updateData(competitions = {}) {
    this.setState(() => ({ started: competitions.started || {}, closed: competitions.closed || {} }));
  }

  render() {
    const { started = {}, closed = {} } = this.state;
    const { types, gameMode } = this.props;

    return (
      <div className="team-competitions">

        <CompetitionsPremiumsBanner gameMode={gameMode} />

        <div className="content-container margin-bottom">
          <div className="title-center-banner">
            {'Andamento'}
          </div>

          <TeamsDetailsCompetitionsItems types={types} competitions={started} />

          <div className="title-center-menu">
            {'Encerrados'}
          </div>

          <TeamsDetailsCompetitionsItems types={types} competitions={closed} />

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCompetitions: () => dispatch(teamsGetCompetitions()),
});

const mapStateToProps = state => ({
  types: state.settings.games && state.settings.competitions.types,
  competitions: state.teams.details && state.teams.details.competitions,
  gameMode: state.teams.details && state.teams.details.overview.mode_type,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsCompetitions);
