import React from 'react';
import { connect } from 'react-redux';

import CampaignInProgressDetailsCurrentResult from './result';
import Colors from '../../../../../data/colors';

export const CampaignInProgressDetailsCurrent = ({
  title, totalUsers, difficulty, game, score, difficulties, coop,
}) => {
  const gameValues = difficulties[difficulty].game;
  const multiplier = coop[totalUsers - 1] || 0;

  const victoryScore = Math.ceil(gameValues.victory * multiplier * totalUsers);
  const drawScore = Math.ceil(gameValues.draw * multiplier * totalUsers);

  const average = (score / totalUsers).toFixed(1);

  return (
    <div className="campaign-details-current">

      <div className="header-game">
        {title}
      </div>

      <div className="team">
        <div className="logo">
          <img src={game.real_team.badge.url} alt={game.real_team.name} />
        </div>
        <div className="name">
          {game.real_team.name}
        </div>

        <div className="score-total">
          {`${score} Ponto${score !== 1 ? 's' : ''}`}
          {` | Média: ${average}`}
        </div>
      </div>

      <CampaignInProgressDetailsCurrentResult
        title="Derrota"
        final={score >= drawScore}
        value={score}
        target={0}
      />
      <CampaignInProgressDetailsCurrentResult
        title="Empate"
        color={Colors.layout.belizeHole}
        final={score >= victoryScore}
        value={score}
        target={drawScore}
      />
      <CampaignInProgressDetailsCurrentResult
        title="Vitória"
        color={Colors.layout.greenSea}
        value={score}
        target={victoryScore}
      />

    </div>
  );
};

const mapStateToProps = state => ({
  difficulties: state.settings.campaigns.difficulties,
  coop: state.settings.campaigns.coop,
});

export default connect(mapStateToProps)(CampaignInProgressDetailsCurrent);
