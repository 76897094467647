import React from 'react';
import { connect } from 'react-redux';

import { teamsStatisticsGetScore } from '../../../../app/store/actions/teamsStatistics';

import Team from '../../elements/team';
import RankingTablePositionChange from '../../../elements/ranking/tablePositionChange';

import Ranking from '../../../elements/statistics/ranking';

const TeamsStatisticsScore = (props) => {
  const {
    teams, totalPages, match, authTeam,
  } = props;

  const tableHeader = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'team', value: 'Ranking de Apostas', className: 'name', colspan: 3,
    },
    { key: 'pos_change', tdClassName: 'pos-change', hidden: true },
    {
      key: 'score', value: 'Pontos', className: 'number', tdClassName: 'high',
    },
    { key: 'total', value: 'Rodadas' },
    { key: 'average', value: 'Média' },
  ];

  const tableLines = teams.map(row => ({
    key: row.team.id,
    position: row.position,
    team: <Team data={row.team} type="table" />,
    pos_change: <RankingTablePositionChange value={row.position_last - row.position} />,
    score: row.value,
    total: row.total,
    average: row.score.average.toFixed(2),
  }));

  const authInformation = {};
  if (authTeam) {
    authInformation.type = 'team';
    authInformation.team = authTeam;
    authInformation.rankings = authTeam.rankings;
    authInformation.tag = 'score';
  }

  return (
    <div className="content-container margin-bottom">
      <div className="title-center-menu">
        {'Apostas'}
      </div>
      <Ranking
        match={match}
        totalPages={totalPages}
        tableHeader={tableHeader}
        tableLines={tableLines}
        authInformation={authInformation}
        getRanking={props.getScore}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getScore: (interval, page) => dispatch(teamsStatisticsGetScore(ownProps.modeData.mode, interval, page)),
});

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.match.params.page || 1;
  const { mode } = ownProps.modeData;

  const ranking = state.teamsStatistics.score[mode];
  return {
    teams: (ranking && ranking.data[page]) || [],
    totalPages: (ranking && ranking.total) || 0,
    authTeam: state.user.teams && state.user.teams[mode],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatisticsScore);
