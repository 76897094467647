import React from 'react';

import CardSquad from '../../../elements/card/squad';

import PlayerIcons from '../icons';
import PlayerTeams from '../teams/teams';

const PlayerGameBet = (props) => {
  const { player, reputation, isPrime } = props;

  return (
    <CardSquad
      name={player.username}
      tag={(
        <React.Fragment>
          <div className="label">
            {reputation}
          </div>
          <PlayerTeams teams={player.teams} />
          <PlayerIcons player={player} type="table" />
        </React.Fragment>
      )}
      badge={{
        url: player.picture.url,
        title: player.username,
      }}
      tagStyle={isPrime ? 'prime' : ''}
      round
    />
  );
};

export default PlayerGameBet;
