import React from 'react';

export class Hover extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hover: false };
  }

  mouseOut = async () => {
    const { forceHover } = this.props;
    await this.setState({ hover: false });
    if (this.props.onHover) this.props.onHover(false || forceHover);
  }

  mouseOver = async () => {
    await this.setState({ hover: true });
    if (this.props.onHover) this.props.onHover(true);
  }

  render() {
    const { hover } = this.state;
    const {
      forceHover, children, className, classHover, styleHover = {}, tag,
    } = this.props;

    const isHover = forceHover || hover;

    const props = {
      className: className + (isHover ? ` ${classHover}` : ''),
      style: isHover ? styleHover : {},
      onMouseOver: this.mouseOver,
      onFocus: this.mouseOver,
      onMouseOut: this.mouseOut,
      onBlur: this.mouseOut,
    };

    switch (tag) {
      case 'tableLine':
        return (
          <tr {...props}>
            {children}
          </tr>
        );
      default:
        return (
          <div {...props}>
            {children}
          </div>
        );
    }
  }
}

export default Hover;
