import React from 'react';
import ReactPaginate from 'react-paginate';

const Paginate = ({
  pageCount, forcePage, onPageChange, marginPagesDisplayed = 1, pageRangeDisplayed = 5,
}) => (
  <div className="paginate">
    {(pageCount || 0) > 0 && (
    <ReactPaginate
      previousLabel="Anterior"
      nextLabel="Próximo"
      breakClassName="break-me"
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      forcePage={forcePage}
      onPageChange={onPageChange}
      containerClassName="pagination"
      subContainerClassName="pages pagination"
      activeClassName="active"
    />
    )}
  </div>
);

export default Paginate;
