import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
  }

  showMenu = (event) => {
    event.preventDefault();

    document.body.classList.add('activeDropdown');

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.handleClick);
    });
  }

  closeMenu = async () => {
    document.body.classList.remove('activeDropdown');

    await this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.handleClick);
    });

    if (this.props.clearSearchText) await this.props.clearSearchText();
  }

  handleClick = (event) => {
    if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
      this.closeMenu();
    }
  }

  handleItemClick = (method) => {
    this.closeMenu();
    if (method) method();
  }

  render() {
    const { showMenu } = this.state;
    const {
      navLink, image, title, message, button, links, search, forceOpen, className = '', alert,
    } = this.props;

    let buttonElement = (
      <div
        onClick={this.showMenu}
        onKeyDown={this.showMenu}
        role="presentation"
      >
        {button}
      </div>
    );

    if (navLink) {
      buttonElement = (
        <NavLink
          activeClassName="selected"
          to={navLink}
        >
          {buttonElement}
        </NavLink>
      );
    }


    if (!forceOpen && !showMenu) {
      return (
        <React.Fragment>
          {search}
          {buttonElement}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {search}
        {buttonElement}
        <div
          className={`dropdown ${className}`}
          ref={(element) => {
            this.dropdownMenu = element;
          }}
        >
          <button className="close" type="button" onClick={this.closeMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          {image && (
          <div className="logo">
            <img src={image} alt={title} />
          </div>
          )}

          <div className="title">
            {title}
          </div>

          {message && (
          <div className="message">
            {message}
          </div>
          )}

          <div className="alert">{alert}</div>

          <ul>
            {links.map(link => (link.url ? (
              (
                <Link to={link.url} key={link.key}>
                  <li>
                    <div
                      className="button"
                      onClick={() => { this.handleItemClick(); }}
                      onKeyDown={() => {}}
                      role="presentation"
                    >
                      {link.value}
                      {link.notifications > 0 && <mark className="show-for-mobile">{link.notifications}</mark>}
                    </div>

                  </li>
                </Link>
              )
            ) : (
              (
                <li key={link.key}>
                  <div
                    className="button"
                    onClick={() => { this.handleItemClick(link.onClick); }}
                    onKeyDown={() => {}}
                    role="presentation"
                  >
                    {link.value}
                  </div>
                </li>
              )
            )))}

          </ul>

        </div>
      </React.Fragment>
    );
  }
}
