import React from 'react';

import Team from '../../../teams/elements/team';

const GameListTeam = ({ team, inverted }) => (
  <div className="team-card">
    <div className={`team-content${inverted ? ' card-table-inverted' : ''}`}>
      <Team data={team} type="table" />
    </div>
  </div>
);

export default GameListTeam;
