import UsersRequests from '../../server/users';
import { addLoading, removeLoading } from './loading';
import { userGetAuth } from './user';

export const gameModeActive = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UsersRequests.activeGameMode(gameMode);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const gameModeDisable = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    await UsersRequests.disableGameMode(gameMode);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};
