import React from 'react';

const TeamsCompetitionsDetailsInformationPositions = ({ positionInfo = {} }) => {
  const labels = [];

  Object.keys(positionInfo).forEach((index) => {
    const position = positionInfo[index];
    if (!labels.length || labels[labels.length - 1].name !== position.name) {
      labels.push(position);
    }
  });

  return (
    <div className="table-labels">
      {labels.map(label => (
        <div key={label.name} className={label.className}>
          {label.name}
        </div>
      ))}
    </div>
  );
};

export default TeamsCompetitionsDetailsInformationPositions;
