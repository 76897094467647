import CampaignsRequests from '../../server/campaigns';
import { userGetAuth } from './user';
import { addLoading, removeLoading } from './loading';

import CampaignsData from '../../../data/campaigns';

export const setCampaignsTypes = campaignsTypes => ({
  type: 'CAMPAIGNS_TYPES_SET',
  campaignsTypes,
});

export const setDetails = campaign => ({
  type: 'CAMPAIGNS_DETAILS_SET',
  campaign,
});

export const campaignsGetTypes = () => async (dispatch, getState) => {
  if (!getState().campaigns.types) {
    dispatch(addLoading());
    try {
      const campaignsTypes = await CampaignsRequests.getCampaignsTypes();
      dispatch(setCampaignsTypes(campaignsTypes));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const campaignsGetTeam = () => async (dispatch) => {
  const campaign = await CampaignsRequests.getLastCampaign();
  dispatch(setDetails(campaign));
  dispatch(userGetAuth());
  return campaign;
};

export const campaignsGetList = (status, page) => async (dispatch) => {
  const limit = CampaignsData.list.gamesPerPage;
  const skip = ((page - 1) * limit);

  dispatch(addLoading());
  try {
    const campaign = await CampaignsRequests.getCampaigns(status, limit, skip);
    return campaign;
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsGetById = campaignId => async (dispatch) => {
  dispatch(addLoading());

  try {
    const campaign = await CampaignsRequests.getCampaignDetails(campaignId);
    return campaign;
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsStartTeam = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(setDetails());

  try {
    await dispatch(campaignsGetTeam());
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsHistoric = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    const campaigns = await CampaignsRequests.getHistoric();
    return campaigns;
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsCreate = (campaignTypeId, teamId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CampaignsRequests.createCampaign(campaignTypeId, teamId);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsStart = campaignId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CampaignsRequests.startCampaign(campaignId);
    await dispatch(campaignsGetTeam());
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsCancel = campaignId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CampaignsRequests.cancelCampaign(campaignId);
    dispatch(setDetails());
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsGetUsersInvitation = campaignTypeId => async (dispatch) => {
  dispatch(addLoading());

  try {
    const users = await CampaignsRequests.getCampaignUsersInvitation(campaignTypeId);
    return users;
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsSendInvitation = (campaignId, users) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CampaignsRequests.sendInvitation(campaignId, users);
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsCancelInvitation = (campaignId, user) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CampaignsRequests.cancelInvitation(campaignId, user);
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsAccept = campaignId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CampaignsRequests.acceptInvitation(campaignId);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const campaignsReject = campaignId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CampaignsRequests.rejectInvitation(campaignId);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};
