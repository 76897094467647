import Promise from 'bluebird';

import { roundsGetCurrentRound } from './rounds';
import { statisticsGet } from './statistics';
import { wallsGetMessages, wallsGetNewMessages } from './walls';
import { userGetAuth } from './user';
import { activitiesSetNotificationsItem } from './activities';

import RoundsData from '../../../data/rounds';
import StatisticsData from '../../../data/statistics';
import MessagesData from '../../../data/messages';
import UsersData from '../../../data/users';

const timers = {
  currentRound: null,
  statistics: null,
  wall: null,
  user: null,
};

export const stop = (timer) => {
  clearInterval(timers[timer]);
};

export const timersStopAuth = () => {
  stop('wall');
  stop('user');
};

export const timersStartCurrentRound = () => async (dispatch, getState) => {
  stop('currentRound');

  timers.currentRound = setInterval(() => {
    const isIdle = getState().activities.idle;
    if (!isIdle) dispatch(roundsGetCurrentRound());
  }, RoundsData.current.updateTime);

  dispatch(roundsGetCurrentRound());
};

export const timersStartStatistics = () => async (dispatch) => {
  stop('statistics');
  timers.statistics = setInterval(() => dispatch(statisticsGet()), StatisticsData.updateTime);
  dispatch(statisticsGet());
};

export const getWallmessages = generalName => async (dispatch, getState) => {
  const { notifications } = getState().activities;
  const wall = getState().walls[generalName];
  if (wall.length === 0) {
    return dispatch(wallsGetMessages(generalName));
  }
  const result = await dispatch(wallsGetNewMessages(generalName));
  if (result && result.newMessages > 0) {
    dispatch(activitiesSetNotificationsItem('wall', result.newMessages + notifications.wall));
  }
};


export const timersStartWall = () => async (dispatch, getState) => {
  const isAuthenticated = getState().activities.authenticated;

  if (isAuthenticated) {
    const { generalName } = MessagesData.wall;
    stop('wall');

    timers.wall = setInterval(async () => {
      const { idle: isIdle } = getState().activities;
      if (!isIdle) {
        await dispatch(getWallmessages(generalName));
      }
    }, MessagesData.wall.generalUpdateTime);

    await dispatch(getWallmessages(generalName));
  }
};

export const timersStartUser = () => async (dispatch, getState) => {
  const isAuthenticated = getState().activities.authenticated;

  if (isAuthenticated) {
    stop('user');

    timers.user = setInterval(() => {
      const isIdle = getState().activities.idle;
      if (!isIdle) dispatch(userGetAuth());
    }, UsersData.updateTime);
  }
};

export default () => async (dispatch) => {
  await Promise.all([
    dispatch(timersStartCurrentRound()),
    dispatch(timersStartStatistics()),
    dispatch(timersStartWall()),
    dispatch(timersStartUser()),
  ]);
};
