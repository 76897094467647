import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsDetailsAchievementsTypeItem from './teamsDetailsAchievementsTypeItem';

const TeamsDetailsAchievementsType = (props) => {
  const { triumphs = [] } = props;

  if (triumphs.length === 0) return <div className="titles-empty">Sem Títulos até o momento</div>;

  return (
    <div className="titles">
      <div className="content-container">
        <Grid fluid>
          <Row center="xs">
            {triumphs.slice().reverse().map(title => (
              <Col key={title.competition.id} md={6} lg={4}>
                <TeamsDetailsAchievementsTypeItem title={title} />
              </Col>
            ))}
          </Row>
        </Grid>
      </div>
    </div>
  );
};

export default TeamsDetailsAchievementsType;
