import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Table from '../../../../elements/table';

const CampaignInProgressDetailsStatistics = ({ phases, currentPhase }) => {
  const tableHeader = [
    { key: 'name', tdClassName: 'name', value: 'Desempenho' },
    { key: 'position', value: 'Pos' },
    { key: 'score', value: 'Pts' },
    { key: 'games', value: 'J' },
    { key: 'victories', value: 'V' },
    { key: 'draws', value: 'E' },
    { key: 'losses', value: 'D' },
  ];

  const data = [{
    name: 'Geral',
    position: phases[currentPhase].position ? `${phases[currentPhase].position}º` : '-',
    games: 0,
    victory: 0,
    draw: 0,
    loss: 0,
  }];

  phases.forEach((phase) => {
    phase.games.forEach((game) => {
      if (game.result) {
        data[0].games += 1;
        data[0][game.result] += 1;
      }
    });
  });

  if (phases.length > 1) {
    phases.forEach((phase, index) => {
      const phaseData = {
        name: `${index + 1}ª Fase`,
        position: phase.position ? `${phase.position}º` : '-',
        games: 0,
        victory: 0,
        draw: 0,
        loss: 0,
      };

      phase.games.forEach((game) => {
        if (game.result) {
          phaseData.games += 1;
          phaseData[game.result] += 1;
        }
      });

      data.push(phaseData);
    });
  }


  const tableData = data.map(value => ({
    key: value.name,
    name: value.name,
    position: value.position,
    score: (value.victory * 3) + value.draw,
    games: value.games,
    victories: value.victory,
    draws: value.draw,
    losses: value.loss,
  }));

  return (
    <div className="content-container margin-bottom">
      <div className="title-center-menu">
        {'Estatísticas'}
      </div>
      <Grid fluid style={{ padding: 0 }}>
        <Row center="md">
          <Col md={6}>
            <Table header={tableHeader} lines={tableData} />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default CampaignInProgressDetailsStatistics;
