import RestService from '../services/rest';
import CampaignsUtils from '../utils/campaigns';
import PlayersUtils from '../utils/players';

async function getCampaignsTypes() {
  const campaignsTypes = await RestService.getAuthenticated('campaigns/type/list');
  return campaignsTypes.map(campaignsType => CampaignsUtils.completeCampaignType(campaignsType));
}

async function getLastCampaign() {
  const campaign = await RestService.getAuthenticated('campaigns/last');
  return CampaignsUtils.completeCampaign(campaign);
}

async function getCampaignDetails(campaignId) {
  const campaign = await RestService.getAuthenticated(`campaigns/details?campaign=${campaignId}`);
  return CampaignsUtils.completeCampaign(campaign);
}

async function getUsersBets(campaignId) {
  return RestService.getAuthenticated(`campaigns/bets?campaign=${campaignId}`);
}

async function getHistoric() {
  const campaigns = await RestService.getAuthenticated('campaigns/historic');
  return campaigns.map(campaign => CampaignsUtils.completeCampaign(campaign));
}

async function getCampaigns(status, limit, skip) {
  const campaigns = await RestService.getAuthenticated(`campaigns/list?status=${status}&skip=${skip}&limit=${limit}`);
  return campaigns.map(campaign => CampaignsUtils.completeCampaign(campaign));
}

async function createCampaign(campaignTypeId, teamId) {
  return RestService.postAuthenticated('campaigns/create', {
    real_team: teamId,
    campaign_type: campaignTypeId,
  });
}

async function startCampaign(campaignId) {
  return RestService.postAuthenticated('campaigns/start', {
    campaign: campaignId,
  });
}

async function cancelCampaign(campaignId) {
  return RestService.postAuthenticated('campaigns/cancel', {
    campaign: campaignId,
  });
}

async function getCampaignUsersInvitation(campaignTypeId) {
  const users = await RestService.getAuthenticated(`campaigns/invite/users?campaign=${campaignTypeId}`);
  return users.map(user => PlayersUtils.completePlayer(user));
}

async function sendInvitation(campaignId, users) {
  return RestService.postAuthenticated('campaigns/invite/send', {
    campaign: campaignId,
    users,
  });
}

async function cancelInvitation(campaignId, user) {
  return RestService.postAuthenticated('campaigns/invite/cancel', {
    campaign: campaignId,
    user,
  });
}

async function acceptInvitation(campaignId) {
  return RestService.postAuthenticated('campaigns/invite/accept', {
    campaign: campaignId,
  });
}

async function rejectInvitation(campaignId) {
  return RestService.postAuthenticated('campaigns/invite/reject', {
    campaign: campaignId,
  });
}

export default {
  getCampaignsTypes,
  getLastCampaign,
  getCampaignDetails,
  getUsersBets,
  getHistoric,
  getCampaigns,
  createCampaign,
  startCampaign,
  cancelCampaign,
  getCampaignUsersInvitation,
  sendInvitation,
  cancelInvitation,
  acceptInvitation,
  rejectInvitation,
};
