import React from 'react';
import moment from 'moment-timezone';
import { Grid, Row, Col } from 'react-flexbox-grid';

import RankingsUtils from '../../../../../../app/utils/rankings';

import Table from '../../../../../elements/table';

const TeamsDetailsOverviewStatisticsRanking = (props) => {
  const { rankings = [], gameMode } = props;

  const data = rankings.filter(r => r.interval === 'all').map((r) => {
    const yearRanking = rankings.find(ranking => ranking.interval === `${moment().year()}_year`
      && ranking.type === r.type);

    const year = yearRanking ? {
      value: yearRanking.value,
      position: yearRanking.position,
    } : {
      value: '-',
      position: '-',
    };

    return {
      type: r.type,
      all: {
        value: r.value,
        position: r.position || '-',
      },
      year,
    };
  });

  data.sort((a, b) => a.all.position - b.all.position);

  const tableHeaderYear = [
    { key: 'name', value: 'Ranking Anual', tdClassName: 'name' },
    { key: 'position', value: 'Posição', tdClassName: 'high' },
    { key: 'value', value: 'Pontos', tdClassName: 'number' },
  ];

  const tableDataYear = data.map(row => ({
    key: row.type,
    name: RankingsUtils.getTypeName(row.type, gameMode),
    position: `${row.year.position}º`,
    value: row.year.value,
  }));

  const tableHeaderAll = [
    { key: 'name', value: 'Ranking Geral', tdClassName: 'name' },
    { key: 'position', value: 'Posição', tdClassName: 'high' },
    { key: 'value', value: 'Pontos', tdClassName: 'number' },
  ];

  const tableDataAll = data.map(row => ({
    key: row.type,
    name: RankingsUtils.getTypeName(row.type, gameMode),
    position: `${row.all.position}º`,
    value: row.all.value,
  }));

  return (
    <Grid>
      <Row>
        <Col md={6}>
          <Table className="dark" header={tableHeaderYear} lines={tableDataYear} />
        </Col>
        <Col md={6}>
          <Table className="dark" header={tableHeaderAll} lines={tableDataAll} />
        </Col>
      </Row>
    </Grid>
  );
};

export default TeamsDetailsOverviewStatisticsRanking;
