import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../../elements/top5/top5Section';

const TeamsStatisticsTop5Games = (props) => {
  const { winning_streak: winning, unbeaten_streak: unbeaten } = props.data;

  return (
    <Top5Section title="Jogos">
      <Grid fluid>
        <Row>
          {winning && (
          <Col lg={4} md={6}>
            {props.createTable(winning.high, 'Maior Série de Vitórias', 'Jogos')}
          </Col>
          )}
          {winning && (
          <Col lg={4} md={6}>
            {props.createTable(winning.current, 'Maior Série de Vitórias Atual', 'Jogos')}
          </Col>
          )}
          {unbeaten && (
          <Col lg={4} md={6}>
            {props.createTable(unbeaten.high, 'Maior Série Invicta', 'Jogos')}
          </Col>
          )}
          {unbeaten && (
          <Col lg={4} md={6}>
            {props.createTable(unbeaten.current, 'Maior Série Invicta Atual', 'Jogos')}
          </Col>
          )}
        </Row>
      </Grid>
    </Top5Section>
  );
};

export default TeamsStatisticsTop5Games;
