import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import SimplePieChart from '../../../../../elements/charts/simplePieChart';
import SimpleBarChart from '../../../../../elements/charts/simpleBarChart';

import Colors from '../../../../../../data/colors';

const TeamsStatisticsConfrontationCharts = (props) => {
  const { teamHome, teamAway } = props;

  const colors = [Colors.layout.turquoise, Colors.layout.peterRiver, Colors.layout.amethyst];

  const dataResults = [
    { name: teamHome.team.name, value: teamHome.statistics.wins },
    { name: teamAway.team.name, value: teamHome.statistics.losses },
    { name: 'Empates', value: teamHome.statistics.draws },
  ];

  const dataGoals = [{
    team_home: teamHome.statistics.goals_for,
    team_away: teamHome.statistics.goals_against,
  }];

  const bars = {
    team_home: {
      name: teamHome.team.name,
      stackId: 'team_home',
    },
    team_away: {
      name: teamAway.team.name,
      stackId: 'team_away',
    },
  };

  return (
    <Grid fluid>
      <Row>
        <Col md={6}>
          <div style={{ height: '30rem', width: '100%', margin: 'auto' }}>
            <SimplePieChart data={dataResults} colors={colors} />
          </div>
        </Col>
        <Col md={6}>
          <div style={{
            height: '30rem', width: '100%', margin: 'auto', maxWidth: '40rem',
          }}
          >
            <SimpleBarChart data={dataGoals} bars={bars} colors={colors} />
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default TeamsStatisticsConfrontationCharts;
