import UsersRequests from '../../server/users';

import { addLoading, removeLoading } from './loading';
import { showAlertError } from './alert';
import { activitiesSetNotificationsItem } from './activities';

export const setUser = user => ({
  type: 'USER_SET',
  user,
});

export const cleanUser = () => ({
  type: 'USER_CLEAN',
});

export const addCollectible = (collectibleId, total) => ({
  type: 'USER_COLLECTIBLE_ADD',
  collectibleId,
  total,
});

export const setNotification = (notification, value) => ({
  type: 'USER_NOTIFICATION_SET',
  notification,
  value,
});

export const madeBet = () => ({
  type: 'USER_BET_MADE',
});

export const userUpdateNotifications = () => async (dispatch, getState) => {
  const { notifications, currency } = getState().user;
  if (notifications) {
    dispatch(activitiesSetNotificationsItem('messages', notifications.messages));
    dispatch(activitiesSetNotificationsItem('collectibles', notifications.collectibles));
    dispatch(activitiesSetNotificationsItem('currency', currency.currently));
  }
};

export const userUpdateNotification = (notification, value) => async (dispatch) => {
  dispatch(setNotification(notification, value));
  dispatch(userUpdateNotifications());
};

export const userGetAuth = () => async (dispatch) => {
  const user = await UsersRequests.getMyUser();
  dispatch(setUser(user));
  dispatch(userUpdateNotifications());
};


export const forgotPassword = email => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UsersRequests.forgotPassword(email);
  } catch (err) {
    dispatch(showAlertError('Usuário não encontrado!'));
    return err;
  } finally {
    dispatch(removeLoading());
  }
};

export const resetPassword = (hash, email, password) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UsersRequests.resetPassword(hash, email, password);
  } catch (err) {
    dispatch(showAlertError('Recuperação Expirou!'));
    return err;
  } finally {
    dispatch(removeLoading());
  }
};
