import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../../routers/appRouter';

import { campaignsGetTeam } from '../../../app/store/actions/campaigns';

import CampaignInProgressHeader from './header/header';
import CampaignInProgressSettings from './settings/settings';
import CampaignInProgressDetails from './details/details';

export class CampaignInProgress extends React.Component {
  async componentDidMount() {
    const hasCampaign = this.redirect();
    if (hasCampaign) await this.props.getDetails();
  }

  async componentWillReceiveProps(nextProps) {
    const { isIdle, roundUpdated, userCampaign } = nextProps;

    const hasCampaign = this.redirect();

    if (hasCampaign) {
      if (this.props.roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
        const campaign = await this.props.getDetails();
        if (!campaign || !campaign.id) {
          history.push(`/jornada/historico/${userCampaign.id}`);
        }
      }
    }
  }

  redirect = () => {
    const { userCampaign } = this.props;
    if (!userCampaign) history.push('/jornada/criacao');
    return !!userCampaign;
  }

  render() {
    const { campaign = {} } = this.props;

    if (!campaign.id) return (null);

    return (
      <React.Fragment>
        <CampaignInProgressHeader campaign={campaign} />
        {campaign.status === 'paused'
          ? <CampaignInProgressSettings campaign={campaign} />
          : <CampaignInProgressDetails campaign={campaign} />
          }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userCampaign: state.user.game_modes_details && state.user.game_modes_details.campaign
  && state.user.game_modes_details.campaign.last,
  campaign: state.campaigns.details,
  roundUpdated: state.rounds.updateAt,
  isIdle: state.activities.idle,
});

const mapDispatchToProps = dispatch => ({
  getDetails: () => dispatch(campaignsGetTeam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInProgress);
