import RestService from '../services/rest';

async function getStatistics() {
  return RestService.getRest('statistics');
}

async function getSettings() {
  return RestService.getRest('settings');
}

export default {
  getStatistics,
  getSettings,
};
