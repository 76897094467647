import React from 'react';
import { Link } from 'react-router-dom';

import TeamsUtils from '../../../../../app/utils/teams';

const TeamsGamesDetailsHeaderTeam = ({ team }) => {
  const Element = team.id ? (
    <Link to={TeamsUtils.getLink(team)}>
      <div className={`name${team.name.length > 14 ? ' low' : ''}`}>
        {team.name}
      </div>
      <div className="tag">
        {team.region || (team.user && team.user.username)}
      </div>
    </Link>
  ) : (
    <React.Fragment>
      <div className={`name${team.name.length > 14 ? ' low' : ''}`}>
        {team.name}
      </div>
      <div className="tag">
        {team.region || (team.user && team.user.username)}
      </div>
    </React.Fragment>
  );

  return (
    <div className="team">
      {Element}
    </div>
  );
};

export default TeamsGamesDetailsHeaderTeam;
