import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import MessangesData from '../../../data/messages';

import { inboxStartRooms, inboxGetRooms, inboxGetMoreRooms } from '../../../app/store/actions/inbox';

import MessagesHeader from '../messagesHeader';
import MessagesListRoom from './messagesListRoom';

export class MessagesList extends React.Component {
  componentWillMount() {
    this.updateInterval = setTimeout(() => this.updateRooms(), MessangesData.inbox.rooms.updateTime);
  }

  componentDidMount() {
    this.props.startRooms();
  }

  componentWillUnmount() {
    clearTimeout(this.updateInterval);
  }

  getMoreRooms = () => {
    this.props.getMoreRooms();
  }

  updateRooms = () => {
    const { isIdle } = this.props;
    if (!isIdle) this.props.getRooms();
    this.updateInterval = setTimeout(() => this.updateRooms(), MessangesData.inbox.rooms.updateTime);
  }

  selectRoom = (roomId) => {
    this.props.selectRoom(roomId);
  }

  render() {
    const { rooms, finished, userId } = this.props;

    const roomsIds = Object.keys(rooms);
    const roomsList = roomsIds.map(roomId => rooms[roomId]);
    roomsList.sort((a, b) => moment(b.last_message.created_at).diff(moment(a.last_message.created_at)));

    return (
      <div className="inbox-list">
        <MessagesHeader buttonList={false} />
        {roomsList.map(room => (
          <MessagesListRoom key={room.id} room={room} userId={userId} selectRoom={this.selectRoom} />
        ))}
        {!finished && (
        <button type="button" className="button normal light-blue" onClick={this.getMoreRooms}>
          {'Mais Mensagens'}
        </button>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startRooms: () => dispatch(inboxStartRooms()),
  getRooms: () => dispatch(inboxGetRooms(1)),
  getMoreRooms: () => dispatch(inboxGetMoreRooms()),
});

const mapStateToProps = state => ({
  rooms: state.inbox.rooms,
  finished: state.inbox.finished,
  isIdle: state.activities.idle,
  userId: state.user.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesList);
