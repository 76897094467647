import React from 'react';
import { connect } from 'react-redux';

import {
  faUser, faUserSlash, faComment, faCommentSlash,
} from '@fortawesome/free-solid-svg-icons';

import { usersAdministrationBlock, usersAdministrationUnblock, usersAdministrationBlockWall }
  from '../../../../app/store/actions/usersAdministration';
import { playersGetPlayer } from '../../../../app/store/actions/players';

import CategoriesIcons from '../../../elements/categories/icons/icons';

export class PlayersDetailsAdministration extends React.Component {
  getStatusLabel = (status) => {
    switch (status) {
      case 'active':
      case 'disable': return 'Ativo';
      case 'blocked': return 'Bloqueado';
      default:
    }
  }

  blockWall = async () => {
    const { userId, username, wallBlocked } = this.props;

    await this.props.blockWall(userId, !wallBlocked);

    await this.props.getPlayer(username);
  }

  changeStatus = async () => {
    const { userId, username, status } = this.props;
    if (status === 'active' || status === 'disable') await this.props.blockUser(userId);
    else if (status === 'blocked') await this.props.unblockUser(userId);

    await this.props.getPlayer(username);
  }

  render() {
    const { status, wallBlocked } = this.props;

    const items = [];

    const statusLabel = this.getStatusLabel(status);

    if (statusLabel) {
      items.push({
        name: statusLabel,
        description: 'Usuário pode acessar o site',
        icon: status === 'blocked' ? faUserSlash : faUser,
        onClick: this.changeStatus,
      });
    }

    if (status === 'active') {
      items.push({
        name: wallBlocked ? 'Bloqueado' : 'Liberado',
        description: 'Usuário pode enviar mensagens no mural',
        icon: wallBlocked ? faCommentSlash : faComment,
        onClick: this.blockWall,
      });
    }

    return (
      <div className="content-container margin-bottom">
        <div className="title-center-menu">
          {'Administração'}
        </div>
        <CategoriesIcons items={items} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  blockUser: userId => dispatch(usersAdministrationBlock(userId)),
  unblockUser: userId => dispatch(usersAdministrationUnblock(userId)),
  blockWall: (userId, isBlocked) => dispatch(usersAdministrationBlockWall(userId, isBlocked)),
  getPlayer: playerName => dispatch(playersGetPlayer(playerName)),
});

const mapStateToProps = (state) => {
  const player = (state.players.details && state.players.details.overview) || {};
  return {
    userId: player.id,
    username: player.username,
    status: player.status,
    wallBlocked: player.blocked && player.blocked.indexOf('wall') !== -1,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayersDetailsAdministration);
