import React from 'react';

import LandingHeaderTop from './top';
import LandingHeaderContent from './content';

export const LandingHeader = () => (
  <div className="landing-header">
    <div className="content-container">
      <LandingHeaderTop />
      <LandingHeaderContent />
    </div>
  </div>
);

export default LandingHeader;
