import React from 'react';

import StepsButtons from '../../../elements/steps/buttons';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

const TutorialNationalIntroduction = props => (
  <TutorialWrapper image="game_modes/introduction.jpg">
    <StepsButtons {...props}>
      <h3 className="title-center">
        {'Clube Real'}
      </h3>
      <p>
        {'Neste modo, o site disponibiliza clubes, que são divididos em Federações. Você será um jogador '
        + 'que irá integrar o elenco de um desses clubes. Cada clube pode ter até 6 jogadores e '
        + 'são administrados por um jogador denominado “Presidente”. O presidente poderá nomear auxiliares '
        + 'em sua administração. As posições para disputa de jogos com outros clubes seguem as posições '
        + 'do Futsal.'}
      </p>
      <p>
        {'Para entrar em um Clube, cabe exclusivamente ao jogador essa escolha. Assim, aceite convites '
        + 'que você sinta que o ambiente entre os jogadores seja de respeito e camaradagem. Lembre-se que, '
        + 'em condições normais, a transferência somente é permitida com a abertura do mercado. Será '
        + 'oferecida também a opção de "Aceitar qualquer Clube", para o caso você não tenha preferências.'}
      </p>
      <p className="text">
        {'Durante o ano, os Clubes participarão de diversos campeonatos oficiais, além de campeonatos '
        + 'personalizados e premiums.'}
      </p>
    </StepsButtons>
  </TutorialWrapper>
);

export default TutorialNationalIntroduction;
