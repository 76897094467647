import React from 'react';

import Team from '../../../../elements/team';
import Table from '../../../../../elements/table';
import RankingTableSequence from '../../../../../elements/ranking/tableSequence';

const TeamsCompetitionsDetailsStatisticsRankingTable = (props) => {
  const { rankings = [] } = props;

  const rankingHeader = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'team', value: 'Clube', className: 'name', colspan: 2,
    },
    {
      key: 'score', value: 'Pts', className: 'number', tdClassName: 'high',
    },
    { key: 'games', value: 'J', className: 'number' },
    { key: 'wins', value: 'V', className: 'number' },
    { key: 'draws', value: 'E', className: 'number' },
    { key: 'losses', value: 'D', className: 'number' },
    { key: 'goals_for', value: 'Gp', className: 'number' },
    { key: 'goals_against', value: 'Gc', className: 'number' },
    { key: 'goals_difference', value: 'S', className: 'number' },
    { key: 'average', value: '%', className: 'number' },
    { key: 'sequence', value: 'Sequência', tdClassName: 'sequence' },
  ];

  const rankingData = rankings.map((row, index) => ({
    key: index,
    position: row.position,
    team: <Team data={row.team} type="table" />,
    score: row.score,
    games: row.games,
    wins: row.wins,
    draws: row.draws,
    losses: row.losses,
    goals_for: row.goals_for,
    goals_against: row.goals_against,
    goals_difference: row.goals_difference,
    average: (row.average * 100).toFixed(1),
    sequence: <RankingTableSequence games={row.sequence.slice().splice(0, 5)} />,
  }));

  return (
    <Table header={rankingHeader} lines={rankingData} />
  );
};

export default TeamsCompetitionsDetailsStatisticsRankingTable;
