export default {
  mode: 'club',
  active: true,
  prime: false,
  basic: true,
  color: '#e74c3c',
  pages: [
    'proposals',
    'teams',
    'calendar',
    'games',
    'competitions',
    'market',
    'statistics',
    'guide',
  ],
  statistics_pages: [
    'achievements',
    'score',
    'games',
    'confrontation',
    'top5',
    'strikers',
  ],
  management_pages: [
    'friendly',
    'market',
    'escalation',
    'portal',
    'roles',
  ],
  list: 'federation',
  players: {},
  regions: {
    brazil_1: 'Paulista',
    brazil_2: 'Carioca',
    brazil_3: 'Mineira e Paranaense',
    brazil_4: 'Catarinense e Gaúcha',
    brazil_5: 'Nordestina',
    brazil_6: 'Centro-Norte',
    america_1: 'Sulamericana',
    america_2: 'Latino Americana',
    europe_1: 'Espanhola',
    europe_2: 'Inglesa',
    europe_3: 'Alemã',
    europe_4: 'Italiana',
    europe_5: 'Francesa',
    europe_6: 'Luso-Holandesa',
    europe_7: 'Oeste Europeu',
    europe_8: 'Leste Europeu',
  },
  leagues: {
    league: {
      name: 'Séries',
    },
    regional: {
      name: 'Regionais',
    },
    continental_cup: {
      name: 'Copas Continentais',
    },
    continental_final_cup: {
      name: 'Finais Continentais',
    },
    qualificatory_cup: {
      name: 'Copa Inter-Regional',
    },
    cup: {
      name: 'Copas Regionais',
    },
    grand_cup: {
      name: 'Copa da Liga',
    },
    final: {
      name: 'Mundial de Clubes',
    },
  },
  positions: {
    goalkeeper: 'Goleiro',
    defender: 'Fixo',
    left_winger: 'Ala Esquerdo',
    right_winger: 'Ala Direito',
    pivot: 'Pivô',
  },
  positions_abbreviation: {
    goalkeeper: 'GOL',
    defender: 'FIX',
    left_winger: 'ALE',
    right_winger: 'ALD',
    pivot: 'PIV',
  },
  formation: '1-2-1',
};
