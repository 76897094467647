import React from 'react';
import SVG from 'react-inlinesvg';

import translator from '../../../data/translator';

export const LandingGameModesMode = ({ gameMode, text = '' }) => (
  <div className="mode">
    <div className="logo">
      <SVG
        src={`/images/game_modes/${gameMode}.svg`}
      >
        <img src={`/images/game_modes/${gameMode}.svg`} alt="Fantasia" />
      </SVG>
    </div>
    <div className="title">
      {translator.s.gameModes[gameMode]}
    </div>
    <div className="text">
      {text}
    </div>
  </div>
);
export default LandingGameModesMode;
