import React from 'react';

import TeamsCompetitionsDetailsInformationPlayOffConfrontation from './confrontation';

import CompetitionsUtils from '../../../../../../app/utils/competitions';

export class TeamsCompetitionsDetailsInformationPlayOffPhase extends React.Component {
  constructor(props) {
    super(props);

    this.state = { };
  }

  goToPhase = (e) => {
    const phaseNumber = e.target.value;
    this.props.goToStep(phaseNumber);
  }

  render() {
    const { phase, totalPhases } = this.props;
    const { confrontations } = phase;

    const phases = [];
    for (let i = 0; i < totalPhases; i += 1) phases.push(CompetitionsUtils.getPlayOffPhaseName(i, totalPhases));

    return (
      <div className="phase">
        <div className="filter-select types center">
          <select value={phase.name + 1} onChange={this.goToPhase}>
            {phases.map((phaseName, index) => (
              <option key={phaseName} value={index + 1}>
                {phaseName}
              </option>
            ))}
          </select>
        </div>
        <div className="confrontations">
          {confrontations.length === 1 ? (
            <TeamsCompetitionsDetailsInformationPlayOffConfrontation
              confrontation={confrontations[0]}
              last
            />
          ) : confrontations.slice(0, confrontations.length / 2).map((confrontation, index) => (
            <React.Fragment key={confrontation.order}>
              <TeamsCompetitionsDetailsInformationPlayOffConfrontation
                confrontation={confrontation}
                last={!phase.next}
              />
              <TeamsCompetitionsDetailsInformationPlayOffConfrontation
                confrontation={confrontations[confrontations.length - (index + 1)]}
                last={!phase.next}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default TeamsCompetitionsDetailsInformationPlayOffPhase;
