import RegistrationRequests from '../../server/registration';
import { addLoading, removeLoading } from './loading';

export const registrationCheckData = data => async (dispatch) => {
  dispatch(addLoading());

  try {
    await RegistrationRequests.checkData(data);
  } finally {
    dispatch(removeLoading());
  }
};

export const registrationSave = data => async (dispatch) => {
  dispatch(addLoading());

  try {
    await RegistrationRequests.registration(data);
  } finally {
    dispatch(removeLoading());
  }
};
