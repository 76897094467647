import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsMarketListHistoryItem from './teamsMarketListHistoryItem';

const TeamsMarketListHistory = (props) => {
  const { history } = props;

  return (
    <Grid fluid>
      <Row>
        {history.map(transaction => (
          <Col sm={6} md={4} lg={3} key={transaction.id}>
            <TeamsMarketListHistoryItem
              player={transaction.user}
              team={transaction.team}
              roundNumber={transaction.round}
              type={transaction.type}
            />
          </Col>
        ))}
      </Row>
    </Grid>
  );
};

export default TeamsMarketListHistory;
