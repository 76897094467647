const initialState = {
  rooms: {},
  finished: false,
  page: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'INBOX_ROOMS_CLEAN':
      return {
        rooms: {},
        finished: false,
        page: 0,
      };
    case 'INBOX_ROOMS_ADD':
      action.rooms.forEach((room) => { state.rooms[room.id] = room; });
      state.rooms = { ...state.rooms };
      state.finished = action.finished;
      state.page = Math.max(state.page, action.page);
      return state;
    default:
      return state;
  }
};
