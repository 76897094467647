import React from 'react';
import { connect } from 'react-redux';

import {
  usersAdministrationBlock, usersAdministrationUnblock,
  usersAdministrationActive, usersAdministrationDelete,
}
  from '../../../app/store/actions/usersAdministration';

export class AdministrationUsers extends React.Component {
  blockUser = async () => {
    const { userId } = this.props;
    await this.props.blockUser(userId);
    this.props.setStatus(userId, 'blocked');
  };

  unblockUser = async () => {
    const { userId } = this.props;
    await this.props.unblockUser(userId);
    this.props.setStatus(userId, 'active');
  };

  activeUser = async () => {
    const { userId } = this.props;
    await this.props.activeUser(userId);
    this.props.setStatus(userId, 'active');
  };

  removeUser = async () => {
    const { userId } = this.props;
    await this.props.deleteUser(userId);
    this.props.setStatus(userId, 'excluded');
  };

  render() {
    const { status } = this.props;

    if (status === 'active' || status === 'disable') {
      return (
        <button className="button red" type="button" onClick={this.blockUser}>
          {'Bloquear'}
        </button>
      );
    }

    if (status === 'blocked') {
      return (
        <button className="button blue" type="button" onClick={this.unblockUser}>
          {'Ativar'}
        </button>
      );
    }

    if (status === 'pending') {
      return (
        <React.Fragment>
          <button className="button blue" type="button" onClick={this.activeUser}>
            {'Ativar'}
          </button>
          {' '}
          <button className="button red" type="button" onClick={this.removeUser}>
            {'Excluir'}
          </button>
        </React.Fragment>
      );
    }

    return 'Sem Ação';
  }
}

const mapDispatchToProps = dispatch => ({
  blockUser: userId => dispatch(usersAdministrationBlock(userId)),
  unblockUser: userId => dispatch(usersAdministrationUnblock(userId)),
  activeUser: userId => dispatch(usersAdministrationActive(userId)),
  deleteUser: userId => dispatch(usersAdministrationDelete(userId)),
});

export default connect(undefined, mapDispatchToProps)(AdministrationUsers);
