import React from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { activitiesSetIdle } from '../../app/store/actions/activities';

import PreferencesData from '../../data/preferences';

export class Idle extends React.Component {
  onActive = () => {
    this.props.setIdle(false);
  }

  onIdle = () => {
    this.props.setIdle(true);
  }

  render() {
    return (
      <IdleTimer
        onActive={this.onActive}
        onIdle={this.onIdle}
        timeout={PreferencesData.idleTime}
        startOnLoad
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setIdle: value => dispatch(activitiesSetIdle(value)),
});

export default connect(undefined, mapDispatchToProps)(Idle);
