import React from 'react';

import TutorialSingle from '../../tutorial/gameMode/single/steps';
import TutorialClub from '../../tutorial/gameMode/club/steps';
import TutorialNational from '../../tutorial/gameMode/national/steps';
import TutorialManager from '../../tutorial/gameMode/manager/steps';
import TutorialCampaign from '../../tutorial/gameMode/campaign/steps';

const GameModeInitiationActivation = (props) => {
  const { modeData } = props;
  const { mode } = modeData;

  let modeTutorial = null;
  switch (mode) {
    case 'single':
      modeTutorial = <TutorialSingle />;
      break;
    case 'club':
      modeTutorial = <TutorialClub />;
      break;
    case 'national':
      modeTutorial = <TutorialNational />;
      break;
    case 'manager':
      modeTutorial = <TutorialManager />;
      break;
    case 'campaign':
      modeTutorial = <TutorialCampaign />;
      break;
    default:
  }

  return modeTutorial;
};

export default GameModeInitiationActivation;
