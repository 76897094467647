import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MenuSecondary from '../../elements/menuSecondary';

import pageTitleService from '../../../app/services/pageTitle';

import TeamsMarketList from './teamsMarketList';

export class TeamsMarket extends React.Component {
  async componentDidMount() {
    pageTitleService.setTitle('Mercado');
  }

  render() {
    const { modeData, match } = this.props;
    const { url } = match;

    const menus = [
      { name: 'Todas', uri: `${url}/todas`, type: 'all' },
      { name: 'Entradas', uri: `${url}/entradas`, type: 'in' },
      { name: 'Saídas', uri: `${url}/saidas`, type: 'out' },
    ];

    return (
      <div>
        <MenuSecondary menus={menus} tag="Mercado" />
        <Switch>
          <Redirect exact from={url} to={`${url}/todas`} />
          {menus.map(menu => (
            <Route
              exact
              key={menu.name}
              path={menu.uri}
              component={() => <TeamsMarketList gameMode={modeData.mode} type={menu.type} />}
            />
          ))}
        </Switch>
      </div>
    );
  }
}

export default TeamsMarket;
