import React from 'react';
import { connect } from 'react-redux';

import { userTeamCreate } from '../../../../app/store/actions/userTeams';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

export class TeamsInitiationCreationName extends React.Component {
  state = {
    name: '',
    abbr: '',
    error: '',
  };

  onNameChange = (e) => {
    const regex = /^[a-z0-9áâãéêíóôõúç ]+$/i;
    const name = e.target.value;

    if (name === '' || regex.test(name.toLowerCase())) {
      this.setState(() => ({ name, error: '' }));
    }
  }

  onAbbrChange = (e) => {
    const regex = /^[A-Z]+$/g;
    const abbr = e.target.value.toUpperCase();

    if (abbr === '' || regex.test(abbr)) {
      this.setState(() => ({ abbr, error: '' }));
    }
  }

  onSubmit = async (e) => {
    const { name, abbr } = this.state;
    const { abbrTotal, minName, mode } = this.props;

    e.preventDefault();

    if (!name || name.length < minName) {
      this.setState(() => ({ error: `O nome deve conter pelo menos ${minName} caracteres. ` }));
    } else if (abbr.length !== abbrTotal) {
      this.setState(() => ({ error: 'Preencha uma abreviação válida.' }));
    } else {
      try {
        await this.props.createTeam(mode, name, abbr);
        this.props.nextStep();
      } catch (err) {
        this.setState(() => ({ error: `Erro na API: ${err}` }));
      }
    }
  }

  render() {
    const { name, abbr, error } = this.state;
    const { abbrTotal, maxName, mode } = this.props;

    return (
      <TutorialWrapper image={`game_modes/${mode}.jpg`}>
        <h3 className="title-center">
          {'Criação'}
        </h3>

        <form onSubmit={this.onSubmit}>
          <div className="section">
            <h4>
              {'Informações do clube'}
            </h4>
            <p>
              {`Para criar o seu clube, o jogador deverá informar o nome do clube e uma abreviação de ${abbrTotal} `
              + 'letras. Escolha o nome com cuidado, pois ele não poderá ser alterado.'}
            </p>
            <div className="section-content">
              <input
                type="text"
                placeholder="Nome do clube"
                value={name}
                maxLength={maxName}
                onChange={this.onNameChange}
              />
              <input
                type="text"
                placeholder={`Abreviação de ${abbrTotal} letras`}
                value={abbr}
                maxLength={abbrTotal}
                onChange={this.onAbbrChange}
              />
              <div className="error">
                {error}
              </div>
            </div>
          </div>
          <button className="button normal" type="submit">
            {'Fundar Clube'}
          </button>
        </form>
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createTeam: (gameMode, name, abbr) => dispatch(userTeamCreate(gameMode, name, abbr)),
});

const mapStateToProps = (state, ownProps) => {
  const { mode } = ownProps;
  const gameMode = (state.settings.game_modes && state.settings.game_modes[mode]) || {};

  return {
    minName: gameMode.name && gameMode.name.min,
    maxName: gameMode.name && gameMode.name.max,
    abbrTotal: gameMode.abbr,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsInitiationCreationName);
