import React from 'react';
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import chartData from '../../../data/charts';

const SimpleLineChart = (props) => {
  const { data, lines, colors } = props;
  const chartColors = colors || chartData.line.colors;

  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 5, right: 60, left: 20, bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        {Object.keys(lines).map((lineKey, index) => (
          <Line
            key={lineKey}
            name={lines[lineKey].name}
            type="monotone"
            dataKey={lineKey}
            stroke={chartColors[index % chartColors.length]}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineChart;
