export default {
  trophies: {
    colors: {
      custom: '#C5C5C5',
      official: '#f39c12',
      others: '#31AB92',
    },
  },
  prime: '#9b59b6',
  bet: {
    made: '#16a085',
    none: '#bdc3c7',
  },
  betRound: '#c0392b',
  score: '#436d88',
};
