import RoundsStatisticsRequests from '../../server/roundsStatistics';
import { addLoading, removeLoading } from './loading';

export const setResume = resume => ({
  type: 'LIVE_RESUME',
  resume,
});

export const liveGetResume = () => async (dispatch) => {
  const resume = await RoundsStatisticsRequests.getResume();
  dispatch(setResume(resume));
};

export const liveStartResume = () => async (dispatch, getState) => {
  const { resume } = getState().live;

  if (!resume) {
    dispatch(addLoading());

    try {
      await dispatch(liveGetResume());
    } finally {
      dispatch(removeLoading());
    }
  }
};
