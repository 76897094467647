import React from 'react';

import TeamsGamesHeader from '../../elements/header/header';

const TeamsGamesDetailsHeader = (props) => {
  const { game } = props;

  const getBottomText = (strikers = []) => {
    let text = '';

    strikers.forEach((player, index) => {
      text += player.player && player.player.username;
      if (player.score > 1) text += ` (${player.score})`;
      if (index + 1 < strikers.length) text += ', ';
    });

    return text;
  };

  return (
    <TeamsGamesHeader
      title={game.competition.name}
      subTitle={`Rodada ${game.round}`}
      teamHome={game.team_home}
      teamAway={game.team_away}
      scoreHome={game.score_home}
      scoreAway={game.score_away}
      teamHomeBottom={getBottomText(game.strikers_home)}
      teamAwayBottom={getBottomText(game.strikers_away)}
    />
  );
};

export default TeamsGamesDetailsHeader;
