import React from 'react';

export class AdministrationRoundsGameAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.startValue,
    };
  }

  onDateChange = async (e) => {
    const dateStr = e.target.value;

    const dateNumbers = dateStr.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})(\d{0,2})(\d{0,2})/);

    const divisor = ['/', '/', ' ', ':'];

    let date = dateNumbers[1];
    for (let i = 2; i < dateNumbers.length; i += 1) {
      if (dateNumbers[i]) date += divisor[i - 2] + dateNumbers[i];
      else break;
    }

    await this.setState(() => ({ date }));
    this.props.setData(date);
  };

  render() {
    const { date } = this.state;

    return (
      <input
        type="text"
        placeholder="dd/mm/aaaa hh:mm"
        className="text-input"
        value={date}
        onChange={this.onDateChange}
      />
    );
  }
}

export default AdministrationRoundsGameAutocomplete;
