import React from 'react';
import { Route } from 'react-router-dom';

import Header from '../components/header/header';

const PublicRoute = ({
  component: Component,
  noHeader,
  ...rest
}) => (
  <React.Fragment>
    {!noHeader && <Header />}
    <Route
      {...rest}
      component={props => (
        <Component {...props} />
      )}
    />
  </React.Fragment>
);

export default PublicRoute;
