import React from 'react';

import TableAuth from '../../../elements/tableAuth';
import Player from '../../../players/elements/player';

import PlayerTableValuation from '../../../players/table/valuation';
import PlayerTableBet from '../../../players/table/bet';
import PlayerTableDiscard from '../../../players/table/discard';

export const TeamsDetailsPlayersStatistics = (props) => {
  const { players } = props;

  const header = [
    { key: 'player', value: 'Jogadores', className: 'name' },
    {
      key: 'value', value: 'Valor', className: 'number', tdClassName: 'high',
    },
    { key: 'bet', value: 'Aposta', className: 'number' },
    { key: 'discard', value: 'Descarte', className: 'number' },
  ];

  const data = players.sort((a, b) => b.user.bets.score.last - a.user.bets.score.last).map(player => ({
    key: player.user.id,
    player: <Player data={player.user} type="table" />,
    value: <PlayerTableValuation valuation={player.user.valuation} />,
    bet: <PlayerTableBet bets={player.user.bets} />,
    discard: <PlayerTableDiscard discard={player.user.discard} />,
  }));

  return (<TableAuth header={header} lines={data} />);
};

export default TeamsDetailsPlayersStatistics;
