import moment from 'moment-timezone';

import TeamsUtils from './teams';
import GamesUtils from './games';
import GameModeUtils from './gameMode';
import CompetitionsUtils from './competitions';
import CampaignsUtils from './campaigns';
import RealTeamsUtils from './realTeams';

const PlayerModel = {
  username: 'Vazio',
  games: [],
  picture: {
    url: '/images/player_placeholder.png',
  },
  level: {},
  achievements: {
    trophies: {
      total: 0,
      types: [],
    },
  },
  bets: {
    score: {},
  },
  subscription: {
    expires: moment(),
  },
};

function completePlayer(player = {}, keepOriginal = false) {
  player = { ...PlayerModel, ...player };

  if (player.status === 'excluded' && !keepOriginal) {
    player.username = 'Excluido';
    player.picture = PlayerModel.picture;
  }

  player.games = player.games.map(game => GamesUtils.completeGame(game));

  if (!player.achievements.level) player.achievements.level = 1;

  const teamsModes = Object.keys(player.teams || {});
  teamsModes.forEach((mode) => {
    if (player.teams[mode].user) {
      player.teams[mode].user = {
        username: player.username,
        picture: player.picture,
      };
    }
    player.teams[mode] = TeamsUtils.completeTeam(player.teams[mode]);
  });

  const teamsModesDetails = Object.keys(player.game_modes_details || {});
  teamsModesDetails.forEach((mode) => {
    if (player.game_modes_details[mode].market && player.game_modes_details[mode].market.user) {
      const proposals = player.game_modes_details[mode].market.user.proposals || {};
      player.game_modes_details[mode].market.user.proposals = proposals.map(team => TeamsUtils.completeTeam(team));
    }
    if (mode === 'campaign') {
      const { invitations = [], last } = player.game_modes_details[mode];
      invitations.forEach((invitation) => {
        invitation.real_team = RealTeamsUtils.completeRealTeam(invitation.real_team);
        invitation.type = CampaignsUtils.completeCampaignType(invitation.type);
        invitation.users = invitation.users.map(user => completePlayer(user));
      });
      if (last) last.type = CampaignsUtils.completeCampaignType(last.type);
    }
  });

  if (player.achievements.triumphs) {
    player.achievements.triumphs.forEach((triumph) => {
      if (triumph.competition) {
        triumph.competition = CompetitionsUtils.completeCompetition(triumph.competition.competition);
      }
    });
  }

  return player;
}

function getPosition(value, gameMode) {
  if (!value) return 'Reserva';
  return GameModeUtils.data[gameMode].positions[value];
}

function getLink(username) {
  return `/jogadores/${username}`;
}

function usernameClass(username) {
  const size = username.length;
  if (size > 13) return 'lg';
  if (size > 10) return 'md';
  if (size > 8) return 'sm';
  return 'xs';
}

function isPrime(player) {
  if (!player.subscription) return false;
  return moment().diff(moment(player.subscription.expires)) < 0;
}

function primeStage(player) {
  if (isPrime(player)) {
    if (moment().add(30, 'days').diff(moment(player.subscription.expires)) > 0) {
      return 'expiring';
    }
    return 'active';
  }
  return 'none';
}

function betValue(player) {
  if (!player.bets.current_made) return 'none';
  if (isPrime(player)) return 'prime';
  return 'bet';
}

function isBestRound(player) {
  return player.bets.best_last_round;
}

function isScorePermission(player) {
  return (player.permissions || []).indexOf('score') !== -1;
}

export default {
  completePlayer,
  getPosition,
  getLink,
  usernameClass,
  isPrime,
  primeStage,
  betValue,
  isBestRound,
  isScorePermission,
};
