import React from 'react';

import TinyPieChart from '../charts/tinyPieChart';

import colorsData from '../../../data/colors';

const GamesResultChart = (props) => {
  const { games = [] } = props;

  let home = 0;
  let away = 0;
  let draw = 0;

  games.forEach((game) => {
    if (game.status === 'finished') {
      if (game.score_home > game.score_away) home += 1;
      else if (game.score_home < game.score_away) away += 1;
      else draw += 1;
    }
  });

  const dataTypes = [
    { name: 'Mandantes', value: home },
    { name: 'Visitantes', value: away },
    { name: 'Empates', value: draw },
  ];

  const colors = [
    colorsData.layout.turquoise,
    colorsData.layout.peterRiver,
    '#FF7373',
  ];

  return (
    <div className="bets">
      <div className="chart-bets">
        <TinyPieChart
          data={dataTypes}
          colors={colors}
        />
      </div>
      <div className="types">
        <div className="data">
          <div className="label">
            <div className="square" style={{ background: colors[0] }} />
            {home}
            {' Mandantes'}
          </div>
        </div>
        <div className="data">
          <div className="label">
            <div className="square" style={{ background: colors[1] }} />
            {away}
            {' Visitantes'}
          </div>
        </div>
        <div className="data">
          <div className="label">
            <div className="square" style={{ background: colors[2] }} />
            {draw}
            {' Empates'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesResultChart;
