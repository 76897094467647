import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { usersAdministrationList } from '../../../app/store/actions/usersAdministration';
import pageTitleService from '../../../app/services/pageTitle';

import { scrollTo, history } from '../../../routers/appRouter';

import AdministrationUsersIp from './administrationUsersIp';
import AdministrationUsersCpf from './administrationUsersCpf';
import AdministrationUsersOptions from './administrationUsersOptions';

import Tabs from '../../elements/tabs/tabs';
import Paginate from '../../elements/paginate';
import Table from '../../elements/table';
import Player from '../../players/elements/player';

import UsersData from '../../../data/users';
import translator from '../../../data/translator';

export class AdministrationUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      users: [],
    };
  }

  async componentDidMount() {
    pageTitleService.setTitle('Usuários');
    scrollTo('menu-secondary');
    await this.getUsers();
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
    await this.getUsers();
  };

  getUsers = async () => {
    const { page } = this.state;
    const { type } = this.props;

    const users = await this.props.getUsersAdministration(type, page);
    await this.setState({ users });
  }

  changeView = async (view) => {
    history.push(`/administrativo/usuarios/${view}`);
  }

  setStatus = async (userId, status) => {
    const { users = [] } = this.state;
    const user = users.find(u => u.id === userId);
    if (user) {
      user.status = status;
      await this.setState({ users });
    }
  }

  render() {
    const { page, users } = this.state;
    const { type } = this.props;

    const totalPages = users.length === UsersData.list.gamesPerPage ? page + 1 : page;

    const tableHeader = [
      { key: 'user', value: 'Usuário' },
      { key: 'email', value: 'E-mail' },
      { key: 'phone', value: 'Telefone' },
      { key: 'cpf', value: 'CPF' },
      { key: 'ip', value: 'Ip' },
      { key: 'birthday', value: 'Nascimento' },
      { key: 'options', value: 'Ferramentas' },
    ];

    const tableData = users.map((user) => {
      let { phone } = user;
      if (phone) {
        phone = user.phone.match(/(\d{2})(\d{5})(\d{4})/) || user.phone.match(/(\d{2})(\d{4})(\d{4})/);
        phone = `(${phone[1]}) ${phone[2]}-${phone[3]}`;
      }

      return {
        key: user.id,
        user: <Player data={user} type="table" />,
        email: user.email,
        phone,
        cpf: <AdministrationUsersCpf cpf={user.cpf} />,
        ip: <AdministrationUsersIp ip={user.last_ip} users={user.user_ips} />,
        birthday: moment(user.birth).format('LL'),
        options: <AdministrationUsersOptions userId={user.id} status={user.status} setStatus={this.setStatus} />,
      };
    });

    return (
      <div className="user-analyze-page">
        <div className="content-container margin-bottom">
          <div className="title-center-menu">
            {'Usuários'}
          </div>
          <Tabs
            items={UsersData.statusTypes.map(statusType => ({
              name: translator.p.users[statusType],
              onClick: () => this.changeView(statusType),
              active: type === statusType,
            }))}
          />
          <Table header={tableHeader} lines={tableData} />
          <Paginate
            pageCount={totalPages}
            forcePage={page - 1}
            onPageChange={this.handlePageClick}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUsersAdministration: (type, page) => dispatch(usersAdministrationList(type, page)),
});

const mapStateToProps = (state, ownProps) => {
  const { type } = ownProps.match.params;

  return {
    type,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationUsers);
