const initialState = {
  league: {},
  federation: {},
  details: {},
  list: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'TEAMS_LEAGUE_SET':
      if (!state.league[action.gameMode]) {
        state.league[action.gameMode] = {
          teams: {},
          maxTeams: action.maxTeams || 1,
        };
      }
      state.league[action.gameMode].teams[action.league] = action.teams;
      return state;
    case 'TEAMS_FEDERATION_SET':
      state.federation[action.gameMode] = action.federations;
      return state;
    case 'TEAMS_DETAILS_SET':
      return {
        ...state,
        details: action.team || {},
      };
    case 'TEAMS_LIST_SET':
      state.list[action.gameMode] = action.teams;
      return state;
    default:
      return state;
  }
};
