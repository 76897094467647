import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { roundsGetRound, roundsUpdate } from '../../../../app/store/actions/rounds';

import AdministrationRoundsGames from '../games/administrationRoundsGames';

export class AdministrationRoundsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  async componentDidMount() {
    const { roundNumber } = this.props;
    const round = await this.props.getRound(roundNumber);

    if (round) {
      const games = round.games.map((game, index) => ({
        index,
        competition: game.competition.id,
        team_home: game.team_home.id,
        team_away: game.team_away.id,
        date: moment(game.date).format('DD/MM/YYYY HH:mm'),
        start: {
          competition: game.competition.id && game.competition,
          team_home: game.team_home.id && game.team_home,
          team_away: game.team_away.id && game.team_away,
        },
      }));

      await this.setState({ games });
    }
  }

  saveGames = async (games) => {
    const { roundNumber } = this.props;

    try {
      await this.props.updateRound(Number(roundNumber), games);
    } catch (err) { /* empty */ }
  }

  render() {
    const { roundNumber } = this.props;
    const { games } = this.state;

    return (
      <div className="content-container margin-bottom administration-round">
        <div className="title-center-menu">
          {`Editar Rodada ${roundNumber}`}
        </div>

        {games && (
          <AdministrationRoundsGames
            games={games}
            buttonLabel="Editar Rodada"
            saveGames={this.saveGames}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getRound: roundNumber => dispatch(roundsGetRound(roundNumber)),
  updateRound: (roundNumber, games) => dispatch(roundsUpdate(roundNumber, games)),
});

const mapStateToProps = (state, ownProps) => {
  const { round } = ownProps.match.params;

  return {
    roundNumber: round,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationRoundsEdit);
