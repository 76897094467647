import React from 'react';

import TutorialWrapper from '../../elements/tutorial/tutorial';

const TutorialGeneralBets = props => (
  <TutorialWrapper image="general/player.jpg">
    <h3 className="title-center">
      {'Jogador'}
    </h3>
    <div className="section">
      <p>
        {'Todo usuário, ao se cadastrar no site, passa a ser um jogador. É esse jogador que '
        + 'realiza todas as ações necessárias à sua atuação.'}
      </p>
    </div>
    <div className="section">
      <h4>
        {'Reputação'}
      </h4>
      <div className="section-content">
        <p>
          {'Todos os jogadores, ao se cadastrarem no site, automaticamente são posicionados na reputação '}
          <b>
            {'Várzea I'}
          </b>
          {'.'}
        </p>
        <p>
          {'A cada rodada, o jogador poderá avançar em sua reputação, desde que atinja a pontuação '
            + 'estipulada, para cada caso, que será calculada pela seguinte fórmula: '}
        </p>
        <h4>
          {'PR = 4C + A – E '}
        </h4>
        <ul>
          <li>
            <i>
              {'PR - Pontos para Reputação'}
            </i>
          </li>
          <li>
            <i>
              {'C - Número de Cravadas (acertos exatos)'}
            </i>
          </li>
          <li>
            <i>
              {'A - Números de Acertos (não exatos)'}
            </i>
          </li>
          <li>
            <i>
              {'E - Número de Erros'}
            </i>
          </li>
        </ul>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Conquista'}
      </h4>
      <div className="section-content">
        <p>
          {'O jogador terá um level no site. Todas as suas conquistas vão acumulando um determinado '
            + 'número de XP, com o objetivo de aumentar esse level.'}
        </p>
        <p>
          {'A cada 100 XP, o jogador subirá 1 level. Você ganhará XP ganhando jogos, campeonatos entre outros.'}
        </p>
        <p>
          {'A cada level conqusitado, o jogador será recompensado com baús de escudos para serem usados em seus '
          + 'clubes.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Descarte'}
      </h4>
      <div className="section-content">
        <p>
          {'Esse ranking objetiva demonstrar o comportamento do jogador nas últimas 10 rodadas realizadas, '
            + 'descartando-se toda a pontuação anterior.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Valor de Mercado'}
      </h4>
      <div className="section-content">
        <p>
          {'O jogador terá um valor de mercado que será referente ao seu desempenho nas apostas. Esse valor '
            + 'será alterado, para mais ou para menos, em todas as rodadas que o jogador palpitar, de acordo '
            + 'com sua pontuação em relação ao seu histórico e desempenho dos outros jogadores na rodada.'}
        </p>
        <p className="text">
          {'O valor '
            + 'apenas será disponibilizado a partir da 10ª rodada em que o jogador palpitar.'}
        </p>
      </div>
    </div>
    <button className="button normal" type="button" onClick={props.previousStep}>
      {'Voltar'}
    </button>
    <button className="button normal" type="button" onClick={props.nextStep}>
      {'Próximo'}
    </button>
  </TutorialWrapper>
);

export default TutorialGeneralBets;
