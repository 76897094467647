export default {
  layout: {
    turquoise: '#1abc9c',
    greenSea: '#16a085',
    emerald: '#2ecc71',
    nephritis: '#27ae60',
    peterRiver: '#3498db',
    belizeHole: '#2980b9',
    amethyst: '#9b59b6',
    wisteria: '#8e44ad',
    wetAsphalt: '#34495e',
    midnightBlue: '#2c3e50',
    sunFlower: '#f1c40f',
    orange: '#f39c12',
    carrot: '#e67e22',
    pumpkin: '#d35400',
    alizarin: '#e74c3c',
    pomegranate: '#c0392b',
    clouds: '#ecf0f1',
    silver: '#bdc3c7',
    concrete: '#95a5a6',
    asbestos: '#7f8c8d',
    black: '#2b2b2b',
  },
  teams: {
    background: {
      black: '#333333',
      blue: '#34495e',
      brown: '#4C2420',
      gray: '#5D4E60',
      green: '#034f3c',
      grenat: '#710033',
      orange: '#d35400',
      pink: '#CC437E',
      purple: '#42033D',
      red: '#BF1616',
      white: '#c9c9c9',
      yellow: '#c7a726',
    },
  },
  numbers: [
    '#2980b9',
    '#16a085',
    '#e74c3c',
    '#8e44ad',
    '#e67e22',
    '#2c3e50',
    '#3498db',
    '#1abc9c',
    '#d35400',
    '#9b59b6',
    '#f39c12',
    '#34495e',
  ],
};
