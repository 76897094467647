import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import HeaderBottomModeButton from './headerBottomModeButton';

import Dropdown from '../../../elements/dropdown';

import RoundsSelector from '../../../../app/store/selectors/rounds';

import GameModeUtils from '../../../../app/utils/gameMode';

import translator from '../../../../data/translator';

export class HeaderBottomMode extends React.Component {
  isBlocked = () => {
    const { userStatus } = this.props;
    return userStatus !== 'active';
  }

  needActive = () => {
    const { userStatus, modeActived } = this.props;
    return userStatus === 'active' && !modeActived;
  }

  canStart = () => {
    const { userStatus, modeActived } = this.props;
    return userStatus === 'active' && modeActived;
  }

  hasPage(page) {
    const { pages } = this.props;
    return pages.indexOf(page) !== -1;
  }

  render() {
    const {
      team, mode, details, basic, isClosedRound,
    } = this.props;

    const teamName = team && team.name;

    const name = translator.s.gameModes[mode];
    const label = translator.p.gameModes[mode];
    const uri = translator.uri.gameModes[mode];

    let alert;
    const marketIsOpen = details.market && details.market.is_open;
    if (marketIsOpen) alert = 'Mercado Aberto';

    const notifications = GameModeUtils.getNotifications(details, team && team.id, isClosedRound, marketIsOpen);
    const activeNotification = this.needActive() && basic;

    const button = (
      <HeaderBottomModeButton
        mode={mode}
        nameMode={name}
        teamName={teamName}
        totalNotification={notifications.total}
        activeNotification={activeNotification}
      />
    );

    const links = [];

    if (this.isBlocked() || this.needActive()) {
      links.push({
        key: 'start',
        url: `/${uri}/introducao`,
        value: (
          <React.Fragment>
            {'Entrar'}
            {activeNotification && <FontAwesomeIcon icon={faExclamationCircle} />}
          </React.Fragment>
        ),
      });
    }

    if (team && !this.isBlocked() && !this.needActive()) {
      links.push({
        key: 'team',
        url: `/${uri}/${team.name}`,
        value: (
          <React.Fragment>
            {team.name}
            {notifications.friendly + notifications.resignRequest + notifications.formation > 0
              && <FontAwesomeIcon icon={faExclamationCircle} />}
          </React.Fragment>
        ),
      });
    }

    if (this.canStart() && !team && this.hasPage('creation')) {
      links.push({
        key: 'creation',
        url: `/${uri}/criacao`,
        value: mode === 'campaign' ? 'Criar Jornada' : 'Criar Clube',
      });
    }

    if (this.canStart() && !team && this.hasPage('invitations') && notifications.campaign) {
      links.push({
        key: 'invitations',
        url: `/${uri}/convites`,
        value: (
          <React.Fragment>
            {'Convites'}
            {notifications.campaign > 0 && <FontAwesomeIcon icon={faExclamationCircle} />}
          </React.Fragment>
        ),
      });
    }

    if (this.canStart() && !team && this.hasPage('proposals')) {
      links.push({
        key: 'proposals',
        url: `/${uri}/propostas`,
        value: (
          <React.Fragment>
            {'Propostas'}
            {notifications.proposals > 0 && <FontAwesomeIcon icon={faExclamationCircle} />}
          </React.Fragment>
        ),
      });
    }

    if (this.canStart() && this.hasPage('historic')) {
      links.push({
        key: 'historic',
        url: `/${uri}/historico`,
        value: 'Histórico',
      });
    }

    if (this.hasPage('teams')) {
      links.push({
        key: 'teams',
        url: `/${uri}`,
        value: label,
      });
    }

    if (this.hasPage('calendar')) {
      links.push({
        key: 'calendar',
        url: `/${uri}/calendario`,
        value: 'Calendário',
      });
    }

    if (this.hasPage('games')) {
      links.push({
        key: 'games',
        url: `/${uri}/jogos`,
        value: 'Jogos',
      });
    }

    if (this.hasPage('competitions')) {
      links.push({
        key: 'competitions',
        url: `/${uri}/campeonatos`,
        value: 'Campeonatos',
      });
    }

    if (this.hasPage('campaigns')) {
      links.push({
        key: 'campaigns',
        url: `/${uri}/lista`,
        value: 'Jornadas',
      });
    }

    if (this.hasPage('market')) {
      links.push({
        key: 'market',
        url: `/${uri}/mercado`,
        value: 'Mercado',
      });
    }

    if (this.hasPage('statistics')) {
      links.push({
        key: 'statistics',
        url: `/${uri}/estatisticas`,
        value: 'Estatísticas',
      });
    }

    if (this.hasPage('guide')) {
      links.push({
        key: 'guide',
        url: `/regulamento/${translator.uri.gameModes[mode]}`,
        value: 'Regulamento',
      });
    }

    return (
      <div className="mode">

        <Dropdown
          alert={alert}
          navLink={`/${translator.uri.gameModes[mode]}`}
          title={name}
          image={`/images/game_modes/${mode}.svg`}
          button={button}
          links={links}
        />

      </div>
    );
  }
}


const mapStateToProps = state => ({
  isClosedRound: RoundsSelector.isClosedRound(state),
});

export default connect(mapStateToProps, null, null, { pure: false })(HeaderBottomMode);
