import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Colors from '../../../../data/colors';

import CardSimple from '../../../elements/card/simple';

import RcProgressLine from '../../../elements/rcProgress/line';
import BetsHitsChart from '../../../elements/statistics/betsHitsChart';
import GamesResultChart from '../../../elements/statistics/gamesResultChart';
import BetsAverageChart from '../../../elements/statistics/betsAverageChart';
import ScoreTable from '../../../elements/statistics/scoreTable';

const RecordsRoundsContentGeneral = (props) => {
  const {
    scores, betsTotal, activeUsers, gamesTotal, simple, accurate, scoreAverage, difficulty, games,
  } = props;
  const { generalScoreAverage, generalDifficulty } = props;

  const betsPer = (activeUsers > 0) ? betsTotal / activeUsers : 0;

  return (
    <div className="general">
      <CardSimple>
        <div className="bets-per">
          <div>
            {betsTotal}
            {' de '}
            {activeUsers}
            {' Jogadores palpitaram'}
            <span className="show-for-desktop"> na rodada</span>
            {'.'}
          </div>
          <div>
            {(betsPer * 100).toFixed(2)}
            {'%'}
          </div>
        </div>
        <div className="bets-per-bar">
          <RcProgressLine
            percent={betsPer * 100}
            strokeLinecap="square"
            strokeColor={Colors.layout.belizeHole}
            trailColor={Colors.layout.silver}
          />
        </div>
        <Grid fluid>
          <Row>
            <Col lg={7} md={12}>
              <Row center="xs">
                <Col md={6}>
                  <BetsAverageChart
                    title="Média de Pontos"
                    single={scoreAverage}
                    general={generalScoreAverage}
                  />
                </Col>
                <Col md={6}>
                  <BetsAverageChart
                    title="Média de Dificuldade"
                    single={difficulty}
                    general={generalDifficulty}
                  />
                </Col>
                <Col md={6}>
                  <BetsHitsChart
                    simple={simple}
                    accurate={accurate}
                    miss={(betsTotal * gamesTotal) - (simple + accurate)}
                  />
                </Col>
                <Col md={6}>
                  <GamesResultChart
                    games={games}
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={5} md={12}>
              <ScoreTable betsTotal={betsTotal * gamesTotal} scores={scores} />
            </Col>

          </Row>
        </Grid>
      </CardSimple>
    </div>
  );
};

export default RecordsRoundsContentGeneral;
