import React from 'react';

import RecordsData from '../../../data/records';

import TinyBarChart from '../../elements/charts/tinyBarChart';

import TeamsUtils from '../../../app/utils/teams';

export const RecordsSupportersChart = (props) => {
  const { teams } = props;

  const totalTeams = RecordsData.supporters.chart.total_perPage;

  const bestTeams = teams.slice(0, totalTeams);

  const barsSupporters = {};
  bestTeams.forEach((team) => {
    barsSupporters[team.id] = {
      name: team.name,
      stackId: team.id,
    };
  });

  const dataSupporters = [{}];

  bestTeams.forEach((team) => {
    dataSupporters[0][team.id] = team.fans;
  });

  const colors = bestTeams.map(team => TeamsUtils.getBackgroundColor(team));

  return (
    <div>
      <div className="badges">
        {bestTeams.map(team => (
          <img key={team.id} src={team.badge.url} alt={team.name} />
        ))}
      </div>
      <div className="chart">
        <TinyBarChart data={dataSupporters} bars={barsSupporters} colors={colors} isVertical />
      </div>
    </div>
  );
};


export default RecordsSupportersChart;
