import React from 'react';

const HeaderBottomModeButton = (props) => {
  const {
    mode, nameMode, teamName, totalNotification, activeNotification,
  } = props;

  return (
    <button type="button" className={`mode-button${totalNotification || activeNotification ? ' alert' : ''}`}>
      <img src={`/images/game_modes/${mode}.svg`} alt={nameMode} />
      <mark>
        {activeNotification ? 1 : totalNotification}
      </mark>
      <div className="texts">
        <div className={`name${teamName ? ' name__team' : ''}`}>
          {nameMode}
        </div>
        {teamName && (
          <div className="team">
            {teamName}
          </div>
        )}
      </div>
    </button>
  );
};

export default HeaderBottomModeButton;
