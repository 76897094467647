import React from 'react';
import { connect } from 'react-redux';

import GameModeUtil from '../../../../app/utils/gameMode';

import GamesCalendar from '../../../elements/games/calendar/calendar';

import TeamsDetailsOverviewPlayers from './players/players';
import TeamsDetailsOverviewBets from './bets/bets';
import TeamsDetailsOverviewBadges from './badges/badges';
import TeamsDetailsOverviewRoles from './roles/roles';
import TeamsDetailsOverviewStatistics from './statistics/statistics';

export const TeamsDetailsOverview = ({
  team, currentRoundNumber, numberClosed, rankedCompetitions = [],
}) => (
  <div className="team-overview">
    <GamesCalendar games={team.games} currentRoundNumber={currentRoundNumber} />
    <TeamsDetailsOverviewRoles team={team} />
    <TeamsDetailsOverviewBadges
      achievements={team.achievements}
      competitions={team.competitions}
      leagues={team.leagues}
      rankedCompetitions={rankedCompetitions}
    />
    {GameModeUtil.data[team.mode_type].positions ? (
      <TeamsDetailsOverviewPlayers team={team} numberClosed={numberClosed} />
    ) : (
      <TeamsDetailsOverviewBets round={team.round} bets={team.bets} numberClosed={numberClosed} />
    )}
    <TeamsDetailsOverviewStatistics
      achievements={team.achievements}
      competitions={team.competitions}
      rankings={team.rankings}
      gameMode={team.mode_type}
    />
  </div>
);

const mapStateToProps = state => ({
  team: state.teams.details && state.teams.details.overview,
  currentRoundNumber: state.rounds.current && state.rounds.current.number,
  numberClosed: state.rounds.numberClosed,
  rankedCompetitions: state.settings.competitions_ranked && state.settings.competitions_ranked.leagues,
});

export default connect(mapStateToProps)(TeamsDetailsOverview);
