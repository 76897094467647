import React from 'react';
import { Link } from 'react-router-dom';

import CompetitionTable from './competition/table';

import CompetitionsUtils from '../../../../app/utils/competitions';

export class Competition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      competition: this.getCompetition(props.data),
      type: props.type,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ competition: this.getCompetition(nextProps.data) });
  }

  getCompetition = (data) => {
    const competition = { ...data };
    competition.link = competition.id ? Link : (<div />);
    return competition;
  }

  getCompetitionElement = () => {
    const { competition, type } = this.state;
    const { className } = this.props;

    switch (type) {
      case 'text':
        return competition.name;
      case 'table':
        return (<CompetitionTable competition={competition} className={className} />);
      default:
        return competition.name;
    }
  }

  render() {
    const { competition } = this.state;

    if (!competition) return (null);

    let CompetitionElement = this.getCompetitionElement();

    if (competition.id && competition.mode_type) {
      CompetitionElement = (
        <Link to={CompetitionsUtils.getLink(competition)}>
          {CompetitionElement}
        </Link>
      );
    }

    return (CompetitionElement);
  }
}

export default Competition;
