import React from 'react';

export const Top5Section = props => (
  <div className="margin-bottom">
    {props.title && (
    <div className="title-line">
      {props.title}
    </div>
    )}
    <div>
      {props.children}
    </div>
  </div>
);

export default Top5Section;
