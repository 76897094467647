import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsUtil from '../../../../../app/utils/teams';

import TeamsDetailsOverviewPlayersTable from './table';
import TeamsGamesEscalationField from '../../../games/details/escalation/field';

export class TeamsDetailsOverviewPlayers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'table',
    };
  }

  changeView = async (view) => {
    await this.setState(() => ({ view }));
  }

  render() {
    const { view } = this.state;
    const { team, numberClosed } = this.props;

    const { formation, players } = TeamsUtil.getTeamFormationPlayers(team);

    if (!formation) return null;

    const positions = Object.keys(formation);

    const score = (team.bets && team.bets.score) || 0;

    const gridCol = team.players.length > 6 ? 6 : 4;

    let scoreClass = '';
    if (score >= 100) scoreClass = ' lg';
    else if (score >= 10) scoreClass = ' md';


    return (
      <div className="content-container players">
        <Grid style={{ padding: 0 }}>
          <Row>
            <Col lg={12}>
              <div className={`title${scoreClass}`}>
                <div className="level">{score}</div>
                <div className="texts">
                  <div className="name">{`Ponto${score !== 1 ? 's' : ''}`}</div>
                  <div className="reputation">
                    {'Rodada '}
                    {numberClosed}
                  </div>
                </div>
              </div>
              <div className="buttons show-for-mobile">
                <button
                  type="button"
                  className={`button normal${view === 'table' ? ' active' : ''}`}
                  onClick={() => this.changeView('table')}
                >
                  {'Tabela'}
                </button>
                <button
                  type="button"
                  className={`button normal${view === 'field' ? ' active' : ''}`}
                  onClick={() => this.changeView('field')}
                >
                  {'Formação'}
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={gridCol} className="show-for-desktop">
              <div className="field">
                <TeamsGamesEscalationField
                  players={players}
                  formation={formation}
                />
              </div>
            </Col>
            <Col lg={12 - gridCol} className="show-for-desktop">
              <TeamsDetailsOverviewPlayersTable
                players={players}
                gameMode={team.mode_type}
                positions={positions}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="show-for-mobile">
              {view === 'table' ? (
                <TeamsDetailsOverviewPlayersTable
                  players={players}
                  gameMode={team.mode_type}
                  positions={positions}
                />
              ) : (
                <div className="field">
                  <TeamsGamesEscalationField
                    players={players}
                    formation={formation}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TeamsDetailsOverviewPlayers;
