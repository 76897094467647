import RestService from '../services/rest';
import TeamsUtils from '../utils/teams';

async function getConfrontationTeams(gameMode, firstTeamId, secondTeamId) {
  const url = `teams/${gameMode}/confrontation?first_team=${firstTeamId}&second_team=${secondTeamId}`;
  const data = await RestService.getAuthenticated(url);
  if (data.team_home) data.team_home.team = TeamsUtils.completeTeam(data.team_home.team);
  if (data.team_away) data.team_away.team = TeamsUtils.completeTeam(data.team_away.team);
  return data;
}

async function getConfrontationTeamsList(gameMode, teamId) {
  const teamsIds = await RestService.getAuthenticated(`teams/${gameMode}/confrontation/list?team=${teamId}`);
  return teamsIds;
}

export default {
  getConfrontationTeams,
  getConfrontationTeamsList,
};
