import React from 'react';
import { connect } from 'react-redux';

import {
  faStar, faHandshake, faClipboard, faUserTie, faFileSignature, faTable,
} from '@fortawesome/free-solid-svg-icons';

import UsersSelector from '../../../../app/store/selectors/users';
import RoundsSelector from '../../../../app/store/selectors/rounds';

import GameModeUtils from '../../../../app/utils/gameMode';

import CategoriesIcons from '../../../elements/categories/icons/icons';

export class TeamsDetailsManagement extends React.Component {
  canAccess(roles, federationPermission) {
    const {
      gameMode, team, userModeTeams, isMaster,
    } = this.props;

    return GameModeUtils.hasAccess(gameMode, userModeTeams, team, {
      roles,
      federationPermission,
      isMaster,
    });
  }

  hasPage(page) {
    const { gameMode } = this.props;
    if (gameMode) {
      const { management_pages: pages } = GameModeUtils.data[gameMode];
      return pages.indexOf(page) !== -1;
    }
  }

  render() {
    const { team, userModeTeams, isClosedRound } = this.props;
    const items = [];

    const marketIsOpen = userModeTeams.market && userModeTeams.market.is_open;
    const notifications = GameModeUtils.getNotifications(userModeTeams, team && team.id, isClosedRound, marketIsOpen);

    if (this.hasPage('friendly') && this.canAccess(['president', 'manager'])) {
      items.push({
        name: 'Amistosos',
        url: 'administrativo/amistosos',
        description: 'Mande ou aceite propostas de amistosos de outros clubes',
        icon: faHandshake,
        notification: notifications.friendly,
      });
    }

    if (this.hasPage('badge') && this.canAccess([])) {
      items.push({
        name: 'Escudo',
        url: 'administrativo/escudo',
        description: 'Altere o escudo do seu clube',
        icon: faStar,
      });
    }

    if (this.hasPage('market') && this.canAccess(['president', 'manager'], true)) {
      items.push({
        name: 'Mercado',
        url: 'administrativo/mercado',
        description: 'Contrate, demita e envie propostas para usuários',
        icon: faFileSignature,
        notification: notifications.resignRequest,
      });
    }

    if (this.hasPage('escalation') && this.canAccess(['president', 'coach'])) {
      items.push({
        name: 'Escalação',
        url: 'administrativo/escalacao',
        description: 'Escale os jogadores do seu clube',
        icon: faClipboard,
        notification: notifications.formation,
      });
    }

    if (this.hasPage('portal') && this.canAccess(['president', 'marketer'])) {
      items.push({
        name: 'Portal',
        url: 'administrativo/portal',
        description: 'Crie ou edite a página personalizada da equipe',
        icon: faTable,
      });
    }

    if (this.hasPage('roles') && this.canAccess(['president'], true)) {
      items.push({
        name: 'Cargos',
        url: 'administrativo/cargos',
        description: 'Altere os cargos do seu clube',
        icon: faUserTie,
      });
    }

    return (<CategoriesIcons items={items} />);
  }
}


const mapStateToProps = (state) => {
  const { details: team } = state.teams;
  const { game_modes_details: gameModesDetails } = state.user;

  let userModeTeams;
  if (team && team.gameMode) {
    userModeTeams = gameModesDetails && gameModesDetails[team.gameMode] && gameModesDetails[team.gameMode];
  }

  return {
    isMaster: UsersSelector.isMaster(state),
    isClosedRound: RoundsSelector.isClosedRound(state),
    team: team && team.overview,
    gameMode: team && team.gameMode,
    userModeTeams,
  };
};

export default connect(mapStateToProps)(TeamsDetailsManagement);
