import React from 'react';
import { Circle } from 'rc-progress';

const RcProgressCircle = props => (
  <Circle
    {...props}
    style={{
      width: '100%',
      height: '100%',
      margin: '0 0 0.8rem',
      padding: 0,
    }}
  />
);

export default RcProgressCircle;
