import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';

import HeaderBottomMode from './mode/headerBottomMode';

import GameModeUtils from '../../../app/utils/gameMode';
import PlayersUtils from '../../../app/utils/players';

import HeaderBottomPublic from './headerBottomPublic';

export const HeaderBottom = ({
  isAuthenticated, userTeams, username, userStatus,
  userGameModes, userAlertBet, userGameModesDetails,
}) => {
  const gameModes = Object.keys(GameModeUtils.data);

  return (

    <div className="header__bottom">
      <Element name="header-bottom" />
      <div className="content-container">
        <div className="content">

          {isAuthenticated && userTeams ? (

            <div className="links">

              { gameModes.map(gameMode => (
                <HeaderBottomMode
                  key={gameMode}
                  userStatus={userStatus}
                  team={userTeams[gameMode]
                    || (userGameModesDetails[gameMode].last && userGameModesDetails[gameMode].last.real_team)}
                  pages={GameModeUtils.data[gameMode].pages}
                  mode={GameModeUtils.data[gameMode].mode}
                  basic={GameModeUtils.data[gameMode].basic}
                  modeActived={userGameModes.indexOf(gameMode) !== -1}
                  details={userGameModesDetails[gameMode]}
                />
              ))}

              <Link
                className={`button bet-button${(userAlertBet) ? ' bet-button__alert' : ''}`}
                to={`${PlayersUtils.getLink(username)}/apostas`}
              >
                {'Palpitar'}
              </Link>
            </div>

          ) : <HeaderBottomPublic />}

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.id,
  userTeams: state.user.teams,
  username: state.user.username,
  userStatus: state.user.status,
  userGameModes: state.user.game_modes || [],
  userAlertBet: state.rounds.numberClosed
    && !((state.user.bets && state.user.bets.current_made) || (state.rounds.isProgress)),
  userGameModesDetails: state.user.game_modes_details || {},
});

export default connect(mapStateToProps, null, null, { pure: false })(HeaderBottom);
