import React from 'react';
import { connect } from 'react-redux';

import TeamsListLeagueHeader from './teamsListLeagueHeader';
import TeamsListLeagueTeams from './teamsListLeagueTeams';

import { teamsGetLeague } from '../../../../app/store/actions/teams';

export class TeamsListLeague extends React.Component {
  state = {
    league: 1,
  }

  async componentDidMount() {
    const { maxTeams, gameMode } = this.props;
    const { league } = this.state;
    if (!maxTeams) { this.props.getTeamsLeague(gameMode, league); }
  }

  goToLeague = async (league) => {
    const { teams, gameMode } = this.props;
    if (!teams[league]) this.props.getTeamsLeague(gameMode, league);
    this.setState({ league });
  };

  render() {
    const { maxTeams, gameMode } = this.props;
    const { league } = this.state;

    return (
      <div className="teams-list-league">
        <TeamsListLeagueHeader
          maxTeams={maxTeams}
          goToLeague={this.goToLeague}
          current={league}
        />
        {maxTeams > 0 && (
        <TeamsListLeagueTeams
          league={league}
          gameMode={gameMode}
        />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTeamsLeague: (gameMode, league) => dispatch(teamsGetLeague(gameMode, league)),
});

const mapStateToProps = (state, ownProps) => ({
  teams: state.teams.league[ownProps.gameMode] && state.teams.league[ownProps.gameMode].teams,
  maxTeams: state.teams.league[ownProps.gameMode] && state.teams.league[ownProps.gameMode].maxTeams,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsListLeague);
