import React from 'react';
import { connect } from 'react-redux';

import CampaignCreationList from './list/campaignCreationList';
import RealTeamSearch from '../../realTeam/search/search';

import { realTeamsGet } from '../../../app/store/actions/realTeams';
import { campaignsCreate } from '../../../app/store/actions/campaigns';

import { history } from '../../../routers/appRouter';

export class CampaignCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  selectCampaignType = async (campaignType) => {
    await this.props.getRealTeams();
    await this.setState({ campaignType });
  }

  selectRealTeam = async (realTeam) => {
    const { campaignType } = this.state;
    await this.props.createCampaign(campaignType.id, realTeam.id);
    history.push(`/jornada/${realTeam.name}`);
  }

  render() {
    const { campaignType } = this.state;
    const { campaign } = this.props;

    const teamName = campaign.real_team && campaign.real_team.name;

    if (campaign.status === 'paused' || campaign.status === 'active') history.push(`/jornada/${teamName}`);
    return (
      <div className="content-container">
        <div className="title-center-menu">
          {'Iniciar Jornada'}
        </div>
        {!campaignType && (
        <CampaignCreationList
          selectCampaignType={this.selectCampaignType}
        />
        )}
        {campaignType && (
        <RealTeamSearch
          title="Escolha o clube"
          states={campaignType.teams.state}
          countries={campaignType.teams.country}
          selectRealTeam={this.selectRealTeam}
        />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.campaigns.details,
});

const mapDispatchToProps = dispatch => ({
  getRealTeams: () => dispatch(realTeamsGet()),
  createCampaign: (campaignTypeId, teamId) => dispatch(campaignsCreate(campaignTypeId, teamId)),
});


export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreation);
