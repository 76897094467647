const initialState = {
  authenticated: false,
  starting: false,
  idle: false,
  maintenance: false,
  notifications: {
    wall: 0,
    messages: 0,
    collectibles: 0,
    currency: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ACTIVITIES_VALUE_SET':
      state[action.attr] = action.value;
      return state;
    default:
      return state;
  }
};
