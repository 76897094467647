import React from 'react';
import { connect } from 'react-redux';

import TeamsGameList from '../../../elements/games/list/list';

const TeamsGamesListRoundGames = (props) => {
  const { games = [] } = props;

  if (games.length === 0) {
    return (
      <div className="content-container games-list-empty">Sem Jogos nessa rodada</div>
    );
  }

  return (
    <div className="content-container games-list-cards">
      {games.map(game => (<TeamsGameList key={game.id} game={game} />))}
    </div>
  );
};


const mapStateToProps = (state, ownProps) => {
  const {
    type, roundNumber, mode, page,
  } = ownProps;

  return {
    games: state.games.list[mode] && state.games.list[mode][type]
    && state.games.list[mode][type][roundNumber] && state.games.list[mode][type][roundNumber].pages[page - 1],
  };
};

export default connect(mapStateToProps)(TeamsGamesListRoundGames);
