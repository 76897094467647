import MessagesRequests from '../../server/messages';
import { userUpdateNotification } from './user';
import { addLoading, removeLoading } from './loading';
import MessagesData from '../../../data/messages';

export const cleanRooms = () => ({
  type: 'INBOX_ROOMS_CLEAN',
});

export const addRooms = (rooms, finished, page) => ({
  type: 'INBOX_ROOMS_ADD',
  rooms,
  finished,
  page,
});

export const inboxGetRooms = page => async (dispatch) => {
  const limit = MessagesData.inbox.rooms.perPage;
  const skip = ((page - 1) * limit);
  const result = await MessagesRequests.getInboxRooms(skip, limit);
  const finished = skip + result.data.length >= result.total;
  dispatch(addRooms(result.data, finished, page));
  dispatch(userUpdateNotification('messages', result.unread));
};

export const inboxStartRooms = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanRooms());

  try {
    await dispatch(inboxGetRooms(1));
  } finally {
    dispatch(removeLoading());
  }
};

export const inboxGetMoreRooms = () => async (dispatch, getState) => {
  dispatch(addLoading());
  const page = getState().inbox.page + 1;

  try {
    await dispatch(inboxGetRooms(page));
  } finally {
    dispatch(removeLoading());
  }
};

export const inboxGetRoom = roomId => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const { finished, page } = getState().inbox;
    const result = await MessagesRequests.getInboxRoom(roomId);
    if (result.room) dispatch(addRooms([result.room], finished, page));
    return result;
  } finally {
    dispatch(removeLoading());
  }
};

export const inboxCreateRoom = (userId, message) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await MessagesRequests.createInboxUserRoom(userId, message);
    return result.id;
  } finally {
    dispatch(removeLoading());
  }
};


export const inboxCreateTeamRoom = (gameMode, message) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await MessagesRequests.createInboxTeamRoom(gameMode, message);
    return result.id;
  } finally {
    dispatch(removeLoading());
  }
};

export const inboxGetMessages = (roomId, date) => async () => {
  const messages = await MessagesRequests.getInboxMessages(roomId, date);
  return messages;
};

export const inboxSendMessages = (roomId, message) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await MessagesRequests.sendInboxMessage(roomId, message);
    const messages = await MessagesRequests.getInboxMessages(roomId);
    return messages;
  } finally {
    dispatch(removeLoading());
  }
};
