import React from 'react';

import TeamsDetailsOverviewBadgesItem from './item';
import TeamsDetailsOverviewBadgesCarousel from './carousel';

import CompetitionsUtils from '../../../../../app/utils/competitions';

import translator from '../../../../../data/translator';

const TeamsDetailsOverviewBadgesCompetitions = (props) => {
  const { competitions = [] } = props;

  const items = competitions.map(competition => (
    <TeamsDetailsOverviewBadgesItem
      link={CompetitionsUtils.getLink(competition.details, competition.status.group)}
      logo={competition.details.logo && competition.details.logo.url}
      name={competition.details.name}
      infos={{
        league: translator.s.competitions[competition.details.type],
        position: competition.status.position,
      }}
    />
  ));

  return (
    <TeamsDetailsOverviewBadgesCarousel
      title="Campeonatos"
      noneText="Sem Campeonatos"
      items={items}
    />
  );
};

export default TeamsDetailsOverviewBadgesCompetitions;
