import React from 'react';
import { Link } from 'react-router-dom';

import { scrollTo } from '../../../routers/appRouter';

export class LandingHeaderTop extends React.Component {
  scrollToSection = (section) => {
    scrollTo(`landing-${section}`, 600);
  }

  render() {
    return (
      <div className="top">
        <div className="logo">
          <img src="/images/landing/logo.png" alt="Barbolão" />
        </div>
        <div className="links">
          <button type="button" className="button" onClick={() => this.scrollToSection('about')}>
            {'sobre'}
          </button>
          <button type="button" className="button" onClick={() => this.scrollToSection('game-modes')}>
            {'modos de jogo'}
          </button>
          <Link to="/cadastro" className="button register">
            {'Cadastrar'}
          </Link>
        </div>
      </div>
    );
  }
}

export default LandingHeaderTop;
