import React from 'react';

import TinyBarChart from '../charts/tinyBarChart';

import colorsData from '../../../data/colors';

const BetsAverageChart = (props) => {
  const { title, single, general } = props;

  const bars = {
    single: {
      name: 'Rodada',
      stackId: 'single',
    },
    general: {
      name: 'Geral',
      stackId: 'general',
    },
  };

  const dataScore = [{ single, general }];

  const colors = [
    colorsData.layout.turquoise,
    colorsData.layout.peterRiver,
  ];

  return (
    <div className="bets">
      <div className="chart-bets">
        <TinyBarChart data={dataScore} bars={bars} />
      </div>
      <div className="types">
        <div className="data">
          <div className="label title">{title}</div>
        </div>
        <div className="data">
          <div className="label">
            <div className="square" style={{ background: colors[1] }} />
            {single.toFixed(2)}
            {' Rodada'}
          </div>
        </div>
        <div className="data">
          <div className="label">
            <div className="square" style={{ background: colors[0] }} />
            {general.toFixed(2)}
            {' Geral'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetsAverageChart;
