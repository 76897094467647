import React from 'react';
import { connect } from 'react-redux';

import CampaignCreationListDifficulty from './campaignCreationListDifficulty';

import { campaignsGetTypes } from '../../../../app/store/actions/campaigns';

import translator from '../../../../data/translator';

import Tabs from '../../../elements/tabs/tabs';

export class CampaignCreationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { types, difficultiesKeys = [] } = this.props;
    if (!types) await this.props.getCampaignsTypes();
    if (difficultiesKeys.length) await this.changeView(difficultiesKeys[0]);
  }

  changeView = async (difficulty) => {
    await this.setState(() => ({ difficultyView: difficulty }));
  }

  render() {
    const { difficultyView } = this.state;
    const {
      user, types = [], difficulties = {}, difficultiesKeys = [],
    } = this.props;

    const difficultyName = difficultiesKeys.find(name => difficultyView === name);

    return (
      <div className="content-container">
        <Tabs
          items={difficultiesKeys.map(name => ({
            name: translator.s.campaign[name],
            onClick: () => this.changeView(name),
            active: difficultyView === name,
          }))}
        />
        {difficultyName && (
          <CampaignCreationListDifficulty
            key={difficultyName}
            name={difficultyName}
            settings={difficulties[difficultyName]}
            user={user}
            types={types}
            selectCampaignType={this.props.selectCampaignType}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCampaignsTypes: () => dispatch(campaignsGetTypes()),
});

const mapStateToProps = state => ({
  user: state.user || {},
  types: state.campaigns.types,
  difficulties: state.settings.campaigns && state.settings.campaigns.difficulties,
  difficultiesKeys: state.settings.campaigns && state.settings.campaigns.difficulties_names,
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreationList);
