import React from 'react';

const LinesHeader = (props) => {
  const { children, color } = props;
  const headerStyle = {};
  if (color) headerStyle.backgroundColor = `${color}E3`;

  return (
    <div className="lines-header">
      <div className="overlay" style={headerStyle}>
        <div className="content-container">
          <div className="back-lines">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinesHeader;
