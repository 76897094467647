import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import colorsData from '../../../data/colors';
import RcProgressLine from '../../elements/rcProgress/line';

import { history } from '../../../routers/appRouter';

import { addCart } from '../../../app/store/actions/cart';

export class CollectiblesChestsChest extends React.Component {
  addToCart = async (chest) => {
    const { packs } = this.props;
    await this.props.addToCart({ chest, packs });
    history.push('/carrinho');
  }

  render() {
    const { chest, url, newCollectible } = this.props;

    const total = Math.ceil((chest.userTotal * 100) / chest.collectibles.length);

    return (
      <div className="chest">
        <div className="content">
          <div className="image">
            {newCollectible ? (
              <img src={newCollectible.image.url} alt={newCollectible.name} />
            ) : (
              <img src={chest.image.url} alt={chest.name} />
            )}
          </div>
          <div className="informations">
            <div className="name">
              {newCollectible ? newCollectible.name : chest.name}
            </div>
            <div className="progress">
              <RcProgressLine
                percent={total}
                strokeWidth="5"
                trailWidth="5"
                strokeLinecap="square"
                strokeColor={total === 100 ? colorsData.layout.amethyst : colorsData.layout.peterRiver}
              />
            </div>
            <div className="total">
              {chest.userTotal}
              {' / '}
              {chest.collectibles.length}
            </div>
            <div className="buttons">
              <Link className="button normal light-blue" to={`${url}/${chest.collection_name}`}>
                {'Itens'}
              </Link>
              {chest.closed > 0 ? (
                <button
                  type="button"
                  className="button normal light-blue open"
                  onClick={() => this.props.handleOpenChest(chest.id)}
                >
                  {'Abrir'}
                  {chest.closed > 1 && ` (${chest.closed})`}
                </button>
              ) : (
                <button
                  type="button"
                  className="button normal buy"
                  onClick={() => this.addToCart(chest)}
                >
                  {'Comprar'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  addToCart: item => dispatch(addCart('chest', item)),
});

export default connect(undefined, mapDispatchToProps)(CollectiblesChestsChest);
