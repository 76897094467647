import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MessagesList from './list/messagesList';
import MessagesRoom from './room/messagesRoom';
import MessagesNew from './new/messagesNew';

export const MessagesPage = () => (
  <div className="content-container margin-bottom messages-wrapper">
    <Switch>
      <Route
        path="/mensagens"
        exact
        component={MessagesList}
      />
      <Route
        path="/mensagens/nova"
        exact
        component={MessagesNew}
      />
      <Route
        path="/mensagens/:roomId"
        component={MessagesRoom}
      />
    </Switch>
  </div>
);

export default MessagesPage;
