import RestService from '../services/rest';
import PlayersUtils from '../utils/players';

async function playerBets(interval, skip, limit) {
  const url = `rankings/users/bets?interval=${interval}&limit=${limit}&skip=${skip}`;
  const bets = await RestService.getAuthenticated(url);
  bets.data.forEach((player) => { player.user = PlayersUtils.completePlayer(player.user); });
  return bets;
}

async function playerDiscard(skip, limit) {
  const discard = await RestService.getAuthenticated(`rankings/users/discard?limit=${limit}&skip=${skip}`);
  discard.data = discard.data.map(player => PlayersUtils.completePlayer(player));
  return discard;
}

async function playerAchievements(skip, limit) {
  const discard = await RestService.getAuthenticated(`rankings/users/achievements?limit=${limit}&skip=${skip}`);
  discard.data = discard.data.map(player => PlayersUtils.completePlayer(player));
  return discard;
}

async function roundRankingBets(roundNumber, limit, skip) {
  const url = `rankings/rounds/bets?round=${roundNumber}&limit=${limit}&skip=${skip}`;
  const rankingBet = await RestService.getAuthenticated(url);
  rankingBet.data.forEach((row) => { row.user = PlayersUtils.completePlayer(row.user); });
  return rankingBet;
}

async function roundRankingPlayers(teamId, limit, skip) {
  const url = `rankings/club/players?interval=all&limit=${limit}&skip=${skip}&team=${teamId}`;
  const rankingPlayers = await RestService.getAuthenticated(url);
  rankingPlayers.data.forEach((row) => { row.user = PlayersUtils.completePlayer(row.user); });
  return rankingPlayers;
}

export default {
  playerBets,
  playerDiscard,
  playerAchievements,
  roundRankingBets,
  roundRankingPlayers,
};
