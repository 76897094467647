import React from 'react';

import CardDefault from '../../../elements/card/default';
import TeamUtils from '../../../../app/utils/teams';

const TeamDefault = (props) => {
  const { team } = props;

  let tag = team.user ? team.user.username : team.region;
  if (!tag) tag = (team.state ? `${team.state} - ` : '') + (team.country ? `${team.country}/` : '') + team.continent;

  return (
    <CardDefault
      name={team.name}
      nameClass={TeamUtils.nameClass(team.name)}
      badge={{
        url: team.badge.url,
        title: team.name,
      }}
      tag={tag}
    />
  );
};

export default TeamDefault;
