import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../../routers/appRouter';

import pageTitleService from '../../../app/services/pageTitle';

import RecordsRoundsHeader from './roundsHeader';
import RecordsRoundsContent from './roundsContent';

export class RecordsRounds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'general',
    };
  }

  async componentDidMount() {
    pageTitleService.setTitle('Rodadas');
  }

  handlePageClick = (data) => {
    const { match } = this.props;
    const { path } = match;
    const { selected: pageIndex } = data;

    const url = path.replace(':round', pageIndex + 1);
    history.push(url);
  }

  changeView = async (view) => {
    await this.setState(() => ({ view }));
  }

  render() {
    const { view } = this.state;
    const { maxRound, match } = this.props;
    const { round } = match.params;

    const menus = [
      { title: 'Geral', view: 'general' },
      { title: 'Jogos', view: 'games' },
      { title: 'Top 5', view: 'top5' },
      { title: 'Apostas', view: 'bets' },
    ];

    return (
      <div className="rounds-statistics-page">
        <RecordsRoundsHeader
          maxRound={maxRound}
          currentRound={round}
          view={view}
          menus={menus}
          changeView={this.changeView}
          handlePageClick={this.handlePageClick}
        />
        <RecordsRoundsContent
          currentRound={round}
          view={view}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  maxRound: state.rounds.current && state.rounds.current.number,
});

export default connect(mapStateToProps)(RecordsRounds);
