import React from 'react';
import { connect } from 'react-redux';
import { scrollTo } from '../../../../routers/appRouter';
import { teamsGetMarketHistoric } from '../../../../app/store/actions/teams';

import TeamsMarketListHistory from '../../market/teamsMarketListHistory';

export class TeamsDetailsPlayersMarket extends React.Component {
  async componentDidMount() {
    const { history } = this.props;
    if (!history) await this.props.getMarketHistoric();
    scrollTo('menu-secondary');
  }

  moreMarket = async () => {
    await this.props.getMarketHistoric();
  }

  render() {
    const { history = [], team, finished } = this.props;

    const marketHistoric = history.map((h, index) => ({
      ...h,
      id: index,
      team,
    }));

    return (
      <div className="content-container market-list margin-bottom">
        <TeamsMarketListHistory history={marketHistoric} />
        {!finished && (
        <button className="button normal" type="button" onClick={this.moreMarket}>
          {'Carregar Mais'}
        </button>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMarketHistoric: page => dispatch(teamsGetMarketHistoric(page)),
});

const mapStateToProps = state => ({
  history: state.teams.details && state.teams.details.market && state.teams.details.market.history,
  finished: state.teams.details && state.teams.details.market && state.teams.details.market.finished,
  team: state.teams.details && state.teams.details.overview,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsPlayersMarket);
