import React from 'react';

const TutorialNationalActive = () => (
  <React.Fragment>
    <h3 className="title-center">
      {'Seleção'}
    </h3>
  </React.Fragment>
);

export default TutorialNationalActive;
