export default {
  ranking: {
    limit: 20,
  },
  strikers: {
    limit: 20,
  },
  contest: {
    limit: 20,
  },
  ranked: {
    limit: 20,
    games: 10,
  },
  leagueColors: {
    league: 'blue',
    cup: 'red',
    grand_cup: 'orange',
    final: 'black',
    regional: 'green',
    qualificatory_cup: 'purple',
    continental_cup: 'grenat',
    continental_final_cup: 'brown',
    qualificatory: 'green',
    world_cup: 'yellow',
    contest: 'grenat',
  },
  typeColors: {
    custom: 'greenSea',
    friendly: 'wisteria',
    official: 'belizeHole',
    premium: 'alizarin',
    vip: 'carrot',
    ranked: 'wetAsphalt',
  },
  positionsColors: [
    'pomegranate',
    'wetAsphalt',
    'belizeHole',
    'greenSea',
  ],
  stagePosition: {
    champion: 'champion',
    final: 'final',
    promotion: 'promotion',
    repechage: 'repechage',
    relegation: 'relegation',
    continental_cup_1: 'competition-1',
    continental_cup_2: 'competition-1',
    continental_cup_3: 'competition-2',
    continental_cup_4: 'competition-2',
    world_cup_1: 'competition-1',
    world_cup_2: 'competition-2',
  },
};
