import InformationsRequests from '../../server/informations';

export const setSettings = data => ({
  type: 'SETTINGS_SET',
  data,
});

export const settingsGet = () => async (dispatch) => {
  const data = await InformationsRequests.getSettings();
  dispatch(setSettings(data));
};
