import React from 'react';

import CampaignInProgressDetailsGame from './game';

import MultipleCarousel from '../../../../elements/multipleCarousel';

import CampaignsUtils from '../../../../../app/utils/campaigns';
import RealTeamsUtils from '../../../../../app/utils/realTeams';

export const CampaignInProgressDetailsGames = ({
  isClosedRound, phases, currentPhase, users,
}) => {
  const games = [];

  let positionIndex = -1;

  phases.forEach((phase, index) => {
    if (currentPhase > index) positionIndex += phase.games.length;
    else if (currentPhase === index) positionIndex += phase.game_round;

    phase.games.forEach((game, gameIndex) => {
      if (index <= currentPhase) {
        if (phase.type === 'classificatory' || phase.game_round >= gameIndex
        || (gameIndex > 0 && gameIndex - 1 === phase.game_round
          && game.real_team.id === phase.games[gameIndex - 1].real_team.id)) {
          return games.push({
            team: game.real_team,
            usersScore: game.users_score,
            result: game.result,
            score: game.score,
            current: isClosedRound && index === currentPhase && phase.game_round === gameIndex,
            phase: index,
            gameRound: phase.game_round,
            title: CampaignsUtils.getPhaseName(phase.type, index, gameIndex, phases.length,
              phase.games, game.round),
          });
        }
      }
      return games.push({
        team: RealTeamsUtils.completeRealTeam(),
      });
    });
  });

  return (
    <div className="campaign-details-games">
      <MultipleCarousel
        items={games.map(game => <CampaignInProgressDetailsGame game={game} users={users} />)}
        xs={1}
        sm={2}
        md={3}
        lg={4}
        indicator
        margin={2.5}
        start={positionIndex}
      />
    </div>
  );
};
export default CampaignInProgressDetailsGames;
