import React from 'react';

import Team from '../../elements/team';

const TeamsCompetitionsDetailsWinners = (props) => {
  const { winners = {} } = props;
  const { champion } = winners;

  if (!champion) return (null);

  return (
    <div className="winners-header">
      <Team data={champion} type="champion" />
    </div>
  );
};

export default TeamsCompetitionsDetailsWinners;
