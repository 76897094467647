import RestService from '../services/rest';
import RoundsUtils from '../utils/rounds';
import PlayersUtils from '../utils/players';
import StatisticsUtil from '../utils/statistics';

async function getCurrentRound() {
  const round = await RestService.getAuthenticated('rounds/current');
  return RoundsUtils.completeRound(round);
}

async function getRound(number) {
  const round = await RestService.getAuthenticated(`rounds?number=${number}`);
  return RoundsUtils.completeRound(round);
}

async function getStatistics(number) {
  const data = await RestService.getAuthenticated(`rounds/statistics?number=${number}`);
  if (data.round) data.round = RoundsUtils.completeRound(data.round);

  data.statistics.bet_scores.games.forEach((game) => {
    game.accurate_users = game.accurate_users.map(user => PlayersUtils.completePlayer(user));
  });

  const top5s = StatisticsUtil.getAllTop(data.statistics);
  top5s.forEach((players) => {
    players.forEach((player) => {
      if (player.user) {
        player.user = PlayersUtils.completePlayer(player.user);
      }
    });
  });

  return data;
}

async function updateScore(data) {
  await RestService.postAuthenticated('rounds/update/score', data);
}

async function finishedRoundGames() {
  await RestService.postAuthenticated('rounds/update/finish');
}

async function getRoundCompetitions() {
  const competitions = await RestService.getAuthenticated('rounds/competitions?limit=1000');
  return competitions.map(competition => RoundsUtils.completeRoundCompetition(competition));
}

async function createRound(data) {
  await RestService.postAuthenticated('rounds/create', data);
}

async function updateRound(data) {
  await RestService.postAuthenticated('rounds/update', data);
}

export default {
  getCurrentRound,
  getRound,
  getStatistics,
  updateScore,
  finishedRoundGames,
  getRoundCompetitions,
  createRound,
  updateRound,
};
