import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PlayersUtils from '../../app/utils/players';

import LandingHeader from './header/header';
import LandingAbout from './about/about';
import LandingGameModes from './gameModes/gameModes';

export const LandingPage = ({ user }) => {
  if (user && user.username) {
    return <Redirect exact from="/" to={PlayersUtils.getLink(user.username)} />;
  }
  return (
    <React.Fragment>
      <LandingHeader />
      <LandingAbout />
      <LandingGameModes />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(LandingPage);
