import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';

import RcProgressLine from '../../../../elements/rcProgress/line';

import Colors from '../../../../../data/colors';

const PlayersDetailsOverviewStatisticsAchievements = (props) => {
  const { xpPerLevel, achievements } = props;
  const {
    position, level, trophies, xp, games,
  } = achievements;

  const xpBar = ((xp % xpPerLevel) / xpPerLevel) * 100;

  const typeOfficial = trophies.types.find(t => t.type === 'official');
  const titlesOfficials = typeOfficial ? typeOfficial.total : 0;

  return (
    <div className="achievements">
      <div className="title-line">
        {'Conquista'}
      </div>
      <div className="infos">
        {position && (
        <span>
          <FontAwesomeIcon icon={faSlackHash} />
          {' '}
          {position}
          {'º'}
        </span>
        )}
        <span>
          <b>
            {'LVL '}
          </b>
          {level || 1}
        </span>
        <span>
          <b>
            {'XP '}
          </b>
          {xp}
        </span>
        <span>
          <FontAwesomeIcon icon={faTrophy} />
          {' '}
          {trophies.total}
        </span>
      </div>
      <div className="progress">
        <RcProgressLine
          percent={xpBar}
          strokeWidth="5"
          trailWidth="5"
          strokeColor={Colors.layout.clouds}
          trailColor={Colors.layout.asbestos}
        />
      </div>
      <div className="categories">
        <div className="category">
          <div className="value">
            {trophies.xp}
            <span> XP</span>
          </div>
          <div className="label">Campeonatos</div>
          <div className="text">
            {titlesOfficials}
            {` Título${titlesOfficials === 1 ? '' : 's'} Oficia${titlesOfficials === 1 ? 'l' : 'is'}`}
          </div>
        </div>
        <div className="category">
          <div className="value">
            {games.xp}
            <span> XP</span>
          </div>
          <div className="label">Jogos Oficiais</div>
          <div className="text">
            {games.wins}
            {` Vitória${games.wins === 1 ? '' : 's'} | `}
            {games.draws}
            {` Empate${games.draws === 1 ? '' : 's'}`}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  xpPerLevel: state.settings.achievement && state.settings.achievement.level
   && state.settings.achievement.level.user && state.settings.achievement.level.user.xp,
});

export default connect(mapStateToProps)(PlayersDetailsOverviewStatisticsAchievements);
