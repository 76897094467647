import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

const TeamsCompetitionsPremiumsCardDetailsRewards = (props) => {
  const { rewards = [], hasPlayers } = props;

  let obs;

  const data = rewards.map((reward, index) => {
    const value = {
      first: index === 0,
      position: index + 1,
    };

    if (reward.currency) {
      value.text = `R$${reward.currency.amount}`;
      obs = 'O prêmio poderá ser sacado.';
    } else if (reward.products) {
      value.text = reward.products.name;
      obs = `Máximo R$${reward.products.max_amount},00.`;
    } else if (reward.chests) {
      value.text = `${reward.chests.amount} Baús`;
    }

    if (hasPlayers) obs = 'Cada jogador da equipe receberá o prêmio.';

    return value;
  });

  return (
    <div className="rewards">
      {data.map(reward => (
        <div key={reward.position} className={`positions${reward.first ? ' first' : ''}`}>
          {reward.first ? (
            <React.Fragment>
              <FontAwesomeIcon icon={faTrophy} />
              {' Campeão: '}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {reward.position}
              {'º Colocado: '}
            </React.Fragment>
          )}

          <span>
            {reward.text}
          </span>

        </div>
      ))}


      {obs && (
      <div className="obs">
        {obs}
      </div>
      )}
    </div>
  );
};

export default TeamsCompetitionsPremiumsCardDetailsRewards;
