import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import MultipleCarousel from '../../elements/multipleCarousel';

import RoundsData from '../../../data/rounds';

import HeaderContentRoundGame from './headerContentRoundGame';

export const HeaderContentRound = ({ currentRound }) => {
  const alert = currentRound && !currentRound.closed
    && moment(currentRound.date_bet_closure).diff(moment(), 'minutes') < RoundsData.alert_time.minutes;

  const firstDay = currentRound && moment(currentRound.date_bet_closure).endOf('day');

  if (!currentRound || !currentRound.games.length) {
    return (
      <div className="round">
        <div className="info">
          <div className="number">
            {'Rodada '}
            {currentRound && currentRound.number}
          </div>
          <div className="text closed right">
            {'Sem jogos'}
          </div>
        </div>
      </div>
    );
  }

  const items = currentRound.games.map(game => <HeaderContentRoundGame game={game} firstDay={firstDay} />);

  return (
    <div className="round">
      <div className="info">
        <div className="number">
          {'Rodada '}
          {currentRound.number}
        </div>
        {(currentRound.closed) ? (
          <div className="text closed">
            <NavLink
              activeClassName="selected"
              to="/ao-vivo"
            >
              {'Ao vivo'}
            </NavLink>

          </div>
        ) : (
          <div className="text">
            {'Fechamento'}
            <div className={alert ? 'alert' : ''}>
              {moment(currentRound.date_bet_closure).fromNow()}
            </div>
          </div>
        )}
      </div>
      <div className="games">
        <MultipleCarousel
          items={items}
          xs={5}
          sm={8}
          md={10}
          lg={10}
          arrows
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentRound: state.rounds.current,
  username: state.user.username,
  userAlertBet: state.rounds.numberClosed
  && !((state.user.bets && state.user.bets.current_made) || (state.rounds.isProgress)),
});

export default connect(mapStateToProps)(HeaderContentRound);
