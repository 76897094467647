import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import pageTitleService from '../../../app/services/pageTitle';

import { calendarsGetEvents } from '../../../app/store/actions/calendars';

import TeamsCalendarRound from './teamsCalendarRound';

export class TeamsCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const { calendarEvents } = this.props;

    pageTitleService.setTitle('Calendário');

    if (!calendarEvents) await this.props.getCalendarEvents();
    await this.createCalendar();
  }

  async createCalendar() {
    const { maxRounds, calendarEvents = {}, currentRoundNumber } = this.props;
    const { competitions, market, firstRound } = calendarEvents;

    const calendar = [];

    for (let i = 0; i < maxRounds; i += 1) {
      const roundNumber = i + firstRound;
      const data = {
        number: roundNumber,
        past: roundNumber < currentRoundNumber,
        current: roundNumber === currentRoundNumber,
      };

      calendar.push(data);
    }

    const competitionsKeys = Object.keys(competitions);

    competitionsKeys.forEach((competitionsKey) => {
      const editions = competitions[competitionsKey];
      editions.forEach((rounds) => {
        rounds.forEach((round, index) => {
          if (calendar[round - firstRound]) {
            calendar[round - firstRound].competition = {
              key: competitionsKey,
              round: index + 1,
            };
          }
        });
      });
    });

    market.forEach((round) => {
      calendar[round - firstRound].market = true;
    });

    await this.setState({ calendar });
  }

  render() {
    const { calendar } = this.state;
    const { gameMode } = this.props;

    if (!calendar) return (null);

    return (
      <div className="content-container margin-bottom team-calendar">
        <div className="title-center">
          {`Calendário de ${moment().year()}`}
        </div>
        <Grid fluid>
          <Row>
            {calendar.map(round => (
              <Col xs={6} sm={4} md={3} lg={2} key={round.number}>
                <TeamsCalendarRound round={round} gameMode={gameMode} />
              </Col>
            ))}
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCalendarEvents: () => dispatch(calendarsGetEvents(ownProps.modeData.mode)),
});

const mapStateToProps = (state, ownProps) => ({
  maxRounds: state.settings.calendar && state.settings.calendar.rounds,
  calendarEvents: state.calendars[ownProps.modeData.mode],
  currentRoundNumber: state.rounds.current && state.rounds.current.number,
  gameMode: ownProps.modeData.mode,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCalendar);
