import React from 'react';
import { connect } from 'react-redux';

export const LandingAbout = () => (
  <div className="landing-about">
    <div className="content-container">
      <div className="top">
        <div className="logo">
          <img src="/images/landing/logo.png" alt="Barbolão" />
        </div>
        <div className="title">
          {'Um jeito diferente de palpitar e testar seus conhecimentos no futebol!'}
        </div>
      </div>
      <div className="content">
        <p>
          {'O Barbolão é um site de entretenimento, desenvolvido com o intuito de oferecer '
          + 'divertimento aos seus usuários. Conta, basicamente, de apostas esportivas (palpites) '
          + 'do futebol Nacional e Internacional. Agora você pode fazer parte desta comunidade e '
          + 'testar seus conhecimentos de futebol, de uma forma diferente.'}
        </p>
        <p>
          {'Agora você vai poder disputar partidas e campeonatos individuais (Clube Fantasia), '
          + 'participará de um Clube Real (com outros 5 jogadores) e de uma Seleção (com outros '
          + '13 jogadores).'}
        </p>
        <p>
          {'Temos participantes de praticamente todos os estados brasileiros, mais alguns '
          + 'palpiteiros que moram no exterior (Europa e América do Norte). Traga mais parentes '
          + 'e amigos para participar de nossa comunidade. Nosso grupo é bastante coeso e aqui '
          + 'se formam grandes amizades.'}
        </p>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(LandingAbout);
