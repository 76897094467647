import React from 'react';
import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import scroll from './scroll';

import pageTitleService from '../app/services/pageTitle';

import Idle from '../components/elements/idle';
import Maintenance from '../components/elements/maintenance';
import Loading from '../components/elements/loading';
import Alert from '../components/elements/alert';
import Content from '../components/content/content';
import Footer from '../components/footer/footer';

export const history = createHistory();
export const scrollTo = scroll;

history.listen((location) => {
  pageTitleService.setTitle();
  ReactGA.pageview(location.pathname);
});

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Idle />
      <Maintenance />
      <Loading />
      <Content />
      <Footer />
      <Alert />
    </div>
  </Router>
);

export default AppRouter;
