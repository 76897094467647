import React from 'react';

import Team from '../../../../elements/team';

import colorsData from '../../../../../../data/colors';
import betsData from '../../../../../../data/bets';

import TeamsGamesBetsCardHeader from './elements/header';
import TeamsGamesBetsCardInformation from './elements/information';
import TeamsGamesBetsCardInformationMobile from './elements/informationMobile';
import TeamsGamesBetsCardDetails from './elements/details';

const TeamsGamesBetsCardGames = (props) => {
  const {
    game, teamHome = {}, teamAway = {},
  } = props;

  const homeGameResult = game.score_home;
  const awayGameResult = game.score_away;

  const homeBet = (teamHome.bets && teamHome.bets.games[game.number]) || {};
  const awayBet = (teamAway.bets && teamAway.bets.games[game.number]) || {};

  const homeGoals = homeBet.score || 0;
  const awayGoals = awayBet.score || 0;

  const setScore = (homeValue, awayValue) => `${homeValue !== undefined
    ? homeValue : ''}-${awayValue !== undefined ? awayValue : ''}`;

  const details = [{
    key: game.number,
    homeName: <Team data={teamHome} type="text" />,
    awayName: <Team data={teamAway} type="text" />,
    result: setScore(homeGoals, awayGoals),
    homeValue: setScore(homeBet.score_home, homeBet.score_away),
    awayValue: setScore(awayBet.score_home, awayBet.score_away),
    homeColor: homeBet.score && colorsData.layout[betsData.colors[homeBet.score.toString()]],
    awayColor: awayBet.score && colorsData.layout[betsData.colors[awayBet.score.toString()]],
    homeGoals,
    awayGoals,
  }];

  return (
    <div className="game-bets-card">
      <TeamsGamesBetsCardHeader title={`Jogo ${game.number + 1}`} />
      <TeamsGamesBetsCardInformation
        home={<Team data={game.team_home} type="gameBet" />}
        away={<Team data={game.team_away} type="gameBet" />}
        homeScore={homeGameResult}
        awayScore={awayGameResult}
        inProgress={game.status === 'in_progress'}
      />
      <TeamsGamesBetsCardInformationMobile
        home={game.team_home.name}
        away={game.team_away.name}
      />
      <TeamsGamesBetsCardDetails lines={details} />
    </div>
  );
};

export default TeamsGamesBetsCardGames;
