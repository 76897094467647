export default (state = {}, action) => {
  switch (action.type) {
    case 'CART_ADD':
      return {
        type: action.cartType,
        item: action.item,
      };
    case 'CART_REMOVE':
      return {};
    default:
      return state;
  }
};
