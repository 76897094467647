import React from 'react';
import { ResponsiveContainer, LineChart, Line } from 'recharts';

import chartData from '../../../data/charts';

const TyneLineChart = (props) => {
  const { data, lines, colors } = props;
  const chartColors = colors || chartData.line.colors;

  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
      >
        {Object.keys(lines).map((lineKey, index) => (
          <Line
            key={lineKey}
            type="monotone"
            dataKey={lineKey}
            stroke={chartColors[index % chartColors.length]}
            strokeWidth={2}
            dot={false}
            isAnimationActive
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TyneLineChart;
