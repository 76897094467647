import React from 'react';

import TeamsDetailsOverviewBadgesItem from './item';
import TeamsDetailsOverviewBadgesCarousel from './carousel';

import CompetitionsUtils from '../../../../../app/utils/competitions';

import translator from '../../../../../data/translator';

const TeamsDetailsOverviewBadgesAchievements = (props) => {
  const { achievements = [] } = props;
  const { triumphs = [] } = achievements;

  const champions = triumphs.filter(triumph => triumph.category === 'champion').reverse();

  const items = champions.map(champion => (
    <TeamsDetailsOverviewBadgesItem
      link={CompetitionsUtils.getLink(champion.competition)}
      logo={champion.competition.logo && champion.competition.logo.url}
      name={champion.competition.name}
      infos={{
        league: translator.s.competitions[champion.competition.type],
        xp: champion.xp,
      }}
    />
  ));

  return (
    <TeamsDetailsOverviewBadgesCarousel
      title="Títulos"
      noneText="Sem Títulos"
      items={items}
    />
  );
};

export default TeamsDetailsOverviewBadgesAchievements;
