import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
} from '@fortawesome/free-solid-svg-icons';

export const HeaderTopPrivateSearchMobile = () => (
  <button type="button" className="show-for-mobile button button--link">
    <FontAwesomeIcon icon={faSearch} />
  </button>
);

export default HeaderTopPrivateSearchMobile;
