import React from 'react';
import { connect } from 'react-redux';

import AdministrationScoreEmpty from './administrationScoreEmpty';
import AdministrationScoreGames from './administrationScoreGames';

const AdministrationScore = ({ currentRound }) => {
  const isAvailable = currentRound && currentRound.closed
     && !currentRound.finished_games && !currentRound.finished;

  return (
    <div className="player-bets">
      <div className="content-container margin-bottom">
        <div className="title-center-menu">
          {'Atualização de Jogos'}
        </div>
        {isAvailable ? <AdministrationScoreGames /> : <AdministrationScoreEmpty /> }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentRound: state.rounds.current,
});

export default connect(mapStateToProps)(AdministrationScore);
