import React from 'react';

import Colors from '../../../../../data/colors';

export const CampaignInProgressTeamInformationPaused = (props) => {
  const { team } = props;

  const color = Colors.teams.background[team.colors[0]];

  const style = {
    background: `${color}E3`,
  };

  return (
    <div className="info paused">
      <button type="button" className="button normal" style={style} onClick={props.onStart}>
        {'Iniciar Jornada'}
      </button>
    </div>
  );
};

export default CampaignInProgressTeamInformationPaused;
