import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import CampaignInvitationsListItem from './campaignInvitationsListItem';

const CampaignInvitationsList = props => (
  <Grid fluid className="competitions-closed">
    <Row>
      {props.invitations.map(invitation => (
        <Col sm={6} md={4} lg={3} key={invitation.id}>
          <CampaignInvitationsListItem
            invitation={invitation}
            maxUsers={props.maxUsers}
            reject={props.reject}
            accept={props.accept}
          />
        </Col>
      ))}
    </Row>
  </Grid>
);

export default CampaignInvitationsList;
