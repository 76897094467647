import React from 'react';
import { connect } from 'react-redux';

import { accountUpdatePicture } from '../../../app/store/actions/account';

import AccountPlayerPictureForm from '../../account/player/pictureForm';

import TutorialWrapper from '../../elements/tutorial/tutorial';

export class TutorialAccountPlayer extends React.Component {
  updatePicture = async (file) => {
    await this.props.updatePicture(file);
  }

  render() {
    return (
      <TutorialWrapper image="account/image_2.jpg">
        <h3 className="title-center">
          {'Foto'}
        </h3>
        <p>
          {'Você pode selecionar uma foto ou imagem que te identifique. Caso não queira, basta clicar em Continuar.'}
        </p>
        <AccountPlayerPictureForm updatePicture={this.updatePicture} />
        <button className="button normal" type="button" onClick={this.props.previousStep}>
          {'Voltar'}
        </button>
        <button className="button normal" type="button" onClick={this.props.nextStep}>
          {'Continuar'}
        </button>
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updatePicture: file => dispatch(accountUpdatePicture(file)),
});

export default connect(undefined, mapDispatchToProps)(TutorialAccountPlayer);
