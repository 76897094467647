import React from 'react';

import TeamsCompetitionsPremiumsCardInfo from './info';
import TeamsCompetitionsPremiumsCardDetails from './details';
import TeamsCompetitionsPremiumsCardButtons from './buttons';

const TeamsCompetitionsPremiumsCard = (props) => {
  const {
    competition = {}, userTeam, userIsPrime, hasPlayers,
  } = props;

  return (
    <div className="competitions-premiums-card">
      <TeamsCompetitionsPremiumsCardInfo
        name={competition.name}
        number={competition.max_teams - competition.teams.length}
      />
      <TeamsCompetitionsPremiumsCardDetails
        rewards={competition.reward}
        competition={competition.competition}
        teams={competition.teams}
        maxTeams={competition.max_teams}
        hasPlayers={hasPlayers}
      />
      <TeamsCompetitionsPremiumsCardButtons
        teams={competition.teams}
        price={competition.price}
        discount={competition.discount}
        userTeam={userTeam}
        userIsPrime={userIsPrime}
        selectPremium={props.selectPremium}
      />
    </div>
  );
};

export default TeamsCompetitionsPremiumsCard;
