import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { campaignsStart } from '../../../../app/store/actions/campaigns';

import CampaignInProgressSettingsInvitations from './invitations/invitations';

import CampaignInProgressTeam from '../team/team';

export class CampaignInProgressSettings extends React.Component {
  startCampaign = async () => {
    const { campaign } = this.props;
    await this.props.startCampaign(campaign.id);
  }

  render() {
    const {
      isOwner, isFull, maxUsers, campaign,
    } = this.props;

    const canSearch = isOwner && !isFull;

    return (
      <div className="content-container">
        <Grid fluid style={{ padding: 0 }}>
          <Row>
            <Col md={canSearch ? 6 : 12}>
              <CampaignInProgressTeam
                isOwner={isOwner}
                campaign={campaign}
                start={this.startCampaign}
                maxUsers={maxUsers}
              />
            </Col>
            {canSearch && (
              <Col md={6}>
                <CampaignInProgressSettingsInvitations
                  campaign={campaign}
                />
              </Col>
            ) }
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const campaignUsers = ownProps.campaign && ownProps.campaign.users;

  return {
    isOwner: (campaignUsers[0] && campaignUsers[0].id) === state.user.id,
    isFull: campaignUsers && state.settings.campaigns.max_users === campaignUsers.length,
    maxUsers: campaignUsers && state.settings.campaigns.max_users,
  };
};

const mapDispatchToProps = dispatch => ({
  startCampaign: campaignId => dispatch(campaignsStart(campaignId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInProgressSettings);
