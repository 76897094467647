import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import GameModeInitiationActivation from './activation/gameModeInitiationActivation';

import CampaignCreation from '../campaign/creation/campaignCreation';
import CampaignInvitations from '../campaign/invitations/campaignInvitations';
import CampaignList from '../campaign/list/campaignList';
import CampaignHistoric from '../campaign/historic/campaignHistoric';
import CampaignDetails from '../campaign/details/campaignDetails';
import CampaignInProgress from '../campaign/inProgress/campaignInProgress';

const GameModeCampaign = (props) => {
  const { numberClosed, match } = props;
  const { url } = match;

  return (
    <div>
      {numberClosed && (
      <Switch>
        <Route
          path={`${url}/introducao`}
          component={() => <GameModeInitiationActivation modeData={props.modeData} />}
        />
        <Route path={`${url}/criacao`} component={() => <CampaignCreation modeData={props.modeData} />} />
        <Route path={`${url}/convites`} component={() => <CampaignInvitations modeData={props.modeData} />} />
        <Route path={`${url}/lista`} component={() => <CampaignList modeData={props.modeData} />} />
        <Route path={`${url}/historico`} component={() => <CampaignHistoric modeData={props.modeData} />} exact />
        <Route path={`${url}/historico/:id`} component={p => <CampaignDetails {...p} modeData={props.modeData} />} />
        <Route path={`${url}/:name`} component={p => <CampaignInProgress {...p} modeData={props.modeData} />} />
      </Switch>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  numberClosed: state.rounds.numberClosed,
});

export default connect(mapStateToProps)(GameModeCampaign);
