import React from 'react';

import translator from '../../../../../../data/translator';

import Table from '../../../../../elements/table';

const TeamsStatisticsConfrontationGamesTypes = (props) => {
  const { teamHome, teamAway, results = {} } = props;

  const gamesHeader = [
    { key: 'type', value: 'Tipo de Jogos', tdClassName: 'high name' },
    { key: 'team_home', value: teamHome.team.name },
    { key: 'team_away', value: teamAway.team.name },
    { key: 'draws', value: 'Empates' },
  ];

  const types = Object.keys(results);

  const gamesData = types.map(type => ({
    key: type,
    type: translator.p.competitions[type],
    team_home: results[type].score_home,
    team_away: results[type].score_away,
    draws: results[type].games - (results[type].score_home + results[type].score_away),
  }));

  const lineClassNames = {
    team_home: [],
    team_away: [],
    draws: [],
  };

  gamesData.forEach((row, index) => {
    const max = Math.max(row.team_home, row.team_away, row.draws);
    if (max === row.team_home) lineClassNames.team_home[index] = { className: 'high' };
    if (max === row.team_away) lineClassNames.team_away[index] = { className: 'high' };
    if (max === row.draws) lineClassNames.draws[index] = { className: 'high' };
  });

  return (
    <Table header={gamesHeader} lines={gamesData} lineClassNames={lineClassNames} />
  );
};

export default TeamsStatisticsConfrontationGamesTypes;
