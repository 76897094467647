import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsGamesDetailsHeaderTitle from './title';
import TeamsGamesDetailsHeaderBadge from './badge';
import TeamsGamesDetailsHeaderTeam from './team';
import TeamsGamesDetailsHeaderScore from './score';
import TeamsGamesDetailsHeaderBottom from './bottom';

const TeamsGamesDetailsHeader = (props) => {
  const {
    title, subTitle,
    scoreHome, scoreAway,
    teamHome = {}, teamAway = {},
    teamHomeBottom, teamAwayBottom,
  } = props;

  return (
    <div className="game-header">
      <div className="content-container">
        <TeamsGamesDetailsHeaderTitle title={title} subTitle={subTitle} />
        <Grid fluid>
          <Row>
            <Col md={4} sm={12}>
              <div className="game-team game-left">
                <TeamsGamesDetailsHeaderBadge team={teamHome} />
                <TeamsGamesDetailsHeaderTeam team={teamHome} />
              </div>
            </Col>
            <Col md={4} sm={12}>
              <TeamsGamesDetailsHeaderScore scoreHome={scoreHome} scoreAway={scoreAway} />
            </Col>
            <Col md={4} sm={12}>
              <div className="game-team game-right">
                <TeamsGamesDetailsHeaderTeam team={teamAway} />
                <TeamsGamesDetailsHeaderBadge team={teamAway} />
              </div>
            </Col>
          </Row>
        </Grid>
        <div className="game-bottom">
          <TeamsGamesDetailsHeaderBottom teamName={teamHome.name} text={teamHomeBottom} />
          <TeamsGamesDetailsHeaderBottom teamName={teamAway.name} text={teamAwayBottom} />
        </div>
      </div>
    </div>
  );
};

export default TeamsGamesDetailsHeader;
