import React from 'react';

const PlayersDetailsBetsRoundTeam = ({ team, type }) => (
  <div className={`team ${type}`}>
    <img src={team.badge.url} alt={team.name} />
    <span>
      {team.name}
    </span>
  </div>
);

export default PlayersDetailsBetsRoundTeam;
