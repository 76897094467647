import React from 'react';

import Table from '../../elements/table';
import Team from '../../teams/elements/team';

export const RealTeamSearchTable = (props) => {
  const { teams } = props;

  const header = [
    { key: 'team', value: 'Clubes', className: 'name' },
    { key: 'options', className: 'number', tdClassName: 'tb-button' },
  ];

  const data = teams.map(team => ({
    key: team.id,
    team: <Team data={team} type="table" />,
    options: (
      <button className="button" type="button" onClick={() => props.selectTeam(team)}>
        {'Selecionar'}
      </button>
    ),
  }));

  return (<Table header={header} lines={data} />);
};

export default RealTeamSearchTable;
