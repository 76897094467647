export default (state = {}, action) => {
  switch (action.type) {
    case 'TRANSACTIONS_SET':
      return {
        ...state,
        list: action.transactions,
      };
    case 'TRANSACTIONS_CLEAN':
      return {};
    default:
      return state;
  }
};
