import React from 'react';

export const HeaderTopPrivateSearchDesktop = props => (
  <input
    className={`show-for-desktop ${props.className || ''}`}
    type="text"
    name="search"
    placeholder="Buscar..."
    autoComplete="off"
    value={props.searchText}
    onChange={props.onChange}
    onBlur={props.onBlur}
  />
);

export default HeaderTopPrivateSearchDesktop;
