import React from 'react';

import PlayersUtils from '../../../../../../app/utils/players';

import Player from '../../../../../players/elements/player';

import colorsData from '../../../../../../data/colors';
import betsData from '../../../../../../data/bets';

import TeamsGamesBetsCardHeader from './elements/header';
import TeamsGamesBetsCardInformation from './elements/information';
import TeamsGamesBetsCardInformationMobile from './elements/informationMobile';
import TeamsGamesBetsCardDetails from './elements/details';

const TeamsGamesBetsCardMarket = (props) => {
  const {
    title, round, games, homeBets = {}, awayBets = {}, goalsAbsolute,
  } = props;

  const homeScore = homeBets.score || 0;
  const awayScore = awayBets.score || 0;

  const homeUser = homeBets.user ? homeBets.user : PlayersUtils.completePlayer();
  const awayUser = awayBets.user ? awayBets.user : PlayersUtils.completePlayer();

  const homeGoals = goalsAbsolute ? homeScore : homeScore - awayScore;
  const awayGoals = goalsAbsolute ? awayScore : awayScore - homeScore;

  const details = games.map((number) => {
    const game = round.games[number];
    const homeBet = (homeBets.bet && homeBets.bet.games[number]) || {};
    const awayBet = (awayBets.bet && awayBets.bet.games[number]) || {};

    const setScore = (homeValue, awayValue) => `${homeValue !== undefined
      ? homeValue : ''}-${awayValue !== undefined ? awayValue : ''}`;

    return {
      key: number,
      homeName: game.team_home.name,
      awayName: game.team_away.name,
      result: setScore(game.score_home, game.score_away),
      inProgress: game.status === 'in_progress',
      homeValue: setScore(homeBet.score_home, homeBet.score_away),
      awayValue: setScore(awayBet.score_home, awayBet.score_away),
      homeColor: homeBet.score && colorsData.layout[betsData.colors[homeBet.score.toString()]],
      awayColor: awayBet.score && colorsData.layout[betsData.colors[awayBet.score.toString()]],
    };
  });

  return (
    <div className="game-bets-card">
      <TeamsGamesBetsCardHeader title={title} />
      <TeamsGamesBetsCardInformation
        home={<Player data={homeUser} type="gameBet" />}
        away={<Player data={awayUser} type="gameBet" />}
        homeScore={homeScore}
        awayScore={awayScore}
        homeGoals={homeGoals}
        awayGoals={awayGoals}
      />
      <TeamsGamesBetsCardInformationMobile
        home={<Player data={homeUser} type="text" />}
        away={<Player data={awayUser} type="text" />}
        homeGoals={homeGoals}
        awayGoals={awayGoals}
      />
      <TeamsGamesBetsCardDetails lines={details} />
    </div>
  );
};

export default TeamsGamesBetsCardMarket;
