import React from 'react';
import { NavLink } from 'react-router-dom';

export const headerBottomPublic = () => (
  <div className="public-buttons">
    <NavLink className="button" to="/">
      {'Entrar'}
    </NavLink>
    <NavLink
      className="button register"
      activeClassName="selected"
      to="/cadastro"
    >
      {'Cadastrar'}
    </NavLink>
  </div>
);

export default headerBottomPublic;
