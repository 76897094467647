import TeamsUtils from './teams';
import PlayersUtils from './players';
import GamesUtils from './games';

import colorsData from '../../data/colors';
import competitionsData from '../../data/competitions';
import translator from '../../data/translator';

const CompetitionModel = {};

function getName(competition = {}) {
  const { name, league, priority } = competition;
  if (name) return name;
  if (league && priority) return `${league} ${priority}`;
}

function getGroupName(group = {}) {
  if (group.stage === 'group') return `Grupo ${String.fromCharCode(65 + group.order)}`;
  if (group.stage === 'repechage') return `Repescagem ${String.fromCharCode(65 + group.order)}`;
  return 'Final';
}

function getLogo(competition) {
  let url;
  switch (competition.type) {
    case 'custom':
      url = '/images/competitions/custom.png';
      break;
    case 'premium':
      url = '/images/competitions/premium.png';
      break;
    case 'vip':
      url = '/images/competitions/vip.png';
      break;
    case 'ranked':
      url = `/images/ranked/${competition.priority}.png`;
      break;
    default:
      url = '/images/competitions/none.png';
  }
  return { url };
}

function completeCompetition(competition = {}) {
  competition = { ...CompetitionModel, ...competition };
  competition.name = getName(competition);

  if (!competition.logo) competition.logo = getLogo(competition);

  if (competition.status === 'finished' && competition.winners) {
    competition.winners.champion = TeamsUtils.completeTeam(competition.winners.champion);
    competition.winners.second_place = TeamsUtils.completeTeam(competition.winners.second_place);
    competition.winners.third_place = TeamsUtils.completeTeam(competition.winners.third_place);
    competition.winners.strikers.players = competition.winners.strikers.players.map(player => (
      PlayersUtils.completePlayer(player)
    ));
  } else {
    delete competition.winners;
  }

  return competition;
}

function completeGroup(group = {}) {
  const gameRoundKeys = Object.keys(group.games || {});
  gameRoundKeys.forEach((gameRound) => {
    group.games[gameRound].games = group.games[gameRound].games.map(game => GamesUtils.completeGame(game));
  });

  if (group.classificatory) {
    group.classificatory.forEach((team) => {
      team.team = TeamsUtils.completeTeam(team.team);
    });
  }

  if (group.contest) {
    group.contest.forEach((team) => {
      team.team = TeamsUtils.completeTeam(team.team);
    });
  }

  if (group.ranked) {
    group.ranked.forEach((team) => {
      team.team = TeamsUtils.completeTeam(team.team);
    });
  }

  if (group.play_off) {
    group.play_off.forEach((playOff) => {
      playOff.teams.forEach((team) => {
        team.team = TeamsUtils.completeTeam(team.team);
        if (!team.penalties) team.penalties = {};
      });

      playOff.games = playOff.games.map((game) => {
        if (!game.team_home) game.team_home = TeamsUtils.completeTeam();
        else if (game.team_home === playOff.teams[0].team.id) game.team_home = playOff.teams[0].team;
        else game.team_home = playOff.teams[1].team;

        if (!game.team_away) game.team_away = TeamsUtils.completeTeam();
        else if (game.team_away === playOff.teams[0].team.id) game.team_away = playOff.teams[0].team;
        else game.team_away = playOff.teams[1].team;

        return GamesUtils.completeGame(game);
      });
    });
  }

  return group;
}

function getTypeColor(type) {
  return colorsData.layout[competitionsData.typeColors[type]];
}

function getLeagueColor(league) {
  if (league) return colorsData.teams.background[competitionsData.leagueColors[league]];
  return colorsData.teams.background.gray;
}

function getCompetitionColor(competition = {}) {
  switch (competition.type) {
    case 'official': return getLeagueColor(competition.league);
    case 'custom': return colorsData.teams.background.gray;
    case 'premium': return colorsData.teams.background.orange;
    case 'vip': return colorsData.teams.background.yellow;
    case 'ranked': return colorsData.teams.background.purple;
    default: return colorsData.teams.background.gray;
  }
}

function getPositionColor(position) {
  return colorsData.layout[competitionsData.positionsColors[
    Math.min(position - 1, competitionsData.positionsColors.length - 1)]
  ];
}

function getEditionNameText(edition, year) {
  return (edition ? `${edition}ª Edição de ` : '') + year;
}

function getLink(competition = {}, groupId) {
  return `/${translator.uri.gameModes[competition.mode_type]}/campeonatos/`
    + `${competition.id}${groupId ? `/detalhes/${groupId}` : ''}`;
}

function getPremiumsLink(gameMode) {
  return `/${translator.uri.gameModes[gameMode]}/campeonatos/premiums-inscricao`;
}

function getStagePosition(positionName) {
  return {
    name: translator.s.stagePosition[positionName],
    className: competitionsData.stagePosition[positionName],
  };
}

function getStageByPosition(stagePositions) {
  const positionInfo = {};

  stagePositions.forEach((stage) => {
    for (let i = stage.initial_position; i < stage.initial_position + stage.total_position; i += 1) {
      if (!positionInfo[i]) {
        positionInfo[i] = getStagePosition(stage.type);
      } else {
        positionInfo[i].name += ` e ${getStagePosition(stage.type).name}`;
      }
    }
  });

  return positionInfo;
}

function getReward(competition) {
  const titles = ['Campeão', 'Vice Campeão', 'Terceiro Colocado'];
  if (competition.reward && competition.reward.length > 0) {
    return competition.reward.map((reward, index) => {
      let text = '';

      if (reward.chests) text += `${reward.chests.amount} baú${reward.chests.amount > 0 ? 's' : ''}. `;
      if (reward.currency) text += `$${reward.currency.amount}. `;
      if (reward.products) text += `${reward.products.name}. (Máximo: R$${reward.products.max_amount},00)`;

      return {
        title: titles[index],
        text,
      };
    });
  }
}

function getPhasesTieBreaker(competition, priority) {
  const phasesTieBreaker = {};
  competition.phases.forEach((phase) => {
    let title = '';
    const lines = [];

    switch (phase.type) {
      case 'ranked':
        title = 'Desempate no Grupo';
        lines.push('Pontos');
        lines.push('Vitórias');
        lines.push('Saldo de gols');
        lines.push('Gols prós');
        lines.push('Ranking de Descarte');
        break;
      case 'classificatory':
        title = 'Desempate no Grupo';
        lines.push('Pontos');
        lines.push('Vitórias');
        lines.push('Saldo de gols');
        lines.push('Gols prós');
        if (priority) lines.push(priority);
        else lines.push('Ordem de inscrição');
        break;
      case 'play_off':
        title = 'Desempate no Mata-mata';
        lines.push('Soma dos placares dos jogos');
        lines.push('Pênaltis. Verificar no regulamento como funciona.');
        break;
      case 'contest':
        title = 'Desempate na Pontuação';
        lines.push('Pontos');
        lines.push('Ranking de Apostas');
        break;
      default:
    }

    phasesTieBreaker[phase.phase] = {
      title,
      lines,
    };
  });
  return Object.keys(phasesTieBreaker).map(phase => phasesTieBreaker[phase]);
}

function getPlayOffPhaseName(index, totalPhases) {
  const playoffPhases = [
    'Final',
    'Semifinais',
    'Quartas de final',
    'Oitavas de final',
  ];

  const diff = totalPhases - (index + 1);
  if (diff < playoffPhases.length) return playoffPhases[diff];
  return `${index + 1}° Fase`;
}

export default {
  getName,
  getGroupName,
  completeCompetition,
  completeGroup,
  getTypeColor,
  getLeagueColor,
  getCompetitionColor,
  getPositionColor,
  getEditionNameText,
  getLink,
  getPremiumsLink,
  getStagePosition,
  getStageByPosition,
  getReward,
  getPhasesTieBreaker,
  getPlayOffPhaseName,
};
