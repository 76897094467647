import RoundsUtils from './rounds';

function completeRoundBet(bets = {}) {
  RoundsUtils.addNumberInGames(bets);
  return bets;
}

export default {
  completeRoundBet,
};
