import CollectiblesRequests from '../../server/collectibles';
import { addLoading, removeLoading } from './loading';
import { addCollectible, userUpdateNotification } from './user';

export const setBasicItems = items => ({
  type: 'COLLECTIBLES_BASIC_ITEMS_SET',
  items,
});

export const setUserItems = (collectionType, items) => ({
  type: 'COLLECTIBLES_USER_ITEMS_SET',
  collectionType,
  items,
});

export const setChests = chests => ({
  type: 'COLLECTIBLES_CHESTS_SET',
  chests,
});

export const setPacks = packs => ({
  type: 'COLLECTIBLES_PACKS_SET',
  packs,
});

export const setChestItems = (chestId, items) => ({
  type: 'COLLECTIBLES_CHEST_ITEMS_SET',
  chestId,
  items,
});

export const collectiblesGetBasicItems = () => async (dispatch, getState) => {
  const { basic } = getState().collectibles;

  if (!basic) {
    dispatch(addLoading());

    try {
      const items = await CollectiblesRequests.getBasicItems();
      dispatch(setBasicItems(items));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const collectiblesGetUserItems = type => async (dispatch) => {
  dispatch(addLoading());

  try {
    const items = await CollectiblesRequests.getUserItems(type);
    dispatch(setUserItems(type, items));
  } finally {
    dispatch(removeLoading());
  }
};

export const collectiblesGetChests = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    const data = await CollectiblesRequests.getChests();
    dispatch(setChests(data.chests));
    dispatch(setPacks(data.packs));
  } finally {
    dispatch(removeLoading());
  }
};

export const collectiblesUpdateChests = force => async (dispatch, getState) => {
  const { chests } = getState().collectibles;
  if (force || !chests) await dispatch(collectiblesGetChests());
};


export const collectiblesGetChestItems = chestId => async (dispatch) => {
  dispatch(addLoading());

  try {
    const items = await CollectiblesRequests.getChestItems(chestId);
    dispatch(setChestItems(chestId, items));
  } finally {
    dispatch(removeLoading());
  }
};

export const collectiblesUpdateChestItems = (chestName, force) => async (dispatch, getState) => {
  const { chests } = getState().collectibles;
  if (chests && chests[chestName]) {
    if (force || !chests[chestName].items) {
      const chestId = chests[chestName].id;
      await dispatch(collectiblesGetChestItems(chestId));
    }
  }
};

export const collectiblesOpenChest = chestId => async (dispatch, getState) => {
  dispatch(addLoading());

  try {
    const collectible = await CollectiblesRequests.openChest(chestId);
    dispatch(addCollectible(collectible.id, 1));
    dispatch(addCollectible(chestId, -1));
    const { notifications } = getState().user;
    dispatch(userUpdateNotification('collectibles', notifications.collectibles - 1));
    return collectible;
  } finally {
    dispatch(removeLoading());
  }
};
