import React from 'react';
import { connect } from 'react-redux';

import { teamsStatisticsGetStrikers } from '../../../../app/store/actions/teamsStatistics';

import Player from '../../../players/elements/player';
import RankingTablePositionChange from '../../../elements/ranking/tablePositionChange';

import Ranking from '../../../elements/statistics/ranking';

const TeamsStatisticsStrikers = (props) => {
  const { players, totalPages, match } = props;

  const tableHeader = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'player', value: 'Artilharia', className: 'name', colspan: 3,
    },
    { key: 'pos_change', tdClassName: 'pos-change', hidden: true },
    { key: 'goals', value: 'Gols Oficiais', tdClassName: 'high' },
    { key: 'friendly_goals', value: 'Gols em Amistosos' },
    { key: 'total', value: 'Rodadas' },
  ];

  const tableLines = players.map(row => ({
    key: row.user.id,
    position: row.position,
    player: <Player data={row.user} type="table" />,
    pos_change: <RankingTablePositionChange value={row.position_last - row.position} />,
    goals: row.value,
    friendly_goals: row.friendly_value,
    total: row.total,
  }));

  return (
    <div className="content-container margin-bottom">
      <div className="title-center-menu">
        {'Artilharia'}
      </div>
      <Ranking
        match={match}
        totalPages={totalPages}
        tableHeader={tableHeader}
        tableLines={tableLines}
        getRanking={props.getStrikers}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getStrikers: (interval, page) => dispatch(teamsStatisticsGetStrikers(ownProps.modeData.mode, interval, page)),
});

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.match.params.page || 1;
  const { mode } = ownProps.modeData;

  const ranking = state.teamsStatistics.strikers[mode];
  return {
    players: (ranking && ranking.data[page]) || [],
    totalPages: (ranking && ranking.total) || 0,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatisticsStrikers);
