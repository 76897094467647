import React from 'react';

import Iframe from '../iframe';

export class TeamsDetailsManagementPortal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: true,
      expand: false,
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.props.updateValue(value);
  }

  render() {
    const { preview, expand } = this.state;
    const { value } = this.props;

    const previewClass = `for-panel${!preview ? ' for-preview-hidden' : ''}`;
    const editorClass = 'for-panel';
    const fullscreen = `for-container${expand ? ' for-fullscreen' : ''}`;

    return (
      <div className="editor-html">
        <div className={fullscreen}>
          <div className="for-editor">
            <div className={editorClass}>
              <div className="for-editor-wrapper">
                <div className="for-editor-block">
                  <div className="for-editor-content">
                    <pre>
                      {value}
                    </pre>
                    <textarea
                      ref={this.handleEditorRef}
                      value={value}
                      onChange={this.handleChange}
                      placeholder="Digite seu código html"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={previewClass}>
              <div className="for-preview for-markdown-preview">
                <Iframe content={value} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamsDetailsManagementPortal;
