import RestService from '../services/rest';

async function send(name, email, subject, message) {
  return RestService.postRest('contact', {
    name,
    email,
    subject,
    message,
  });
}

export default {
  send,
};
