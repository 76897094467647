import React from 'react';

import translator from '../../../../data/translator';
import CompetitionsUtils from '../../../../app/utils/competitions';

import BadgeListItem from '../../../elements/badgeList/badgeList';
import Tabs from '../../../elements/tabs/tabs';

export class TeamsDetailsCompetitionsItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: props.types[0],
      types: props.types,
    };
  }

  changeType = async (type) => {
    await this.setState({ current: type });
  }

  render() {
    const { types, current } = this.state;
    const { competitions } = this.props;
    const competitionsTypes = competitions[current] || [];

    const items = competitionsTypes.map(({ details, status }) => ({
      id: details.id,
      color: CompetitionsUtils.getCompetitionColor(details),
      name: details.name,
      tag: {
        name: `${status.position}º Colocado`,
        color: CompetitionsUtils.getPositionColor(status.position),
        nameHover: CompetitionsUtils.getEditionNameText(details.edition, details.year),
        colorHover: CompetitionsUtils.getCompetitionColor(details),
      },
      badge: details.logo.url,
      locked: false,
      mark: details.xp ? details.xp : undefined,
      link: CompetitionsUtils.getLink(details, status.group),
    }));

    return (
      <div className="competitions-items">
        <Tabs
          items={types.map(type => ({
            name: translator.p.competitions[type],
            onClick: () => this.changeType(type),
            active: type === current,
          }))}
        />
        {items.length === 0 && <div className="competitions-empty">Sem campeonatos no momento</div>}
        <BadgeListItem
          items={items}
          gridInfo={{
            lg: 2, md: 3, sm: 4, xs: 6,
          }}
          center
        />
      </div>
    );
  }
}

export default TeamsDetailsCompetitionsItems;
