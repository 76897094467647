import PlayersUtils from './players';
import CompetitionsUtils from './competitions';
import RealTeamsUtils from './realTeams';

import CampaignsData from '../../data/campaigns';
import TranslatorData from '../../data/translator';

const CampaignModel = {
  phases: [],
};

const CampaignTypeModel = {
  name: 'Indefinido',
  logo: {
    url: '/images/campaign_type_placeholder.png',
  },
};

function userTypesAchievements(triumphs) {
  const campaignTypes = triumphs.filter(t => t.campaign_type).map(t => t.campaign_type);
  return campaignTypes;
}

function getblockValue(tag, extra = {}) {
  return {
    tag,
    color: CampaignsData.block_colors[tag],
    name: TranslatorData.s.campaignBlock[tag],
    ...extra,
  };
}

function blockDetails(type, player = {}) {
  const history = ((player.achievements && player.achievements.triumphs) || [])
    .filter(triumph => triumph.mode_type === 'campaign')
    .map(triumph => triumph.campaign.type);

  if (history.find(h => h === type.id)) return getblockValue('winner');

  if (type.subscription_required === true && !PlayersUtils.isPrime(player)) return getblockValue('prime');
  const requirements = (type.requirement || []).map(reqGroup => reqGroup.map(req => req.id));

  const block = [];
  requirements.forEach((reqGroup) => {
    const found = reqGroup.find(req => !!history.find(h => h === req));
    if (!found && reqGroup.length) block.push(reqGroup[0]);
  });

  if (block.length) {
    return getblockValue('requirement', {
      requirements: block,
    });
  }
}

function completeCampaignType(campaignType = {}) {
  campaignType = { ...CampaignTypeModel, ...campaignType };
  return campaignType;
}

function getPlayOffPhaseName(gameRound, games, twoGamesShow) {
  const twoGames = games.length >= 2 && games[0].real_team.id === games[1].real_team.id;

  const playOffPhase = Math.floor(gameRound / (twoGames ? 2 : 1));
  const playOffTotalPhases = games.length / (twoGames ? 2 : 1);

  let name = CompetitionsUtils.getPlayOffPhaseName(playOffPhase, playOffTotalPhases);

  if (twoGames && twoGamesShow) name += ` - Jogo ${(gameRound % 2) + 1}`;

  return name;
}

function getPhaseName(type, phase, gameRound, totalPhases, games, roundNumber) {
  let name = '';

  if (phase + 1 < totalPhases) name += `${phase + 1}ª Fase - `;

  switch (type) {
    case 'play_off':
      name += getPlayOffPhaseName(gameRound, games, true);
      break;
    case 'classificatory':
    default:
      name += `${gameRound + 1}ª Rodada`;
  }

  if (roundNumber) name += ` - Rodada ${roundNumber}`;

  return name;
}

function completeCampaign(campaign = {}) {
  campaign = { ...CampaignModel, ...campaign };

  campaign.real_team = RealTeamsUtils.completeRealTeam(campaign.real_team);
  campaign.type = completeCampaignType(campaign.type);

  campaign.phases.forEach((phase) => {
    (phase.games || []).forEach((game) => {
      game.real_team = RealTeamsUtils.completeRealTeam(game.real_team);
    });
  });

  campaign.invitations = (campaign.invitations || []).map(user => PlayersUtils.completePlayer(user));
  campaign.users = (campaign.users || []).map(user => PlayersUtils.completePlayer(user));

  return campaign;
}

export default {
  userTypesAchievements,
  blockDetails,
  completeCampaignType,
  getPlayOffPhaseName,
  getPhaseName,
  completeCampaign,
};
