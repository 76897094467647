import MessagesData from '../../data/messages';

function getRealModeName(mode) {
  if (mode === MessagesData.wall.generalName) return '';
  return mode;
}

function sortingMessages(messages) {
  messages.sort((a, b) => (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime());
}

export default {
  getRealModeName,
  sortingMessages,
};
