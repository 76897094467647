import React from 'react';

const TutorialSingleActive = () => (
  <React.Fragment>
    <h3 className="title-center">
      {'Fantasia'}
    </h3>
  </React.Fragment>
);

export default TutorialSingleActive;
