import React from 'react';

const AdministrationReportTransactionsGeneral = (props) => {
  const { months } = props;

  let netProfit = 0;
  let grossProfit = 0;
  let outflow = 0;
  let expenses = 0;

  months.forEach((month) => {
    netProfit += month.transactions.inflow.net_profit;
    grossProfit += month.transactions.inflow.gross_profit;
    outflow += month.transactions.outflow.value;
    expenses += month.transactions.expenses.value;
  });

  return (
    <div>
      <h3>
        {'Valores'}
      </h3>
      <h4>
        {'Receita Líquida: R$'}
        {netProfit.toFixed(2)}
      </h4>
      <h4>
        {'Despesas: R$'}
        {expenses.toFixed(2)}
      </h4>
      <h4>
        {'Crédtios inseridos: R$'}
        {grossProfit.toFixed(2)}
      </h4>
      <h4>
        {'Crédito Gasto: R$'}
        {outflow.toFixed(2)}
      </h4>
    </div>
  );
};

export default AdministrationReportTransactionsGeneral;
