import React from 'react';

import RcProgressLine from '../../../../elements/rcProgress/line';
import Colors from '../../../../../data/colors';

export const CampaignInProgressDetailsCurrentResult = ({
  title, final, color = Colors.layout.asbestos, value, target,
}) => (
  <div className="result">
    {value >= target ? (
      <div className={`final${final ? ' old' : ''}`}>
        {`${title} ${target ? ` | ${target} pontos` : ''}` }
      </div>
    ) : (
      <React.Fragment>
        <div className="name">
          {title}
        </div>
        <div className="bar">
          <div className="progress">
            <RcProgressLine
              percent={Math.ceil(100 * (value / target))}
              strokeWidth="2"
              trailWidth="2"
              strokeColor={color}
              trailColor={Colors.layout.silver}
            />
          </div>
        </div>
        <div className="value">
          {`${value}/${target}`}
        </div>
      </React.Fragment>
    )}

  </div>
);
export default CampaignInProgressDetailsCurrentResult;
