import React from 'react';

const TeamsInitiationProposalsSettings = props => (
  <div className="proposals-settings">
    <div className="title-line">
      {'Preferências'}
    </div>
    {props.isAvailable ? (
      <button className="button normal red" type="button" onClick={() => props.notAvailable()}>
        {'Não aceitar propostas automaticamente'}
      </button>
    ) : (
      <button className="button normal" type="button" onClick={() => props.beAvailable()}>
        {'Aceitar propostas automaticamente'}
      </button>
    )}
  </div>
);

export default TeamsInitiationProposalsSettings;
