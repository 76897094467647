import React from 'react';
import { Link } from 'react-router-dom';

import CompetitionsUtils from '../../../app/utils/competitions';

const RecordsAchievementsTitles = (props) => {
  const { titles = [] } = props;

  if (titles.length === 0) return '-';

  return (
    <div className="competitions-list">
      {titles.map(title => (
        <Link key={title.competition.id} to={CompetitionsUtils.getLink(title.competition)}>
          <div className="item">
            <img src={title.competition.logo.url} alt={title.competition.name} />
          </div>
        </Link>
      ))}
    </div>
  );
};

export default RecordsAchievementsTitles;
