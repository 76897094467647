import RestService from '../services/rest';
import PlayersUtils from '../utils/players';
import CompetitionsUtils from '../utils/competitions';
import CampaignsUtils from '../utils/campaigns';

async function getPlayer(username) {
  const player = await RestService.getAuthenticated(`users/details?username=${username}`);
  return PlayersUtils.completePlayer(player);
}

async function getPlayerAchievements(playerId) {
  const achievements = await RestService.getAuthenticated(`users/achievements?user=${playerId}`);
  achievements.triumphs.forEach((triumph) => {
    if (triumph.competition) {
      triumph.competition = CompetitionsUtils.completeCompetition(triumph.competition.competition);
    } else if (triumph.campaign) {
      const type = CampaignsUtils.completeCampaignType(triumph.campaign.type);
      triumph.campaign = CampaignsUtils.completeCampaign(triumph.campaign.campaign);
      triumph.campaign.type = type;
    }
  });
  return achievements;
}

export default {
  getPlayer,
  getPlayerAchievements,
};
