import React from 'react';
import { connect } from 'react-redux';

import PlayersDetailsBetsRound from '../players/details/bets/playersDetailsBetsRound';

export const LiveBetsGames = ({ roundNumber, round, bet }) => (
  <div>
    <h3 className="title-center-banner">
      {`Rodada ${roundNumber}`}
    </h3>
    <PlayersDetailsBetsRound
      roundNumber={roundNumber}
      round={round}
      bet={bet}
      canBet={false}
    />
  </div>
);

const mapStateToProps = (state, ownProps) => ({
  round: state.rounds.byNumber[ownProps.roundNumber],
  bet: state.roundsBets.users[state.user.id] && state.roundsBets.users[state.user.id][ownProps.roundNumber],
});

export default connect(mapStateToProps)(LiveBetsGames);
