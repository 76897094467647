import React from 'react';

import CardSimple from '../../card/simple';

import GameListTeam from './team';
import GameListResult from './result';

const GameList = (props) => {
  const { game, round } = props;

  if (!game) return (null);

  return (
    <div className="game-list">
      <CardSimple>
        <div className="content">
          <GameListTeam team={game.team_home} inverted />
          <GameListResult game={game} round={round} />
          <GameListTeam team={game.team_away} />
        </div>
      </CardSimple>
    </div>
  );
};

export default GameList;
