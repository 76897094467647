import React from 'react';

import CompetitionsUtils from '../../../../app/utils/competitions';

import Player from '../../../players/elements/player';
import Team from '../../elements/team';
import CardSimple from '../../../elements/card/simple';
import BadgeList from '../../../elements/badgeList/badgeList';

export const TeamsCompetitionsListClosedItem = (props) => {
  const { competition } = props;
  const { winners } = competition;

  const item = {
    id: competition.id,
    color: CompetitionsUtils.getCompetitionColor(competition),
    name: competition.name,
    tag: {
      name: `Rodadas: ${competition.round.first} - ${competition.round.last}`,
      color: CompetitionsUtils.getTypeColor(competition.type),
      nameHover: CompetitionsUtils.getEditionNameText(competition.edition, competition.year),
      colorHover: CompetitionsUtils.getCompetitionColor(competition),
    },
    badge: competition.logo.url,
    locked: false,
    mark: competition.xp ? competition.xp : undefined,
    link: competition.id,
  };

  return (
    <CardSimple>
      <BadgeList
        items={[item]}
        gridInfo={{ lg: 12, md: 12, sm: 12 }}
      />
      <div className="team-content">
        <div className="label">Campeão:</div>
        <Team data={winners.champion} type="table" />
      </div>
      <div className="team-content">
        <div className="label">Vice Campeão:</div>
        <Team data={winners.second_place} type="table" />
      </div>
      <div className="team-content">
        {winners.third_place.id && (
        <React.Fragment>
          <div className="label">Terceiro Lugar:</div>
          <Team data={winners.third_place} type="table" />
        </React.Fragment>
        )}
      </div>
      {winners.strikers && winners.strikers.players.length > 0 && (
      <div className="team-content">
        <div className="label">
          {'Artilharia: '}
          <span>
            {winners.strikers.goals}
            {' Gols'}
          </span>
        </div>
        {winners.strikers.players.length > 1 ? (
          <div className="strikers">
            {winners.strikers.players.map(player => <Player key={player.id} data={player} type="image" />)}
          </div>
        ) : (
          <Player data={winners.strikers.players[0]} type="table" />
        )}
      </div>
      )}
    </CardSimple>
  );
};

export default TeamsCompetitionsListClosedItem;
