export default {
  mode: 'single',
  active: true,
  prime: false,
  basic: true,
  color: '#2980b9',
  pages: [
    'creation',
    'teams',
    'calendar',
    'games',
    'competitions',
    'statistics',
    'guide',
  ],
  statistics_pages: [
    'achievements',
    'games',
    'confrontation',
    'top5',
  ],
  management_pages: [
    'badge',
    'friendly',
  ],
  list: 'league',
  leagues: {
    league: {
      name: 'Séries',
    },
    cup: {
      name: 'Copas',
    },
    grand_cup: {
      name: 'Copa Barbolão',
    },
    final: {
      name: 'Bola de Ouro',
    },
  },
};
