import 'normalize.css/normalize.css';
import moment from 'moment-timezone';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import AppRouter from './routers/appRouter';
import configureStore from './app/store/configureStore';
import initialization from './app/initialization';

import PreferencesData from './data/preferences';

import './styles/styles.scss';

moment.tz.setDefault(PreferencesData.timezone);
moment.locale(PreferencesData.language);
moment.relativeTimeThreshold('h', 24 * 4);
moment.relativeTimeThreshold('m', 60 * 2);

const store = configureStore();
const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

initialization(store);

if (document.documentElement.clientWidth < 360) {
  const scale = document.documentElement.clientWidth / 360;
  document.querySelector('meta[name=viewport]').setAttribute(
    'content',
    `width=360, initial-scale=${scale}, maximum-scale=1.0, user-scalable=0`,
  );
}

ReactDOM.render(jsx, document.getElementById('app'));
