import React from 'react';

export const GuideManagerGeneral = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Neste modo, o jogador fundará um clube com o nome de sua escolha. Além de poder escolher o escudo '
      + 'que mais lhe agrade.'}
        </p>
        <p>
          {'Cada jogador no site tem um '}
          <b>valor de mercado</b>
          {', alguns valem mais e outros menos, dependendo do desempenho nas rodadas. Você terá um '}
          <b>valor fictício</b>
          {' para escalar seu clube, com os jogadores do site. Você terá que escolher a '}
          <b>formação</b>
          {' para seu clube, dependendo da sua estratégia.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Valorização'}
      </h4>
      <div className="section-content">
        <p>
          {'Toda vez que um jogador valorizar durante a rodada e você o tiver escalado, seu valor fictício '
        + 'aumentará para a próxima rodada. No caso dele desvalorizar, o inverso acontece.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuideManagerGeneral;
