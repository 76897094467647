import React from 'react';
import { connect } from 'react-redux';

import MenuSecondary from '../elements/menuSecondary';

export const WallMenu = ({ userTeams, menus }) => {
  const userMenus = menus.filter(m => m.mode === 'general');
  userMenus[0].name = 'Geral';

  menus.filter(m => !m.mode).forEach((m) => {
    userMenus.push(m);
  });

  if (userTeams) {
    if (userTeams.club) {
      const clubMenu = menus.find(m => m.mode === 'club');
      clubMenu.name = userTeams.club.name;
      userMenus.push(clubMenu);
    }
    if (userTeams.national) {
      const nationalMenu = menus.find(m => m.mode === 'national');
      nationalMenu.name = userTeams.national.name;
      userMenus.push(nationalMenu);
    }
  }


  return <MenuSecondary menus={userMenus} tag="Mural" />;
};

const mapStateToProps = state => ({
  userTeams: state.user.teams,
});

export default connect(mapStateToProps)(WallMenu);
