import RestService from '../services/rest';
import PlayersUtils from '../utils/players';
import TeamsUtils from '../utils/teams';

async function getHistory(gameMode, type, lastDate, limit) {
  let url = `markets/${gameMode}/history`;
  if (type) url += `/${type}`;
  url += `?limit=${limit}`;
  if (lastDate) url += `&date=${lastDate}`;

  const history = await RestService.getAuthenticated(url);
  history.forEach((line) => {
    line.user = PlayersUtils.completePlayer(line.user);
    line.team = TeamsUtils.completeTeam(line.team);
  });
  return history;
}

export default {
  getHistory,
};
