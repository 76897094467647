import React from 'react';
import { connect } from 'react-redux';

import Paginate from '../../elements/paginate';

import RealTeamsData from '../../../data/realTeams';

import TeamsUtils from '../../../app/utils/teams';
import RealTeamsUtils from '../../../app/utils/realTeams';

import RealTeamSearchTable from './table';

export class RealTeamSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      page: 1,
    };
  }

  onSearchChange = (e) => {
    const search = e.target.value.toLowerCase();
    this.setState(() => ({ search, page: 1 }));
  };

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  render() {
    const { search, page } = this.state;
    const {
      teams, title, states, countries,
    } = this.props;

    const totalPage = RealTeamsData.search.total;
    let totalPages = 0;

    let selectTeams;

    if (teams) {
      selectTeams = TeamsUtils.searchTeams(RealTeamsUtils.filterRealTeam(teams, states, countries), search);
      totalPages = Math.ceil(selectTeams.length / totalPage);
    }

    return (
      <div className="content-container margin-bottom">
        <div className="title-line">
          {title || 'Escolha o Clube'}
        </div>

        <div className="search-header">
          <input
            type="text"
            placeholder="Busque pelo nome"
            className="text-input filter-input"
            value={search}
            onChange={this.onSearchChange}
          />

          <div className="pagination-header">
            <Paginate
              pageCount={totalPages}
              forcePage={page - 1}
              onPageChange={this.handlePageClick}
            />
          </div>
        </div>

        {selectTeams && (
          <RealTeamSearchTable
            teams={selectTeams.slice(((page - 1) * totalPage), page * totalPage)}
            selectTeam={this.props.selectRealTeam}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.realTeams.teams || [],
});

export default connect(mapStateToProps)(RealTeamSearch);
