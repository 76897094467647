import React from 'react';
import { Link } from 'react-router-dom';

import TutorialWrapper from '../elements/tutorial/tutorial';

const CartSuccess = () => (
  <div className="cart">
    <TutorialWrapper image="cart.jpg">
      <h3 className="title-center">
        {'Finalizada!'}
      </h3>
      <p>
        {'Compra efetuada com sucesso!'}
      </p>
      <p>
        {'Você já pode desfrutar do produto comprado.'}
      </p>
      <p className="text">
        {'Obrigado!'}
      </p>
      <Link className="button normal" to="/compras">
        {'Minhas Compras'}
      </Link>
    </TutorialWrapper>
  </div>
);

export default CartSuccess;
