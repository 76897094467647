import React from 'react';
import { Link } from 'react-router-dom';

import CompetitionsUtils from '../../../app/utils/competitions';

export const CompetitionsPremiumsBanner = ({ gameMode }) => (
  <div className="competitions-premiums-banner">
    <Link to={CompetitionsUtils.getPremiumsLink(gameMode)}>
      <img src="/images/competitions_premium.png" alt="Premium" />
    </Link>
  </div>
);

export default CompetitionsPremiumsBanner;
