import AuthRequests from '../../server/auth';
import AuthService from '../../services/auth';

import { cleanUser, userGetAuth } from './user';
import startTimers, { timersStopAuth } from './timers';
import { activitiesSetAuthenticated } from './activities';
import { addLoading, removeLoading } from './loading';

export const authenticateComplete = () => async (dispatch) => {
  await dispatch(userGetAuth());
  dispatch(startTimers());
};

export const authenticateLogin = (emailOrUsername, password) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const auth = await AuthRequests.authenticate(emailOrUsername, password);
    AuthService.create(auth);
    dispatch(activitiesSetAuthenticated(true));
    await dispatch(authenticateComplete());
  } finally {
    dispatch(removeLoading());
  }
};

export const authenticateRefreshToken = token => async () => {
  const auth = await AuthRequests.refreshToken(token);
  AuthService.create(auth);
};

export const authenticateClean = () => async (dispatch) => {
  AuthService.reset();
  dispatch(cleanUser());
  dispatch(activitiesSetAuthenticated(false));
  timersStopAuth();
};


export const authenticateLogout = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    await AuthRequests.logout();
  } finally {
    dispatch(authenticateClean());
    dispatch(removeLoading());
  }
};
