import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Grid, Row, Col } from 'react-flexbox-grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { history } from '../../routers/appRouter';

import { addCart } from '../../app/store/actions/cart';

import SubscriptionData from '../../data/subscriptions';

export class SubscriptionPage extends React.Component {
  addToCart = async (plan) => {
    await this.props.addToCart(plan);
    history.push('/carrinho');
  }

  render() {
    const { userExpires } = this.props;
    const maxFeatures = SubscriptionData.plans.reduce((total, plan) => Math.max(total, plan.features.length), 0);

    const userExpiresDate = moment(userExpires);

    return (
      <div className="content-container with-margin">
        <div className="title-center">
          {'Membro Prime'}
        </div>
        <div className="subscription">
          {userExpiresDate.isAfter(moment()) && (
          <div className="expires-info">
            {'Sua assinatura vai até dia '}
            <b>{userExpiresDate.subtract(1, 'day').format('LL')}</b>
          </div>
          )}
          <Grid fluid>
            <Row>
              {SubscriptionData.plans.map((plan, index) => (
                <Col md={12} lg={4} key={plan.id}>
                  <div className={`plan${plan.featured ? ' featured' : ''}${index === 0 ? ' plan__first' : ''}`}>
                    <header>
                      <h4 className="plan-title">
                        {plan.name}
                      </h4>
                      <div className="plan-cost">
                        <span className="plan-price">
                          {`R$${plan.amount}`}
                        </span>
                        {plan.fullAmount && (
                        <span className="plan-type">
                          {'/ '}
                          <span>
                            {`R$${plan.fullAmount}`}
                          </span>
                        </span>
                        )}
                      </div>
                    </header>
                    <ul className="plan-features">
                      {plan.features.map(feature => (
                        <li key={feature}>
                          <span>
                            <FontAwesomeIcon icon={faCheck} />
                            {' '}
                          </span>
                          {feature}
                        </li>
                      ))}
                      {(Array(maxFeatures - plan.features.length).fill(null)).map((v, i) => i).map(val => (
                        <li key={val}>
                            &nbsp;
                        </li>
                      ))}
                    </ul>
                    <div className="plan-select">
                      <button type="button" className="button plan-select" onClick={() => this.addToCart(plan)}>
                        {'Selecionar'}
                      </button>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userExpires: state.user.subscription.expires,
});

const mapDispatchToProps = dispatch => ({
  addToCart: item => dispatch(addCart('subscription', item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
