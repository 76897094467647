import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol } from '@fortawesome/free-solid-svg-icons';

import TeamUtil from '../../../../../app/utils/teams';
import PlayersUtils from '../../../../../app/utils/players';

import Table from '../../../../elements/table';
import Player from '../../../../players/elements/player';

export const TeamsGamesEscalationTable = (props) => {
  const {
    name, gameMode, players = [], strikers = [], positions = [],
  } = props;

  const header = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'player', value: name, className: 'name', colspan: 3,
    },
    { key: 'goals', hidden: true, tdClassName: 'high' },
    {
      key: 'score', value: 'Pontos', className: 'number', tdClassName: 'high',
    },
    { key: 'bet', value: 'Aposta', className: 'number' },
  ];

  const data = positions.map((position) => {
    const playerData = {
      key: position,
      position: TeamUtil.getPositionAbbr(gameMode, position),
      goalsL: (null),
      player: <Player data={PlayersUtils.completePlayer()} type="table" />,
      score: '-',
      bet: '-',
    };

    const player = players.find(p => p.position === position);

    if (player) {
      const strikerPlayer = strikers.find(striker => striker.player.id === player.user.id);

      playerData.player = <Player data={player.user} type="table" />;
      playerData.score = player.score !== undefined ? player.score : '-';
      playerData.bet = player.bet ? player.bet.score : '-';

      if (strikerPlayer) {
        playerData.goals = (
          <React.Fragment>
            <FontAwesomeIcon icon={faFutbol} />
            {strikerPlayer.score > 1 && (
              <span>
                {` (${strikerPlayer.score})`}
              </span>
            )}
          </React.Fragment>
        );
      }
    }

    return playerData;
  });

  return (<Table header={header} lines={data} />);
};

export default TeamsGamesEscalationTable;
