import React from 'react';

import CardSimple from '../../../../../elements/card/simple';
import Team from '../../../../elements/team';
import GamePlayOff from '../../../../../elements/games/playoff/playoff';

import TeamsCompetitionsDetailsInformationPlayOffPenalties from './penalties';

export class TeamsCompetitionsDetailsInformationPlayOffConfrontation extends React.Component {
  constructor(props) {
    super(props);

    this.state = { };
  }

  render() {
    const { confrontation, last } = this.props;

    const homeTeam = confrontation.teams[0];
    const awayTeam = confrontation.teams[1];

    return (
      <CardSimple className={`confrontation${last ? ' last' : ''}${confrontation.final ? ' final' : ''}`}>
        {!confrontation.final && last && (
        <div className="title-third">Terceiro Lugar</div>
        )}
        <div className="data">
          <div className={`team${!homeTeam.winner ? ' loser' : ''}`}>
            <Team data={homeTeam.team} />
          </div>
          <div className="result">
            <div className="score">
              <div className={`goals${homeTeam.winner ? ' winner' : ''}`}>
                {homeTeam.score}
                {homeTeam.penalties.score !== undefined && <span>{homeTeam.penalties.score}</span>}
              </div>
              <div className="divisor">-</div>
              <div className={`goals${awayTeam.winner ? ' winner' : ''}`}>
                {awayTeam.penalties.score !== undefined && <span>{awayTeam.penalties.score}</span>}
                {awayTeam.score}
              </div>
            </div>
            <div className="games">
              {confrontation.games.map(game => <GamePlayOff key={game.id} game={game} />)}
            </div>
          </div>
          <div className={`team${!awayTeam.winner ? ' loser' : ''}`}>
            <Team data={awayTeam.team} />
          </div>
        </div>
        {homeTeam.penalties.score !== undefined && (
        <TeamsCompetitionsDetailsInformationPlayOffPenalties
          home={homeTeam.penalties}
          away={awayTeam.penalties}
        />
        )}
      </CardSimple>
    );
  }
}

export default TeamsCompetitionsDetailsInformationPlayOffConfrontation;
