import React from 'react';

import Steps from '../../../../../elements/steps/steps';

import TeamsCompetitionsDetailsInformationPlayOffPhase from './phase';

export class TeamsCompetitionsDetailsInformationPlayOff extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phasesTotal: props.playOff.reduce((max, confrontation) => ((confrontation.phase > max)
        ? confrontation.phase : max), 0),
    };
  }

  render() {
    const { phasesTotal } = this.state;
    const { playOff, info } = this.props;

    const phases = [];
    for (let i = 0; i < phasesTotal; i += 1) {
      phases[i] = {
        next: i < phasesTotal - 1,
        previous: i > 0,
        name: i,
        confrontations: playOff.filter(confrontation => confrontation.phase === i + 1),
      };
    }

    return (
      <div className="play-off">
        <Steps props={{ initialStep: Math.min(info.phase, phasesTotal) }} noScroll>
          {phases.map(phase => (
            <TeamsCompetitionsDetailsInformationPlayOffPhase
              phase={phase}
              totalPhases={phases.length}
              key={phase.name}
            />
          ))}
        </Steps>
      </div>
    );
  }
}

export default TeamsCompetitionsDetailsInformationPlayOff;
