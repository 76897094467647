import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import GameModeInitiationActivation from './activation/gameModeInitiationActivation';
import TeamsInitiationCreation from '../teams/initiation/creation/teamsInitiationCreation';
import TeamsInitiationProposals from '../teams/initiation/proposals/teamsInitiationProposals';

import TeamsDetails from '../teams/details/teamsDetails';
import TeamsList from '../teams/list/teamsList';
import TeamsCalendar from '../teams/calendar/teamsCalendar';
import TeamsGames from '../teams/games/teamsGames';
import TeamsCompetitions from '../teams/competitions/teamsCompetitions';
import TeamsMarket from '../teams/market/teamsMarket';
import TeamsStatistics from '../teams/statistics/teamsStatistics';

const GameModeBase = (props) => {
  const { numberClosed, match } = props;
  const { url } = match;

  return (
    <div>
      {numberClosed && (
      <Switch>
        <Route
          path={`${url}/introducao`}
          component={() => <GameModeInitiationActivation modeData={props.modeData} />}
        />
        <Route path={`${url}/criacao`} component={() => <TeamsInitiationCreation modeData={props.modeData} />} />
        <Route path={`${url}/propostas`} component={() => <TeamsInitiationProposals modeData={props.modeData} />} />
        <Route path={`${url}/calendario`} component={() => <TeamsCalendar modeData={props.modeData} />} />
        <Route path={`${url}/jogos`} component={p => <TeamsGames {...p} modeData={props.modeData} />} />
        <Route path={`${url}/campeonatos`} component={p => <TeamsCompetitions {...p} modeData={props.modeData} />} />
        <Route path={`${url}/mercado`} component={p => <TeamsMarket {...p} modeData={props.modeData} />} />
        <Route path={`${url}/estatisticas`} component={p => <TeamsStatistics {...p} modeData={props.modeData} />} />
        <Route path={`${url}/:name`} component={p => <TeamsDetails {...p} modeData={props.modeData} />} />
        <Route
          exact
          path={url}
          component={p => <TeamsList {...p} modeData={props.modeData} />}
        />
      </Switch>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  numberClosed: state.rounds.numberClosed,
});

export default connect(mapStateToProps)(GameModeBase);
