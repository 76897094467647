import React from 'react';
import { connect } from 'react-redux';
import { scrollTo } from '../../../../routers/appRouter';
import { teamsGetAchievements } from '../../../../app/store/actions/teams';

import translator from '../../../../data/translator';
import Tabs from '../../../elements/tabs/tabs';

import TeamsDetailsAchievementsType from './teamsDetailsAchievementsType';

export class TeamsDetailsAchievements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      achievements: {},
      selectedType: 'official',
    };
  }

  async componentDidMount() {
    const { achievements } = this.props;
    if (!achievements) await this.props.getAchievements();
    else this.updateData(achievements);
    scrollTo('menu-secondary');
  }

  componentWillReceiveProps(nextProps) {
    const { achievements } = nextProps;
    if (achievements) this.updateData(achievements);
  }

  selectType = async (type) => {
    await this.setState({ selectedType: type });
  }

  updateData(achievements = {}) {
    this.setState(() => ({ achievements }));
  }

  render() {
    const { achievements, selectedType } = this.state;

    if (!achievements) return (null);

    const { triumphs = [] } = achievements;

    const types = ['official'];
    triumphs.filter(t => t.category === 'champion').forEach((t) => {
      if (types.indexOf(t.competition.type) === -1) types.push(t.competition.type);
    });

    const selectedAchievements = triumphs.filter(t => t.competition.type === selectedType && t.category === 'champion');

    return (
      <div className="team-achievements">
        <div className="title-center-menu">
          {'Títulos'}
        </div>
        <Tabs
          items={types.map(type => ({
            name: translator.s.competitions[type],
            onClick: () => this.selectType(type),
            active: type === selectedType,
          }))}
        />

        <TeamsDetailsAchievementsType triumphs={selectedAchievements} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAchievements: () => dispatch(teamsGetAchievements()),
});

const mapStateToProps = state => ({
  achievements: state.teams.details && state.teams.details.achievements,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsAchievements);
