import React from 'react';
import { connect } from 'react-redux';

import { teamsStatisticsGetTop5 } from '../../../../app/store/actions/teamsStatistics';

import IntervalSelect from '../../../elements/statistics/intervalSelect';
import Top5Table from '../../../elements/top5/top5Table';
import Team from '../../elements/team';
import Player from '../../../players/elements/player';

import TeamsStatisticsTop5Bets from './top5Bets';
import TeamsStatisticsTop5Games from './top5Games';
import TeamsStatisticsTop5Ranking from './top5Ranking';
import TeamsStatisticsTop5Players from './top5Players';
import TeamsStatisticsTop5Achievements from './top5Achievements';

export class TeamsStatisticsTop5 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      interval: props.match.params.interval,
    };
  }

  async componentDidMount() {
    const { interval } = this.state;
    const { modeData } = this.props;
    await this.props.getTop5(modeData.mode, interval);
  }

  createTeamTable = (data, title, valueTxt, typeData) => {
    if (!data) return (null);
    return (
      <Top5Table
        title={title}
        data={data}
        typeData={typeData}
        entity="team"
        component={Team}
        header={{ entity: 'Clube', value: valueTxt }}
      />
    );
  }

  createPlayerTable = (data, title, valueTxt) => {
    if (!data) return (null);
    return (
      <Top5Table
        title={title}
        data={data}
        entity="player"
        component={Player}
        header={{ entity: 'Jogador', value: valueTxt }}
      />
    );
  }

  render() {
    const { data, match } = this.props;

    if (!data) return (null);

    return (
      <div className="content-container margin-bottom">
        <div className="title-center-menu">
          {'Top 5'}
        </div>
        <IntervalSelect match={match} />
        {data.bets && (<TeamsStatisticsTop5Bets data={data.bets} createTable={this.createTeamTable} />)}
        {data.games && (<TeamsStatisticsTop5Games data={data.games} createTable={this.createTeamTable} />)}
        {data.ranking && (<TeamsStatisticsTop5Ranking data={data.ranking} createTable={this.createTeamTable} />)}
        {data.players && (<TeamsStatisticsTop5Players data={data.players} createTable={this.createPlayerTable} />)}
        {data.achievements && (
        <TeamsStatisticsTop5Achievements
          data={data.achievements}
          createTable={this.createTeamTable}
        />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTop5: (gameMode, interval) => dispatch(teamsStatisticsGetTop5(gameMode, interval)),
});

const mapStateToProps = (state, ownProps) => {
  const interval = ownProps.match.params.interval === 'geral' ? 'all' : ownProps.match.params.interval;
  const { mode } = ownProps.modeData;
  return {
    data: state.teamsStatistics.top5[mode]
    && state.teamsStatistics.top5[mode][interval],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatisticsTop5);
