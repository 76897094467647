import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import pageTitleService from '../../../app/services/pageTitle';

import { teamsStartTeam, teamsGetTeam } from '../../../app/store/actions/teams';

import MenuSecondary from '../../elements/menuSecondary';

import TeamsDetailsHeader from './header/teamsDetailsHeader';

import TeamsDetailsOverview from './overview/teamsDetailsOverview';
import TeamsDetailsGames from './games/teamsDetailsGames';
import TeamsDetailsCompetitions from './competitions/teamsDetailsCompetitions';
import TeamsDetailsAchievements from './achievements/teamsDetailsAchievements';
import TeamsDetailsStatistics from './statistics/teamsDetailsStatistics';
import TeamsDetailsPlayers from './players/teamsDetailsPlayers';
import TeamsDetailsPortal from './portal/portal';

import TeamsDetailsManagement from './management/teamsDetailsManagement';
import TeamsDetailsManagementFriendly from './management/friendly/friendly';
import TeamsDetailsManagementmarket from './management/market/market';
import TeamsDetailsManagementBadge from './management/badge/teamsDetailsManagementBadge';
import TeamsDetailsManagementEscalation from './management/escalation/escalation';
import TeamsDetailsManagementPortal from './management/portal/portal';
import TeamsDetailsManagementRoles from './management/roles/teamsDetailsManagementRoles';

import { history, scrollTo } from '../../../routers/appRouter';

export class TeamsDetails extends React.Component {
  constructor(props) {
    super(props);

    const pathnames = history.location.pathname.split('/');

    this.state = {
      isResume: pathnames.length < 4,
    };
  }

  async componentDidMount() {
    const { isResume } = this.state;
    const { teamOverview, modeData, name } = this.props;

    pageTitleService.setTitle(name);

    if (!teamOverview) await this.props.startTeam(modeData.mode, name);
    else if (isResume) await this.props.getTeam(modeData.mode, name);

    if (isResume) scrollTo('header-bottom');
  }

  async componentWillReceiveProps(nextProps) {
    const {
      isIdle, roundUpdated, modeData, name,
    } = nextProps;

    if (this.props.roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
      await this.props.getTeam(modeData.mode, name);
    }
  }

  render() {
    const {
      modeData, teamOverview: team, teamPortal, url,
    } = this.props;
    const { players, mode } = modeData;

    const managementMenus = [
      { name: 'Amistosos', uri: `${url}/administrativo/amistosos`, component: TeamsDetailsManagementFriendly },
      { name: 'Escudo', uri: `${url}/administrativo/escudo`, component: TeamsDetailsManagementBadge },
      { name: 'Mercado', uri: `${url}/administrativo/mercado`, component: TeamsDetailsManagementmarket },
      { name: 'Escalação', uri: `${url}/administrativo/escalacao`, component: TeamsDetailsManagementEscalation },
      { name: 'Cargos', uri: `${url}/administrativo/cargos`, component: TeamsDetailsManagementRoles },
      { name: 'Portal', uri: `${url}/administrativo/portal`, component: TeamsDetailsManagementPortal },
      { name: 'Administrativo', uri: `${url}/administrativo`, component: TeamsDetailsManagement },
    ];

    const menus = [
      { name: 'Resumo', uri: url, component: TeamsDetailsOverview },
      { name: 'Jogos', uri: `${url}/jogos`, component: TeamsDetailsGames },
      { name: 'Campeonatos', uri: `${url}/campeonatos`, component: TeamsDetailsCompetitions },
      { name: 'Títulos', uri: `${url}/titulos`, component: TeamsDetailsAchievements },
    ];

    // TODO remove this
    if (mode === 'club' || mode === 'national') {
      menus.push({ name: 'Estatísticas', uri: `${url}/estatisticas`, component: TeamsDetailsStatistics });
    }

    if (players) {
      menus.splice(1, 0, { name: 'Jogadores', uri: `${url}/players`, component: TeamsDetailsPlayers });
    }

    if (teamPortal) {
      menus.push({ name: 'Portal', uri: `${url}/portal`, component: TeamsDetailsPortal });
    }

    if (!team) return (null);

    return (
      <div className="teams-details">
        <TeamsDetailsHeader team={team} url={url} mode={mode} />
        <MenuSecondary menus={menus} tag={team && team.name} />
        <Switch>
          {managementMenus.concat(menus).map(menu => (
            <Route
              key={menu.name}
              exact={url === menu.uri}
              path={menu.uri}
              component={menu.component}
            />
          ))}
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startTeam: (gameMode, teamName) => dispatch(teamsStartTeam(gameMode, teamName)),
  getTeam: (gameMode, teamName) => dispatch(teamsGetTeam(gameMode, teamName)),
});

const mapStateToProps = (state, ownProps) => {
  const { name } = ownProps.match.params;
  const { url } = ownProps.match;

  const teamOverview = (state.teams.details && state.teams.details.overview) || {};
  const teamGameMode = state.teams.details && state.teams.details.gameMode;

  return {
    name,
    url,
    isIdle: state.activities.idle,
    teamOverview: teamOverview && teamOverview.name === name
     && teamGameMode === ownProps.modeData.mode ? teamOverview : null,
    teamPortal: state.teams.details.overview && state.teams.details.overview.portal
    && state.teams.details.overview.portal.url,
    roundUpdated: state.rounds.updateAt,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetails);
