import React from 'react';
import { NavLink } from 'react-router-dom';
import { Element } from 'react-scroll';

const MenuSecondary = ({ menus, tag }) => (
  <div className="menu-secondary">
    <Element name="menu-secondary" />
    <div className="content-container">
      <div className="wrapper">
        {menus.map(menu => (
          <div
            className="item"
            key={menu.name}
            style={{ width: `${100 / menus.length}%` }}
          >
            <NavLink
              to={menu.uri}
              activeClassName="selected"
              exact={!menu.menuNotExact}
            >
              <div className="content">
                <div className="tag">
                  {tag}
                </div>
                <div className="name">
                  {menu.name}
                </div>
              </div>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default MenuSecondary;
