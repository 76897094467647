import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import RecordsRoundsContentGame from './game';

const RecordsRoundsContentGames = (props) => {
  const { games, statistics, betsTotal } = props;
  return (
    <Grid fluid style={{ padding: 0 }}>
      <Row>
        {games.map(game => (
          <Col key={game.number} md={6}>
            <RecordsRoundsContentGame
              game={game}
              statistics={statistics[game.number]}
              betsTotal={betsTotal}
            />
          </Col>
        ))}
      </Row>
    </Grid>
  );
};

export default RecordsRoundsContentGames;
