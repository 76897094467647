import React from 'react';

import ReputationData from '../../../data/reputation';

export const GuidePlayerReputation = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Todos os jogadores, ao se cadastrarem no site, automaticamente são posicionados na reputação '}
          <b>
            {'Várzea I'}
          </b>
          {'.'}
        </p>
        <p>
          {'A cada rodada, o jogador poderá avançar em sua reputação, desde que atinja a pontuação '
            + 'estipulada, para cada caso, que será calculada pela seguinte fórmula: '}
        </p>
        <h4>
          {'PR = 4C + A – E '}
        </h4>
        <ul>
          <li>
            <i>
              {'PR - Pontos para Reputação'}
            </i>
          </li>
          <li>
            <i>
              {'C - Número de Cravadas (acertos exatos)'}
            </i>
          </li>
          <li>
            <i>
              {'A - Números de Acertos (não exatos)'}
            </i>
          </li>
          <li>
            <i>
              {'E - Número de Erros'}
            </i>
          </li>
        </ul>
      </div>
      <p>
        {'Repare que será possível fazer uma pontuação negativa. Ela não rebaixará o '
        + 'jogador de reputação. O jogador terá sua reputação rebaixada apenas se deixar '
        + 'de palpitar alguma rodada.'}
      </p>
    </div>
    <div className="section">
      <h4>
        {'Reputações'}
      </h4>
      <div className="section-content">
        <ul className="list">
          {ReputationData.levels.map((level, index) => (
            <li key={level.tag}>
              <b>
                {level.name}
              </b>
              {` (Level ${(index * 5) + 1} ao ${(index + 1) * 5})`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </React.Fragment>
);
export default GuidePlayerReputation;
