import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { history, scrollTo } from '../../../../routers/appRouter';

import Paginate from '../../../elements/paginate';

import { roundBetsGetAuthUserBet, roundBetsGetUserBet, roundBetsSubmitBet }
  from '../../../../app/store/actions/roundsBets';

import PlayersDetailsBetsRound from './playersDetailsBetsRound';

export class PlayersDetailsBets extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const { round } = match.params;

    this.state = {
      roundNumber: Number(round),
    };
  }

  componentWillMount() {
    const { needTutorialRedirect } = this.props;
    if (needTutorialRedirect) history.push('/tutorial/geral');
  }

  async componentDidMount() {
    const {
      roundAvailables, round, bet, playerId, numberClosed, isAuthUser,
      getAuthBets, getBets, needTutorialRedirect,
    } = this.props;
    const { roundNumber } = this.state;
    if (Number.isInteger(roundNumber) && roundNumber > 0) {
      if (isAuthUser && (roundAvailables.length === 0 || roundNumber > numberClosed)) {
        await getAuthBets(roundNumber);
      } else if (roundNumber <= numberClosed) {
        if (!round || !bet || !round.finished) {
          await getBets(playerId, roundNumber);
        }
      }
    } else {
      // TODO 404
    }
    if (!needTutorialRedirect) scrollTo('menu-secondary');
  }

  goToRound = (roundNumber) => {
    const { match } = this.props;
    const { path } = match;

    const url = path.replace(':round', roundNumber);
    history.push(url);
  };

  handlePageClick = (data) => {
    const { selected: pageIndex } = data;

    const roundNumber = pageIndex + 1;
    this.goToRound(roundNumber);
  };

  handleBet = async (roundNumber, scores) => {
    const { needTutorial } = this.props;
    if (this.canBet(scores)) {
      await this.props.userBet(roundNumber, scores);
      if (needTutorial) history.push('/tutorial/geral');
    } else {
      // TODO Show message Error
    }
  };

  canBet = (scores) => {
    const { totalGames } = this.props;
    return (scores.filter(s => s.score_home >= 0 && s.score_away >= 0).length === totalGames);
  };

  render() {
    const {
      isAuthUser, roundAvailables, numberClosed, round, bet, suggestion,
    } = this.props;
    const { roundNumber } = this.state;

    return (
      <div className="player-bets">
        <div className="content-container margin-bottom">
          <div className="content-container">
            <div className="title-center-menu">
              {'Rodada '}
              {roundNumber}
            </div>
            <Grid fluid className="rounds" style={{ padding: 0 }}>
              <Row>
                <Col md={5}>
                  {isAuthUser && (
                  <div className="open">
                    <div className="text">
                      {'Abertas'}
                    </div>
                    {roundAvailables.map(roundAvailable => (
                      <button
                        type="button"
                        key={roundAvailable}
                        className={`button normal${roundAvailable === roundNumber ? ' active' : ''}`}
                        onClick={() => this.goToRound(roundAvailable)}
                      >
                        {'Rodada '}
                        {roundAvailable}
                      </button>
                    ))}
                    {roundAvailables.length === 0 && (
                      <div className="message">
                        {'Não há rodadas disponíveis para aposta no momento.'}
                        <br />
                        {'Aguarde a abertura de temporada para poder efetuar sua aposta!'}
                      </div>
                    )}
                  </div>
                  )}
                </Col>
                <Col md={7}>
                  <div className="history">
                    {isAuthUser && (
                    <div className="text">
                      {'Passadas'}
                    </div>
                    )}
                    <Paginate
                      pageCount={numberClosed}
                      forcePage={roundNumber - 1}
                      onPageChange={this.handlePageClick}
                    />
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>

          {!!round && (
          <PlayersDetailsBetsRound
            roundNumber={roundNumber}
            round={round}
            bet={bet}
            suggestion={suggestion}
            canBet={this.canBet}
            onSubmit={this.handleBet}
          />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAuthBets: roundNumber => dispatch(roundBetsGetAuthUserBet(roundNumber)),
  getBets: (userId, roundNumber) => dispatch(roundBetsGetUserBet(userId, roundNumber)),
  userBet: (roundNumber, scores) => dispatch(roundBetsSubmitBet(roundNumber, scores)),
});

const mapStateToProps = (state, ownProps) => {
  const { round } = ownProps.match.params;
  const roundNumber = round;
  const playerId = state.players.details.id;
  const { numberClosed } = state.rounds;
  const roundAvailables = state.roundsBets.availables;
  const { isAuthUser } = state.players.details;

  const userBetsTotal = state.user.bets && state.user.bets.total;
  const needTutorial = isAuthUser && userBetsTotal === 0 && (numberClosed + 1) === Number(roundNumber);
  const needTutorialRedirect = isAuthUser && userBetsTotal === 0 && roundAvailables.length === 0;

  return {
    numberClosed,
    roundAvailables,
    isAuthUser,
    playerId,
    round: state.rounds.byNumber[roundNumber],
    suggestion: state.roundsBets.suggestion[roundNumber],
    bet: state.roundsBets.users[playerId] && state.roundsBets.users[playerId][roundNumber],
    totalGames: state.settings.rounds && state.settings.rounds.total_games,
    needTutorial,
    needTutorialRedirect,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayersDetailsBets);
