import React from 'react';

import Paginate from '../../../../elements/paginate';

import TeamsDetailsManagementMarketTable from './table';

export class TeamsDetailsManagementMarketSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      page: 1,
    };
  }

  onSearchChange = (e) => {
    const search = e.target.value.toLowerCase();
    this.setState(() => ({ search, page: 1 }));
  };

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  render() {
    const { search, page } = this.state;
    const { availables, totalPage, hasAction } = this.props;

    let selectMarkets = [];

    if (availables) {
      selectMarkets = availables.filter(market => market.user.username
        && market.user.username.toLowerCase().includes(search));
    }

    const totalPages = Math.ceil(selectMarkets.length / totalPage);

    return (
      <div className="content">
        <div className="title-line">
          {'Buscar por Jogador'}
        </div>
        <div className="search-header">
          <input
            type="text"
            placeholder="Busque pelo nome"
            className="text-input filter-input"
            value={search}
            onChange={this.onSearchChange}
          />

          <div className="pagination-header">
            <Paginate
              pageCount={totalPages}
              forcePage={page - 1}
              onPageChange={this.handlePageClick}
            />
          </div>
        </div>

        <TeamsDetailsManagementMarketTable
          markets={selectMarkets.slice(((page - 1) * totalPage), page * totalPage)}
          hasAction={hasAction}
          onHire={this.props.hire}
          onSend={this.props.send}
          onCancel={this.props.cancel}
        />
      </div>
    );
  }
}

export default TeamsDetailsManagementMarketSearch;
