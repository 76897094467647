import MarketHistoryRequests from '../../server/marketHistory';

import { addLoading, removeLoading } from './loading';

import MarketData from '../../../data/market';

export const marketHistoryGet = (gameMode, type, lastDate) => async (dispatch) => {
  dispatch(addLoading());

  const limit = MarketData.history.perPage;
  if (type === 'all') type = undefined;

  try {
    const data = await MarketHistoryRequests.getHistory(gameMode, type, lastDate, limit);
    return data;
  } finally {
    dispatch(removeLoading());
  }
};

export default marketHistoryGet;
