import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import GameModeUtils from '../../../app/utils/gameMode';

import LandingGameModesMode from './mode';

export const LandingGameModes = () => (
  <div className="landing-game-modes">
    <div className="content-container">
      <Grid className="modes" fluid>
        <Row>
          <Col md={4}>
            <LandingGameModesMode
              gameMode={GameModeUtils.data.single.mode}
              text={'Neste modo, o jogador irá criar um clube, podendo escolher o nome e o escudo que deseja. '
              + 'Seu clube irá disputar torneios, amistosos e outro modos de disputa com os demais clubes fantasias. '
              + 'Os pontos realizados nas apostas pelo seu jogador representarão o número de gols feito pelo seu '
              + 'clube nos jogos.'}
            />
          </Col>
          <Col md={4}>
            <LandingGameModesMode
              gameMode={GameModeUtils.data.club.mode}
              text={'Neste modo, o site disponibiliza clubes, que são divididos em Ligas. Cada clube pode ter '
              + 'até 6 jogadores e são administrados por um jogador denominado “Presidente”. O presidente '
              + 'poderá nomear auxiliares em sua administração. As posições para disputa de jogos com outros '
              + 'clubes seguem as posições do Futsal. Neste modo valem as apostas de todos os jogador do clube.'}
            />
          </Col>
          <Col md={4}>
            <LandingGameModesMode
              gameMode={GameModeUtils.data.national.mode}
              text={'Neste modo, o site disponibiliza seleções, que são divididas em Federações. Cada seleção pode '
              + 'ter até 14 jogadores e são administrados por um jogador denominado “Presidente”. O presidente '
              + 'poderá nomear auxiliares em sua administração. As posições para disputa de jogos com outras '
              + 'seleções seguem as posições do Futebol de Campo. Neste modo valem as apostas de todos os jogador '
              + 'da seleção.'}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
);
export default LandingGameModes;
