import React from 'react';
import { connect } from 'react-redux';

import { campaignsGetUsersInvitation, campaignsSendInvitation, campaignsCancelInvitation }
  from '../../../../../app/store/actions/campaigns';

import Paginate from '../../../../elements/paginate';

import CampaignInProgressSettingsInvitationsTable from './table';

export class CampaignDetailsActiveInvitations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      page: 1,
      users: [],
      invitations: props.campaign.invitations,
    };
  }

  async componentDidMount() {
    const { campaign } = this.props;
    const users = await this.props.getusers(campaign.id);
    await this.setState({ users });
  }

  sendInvitation = async (user) => {
    const { campaign } = this.props;
    const { invitations } = this.state;

    await this.props.sendInvitation(campaign.id, [user.id]);

    invitations.push(user);
    await this.setState(() => ({ invitations }));
  }

  cancelInvitation = async (userId) => {
    const { campaign } = this.props;
    const { invitations } = this.state;

    await this.props.cancelInvitation(campaign.id, userId);

    const userIndex = invitations.findIndex(u => u.id === userId);
    if (userIndex !== -1) invitations.splice(userIndex, 1);
    await this.setState(() => ({ invitations }));
  }

  onSearchChange = (e) => {
    const search = e.target.value.toLowerCase();
    this.setState(() => ({ search, page: 1 }));
  };

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  render() {
    const { campaign, maxUsers } = this.props;
    const { users, search, page } = this.state;

    const totalPage = 6;

    let players = users.filter(player => !campaign.users.find(member => member.id === player.id));
    players.forEach((player) => {
      player.sent = campaign.invitations.find(member => member.id === player.id);
    });
    if (search) {
      players = players.filter(player => (player.username.toLowerCase().includes(search.toLowerCase())));
    }

    const totalPages = Math.ceil(players.length / totalPage);

    return (
      <div className="campaign-invite-search">
        <div className="title-line">
          {'Convidar Jogadores'}
        </div>
        <div className="search-header">
          <input
            type="text"
            placeholder="Busque pelo nome"
            className="text-input filter-input"
            value={search}
            onChange={this.onSearchChange}
          />

          <div className="pagination-header">
            <Paginate
              pageCount={totalPages}
              forcePage={page - 1}
              onPageChange={this.handlePageClick}
            />
          </div>
        </div>

        {players && (
        <CampaignInProgressSettingsInvitationsTable
          users={players.slice(((page - 1) * totalPage), page * totalPage)}
          isMaxUsers={campaign.users.length + campaign.invitations.length === maxUsers}
          sendInvitation={this.sendInvitation}
          cancelInvitation={this.cancelInvitation}
        />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getusers: campaignId => dispatch(campaignsGetUsersInvitation(campaignId)),
  sendInvitation: (campaignId, users) => dispatch(campaignsSendInvitation(campaignId, users)),
  cancelInvitation: (campaignId, user) => dispatch(campaignsCancelInvitation(campaignId, user)),
});

const mapStateToProps = state => ({
  maxUsers: state.settings.campaigns && state.settings.campaigns.max_users,
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetailsActiveInvitations);
