import React from 'react';

import pageTitleService from '../../../app/services/pageTitle';
import GameModeUtil from '../../../app/utils/gameMode';

import translator from '../../../data/translator';

import TeamsListFederation from './federation/teamsListFederation';
import TeamsListLeague from './league/teamsListLeague';

export class TeamsList extends React.Component {
  componentDidMount() {
    const { modeData } = this.props;
    const { mode } = modeData;
    pageTitleService.setTitle(translator.p.gameModes[GameModeUtil.data[mode].mode]);
  }

  render() {
    const { modeData } = this.props;
    const { list, mode } = modeData;

    switch (list) {
      case 'federation': {
        return (<TeamsListFederation gameMode={mode} />);
      }
      case 'league': {
        return (<TeamsListLeague gameMode={mode} />);
      }
      default:
        return (null);
    }
  }
}
export default TeamsList;
