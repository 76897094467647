import React from 'react';

import { history } from '../../../../../../routers/appRouter';

import CompetitionsUtils from '../../../../../../app/utils/competitions';
import translator from '../../../../../../data/translator';

import Tabs from '../../../../../elements/tabs/tabs';

export class TeamsCompetitionsDetailsInformationHeaderGroups extends React.Component {
  selectGroup = (groupId) => {
    history.push(groupId);
  }

  goToGroup = (e) => {
    const groupId = e.target.value;
    this.selectGroup(groupId);
  }

  render() {
    const { phases = [], currentGroup = {} } = this.props;

    const phaseTypes = phases.map(phase => ({
      name: translator.s.groups[phase.stage],
      group: phase.groups[0].id,
      active: currentGroup.stage === phase.stage,
    }));

    let groups = [];
    const currentPhase = phases.find(phase => currentGroup.stage === phase.stage);
    if (currentPhase) ({ groups } = currentPhase);

    return (
      <div className="content-container">
        <div className="title-center-menu">
          {translator.s.groups[currentGroup.stage]}
        </div>
        {phases.length > 1 && (
        <Tabs
          items={phaseTypes.map(phaseType => ({
            name: phaseType.name,
            onClick: () => this.selectGroup(phaseType.group),
            active: phaseType.active,
          }))}
        />
        )}
        {groups.length > 1 && (
        <div className="filter-select types center">
          <select value={currentGroup.id} onChange={this.goToGroup}>
            {groups.map(group => (
              <option key={group.id} value={group.id}>
                {CompetitionsUtils.getGroupName(group)}
              </option>
            ))}
          </select>
        </div>
        )}
      </div>
    );
  }
}

export default TeamsCompetitionsDetailsInformationHeaderGroups;
