import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';

export const CampaignInProgressTeamInformationVictory = () => (
  <div className="info victory">
    <div className="title">Campeão!</div>
    <div className="icon">
      <FontAwesomeIcon icon={faAward} />
    </div>
    <div className="text">Parabéns pela conquista!</div>
  </div>
);

export default CampaignInProgressTeamInformationVictory;
