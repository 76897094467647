import React from 'react';

import GuideWrapper from '../wrapper';

import GuideSingleGeneral from './general';
import GuideCompetitions from '../competitions';
import GuideSingleRanked from './ranked';
import GuideSinglePenalties from './penalties';

export const GuideSingle = () => {
  const menus = [
    { key: 'general', title: 'Geral', component: GuideSingleGeneral },
    { key: 'ranked', title: 'Rankeado', component: GuideSingleRanked },
    { key: 'competitions', title: 'Competições', component: GuideCompetitions },
    { key: 'penalties', title: 'Pênaltis', component: GuideSinglePenalties },
  ];

  return <GuideWrapper title="Regulamento" menus={menus} gameMode="single" />;
};

export default GuideSingle;
