import TeamsRolesRequests from '../../server/teamsRoles';
import { teamsGetTeam } from './teams';
import { addLoading, removeLoading } from './loading';

export const teamsRolesSetPresident = userId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsRolesRequests.setPresident(team.id, userId);
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsRolesUnsetPresident = () => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsRolesRequests.unsetPresident(team.id);
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsRolesSetRole = (role, userId) => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsRolesRequests.setRole(team.id, role, userId);
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
    } finally {
      dispatch(removeLoading());
    }
  }
};


export const teamsRolesUnsetRole = role => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsRolesRequests.unsetRole(team.id, role);
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
    } finally {
      dispatch(removeLoading());
    }
  }
};
