import moment from 'moment-timezone';

import RoundsRequests from '../../server/rounds';
import { addLoading, removeLoading } from './loading';
import { showAlertError, showAlertSuccess } from './alert';
import { setAvailablesSuggestion } from './roundsBets';

export const setRound = (roundNumber, round) => ({
  type: 'ROUNDS_ROUND_SET',
  roundNumber,
  round,
});

export const setCurrentRound = current => ({
  type: 'ROUNDS_CURRENT_ROUND_SET',
  current,
});

export const updateCurrentDateRound = date => ({
  type: 'ROUNDS_CURRENT_DATE_UPDATE',
  date,
});

export const setStatistics = (roundNumber, statistics) => ({
  type: 'ROUNDS_STATISTICS_SET',
  roundNumber,
  statistics,
});


export const setCompetitions = competitions => ({
  type: 'ROUNDS_COMPETITIONS_SET',
  competitions,
});

export const roundsGetCurrentRound = () => async (dispatch, getState) => {
  const currentOld = getState().rounds.current;

  const current = await RoundsRequests.getCurrentRound();

  await dispatch(setCurrentRound(current));
  if (current.updated_at && (!currentOld || currentOld.updated_at !== current.updated_at)) {
    await dispatch(updateCurrentDateRound(current.updated_at));
  }
};

export const roundsGetRound = roundNumber => async (dispatch) => {
  dispatch(addLoading());

  try {
    const round = await RoundsRequests.getRound(roundNumber);
    return round;
  } finally {
    dispatch(removeLoading());
  }
};

export const roundsGetStatistics = roundNumber => async (dispatch, getState) => {
  const statistics = getState().rounds.statistics[roundNumber];
  if (!statistics) {
    dispatch(addLoading());

    try {
      const data = await RoundsRequests.getStatistics(roundNumber);
      if (data.statistics) dispatch(setStatistics(roundNumber, data.statistics));
      if (data.round) dispatch(setRound(roundNumber, data.round));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const roundsUpdateScore = (games = []) => async (dispatch) => {
  dispatch(addLoading());

  const data = games.map(game => ({
    status: game.status,
    score_home: game.score_home,
    score_away: game.score_away,
  }));

  try {
    await RoundsRequests.updateScore({ games: data });
    await dispatch(roundsGetCurrentRound());
  } finally {
    dispatch(removeLoading());
  }
};


export const roundsFinishRoundGames = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    await RoundsRequests.finishedRoundGames();
    await dispatch(roundsGetCurrentRound());
  } finally {
    dispatch(removeLoading());
  }
};

export const roundsCompetitionsGet = () => async (dispatch, getState) => {
  if (!getState().rounds.competitions) {
    dispatch(addLoading());
    try {
      const competitions = await RoundsRequests.getRoundCompetitions();
      dispatch(setCompetitions(competitions));
    } finally {
      dispatch(removeLoading());
    }
  }
};

const formatGames = games => games.map((game) => {
  const gameFormatted = { date: moment(game.date, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DD HH:mm') };
  if (game.team_home) gameFormatted.team_home = game.team_home;
  if (game.team_away) gameFormatted.team_away = game.team_away;
  if (game.competition) gameFormatted.competition = game.competition;
  return gameFormatted;
});

export const roundsCreate = games => async (dispatch) => {
  dispatch(addLoading());
  try {
    await RoundsRequests.createRound({ games: formatGames(games) });
    dispatch(setAvailablesSuggestion([]));
    dispatch(showAlertSuccess('Rodada criada com sucesso!'));
  } catch (err) {
    dispatch(showAlertError());
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};


export const roundsUpdate = (roundNumber, games) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    await RoundsRequests.updateRound({ number: roundNumber, games: formatGames(games) });
    dispatch(setRound(roundNumber));
    dispatch(showAlertSuccess('Rodada editada com sucesso!'));
    if (getState().rounds.current && getState().rounds.current.number === roundNumber) {
      await dispatch(roundsGetCurrentRound());
    }
  } catch (err) {
    dispatch(showAlertError());
    throw err;
  } finally {
    dispatch(removeLoading());
  }
};
