import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../elements/top5/top5Section';

const RecordsTop5Achievements = (props) => {
  const {
    xp, champion, second_place: secondPlace, third_place: thirdPlace, striker, old,
  } = props.data;

  return (
    <Top5Section title="Conquistas">
      <Grid fluid>
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(champion, 'Títulos', 'Vezes')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(secondPlace, 'Segundo Lugar', 'Vezes')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(thirdPlace, 'Terceiro Lugar', 'Vezes')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(striker, 'Artilheiro', 'Vezes')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(xp, 'XP Total', 'XP')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(old, 'Títulos Antigos', 'Vezes')}
          </Col>
        </Row>
      </Grid>
    </Top5Section>
  );
};

export default RecordsTop5Achievements;
