import React from 'react';

export const GuideClubGeneral = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Neste modo, o site disponibiliza clubes, que são divididos em '}
          <b>Federações</b>
          {'. Você será um jogador que irá integrar o elenco de um desses clubes.'}
        </p>
        <p>
          {'Cada clube pode ter até '}
          <b>
            {'6 jogadores'}
          </b>
          {' (5 titulares e 1 reserva)'}
          {' e são administrados por um jogador denominado '}
          <b>Presidente</b>
          {'. O presidente poderá nomear auxiliares em sua administração. As posições para '
          + 'disputa de jogos com outras seleções seguem as posições do '}
          <b>Futsal</b>
          {'.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Participar de um clube'}
      </h4>
      <div className="section-content">
        <p>
          {'Para entrar em um clube, cabe exclusivamente ao jogador essa escolha. Assim, ele deve aceitar convites '
        + 'que sinta que o ambiente entre os jogadores seja de respeito e camaradagem.'}
        </p>
        <p>
          {'Lembre-se que, em condições normais, a transferência somente é permitida com a abertura do mercado. Será '
        + 'oferecida também a opção de "Aceitar qualquer Clube", para o caso você não tenha preferências.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuideClubGeneral;
