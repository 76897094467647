import React from 'react';
import { connect } from 'react-redux';

export const GuideSingleRanked = ({ multiplier, minTeams, leagues }) => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Serão campeonatos criados a cada 8 rodadas, reunindo todos os clubes ativos '}
          {'e que seu fundador tenha apostado.'}
        </p>
        <p>
          {'Serão gerados jogos aleatoriamente entre os clubes em cada rodada. '}
          {'Quando tiver um total de jogadores impares que palpitarem, um clube '
          + 'aleatório ficará sem jogo na rodada.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Acesso e Rebaixamento'}
      </h4>
      <div className="section-content">
        <p>
          {'Em toda competição criada e que tiver pelo menos '}
          <b>{minTeams}</b>
          {' clubes, '}
          <b>{multiplier * 100}</b>
          {'% dos participantes subirão.'}
        </p>
        <p>
          {'Tirando a '}
          <b>{leagues[0].name}</b>
          {', todas as competições terão '}
          <b>{multiplier * 100}</b>
          {'% dos clubes rebaixados.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Divisões'}
      </h4>
      <div className="section-content">
        <ul className="list">
          {leagues.map(league => (
            <li key={league.name}>
              <b>
                {league.name}
              </b>
              {` (${league.xp} XP)`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </React.Fragment>
);

const mapStateToProps = state => ({
  multiplier: state.settings.competitions_ranked && state.settings.competitions_ranked.multiplier,
  minTeams: state.settings.competitions_ranked && state.settings.competitions_ranked.min_teams,
  leagues: state.settings.competitions_ranked && state.settings.competitions_ranked.leagues,
});

export default connect(mapStateToProps)(GuideSingleRanked);
