import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

import { authenticateLogin } from '../../../app/store/actions/authenticate';
import { showAlertError } from '../../../app/store/actions/alert';
import { history } from '../../../routers/appRouter';

export class LandingHeaderContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailOrUsername: '',
      password: '',
    };
  }

  onEmailOrUsernameChange = (e) => {
    const emailOrUsername = e.target.value;
    this.setState(() => ({ emailOrUsername }));
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password }));
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { emailOrUsername, password } = this.state;

    if (emailOrUsername && password) {
      try {
        await this.props.login(emailOrUsername, password);
        history.push('/');
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { code } = err.response.data.error;

          switch (code) {
            case 20003:
              this.props.alertError('Você digitou a senha incorretamente. Tente novamente.');
              break;
            case 50201:
            default:
              this.props.alertError('O e-mail inserido não corresponde a nenhuma conta. Cadastre-se para participar.');
              break;
          }
        }
      }
    }
  };

  render() {
    const { emailOrUsername, password } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="title">
          <b>
            {'Bem vindo ao Barbolão'}
          </b>
        </div>
        <div className="field">
          <div className="label">
            {'Email ou nome do usuário'}
          </div>
          <div className="input">
            <input
              type="text"
              className="text-input"
              value={emailOrUsername}
              onChange={this.onEmailOrUsernameChange}
            />
            <div className="icon">
              <FontAwesomeIcon icon={faUser} />
            </div>
          </div>
        </div>
        <div className="field">
          <div className="label">
            {'Senha'}
          </div>
          <div className="input">
            <input
              type="password"
              className="text-input"
              value={password}
              onChange={this.onPasswordChange}
            />
            <div className="icon">
              <FontAwesomeIcon icon={faLock} />
            </div>
          </div>
        </div>
        <div className="forget-password">
          <Link to="/esqueci-senha">
            {'esqueceu a senha?'}
          </Link>
        </div>
        <button className="button" type="submit">
          {'Entrar'}
        </button>
        <div className="register">
          {'Ainda não tem uma conta? '}
          <Link to="/cadastro">
            {'Cadastre aqui'}
          </Link>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  login: (emailOrUsername, password) => dispatch(authenticateLogin(emailOrUsername, password)),
  alertError: message => dispatch(showAlertError(message)),
});

export default connect(undefined, mapDispatchToProps)(LandingHeaderContent);
