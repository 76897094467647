import React from 'react';
import { Link } from 'react-router-dom';

import GamesUtils from '../../../../app/utils/games';

const GamePlayOff = (props) => {
  const { game } = props;

  if (!game) return (null);
  return (
    <div className="game-playoff">
      <div className="round">
        {'Rodada '}
        {game.round}
      </div>
      <div className="result">
        <Link to={GamesUtils.getLink(game)}>
          {game.team_home.abbr}
          {' '}
          {game.score_home}
          {' X '}
          {game.score_away}
          {' '}
          {game.team_away.abbr}
        </Link>
      </div>
    </div>
  );
};

export default GamePlayOff;
