import React from 'react';

import TeamsGamesBetsCardGoals from './goals';

const TeamsGamesBetsCardInformationMobile = ({
  home, away, homeGoals, awayGoals,
}) => (
  <div className="mobile-players">
    <div className="player-name">
      {home}
      {homeGoals > 0 && <TeamsGamesBetsCardGoals goals={homeGoals} className="home" />}
    </div>
    <div className="player-name">
      {awayGoals > 0 && <TeamsGamesBetsCardGoals goals={-homeGoals} className="away" />}
      {away}
    </div>
  </div>
);

export default TeamsGamesBetsCardInformationMobile;
