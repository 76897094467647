import React from 'react';

import TutorialWrapper from '../elements/tutorial/tutorial';
import { scrollTo } from '../../routers/appRouter';

export class Privacy extends React.Component {
  async componentDidMount() {
    scrollTo('header-bottom');
  }

  render() {
    return (
      <div className="content-container min-container">
        <h3 className="title-center">
          {'Política de Privacidade'}
        </h3>
        <TutorialWrapper>
          <i>
            {'Última modificação: 20 de dezembro de 2017.'}
          </i>
          <div className="section">
            <p>
              {'Como usuário do site Barbolão – Bolão de Futebol, você está de acordo com as '
          + 'condições de uso e a política de privacidade que será descrita abaixo. Podemos '
          + 'fazer alterações sobre as mesmas a qualquer momento sem aviso prévio, sendo '
          + 'atualizadas no mesmo momento nesta página.'}
            </p>
          </div>
          <div className="section">
            <h4>
              {'1. Suas Informações'}
            </h4>
            <div className="section-content">
              <p>
                {'Usamos as informações que coletamos em nosso site para fornecer, manter, proteger '
            + 'e melhorar os serviços por este prestado, desenvolver novos e proteger o Barbolão '
            + 'e nossos colaboradores. Também usamos essas informações para oferecer ao usuário '
            + 'uma maior transparência dentro do site e termos maior credibilidade perante nossos '
            + 'visitantes.'}
              </p>
              <p>
                {'Quando o usuário entra em contato com o Barbolão, mantemos um registro da comunicação '
            + 'para ajudar a resolver qualquer problema que ele possa estar enfrentando. Podemos '
            + 'usar o endereço de e-mail do usuário para informar a ele sobre nossos serviços, por '
            + 'exemplo, as próximas mudanças ou melhorias.'}
              </p>
              <p>
                {'Solicitaremos sua autorização antes de usar informações para outros fins que não os '
            + 'definidos nesta Política de Privacidade.'}
              </p>
            </div>
          </div>
          <div className="section">
            <h4>
              {'2. Transparência e Escolha'}
            </h4>
            <div className="section-content">
              <p>
                {'As pessoas têm diferentes preocupações sobre privacidade. Nosso objetivo é a clareza '
            + 'quanto às informações que solicitamos durante o cadastro, de modo que o usuário possa '
            + 'fazer aceitar ou não sobre como elas são armazenadas em nosso banco de dados. Por '
            + 'exemplo, o usuário poderá:'}
              </p>
              <ol type="a">
                <li>
                  {'Aceitar ou não em receber novidades do Barbolão ou de terceiros (quando houver) em '
              + 'seu e-mail cadastrado.'}
                </li>
                <li>
                  {'Editar suas informações de cadastro.'}
                </li>
                <li>
                  {'Tornar público ou privado suas informações de cadastro dentro do site para os demais '
              + 'usuários.'}
                </li>
              </ol>
            </div>
          </div>
          <div className="section">
            <h4>
              {'3. Segurança das Informações'}
            </h4>
            <div className="section-content">
              <p>
                {'Trabalhamos com bastante empenho para proteger o Barbolão e nossos usuários de acesso '
            + 'não autorizado ou alteração, divulgação ou destruição não autorizada das informações '
            + 'que detemos. Especificamente:'}
              </p>
              <ol type="a">
                <li>
                  {'Criptografamos nosso site usando SSL.'}
                </li>
                <li>
                  {'Analisamos nossa coleta de informações, práticas de armazenamento e processamento, '
              + 'inclusive medidas de segurança lógica, para proteção contra acesso não autorizado ao '
              + 'sistema.'}
                </li>
                <li>
                  {'Restringimos o acesso a informações pessoais por parte dos moderadores e terceiros do '
              + 'Barbolão que necessitam saber essas informações para processá-las para nós, e que '
              + 'estão sujeitos a rigorosas obrigações contratuais de confidencialidade, podendo ser '
              + 'processados ou dispensados se deixarem de cumprir tais obrigações.'}
                </li>
              </ol>
            </div>
          </div>
          <div className="section">
            <h4>
              {'4. Quando esta Política de Privacidade se aplica'}
            </h4>
            <div className="section-content">
              <p>
                {'Nossa Política de Privacidade se aplica a todos os recursos oferecidos pelo Barbolão, '
            + 'mas exclui serviços que tenham políticas de privacidade separadas que não incorporam '
            + 'esta Política de Privacidade.'}
              </p>
              <p>
                {'Nossa Política de Privacidade não se aplica a serviços oferecidos por outras empresas '
            + 'ou indivíduos, inclusive produtos ou sites que podem ser exibidos ao usuário nos resultados '
            + 'de pesquisa, sites que podem incluir o nome do Barbolão, ou outros sites com links do nosso '
            + 'site. Nossa Política de Privacidade não abrange as práticas de informação de outras empresas '
            + 'e organizações que anunciam nossos serviços e que podem usar cookies, pixels tags e outras '
            + 'tecnologias para oferecer anúncios relevantes.'}
              </p>
            </div>
          </div>
          <div className="section">
            <h4>
              {'5. Alterações'}
            </h4>
            <div className="section-content">
              <p>
                {'Nossa Política de Privacidade pode ser alterada de tempos em tempos. Nós não reduzimos os '
            + 'direitos do usuário nesta Política de Privacidade sem seu consentimento explícito.'}
              </p>
              <p>
                {'Publicaremos quaisquer alterações da política de privacidade nesta página e, se as alterações '
            + 'forem significativas, forneceremos um aviso com mais destaque (incluindo, para alguns serviços, '
            + 'notificação por e-mail das alterações da política de privacidade). Também manteremos as versões '
            + 'anteriores desta Política de Privacidade arquivadas para que o usuário possa visualizá-las.'}
              </p>
            </div>
          </div>
          <div className="section">
            <h4>
              {'6. Dúvidas e Sugestões'}
            </h4>
            <div className="section-content">
              <p>
                {'Quaisquer dúvidas e/ou sugestões sobre esta Política de Privacidade devem ser endereçadas '
            + 'a barbolao@gmail.com'}
              </p>
            </div>
          </div>
        </TutorialWrapper>
      </div>
    );
  }
}

export default Privacy;
