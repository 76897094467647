import React from 'react';

import TutorialWrapper from '../elements/tutorial/tutorial';

const RegistrationError = props => (
  <TutorialWrapper image="registration/error.jpg">
    <h3 className="title-center">
      {'Erro!'}
    </h3>
    <p className="text">
      {'Aconteceu algo inesperado. O cadastro não foi finalizado com sucesso. '
      + 'Vamos revisar os dados e tentar novamente. Caso o erro persista, entre em contato com nossa equipe.'}
    </p>
    <button className="button normal" type="button" onClick={() => props.goToStep(2)}>
      {'Revisar'}
    </button>
  </TutorialWrapper>
);

export default RegistrationError;
