import React from 'react';
import { connect } from 'react-redux';
import { scrollTo } from '../../../../routers/appRouter';
import { teamsGetGames } from '../../../../app/store/actions/teams';

import GamesData from '../../../../data/games';

import GamesTeamHighlight from '../../../elements/games/teamHighlight/highlight';

export class TeamsDetailsGames extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished: false,
      total: 0,
    };
  }

  async componentDidMount() {
    const { games, numberNumber } = this.props;
    if (!games) await this.props.getGames(numberNumber, 1);
    else this.updateData(games);
    scrollTo('menu-secondary');
  }

  componentWillReceiveProps(nextProps) {
    const { games } = nextProps;
    if (games) this.updateData(games);
  }

  moreGames = async () => {
    const { games, numberNumber } = this.props;
    const page = Math.ceil((games || []).length / GamesData.list.gamesPerPage);
    await this.props.getGames(numberNumber, page + 1);
  }

  updateData(games = []) {
    const { total } = this.state;
    const finished = games.length === total || games.length % GamesData.list.gamesPerPage !== 0;
    this.setState(() => ({ games, finished, total: games.length }));
  }

  render() {
    const { games, finished } = this.state;
    const { teamId } = this.props;

    if (!games) return (null);

    const gamesPerRound = {};

    games.forEach((game) => {
      if (!gamesPerRound[game.round]) gamesPerRound[game.round] = [];
      gamesPerRound[game.round].push(game);
    });

    return (
      <div className="team-games">
        <div className="content-container margin-bottom">
          <div className="title-center-menu">
            {'Jogos'}
          </div>
          {Object.keys(gamesPerRound).sort((a, b) => b - a).map(roundNumber => (
            <React.Fragment key={roundNumber}>
              {gamesPerRound[roundNumber].map(game => (
                <GamesTeamHighlight key={game.id} teamId={teamId} game={game} />
              ))}
            </React.Fragment>
          ))}

          {!finished && (
          <button
            className="button normal"
            type="button"
            onClick={this.moreGames}
          >
            {'Mais Jogos'}
          </button>
          )}

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getGames: (round, limit, gameId) => dispatch(teamsGetGames(round, limit, gameId)),
});

const mapStateToProps = state => ({
  teamId: state.teams.details && state.teams.details.id,
  games: state.teams.details && state.teams.details.games,
  numberNumber: state.rounds.numberClosed + (state.rounds.isProgress ? 0 : 1),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsGames);
