import RestService from '../services/rest';
import PlayersUtils from '../utils/players';
import TeamsUtils from '../utils/teams';
import CompetitionsUtils from '../utils/competitions';

async function search(searchText) {
  const result = await RestService.getAuthenticated(`search?search=${searchText}`);
  result.users = result.users.map(user => PlayersUtils.completePlayer(user));
  result.teams = result.teams.map(team => TeamsUtils.completeTeam(team));
  result.competitions = result.competitions.map(competition => CompetitionsUtils.completeCompetition(competition));
  return result;
}

export default {
  search,
};
