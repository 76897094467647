import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { scrollTo } from '../../../routers/appRouter';

import { realTeamsGet } from '../../../app/store/actions/realTeams';

import pageTitleService from '../../../app/services/pageTitle';

import RecordsSupportersTable from './table';
import RecordsSupportersChart from './chart';
import RecordsSupportersWordCloud from './wordCloud';

export class RecordsSupporters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'chart',
    };
  }

  async componentDidMount() {
    pageTitleService.setTitle('Torcida');
    await this.props.getRealTeams();
    scrollTo('menu-secondary');
  }

  changeView = async (view) => {
    await this.setState(() => ({ view }));
  }

  render() {
    const { view } = this.state;
    const { teams } = this.props;

    const teamsWithFans = teams.filter(t => t.fans > 0);
    teamsWithFans.sort((a, b) => b.fans - a.fans);

    return (
      <div className="content-container records-supporters">
        <div className="title-center-menu">
          {'Torcida'}
        </div>
        <Grid fluid style={{ padding: 0 }}>
          <Row>
            <Col md={6}>
              <RecordsSupportersTable teams={teamsWithFans} />
            </Col>
            <Col md={6} className="charts">
              <div className="title-line">
                {'Maiores Torcidas'}
              </div>
              <button
                type="button"
                className={`button normal${view === 'chart' ? ' active' : ''}`}
                onClick={() => this.changeView('chart')}
              >
                {'Gráfico'}
              </button>
              <button
                type="button"
                className={`button normal${view === 'words' ? ' active' : ''}`}
                onClick={() => this.changeView('words')}
              >
                {'Nuvem de Palavras'}
              </button>
              {view === 'chart' ? (
                <RecordsSupportersChart teams={teamsWithFans} />
              ) : (
                <RecordsSupportersWordCloud teams={teamsWithFans} />
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getRealTeams: () => dispatch(realTeamsGet()),
});

const mapStateToProps = state => ({
  teams: state.realTeams.teams || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordsSupporters);
