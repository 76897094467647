import React from 'react';
import TagCloud from 'react-tag-cloud';

import RecordsData from '../../../data/records';

import TeamsUtils from '../../../app/utils/teams';

export const RecordsSupportersWordCloud = (props) => {
  const { teams } = props;

  const minFont = RecordsData.supporters.words.font.min;
  const maxFont = RecordsData.supporters.words.font.max;

  const maxFans = teams.reduce((a, b) => Math.max(a, b.fans), 0);

  const fansTeams = teams.map(team => ({
    font: minFont + Math.floor((Math.log(team.fans) / Math.log(maxFans)) * (maxFont - minFont)),
    id: team.id,
    name: team.name,
    color: TeamsUtils.getBackgroundColor(team),
  }));

  return (
    <div className="words">
      <TagCloud
        style={{
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          fontStyle: 'italic',
          padding: 10,
          width: '100%',
          height: '100%',
        }}
      >
        {fansTeams.map(team => (
          <div
            key={team.id}
            style={{ color: team.color, fontSize: team.font }}
          >
            {team.name}
          </div>
        ))}
      </TagCloud>
    </div>
  );
};


export default RecordsSupportersWordCloud;
