const initialState = {
  details: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CAMPAIGNS_TYPES_SET':
      state.types = action.campaignsTypes;
      return state;
    case 'CAMPAIGNS_DETAILS_SET':
      return {
        ...state,
        details: action.campaign || {},
      };
    default:
      return state;
  }
};
