import React from 'react';

import ImageLoad from '../image/imageLoad';

const CardDefault = ({
  name, tag, icons, tagStyle, badge, level, levelStyle, round, nameClass = '',
}) => (
  <div className="card-all card-default" key={name}>
    <div className={`badge${(round) ? ' round' : ''}`}>
      <ImageLoad
        src={badge.url}
        alt={badge.name}
        placeholder="/images/team_placeholder.png"
      />
      {level && (
      <div className={`level${levelStyle ? ` ${levelStyle}` : ''}`}>
        {level}
      </div>
      )}
    </div>
    <div className="texts">
      <div className={`name ${nameClass}`}>
        {name}
      </div>
      <div className={`tag ${tagStyle}`}>
        {tag}
      </div>
      {icons && (
      <div className="icons-field">
        {icons}
      </div>
      )}
    </div>
  </div>
);

export default CardDefault;
