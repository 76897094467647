import React from 'react';

import TeamsGamesBetsCardGames from './card/cardGames';
import TeamsGamesBetsCardMarket from './card/cardMarket';

import Tabs from '../../../../elements/tabs/tabs';

export class TeamsGamesBetsSingle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'teams',
    };
  }

  changeView = async (view) => {
    await this.setState(() => ({ view }));
  }

  render() {
    const { view } = this.state;
    const { game, round } = this.props;

    let GameDetailsElement = (null);

    if (view === 'teams') {
      GameDetailsElement = round.games.map(roundGame => (
        <TeamsGamesBetsCardGames
          key={roundGame.number}
          game={roundGame}
          teamHome={game.team_home}
          teamAway={game.team_away}
        />
      ));
    } else {
      GameDetailsElement = round.games.map(roundGame => (
        <TeamsGamesBetsCardMarket
          key={roundGame.number}
          title={`Jogo ${roundGame.number + 1}`}
          round={round}
          games={[roundGame.number]}
          homeBets={{
            bet: game.team_home.bets,
            score: (game.team_home.bets && game.team_home.bets.games[roundGame.number].score) || 0,
            user: game.team_home.user,
          }}
          awayBets={{
            bet: game.team_away.bets,
            score: (game.team_away.bets && game.team_away.bets.games[roundGame.number].score) || 0,
            user: game.team_away.user,
          }}
          goalsAbsolute
        />
      ));
    }

    return (
      <div key={game.id} className="game-bets-single">
        <Tabs
          items={[{
            name: 'Jogos',
            onClick: () => this.changeView('teams'),
            active: view === 'teams',
          }, {
            name: 'Confrontos',
            onClick: () => this.changeView('players'),
            active: view === 'players',
          }]}
        />
        {GameDetailsElement}
      </div>
    );
  }
}

export default TeamsGamesBetsSingle;
