import React from 'react';

import CardHighlight from '../../../elements/card/highlight';

const TeamHighlight = (props) => {
  const { team } = props;

  return (
    <CardHighlight
      name={team.name}
      badge={{
        url: team.badge.url,
        title: team.name,
      }}
      tag={team.user ? team.user.username : team.region}
    />
  );
};

export default TeamHighlight;
