import React from 'react';
import { connect } from 'react-redux';

export const Loading = ({ isLoading, inMaintenance }) => (
  <React.Fragment>
    {isLoading && !inMaintenance && (
      <div className="loader">
        <div className="content">
          <img key="loader" src="/images/loader.svg" alt="Loader" />
        </div>
      </div>
    )}
  </React.Fragment>
);

const mapStateToProps = state => ({
  isLoading: (state.loading > 0),
  inMaintenance: state.activities.maintenance,
});

export default connect(mapStateToProps)(Loading);
