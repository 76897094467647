import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const RankingTablePositionChange = ({ value }) => {
  if (!value) return (null);

  if (value > 0) {
    return (
      <React.Fragment>
        {value}
        <div className="up">
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {-value}
      <div className="down">
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    </React.Fragment>
  );
};

export default RankingTablePositionChange;
