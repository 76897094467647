import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../elements/top5/top5Section';

const RecordsTop5Collectibles = (props) => {
  const { total, uniques } = props.data;

  return (
    <Top5Section title="Colecionáveis">
      <Grid fluid>
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(total, 'Total de Itens', 'Itens')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(uniques, 'Itens Únicos', 'Itens')}
          </Col>
        </Row>
      </Grid>
    </Top5Section>
  );
};

export default RecordsTop5Collectibles;
