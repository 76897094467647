import React from 'react';
import { connect } from 'react-redux';

import BadgeListItem from '../../elements/badgeList/badgeList';

import { collectiblesUpdateChestItems } from '../../../app/store/actions/collectibles';

import colorsData from '../../../data/colors';
import collectiblesData from '../../../data/collectibles';

export class CollectiblesChestItems extends React.Component {
  componentDidMount() {
    const { chestName } = this.props;
    this.props.updateChestItems(chestName);
  }

  render() {
    const { chestName, collectibles = [], userCollectibles } = this.props;

    const colors = colorsData.teams.background;

    const items = collectibles.map((collectible) => {
      const rarity = collectiblesData.rarity[collectible.rarity] || {};
      const userItem = userCollectibles.find(c => c.id === collectible.id);
      const total = (userItem) ? userItem.amount : 0;

      return {
        id: collectible.id,
        color: collectible.colors[0] && colors[collectible.colors[0]],
        name: collectible.name,
        tag: {
          nameHover: rarity.name,
          colorHover: colorsData.layout[rarity.color],
        },
        badge: collectible.image.url,
        locked: !total,
        mark: `${total}x`,
      };
    });

    return (
      <div className="margin-bottom">
        <div className="title-center">
          {chestName}
        </div>
        <BadgeListItem items={items} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateChestItems: chestName => dispatch(collectiblesUpdateChestItems(chestName)),
});

const mapStateToProps = (state, ownProps) => {
  const { chestName } = ownProps.match.params;

  return {
    collectibles: state.collectibles.chests[chestName] && state.collectibles.chests[chestName].items,
    userCollectibles: state.user.collectibles,
    chestName,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectiblesChestItems);
