import React from 'react';

import TeamsListTeams from '../../../../teams/list/teamsListTeams';

const PlayersDetailsOverviewModes = ({ teams }) => {
  const gameModes = ['single', 'club', 'national', 'manager'];
  const teamsSorted = [];

  if (teams) {
    gameModes.forEach((gameMode) => {
      if (teams[gameMode]) teamsSorted.push(teams[gameMode]);
    });
  }

  const gridInfo = {
    lg: 3, md: 3, sm: 4, xs: 6,
  };

  if (teamsSorted.length === 0) return (null);

  return (
    <section className="modes">
      <div className="content-container">
        <TeamsListTeams teams={teamsSorted} gridInfo={gridInfo} around tagType="gameMode" />
      </div>
    </section>
  );
};

export default PlayersDetailsOverviewModes;
