import RealTeamsRequests from '../../server/realTeams';
import { addLoading, removeLoading } from './loading';

export const setRealTeams = realTeams => ({
  type: 'REAL_TEAMS_SET',
  realTeams,
});

export const realTeamsGet = () => async (dispatch, getState) => {
  if (!getState().realTeams.teams) {
    dispatch(addLoading());
    try {
      const realTeams = await RealTeamsRequests.getTeams();
      dispatch(setRealTeams(realTeams));
    } finally {
      dispatch(removeLoading());
    }
  }
};
