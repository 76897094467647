import React from 'react';

import GuideGeneralScore from '../general/score';

export const GuideSingleGeneral = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Neste modo, o jogador fundará um clube com o nome de sua escolha. Além de poder escolher o escudo '
      + 'que mais lhe agrade.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Jogos'}
      </h4>
      <div className="section-content">
        <p>
          {'O número de gols que o clube fará nos jogos será a sua pontuação nas apostas na rodada do '
          + 'fundador do clube.'}
        </p>
      </div>
    </div>
    <GuideGeneralScore />
  </React.Fragment>
);
export default GuideSingleGeneral;
