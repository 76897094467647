import React from 'react';

import GuideWrapper from '../wrapper';

import GuideManagerGeneral from './general';
import GuideManagerGames from './games';
import GuideCompetitions from '../competitions';
import GuideManagerPenalties from './penalties';

export const GuideManager = () => {
  const menus = [
    { key: 'general', title: 'Geral', component: GuideManagerGeneral },
    { key: 'games', title: 'Jogos', component: GuideManagerGames },
    { key: 'competitions', title: 'Competições', component: GuideCompetitions },
    { key: 'penalties', title: 'Pênaltis', component: GuideManagerPenalties },
  ];

  return <GuideWrapper title="Regulamento" menus={menus} gameMode="manager" />;
};

export default GuideManager;
