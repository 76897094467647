import React from 'react';
import { connect } from 'react-redux';

export class AccountPlayerPictureForm extends React.Component {
  onPictureChange = (e) => {
    const { files } = e.target;
    if (files) {
      this.props.updatePicture(files[0]);
    }
  };

  getFile = () => {
    document.getElementById('picture-file').click();
  };

  render() {
    const { username, picture } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <div
          className="user-picture"
          onClick={this.getFile}
          onKeyDown={this.getFile}
          role="presentation"
        >
          <img src={picture} alt={username} />
        </div>
        <input
          id="picture-file"
          type="file"
          accept=".jpg"
          className="hidden"
          onChange={this.onPictureChange}
        />
      </form>
    );
  }
}

const mapStateToProps = state => ({
  username: state.user.username,
  picture: state.user.picture && state.user.picture.url,
});

export default connect(mapStateToProps)(AccountPlayerPictureForm);
