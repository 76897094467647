import React from 'react';

import PlayersDetailsBetsRoundTeamNames from '../../players/details/bets/game/teamNames';
import PlayersDetailsBetsRoundTeam from '../../players/details/bets/game/team';
import AdministrationScoreFormTop from './administrationScoreFormTop';

const AdministrationScoreForm = ({
  games = [], canScore, onScoreChange, onStatusChange,
}) => (
  <div className="games">
    {games.map(game => (
      <div key={game.number} className="game">
        <PlayersDetailsBetsRoundTeamNames game={game} />

        <AdministrationScoreFormTop number={game.number} competition={game.competition} date={game.date} />

        <div className="bet">
          <PlayersDetailsBetsRoundTeam team={game.team_home} type="home" />

          <div className="result-form">
            <input
              type="tel"
              className="text-input"
              value={game.score_home !== undefined ? game.score_home : ''}
              onChange={e => onScoreChange(game.number, true, e.target.value)}
              maxLength={2}
              disabled={!canScore(game)}
            />
            <div>
              {'x'}
            </div>
            <input
              type="tel"
              className="text-input"
              value={game.score_away !== undefined ? game.score_away : ''}
              onChange={e => onScoreChange(game.number, false, e.target.value)}
              maxLength={2}
              disabled={!canScore(game)}
            />
          </div>

          <PlayersDetailsBetsRoundTeam team={game.team_away} type="away" />
        </div>

        <div className="status-form">
          <select value={game.status} onChange={e => onStatusChange(game.number, e.target.value)}>
            <option value="not_started">Não iniciado</option>
            <option value="canceled">Cancelado</option>
            <option value="in_progress">Em andamento</option>
            <option value="finished">Finalizado</option>
          </select>
        </div>

      </div>
    ))}
  </div>
);

export default AdministrationScoreForm;
