import React from 'react';

import Team from '../../../../elements/team';
import Table from '../../../../../elements/table';
import Paginate from '../../../../../elements/paginate';
import RankingTablePositionChange from '../../../../../elements/ranking/tablePositionChange';

import CompetitionsData from '../../../../../../data/competitions';
import CompetitionsUtils from '../../../../../../app/utils/competitions';

import TeamsCompetitionsDetailsInformationPositions from '../teamsCompetitionsDetailsInformationPositions';

export class TeamsCompetitionsDetailsInformationContest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  render() {
    const { page } = this.state;
    const {
      data, info = {}, userTeamIds = [], stagePositions,
    } = this.props;

    const classificatoryHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      {
        key: 'team', value: 'Clube', className: 'name', colspan: 3,
      },
      { key: 'pos_change', tdClassName: 'pos-change', hidden: true },
      {
        key: 'score', value: 'Pts', className: 'number', tdClassName: 'high',
      },
      { key: 'scores', value: 'Pontuações' },
    ];

    const totalPage = CompetitionsData.contest.limit;
    const totalPages = Math.ceil(data.length / totalPage);
    const contestData = data.slice(((page - 1) * totalPage), page * totalPage);

    const classificatoryData = contestData.map((row, index) => ({
      key: index,
      position: row.position,
      team: <Team data={row.team} type="table" />,
      pos_change: <RankingTablePositionChange value={row.position_last - row.position} />,
      score: row.score,
      scores: row.scores.join(' - '),
    }));

    const positionInfo = CompetitionsUtils.getStageByPosition(stagePositions);
    const lineClassNames = {};
    lineClassNames.position = contestData.map(row => ({
      className: positionInfo[row.position - 1] && positionInfo[row.position - 1].className,
    }));

    userTeamIds.forEach((userTeamId) => {
      const userTeamIndex = contestData.findIndex(row => userTeamId === row.team.id);
      if (userTeamIndex !== -1) classificatoryData[userTeamIndex].className = 'highlight';
    });

    return (
      <div className="contenst">
        {info.rounds_left > 0 && (
        <div className="information">
          {info.rounds_total - info.rounds_left}
          {'/'}
          {info.rounds_total}
          {' Rodadas'}
        </div>
        )}
        <Table header={classificatoryHeader} lines={classificatoryData} lineClassNames={lineClassNames} />
        <TeamsCompetitionsDetailsInformationPositions positionInfo={positionInfo} />
        <Paginate
          pageCount={totalPages}
          forcePage={page - 1}
          onPageChange={this.handlePageClick}
        />
      </div>
    );
  }
}

export default TeamsCompetitionsDetailsInformationContest;
