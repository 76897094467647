import React from 'react';

const TeamsGamesDetailsHeaderBottom = ({ teamName, text }) => {
  if (!text) return (null);

  return (
    <div className="row">
      {teamName}
      {':'}
      <span>
        {text}
      </span>
    </div>
  );
};

export default TeamsGamesDetailsHeaderBottom;
