import React from 'react';

import GuideWrapper from '../wrapper';

import GuidePlayerReputation from './reputation';
import GuidePlayerAchievements from './achievements';
import GuidePlayerValuation from './valuation';
import GuidePlayerStatistics from './statistics';
// import GuidePlayerPunishments from './punishments';

export const GuidePlayer = () => {
  const menus = [
    { key: 'reputation', title: 'Reputação', component: GuidePlayerReputation },
    { key: 'achievements', title: 'Conquistas', component: GuidePlayerAchievements },
    { key: 'valuation', title: 'Valor de Mercado', component: GuidePlayerValuation },
    { key: 'statistics', title: 'Estatísticas', component: GuidePlayerStatistics },
  ];

  return <GuideWrapper title="Regulamento" menus={menus} />;
};

export default GuidePlayer;
