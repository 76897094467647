import React from 'react';

export const GuideManagerGames = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Nos confrontos entre Clubes no Cartola, todos os jogadores da mesma posição enfrentarão todos'
        + ' os jogadores da mesma posição do outro clube. Ou seja, teremos 5 confrontos.'}
        </p>
        <p>
          {'A pontuação da posição será formada pela somatória da pontuação de cada jogador na rodada mais'
        + ' a bonificação da posição. Segue a bonificação por posição:'}
        </p>
        <ul className="list">
          <li>
            <b>
              {'Goleiro: '}
            </b>
            {'Dobra a pontuação do jogo quando terminar sem gols.'}
          </li>
          <li>
            <b>
              {'Zagueiro: '}
            </b>
            {'Dobra a pontuação do jogo quando o um dos clubes fizer 3 ou mais gols e não tomar gols.'}
          </li>
          <li>
            <b>
              {'Meia Campo: '}
            </b>
            {'Dobra a pontuação das cravadas de cada jogo.'}
          </li>
          <li>
            <b>
              {'Atacante: '}
            </b>
            {'Dobra a pontuação do jogo quando o jogo tiver 5 ou mais gols.'}
          </li>
          <li>
            <b>
              {'Técnico: '}
            </b>
            {'Dobra a pontuação dos jogos com dificuldade maior que 0.75'}
          </li>
        </ul>
        <p>
          {'Quem fizer mais pontos em cada confronto fará '}
          <b>um gol</b>
          {' para seu clube.'}
        </p>
        <p>
          {'O clube que fizer o gol terá um '}
          <b>gol extra</b>
          {' para cada jogador a menos que o clube tiver no confronto em relação ao adversário'}
        </p>
        <p>
          {'A soma de gols de cada confronto representará o resultado final do jogo.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuideManagerGames;
