import React from 'react';

const PlayersDetailsOverviewBanner = () => (
  <section className="banner-in-player">
    <a href="https://prosport.bet/" rel="noopener noreferrer" target="_blank">
      <img src="/images/banner_prosport.jpg" alt="Prosport" />
    </a>
  </section>
);

export default PlayersDetailsOverviewBanner;
