import React from 'react';
import { connect } from 'react-redux';

import { faHandshake } from '@fortawesome/free-solid-svg-icons';

import PagePlaceholder from '../../../../elements/pagePlaceholder';

import {
  teamsFriendlyGet, teamsFriendlySendProposal, teamsFriendlyCancelProposal,
  teamsFriendlyAcceptProposal, teamsFriendlyRejectProposal,
}
  from '../../../../../app/store/actions/teamsFriendly';

import TeamsDetailsManagementFriendlyReceived from './received/received';
import TeamsDetailsManagementFriendlySearch from './search/search';

export class TeamsDetailsManagementFriendly extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      receivedProposals: [],
      sentProposals: [],
    };
  }

  async componentDidMount() {
    const { hasFriendly } = this.props;
    if (!hasFriendly) {
      const data = await this.props.getTeamFriendly();
      if (data) {
        const { teams, received, sent } = data;
        this.updateData(teams, received, sent);
      }
    }
  }

  send = async (teamId) => {
    const { teams, sentProposals } = this.state;
    await this.props.sendProposal(teamId);
    const team = teams.find(t => t.id === teamId);
    if (team) sentProposals.push(team);
    await this.setState(() => ({ sentProposals }));
  }

  cancel = async (teamId) => {
    const { sentProposals } = this.state;
    await this.props.cancelProposal(teamId);
    const teamIndex = sentProposals.findIndex(t => t.id === teamId);
    if (teamIndex !== -1) sentProposals.splice(teamIndex, 1);
    await this.setState(() => ({ sentProposals }));
  }

  accept = async (teamId) => {
    await this.props.acceptProposal(teamId);
  }

  reject = async (teamId) => {
    const { receivedProposals } = this.state;
    await this.props.rejectProposal(teamId);
    const teamIndex = receivedProposals.findIndex(t => t.id === teamId);
    if (teamIndex !== -1) receivedProposals.splice(teamIndex, 1);
    await this.setState(() => ({ receivedProposals }));
  }

  updateData(teams, receivedProposals, sentProposals) {
    this.setState(() => ({ teams, receivedProposals, sentProposals }));
  }

  render() {
    const { hasFriendly, gameMode } = this.props;
    const { teams, receivedProposals, sentProposals } = this.state;

    if (hasFriendly) {
      return (
        <PagePlaceholder
          icon={faHandshake}
          text="O clube já tem um amistoso para essa rodada."
        />
      );
    }

    return (
      <div className="content-container with-margin">
        {receivedProposals.length > 0 && (
          <TeamsDetailsManagementFriendlyReceived
            teams={receivedProposals}
            accept={this.accept}
            reject={this.reject}
          />
        )}

        <TeamsDetailsManagementFriendlySearch
          teams={teams}
          receivedProposals={receivedProposals}
          sentProposals={sentProposals}
          gameMode={gameMode}
          send={this.send}
          cancel={this.cancel}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTeamFriendly: () => dispatch(teamsFriendlyGet()),
  sendProposal: teamFriendlyId => dispatch(teamsFriendlySendProposal(teamFriendlyId)),
  cancelProposal: teamFriendlyId => dispatch(teamsFriendlyCancelProposal(teamFriendlyId)),
  acceptProposal: teamFriendlyId => dispatch(teamsFriendlyAcceptProposal(teamFriendlyId)),
  rejectProposal: teamFriendlyId => dispatch(teamsFriendlyRejectProposal(teamFriendlyId)),
});

const mapStateToProps = state => ({
  teamId: state.teams.details.id,
  gameMode: state.teams.details.gameMode,
  hasFriendly: state.teams.details.overview && state.teams.details.overview.has_friendly,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsManagementFriendly);
