import React from 'react';

import Steps from '../../../elements/steps/steps';

import TutorialCampaignIntroduction from './introduction';
import TutorialCampaignActive from './active';

import TutorialGameModeActivation from '../activation';

const TutorialCampaign = () => (
  <Steps>
    <TutorialCampaignIntroduction />
    <TutorialGameModeActivation
      mode="campaign"
      pageName="detalhes/criacao"
      content={<TutorialCampaignActive />}
    />
  </Steps>
);

export default TutorialCampaign;
