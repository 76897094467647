import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';


import TeamsCompetitionsDetailsWinners from '../teamsCompetitionsDetailsWinners';
import TeamsCompetitionsDetailsInformationHeader from './header/header';
import TeamsCompetitionsDetailsInformationGroup from './teamsCompetitionsDetailsInformationGroup';

const TeamsCompetitionsDetailsInformation = (props) => {
  const { winners = {}, groups, match } = props;
  const { url } = match;

  const groupsIds = Object.keys(groups);

  return (
    <React.Fragment>
      <TeamsCompetitionsDetailsWinners
        winners={winners}
      />
      <TeamsCompetitionsDetailsInformationHeader />
      {groupsIds.length > 0 && (
        <Switch>
          <Redirect exact from={url} to={`${url}/${groupsIds[0]}`} />
          <Route
            path={`${url}/:groupId`}
            component={TeamsCompetitionsDetailsInformationGroup}
          />
        </Switch>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  groups: state.competitions.details.groups,
  winners: state.competitions.details.general.winners,
});

export default connect(mapStateToProps)(TeamsCompetitionsDetailsInformation);
