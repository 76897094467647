import React from 'react';

import CardSimple from '../../../elements/card/simple';

import RecordsRoundsContentGameTitle from './gameTitle';
import RecordsRoundsContentGameResult from './gameResult';

const RecordsRoundsContentGame = (props) => {
  const { game, statistics, betsTotal } = props;

  return (
    <div className="game">
      <CardSimple>
        <RecordsRoundsContentGameTitle game={game} />
        <RecordsRoundsContentGameResult game={game} statistics={statistics} betsTotal={betsTotal} />
      </CardSimple>
    </div>
  );
};

export default RecordsRoundsContentGame;
