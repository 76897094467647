import React from 'react';
import ReactAutocomplete from 'react-autocomplete';

const Autocomplete = ({
  getItemValue, items, renderItem, value, shouldItemRender, onChange, onSelect, placeholder, menuStyle,
}) => (
  <div className="autocomplete">
    <ReactAutocomplete
      menuStyle={menuStyle}
      getItemValue={getItemValue}
      items={items}
      renderItem={renderItem}
      value={value}
      shouldItemRender={shouldItemRender}
      onChange={onChange}
      onSelect={onSelect}
      inputProps={{ placeholder }}
    />
  </div>
);

export default Autocomplete;
