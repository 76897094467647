import React from 'react';
import { connect } from 'react-redux';

import TeamsBadgesForm from '../../../elements/teamsBadgesForm';

import { collectiblesGetUserItems } from '../../../../../app/store/actions/collectibles';
import { userTeamUpdateBadge } from '../../../../../app/store/actions/userTeams';

export class TeamsDetailsManagementBadge extends React.Component {
  constructor(props) {
    super(props);

    const { team } = props;

    this.state = {
      badgeId: team && team.collectible,
    };

    this.saveBadge = this.saveBadge.bind(this);
  }

  async componentDidMount() {
    await this.props.getUseritems();
  }

  async componentWillReceiveProps(nextProps) {
    const { team } = nextProps;
    const { badgeId } = this.state;
    if (!badgeId && team && team.collectible) this.setState(() => ({ badgeId: team.collectible }));
  }

  async saveBadge() {
    const { badgeId } = this.state;
    const { gameMode } = this.props;
    await this.props.updateTeamBadge(gameMode, badgeId);
  }

  async selectBadge(badgeId) {
    await this.setState(() => ({ badgeId }));
    await this.saveBadge();
  }

  render() {
    const { badgeId } = this.state;
    const { userBadges } = this.props;

    return (
      <div className="content-container">
        <TeamsBadgesForm
          badgeId={badgeId}
          badges={userBadges}
          selectBadge={badgeIdSelected => this.selectBadge(badgeIdSelected)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUseritems: () => dispatch(collectiblesGetUserItems('badge')),
  updateTeamBadge: (gameMode, badgeId) => dispatch(userTeamUpdateBadge(gameMode, badgeId)),
});

const mapStateToProps = state => ({
  team: state.teams.details && state.teams.details.overview,
  gameMode: state.teams.details && state.teams.details.gameMode,
  userBadges: state.collectibles.user.badge || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsManagementBadge);
