import React from 'react';

import CampaignsUtils from '../../../../../../app/utils/campaigns';

export const CampaignInProgressTeamInformationActivePlayOff = ({ phase }) => {
  const gameIndex = phase.game_round;

  const playOffPhase = CampaignsUtils.getPlayOffPhaseName(gameIndex, phase.games);
  const teamName = phase.games[gameIndex].real_team.name;

  return (
    <div className="info active">
      <div className="data">
        <div className="playoff-phase">
          {playOffPhase}
          <div className="position">
            {teamName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignInProgressTeamInformationActivePlayOff;
