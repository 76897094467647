import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSlash } from '@fortawesome/free-solid-svg-icons';

import RcProgressCircle from '../../../elements/rcProgress/circle';

import Colors from '../../../../data/colors';

import ReputationUtils from '../../../../app/utils/reputation';
import PlayersUtils from '../../../../app/utils/players';

const PlayersDetailsHeaderProfile = (props) => {
  const {
    username, status, reputation, xp, picture, achievements, icons,
  } = props;

  return (
    <div className="profile">
      <div className={`title ${PlayersUtils.usernameClass(username)}`}>
        <div className="level">
          {achievements.level}
        </div>
        <div className="texts">
          <div className="name">
            {username}
          </div>
          <div className="reputation">
            {ReputationUtils.getReputation(reputation).name}
            <span className="show-for-mobile">
              {' - Level '}
              {achievements.level}
            </span>
          </div>
          <span className="show-for-mobile">
            {icons}
          </span>
        </div>
      </div>
      <div className="picture">
        <div className="progress">
          <RcProgressCircle
            percent={xp}
            strokeWidth="5"
            trailWidth="5"
            strokeLinecap="square"
            strokeColor={Colors.layout.pomegranate}
            trailColor={Colors.layout.clouds}
          />
        </div>
        <div className="photo">
          {status === 'blocked' && (
            <div className="blocked">
              <div className="show-for-desktop text">Bloqueado</div>
              <span className="show-for-mobile icon">
                <FontAwesomeIcon icon={faBan} />
              </span>
            </div>
          )}
          {status === 'disable' && (
            <div className="disable">
              <div className="show-for-desktop text">Inativo</div>
              <span className="show-for-mobile icon">
                <FontAwesomeIcon icon={faSlash} />
              </span>
            </div>
          )}
          <img src={picture} alt={username} />
        </div>
      </div>
    </div>
  );
};

export default PlayersDetailsHeaderProfile;
