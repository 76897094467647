import React from 'react';

import Player from '../../players/elements/player';

import MultipleCarousel from '../../elements/multipleCarousel';
import CardSimple from '../../elements/card/simple';

const MessagesRoomHeader = (props) => {
  const { room } = props;

  return (
    <div className="users">
      <MultipleCarousel
        items={room.users.map(user => (
          <CardSimple key={user.id}>
            <Player data={user} type="table" />
          </CardSimple>
        ))}
        xs={1}
        sm={2}
        md={3}
        lg={5}
        arrows
      />
    </div>
  );
};

export default MessagesRoomHeader;
