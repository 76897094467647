import RestService from '../services/rest';
import PlayersUtils from '../utils/players';

async function getTeamMarket(teamId) {
  const markets = await RestService.getAuthenticated(`markets/teams?team=${teamId}`);
  markets.availables.forEach((player) => { player.user = PlayersUtils.completePlayer(player.user); });
  markets.players.forEach((player) => { player.user = PlayersUtils.completePlayer(player.user); });
  return markets;
}

async function teamSendProposal(teamId, userId) {
  return RestService.postAuthenticated('markets/teams/proposal', {
    team: teamId,
    user: userId,
  });
}

async function teamCancelProposal(teamId, userId) {
  return RestService.postAuthenticated('markets/teams/proposal/cancel', {
    team: teamId,
    user: userId,
  });
}

async function teamHirePlayer(teamId, userId) {
  return RestService.postAuthenticated('markets/teams/hire', {
    team: teamId,
    user: userId,
  });
}

async function teamDismissPlayer(teamId, userId) {
  return RestService.postAuthenticated('markets/teams/dismiss', {
    team: teamId,
    user: userId,
  });
}

async function playerBeAvailable(gameMode) {
  return RestService.postAuthenticated('markets/players/be_available', {
    game_mode: gameMode,
  });
}

async function playerNotAvailable(gameMode) {
  return RestService.postAuthenticated('markets/players/not_available', {
    game_mode: gameMode,
  });
}

async function playerRejectProposal(teamId) {
  return RestService.postAuthenticated('markets/players/proposal/reject', {
    team: teamId,
  });
}

async function playerAcceptProposal(teamId) {
  return RestService.postAuthenticated('markets/players/proposal/accept', {
    team: teamId,
  });
}

async function playerSendResign(gameMode) {
  return RestService.postAuthenticated('markets/players/resign/request', {
    game_mode: gameMode,
  });
}

async function playerCancelResign(gameMode) {
  return RestService.postAuthenticated('markets/players/resign/cancel', {
    game_mode: gameMode,
  });
}

async function playerResign(gameMode) {
  return RestService.postAuthenticated('markets/players/resign', {
    game_mode: gameMode,
  });
}

export default {
  getTeamMarket,
  teamSendProposal,
  teamCancelProposal,
  teamHirePlayer,
  teamDismissPlayer,
  playerBeAvailable,
  playerNotAvailable,
  playerRejectProposal,
  playerAcceptProposal,
  playerSendResign,
  playerCancelResign,
  playerResign,
};
