import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';

import PlayersUtils from '../../app/utils/players';

export const FooterContent = ({ statistics, isAuthenticated }) => (
  <div className="footer">
    <div className="content-container">
      <Grid fluid className="footer__content">
        <Row>
          <Col md={12} lg={6} className="footer-about">
            <div className="footer-logo">
              <img src="/images/barbolao_logo.png" alt="Barbolão" />
            </div>
            <div className="footer-info">
              <div className="description">
              O Barbolão é um site de entretenimento, com intuito de oferecer divertimento
              aos seus usuários voltado em apostas esportivas do futebol nacional e internacional.
              Agora você pode fazer parte desta comunidade e mostrar todo o seu conhecimento nesse
              esporte, de uma forma diferente.
              </div>

              <div className="social-media">
                <a href="https://www.instagram.com/barbolao/" rel="noopener noreferrer" target="_blank">
                  <div className="link">
                    <FontAwesomeIcon icon={faInstagram} />
                  </div>
                </a>
                <a href="https://www.facebook.com/barbolao/" rel="noopener noreferrer" target="_blank">
                  <div className="link">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </div>
                </a>
                <a href="https://twitter.com/barbolao" rel="noopener noreferrer" target="_blank">
                  <div className="link">
                    <FontAwesomeIcon icon={faTwitter} />
                  </div>
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={5} lg={3} className="stats">
            <h2>
              {'Estatísticas'}
            </h2>
            <ul className="footer-list">
              <li>
                {`Usuários ativos: ${numeral(statistics.active_users).format('0')}`}
              </li>
              <li>
                {`Usuários que Palpitaram: ${numeral(statistics.bettors_percentage).format('0.0%')}`}
              </li>
              <li>
                {`Usuários online: ${numeral(statistics.online_users).format('0')}`}
              </li>
              <li>
                {`Novos Usuários: ${numeral(statistics.new_users).format('0')}`}
              </li>
              <li>
                {`Média de Pontos na Rodada: ${numeral(statistics.score_average).format('0.0')}`}
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={7} lg={3}>
            <Row>
              <Col xs={6} sm={8}>
                <h2>
                  {'Navegação'}
                </h2>
                <ul className="footer-list footer-menu-links">
                  <li>
                    <Link to="/noticias">
                      {'Notícias'}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contato">
                      {'Fale conosco'}
                    </Link>
                  </li>
                  <li>
                    <Link to="/regulamento">
                      {'Regulamento'}
                    </Link>
                  </li>
                  <li>
                    <Link to="/termos">
                      {'Termos de uso'}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacidade">
                      {'Politica de Privacidade'}
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col xs={6} sm={4}>
                <h2>
                  {'Novatos'}
                </h2>
                <ul className="footer-list footer-menu-links">
                  {(statistics.last_users || []).map(user => (
                    <li key={user.id}>
                      {isAuthenticated ? (
                        <Link to={PlayersUtils.getLink(user.username)}>
                          {user.username}
                        </Link>
                      ) : user.username}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
);

const mapStateToProps = state => ({
  isAuthenticated: !!state.user.id,
});

export default connect(mapStateToProps)(FooterContent);
