import React from 'react';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import PreferencesrData from '../../../data/preferences';

export class AdministrationReportHeader extends React.Component {
  previous() {
    const { date } = this.props;
    this.props.updateDate(date.subtract(1, 'month'));
  }

  next() {
    const { date } = this.props;
    this.props.updateDate(date.add(1, 'month'));
  }

  render() {
    const { date } = this.props;

    const hasPrevious = (date.clone().subtract(1, 'month').year() < PreferencesrData.yearStart);
    const hasNext = (date.clone().diff(moment()) >= 0);

    return (
      <div>

        <button
          className="button"
          type="button"
          onClick={() => this.previous()}
          disabled={hasPrevious}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>

        {date.format('MMMM [de] YYYY')}

        <button
          className="button"
          type="button"
          onClick={() => this.next()}
          disabled={hasNext}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>

      </div>
    );
  }
}

export default AdministrationReportHeader;
