import moment from 'moment-timezone';

import RoundsData from '../../data/rounds';

import RealTeamsUtils from './realTeams';

const RoundModel = {
  games: [],
};

const RoundCompetitionModel = {
  name: 'Amistoso',
};

function calculateProgress(date) {
  const gameMinutes = moment().diff(moment(date), 'minutes');
  return Math.floor(Math.max(Math.min(gameMinutes / RoundsData.progress.max_minutes, 1), 0) * 100);
}

function addNumberInGames(round) {
  if (round && round.games) {
    round.games.forEach((game, index) => { game.number = index; });
  }
}

function completeRoundCompetition(competition = {}) {
  competition = { ...RoundCompetitionModel, ...competition };
  return competition;
}

function completeRound(round = {}) {
  round = { ...RoundModel, ...round };
  addNumberInGames(round);
  round.games.forEach((game) => {
    game.competition = completeRoundCompetition(game.competition);
    game.team_home = RealTeamsUtils.completeRealTeam(game.team_home);
    game.team_away = RealTeamsUtils.completeRealTeam(game.team_away);
    game.status = game.status || 'not_started';
  });
  return round;
}

export default {
  calculateProgress,
  addNumberInGames,
  completeRound,
  completeRoundCompetition,
};
