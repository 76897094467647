import React from 'react';

export const GuideGeneralFakes = () => (
  <React.Fragment>
    <div className="section">
      <h4>
        {'Artigo I'}
      </h4>
      <div className="section-content">
        <p>
          {'Na hipótese de determinado usuário possuir múltiplas contas, somente será mantida a '
        + 'conta "Original" nas seguintes ordens de avaliação:'}
        </p>
        <ol className="list-number">
          <li>
            {'Data do Cadastro'}
          </li>
          <li>
            {'Caso as contas tenham a mesma data de cadastro, será considerada "Original" a que '
          + 'possuir mais horas de acesso no site.'}
          </li>
        </ol>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Artigo II'}
      </h4>
      <div className="section-content">
        <p>
          {'As contas originais estarão sujeitas a punições, nos termos a seguir:'}
        </p>
        <ol className="list-number">
          <li>
            {'Perda de todos os títulos conquistados no período compreendido entre a data de cadastro '
            + 'da segunda conta e a exclusão desta.'}
          </li>
          <li>
            {'Perda de todos os pontos obtidos em quaisquer rankings do site no período compreendido '
            + 'entre a data de cadastro da segunda conta e a exclusão desta.'}
          </li>
          <li>
            {'Rebaixamento para a última divisão nos Campeonatos Individuais.'}
          </li>
          <li>
            {'Rebaixamento para a última divisão nos campeonatos coletivos constatando-se uma Organização '
            + 'de contas falsas em times e países.'}
          </li>
          <li>
            {'Na hipótese de Campeonatos por pontos corridos em que o usuário, time ou país terminem entre '
            + 'os melhores classificados para a divisão superior ou piores colocados para uma divisão inferior, '
            + 'será desconsiderada a promoção ou rebaixamento e considerada a vaga ao próximo colocado.'}
          </li>
          <li>
            {'O responsável pela(s) conta(s) falsa(s) o (proprietário da conta original), ficará suspenso por '
            + 'um período de 6 meses, sendo impedido de integrar qualquer equipe dentro do site e impedido de '
            + 'comandar de times e países.'}
          </li>
          <li>
            {'Expulsão do site, nas hipóteses de reincidência.'}
          </li>
        </ol>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Artigo III'}
      </h4>
      <div className="section-content">
        <p>
          {'Quando um time ou país possuir mais de 50% de contas falsas em seu elenco, será punido com a '
          + 'perda dos pontos nas partidas referentes a época em que era formado por membros fakes.'}
        </p>
        <ol className="list-number">
          <li>
            {'A punição será a perda de 3 pontos por partida.'}
          </li>
          <li>
            {'Os fakes serão retirados e a punição com a perda dos pontos, será aplicada ao final do '
            + 'campeonato.'}
          </li>
          <li>
            {'Os times/países que jogaram contra as contas falsas não receberão a pontuação da partida '
            + 'em que os fakes atuaram.'}
          </li>
          <li>
            {'Em torneios eliminatórios, não existe a possibilidade de desclassificação. As contas falsas '
            + 'serão retiradas e ficará sob responsabilidade do comandante em encontrar novos usuários a '
            + 'tempo do próximo jogo de sua equipe.'}
          </li>
          <li>
            {'Essa regra não poderá ser aplicada aos torneios individuais. Nas questões individuais, o fake '
            + 'descoberto após as rodadas da competição transcorridas, será substituído por algum usuário '
            + 'devidamente qualificado e as apostas dos fakes nas rodadas anteriores, continuarão tendo validade. '
            + 'Em caso de não possuir usuários qualificados, a vaga do usuário fake no torneio em questão, não '
            + 'será preenchida.'}
          </li>
        </ol>
      </div>
    </div>
  </React.Fragment>
);
export default GuideGeneralFakes;
