import React from 'react';
import { Switch } from 'react-router-dom';

import Landing from '../landing/landing';
import Terms from '../statics/terms';
import Privacy from '../statics/privacy';

import Contact from '../statics/contact/contact';
import ForgotPassword from '../statics/forgotPassword/forgotPassword';
import ResetPassword from '../statics/resetPassword';

import Refistration from '../registration/registration';

import Tutorial from '../tutorial/tutorial';
import Account from '../account/account';

import Cart from '../cart/cart';

import Wall from '../wall/wall';
import Live from '../live/live';
import Records from '../records/records';
import News from '../news/news';
import Guide from '../guide/guide';

import Messages from '../messages/messages';

import Transactions from '../transactions/transactions';
import Subscription from '../subscription/subscription';
import Collectible from '../collectibles/collectibles';

import PlayersDetails from '../players/details/playersDetails';

import GameModeBase from '../gameMode/gameMode';
import GameModeCampaign from '../gameMode/gameModeCampaign';

import Administration from '../administration/administration';

import NotFound from '../statics/notFound';

import PrivateRoute from '../../routers/privateRoute';
import PublicRoute from '../../routers/publicRoute';

import GameModeUtils from '../../app/utils/gameMode';

const Content = () => (
  <Switch>
    <PublicRoute path="/" component={Landing} exact noHeader />
    <PublicRoute path="/termos" component={Terms} exact />
    <PublicRoute path="/privacidade" component={Privacy} exact />
    <PublicRoute path="/noticias" component={News} exact />

    <PublicRoute path="/contato" component={Contact} exact />
    <PublicRoute path="/esqueci-senha" component={ForgotPassword} exact />
    <PublicRoute path="/redefinir-senha" component={ResetPassword} exact />

    <PublicRoute path="/cadastro" component={Refistration} />

    <PrivateRoute path="/tutorial" component={Tutorial} />
    <PrivateRoute path="/conta" component={Account} />

    <PublicRoute path="/carrinho" component={Cart} exact />

    <PrivateRoute path="/mural" component={Wall} />
    <PrivateRoute path="/ao-vivo" component={Live} />
    <PrivateRoute path="/registros" component={Records} />
    <PrivateRoute path="/noticias" component={News} />
    <PrivateRoute path="/regulamento" component={Guide} />

    <PrivateRoute path="/mensagens" component={Messages} />

    <PrivateRoute path="/compras" component={Transactions} />
    <PrivateRoute path="/prime" component={Subscription} />
    <PrivateRoute path="/colecionaveis" component={Collectible} />

    <PrivateRoute path="/jogadores/:username" component={PlayersDetails} />

    <PrivateRoute path="/administrativo" component={Administration} />

    <PrivateRoute
      path="/fantasia"
      component={({ ...props }) => <GameModeBase {...props} modeData={GameModeUtils.data.single} />}
    />
    <PrivateRoute
      path="/clube-real"
      component={({ ...props }) => <GameModeBase {...props} modeData={GameModeUtils.data.club} />}
    />
    <PrivateRoute
      path="/selecao"
      component={({ ...props }) => <GameModeBase {...props} modeData={GameModeUtils.data.national} />}
    />
    <PrivateRoute
      path="/cartola"
      component={({ ...props }) => <GameModeBase {...props} modeData={GameModeUtils.data.manager} />}
    />
    <PrivateRoute
      path="/jornada"
      component={({ ...props }) => <GameModeCampaign {...props} modeData={GameModeUtils.data.campaign} />}
    />
    <PublicRoute component={NotFound} />
  </Switch>
);

export default Content;
