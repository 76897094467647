import React from 'react';

import Table from '../../../elements/table';
import Player from '../../../players/elements/player';
import PlayerTableBet from '../../../players/table/bet';

export const CampaignInProgressTeamTable = (props) => {
  const {
    users, maxUsers, scores, useCampaignScore,
  } = props;

  let title = 'Jogadores';
  if (maxUsers) title += ` ${users.length}/${maxUsers}`;

  const header = [
    { key: 'user', value: title, className: 'name' },
    { key: 'bet', value: 'Aposta', className: 'number' },
  ];

  const data = users.map((user, index) => ({
    key: user.id,
    user: <Player data={user} type="table" />,
    bet: useCampaignScore ? scores[index] : <PlayerTableBet bets={user.bets} />,
  }));

  return (<Table header={header} lines={data} />);
};

export default CampaignInProgressTeamTable;
