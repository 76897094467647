import RestService from '../services/rest';

async function updatePicture(file) {
  const fd = new FormData();
  fd.append('file', file);

  return RestService.postAuthenticated('users/update_image', fd, {
    'Content-Type': 'multipart/form-data',
  });
}

async function chancePassword(oldPassword, password) {
  return RestService.postAuthenticated('users/update_password', {
    old_password: oldPassword,
    password,
  });
}

async function updateUser(data) {
  return RestService.postAuthenticated('users/update', data);
}

async function updateRealTeam(realTeamId) {
  const data = {};
  if (realTeamId) data.real_team = realTeamId;
  return RestService.postAuthenticated('users/real_team', data);
}

export default {
  updatePicture,
  chancePassword,
  updateUser,
  updateRealTeam,
};
