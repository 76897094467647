import React from 'react';
import { Link } from 'react-router-dom';

import TeamsUtils from '../../../../app/utils/teams';

import Hover from '../../hover';

const GamesHighlightTeam = (props) => {
  const { team, noLink = false } = props;

  const teamStyle = {};
  teamStyle.backgroundColor = TeamsUtils.getBackgroundColor(team);

  const teamElement = (
    <div className="team">
      <Hover
        className="logo"
        styleHover={teamStyle}
      >
        <img
          src={team.badge.url}
          alt={team.name}
        />
      </Hover>
      <div className="name">
        {team.name}
      </div>
    </div>
  );

  const link = TeamsUtils.getLink(team);

  if (link && !noLink) {
    return (
      <Link to={link}>
        {teamElement}
      </Link>
    );
  }

  return teamElement;
};

export default GamesHighlightTeam;
