import React from 'react';

import Table from '../../../../../elements/table';
import Team from '../../../../elements/team';
import RankingTableSequence from '../../../../../elements/ranking/tableSequence';

const TeamsStatisticsConfrontationContent = (props) => {
  const { teamHome, teamAway } = props;

  const teams = [teamHome, teamAway];

  const teamsHeader = [
    { key: 'position', tdClassName: 'position top5', hidden: true },
    { key: 'team', value: 'Ranking', colspan: 2 },
    { key: 'score', value: 'Pontos', tdClassName: 'high' },
    { key: 'wins', value: 'V', className: 'number' },
    { key: 'draws', value: 'E', className: 'number' },
    { key: 'losses', value: 'D', className: 'number' },
    { key: 'goals_for', value: 'Gp', className: 'number' },
    { key: 'goals_against', value: 'Gc', className: 'number' },
    { key: 'goals_difference', value: 'S', className: 'number' },
    { key: 'average', value: '%', className: 'number' },
    { key: 'sequence', value: 'Sequência', tdClassName: 'sequence' },
  ];

  const teamsData = teams.sort((a, b) => {
    if (a.statistics.score !== b.statistics.score) {
      return (a.statistics.score > b.statistics.score) ? -1 : 1;
    } if (a.statistics.goals_difference !== b.statistics.goals_difference) {
      return (a.statistics.goals_difference > b.statistics.goals_difference) ? -1 : 1;
    }
    return (a.team.id < b.team.id) ? -1 : 1;
  }).map((row, index) => ({
    position: index + 1,
    key: row.team.id,
    team: <Team data={row.team} type="table" />,
    score: row.statistics.score,
    wins: row.statistics.wins,
    draws: row.statistics.draws,
    losses: row.statistics.losses,
    goals_for: row.statistics.goals_for,
    goals_against: row.statistics.goals_against,
    goals_difference: row.statistics.goals_difference,
    average: `${Math.ceil(row.statistics.average * 100)}%`,
    sequence: <RankingTableSequence games={row.statistics.sequence.slice().splice(0, 5)} />,
  }));

  return (
    <Table header={teamsHeader} lines={teamsData} />
  );
};

export default TeamsStatisticsConfrontationContent;
