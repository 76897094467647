import React from 'react';

const PlayerTableValuation = ({ valuation = {} }) => {
  if (!valuation.value) return '-';

  return (
    <React.Fragment>
      {valuation.value.toFixed(2)}
      {valuation.variation !== 0 && (
      <div className={`player-variation ${valuation.variation > 0 ? 'up' : 'down'}`}>
        {(valuation.variation > 0 ? '+' : '') + valuation.variation.toFixed(2)}
      </div>
      )}
    </React.Fragment>
  );
};

export default PlayerTableValuation;
