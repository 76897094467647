import React from 'react';

import ObjectHelper from '../../../../app/helpers/object';
import CampaignsUtils from '../../../../app/utils/campaigns';

import CampaignsData from '../../../../data/campaigns';

import BadgeListItem from '../../../elements/badgeList/badgeList';

const CampaignListDifficulty = (props) => {
  const { user, name, types } = props;

  const difficultyTypes = ObjectHelper.clone(types).filter(t => t.difficulty === name);

  difficultyTypes.forEach((type) => {
    type.requirement = type.requirement.map(r => r.map(requirement => types.find(t => t.id === requirement)));
  });

  const colors = CampaignsData.difficulties_colors;

  const items = difficultyTypes.map((type) => {
    const blockDetails = CampaignsUtils.blockDetails(type, user);

    return {
      id: type.id,
      color: colors[name],
      name: type.name,
      tag: {
        nameHover: blockDetails && blockDetails.name,
        colorHover: blockDetails && blockDetails.color,
      },
      badge: type.logo.url,
      locked: !!blockDetails,
      lockedClass: blockDetails && blockDetails.tag === 'winner' && blockDetails.tag,
      mark: type.xp ? type.xp : undefined,
      onClick: !blockDetails && props.selectCampaignType && (() => props.selectCampaignType(type)),
    };
  });


  return (
    <BadgeListItem
      items={items}
      gridInfo={{
        lg: 2, md: 3, sm: 4, xs: 6,
      }}
      center
    />
  );
};

export default CampaignListDifficulty;
