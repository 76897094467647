import React from 'react';

import CampaignInProgressDetailsGameLogo from './gameLogo';
import CampaignInProgressDetailsGameDetails from './gameDetails';

export class CampaignInProgressDetailsGame extends React.Component {
  render() {
    const { game, users = [] } = this.props;
    const {
      title, team, result, current, usersScore = [],
    } = game;

    const usersData = users.map((user, index) => ({
      username: user.username,
      score: usersScore[index] || '-',
    }));

    const score = usersScore.reduce((total, value) => total + value, 0);

    return (
      <div
        className="container"
        onClick={this.changeView}
        onKeyDown={this.changeView}
        role="presentation"
      >
        {!result
          ? (
            <CampaignInProgressDetailsGameLogo
              team={team}
              isCurrent={current}
            />
          )
          : (
            <CampaignInProgressDetailsGameDetails
              title={title}
              team={team}
              result={result}
              users={usersData}
              score={score}
            />
          )
        }
      </div>
    );
  }
}

export default CampaignInProgressDetailsGame;
