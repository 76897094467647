import React from 'react';
import { connect } from 'react-redux';

import { marketHistoryGet } from '../../../app/store/actions/marketHistory';

import TeamsMarketListHistory from './teamsMarketListHistory';

export class TeamsMarketList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      history: [],
      finished: false,
    };
  }

  componentDidMount() {
    this.getHistory();
  }

   getHistory = async () => {
     const { history } = this.state;
     let lastDate;
     if (history.length) lastDate = history[history.length - 1].created_at;
     const newHistory = await this.props.getHistory(lastDate);
     const finished = newHistory.length === 0;
     this.setState({ history: [...history, ...newHistory], finished });
   }

   render() {
     const { history, finished } = this.state;
     const { type } = this.props;

     return (
       <div className="content-container market-list margin-bottom">
         <div className="title-center-menu">
           {'Mercado'}
         </div>
         <TeamsMarketListHistory history={history} type={type} />
         {!finished && (
         <button className="button normal" type="button" onClick={this.getHistory}>
           {'Carregar Mais'}
         </button>
         )}
       </div>
     );
   }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getHistory: lastDate => dispatch(marketHistoryGet(ownProps.gameMode, ownProps.type, lastDate)),
});

export default connect(undefined, mapDispatchToProps)(TeamsMarketList);
