import React from 'react';
import { Link } from 'react-router-dom';

import TeamDefault from './team/default';
import TeamGameBet from './team/gameBet';
import TeamTable from './team/table';
import TeamHighlight from './team/highlight';
import TeamChampion from './team/champion';

import ImageLoad from '../../elements/image/imageLoad';

import TeamsUtils from '../../../app/utils/teams';

export class Team extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      team: this.getTeam(props.data),
      type: props.type,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ team: this.getTeam(nextProps.data) });
  }

  getTeam = (data) => {
    const team = { ...data };
    team.link = team.id ? Link : (<div />);
    return team;
  }

  getTeamElement = () => {
    const { team, type } = this.state;
    const { noIcons, className } = this.props;

    const date = {
      team,
      className,
      noIcons,
    };

    switch (type) {
      case 'text':
        return team.name;
      case 'image':
        return (
          <ImageLoad
            src={team.badge.url}
            alt={team.badge.name}
            placeholder="/images/team_placeholder.png"
          />
        );
      case 'table':
        return (<TeamTable {...date} />);
      case 'gameBet':
        return (<TeamGameBet {...date} />);
      case 'highlight':
        return (<TeamHighlight {...date} />);
      case 'champion':
        return (<TeamChampion {...date} />);
      default:
        return (<TeamDefault {...date} />);
    }
  }

  render() {
    const { team } = this.state;

    if (!team.name) return (null);

    let TeamElement = this.getTeamElement();

    if (team.id && team.mode_type) {
      TeamElement = (
        <Link to={TeamsUtils.getLink(team)}>
          {TeamElement}
        </Link>
      );
    }

    return (TeamElement);
  }
}

export default Team;
