import React from 'react';

import TutorialWrapper from '../../elements/tutorial/tutorial';

const ForgotPasswordSuccess = () => (
  <div className="contact">
    <TutorialWrapper image="forget.jpg">
      <h3 className="title-center">
        {'Enviado!'}
      </h3>
      <p>
        {'E-mail enviado com as instruções para redefinir sua senha.'}
      </p>
      <p className="text">
        {'Qualquer problema entre em contato!'}
      </p>
    </TutorialWrapper>
  </div>
);

export default ForgotPasswordSuccess;
