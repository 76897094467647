import React from 'react';

import LinesHeader from '../../../elements/headers/linesHeader';

import CompetitionsUtils from '../../../../app/utils/competitions';

const TeamsCompetitionsDetailsHeader = (props) => {
  const { competition } = props;
  const { name, logo } = competition;

  const color = CompetitionsUtils.getCompetitionColor(competition);
  const infoStyle = { color };

  return (
    <LinesHeader color={color}>
      <div className="info">
        <div className="logo">
          <img src={logo.url} alt={name} />
        </div>
        <div className="name" style={infoStyle}>
          {name}
        </div>
      </div>
    </LinesHeader>
  );
};

export default TeamsCompetitionsDetailsHeader;
