import TransactionsRequests from '../../server/transactions';
import SubscriptionRequests from '../../server/subscription';
import CollectiblesRequests from '../../server/collectibles';
import CompetitionRequests from '../../server/competitions';

import { userGetAuth } from './user';
import { cleanPremiums } from './competitions';
import { addLoading, removeLoading } from './loading';
import { showAlertError } from './alert';

export const addCart = (type, item) => ({
  type: 'CART_ADD',
  cartType: type,
  item,
});

export const removeCart = () => ({
  type: 'CART_REMOVE',
});

export const addCredits = amount => async (dispatch, getState) => {
  dispatch(addLoading());

  const userId = getState().user.id;

  try {
    const result = await TransactionsRequests.addCredits(userId, amount);
    await dispatch(userGetAuth());

    return result;
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};

export const buySubscription = (subscriptionId, useCredit) => async (dispatch, getState) => {
  dispatch(addLoading());

  const userId = getState().user.id;

  try {
    const result = await SubscriptionRequests.subscribe(userId, subscriptionId, useCredit);
    await dispatch(userGetAuth());

    return result;
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};

export const buyChests = (chestId, total, useCredit) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await CollectiblesRequests.buyChests(chestId, total, useCredit);
    await dispatch(userGetAuth());

    return result;
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};

export const buyPremium = (premiumId, teamId, useCredit) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const result = await CompetitionRequests.participatePremium(premiumId, teamId, useCredit);
    await dispatch(userGetAuth());
    dispatch(cleanPremiums());

    return result;
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};
