import React from 'react';

import Tabs from '../elements/tabs/tabs';
import TutorialWrapper from '../elements/tutorial/tutorial';

export class GuideWrapper extends React.Component {
  constructor(props) {
    super(props);

    const menus = props.menus || [];

    this.state = {
      menus,
      view: menus[0],
    };
  }

  changeView = async (view) => {
    await this.setState(() => ({ view }));
  }

  render() {
    const { menus, view } = this.state;
    const { title, gameMode } = this.props;

    return (
      <React.Fragment>
        <div className="title-center-menu">
          {title}
        </div>
        <Tabs
          items={menus.map(menu => ({
            name: menu.title,
            onClick: () => this.changeView(menu),
            active: view.key === menu.key,
          }))}
        />

        {view && (
          <TutorialWrapper image="guide.jpg">
            <h3 className="title-center">
              {view.title}
            </h3>
            {<view.component gameMode={gameMode} />}
          </TutorialWrapper>
        )}
      </React.Fragment>
    );
  }
}

export default GuideWrapper;
