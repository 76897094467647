import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { usersAnalyzeList } from '../../../app/store/actions/usersAnalyze';
import pageTitleService from '../../../app/services/pageTitle';

import { scrollTo } from '../../../routers/appRouter';

import Table from '../../elements/table';
import Player from '../../players/elements/player';

export class AdministrationUsersAnalyze extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ipsData: [],
    };
  }

  async componentDidMount() {
    pageTitleService.setTitle('Análise de Usuários');
    const ipsData = await this.props.getUsersAnalyze();
    await this.setState({ ipsData });
    scrollTo('menu-secondary');
  }

  render() {
    const { ipsData = [] } = this.state;

    const tableHeader = [
      { key: 'ip', tdClassName: 'name', value: 'Ips Duplicados' },
      { key: 'total', value: 'Total' },
      { key: 'users', value: 'Usuários' },
    ];

    const tableData = ipsData.map(data => ({
      key: data.ip,
      ip: (
        <Link to={`usuarios-analise/${data.ip}`}>{data.ip}</Link>
      ),
      total: data.total,
      users: data.users.map(user => (
        <Player key={user.id} data={user} type="image" />
      )),
    }));

    return (
      <div className="user-analyze-page">
        <div className="content-container margin-bottom">
          <div className="title-center-menu">
            {'Analise de Usuários'}
          </div>
          <Table header={tableHeader} lines={tableData} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUsersAnalyze: () => dispatch(usersAnalyzeList()),
});

export default connect(undefined, mapDispatchToProps)(AdministrationUsersAnalyze);
