const initialState = {
  top5: {},
  score: {},
  games: {},
  strikers: {},
  achievements: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'TEAMS_STATISTICS_TOP5_SET':
      if (!state.top5[action.gameMode]) state.top5[action.gameMode] = {};
      state.top5[action.gameMode][action.interval] = action.data;
      return state;
    case 'TEAMS_STATISTICS_RANKING_CLEAN':
      state[action.ranking][action.gameMode] = {
        data: {},
        total: 0,
      };
      return state;
    case 'TEAMS_STATISTICS_RANKING_SET':
      if (!state[action.ranking][action.gameMode]) {
        state[action.ranking][action.gameMode] = {
          data: {},
        };
      }
      state[action.ranking][action.gameMode].total = action.total;
      state[action.ranking][action.gameMode].interval = action.interval;
      state[action.ranking][action.gameMode].data[action.page] = action.data;
      return state;
    default:
      return state;
  }
};
