import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import UsersUtils from '../../app/utils/users';

import MenuSecondary from '../elements/menuSecondary';

import AdministrationScore from './score/administrationScore';
import AdministrationRounds from './rounds/administrationRounds';
import AdministrationRoundsCreate from './rounds/create/administrationRoundsCreate';
import AdministrationRoundsList from './rounds/list/administrationRoundsList';
import AdministrationRoundsEdit from './rounds/edit/administrationRoundsEdit';
import AdministrationReport from './report/administrationReport';
import AdministrationUsersAnalyze from './usersAnalyze/administrationUsersAnalyze';
import AdministrationUsers from './users/administrationUsers';
import AdministrationUsersAnalyzeIp from './usersAnalyzeIp/administrationUsersAnalyzeIp';

export const Administration = (props) => {
  const { permissions } = props;

  const menus = [];

  if (UsersUtils.hasAccess(permissions, 'score')) {
    menus.push({
      name: 'Placares',
      uri: '/administrativo/placares',
      component: AdministrationScore,
      extra: '',
    });
  }

  if (UsersUtils.hasAccess(permissions, 'rounds')) {
    menus.push({
      name: 'Criar Rodada',
      uri: '/administrativo/rodadas/nova',
      component: AdministrationRoundsCreate,
      extra: '',
      internal: true,
    });
    menus.push({
      name: 'Lsita de Rodadas',
      uri: '/administrativo/rodadas/lista/:round',
      component: AdministrationRoundsEdit,
      extra: '',
      internal: true,
    });
    menus.push({
      name: 'Lista de Rodadas',
      uri: '/administrativo/rodadas/lista',
      component: AdministrationRoundsList,
      extra: '',
      internal: true,
    });
    menus.push({
      name: 'Rodadas',
      uri: '/administrativo/rodadas',
      component: AdministrationRounds,
      extra: '',
      menuNotExact: true,
    });
  }

  if (UsersUtils.hasAccess(permissions, 'transactions')) {
    menus.push({
      name: 'Relatório',
      uri: '/administrativo/relatorio',
      component: AdministrationReport,
      extra: '',
    });
  }

  if (UsersUtils.hasAccess(permissions, 'users')) {
    menus.push({
      name: 'Usuários',
      uri: '/administrativo/usuarios',
      extra: '/:type',
      component: AdministrationUsers,
      menuNotExact: true,
    });
  }

  if (UsersUtils.hasAccess(permissions, 'users_analyze')) {
    menus.push({
      name: 'Analise de Ip',
      uri: '/administrativo/usuarios-analise',
      extra: '/:ip',
      component: AdministrationUsersAnalyzeIp,
      internal: true,
    });
    menus.push({
      name: 'Fakes',
      uri: '/administrativo/usuarios-analise',
      extra: '',
      component: AdministrationUsersAnalyze,
      menuNotExact: true,
    });
  }

  return (
    <div>
      <MenuSecondary menus={menus.filter(menu => !menu.internal)} tag="Administrativo" />
      <Switch>
        {menus.length > 0 && (<Redirect exact from="/administrativo" to={menus[0].uri} />)}
        {UsersUtils.hasAccess(permissions, 'users') && (
          <Redirect exact from="/administrativo/usuarios" to="/administrativo/usuarios/pending" />
        )}
        {menus.map(menu => (
          <Route
            key={menu.name}
            path={menu.uri + menu.extra}
            component={menu.component}
          />
        ))}
      </Switch>
    </div>
  );
};

const mapStateToProps = state => ({
  permissions: state.user.permissions || [],
});

export default connect(mapStateToProps)(Administration);
