import React from 'react';
import { connect } from 'react-redux';

export const GuidePlayerAchievements = ({ trophyRounds }) => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'O jogador terá um level no site. Todas as suas conquistas vão acumulando um determinado '
            + 'número de XP, com o objetivo de aumentar esse level.'}
        </p>
        <p>
          {'A cada '}
          <b>100 XP</b>
          {', o jogador subirá '}
          <b>1 Level</b>
          {'. Você ganhará XP ganhando jogos, campeonatos entre outros.'}
        </p>
        <p>
          {'A cada level conqusitado, o jogador será recompensado com baús de escudos para serem usados em seus '
          + 'clubes.'}
        </p>
        <p>
          {'Nos modos em que o jogador integra uma equipe só será creditado a conquista se ele estiver no elenco a '}
          <b>{`${trophyRounds} ou mais rodadas.`}</b>
        </p>
      </div>
    </div>
  </React.Fragment>
);

const mapStateToProps = state => ({
  trophyRounds: state.settings && state.settings.market
    && state.settings.market.trophy && state.settings.market.trophy.rounds,
});

export default connect(mapStateToProps)(GuidePlayerAchievements);
