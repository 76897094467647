import React from 'react';
import StepWizard from 'react-step-wizard';

import { scrollTo } from '../../../routers/appRouter';

export class Steps extends React.Component {
  componentDidMount() {
    this.onStepChange();
  }

  onStepChange = () => {
    const { noScroll } = this.props;
    if (!noScroll) scrollTo('header-bottom', 600, 300);
  }

  render() {
    const { children, props = {} } = this.props;

    return (
      <StepWizard {...props} onStepChange={this.onStepChange}>
        {children}
      </StepWizard>
    );
  }
}

export default Steps;
