import React from 'react';

export const GuideClubGames = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Nos confrontos entre Clubes, cada jogador enfrentará o jogador da outro clube que esteja '
        + 'jogando na mesma posição, considerando-se os apenas seguintes jogos, por posição:'}
        </p>
        <ul className="list">
          <li>
            <b>
              {'Goleiro: '}
            </b>
            {'(1º e 2º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Fixo: '}
            </b>
            {'(2º, 3º e 4º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Ala Esquerda: '}
            </b>
            {'(4º, 5º, 6º e 7º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Ala Direita: '}
            </b>
            {'(4º, 5º, 6º e 7º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Pivô: '}
            </b>
            {'(6º, 7º, 8º, 9º e 10º - Jogo da rodada)'}
          </li>
        </ul>
        <p>
          {'A diferença de pontuação entre os jogadores de cada confronto será o número de gols que o '
        + 'jogador com a maior pontuação fará no confronto.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuideClubGames;
