import React from 'react';
import { connect } from 'react-redux';

import { teamsConfrontationGetTeamList } from '../../../../app/store/actions/teamsConfrontation';

import TeamsStatisticsConfrontationSearch from './search';

export class TeamsStatisticsConfrontationSecondTeam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentWillReceiveProps(nextProps) {
    const { teamId } = this.state;
    const { firstTeam } = nextProps;
    if (firstTeam && teamId !== firstTeam.id) {
      await this.setState({ teamId: firstTeam.id });
      const teamsIds = await this.props.getTeamsIds(firstTeam.id);
      await this.setState({ teamsIds });
    }
  }

  select = async (team) => {
    await this.props.setSecondTeam(team);
    this.props.nextStep();
  }

  render() {
    const { teams = [] } = this.props;
    const { teamsIds = [] } = this.state;

    const gameTeams = teams.filter(t => teamsIds.indexOf(t.id) !== -1);

    return (
      <div className="content-container">
        <div className="title-line title-page">
          {'Segunda equipe'}
        </div>
        <button className="button normal back-button" type="button" onClick={this.props.previousStep}>
          {'Voltar'}
        </button>
        <TeamsStatisticsConfrontationSearch teams={gameTeams} select={this.select} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTeamsIds: teamId => dispatch(teamsConfrontationGetTeamList(ownProps.mode, teamId)),
});

const mapStateToProps = (state, ownProps) => ({
  teams: state.teams.list[ownProps.mode] || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatisticsConfrontationSecondTeam);
