import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../routers/appRouter';

export class Maintenance extends React.Component {
  async componentWillReceiveProps(nextProps) {
    const { inMaintenance } = nextProps;
    if (!inMaintenance) history.push('/');
  }

  render() {
    const { inMaintenance } = this.props;

    return (
      <div>
        {inMaintenance && (
        <div className="loader">
          <div className="content">
            <img src="/images/maintenance.svg" alt="Manutenção" />
          </div>
        </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  inMaintenance: state.activities.maintenance,
});

export default connect(mapStateToProps)(Maintenance);
