import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import BadgeListItem from './badgeListItem';

export const BadgeList = ({
  items, gridInfo, around, center,
}) => {
  const rowParams = {};
  if (around) rowParams.around = 'xs';
  if (center) rowParams.center = 'xs';

  return (
    <Grid fluid className="badge-list">
      <Row className="items" {...rowParams}>
        {items && items.map(item => (
          <Col lg={3} md={4} sm={6} key={item.id} {...gridInfo}>
            <BadgeListItem item={item} />
          </Col>
        ))}
      </Row>
    </Grid>
  );
};

export default BadgeList;
