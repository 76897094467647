import React from 'react';

import Steps from '../../../elements/steps/steps';

import TutorialSingleIntroduction from './introduction';
import TutorialSingleActive from './active';

import TutorialGameModeActivation from '../activation';

const TutorialSingle = () => (
  <Steps>
    <TutorialSingleIntroduction />
    <TutorialGameModeActivation
      mode="single"
      pageName="criacao"
      content={<TutorialSingleActive />}
    />
  </Steps>
);

export default TutorialSingle;
