import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import {
  marketGetTeamMarket, marketTeamSendProposal, marketTeamCancelProposal,
  marketTeamHirePlayer, marketTeamDismissPlayer, marketFederationDismissPlayer,
} from '../../../../../app/store/actions/market';
import UsersSelector from '../../../../../app/store/selectors/users';

import TeamsDetailsManagementMarketPlayers from './players';
import TeamsDetailsManagementMarketSearch from './search';

export class TeamsDetailsManagementMarket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      availables: [],
      players: [],
      isManager: props.teamPresidentId === props.userId || props.teamManagerId === props.userId,
    };
  }

  async componentDidMount() {
    await this.updateData();
  }

  canDismiss = () => {
    const { userModeTeams, isMaster, teamRegion } = this.props;
    return isMaster || (userModeTeams.federations || []).some(federation => federation.region === teamRegion);
  }

  updateData = async () => {
    const { isManager } = this.state;
    const { teamPlayers } = this.props;

    if (isManager) {
      const data = await this.props.getTeamMarket();
      if (data) {
        const { availables, players } = data;

        if (this.canDismiss()) {
          players.forEach((p) => { p.federation = true; });
        }

        await this.setState(() => ({ availables, players }));
      }
    } else if (this.canDismiss()) {
      await this.setState(() => ({
        players: teamPlayers.map(p => ({
          user: p.user,
          federation: true,
        })),
      }));
    }
  }

  send = async (userId) => {
    const { availables } = this.state;
    await this.props.sendProposal(userId);
    const market = availables.find(m => m.user.id === userId);
    if (market) market.sent = true;
    await this.setState(() => ({ availables }));
  }

  cancel = async (userId) => {
    const { availables } = this.state;
    await this.props.cancelProposal(userId);
    const market = availables.find(m => m.user.id === userId);
    if (market) market.sent = false;
    await this.setState(() => ({ availables }));
  }

  hire = async (userId) => {
    await this.props.hirePlayer(userId);
    await this.updateData();
  }

  dismiss = async (userId) => {
    await this.props.dismissPlayer(userId);
    await this.updateData();
  }

  federationDismiss = async (userId) => {
    await this.props.federationDismiss(userId);
    await this.updateData();
  }

  render() {
    const { availables, players } = this.state;
    const {
      maxPlayers, marketIsOpen, teamPresidentId, teamManagerId,
    } = this.props;

    const allowSearch = !!availables.length;

    return (
      <div className="content-container with-margin market-management">
        <Grid fluid>
          <Row>
            <Col md={allowSearch ? 6 : 12}>
              <TeamsDetailsManagementMarketPlayers
                players={players}
                marketIsOpen={marketIsOpen}
                teamPresidentId={teamPresidentId}
                teamManagerId={teamManagerId}
                dismiss={this.dismiss}
                federationDismiss={this.federationDismiss}
              />
            </Col>
            {allowSearch && (
              <Col md={6}>
                <TeamsDetailsManagementMarketSearch
                  totalPage={maxPlayers - 2}
                  availables={availables}
                  hasAction={players.length < maxPlayers}
                  send={this.send}
                  cancel={this.cancel}
                  hire={this.hire}
                />
              </Col>
            )}
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTeamMarket: () => dispatch(marketGetTeamMarket()),
  sendProposal: userId => dispatch(marketTeamSendProposal(userId)),
  cancelProposal: userId => dispatch(marketTeamCancelProposal(userId)),
  hirePlayer: userId => dispatch(marketTeamHirePlayer(userId)),
  dismissPlayer: userId => dispatch(marketTeamDismissPlayer(userId)),
  federationDismiss: userId => dispatch(marketFederationDismissPlayer(userId)),
});

const mapStateToProps = (state) => {
  const { details: team } = state.teams;
  const { game_modes_details: gameModesDetails } = state.user;
  const teamOverview = team.overview || {};

  let userModeTeams;
  if (state.teams.details && state.teams.details.gameMode) {
    userModeTeams = gameModesDetails && gameModesDetails[team.gameMode] && gameModesDetails[team.gameMode];
  }

  return {
    userId: state.user.id,
    teamId: state.teams.details.id,
    teamPresidentId: teamOverview && teamOverview.president && teamOverview.president.id,
    teamManagerId: teamOverview && teamOverview.manager && teamOverview.manager.id,
    teamPlayers: (teamOverview && teamOverview.players) || [],
    maxPlayers: state.settings.game_modes[state.teams.details.gameMode].max_players,
    teamRegion: teamOverview.region_tag,
    marketIsOpen: state.user.game_modes_details && state.user.game_modes_details[state.teams.details.gameMode]
      && state.user.game_modes_details[state.teams.details.gameMode].market.is_open,
    isMaster: UsersSelector.isMaster(state),
    userModeTeams,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsManagementMarket);
