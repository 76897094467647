export default {
  mode: 'campaign',
  active: true,
  prime: false,
  basic: true,
  color: '#e67e22',
  statistics_pages: [
    'top5',
  ],
  pages: [
    'creation',
    'invitations',
    'historic',
    'campaigns',
    'guide',
  ],
  management_pages: [],
};
