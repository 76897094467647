import React from 'react';

const TableRankingSequence = ({ games = [] }) => (
  <div style={{ width: `${games.length * 0.9}rem` }}>
    {games.reverse().map((v, i) => ({ value: v, key: i })).map(game => (
      <div key={game.key} className={game.value} />
    ))}
  </div>
);

export default TableRankingSequence;
