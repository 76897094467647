import TransactionsRequests from '../../server/transactions';
import { addLoading, removeLoading } from './loading';

export const setList = transactions => ({
  type: 'TRANSACTIONS_SET',
  transactions,
});

export const transactionsGetList = () => async (dispatch, getState) => {
  const transactions = getState().transactions.list;
  if (!transactions) {
    dispatch(addLoading());
    try {
      const data = await TransactionsRequests.list();
      await dispatch(setList(data));
    } finally {
      dispatch(removeLoading());
    }
  }
};
