import moment from 'moment-timezone';

import TeamsRequests from '../../server/teams';
import { addLoading, removeLoading } from './loading';
import { showAlertError, showAlertSuccess } from './alert';

import GamesData from '../../../data/games';
import MarketData from '../../../data/market';

export const setLeague = (gameMode, league, teams, maxTeams) => ({
  type: 'TEAMS_LEAGUE_SET',
  gameMode,
  league,
  teams,
  maxTeams,
});

export const setFederation = (gameMode, federations) => ({
  type: 'TEAMS_FEDERATION_SET',
  gameMode,
  federations,
});

export const setDetails = team => ({
  type: 'TEAMS_DETAILS_SET',
  team,
});

export const setList = (gameMode, teams) => ({
  type: 'TEAMS_LIST_SET',
  gameMode,
  teams,
});

export const teamsGetLeague = (gameMode, league) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const leagueTeams = await TeamsRequests.getTeamsLeague(gameMode, league);
    if (leagueTeams) {
      leagueTeams.teams.forEach((team) => { team.mode_type = gameMode; });
      dispatch(setLeague(gameMode, league, leagueTeams.teams, leagueTeams.total_leagues));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const teamsGetFederation = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    const federations = await TeamsRequests.getTeamsFederation(gameMode);
    federations.forEach((federation) => {
      federation.teams.forEach((team) => { team.mode_type = gameMode; });
    });
    dispatch(setFederation(gameMode, federations));
  } finally {
    dispatch(removeLoading());
  }
};

export const teamsGetList = gameMode => async (dispatch, getState) => {
  const list = getState().teams.list[gameMode];
  if (!list) {
    dispatch(addLoading());
    try {
      const teams = await TeamsRequests.getTeams(gameMode);
      dispatch(setList(gameMode, teams));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsGetTeam = (gameMode, name) => async (dispatch) => {
  const team = await TeamsRequests.getTeam(gameMode, name);

  const teamDetails = {
    id: team.id,
    gameMode,
    overview: team,
    updatedAt: moment(),
  };

  dispatch(setDetails(teamDetails));
};

export const teamsStartTeam = (gameMode, name) => async (dispatch, getState) => {
  const team = getState().teams.details;

  dispatch(addLoading());
  if (!team || !(team.gameMode === gameMode && team.overview && team.overview.name === name)) dispatch(setDetails());

  try {
    await dispatch(teamsGetTeam(gameMode, name));
  } finally {
    dispatch(removeLoading());
  }
};

export const teamsGetMarketHistoric = () => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());

    const limit = MarketData.teamHistory.perPage;
    const skip = ((team.market && team.market.history) || []).length;

    try {
      const market = await TeamsRequests.getTeamMarketHistoric(team.id, skip, limit);
      team.market = {
        history: ((team.market && team.market.history) || []).concat(market.data),
        finished: skip + limit >= market.total,
      };
      dispatch(setDetails(team));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsGetGames = (round, page) => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());

    const limit = GamesData.list.gamesPerPage;
    const skip = ((page - 1) * limit);

    try {
      const games = await TeamsRequests.getTeamGames(team.id, round, skip, limit);
      team.games = (team.games || []).concat(games);
      dispatch(setDetails(team));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsGetCompetitions = () => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());

    try {
      const competitions = await TeamsRequests.getTeamCompetitions(team.id);
      team.competitions = competitions;
      dispatch(setDetails(team));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsGetAchievements = () => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      team.achievements = await TeamsRequests.getTeamAchievements(team.id);
      dispatch(setDetails(team));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsUpdateFormation = players => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsRequests.updateFormation(team.id, players);
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
      dispatch(showAlertSuccess('Escalação alterada com sucesso!'));
    } catch (err) {
      dispatch(showAlertError());
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsPortalSave = code => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsRequests.savePortal(team.id, code);
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
      dispatch(showAlertSuccess('Portal salvo com sucesso!'));
    } catch (err) {
      dispatch(showAlertError());
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsPortalDownload = () => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      const portal = await TeamsRequests.loadPortal(team.id);
      return portal;
    } finally {
      dispatch(removeLoading());
    }
  }
};
