import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PagePlaceholder = ({ text, icon }) => (
  <div className="content-container page-placeholder">
    <FontAwesomeIcon icon={icon} />
    <div className="text">
      {text}
    </div>
  </div>
);

export default PagePlaceholder;
