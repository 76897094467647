import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PlayersUtils from '../../../app/utils/players';

import TutorialWrapper from '../../elements/tutorial/tutorial';

const TutorialAccountBets = (props) => {
  const { username } = props;

  return (
    <TutorialWrapper image="account/image_4.jpg">
      <h3 className="title-center">
        {'Apostas'}
      </h3>
      <p>
        {'Como já foi informado, este é um site de apostas (palpites) de futebol. Tudo no site '
        + 'está baseado nas suas apostas. Semanalmente serão disponibilizadas 2 rodadas para '
        + 'apostas, sendo uma no meio de semana (MDS) e outra no final de semana (FDS), cada '
        + 'uma constando de 10 jogos.'}
      </p>
      <p className="text">
        {'Após a realização das apostas, com o preenchimento de todos os 10 palpites, clique no '
        + 'botão “APOSTAR”. Será encaminhada, automaticamente, uma mensagem para o seu email,  '
        + 'informado no cadastro, mostrando todos os palpites registrados. As apostas '
        + 'poderão ser alteradas até 1 hora antes do início do primeiro jogo da rodada, quando o '
        + 'site bloqueará automaticamente quaisquer alterações.'}
      </p>
      <button className="button normal" type="button" onClick={props.previousStep}>
        {'Voltar'}
      </button>
      <Link className="button normal light-blue" to={`${PlayersUtils.getLink(username)}/apostas`}>
        {'Realizar Apostas'}
      </Link>
    </TutorialWrapper>
  );
};

const mapStateToProps = state => ({
  username: state.user.username,
});

export default connect(mapStateToProps)(TutorialAccountBets);
