import React from 'react';

import PlayersDetailsBetsRoundGameScore from './game/score';
import PlayersDetailsBetsRoundGameForm from './game/form';

export default class PlayersDetailsBetsRound extends React.Component {
  constructor(props) {
    super(props);

    const { round, bet, suggestion } = this.props;

    this.state = {
      games: round ? this.createGames(round, bet, suggestion) : [],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { round, bet, suggestion } = nextProps;
    if (round && round.closed) {
      this.setState({ games: this.createGames(round, bet, suggestion) });
    }
  }

  onSubmit = () => {
    const { roundNumber } = this.props;

    const scores = this.getScores();
    this.props.onSubmit(roundNumber, scores);
  }

  onScoreChange = (gameIndex, isHome, value) => {
    const { games } = this.state;
    const field = (isHome) ? 'score_home' : 'score_away';
    if (value && Number(value) >= 0) {
      games[gameIndex].bet[field] = Number(value);
    } else {
      delete games[gameIndex].bet[field];
    }
    this.setState({ games });
  }

  getScores = () => {
    const { games } = this.state;
    return games.map(g => g.bet);
  }

  createGames = (round, bet, suggestion = []) => {
    const games = round.games.map((roundGame, index) => {
      const game = { ...roundGame, index, bet: {} };
      if (bet) game.bet = bet.games[index];
      if (suggestion[index]) game.suggestion = suggestion[index];
      return game;
    });
    return games;
  }

  sortingByCompetition = (games) => {
    const competitions = {};

    games.forEach((game) => {
      const competitionId = (game.competition) ? game.competition.id : 'none';
      if (!competitions[competitionId]) {
        competitions[competitionId] = {
          competition: game.competition || { name: 'Competição Indefinida' },
          games: [],
        };
      }

      competitions[competitionId].games.push(game);
    });

    return competitions;
  }

  canBet = () => {
    const scores = this.getScores();
    return this.props.canBet(scores);
  }

  render() {
    const { games } = this.state;
    const { round } = this.props;
    const rounsIsOpenToBet = round && !round.closed;

    const gamesByCompetition = this.sortingByCompetition(games);

    let score = 0;
    let hasBet = false;

    if (!rounsIsOpenToBet) {
      const scores = this.getScores();
      scores.forEach((s) => {
        if (s.score !== undefined) {
          score += s.score;
          hasBet = true;
        }
      });
    }

    return (
      <div>
        {Object.keys(gamesByCompetition).map(competitionId => (
          <React.Fragment key={competitionId}>
            <div className="competitions-title">
              {gamesByCompetition[competitionId].competition.name}
            </div>
            <div className="games">
              {gamesByCompetition[competitionId].games.map((game) => {
                if (rounsIsOpenToBet) {
                  return (
                    <PlayersDetailsBetsRoundGameForm
                      key={game.index}
                      game={game}
                      onScoreChange={this.onScoreChange}
                    />
                  );
                }
                return (<PlayersDetailsBetsRoundGameScore key={game.index} game={game} />);
              })}
            </div>
          </React.Fragment>
        ))}
        {rounsIsOpenToBet ? (
          <button
            type="button"
            className="button normal light-blue button-bet"
            onClick={this.onSubmit}
            disabled={!this.canBet()}
          >
            {`Palpitar na Rodada ${round.number}`}
          </button>
        ) : (hasBet && (
          <div className="competitions-title score">
            {score}
            {` Ponto${score !== 1 ? 's' : ''}`}
          </div>
        ))}
      </div>
    );
  }
}
