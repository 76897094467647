import React from 'react';

const TeamsGamesBetsCardSelectPlayers = ({
  homePlayers = [], awayPlayers = [], homeTotalPlayers = 0, awayTotalPlayers = 0,
  homeScore = 0, awayScore = 0, homePlayerIndex, awayPlayerIndex, selectPlayerIndex,
}) => (
  <div className="select-players">
    <div className="player-name">
      {homeTotalPlayers}
      <select value={homePlayerIndex} onChange={e => selectPlayerIndex('home', e.target.value)}>
        {homePlayers.map((player, index) => (
          <option key={player.id} value={index}>
            {player.username}
          </option>
        ))}
      </select>
    </div>
    <div className="result">
      {homeScore}
      {'-'}
      {awayScore}
    </div>
    <div className="player-name away">
      <select value={awayPlayerIndex} className="away" onChange={e => selectPlayerIndex('away', e.target.value)}>
        {awayPlayers.map((player, index) => (
          <option key={player.id} value={index}>
            {player.username}
          </option>
        ))}
      </select>
      {awayTotalPlayers}
    </div>
  </div>
);

export default TeamsGamesBetsCardSelectPlayers;
