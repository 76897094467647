import RestService from '../services/rest';
import PlayersUtils from '../utils/players';

async function activeUser(userId) {
  await RestService.postAuthenticated('admin/users/active', {
    user: userId,
  });
}

async function deleteUser(userId) {
  await RestService.deleteAuthenticated('admin/users', {
    user: userId,
  });
}

async function block(userId) {
  await RestService.postAuthenticated('admin/users/block', {
    user: userId,
  });
}

async function unblock(userId) {
  await RestService.postAuthenticated('admin/users/block/remove', {
    user: userId,
  });
}

async function blockWall(userId, isBlocked) {
  await RestService.postAuthenticated('admin/users/block/wall', {
    user: userId,
    blocked: isBlocked,
  });
}

async function getList(type, skip, limit) {
  const users = await RestService.getAuthenticated(`admin/users/list?status=${type}&skip=${skip}&limit=${limit}`);
  return users.map((user) => {
    const newUser = PlayersUtils.completePlayer(user, true);
    newUser.user_ips = newUser.user_ips.map(userIp => PlayersUtils.completePlayer(userIp, true));
    return newUser;
  });
}

export default {
  activeUser,
  deleteUser,
  block,
  unblock,
  blockWall,
  getList,
};
