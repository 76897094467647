import RestService from '../services/rest';

async function federationDismissPlayer(teamId, userId) {
  return RestService.postAuthenticated('federations/teams/dismiss', {
    team: teamId,
    user: userId,
  });
}

export default {
  federationDismissPlayer,
};
