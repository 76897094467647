import React from 'react';
import Highlighter from 'react-highlight-words';

import LandingData from '../../../data/landing';

export class LandingHeaderContentPhrase extends React.Component {
  createPhrase = (phrase) => {
    const { texts = [], highlight = [] } = phrase;

    return texts.map((text, index) => (
      <React.Fragment key={text}>
        <Highlighter
          highlightClassName="phrase-highlight"
          searchWords={highlight}
          autoEscape
          textToHighlight={(index === 0 ? '“' : '') + text + (index === texts.length - 1 ? '”' : '')}
        />
        <br />
      </React.Fragment>
    ));
  }

  render() {
    const phrase = LandingData.phrases[Math.floor(Math.random() * LandingData.phrases.length)];
    const text = this.createPhrase(phrase);

    return (
      <React.Fragment>
        <div className="phrase-content">
          <div className="text">
            {text}
          </div>
          <div className="author">
            {'- '}
            {phrase.author}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LandingHeaderContentPhrase;
