import GameModeUtils from './gameMode';

function getRankings(rankings = {}) {
  switch (rankings) {
    case 'discard': return 'Descarte';
    case 'achievements_official':
    case 'achievements': return 'Conquistas Anual';
    case 'score': return 'Apostas Anual';
    case 'games_official': return 'Jogos Oficiais Anual';
    default:
  }
}

function getQualification(qualification = {}, leagues) {
  switch (qualification.type) {
    case 'league': return 'Todos os clubes';
    case 'region': return 'Todos os clubes';
    case 'ranking': return `Melhores pelo Ranking de ${getRankings(qualification.ranking.type)}`;
    case 'competition': {
      const name = leagues[qualification.competition.league]
        ? leagues[qualification.competition.league].name : qualification.competition.league;
      return `Classificação através ${name}`; }
    default:
  }
}

function getPriority(priority = {}) {
  switch (priority.type) {
    case 'ranking': {
      const rankingsTexts = priority.ranking.map(r => getRankings(r)).join(', seguido pelo Ranking de ');
      return `Melhores pelo Ranking de ${rankingsTexts}`;
    }
    default:
  }
}

function getXp(xp = {}, divisions = []) {
  switch (xp.type) {
    case 'equation': {
      const priorities = divisions.map((d, i) => i + 1);
      const xps = priorities.slice(0, 5).map((priority) => {
        const aux = (10 - priority);
        const partX3 = (aux * aux * aux);
        const partX2 = (aux * aux * 3) - (aux * (aux + 1));
        const partX1 = aux * 2;
        return Math.ceil(((partX3 + partX2 + partX1) / 10) + 10);
      });

      const values = [];
      divisions.forEach((division, index) => {
        if (xps[index]) values.push(`${xps[index]} XP (${division.name})`);
      });
      return values.join(', ');
    }
    case 'values': {
      if (divisions.length > 1) {
        const values = [];
        const multi = Math.floor(divisions.length / xp.values.length);
        divisions.forEach((division, index) => {
          const i = Math.floor(index / multi);
          if (xp.values[i]) values.push(`${xp.values[i]} XP (${division.name})`);
        });
        return values.join(', ');
      }
      return `${xp.values} XP`;
    }
    default:
  }
}

function getTotalTeams(competition = {}) {
  if (competition.options) {
    let teams = [];
    competition.options.forEach((option) => {
      teams = [...teams, ...option.total_teams];
    });
    return teams.join(' ou ');
  }
}

function getType(competition = {}) {
  switch (competition.type) {
    case 'classificatory': return 'Grupo';
    case 'play_off': return 'Mata-mata';
    case 'two_pahses': return 'Grupos e Mata-mata';
    default:
  }
}

function formatCompetitionType(competitionType = {}) {
  const type = {};
  const gameMode = competitionType.mode_type;

  const { leagues } = GameModeUtils.data[gameMode];

  type.league = competitionType.league;
  type.name = leagues[competitionType.league] ? leagues[competitionType.league].name : competitionType.league;
  type.qualification = getQualification(competitionType.qualification, leagues);
  type.priority = getPriority(competitionType.priority);
  type.xp = getXp(competitionType.rewards && competitionType.rewards.xp, competitionType.divisions);
  type.teams = getTotalTeams(competitionType.competition);
  type.type = getType(competitionType.competition);

  return type;
}

export default {
  formatCompetitionType,
};
