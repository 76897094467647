import React from 'react';
import { Switch, Route } from 'react-router-dom';

import WallMenus from './wallMenus';
import WallFeed from './wallFeed';
import WallBets from './wallBets';

const WallPage = () => {
  const menus = [
    { uri: '/mural', mode: 'general', exact: true },
    { uri: '/mural/clube-real', mode: 'club' },
    { uri: '/mural/selecao', mode: 'national' },
  ];
  const menusExtras = [
    { name: 'Apostas', uri: '/mural/apostas', component: WallBets },
  ];

  return (
    <div>
      <WallMenus menus={[...menus, ...menusExtras]} />
      <Switch>
        {menus.map(menu => (
          <Route
            key={menu.mode}
            exact={menu.exact}
            path={menu.uri}
            component={props => <WallFeed {...props} mode={menu.mode} />}
          />
        ))}
        {menusExtras.map(menu => (
          <Route
            key={menu.name}
            path={menu.uri}
            component={menu.component}
          />
        ))}
      </Switch>
    </div>
  );
};

export default WallPage;
