import React from 'react';
import { connect } from 'react-redux';

import Paginate from '../../../../../elements/paginate';

import TeamsCompetitionsDetailsStatisticsStrikersTable from './teamsCompetitionsDetailsStatisticsStrikersTable';

import { cleanStatistics, competitionsGetStrikers } from '../../../../../../app/store/actions/competitions';

export class TeamsCompetitionsDetailsStatisticsStrikers extends React.Component {
  constructor(props) {
    super(props);

    const { currentRoundNumber } = props;

    this.state = {
      roundNumber: currentRoundNumber,
      page: 0,
    };
  }

  componentDidMount() {
    this.loadStrikers();
  }

  async componentWillReceiveProps(nextProps) {
    const { roundNumber } = this.state;
    const { currentRoundNumber } = nextProps;

    if (roundNumber !== currentRoundNumber) {
      this.setState(() => ({ roundNumber: currentRoundNumber }));
      await this.resetStrikerss();
      this.loadStrikers();
    }
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex });
    this.loadStrikers();
  };

  loadStrikers = async () => {
    const { strikers } = this.props;
    const { page } = this.state;
    if (!strikers || !strikers[page]) {
      await this.props.getStrikers(page);
    }
  };

  render() {
    const { page } = this.state;
    const { strikers, totalPages } = this.props;

    if (!totalPages) return (null);

    return (
      <React.Fragment>
        <TeamsCompetitionsDetailsStatisticsStrikersTable strikers={strikers[page]} />
        <Paginate
          pageCount={totalPages}
          forcePage={page}
          onPageChange={this.handlePageClick}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  cleanStatistics: () => dispatch(cleanStatistics()),
  getStrikers: page => dispatch(competitionsGetStrikers(page)),
});

const mapStateToProps = state => ({
  strikers: state.competitions.details.strikers.data,
  totalPages: state.competitions.details.strikers.pages,
  currentRoundNumber: state.rounds.current && state.rounds.current.number,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCompetitionsDetailsStatisticsStrikers);
