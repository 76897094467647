import moment from 'moment-timezone';
import React from 'react';

import RoundsUtils from '../../../app/utils/rounds';

import RcProgressLine from '../../elements/rcProgress/line';
import Colors from '../../../data/colors';

import translator from '../../../data/translator';

export default class HeaderContentRoundGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressPercentage: 0,
    };
  }

  componentDidMount() {
    this.calculateProgress(this.props.game);
  }

  componentWillReceiveProps(nextProps) {
    this.calculateProgress(nextProps.game);
  }

  getStatus = (status) => {
    const value = translator.s.roundStatus[status];
    if (value.length > 5) return `${value.substring(0, 4)}.`;
    return value;
  }

  calculateProgress(game) {
    const { date, status } = game;
    if (status === 'in_progress') {
      this.setState({ progressPercentage: RoundsUtils.calculateProgress(date) });
    }
  }

  render() {
    const { game, firstDay } = this.props;
    const { progressPercentage } = this.state;

    const date = moment(game.date);
    const isTodayGame = firstDay.isAfter(game.date) || date.clone().startOf('day').isBefore(moment());

    return (
      <div className="game">
        <div className={`team ${game.status} ${game.score_home < game.score_away ? 'lose' : ''}`}>
          <div className="name">
            {game.team_home && game.team_home.abbr}
          </div>
          {game.score_home && (
          <div className="score">
            {game.score_home}
          </div>
          )}
        </div>
        <div className={`team ${game.status} ${game.score_away < game.score_home ? 'lose' : ''}`}>
          <div className="name">
            {game.team_away && game.team_away.abbr}
          </div>
          {game.score_away && (
          <div className="score">
            {game.score_away}
          </div>
          )}
        </div>
        <div className="status">
          {game.status === 'in_progress' ? (
            <div className="line-bar">
              <RcProgressLine
                percent={progressPercentage}
                strokeWidth="5"
                trailWidth="5"
                strokeColor={Colors.layout.sunFlower}
                trailColor={Colors.layout.silver}
              />
            </div>
          ) : (
            <div className={`text ${game.status}`}>
              {game.status === 'not_started'
                ? date.format(isTodayGame ? 'HH:mm' : 'DD/MM')
                : this.getStatus(game.status)}
            </div>
          )}
        </div>
      </div>
    );
  }
}
