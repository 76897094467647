import React from 'react';
import { connect } from 'react-redux';

export const CampaignInProgressTeamInformationActiveClassificatory = ({ settings, phase }) => {
  const games = phase.games.slice().reverse();
  const position = phase.position || (games.length + 1);

  const scoreInterval = settings.classificatory;
  const totalScore = phase.games.length * settings.result.victory;
  const scoreFirst = Math.ceil(scoreInterval.first * totalScore);

  return (
    <div className="info active">
      <div className="data">
        <div className="score">
          {`${phase.score}`}
          <span className="target">{`/${scoreFirst}`}</span>
          <span className="pt">{` Pt${phase.score !== 1 ? 's' : ''}`}</span>
          <div className="position">
            {`${position}º Colocado`}
          </div>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  settings: {
    classificatory: state.settings.campaigns.classificatory,
    result: state.settings.campaigns.result,
  },
});

export default connect(mapStateToProps)(CampaignInProgressTeamInformationActiveClassificatory);
