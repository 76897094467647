import React from 'react';
import { connect } from 'react-redux';

import Paginate from '../../../elements/paginate';

import TeamsData from '../../../../data/teams';

import TeamsListTeams from '../teamsListTeams';

export class TeamsListFederationTeams extends React.Component {
  state = {
    page: 1,
  }

  async componentWillReceiveProps() {
    await this.setState({ page: 1 });
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  render() {
    const { teams = [] } = this.props;
    const { page } = this.state;

    if (!teams) return (null);

    const { teamsPerPage } = TeamsData.list.league;

    const teamsPage = teams.slice((page - 1) * teamsPerPage, page * teamsPerPage);

    const totalPages = Math.ceil(teams.length / teamsPerPage);

    return (
      <div className="content-container">
        <TeamsListTeams
          teams={teamsPage}
          gridInfo={{
            lg: 2, md: 3, sm: 4, xs: 6,
          }}
        />

        <Paginate
          pageCount={totalPages}
          forcePage={page - 1}
          onPageChange={this.handlePageClick}
        />

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  teams: state.teams.league[ownProps.gameMode].teams[ownProps.league],
});

export default connect(mapStateToProps)(TeamsListFederationTeams);
