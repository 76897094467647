import React from 'react';
import { connect } from 'react-redux';

import { accountUpdatePicture } from '../../../app/store/actions/account';

import TutorialWrapper from '../../elements/tutorial/tutorial';

import AccountPlayerPictureForm from './pictureForm';

export class AccountPlayer extends React.Component {
  updatePicture = async (file) => {
    await this.props.updatePicture(file);
  }

  render() {
    return (
      <TutorialWrapper image="account/image_2.jpg">
        <h3 className="title-center">
          {'Foto'}
        </h3>
        <p>
          {'Você pode selecionar uma foto ou imagem que te identifique.'}
        </p>
        <AccountPlayerPictureForm updatePicture={this.updatePicture} />
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updatePicture: file => dispatch(accountUpdatePicture(file)),
});


export default connect(undefined, mapDispatchToProps)(AccountPlayer);
