import React from 'react';
import { connect } from 'react-redux';

import { competitionsGetTypes } from '../../../../../app/store/actions/competitions';

import CompetitionsTypeUtils from '../../../../../app/utils/competitionsTypes';
import CompetitionsUtils from '../../../../../app/utils/competitions';

import TutorialWrapper from '../../../../elements/tutorial/tutorial';

export class TeamsCompetitionsDetailsRules extends React.Component {
  async componentDidMount() {
    await this.props.getCompetitionsTypes();
  }

  render() {
    const { type, competition, hasStriker } = this.props;

    const rewards = CompetitionsUtils.getReward(competition);
    const phasesTieBreaker = CompetitionsUtils.getPhasesTieBreaker(competition);

    return (
      <TutorialWrapper image="competition_rules.jpg">
        <h3 className="title-center">
          {'Regulamento'}
        </h3>
        <div className="section">
          <h4>Geral</h4>
          <div className="section-content">
            <ul className="list">
              {type && type.qualification && type.qualification !== 'Todos os clubes' && (
                <li>
                  <b>
                    {'Qualificação: '}
                  </b>
                  {` ${type.qualification}`}
                </li>
              )}
              {type && type.priority && (
                <li>
                  <b>
                    {'Vantagem: '}
                  </b>
                  {` ${type.priority}`}
                </li>
              )}
              <li>
                <b>
                  {'Total de clubes: '}
                </b>
                {` ${competition.phases[0].total_teams}`}
              </li>
              <li>
                <b>
                  {'Rodada de início: '}
                </b>
                {` ${competition.round.first}`}
              </li>
              <li>
                <b>
                  {'Rodada final: '}
                </b>
                {` ${competition.round.last}`}
              </li>
            </ul>
          </div>
        </div>
        {competition.xp > 0 && (
        <div className="section">
          <h4>Pontos de Experiência</h4>
          <div className="section-content">
            <ul className="list">
              <li>
                <b>
                  {'Campeão: '}
                </b>
                {` ${competition.xp} XP`}
              </li>
              <li>
                <b>
                  {'Vice Campeão: '}
                </b>
                {` ${Math.ceil(competition.xp / 2)} XP`}
              </li>
              <li>
                <b>
                  {'Terceiro Colocado: '}
                </b>
                {` ${Math.ceil(competition.xp / 4)} XP`}
              </li>
              {hasStriker && (
              <li>
                <b>
                  {'Artilheiro: '}
                </b>
                {` ${Math.ceil(competition.xp / 4)} XP`}
              </li>
              )}
            </ul>
          </div>
        </div>
        )}
        {rewards && (
        <div className="section">
          <h4>Prêmios</h4>
          <div className="section-content">
            <ul className="list">
              {rewards.map(reward => (
                <li key={reward.title}>
                  <b>
                    {reward.title}
                    {': '}
                  </b>
                  {reward.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
        )}
        {phasesTieBreaker.map(tieBreaker => (
          <div className="section" key={tieBreaker.title}>
            <h4>{tieBreaker.title}</h4>
            <div className="section-content">
              <ul className="list">
                {tieBreaker.lines.map(line => (
                  <li key={line}>
                    {line}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCompetitionsTypes: () => dispatch(competitionsGetTypes()),
});

const mapStateToProps = (state) => {
  const competition = state.competitions.details.general;
  const { types } = state.competitions;
  let type;

  if (types) {
    type = types.find(t => t.mode_type === competition.mode_type && t.league === competition.league);
    if (type) type = CompetitionsTypeUtils.formatCompetitionType(type);
  }

  return {
    type,
    competition,
    hasStriker: state.settings.game_modes[competition.mode_type].max_players,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCompetitionsDetailsRules);
