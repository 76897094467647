import React from 'react';

const RecordsRoundsContentGameTitle = (props) => {
  const { game } = props;

  return (
    <div className="title">
      {game.team_home.name}
      {' '}
      {game.score_home}
      {' x '}
      {game.score_away}
      {' '}
      {game.team_away.name}
    </div>
  );
};

export default RecordsRoundsContentGameTitle;
