export default {
  updateTime: 1 * 60 * 1000,
  statusTypes: [
    'pending',
    'active',
    'disable',
    'blocked',
    'excluded',
  ],
  list: {
    gamesPerPage: 12,
  },
};
