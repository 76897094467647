import React from 'react';

import TutorialWrapper from '../../elements/tutorial/tutorial';

const RegistrationIntroduction = () => (
  <div className="contact">
    <h3 className="title-center">
      {'Fale Conosco'}
    </h3>
    <TutorialWrapper image="contact.jpg">
      <h3 className="title-center">
        {'Enviada!'}
      </h3>
      <p>
        {'Sua mensagem foi enviada com sucesso!'}
      </p>
      <p>
        {'Em breve, nossa equipe irá retornar o seu contato.'}
      </p>
      <p className="text">
        {'Obrigado!'}
      </p>
    </TutorialWrapper>
  </div>
);

export default RegistrationIntroduction;
