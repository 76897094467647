import React from 'react';
import { connect } from 'react-redux';

import { competitionsGetTypes } from '../../app/store/actions/competitions';

import CompetitionsTypeUtils from '../../app/utils/competitionsTypes';

export class GuideCompetitions extends React.Component {
  async componentDidMount() {
    await this.props.getCompetitionsTypes();
  }

  render() {
    const { gameMode, competitionsTypes = [] } = this.props;

    if (!gameMode) return (null);

    const types = competitionsTypes.filter(t => t.mode_type === gameMode)
      .map(t => CompetitionsTypeUtils.formatCompetitionType(t));

    return (
      <React.Fragment>
        <div className="section">
          <div className="section-content">
            <p>
              {'Acesse ao '}
              <b>Calendário</b>
              {' para saber quando cada competição irá ocorrer.'}
            </p>
            <p>
              {'Os '}
              <b>Pontos de Experiência</b>
              {' mostrados serão dados aos campeões. O segundo colocado receberá metade desse valor. '
              + 'Já o terceiro colocado e o artilheiro receberão um quarto desse valor.'}
            </p>
          </div>
        </div>
        {types.map(type => (
          <div className="section" key={type.league}>
            <h4>{type.name}</h4>
            <div className="section-content">
              <ul className="list">
                {type.qualification && (
                <li>
                  <b>
                    {'Qualificação: '}
                  </b>
                  {` ${type.qualification}`}
                </li>
                )}
                {type.priority && (
                <li>
                  <b>
                    {'Vantagem: '}
                  </b>
                  {` ${type.priority}`}
                </li>
                )}
                {type.type && (
                <li>
                  <b>
                    {'Tipo: '}
                  </b>
                  {` ${type.type}`}
                </li>
                )}
                {type.teams && (
                <li>
                  <b>
                    {'Total de Clubes: '}
                  </b>
                  {` ${type.teams}`}
                </li>
                )}
                {type.xp && (
                <li>
                  <b>
                    {'Pontos de Experiência: '}
                  </b>
                  {` ${type.xp}`}
                </li>
                )}
              </ul>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCompetitionsTypes: () => dispatch(competitionsGetTypes()),
});

const mapStateToProps = state => ({
  competitionsTypes: state.competitions.types,
});

export default connect(mapStateToProps, mapDispatchToProps)(GuideCompetitions);
