import GameModeUtils from './gameMode';

import rankingsData from '../../data/rankings';

function getTypeName(type, gameMode) {
  if (rankingsData.types[type]) return rankingsData.types[type];
  if (GameModeUtils.data[gameMode] && GameModeUtils.data[gameMode].regions[type]) {
    return GameModeUtils.data[gameMode].regions[type];
  }
  return type;
}

export default {
  getTypeName,
};
