import React from 'react';
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import chartData from '../../../data/charts';

const SimpleBarChart = (props) => {
  const { data, bars, colors } = props;
  const chartColors = colors || chartData.bar.colors;

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        {Object.keys(bars).map((barKey, index) => (
          <Bar
            key={barKey}
            name={bars[barKey].name}
            stackId={bars[barKey].stackId}
            dataKey={barKey}
            fill={chartColors[index % chartColors.length]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SimpleBarChart;
