import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../../elements/top5/top5Section';

const TeamsStatisticsTop5Players = (props) => {
  const { participations, strikers, market } = props.data;

  return (
    <Top5Section title="Jogadores">
      <Grid fluid>
        {participations && (
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(participations.total, 'Rodadas como Titular', 'Vezes')}
          </Col>
        </Row>
        )}
        {strikers && (
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(strikers.official, 'Gols Oficiais', 'Gols')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(strikers.total, 'Gols Total', 'Gols')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(strikers.friendly, 'Gols em Amistosos', 'Gols')}
          </Col>
        </Row>
        )}
        {market && (
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(market.teams, 'Jogou por mais Clubes', 'Clubes')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(market.transfers, 'Mais Transfências', 'Vezes')}
          </Col>
        </Row>
        )}
      </Grid>
    </Top5Section>
  );
};

export default TeamsStatisticsTop5Players;
