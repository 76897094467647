import React from 'react';
import {
  ResponsiveContainer, PieChart, Pie, Cell,
} from 'recharts';

import chartData from '../../../data/charts';

const TinyPieChart = (props) => {
  const { data, colors } = props;
  const chartColors = colors || chartData.pie.colors;

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          labelLine={false}
          innerRadius="40%"
          outerRadius="100%"
          fill="#8884d8"
        >
          {data.map((entry, index) => <Cell key={entry.name} fill={chartColors[index % chartColors.length]} />) }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default TinyPieChart;
