import React from 'react';
import moment from 'moment-timezone';
import Linkify from 'react-linkify';

import Player from '../../players/elements/player';

import CardSimple from '../../elements/card/simple';

const MessagesRoomMessage = (props) => {
  const {
    id, message, userId, from,
  } = props;
  const isUser = (message.from === userId);

  return (
    <div className={`message${isUser ? ' is-user' : ''}`}>
      <CardSimple>
        {!isUser && from && (<Player data={from} type="table" />)}
        {!message.from && (
        <div className="card-all card-table">
          <div className="badge">
            <img src="/images/barbolao_logo.png" alt="Barbolão" />
          </div>
          <div className="texts">
            <div className="name">Barbolão</div>
            <div className="tag prime">
              <div className="label">Sistema</div>
            </div>
          </div>
        </div>
        )}
        <div className="date">
          {moment(message.created_at).format('LLL')}
          {props.removeMessage && (
            <span
              className="delete"
              onClick={() => props.removeMessage(id)}
              onKeyDown={() => props.removeMessage(id)}
              role="presentation"
            >
              {'Deletar'}
            </span>
          )}
        </div>
        <div className="text">
          <Linkify properties={{ target: '_blank' }}>{message.message}</Linkify>
        </div>
      </CardSimple>
    </div>
  );
};

export default MessagesRoomMessage;
