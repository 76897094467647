import React from 'react';
import { Link } from 'react-router-dom';

import PlayersUtils from '../../../../../app/utils/players';
import ReputationUtils from '../../../../../app/utils/reputation';
import Hover from '../../../../elements/hover';

export const TeamsDetailsPlayersPicturesPlayer = (props) => {
  const { player, color } = props;

  const statsStyle = {};
  statsStyle.backgroundColor = `${color}A3`;

  const PlayerComponet = (
    <React.Fragment>
      <Hover
        className="picture"
        classHover="hovered"
      >
        <img src={player.picture.url} alt={player.username} />
        <div className="stats" style={statsStyle} />
      </Hover>
      <div className="info">

        <div className="texts">
          <div className="name">
            {player.username}
          </div>
          <div className="label">
            {ReputationUtils.getReputation(player.level && player.level.value).name}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div className="player">

      {player.status !== 'excluded' ? (
        <Link to={player.status !== 'excluded' && PlayersUtils.getLink(player.username)}>
          {PlayerComponet}
        </Link>
      ) : PlayerComponet}

    </div>
  );
};

export default TeamsDetailsPlayersPicturesPlayer;
