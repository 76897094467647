import React from 'react';
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis,
} from 'recharts';

import chartData from '../../../data/charts';

const TinyBarChart = (props) => {
  const {
    data, bars, colors, isVertical = false,
  } = props;
  const chartColors = colors || chartData.bar.colors;

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        layout={isVertical ? 'vertical' : 'horizontal'}
      >
        {isVertical && (<XAxis type="number" hide />)}
        {isVertical && (<YAxis type="category" hide />)}

        {Object.keys(bars).map((barKey, index) => (
          <Bar
            key={barKey}
            name={bars[barKey].name}
            stackId={bars[barKey].stackId}
            dataKey={barKey}
            fill={chartColors[index % chartColors.length]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TinyBarChart;
