import React from 'react';

const Tabs = ({ items }) => (
  <div className="content-container">
    <ul className="nav-tabs">
      {items.map(item => (
        <li
          key={item.name}
          className={`item${item.active ? ' active' : ''}`}
          onClick={item.onClick}
          onKeyDown={item.onClick}
          role="presentation"
        >
          {item.name}
        </li>
      ))}
    </ul>
  </div>
);

export default Tabs;
