import React from 'react';

import TableAuth from '../tableAuth';

export class top5Table extends React.Component {
  getValue = (type, value) => {
    switch (type) {
      case 'percentage':
        return (value * 100).toFixed(1);
      case 'average':
        return value.toFixed(2);
      case 'number':
      default:
        return value;
    }
  }

  createHighTable = (title, data, entity, EntityComponent, header, valueName, typeData) => {
    const tableHeader = [
      { key: 'position', tdClassName: 'position top5', hidden: true },
      { key: 'entity', value: title, colspan: 2 },
      { key: 'value', value: header.value, tdClassName: 'high' },
    ];

    const tableData = data.map((row, index) => ({
      key: row[entity].id,
      position: index + 1,
      entity: (<EntityComponent data={row[entity]} type="table" />),
      value: this.getValue(typeData, row[valueName]),
    }));

    return (<TableAuth header={tableHeader} lines={tableData} />);
  }

  render() {
    const {
      title, data = {}, entity = '', component, header = {}, valueName = 'value', typeData = 'number',
    } = this.props;

    return this.createHighTable(title, data, entity, component, header, valueName, typeData);
  }
}

export default top5Table;
