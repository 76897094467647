import React from 'react';

import collectiblesData from '../../../data/collectibles';
import colorsData from '../../../data/colors';

import BadgeListItem from '../../elements/badgeList/badgeList';
import Tabs from '../../elements/tabs/tabs';

export class TeamsBadgesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      color: collectiblesData.basic.colors[0],
      selected: '',
    };

    this.onBadgeSelect = this.onBadgeSelect.bind(this);
  }

  componentDidMount() {
    this.selectChest(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.selectChest(nextProps);
  }

  onBadgeSelect(badgeId) {
    this.props.selectBadge(badgeId);
  }

   changeColor = async (e) => {
     const color = e.target.value;
     await this.setState(() => ({ color }));
   }

   async handleSelectChest(chestName, color) {
     const newState = { selected: chestName };
     if (color) newState.color = color;
     await this.setState(() => (newState));
   }

   async selectChest(props) {
     const { selected } = this.state;
     const { badgeId, badges } = props;

     if (!selected) {
       const chests = Object.keys(badges);
       if (chests.length > 0) {
         let index = 0;
         chests.some((chest, i) => {
           const badge = badges[chest].find(b => badgeId === b.id);
           if (badge) index = i;
           return badge;
         });

         const chestName = chests[index || 0];
         let color;

         if (chestName === collectiblesData.basic.name) {
           const badge = badges[chestName].find(b => badgeId === b.id);
           color = badge && badge.name.split(' ')[0];
         }

         await this.handleSelectChest(chestName, color);
       }
     }
   }

   render() {
     const { color, selected } = this.state;
     const { badgeId, badges } = this.props;
     const chests = Object.keys(badges);

     let badgesSelected = [];

     if (badges[selected]) {
       if (selected === collectiblesData.basic.name) {
         badgesSelected = badges[selected].filter(i => i.name.split(' ')[0] === color);
       } else badgesSelected = badges[selected];
     }

     const colors = colorsData.teams.background;

     const items = badgesSelected.map((badge) => {
       let tag;

       if (badge.id === badgeId) {
         tag = {
           nameHover: 'Selecionado',
           colorHover: colors[badge.colors[0]],
         };
       }

       return {
         id: badge.id,
         color: badge.colors[0] && colors[badge.colors[0]],
         name: badge.name,
         tag,
         badge: badge.image.url,
         forceHover: badge.id === badgeId,
         onClick: () => this.onBadgeSelect(badge.id),
       };
     });

     return (
       <div>
         <div className="title-center">
           {'Alterar o Escudo'}
         </div>
         <Tabs
           items={chests.map(chest => ({
             name: chest,
             onClick: () => this.handleSelectChest(chest),
             active: selected === chest,
           }))}
         />
         <div className="filter-select types center">
           {selected === collectiblesData.basic.name && (
           <select value={color} onChange={this.changeColor}>
             {collectiblesData.basic.colors.map(colorName => (
               <option key={colorName} value={colorName}>
                 {colorName}
               </option>
             ))}
           </select>
           )}
         </div>
         <div>
           <BadgeListItem
             items={items}
             gridInfo={{
               lg: 2, md: 3, sm: 4, xs: 6,
             }}
           />
         </div>
       </div>
     );
   }
}

export default TeamsBadgesForm;
