import React from 'react';

import colorsData from '../../../data/colors';
import betsData from '../../../data/bets';

export const GuideGeneralScore = () => {
  const scores = [{
    score: '0 Pontos',
    text: 'Não acertar o placar nem o vencedor do jogo.',
    color: colorsData.layout[betsData.colors['0']],
  }, {
    score: '1 Ponto',
    text: 'Acertar o vencedor ou o empate, mas errar o placa.',
    color: colorsData.layout[betsData.colors['1']],
  }, {
    score: '2 Pontos',
    text: 'Acertar o placar exato do jogo.',
    color: colorsData.layout[betsData.colors['2']],
  }, {
    score: '3 Pontos',
    text: 'Acertar o placar exato do jogo, desde que sejam mais de 4 gols.',
    color: colorsData.layout[betsData.colors['3']],
  }];

  return (
    <React.Fragment>
      <div className="section">
        <h4>
          {'Pontuação'}
        </h4>
        <div className="section-content">
          <p>
            {'Em cada um dos jogos apostados em cada rodada, o jogador poderá atingir as seguintes pontuações:'}
          </p>
          <div className="scores">
            {scores.map(score => (
              <div key={score.score} className="score">
                <div className="color" style={{ backgroundColor: score.color }} />
                <div className="texts">
                  <h5>
                    {score.score}
                  </h5>
                  <p>
                    {score.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <p>
            {'A pontuação final será a soma da pontuação dos 10 jogos.'}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default GuideGeneralScore;
