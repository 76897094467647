import React from 'react';

import TeamsGamesBetsCardGoals from './goals';

const TeamsGamesBetsCardInformation = ({
  home, away, homeScore, awayScore, homeGoals, awayGoals, classScore = '', inProgress,
}) => (
  <div className="bets-info">
    <div className="player home">
      {home}
    </div>
    <div className={`result ${classScore}${inProgress ? ' in-progress' : ''}`}>
      {homeGoals > 0 && <TeamsGamesBetsCardGoals goals={homeGoals} className="home" />}
      {awayGoals > 0 && <TeamsGamesBetsCardGoals goals={awayGoals} className="away" />}

      <div className="text">
        <div className={homeGoals > 0 ? 'win' : ''}>{homeScore}</div>
        <div>-</div>
        <div className={awayGoals > 0 ? 'win' : ''}>{awayScore}</div>
      </div>

    </div>
    <div className="player away card-table-inverted">
      {away}
    </div>
  </div>
);

export default TeamsGamesBetsCardInformation;
