import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CardSimple from '../../../../elements/card/simple';
import Player from '../../../../players/elements/player';
import PlayersUtils from '../../../../../app/utils/players';

import translator from '../../../../../data/translator';

export default class TeamsDetailsManagementRolesBasics extends React.Component {
  constructor(props) {
    super(props);

    const { playerId } = props;

    this.state = {
      playerSelected: playerId,
      changing: false,
    };
  }

  showSelect = async () => {
    await this.setState(() => ({ changing: true }));
  }

  changeRole = async () => {
    const { playerSelected } = this.state;
    const { teamRole } = this.props;

    await this.setState(() => ({ changing: false }));
    await this.props.changeRole(playerSelected, teamRole);
  }

  removeRole = async () => {
    const { teamRole } = this.props;
    await this.props.removeRole(teamRole);
  }

  handleChangePlayer = async (e) => {
    const playerId = e.target.value;
    await this.setState(() => ({ playerSelected: playerId }));
  }

  render() {
    const { playerSelected, changing } = this.state;
    const {
      teamRole, players, playerId, icon,
    } = this.props;

    const player = players.find(p => p.id === playerId);

    return (
      <CardSimple>
        <FontAwesomeIcon icon={icon} />
        <h4>
          {translator.s.roles[teamRole]}
        </h4>

        <div className="content">

          {!changing && (<Player data={player || PlayersUtils.completePlayer()} />)}

          {changing && (
          <div className="filter-select">
            <select value={playerSelected} onChange={this.handleChangePlayer}>
              {!playerSelected && (<option value="" />)}
              {players.map(p => (
                <option key={p.id} value={p.id}>
                  {p.username}
                </option>
              ))}
            </select>
          </div>
          )}

        </div>

        {!changing ? (
          <button className="button normal" type="button" onClick={this.showSelect}>
            {'Alterar'}
          </button>
        ) : (
          <button className="button normal" type="button" onClick={this.changeRole}>
            {'Alterar'}
          </button>
        )}

        {!changing && playerId && (
          <button className="button normal red" type="button" onClick={this.removeRole}>
            {'Remover'}
          </button>
        )}
      </CardSimple>
    );
  }
}
