import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import CardSimple from '../../elements/card/simple';

const TeamsMarketListHistoryItem = (props) => {
  const {
    roundNumber, player, team, type,
  } = props;

  return (
    <CardSimple>
      <div className="market-card">
        <div className="name">
          {player.username}
        </div>
        <div className="images">
          <div className="player">
            <img src={player.picture.url} alt={player.username} />
          </div>
          <div className={`icon ${type}`}>
            <div>
              {type === 'in' ? 'Entrou' : 'Saiu'}
            </div>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <div className="round">
              {'Rodada '}
              {roundNumber}
            </div>
          </div>
          <div className="team">
            <img src={team.badge.url} alt={team.name} />
          </div>
        </div>
        <div className="name">
          {team.name}
        </div>
      </div>
    </CardSimple>
  );
};

export default TeamsMarketListHistoryItem;
