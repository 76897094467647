import React from 'react';

import Steps from '../../../elements/steps/steps';

import TutorialClubIntroduction from './introduction';
import TutorialClubGame from './game';
import TutorialClubActive from './active';

import TutorialGameModeActivation from '../activation';

const TutorialClub = () => (
  <Steps>
    <TutorialClubIntroduction />
    <TutorialClubGame />
    <TutorialGameModeActivation
      mode="club"
      pageName="propostas"
      content={<TutorialClubActive />}
    />
  </Steps>
);

export default TutorialClub;
