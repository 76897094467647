import React from 'react';

export const GuideRoles = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Os cargos são definidos pelo presidente. O cargo de Presidente é definido pela '
      + 'administração do site. Caso tenha interesse em ser presidente de algum clube entre em contato.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h3>Presidente</h3>
      <div className="section-content">
        <p>
          {'O Presidente terá a função de escolher seus membros da Diretoria (os demais cargos), ou seja, '
        + 'ele poderá escolher quem será seu Técnico, Manager e Marketeiro. Além disso, ele poderá exercer '
        + 'todas essas funções para melhor administração. Caberá ao Presidente manter a ordem entre seus Jogadores.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h3>Técnico</h3>
      <div className="section-content">
        <p>
          {'O Técnico terá a responsabilidade de escalar os jogadores nas posições.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h3>Gerente</h3>
      <div className="section-content">
        <p>
          {'O Gerente será o responsável pelas contratações. Além disso, caberá a está função ficar atento ao mercado '
        + 'de transferências, manutenção do elenco, dispensa de jogadores e na revelação de craques.'}
        </p>
        <p>
          {'Uma vez que o jogador esteja no elenco, o gerente não poderá demitir o jogador, a menos que o jogador '
          + 'requisite, ou ele tenha dado '}
          <b>2 W.O.</b>
          {' seguidos ou em datas quando o mercado estiver aberto.'}
        </p>
        <p>
          {'Outra responsabilidade será marcar e aceitar pedidos de amistosos.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h3>Marketeiro</h3>
      <div className="section-content">
        <p>
          {'O Marketeiro será o responsável pela imagem da instituição. Caberá a ele organizar uma Página e '
          + 'oferecer entretenimento da equipe.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuideRoles;
