export default {
  s: {
    campaign: {
      rookie: 'Juvenil',
      amateur: 'Amador',
      professional: 'Profissional',
      world_class: 'Estrela',
      legendary: 'Lendário',
      ultimate: 'Ultimate',
    },
    campaignBlock: {
      prime: 'Prime',
      winner: 'Vencedor',
      requirement: 'Bloqueado',
    },
    competitions: {
      custom: 'Personalizado',
      friendly: 'Amistoso',
      official: 'Oficial',
      premium: 'Premium',
      vip: 'Prime',
      ranked: 'Rankeado',
    },
    gameModes: {
      club: 'Clube Real',
      manager: 'Cartola',
      national: 'Seleção',
      single: 'Fantasia',
      campaign: 'Jornada',
    },
    groups: {
      group: 'Primeira Fase',
      final: 'Fase Final',
      repechage: 'Repescagem',
    },
    phases: {
      classificatory: 'Pontos Corridos',
      play_off: 'Mata-Mata',
    },
    purchases: {
      chest: 'Baú',
      competition: 'Competição',
      premium: 'Premium',
      subscription: 'Membro Prime',
    },
    roles: {
      coach: 'Técnico',
      manager: 'Gerente',
      marketer: 'Marketeiro',
      president: 'Presidente',
      user: 'Fundador',
    },
    roundStatus: {
      finished: 'Final',
      canceled: 'Cancelada',
    },
    stagePosition: {
      champion: 'Campeão',
      final: 'Final',
      promotion: 'Acesso',
      repechage: 'Repescagem',
      relegation: 'Rebaixamento',
      continental_cup_1: 'Libertadores',
      continental_cup_2: 'Liga dos Campeões',
      continental_cup_3: 'Sul Americana',
      continental_cup_4: 'Liga Europa',
      world_cup_1: 'Copa do Mundo',
      world_cup_2: 'Copinha',
    },
    transactions: {
      bonus: 'Bônus',
      deposit: 'Depósito',
      pagseguro: 'PagSeguro',
      reward: 'Prêmio',
    },
    transactionExpenses: {
      server: 'Servidor',
      withdrawal: 'Saque',
    },
    transactionOperations: {
      inflow: 'Crédito',
      outflow: 'Saque',
      purchase: 'Compra',
    },
    transactionStatus: {
      pending: 'Pendente',
      awaiting_payment: 'Pendente',
      approved: 'Aprovada',
      canceled: 'Cancelada',
    },
  },
  p: {
    competitions: {
      custom: 'Personalizados',
      friendly: 'Amistosos',
      official: 'Oficiais',
      premium: 'Premiums',
      vip: 'Primes',
      ranked: 'Rankeados',
    },
    gameModes: {
      club: 'Clubes Reais',
      manager: 'Clubes',
      national: 'Seleções',
      single: 'Clubes Fantasias',
    },
    purchases: {
      chest: 'Baús',
      competition: 'Competições',
      premium: 'Premiums',
      subscription: 'Membros Primes',
    },
    roles: {
      coach: 'Técnicos',
      manager: 'Managers',
      marketer: 'Marketeiros',
      president: 'Presidentes',
    },
    transactions: {
      bonus: 'Bônus',
      deposit: 'Depósitos',
      pagseguro: 'PagSeguro',
      reward: 'Prêmioss',
    },
    transactionExpenses: {
      server: 'Servidor',
      withdrawal: 'Saques',
    },
    users: {
      pending: 'Pendentes',
      active: 'Ativos',
      disable: 'Desativos',
      blocked: 'Bloqueados',
      excluded: 'Excluídos',
    },
  },
  uri: {
    gameModes: {
      club: 'clube-real',
      manager: 'cartola',
      national: 'selecao',
      single: 'fantasia',
      campaign: 'jornada',
    },
    competitions: {
      custom: 'personalizados',
      friendly: 'amistosos',
      official: 'oficiais',
      premium: 'premiums',
      vip: 'primes',
      ranked: 'rankeados',
    },
  },
};
