import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';

import activitiesReducer from './reducers/activities';
import alertReducer from './reducers/alert';
import calendarsReducer from './reducers/calendars';
import campaignsReducer from './reducers/campaigns';
import cartReducer from './reducers/cart';
import collectiblesReducer from './reducers/collectibles';
import competitionsReducer from './reducers/competitions';
import gamesReducer from './reducers/games';
import inboxReducer from './reducers/inbox';
import liveReducer from './reducers/live';
import loadingReducer from './reducers/loading';
import playersReducer from './reducers/players';
import realTeamsReducer from './reducers/realTeams';
import recordsReducer from './reducers/records';
import reportsReducer from './reducers/reports';
import roundsReducer from './reducers/rounds';
import roundsBetsReducer from './reducers/roundsBets';
import settingsReducer from './reducers/settings';
import statisticsReducer from './reducers/statistics';
import teamsReducer from './reducers/teams';
import teamsStatisticsReducer from './reducers/teamsStatistics';
import transactionsReducer from './reducers/transactions';
import userReducer from './reducers/user';
import usersReducer from './reducers/users';
import wallsReducer from './reducers/walls';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      activities: activitiesReducer,
      alert: alertReducer,
      calendars: calendarsReducer,
      campaigns: campaignsReducer,
      cart: cartReducer,
      collectibles: collectiblesReducer,
      competitions: competitionsReducer,
      games: gamesReducer,
      inbox: inboxReducer,
      live: liveReducer,
      loading: loadingReducer,
      players: playersReducer,
      realTeams: realTeamsReducer,
      records: recordsReducer,
      reports: reportsReducer,
      rounds: roundsReducer,
      roundsBets: roundsBetsReducer,
      settings: settingsReducer,
      statistics: statisticsReducer,
      teams: teamsReducer,
      teamsStatistics: teamsStatisticsReducer,
      transactions: transactionsReducer,
      user: userReducer,
      users: usersReducer,
      walls: wallsReducer,
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};
