import React from 'react';

import SoccerField from '../../../../elements/soccerField';

export const TeamsGamesEscalationField = (props) => {
  const { formation } = props;

  const getPlayer = (position) => {
    const { players = [] } = props;

    const player = players.find(p => p.position === position);

    if (player) {
      return (
        <React.Fragment>
          <div className="mark">
            <div className="value">
              {player.user.username}
            </div>
          </div>
          <div className="button player">
            <img src={player.user.picture.url} alt={player.user.username} />
          </div>
        </React.Fragment>
      );
    }

    return (null);
  };

  const positions = Object.keys(formation);

  const players = {};
  positions.forEach((position) => {
    players[position] = getPlayer(position);
  });

  return (
    <div className="formation">
      <SoccerField
        formation={formation}
        players={players}
      />
    </div>
  );
};

export default TeamsGamesEscalationField;
