import React from 'react';

const AdministrationScoreEmpty = () => (
  <div className="margin-side">
    <b>Rodada aberta para apostas!</b>
    <p>Só será possível atualizar os placares quando a rodada se encerrar para as apostas.</p>
  </div>
);

export default AdministrationScoreEmpty;
