import React from 'react';

import StatisticsUtil from '../../../app/utils/statistics';
import StatisticsData from '../../../data/statistics';

import TableAuth from '../tableAuth';
import IntervalSelect from './intervalSelect';

export class Ranking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: Number(props.match.params.page || 1),
      interval: props.match.params.interval,
    };
  }

  async componentDidMount() {
    const { page, interval } = this.state;
    await this.props.getRanking(interval, page);
  }

  render() {
    const {
      match, totalPages, buttonTypes, tableHeader, tableLines, authInformation = {},
    } = this.props;
    const { type, rankings = [], tag } = authInformation;

    const interval = StatisticsUtil.getInterval(match.params.interval || 'geral');
    const ranking = rankings.find(r => r.interval === interval && r.type === tag);

    let userPosition;

    if (type === 'team') {
      userPosition = {
        name: authInformation.team.name,
        picture: authInformation.team.badge.url,
        value: (ranking && ranking.value) || 0,
        position: (ranking && ranking.position),
      };
    }

    return (
      <div className="discard-page">
        <div className="content-container">
          <IntervalSelect
            totalPages={totalPages}
            perPage={StatisticsData.rankings.perPage}
            match={match}
            userPosition={userPosition}
            buttonTypes={buttonTypes}
            removeSelect={!match.params.interval}
          />
          <TableAuth header={tableHeader} lines={tableLines} />
        </div>
      </div>
    );
  }
}

export default Ranking;
