import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import MenuSecondary from '../../../elements/menuSecondary';
import TeamsCompetitionsDetailsHeader from './teamsCompetitionsDetailsHeader';

import { competitionsStartCompetition, competitionsGetCompetition } from '../../../../app/store/actions/competitions';

import pageTitleService from '../../../../app/services/pageTitle';

import TeamsCompetitionsDetailsInformation from './information/teamsCompetitionsDetailsInformation';
import TeamsCompetitionsDetailsStatistics from './statistics/teamsCompetitionsDetailsStatistics';
import TeamsCompetitionsDetailsRules from './rules/teamsCompetitionsDetailsRules';

export class TeamsCompetitionsDetails extends React.Component {
  async componentDidMount() {
    const { competition, competitionId } = this.props;

    pageTitleService.setTitle(competition && competition.name);

    if (!competition) await this.props.startCompetition(competitionId);
    else await this.props.getCompetition(competitionId);
  }

  async componentWillReceiveProps(nextProps) {
    const { isIdle, roundUpdated, competitionId } = nextProps;

    if (this.props.roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
      await this.props.getCompetition(competitionId);
    }
  }

  render() {
    const { isLoaded, competition, match } = this.props;
    const { url } = match;

    const menus = [
      {
        name: 'Detalhes', uri: `${url}/detalhes`, component: TeamsCompetitionsDetailsInformation, menuNotExact: true,
      },
      { name: 'Regulamento', uri: `${url}/regulamento`, component: TeamsCompetitionsDetailsRules },
    ];

    if (competition && competition.phases && competition.phases[0] && competition.phases[0].type !== 'contest') {
      menus.splice(1, 0, {
        name: 'Estatísticas', uri: `${url}/estatisticas`, component: TeamsCompetitionsDetailsStatistics,
      });
    }

    return (
      <div className="competitions-details">
        {isLoaded && competition.id && (
          <React.Fragment>
            <TeamsCompetitionsDetailsHeader
              competition={competition}
            />
            <MenuSecondary menus={menus} tag={competition.name} />
            <Switch>
              <Redirect exact from={url} to={menus[0].uri} />
              {menus.map(menu => (
                <Route
                  key={menu.name}
                  path={menu.uri}
                  component={menu.component}
                />
              ))}
            </Switch>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startCompetition: competitionId => dispatch(competitionsStartCompetition(competitionId)),
  getCompetition: competitionId => dispatch(competitionsGetCompetition(competitionId)),
});

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { url, params } = match;
  const { id } = params;

  const competition = state.competitions.details && state.competitions.details.general;

  return {
    competitionId: id,
    url,
    isIdle: state.activities.idle,
    isLoaded: ownProps.match.params.id === state.competitions.details.general.id,
    competition: competition && competition.id === id ? competition : null,
    roundUpdated: state.rounds.updateAt,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCompetitionsDetails);
