function removeAccents(value = '') {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function romanize(num) {
  if (!+num) return false;
  const digits = String(+num).split('');
  const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
    '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
    '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
  let roman = '';

  for (let i = 2; i >= 0; i -= 1) {
    roman = (key[+digits.pop() + (i * 10)] || '') + roman;
  }
  return Array(+digits.join('') + 1).join('M') + roman;
}

function deromanize(str) {
  str = str.toUpperCase();
  const validator = /^M*(?:D?C{0,3}|C[MD])(?:L?X{0,3}|X[CL])(?:V?I{0,3}|I[XV])$/;
  const token = /[MDLV]|C[MD]?|X[CL]?|I[XV]?/g;
  const key = {
    M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1,
  };
  let num = 0;

  if (!(str && validator.test(str))) return false;
  let m = token.exec(str);

  while (m) {
    num += key[m[0]];
    m = token.exec(str);
  }

  return num;
}

function removeHashTags(postText) {
  const regexp = new RegExp('#([^\\s]*)', 'g');
  return postText.replace(regexp, '');
}

export default {
  removeAccents,
  romanize,
  deromanize,
  removeHashTags,
};
