import React from 'react';

export const GuidePlayerAchievements = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'O jogador terá um valor de mercado que será referente ao seu desempenho nas apostas.'}
        </p>
        <p>
          {'Esse valor será alterado, para mais ou para menos, em todas as rodadas que o jogador '
            + 'palpitar, de acordo com sua pontuação em relação ao seu histórico e desempenho dos '
            + 'outros jogadores na rodada.'}
        </p>
        <p>
          {'O valor apenas será disponibilizado a partir da 10ª rodada em que o jogador palpitar.'}
        </p>
        <p>
          {'O valor der mercado será usado para o modo '}
          <b>Cartola</b>
          {'.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuidePlayerAchievements;
