import React from 'react';

import PlayersDetailsBetsRoundGameScore from '../../../../players/details/bets/game/score';

export const TeamsDetailsOverviewBets = (props) => {
  const { bets = {}, round = {}, numberClosed } = props;

  const games = round.games.map((game, index) => {
    const data = { ...game, bet: {}, number: index };
    if (bets && bets.games && bets.games[index]) data.bet = bets.games[index];
    return data;
  });

  const score = (bets && bets.score) || 0;

  let scoreClass = '';
  if (score >= 10) scoreClass = ' md';

  return (
    <div className="content-container margin-bottom team-bets player-bets">
      <div className={`title${scoreClass}`}>
        <div className="level">{score}</div>
        <div className="texts">
          <div className="name">{`Ponto${score !== 1 ? 's' : ''}`}</div>
          <div className="reputation">
            {'Rodada '}
            {numberClosed}
          </div>
        </div>
      </div>
      <div className="games">
        {games.map(game => <PlayersDetailsBetsRoundGameScore key={game.number} game={game} />)}
      </div>
    </div>
  );
};

export default TeamsDetailsOverviewBets;
