import React from 'react';
import { connect } from 'react-redux';

import HeaderContentRound from './headerContentRound';

const HeaderContent = ({ hasGames }) => (
  <div className={`header__content${hasGames ? '' : ' no-games'}`}>
    <div className="content-container">
      <div className="content">
        <HeaderContentRound />
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  hasGames: state.rounds.current && !!state.rounds.current.games.length,
});

export default connect(mapStateToProps)(HeaderContent);
