import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';

const PlayersDetailsOverviewStatisticsReputationBadge = ({ currentReputation, position }) => (
  <div className="badge">
    <div className="image">
      <img src={currentReputation.picture} alt={currentReputation.name} />
    </div>
    <div className="name">
      {currentReputation.name}
    </div>
    {position && (
    <div className="position">
      <FontAwesomeIcon icon={faSlackHash} />
      {' '}
      {position}
      {'º'}
    </div>
    )}
  </div>
);

export default PlayersDetailsOverviewStatisticsReputationBadge;
