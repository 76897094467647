import React from 'react';

import LandingHeaderContentPhrase from './phrase';
import LandingHeaderContentLogin from './login';

export const LandingHeaderContent = () => (
  <div className="content-header">
    <div className="wrapper">
      <div className="content">
        <div className="phrase">
          <LandingHeaderContentPhrase />
        </div>
        <div className="login">
          <LandingHeaderContentLogin />
        </div>
      </div>
    </div>
  </div>
);

export default LandingHeaderContent;
