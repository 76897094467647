import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import RoundsSelector from '../../../../app/store/selectors/rounds';

import CampaignInProgressTeam from '../team/team';

import CampaignInProgressDetailsGames from './games/games';
import CampaignInProgressDetailsStatistics from './statistics/statistics';
import CampaignInProgressDetailsCurrent from './current/current';

import CampaignsUtils from '../../../../app/utils/campaigns';

export class CampaignInProgressDetails extends React.Component {
  render() {
    const { campaign, isClosedRound } = this.props;

    let phaseIndex = campaign.phase;
    let phase = campaign.phases[phaseIndex];

    let gameIndex = phase.game_round;

    if (!isClosedRound && (phaseIndex !== 0 || gameIndex !== 0)) {
      if (gameIndex === 0) {
        phaseIndex = Math.max(0, phaseIndex - 1);
        phase = campaign.phases[phaseIndex];
        gameIndex = phase.games.length - 1;
      } else {
        gameIndex -= 1;
      }
    }

    if (gameIndex >= campaign.phases[phaseIndex].games.length) {
      gameIndex = campaign.phases[phaseIndex].games.length - 1;
    }

    const currentGame = campaign.phases[phaseIndex].games[gameIndex];

    const title = CampaignsUtils.getPhaseName(phase.type, phaseIndex, gameIndex, campaign.phases.length,
      campaign.phases[phaseIndex].games, currentGame.round);

    const useCampaignScore = isClosedRound && campaign.status === 'active';

    const scoreValues = useCampaignScore ? campaign.scores : currentGame.users_score;
    const score = (scoreValues || []).reduce((total, value) => total + value, 0);

    return (
      <React.Fragment>
        <div className="content-container">
          <Grid fluid style={{ padding: 0 }}>
            <Row>
              <Col md={6}>
                <CampaignInProgressTeam
                  useCampaignScore={useCampaignScore}
                  campaign={campaign}
                  start={this.startCampaign}
                />
              </Col>
              <Col md={6}>
                <CampaignInProgressDetailsCurrent
                  title={title}
                  totalUsers={campaign.users.length}
                  difficulty={campaign.difficulty}
                  game={currentGame}
                  score={score}
                />
              </Col>
            </Row>
          </Grid>
        </div>

        <CampaignInProgressDetailsGames
          phases={campaign.phases}
          currentPhase={campaign.phase}
          users={campaign.users}
          isClosedRound={isClosedRound}
        />

        <CampaignInProgressDetailsStatistics
          phases={campaign.phases}
          currentPhase={campaign.phase}
        />
      </React.Fragment>

    );
  }
}

const mapStateToProps = state => ({
  isClosedRound: RoundsSelector.isClosedRound(state),
});

export default connect(mapStateToProps)(CampaignInProgressDetails);
