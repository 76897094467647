import RestService from '../services/rest';
import TeamsUtils from '../utils/teams';
import PlayersUtils from '../utils/players';
import StatisticsUtil from '../utils/statistics';

async function getTop5(gameMode, year) {
  let url = `teams/${gameMode}/statistics/top`;
  if (year) url += `?year=${year}`;
  const data = await RestService.getAuthenticated(url);

  const top5s = StatisticsUtil.getAllTop(data);
  top5s.forEach((playersOrTeams) => {
    playersOrTeams.forEach((playerOrTeam) => {
      if (playerOrTeam.team) playerOrTeam.team = TeamsUtils.completeTeam(playerOrTeam.team);
      else playerOrTeam.player = PlayersUtils.completePlayer(playerOrTeam.player);
    });
  });

  return data;
}

async function getScore(gameMode, interval, skip, limit) {
  const url = `rankings/${gameMode}/score?interval=${interval}&limit=${limit}&skip=${skip}`;
  const ranking = await RestService.getAuthenticated(url);
  ranking.data.forEach((row) => { row.team = TeamsUtils.completeTeam(row.team); });
  return ranking;
}

async function getGames(gameMode, interval, skip, limit, extras) {
  const url = `rankings/${gameMode}/games?type=games_${extras.type}&interval=${interval}&limit=${limit}&skip=${skip}`;
  const ranking = await RestService.getAuthenticated(url);
  ranking.data.forEach((row) => { row.team = TeamsUtils.completeTeam(row.team); });
  return ranking;
}

async function getStrikers(gameMode, interval, skip, limit, team) {
  const teamParam = team ? `&team=${team}` : '';
  const url = `rankings/${gameMode}/players?interval=${interval}&limit=${limit}&skip=${skip}${teamParam}`;
  const ranking = await RestService.getAuthenticated(url);
  ranking.data.forEach((row) => { row.user = PlayersUtils.completePlayer(row.user); });
  return ranking;
}

async function getAchievements(gameMode, interval, skip, limit, extras) {
  const url = `rankings/${gameMode}/achievements?type=achievements_${extras.type
  }&interval=${interval}&limit=${limit}&skip=${skip}`;

  const ranking = await RestService.getAuthenticated(url);
  ranking.data.forEach((row) => { row.team = TeamsUtils.completeTeam(row.team); });
  return ranking;
}

export default {
  getTop5,
  getScore,
  getGames,
  getStrikers,
  getAchievements,
};
