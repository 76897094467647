import React from 'react';

import colorsData from '../../../../data/colors';
import CampaignInProgressTeamTable from './table';

import CampaignInProgressTeamInformationPaused from './information/paused';
import CampaignInProgressTeamInformationPausedWaiting from './information/pausedWaiting';
import CampaignInProgressTeamInformationVictory from './information/victory';
import CampaignInProgressTeamInformationLoss from './information/loss';
import CampaignInProgressTeamInformationActive from './information/active';

export const CampaignInProgressTeam = (props) => {
  const {
    isOwner, maxUsers, campaign, useCampaignScore,
  } = props;
  const { users, real_team: team = {}, scores } = campaign;

  const logoStyle = {
    backgroundColor: `${colorsData.teams.background[team.colors[0]]}E3`,
  };

  const trophyStyle = {
    backgroundColor: `${colorsData.teams.background.white}E3`,
  };

  let infoComponent;

  switch (campaign.status) {
    case 'paused':
      if (isOwner) infoComponent = <CampaignInProgressTeamInformationPaused team={team} onStart={props.start} />;
      else infoComponent = <CampaignInProgressTeamInformationPausedWaiting />;
      break;
    case 'finished':
      if (campaign.winner) infoComponent = <CampaignInProgressTeamInformationVictory />;
      else infoComponent = <CampaignInProgressTeamInformationLoss />;
      break;
    case 'canceled':
      infoComponent = <CampaignInProgressTeamInformationLoss />;
      break;
    default:
      infoComponent = <CampaignInProgressTeamInformationActive team={team} phase={campaign.phases[campaign.phase]} />;
      break;
  }


  return (
    <div className="campaign-team">
      <div className="header-campaign-team">
        <div className="team">
          <div className="mask" style={logoStyle}>
            <div className="texture" />
          </div>
          <div className="logo">
            <span className="img-align-helper" />
            <img src={team.badge.url} alt={team.name} />
          </div>
        </div>
        <div className="team">
          <div className="mask" style={trophyStyle}>
            <div className="texture" />
          </div>
          {infoComponent}
        </div>
      </div>


      <CampaignInProgressTeamTable
        users={users}
        maxUsers={maxUsers}
        scores={scores}
        useCampaignScore={useCampaignScore}
      />
    </div>
  );
};

export default CampaignInProgressTeam;
