import React from 'react';

import TeamsCompetitionsPremiumsCardDetailsRewards from './rewards';
import TeamsCompetitionsPremiumsCardDetailsCompetition from './competition';
import TeamsCompetitionsPremiumsCardDetailsTeams from './teams';

const TeamsCompetitionsPremiumsCardDetails = ({
  rewards, competition, teams, maxTeams, hasPlayers,
}) => (
  <div className="details">
    <div className="left">
      <TeamsCompetitionsPremiumsCardDetailsRewards rewards={rewards} hasPlayers={hasPlayers} />
      <TeamsCompetitionsPremiumsCardDetailsCompetition competition={competition} />
    </div>
    <TeamsCompetitionsPremiumsCardDetailsTeams teams={teams} maxTeams={maxTeams} />
  </div>
);

export default TeamsCompetitionsPremiumsCardDetails;
