import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsDetailsPlayersPicturesPlayer from './picturePlayer';

export const TeamsDetailsPlayersPictures = ({ players, gameMode, color }) => {
  const playersSorted = players.slice();
  playersSorted.sort((a, b) => b.user.achievements.level - a.user.achievements.level);

  return (
    <section className="picture">
      <Grid fluid>
        <Row center="xs">
          {playersSorted.map(player => (
            <Col key={player.user.id} xs={4} sm={3} md={2} lg={2}>
              <TeamsDetailsPlayersPicturesPlayer
                position={player.position}
                player={player.user}
                gameMode={gameMode}
                color={color}
              />
            </Col>
          ))}
        </Row>
      </Grid>
    </section>
  );
};

export default TeamsDetailsPlayersPictures;
