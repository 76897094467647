export const addAlert = (type, message) => ({
  type: 'ALERT_ADD',
  alertType: type,
  message,
});

export const removeAlert = () => ({
  type: 'ALERT_REMOVE',
});

let timeOutAlert;

export const showAlert = (type, message) => (dispatch, getState) => {
  if (timeOutAlert) {
    clearTimeout(timeOutAlert);
    dispatch(removeAlert());
  }

  dispatch(addAlert(type, message));

  timeOutAlert = setTimeout(() => {
    if (getState().alert.type) {
      dispatch(removeAlert());
    }
  }, 4000);
};

export const showAlertSuccess = message => (dispatch) => {
  dispatch(showAlert('success', message || 'Alteração feita com sucesso!'));
};

export const showAlertError = message => (dispatch) => {
  dispatch(showAlert('error', message || 'Algo estranho aconteceu! Tente novamente ou entre em contato.'));
};
