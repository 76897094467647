import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Tabs from '../../../elements/tabs/tabs';

import TeamsDetailsStatisticsStrikers from './strikers/strikers';

export class TeamsDetailsStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'strikers',
    };
  }

  changeView = async (view) => {
    await this.setState(() => ({ view }));
  }

  render() {
    const { view } = this.state;
    const { match } = this.props;

    const menus = [
      {
        name: 'Artilheiros',
        view: 'strikers',
        uri: `${match.url}/artilheiros`,
        extras: '/:page',
        component: TeamsDetailsStatisticsStrikers,
      },
    ];

    return (
      <div className="content-container margin-bottom competition-statistics">
        <div className="title-center-menu">
          {'Estatísticas'}
        </div>
        <Tabs
          items={menus.map(menu => ({
            name: menu.name,
            onClick: () => this.changeView(menu.view),
            active: view === menu.view,
          }))}
        />

        <Switch>
          <Redirect exact from={match.url} to={menus[0].uri} />
          <Redirect exact from={`${match.url}/artilheiros`} to={`${match.url}/artilheiros/1`} />
          {menus.map(menu => (
            <Route
              key={menu.name}
              path={menu.uri + menu.extras}
              component={menu.component}
            />
          ))}
        </Switch>
      </div>
    );
  }
}

export default TeamsDetailsStatistics;
