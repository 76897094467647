import React from 'react';
import FormationsData from '../../../../../data/formations';

export const TeamsDetailsManagementEscalationHeader = (props) => {
  const {
    leftCurrency, view, tatic, unsaved, fixedTatic,
  } = props;

  return (
    <React.Fragment>
      <div className="title-line">
        {'Escalação'}
      </div>
      <div className="header-manager">
        {tatic && view === 'formation' && (
        <div className="tactic">
          <div className="label">
            {'Formação:'}
          </div>
          <div className="filter-select">
            <select value={tatic} onChange={props.changeTatic}>
              {Object.keys(FormationsData.manager).map(taticValue => (
                <option key={taticValue} value={taticValue}>
                  {taticValue}
                </option>
              ))}
            </select>
          </div>
        </div>
        )}
        {fixedTatic && (
        <div className="currency">
          <div className="label">
            {'Formação: ' }
          </div>
          <div className="value">
            {fixedTatic}
          </div>
        </div>
        )}
        {leftCurrency !== undefined && (
        <div className="currency">
          <div className="label">
            {'Restante: ' }
          </div>
          <div className={`value${leftCurrency < 0 ? ' negative' : ''}`}>
            {'$'}
            {(leftCurrency).toFixed(2)}
          </div>
        </div>
        )}

        <div className="buttons">
          {view === 'players' && (
          <button className="button normal" type="button" onClick={props.goToBack}>
            {'Voltar'}
          </button>
          )}
          <button className="button normal red" type="button" onClick={props.onReset}>
            {'Limpar'}
          </button>
          <button
            className="button normal"
            type="button"
            onClick={props.onSave}
            disabled={!unsaved || view !== 'formation'}
          >
            {'Salvar'}
          </button>
        </div>

      </div>

    </React.Fragment>
  );
};

export default TeamsDetailsManagementEscalationHeader;
