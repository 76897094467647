import RestService from '../services/rest';

async function checkData(data) {
  return RestService.postRest('users/registration/check', data);
}

async function registration(data) {
  return RestService.postRest('users/registration', data);
}

export default {
  checkData,
  registration,
};
