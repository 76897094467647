
import moment from 'moment-timezone';

const isMaster = state => state.user.permissions && state.user.permissions.indexOf('master') !== -1;

const isPrime = state => state.user.subscription.expires
&& moment().diff(moment(state.user.subscription.expires)) < 0;

export default {
  isMaster,
  isPrime,
};
