import React from 'react';

import TinyBarChart from '../../../../../elements/charts/tinyBarChart';

const PlayersDetailsOverviewStatisticsBetsChartBar = (props) => {
  const {
    title, player, general, colors,
  } = props;

  const dataScore = [{
    player,
    general,
  }];

  const bars = {
    player: {
      name: 'Jogador',
      stackId: 'player',
    },
    general: {
      name: 'Média do Site',
      stackId: 'general',
    },
  };

  return (
    <div className="bet-chart">
      <div className="chart">
        <TinyBarChart data={dataScore} bars={bars} colors={colors} />
      </div>
      <div className="title">
        {title}
      </div>
    </div>
  );
};

export default PlayersDetailsOverviewStatisticsBetsChartBar;
