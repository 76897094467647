import React from 'react';
import { connect } from 'react-redux';

import CardSimple from '../../elements/card/simple';

import {
  cleanRooms, inboxSendMessages, inboxCreateRoom, inboxCreateTeamRoom,
} from '../../../app/store/actions/inbox';

import { history } from '../../../routers/appRouter';

import MessagesHeader from '../messagesHeader';
import MessagesNewForm from './messagesNewForm';
import MessagesNewUser from './messagesNewUser';

export class MessagesNew extends React.Component {
  constructor(props) {
    super(props);

    const types = {
      user: 'Usuário',
    };
    if (props.clubName) types.club = props.clubName;
    if (props.nationalName) types.national = props.nationalName;

    this.state = {
      type: 'user',
      types,
      userSelected: null,
    };
  }

  changeType = async (e) => {
    const type = e.target.value;
    await this.setState(() => ({ type, userSelected: null }));
  }

  selectUser = async (user) => {
    await this.setState(() => ({ userSelected: user }));
  }

  sendMessage = async (messageText) => {
    const { type, userSelected } = this.state;

    let roomId;

    if (type === 'user') {
      if (userSelected) {
        if (userSelected.room) {
          roomId = userSelected.room;
          this.props.cleanRooms();
          await this.props.sendMessage(roomId, messageText);
        } else {
          const userId = userSelected.id;
          roomId = await this.props.createUserRoom(userId, messageText);
        }
      }
    } else {
      roomId = await this.props.createTeamRoom(type, messageText);
    }

    history.push(`/mensagens${roomId ? `/${roomId}` : ''}`);
  }

  canSend = () => {
    const { type, userSelected } = this.state;
    return (type !== 'user' || userSelected);
  }

  render() {
    const { types, type, userSelected } = this.state;
    const { minMessage, maxMessage } = this.props;

    const typesKeys = Object.keys(types);

    return (
      <div className="inbox-new">
        <MessagesHeader buttonNew={false} />

        <CardSimple>
          <div className="from">
            {typesKeys.length > 1 && !userSelected && (
            <div className="filter-select">
              <select value={type} onChange={this.changeType}>
                {typesKeys.map(roomType => (
                  <option key={roomType} value={roomType}>
                    {types[roomType]}
                  </option>
                ))}
              </select>
            </div>
            )}

            {type === 'user' && (<MessagesNewUser selectUser={this.selectUser} />)}
          </div>
          <MessagesNewForm
            minMessage={minMessage}
            maxMessage={maxMessage}
            onSubmit={this.sendMessage}
            canSend={this.canSend()}
          />
        </CardSimple>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  cleanRooms: () => dispatch(cleanRooms()),
  sendMessage: (roomId, message) => dispatch(inboxSendMessages(roomId, message)),
  createUserRoom: (userId, message) => dispatch(inboxCreateRoom(userId, message)),
  createTeamRoom: (gameMode, message) => dispatch(inboxCreateTeamRoom(gameMode, message)),
});

const mapStateToProps = state => ({
  clubName: state.user.teams && state.user.teams.club && state.user.teams.club.name,
  nationalName: state.user.teams && state.user.teams.national && state.user.teams.national.name,
  minMessage: state.settings.messages && state.settings.messages.message && state.settings.messages.message.min,
  maxMessage: state.settings.messages && state.settings.messages.message && state.settings.messages.message.max,
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesNew);
