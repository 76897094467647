import TeamsManagerRequests from '../../server/teamsManager';
import { userGetAuth } from './user';
import { teamsGetTeam } from './teams';
import { addLoading, removeLoading } from './loading';
import { showAlertError, showAlertSuccess } from './alert';

export const teamsManagerGetUsers = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    const data = await TeamsManagerRequests.getUsers();
    return data;
  } finally {
    dispatch(removeLoading());
  }
};

export const teamsManagerUpdateFormation = (players, formation) => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsManagerRequests.updateFormation(players, formation);
      await dispatch(userGetAuth());
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
      dispatch(showAlertSuccess('Escalação salva com sucesso!'));
    } catch (err) {
      dispatch(showAlertError());
    } finally {
      dispatch(removeLoading());
    }
  }
};
