import React from 'react';

export const GuideGeneralFakes = () => (
  <React.Fragment>
    <div className="section">
      <h4>
        {'O que é um Fake?'}
      </h4>
      <div className="section-content">
        <p>
          {'Fake é um termo usado para denominar contas ou perfis usados na Internet para ocultar a '
        + 'identidade real de um usuário. Para isso, são usadas identidades de famosos, cantores, '
        + 'personagens de filmes ou até mesmo outras pessoas anônimas.'}
        </p>
        <p>
          {'Podem haver casos em que determinado usuário assuma a conta de outro usuário inativo no '
        + 'site, passando a utilizar como sendo sua. Isso também configura um caso de fake. '
        + 'É indiscutivelmente proibido um usuário possuir mais que um perfil no barbolão.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Pergunta e Respostas?'}
      </h4>
      <div className="section-content">
        <p>
          {'Meu amigo pediu para palpitar pra ele nesta rodada, pois está sem acesso a Internet. É '
          + 'cabível de punição? Resposta: Sim. Não existe nenhuma razão para que você acesse a conta '
          + 'de outra pessoa. Isto é proibido e cabível de punição para ambas as contas.'}
        </p>
        <p>
          {'Sou Presidente do Brasil e não posso arriscar que minha Seleção, seja desfalcada com W.O, '
          + 'de Jogadores e por isso solicitei a senha da maioria para garantir que palpitem. É permitido? '
          + 'Resposta: Não. Você só pode ter acesso a sua própria conta. Ser Presidente não lhe dá o direito a '
          + 'acessar contas de outros usuários. Caso venha acontecer será intermitentemente punido as contas '
          + 'envolvidas devido ao anti-jogo desportivo.'}
        </p>
        <p>
          {'Conheço um usuário que está utilizando outra conta além do seu perfil. Onde posso denunciar? '
          + 'Resposta: Envie uma mensagem interna a administração do site e informe o usuário Fake. Será '
          + 'mantido o sigilo absoluto sobre sua denúncia.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuideGeneralFakes;
