import ContactRequests from '../../server/contact';
import { addLoading, removeLoading } from './loading';
import { showAlertError } from './alert';

export default (name, email, subject, message) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await ContactRequests.send(name, email, subject, message);
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};
