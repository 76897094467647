import React from 'react';

import Paginate from '../../../../../elements/paginate';
import GameClassificatory from '../../../../../elements/games/classificatory/classificatory';

export class TeamsCompetitionsDetailsInformationGames extends React.Component {
  constructor(props) {
    super(props);

    const gameRound = this.getStartRound();
    const page = this.getPage(gameRound);

    this.state = { page, gameRound };
  }

  getStartRound() {
    const { data, currentRoundNumber } = this.props;
    const roundKeys = Object.keys(data);
    return roundKeys.find((roundKey, index) => {
      const roundGames = data[roundKey];
      return roundGames.round >= currentRoundNumber || (index + 1) === roundKeys.length;
    });
  }

  getPage = (gameRound) => {
    const { maxPage, data, userTeamIds = [] } = this.props;

    let page = 1;

    if (maxPage && data && data[gameRound] && userTeamIds) {
      userTeamIds.forEach((userTeamId) => {
        const userTeamIndex = data[gameRound].games
          .findIndex(game => userTeamId === game.team_home.id || userTeamId === game.team_away.id);
        if (userTeamIndex !== -1) page = Math.ceil((userTeamIndex + 1) / maxPage);
      });
    }

    return page;
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  handleChangeRound = async (event) => {
    const gameRound = event.target.value;
    const { data } = this.props;
    const { games } = data[gameRound];
    if (!games && this.props.selectRoundGame) await this.props.selectRoundGame(gameRound);
    const page = this.getPage(gameRound);
    await this.setState({ gameRound, page });
  }

  setPlayerTeams = (game) => {
    const { userTeamIds } = this.props;
    let className;
    userTeamIds.forEach((userTeamId) => {
      if (userTeamId === game.team_home.id || userTeamId === game.team_away.id) className = 'teamhighlight';
    });
    return className;
  }

  render() {
    const { gameRound, page } = this.state;
    const { data, maxPage } = this.props;

    const { round = '', games = [] } = data[gameRound];

    let gamesData = [];
    let totalPages = 0;

    if (maxPage) {
      gamesData = games.slice(((page - 1) * maxPage), page * maxPage);
      totalPages = Math.ceil(games.length / maxPage);
    } else {
      gamesData = games;
      totalPages = 0;
    }

    return (
      <div className="competition-games">
        <div className="header-games">
          <div className="title">
            {'Jogos'}
            <span>{`Rodada ${round}`}</span>
          </div>
          <div className="filter-select">
            <select value={gameRound} onChange={this.handleChangeRound}>
              {Object.keys(data).map(roundKey => (
                <option key={roundKey} value={roundKey}>
                  {`Rodada ${roundKey}`}
                </option>
              ))}
            </select>
          </div>
        </div>

        {gamesData.map(game => (
          <GameClassificatory
            key={game.id}
            game={game}
            className={this.setPlayerTeams(game)}
          />
        ))}

        <Paginate
          pageCount={totalPages}
          forcePage={page - 1}
          onPageChange={this.handlePageClick}
          marginPagesDisplayed={0}
        />
      </div>
    );
  }
}

export default TeamsCompetitionsDetailsInformationGames;
