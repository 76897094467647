import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol } from '@fortawesome/free-solid-svg-icons';

const TeamsGamesBetsCardGoals = ({ goals, className }) => (
  <div className={`goals ${className}`}>
    <div className="icon">
      <FontAwesomeIcon icon={faFutbol} />
    </div>
    {goals > 1 && (
      <div className="value">
        {` (${goals})`}
      </div>
    )}
  </div>
);

export default TeamsGamesBetsCardGoals;
