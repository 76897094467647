import RestService from '../services/rest';

import CollectiblesUtils from '../utils/collectibles';

async function getBasicItems() {
  const data = await RestService.getAuthenticated('collectibles/basic/items');
  CollectiblesUtils.sortingBasic(data);
  return data;
}

async function getUserItems(type) {
  const data = await RestService.getAuthenticated(`collectibles/user?type=${type}`);
  return CollectiblesUtils.groupUserItems(data);
}

async function getChests() {
  return RestService.getAuthenticated('collectibles/chests');
}

async function getChestItems(chestId) {
  return RestService.getAuthenticated(`collectibles/chests/items?collectible=${chestId}`);
}

async function openChest(chestId) {
  return RestService.postAuthenticated('collectibles/chests/open', {
    collectible: chestId,
  });
}

async function buyChests(chestId, total, useCredit) {
  return RestService.postAuthenticated('collectibles/chests/buy', {
    collectible: chestId,
    total,
    use_credit: useCredit,
  });
}

export default {
  getBasicItems,
  getUserItems,
  getChests,
  getChestItems,
  openChest,
  buyChests,
};
