import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../../routers/appRouter';

import translator from '../../../data/translator';

import StepsButtons from '../../elements/steps/buttons';
import TutorialWrapper from '../../elements/tutorial/tutorial';

import { gameModeActive } from '../../../app/store/actions/gameModes';
import UsersSelector from '../../../app/store/selectors/users';

import GameModeUtils from '../../../app/utils/gameMode';

export class TutorialGameModeActivation extends React.Component {
  handleActive = async () => {
    const { team, mode, pageName = '' } = this.props;
    await this.props.activeGameMode(mode);

    const baseUri = `/${translator.uri.gameModes[mode]}`;

    if (team) {
      history.push(`${baseUri}/${team.name}`);
    } else {
      history.push(`${baseUri}/${pageName}`);
    }
  }

  render() {
    const {
      mode, userIsPrime, userStatus, content,
    } = this.props;

    const userIsActive = userStatus === 'active';
    const gameModeIsActive = GameModeUtils.data[mode].active;
    const gameModeIsPrime = GameModeUtils.data[mode].prime;

    let text;
    let activeButton = false;

    if (!gameModeIsActive) {
      text = 'O modo de jogo ainda não está disponível. Aguarde que assim que ele estiver acessível, '
      + 'você será informado.';
    } else if (!userIsActive) {
      text = 'Sua conta ainda não foi analisada e ativa, portanto você não poderá ativar o modo '
      + 'de jogo no momento. Fique atento que em breve você receberá um e-mail com a confirmação.';
    } else if (!userIsPrime && gameModeIsPrime) {
      text = 'Esse modo está disponível apenas para usuários PRIMES. Para participar, se torne PRIME '
      + 'e ajude o site a crescer.';
    } else {
      text = 'Agora que você já entendeu como esse modo de jogo funciona. A última coisa que '
      + 'você precisa fazer para participar é clicar no botão abaixo.';
      activeButton = true;
    }

    return (
      <TutorialWrapper image={`game_modes/${mode}.jpg`}>
        <StepsButtons {...this.props} activeButton={activeButton && this.handleActive}>
          {content}
          <p className="text">
            {text}
          </p>
        </StepsButtons>
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  activeGameMode: gameMode => dispatch(gameModeActive(gameMode)),
});

const mapStateToProps = (state, ownProps) => ({
  team: state.user.teams && state.user.teams[ownProps.mode],
  userStatus: state.user.status,
  userIsPrime: UsersSelector.isPrime(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorialGameModeActivation);
