import React from 'react';

import Team from '../../elements/team';
import Table from '../../../elements/table';

const TeamsInitiationProposalsList = (props) => {
  const header = [
    { key: 'team', value: 'Clubes', className: 'name' },
    { key: 'accept', className: 'number', tdClassName: 'tb-button' },
    { key: 'reject', className: 'number', tdClassName: 'tb-button' },
  ];

  const data = props.proposals.map(team => ({
    key: team.id,
    team: <Team data={team} type="table" />,
    accept: (
      <button className="button" type="button" onClick={() => props.accept(team.id, team.name)}>
        {'Aceitar'}
      </button>
    ),
    reject: (
      <button className="button red" type="button" onClick={() => props.reject(team.id)}>
        {'Recusar'}
      </button>
    ),
  }));


  return (
    <div>
      <div className="title-line">
        {'Propostas Recebidas'}
      </div>
      <Table header={header} lines={data} />
    </div>
  );
};

export default TeamsInitiationProposalsList;
