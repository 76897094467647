import React from 'react';

import CardSimple from '../../elements/card/simple';
import Hover from '../../elements/hover';

import TeamsUtils from '../../../app/utils/teams';

import colorsData from '../../../data/colors';
import competitionsData from '../../../data/competitions';

export class TeamsCalendarRound extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hover: props.round.current };
  }

  onHover = async (isHover) => {
    await this.setState({ hover: isHover });
  }

  render() {
    const { hover } = this.state;
    const { round, gameMode } = this.props;
    const {
      number, market, competition, current, past,
    } = round;

    let competitionText;
    let competitionHover;
    const competitionStyle = {};
    if (competition) {
      competitionStyle.background = colorsData.teams.background[competitionsData.leagueColors[competition.key]];
      competitionText = TeamsUtils.getLeagueTypeLabel(gameMode, competition.key);
      competitionHover = `Rodada ${competition.round}`;
    } else {
      competitionStyle.background = colorsData.layout.asbestos;
      competitionText = 'Sem Competição';
    }

    const colorStyle = {};
    if (hover) {
      colorStyle.background = competitionStyle.background;
    }

    let marketText = '';
    let marketTextAbbr = '';

    if (market) {
      marketText = 'Mercado Aberto';
      marketTextAbbr = ' - M.A.';
    }

    return (
      <Hover
        forceHover={current}
        className={`calendar-card${past ? ' past' : ''}`}
        classHover="hovered"
        onHover={this.onHover}
      >
        <CardSimple style={colorStyle}>
          <div className="calendar-card">
            <div className="tag" style={colorStyle}>
              {market && (
              <span className="value">
                {marketText}
              </span>
              )}
              <span className="hover">
                {competitionHover ? `${competitionHover}${marketTextAbbr}` : marketText}
              </span>
            </div>
            <div className="number">
              {number}
            </div>
            <div className="text" style={competitionStyle}>
              {competitionText}
            </div>
          </div>
        </CardSimple>
      </Hover>
    );
  }
}

export default TeamsCalendarRound;
