import React from 'react';

import CampaignInProgressTeamInformationActiveClassificatory from './active/classificatory';
import CampaignInProgressTeamInformationActivePlayOff from './active/playOff';

export const CampaignInProgressTeamInformationActive = ({ team, phase }) => {
  switch (phase.type) {
    case 'play_off':
      return (
        <CampaignInProgressTeamInformationActivePlayOff
          team={team}
          phase={phase}
        />
      );
    case 'classificatory':
    default:
      return (
        <CampaignInProgressTeamInformationActiveClassificatory
          team={team}
          phase={phase}
        />
      );
  }
};

export default CampaignInProgressTeamInformationActive;
