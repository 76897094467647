export default {
  mode: 'manager',
  active: true,
  prime: true,
  basic: false,
  color: '#8e44ad',
  total_players: 12,
  pages: [
    'creation',
    'teams',
    'calendar',
    'games',
    'competitions',
    'statistics',
    'guide',
  ],
  statistics_pages: [
    'score',
    'achievements',
    'games',
    'confrontation',
    'top5',
  ],
  management_pages: [
    'badge',
    'friendly',
    'escalation',
  ],
  list: 'league',
  leagues: {
    league: {
      name: 'Séries',
    },
    contest: {
      name: 'Liga dos Cartolas',
    },
    grand_cup: {
      name: 'Copa Cartola',
    },
    final: {
      name: 'Cartola de Ouro',
    },
  },
  positions: {
    goalkeeper: 'Goleiro',
    defender: 'Zagueiro',
    midfielder: 'Meio Campista',
    forward: 'Atacante',
    coach: 'Técnico',
  },
  positions_abbreviation: {
    goalkeeper: 'GOL',
    defender: 'ZAG',
    midfielder: 'MEI',
    forward: 'ATA',
    coach: 'TEC',
  },
};
