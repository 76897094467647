export default (state = {}, action) => {
  switch (action.type) {
    case 'USER_SET':
      return action.user;
    case 'USER_CLEAN':
      return {};
    case 'USER_COLLECTIBLE_ADD': {
      const collectibleFoundIndex = state.collectibles.findIndex(c => c.id === action.collectibleId);
      if (collectibleFoundIndex !== -1) {
        state.collectibles[collectibleFoundIndex].amount += action.total;
      } else if (action.total > 0) {
        state.collectibles.push({
          id: action.collectibleId,
          amount: action.total,
        });
      }
      state.collectibles = state.collectibles.slice();
      return state;
    }
    case 'USER_NOTIFICATION_SET':
      state.notifications[action.notification] = action.value;
      return state;
    case 'USER_BET_MADE':
      state.bets.current_made = true;
      return state;
    default:
      return state;
  }
};
