import React from 'react';
import { connect } from 'react-redux';

import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { roundBetsGetAvailableRounds } from '../../../../app/store/actions/roundsBets';

import CategoriesIcons from '../../../elements/categories/icons/icons';

export class AdministrationRoundsList extends React.Component {
  async componentDidMount() {
    const { roundAvailables } = this.props;

    if (!roundAvailables.length) await this.props.getAvailableRounds();
  }

  render() {
    const { roundAvailables, currentCurrent } = this.props;

    const number = roundAvailables.slice();
    if (currentCurrent && (!number.length || number[0] !== currentCurrent)) number.unshift(currentCurrent);

    const items = number.map(n => ({
      name: `Rodada ${n}`,
      url: `lista/${n}`,
      description: n === currentCurrent ? 'Rodada em andamento' : 'Rodada Futura',
      icon: faEdit,
    }));


    return (
      <div className="content-container margin-bottom">
        <div className="title-center-menu">
          {'Rodadas não Finalizadas'}
        </div>
        <CategoriesIcons items={items} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAvailableRounds: () => dispatch(roundBetsGetAvailableRounds()),
});

const mapStateToProps = state => ({
  currentCurrent: state.rounds.current && state.rounds.current.number,
  roundAvailables: state.roundsBets.availables,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationRoundsList);
