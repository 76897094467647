import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';

const TeamsDetailsOverviewBadgesItem = (props) => {
  const {
    link, logo, name, infos = {},
  } = props;

  const ItemElement = (
    <div className="champion">
      <div className="image">
        <img src={logo} alt={name} />
      </div>
      <div className="name">
        {name}
      </div>
      <div className="infos">
        {infos.league && (
        <span className="text">
          <b>
            {infos.league}
          </b>
        </span>
        )}
        {infos.position && (
        <span>
          <FontAwesomeIcon icon={faSlackHash} />
          {' '}
          {infos.position}
          {'º'}
        </span>
        )}
        {infos.xp > 0 && (
        <span>
          <b>
            {'XP '}
          </b>
          {infos.xp}
        </span>
        )}
      </div>
    </div>
  );

  if (!link) return ItemElement;

  return (
    <Link to={link}>
      {ItemElement}
    </Link>
  );
};

export default TeamsDetailsOverviewBadgesItem;
