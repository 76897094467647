import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';
import { faStar, faDollarSign, faSignature } from '@fortawesome/free-solid-svg-icons';

import TyneLineChart from '../../../../elements/charts/tinyLineChart';

import Colors from '../../../../../data/colors';

const PlayersDetailsOverviewStatisticsValuation = (props) => {
  const { valuation } = props;
  const {
    position, history, value, variation, high,
  } = valuation;

  const colors = [Colors.layout.clouds];

  const min = Math.min(...history);
  const data = history.map(v => ({ value: v - min }));

  const lines = {
    value: {
      name: 'Valor',
      stackId: 'valuation',
    },
  };

  return (
    <div className="valuation">
      <div className="title-line">
        {'Mercado'}
      </div>
      {value ? (
        <React.Fragment>
          <div className="infos">
            <span>
              <FontAwesomeIcon icon={faSlackHash} />
              {' '}
              {position}
              {'º'}
            </span>
            <span>
              <FontAwesomeIcon icon={faDollarSign} />
              {' '}
              {value}
            </span>
            <span>
              <FontAwesomeIcon icon={faSignature} />
              {' '}
              {variation}
            </span>
            <span>
              <FontAwesomeIcon icon={faStar} />
              {' '}
              {high}
            </span>
          </div>
          <div className="chart">
            <TyneLineChart data={data} lines={lines} colors={colors} />
          </div>
        </React.Fragment>
      ) : (
        <div className="message">
          {'O jogador só terá um valor de mercado ao completar 10 partidas apostadas.'}
        </div>
      )}
    </div>
  );
};

export default PlayersDetailsOverviewStatisticsValuation;
