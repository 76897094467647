import React from 'react';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';

const AdministrationScoreFormTop = ({ number, competition = {}, date }) => (
  <div className="score-update-top">
    <FontAwesomeIcon icon={faSlackHash} />
    {'Jogo '}
    {number + 1}
    <FontAwesomeIcon icon={faMapMarkerAlt} />
    {competition.name}
    <br className="show-for-mobile" />
    <FontAwesomeIcon icon={faCalendar} />
    {moment(date).format('LLL')}
  </div>
);

export default AdministrationScoreFormTop;
