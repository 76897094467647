import React from 'react';

import Autocomplete from '../../../elements/autocomplete';

import TeamsUtils from '../../../../app/utils/teams';

export class AdministrationRoundsGameAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSelected: props.startValue,
      autocompleteValue: '',
      data: [],
    };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) this.props.getRealTeams();
  }

  onAutocompleteValueChange = (value) => {
    const { autocompleteValue } = this.state;
    const { allData } = this.props;

    const stateUpdate = { autocompleteValue: value };

    if (value !== autocompleteValue) {
      const data = TeamsUtils.searchTeams(allData, value).slice(0, 5);
      stateUpdate.data = data;
    }

    this.setState(stateUpdate);
  };

  handleChangeRealTeam = async (value, dataSelected) => {
    await this.setState({
      dataSelected,
      autocompleteValue: '',
      data: [],
    });
    this.props.setData(dataSelected);
  };

  handleReset = async () => {
    await this.setState({
      dataSelected: undefined,
      autocompleteValue: '',
    });
    this.props.setData();
  };

  render() {
    const { dataSelected, autocompleteValue, data } = this.state;
    const { component: Component, placeholder } = this.props;

    if (dataSelected) {
      return (
        <div
          onClick={this.handleReset}
          onKeyDown={this.handleReset}
          role="presentation"
          style={{ cursor: 'pointer' }}
        >
          <Component data={dataSelected} />
        </div>
      );
    }

    return (
      <Autocomplete
        menuStyle={{
          zIndex: 10, position: 'relative', left: 0, top: 0,
        }}
        getItemValue={item => item.name}
        items={data}
        renderItem={(item, isHighlighted) => (
          <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            {item.name}
          </div>
        )
      }
        value={autocompleteValue}
        onChange={(e, value) => this.onAutocompleteValueChange(value)}
        onSelect={this.handleChangeRealTeam}
        placeholder={placeholder}
      />
    );
  }
}

export default AdministrationRoundsGameAutocomplete;
