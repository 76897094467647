import React from 'react';

const TutorialManagerActive = () => (
  <React.Fragment>
    <h3 className="title-center">
      {'Jornada'}
    </h3>
  </React.Fragment>
);

export default TutorialManagerActive;
