import React from 'react';

const AdministrationReportTransactionsGeneral = (props) => {
  const { value, top_unspenders: unspenders } = props.unspent;
  return (
    <div>
      <h3>
        {'Créditos não gastos'}
      </h3>
      <h4>
        {'Total: R$'}
        {value.toFixed(2)}
      </h4>
      <table>

        <tbody>

          <tr>
            <th>
              {'Jogador'}
            </th>
            <th>
              {'Valor'}
            </th>
          </tr>

          {unspenders.map(unspender => (
            <tr key={unspender.user.id}>
              <th>
                {unspender.user.username}
              </th>
              <th>
                {'R$'}
                {unspender.value.toFixed(2)}
              </th>
            </tr>
          ))}

        </tbody>

      </table>
    </div>
  );
};

export default AdministrationReportTransactionsGeneral;
