import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import MenuSecondary from '../elements/menuSecondary';

import RecordsReputation from './reputation/reputation';
import RecordsBets from './bets/bets';
import RecordsDiscard from './discard/discard';
import RecordsAchievements from './achievements/achievements';
import RecordsTop5 from './top5/top5';
import RecordsRounds from './rounds/rounds';
import RecordsSupporters from './supporters/supporters';

export const RecordsPage = (props) => {
  const { currentRoundNumber } = props;

  const menus = [
    {
      name: 'Reputação', uri: '/registros/reputacao', extra: '', component: RecordsReputation,
    },
    {
      name: 'Apostas', uri: '/registros/apostas', extra: '/:interval/:page', component: RecordsBets, menuNotExact: true,
    },
    {
      name: 'Descarte', uri: '/registros/descarte', extra: '/:page', component: RecordsDiscard, menuNotExact: true,
    },
    {
      name: 'Conquistas',
      uri: '/registros/conquistas',
      extra: '/:page',
      component: RecordsAchievements,
      menuNotExact: true,
    },
    {
      name: 'Top 5', uri: '/registros/top5', extra: '/:interval', component: RecordsTop5, menuNotExact: true,
    },
    {
      name: 'Rodadas', uri: '/registros/rodadas', extra: '/:round', component: RecordsRounds, menuNotExact: true,
    },
    {
      name: 'Torcida', uri: '/registros/torcida', extra: '', component: RecordsSupporters,
    },
  ];

  const year = moment().year();

  return (
    <React.Fragment>
      <MenuSecondary menus={menus} tag="Jogadores" />
      <Switch>
        <Redirect exact from="/registros" to={menus[0].uri} />
        <Redirect exact from="/registros/descarte" to="/registros/descarte/1" />
        <Redirect exact from="/registros/conquistas" to="/registros/conquistas/1" />
        <Redirect exact from="/registros/apostas" to={`/registros/apostas/${year}/1`} />
        <Redirect exact from="/registros/top5" to="/registros/top5/geral" />
        <Redirect exact from="/registros/rodadas" to={`/registros/rodadas/${currentRoundNumber - 1}`} />
        {menus.map(menu => (
          <Route
            key={menu.name}
            path={menu.uri + menu.extra}
            component={menu.component}
          />
        ))}
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  currentRoundNumber: state.rounds.current && state.rounds.current.number,
});

export default connect(mapStateToProps)(RecordsPage);
