import React from 'react';

const PlayerImage = (props) => {
  const { player } = props;

  return (
    <img className="player-image" src={player.picture.url} alt={player.username} />
  );
};

export default PlayerImage;
