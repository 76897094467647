import React from 'react';
import moment from 'moment-timezone';

export const FooterBottom = () => (
  <div className="footer-legal">
    <div className="content-container">
      <div className="text">
        {`© ${moment().year()} Barbolão. Todos os diretos reservados.`}
      </div>
    </div>
  </div>
);

export default FooterBottom;
