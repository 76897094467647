export default {
  bets: {
    players_perPage: 16,
  },
  discard: {
    players_perPage: 16,
  },
  achievements: {
    players_perPage: 16,
  },
  supporters: {
    table: {
      total_perPage: 16,
    },
    chart: {
      total_perPage: 12,
    },
    words: {
      font: {
        min: 10,
        max: 50,
      },
    },
  },
};
