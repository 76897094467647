import React from 'react';
import { connect } from 'react-redux';

import UsersSelector from '../../../../app/store/selectors/users';

import { competitionsGetPremiums } from '../../../../app/store/actions/competitions';
import { addCart } from '../../../../app/store/actions/cart';

import TeamsCompetitionsPremiumsCard from './teamsCompetitionsPremiumsCard';

import pageTitleService from '../../../../app/services/pageTitle';

import { history } from '../../../../routers/appRouter';

export class TeamsCompetitionsPremiums extends React.Component {
  async componentDidMount() {
    pageTitleService.setTitle('Competições Premiums');
    await this.props.getCompetitions();
  }

  addToCart = async (competition, userIsPrime, teamId) => {
    await this.props.addToCart({ ...competition, userIsPrime, teamId });
    history.push('/carrinho');
  }

  render() {
    const {
      allCompetitions, userTeam, userIsPrime, modeData, hasPlayers,
    } = this.props;

    const competitions = allCompetitions
      .filter(competition => competition.mode_type === modeData.mode)
      .sort((a, b) => (a.max_teams - a.teams.length) - (b.max_teams - b.teams.length));

    return (
      <div className="competitions-premiums">
        <h3 className="title-center">
          {'Campeonatos Premiums'}
        </h3>
        {(competitions.length === 0) && <div className="competitions-empty">Sem campeonatos disponíveis</div>}
        <div className="content-container">
          {competitions.map(competition => (
            <TeamsCompetitionsPremiumsCard
              key={competition.id}
              competition={competition}
              userTeam={userTeam}
              userIsPrime={userIsPrime}
              hasPlayers={hasPlayers}
              selectPremium={() => this.addToCart(competition, userIsPrime, userTeam && userTeam.id)}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCompetitions: () => dispatch(competitionsGetPremiums()),
  addToCart: item => dispatch(addCart('premium', item)),
});

const mapStateToProps = (state, { modeData }) => ({
  allCompetitions: state.competitions.premiums || [],
  userTeam: state.user.teams[modeData.mode],
  userIsPrime: UsersSelector.isPrime(state),
  hasPlayers: !!state.settings.game_modes[modeData.mode].max_players,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCompetitionsPremiums);
