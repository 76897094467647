import React from 'react';

const TeamsGamesDetailsHeaderTitle = ({ title, subTitle }) => (
  <div className="game-title">
    {title && (
      <div className="title">
        {title}
      </div>
    )}
    {subTitle && (
      <div className="sub-title">
        {subTitle}
      </div>
    )}
  </div>
);

export default TeamsGamesDetailsHeaderTitle;
