import RestService from '../services/rest';
import RealTeamsUtils from '../utils/realTeams';

async function getTeams() {
  const realTeams = await RestService.getAuthenticated('real_teams');
  return realTeams.map(team => RealTeamsUtils.completeRealTeam(team));
}

export default {
  getTeams,
};
