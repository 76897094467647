import React from 'react';

import Steps from '../../elements/steps/steps';

import ContactForm from './form';
import ContactSuccess from './success';

const ContactPage = () => (
  <div className="content-container">
    <Steps>
      <ContactForm />
      <ContactSuccess />
    </Steps>
  </div>
);

export default ContactPage;
