import React from 'react';

import HeaderTop from './top/headerTop';
import HeaderContent from './content/headerContent';
import HeaderBottom from './bottom/headerBottom';

const Header = () => (
  <header className="header">
    <HeaderTop />
    <HeaderContent />
    <HeaderBottom />
  </header>
);

export default Header;
