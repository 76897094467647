import RestService from '../services/rest';
import CompetitionsData from '../../data/competitions';
import CompetitionsUtils from '../utils/competitions';
import TeamsUtils from '../utils/teams';
import PlayersUtils from '../utils/players';
import GamesUtils from '../utils/games';

async function getStarted(gameMode, type) {
  const url = `competitions/${gameMode}/list/started?type=${type}`;
  const competitions = await RestService.getAuthenticated(url);
  return competitions.map(competition => CompetitionsUtils.completeCompetition(competition));
}

async function getClosed(gameMode, type, year) {
  const url = `competitions/${gameMode}/list/closed?type=${type}&year=${year}`;
  const competitions = await RestService.getAuthenticated(url);
  return competitions.map(competition => CompetitionsUtils.completeCompetition(competition));
}

async function getById(competitionId) {
  const competition = await RestService.getAuthenticated(`competitions/general?competition=${competitionId}`);
  competition.general = CompetitionsUtils.completeCompetition(competition.general);
  competition.group = CompetitionsUtils.completeGroup(competition.group);
  return competition;
}

async function getGroup(competitionId, groupId) {
  const group = await RestService.getAuthenticated(`competitions/group?competition=${competitionId}&group=${groupId}`);
  return CompetitionsUtils.completeGroup(group);
}

async function getRanking(competitionId, page) {
  const { limit } = CompetitionsData.ranking;
  const skip = limit * page;
  const url = `competitions/ranking?competition=${competitionId}&skip=${skip}&limit=${limit}`;
  const teams = await RestService.getAuthenticated(url);
  teams.data.forEach((row) => { row.team = TeamsUtils.completeTeam(row.team); });
  return teams;
}

async function getStrikers(competitionId, page) {
  const { limit } = CompetitionsData.strikers;
  const skip = limit * page;
  const url = `competitions/strikers?competition=${competitionId}&skip=${skip}&limit=${limit}`;
  const players = await RestService.getAuthenticated(url);
  players.data.forEach((row) => { row.player = PlayersUtils.completePlayer(row.player); });
  return players;
}

async function getRankedGames(groupId, roundNumber) {
  const url = `competitions/group/ranked/games?group=${groupId}&round=${roundNumber}`;
  const games = await RestService.getAuthenticated(url);
  const gameRoundKeys = Object.keys(games || {});
  gameRoundKeys.forEach((gameRound) => {
    games[gameRound].games = games[gameRound].games.map(game => GamesUtils.completeGame(game));
  });
  return games;
}

async function getCompetitionsTypes() {
  return RestService.getAuthenticated('competitions/type/list');
}

async function getCompetitionsPremiums() {
  const premiums = await RestService.getAuthenticated('competitions/premium');

  premiums.forEach((premium) => {
    premium.teams = premium.teams.map(team => TeamsUtils.completeTeam(team));
  });

  return premiums;
}

async function participatePremium(premiumId, teamId, useCredit) {
  return RestService.postAuthenticated('competitions/premium/participate', {
    premium: premiumId,
    team: teamId,
    use_credit: useCredit,
  });
}

export default {
  getStarted,
  getClosed,
  getById,
  getGroup,
  getRanking,
  getStrikers,
  getRankedGames,
  getCompetitionsTypes,
  getCompetitionsPremiums,
  participatePremium,
};
