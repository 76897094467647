import React from 'react';

const RankingPosition = (props) => {
  const {
    name, picture, value, position, type,
  } = props;

  return (
    <div className="ranking-position">
      <div className="title-line">
        {'Minha Posição'}
      </div>
      <div className={`content ${type || ''}`}>
        <div className="picture">
          <img src={picture} alt={name} />
        </div>
        <div className="info">
          <div className="text">
            {'Pontos: '}
            {value}
          </div>
          {position && (
            <React.Fragment>
              <div className="text">
                {'Posição: '}
                {position}
                {'º'}
              </div>
              <button className="button" type="button" onClick={props.handlePage}>
                {'ver na tabela'}
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default RankingPosition;
