import React from 'react';

const TeamsCompetitionsPremiumsCardInfo = (props) => {
  const { name, number } = props;

  return (
    <div className="info">
      <div className="name">{name}</div>
      <div className="number">{number}</div>
      <div className="label">Vagas Restantes</div>
    </div>
  );
};

export default TeamsCompetitionsPremiumsCardInfo;
