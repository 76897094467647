import React from 'react';
import { connect } from 'react-redux';

import GamesUtil from '../../../../app/utils/games';

import { scrollTo } from '../../../../routers/appRouter';

import { teamsStatisticsGetAchievements } from '../../../../app/store/actions/teamsStatistics';

import TeamsStatisticsAchievementsHeader from './header';

import Team from '../../elements/team';
import RankingTablePositionChange from '../../../elements/ranking/tablePositionChange';

import Ranking from '../../../elements/statistics/ranking';

export class TeamsStatisticsAchievements extends React.Component {
  async componentDidMount() {
    const { top3, match } = this.props;
    const page = Number(match.params.page || 1);
    if (page === 1 && top3.length !== 3) scrollTo('menu-secondary');
    if (page !== 1 && top3.length !== 3) await this.props.getAchievements('', 1);
  }

  render() {
    const {
      top3, teams, totalPages, types, type, match, authTeam,
    } = this.props;

    const tableHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      {
        key: 'team', value: 'Campeões', className: 'name', colspan: 3,
      },
      { key: 'pos_change', tdClassName: 'pos-change', hidden: true },
      {
        key: 'value', value: 'Pontos', className: 'number', tdClassName: 'high',
      },
      { key: 'champion', value: 'Títulos', className: 'number' },
      { key: 'second_place', value: 'Vices', className: 'number' },
      { key: 'third_place', value: 'Terceiros', className: 'number' },
    ];

    const tableLines = teams.map(row => ({
      key: row.team.id,
      position: row.position,
      team: <Team data={row.team} type="table" />,
      pos_change: <RankingTablePositionChange value={row.position_last - row.position} />,
      value: row.value,
      champion: row.achievements.champion,
      second_place: row.achievements.second_place,
      third_place: row.achievements.third_place,
    }));

    const authInformation = {};
    if (authTeam) {
      authInformation.type = 'team';
      authInformation.team = authTeam;
      authInformation.rankings = authTeam.rankings;
      authInformation.tag = `achievements_${type}`;
    }

    return (
      <div className="achievements-list">
        <TeamsStatisticsAchievementsHeader top3={top3} />
        <div className="content-container margin-bottom">
          <div className="title-center-menu">
            {'Campeões'}
          </div>
          <Ranking
            match={match}
            totalPages={totalPages}
            tableHeader={tableHeader}
            tableLines={tableLines}
            authInformation={authInformation}
            getRanking={this.props.getAchievements}
            buttonTypes={types}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const typeName = ownProps.match.params.type;
  const gameMode = ownProps.modeData.mode;
  const type = GamesUtil.convertNameType(typeName);
  return {
    getAchievements: (interval, page) => dispatch(teamsStatisticsGetAchievements(gameMode, type, interval, page)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.match.params.page || 1;
  const typeName = ownProps.match.params.type;
  const { mode } = ownProps.modeData;
  const type = GamesUtil.convertNameType(typeName);

  const ranking = state.teamsStatistics.achievements[mode];
  return {
    top3: ((ranking && ranking.data[1]) || []).slice(0, 3),
    teams: (ranking && ranking.data[page]) || [],
    totalPages: (ranking && ranking.total) || 0,
    types: state.settings.competitions && state.settings.competitions.types,
    type,
    authTeam: state.user.teams && state.user.teams[mode],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatisticsAchievements);
