import React from 'react';
import { connect } from 'react-redux';

import FooterContent from './footerContent';
import FooterBottom from './footerBottom';

export const Footer = ({ statistics }) => (
  <footer>
    <FooterContent statistics={statistics} />
    <FooterBottom />
  </footer>
);

const mapStateToProps = state => ({
  statistics: state.statistics,
});

export default connect(mapStateToProps)(Footer);
