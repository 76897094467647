import React from 'react';

import TeamsUtils from '../../../../app/utils/teams';

import TeamsDetailsHeaderButtons from './teamsDetailsHeaderButtons';

const TeamsDetailsHeaderInfo = (props) => {
  const { team, url, mode } = props;
  const { name, badge } = team;

  const infoStyle = {};
  infoStyle.color = TeamsUtils.getBackgroundColor(team);

  return (
    <div className="info">
      <div className="logo">
        <img src={badge.url} alt={name} />
      </div>
      <div className="name" style={infoStyle}>
        {name}
      </div>
      <TeamsDetailsHeaderButtons
        team={team}
        url={url}
        mode={mode}
      />
    </div>
  );
};

export default TeamsDetailsHeaderInfo;
