import React from 'react';
import { connect } from 'react-redux';

import { recordsGetDiscard } from '../../../app/store/actions/records';
import pageTitleService from '../../../app/services/pageTitle';

import { scrollTo } from '../../../routers/appRouter';

import RecordsData from '../../../data/records';

import Player from '../../players/elements/player';

import TableAuth from '../../elements/tableAuth';
import IntervalSelect from '../../elements/statistics/intervalSelect';

export class RecordsDiscard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: Number(props.match.params.page || 1),
    };
  }

  async componentDidMount() {
    const { page } = this.state;
    pageTitleService.setTitle('Descarte');
    await this.props.getDiscard(page);
    scrollTo('menu-secondary');
  }

  render() {
    const { players, totalPages, match } = this.props;
    const { authUser, userDiscardValue, userDiscardPosition } = this.props;

    const discardHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      { key: 'user', value: 'Descarte', colspan: 2 },
      { key: 'score', value: 'Pontos', tdClassName: 'high' },
      { key: 'high', value: 'Melhor' },
      { key: 'scores', value: 'Pontuações' },
    ];

    const discardData = players.map(user => ({
      key: user.id,
      position: user.discard.position,
      user: <Player data={user} type="table" />,
      score: user.discard.total,
      high: user.discard.high,
      scores: user.discard.score.join(' - '),
    }));

    return (
      <div className="discard-page">
        <div className="content-container margin-bottom">
          <div className="title-center-menu">
            {'Descarte'}
          </div>
          <IntervalSelect
            totalPages={totalPages}
            perPage={RecordsData.discard.players_perPage}
            match={match}
            removeSelect
            userPosition={{
              name: authUser.username,
              picture: authUser.picture.url,
              value: userDiscardValue,
              position: userDiscardPosition,
              type: 'player',
            }}
          />
          <TableAuth header={discardHeader} lines={discardData} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getDiscard: page => dispatch(recordsGetDiscard(page)),
});

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.match.params.page || 1;
  return {
    players: state.records.discard.data[page] || [],
    totalPages: state.records.discard.total,
    authUser: state.user,
    userDiscardValue: (state.user.discard && state.user.discard.total) || 0,
    userDiscardPosition: state.user.discard && state.user.discard.position,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordsDiscard);
