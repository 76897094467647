import React from 'react';

import Table from '../../../../../elements/table';
import Player from '../../../../../players/elements/player';
import PlayersUtils from '../../../../../../app/utils/players';

import PlayerTableValuation from '../../../../../players/table/valuation';
import PlayerTableBet from '../../../../../players/table/bet';

export const TeamsDetailsManagementEscalationFormationTable = (props) => {
  const { formation } = props;

  const getPlayersTable = (positions) => {
    const { players, gamesRoundsPositions = {} } = props;

    return positions.map((position) => {
      const data = {
        key: position,
        pos: props.getPositionName(position),
        player: <Player
          data={PlayersUtils.completePlayer()}
          type="table"
          label={gamesRoundsPositions[position]}
        />,
        value: '-',
        lastBets: '-',
      };

      const player = players.find(p => p.position === position);

      if (player) {
        data.player = (
          <Player
            data={player.user}
            type="table"
            label={gamesRoundsPositions[position]}
          />
        );

        const { valuation } = player.user;
        data.value = <PlayerTableValuation valuation={valuation} />;
        data.lastBets = <PlayerTableBet bets={player.user.bets} />;
      }

      return data;
    });
  };

  const positions = Object.keys(formation);

  const tableHeader = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'player', value: 'Escalação', className: 'name', colspan: 2,
    },
    {
      key: 'value', value: 'Valor', className: 'number', tdClassName: 'high',
    },
    { key: 'bet', value: 'Aposta', className: 'number' },
    { key: 'options', className: 'number' },
  ];

  const positionsTable = getPlayersTable(positions);

  const tableData = positionsTable.map(position => ({
    key: position.key,
    position: position.pos,
    player: position.player,
    value: position.value,
    bet: position.lastBets,
    options: (
      <button
        type="button"
        className="button"
        onClick={() => props.selectPosition(position.key)}
      >
        {'Alterar'}
      </button>
    ),
  }));

  return (<Table header={tableHeader} lines={tableData} />);
};

export default TeamsDetailsManagementEscalationFormationTable;
