export default {
  wall: {
    generalName: 'general',
    updateTime: 20 * 1000,
    generalUpdateTime: 1 * 60 * 1000,
  },
  inbox: {
    rooms: {
      perPage: 12,
      updateTime: 1 * 60 * 1000,
    },
    messages: {
      perPage: 10,
      updateTime: 1 * 60 * 1000,
    },
  },
};
