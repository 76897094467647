import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import TutorialAccount from './account/account';
import TutorialGeneral from './general/general';

export const TutorialPage = () => {
  const menus = [
    { name: 'Conta', uri: '/tutorial/conta', component: TutorialAccount },
    { name: 'Geral', uri: '/tutorial/geral', component: TutorialGeneral },
  ];

  return (
    <Switch>
      <Redirect exact from="/tutorial" to={menus[0].uri} />
      {menus.map(menu => (
        <Route
          key={menu.name}
          path={menu.uri}
          component={menu.component}
        />
      ))}
    </Switch>
  );
};

export default TutorialPage;
