import React from 'react';

import TeamsUtils from '../../../../app/utils/teams';

import ImageLoad from '../../../elements/image/imageLoad';

const TeamTable = (props) => {
  const { team } = props;

  const teamStyle = {
    background: TeamsUtils.getBackgroundColor(team),
  };

  return (
    <div className="card-all card-table card-squad real-team-card" key={team.name}>
      <div className="badge" style={teamStyle}>
        <ImageLoad
          src={team.badge.url}
          alt={team.name}
          placeholder="/images/team_placeholder.png"
        />
      </div>
      <div className="texts">
        <div className="name">
          {team.name}
        </div>
      </div>
    </div>
  );
};

export default TeamTable;
