import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import RcProgressLine from '../../../elements/rcProgress/line';

import RoundsUtils from '../../../../app/utils/rounds';

import betsData from '../../../../data/bets';
import colorsData from '../../../../data/colors';

const PlayersDetailsHeaderBets = (props) => {
  const { bet, round } = props;

  if (!bet) {
    return (
      <div className="bets-none">
        <FontAwesomeIcon icon={faThumbsDown} />
        <div className="text">
          {'Usuário não apostou'}
          <br />
          {`na rodada ${round.number}!`}
        </div>
      </div>
    );
  }

  return (
    <div className="bets clearfix">
      <div className="title">
        <div className="score-value">
          {bet.score || 0}
        </div>
        <div className="texts">
          <div className="score-text">
            {`Ponto${bet.score !== 1 ? 's' : ''}`}
          </div>
          <div className="round">
            {'Rodada '}
            {bet.round}
          </div>
        </div>
      </div>
      {bet.games.map(game => (
        <div key={game.number} className="game">
          <div className="label">
            <div className="teams">
              <div className="name">
                {round.games[game.number].team_home.name}
              </div>
              <div className="abbr">
                {round.games[game.number].team_home.abbr}
              </div>
              <div className="result">
                {game.score_home}
                {'x'}
                {game.score_away}
              </div>
              <div className="name">
                {round.games[game.number].team_away.name}
              </div>
              <div className="abbr">
                {round.games[game.number].team_away.abbr}
              </div>
            </div>
            <div className="score">
              {game.score || 0}
              {' PT'}
            </div>
          </div>
          <div
            className={`progress${round.games[game.number].status === 'not_started'
            || round.games[game.number].status === 'in_progress' ? ' in-progress' : ''}`}
          >
            <RcProgressLine
              percent={RoundsUtils.calculateProgress(round.games[game.number].date)}
              strokeWidth="5"
              trailWidth="5"
              strokeColor={colorsData.layout[betsData.colors[game.score || 0]]}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlayersDetailsHeaderBets;
