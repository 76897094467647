import TeamsUtils from './teams';
import CompetitionsUtils from './competitions';
import PlayersUtils from './players';

import translator from '../../data/translator';

const GameModel = {
  competition: {
    competition: {
      name: 'Amistoso',
    },
  },
};

function completeGameBetPlayers(players) {
  players.forEach((player) => {
    player.user = PlayersUtils.completePlayer(player.user);
  });
}

function completeGameStrikersPlayers(players = []) {
  players.forEach((player) => {
    if (player.players) player.players = PlayersUtils.completePlayer(player.players);
  });
}

function completeGame(game = {}) {
  game = { ...GameModel, ...game };

  game.team_home = TeamsUtils.completeTeam(game.team_home);
  game.team_away = TeamsUtils.completeTeam(game.team_away);

  if (game.team_home.mode_type) game.mode_type = game.team_home.mode_type;
  else if (game.team_away.mode_type) game.mode_type = game.team_away.mode_type;

  game.competition.name = CompetitionsUtils.getName(game.competition.competition);

  if (game.team_home.bets && game.team_home.bets.players) completeGameBetPlayers(game.team_home.bets.players);
  if (game.team_away.bets && game.team_away.bets.players) completeGameBetPlayers(game.team_away.bets.players);
  completeGameStrikersPlayers(game.strikers_home);
  completeGameStrikersPlayers(game.strikers_away);

  return game;
}

function convertNameType(name) {
  return Object.keys(translator.uri.competitions).reduce(
    (o, n) => (translator.uri.competitions[n] === name ? n : o), '',
  );
}

function getLink(game) {
  return `/${translator.uri.gameModes[game.mode_type]}/jogos/${game.id}`;
}

function completeGamesControntation(data = {}) {
  const { games = [] } = data;

  const teams = [data.team_home.team, data.team_away.team];

  games.forEach((game) => {
    game.team_home = teams.find(t => t.id === game.team_home);
    game.team_away = teams.find(t => t.id === game.team_away);
  });

  data.games = data.games.map(game => completeGame(game));
}

export default {
  completeGame,
  convertNameType,
  getLink,
  completeGamesControntation,
};
