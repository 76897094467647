import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PlayersUtils from '../../../app/utils/players';

import TutorialWrapper from '../../elements/tutorial/tutorial';

const TutorialGeneralGameModes = (props) => {
  const { username, userStatus } = props;

  return (
    <TutorialWrapper image="general/game_modes.jpg">
      <h3 className="title-center">
        {'Modos de Jogos'}
      </h3>
      {userStatus && (
      <div className="section">
        <p>
          {'Para poder participar dos modos de jogos é preciso antes ter a conta aprovada. '
          + 'Enquanto sua conta está sendo analisada, vamos entender um pouco melhor cada modo.'}
        </p>
      </div>
      )}
      <div className="section">
        <h4>
          {'Fantasia'}
        </h4>
        <div className="section-content">
          <p>
            {'Neste modo, o jogador irá criar um clube, podendo escolher o nome e o escudo que deseja.'}
          </p>
          <p>
            {'Seu clube irá disputar torneios, amistosos e outro modos de disputa com os demais clubes fantasias.'}
          </p>
          <p>
            {'Os pontos realizados nas apostas pelo seu jogador representarão o número de gols feito pelo seu '
              + 'clube nos jogos.'}
          </p>
        </div>
      </div>
      <div className="section">
        <h4>
          {'Clube Real'}
        </h4>
        <div className="section-content">
          <p>
            {'Neste modo, o site disponibiliza clubes, que são divididos em Ligas. Cada clube pode ter '
          + 'até 6 jogadores e são administrados por um jogador denominado “Presidente”.'}
          </p>
          <p>
            {'O presidente poderá nomear auxiliares em sua administração. As posições para disputa de jogos com outros '
          + 'clubes seguem as posições do Futsal. Neste modo valem as apostas de todos os jogador do clube.'}
          </p>
          <p>
            {'Para entrar em um clube, é preciso aceitar um convite feito pelo presidente do mesmo.'}
          </p>
        </div>
      </div>
      <div className="section">
        <h4>
          {'Seleção'}
        </h4>
        <div className="section-content">
          <p>
            {'Neste modo, o site disponibiliza seleções, que são divididas em Federações. Cada seleção pode '
              + 'ter até 14 jogadores e são administrados por um jogador denominado “Presidente”.'}
          </p>
          <p>
            {'O presidente poderá nomear auxiliares em sua administração. As posições para disputa de jogos com outras '
              + 'seleções seguem as posições do Futebol de Campo. Neste modo valem as apostas de todos os jogador da '
              + 'seleção.'}
          </p>
          <p className="text">
            {'Para entrar em uma seleção, é preciso aceitar um convite feito pelo presidente da mesma.'}
          </p>
        </div>
      </div>
      <button className="button normal" type="button" onClick={props.previousStep}>
        {'Voltar'}
      </button>
      <Link className="button normal light-blue" to={PlayersUtils.getLink(username)}>
        {'Meu Jogador'}
      </Link>
    </TutorialWrapper>
  );
};

const mapStateToProps = state => ({
  username: state.user.username,
  userStatus: state.user.status,
});

export default connect(mapStateToProps)(TutorialGeneralGameModes);
