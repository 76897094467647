import RestService from '../services/rest';
import GamesUtils from '../utils/games';

async function getResume() {
  const resume = await RestService.getAuthenticated('rounds/statistics/resume');
  (resume.games || []).forEach((gameModeGames) => {
    (gameModeGames.games || []).forEach((game) => {
      game.game = GamesUtils.completeGame(game.game);
    });
  });
  return resume;
}

export default {
  getResume,
};
