import React from 'react';

import PlayersDetailsOverviewStatisticsBetsRowBar from './rowBar';
import PlayersDetailsOverviewStatisticsBetsRowPie from './rowPie';

const PlayersDetailsOverviewStatisticsBets = (props) => {
  const { bets, username } = props;
  const { score, position, difficulty } = bets;
  const { generalScoreAverage, generalDifficulty } = props;

  return (
    <div className="bets">
      <div className="title-line">
        {'Apostas'}
      </div>
      <div className="content">
        <PlayersDetailsOverviewStatisticsBetsRowBar
          title="Total de Pontos"
          chartLabel="Pontos"
          score={score.total}
          player={score.average}
          general={generalScoreAverage}
          username={username}
        />
        <PlayersDetailsOverviewStatisticsBetsRowBar
          title="Dificuldade"
          chartLabel="Dificuldade"
          score={difficulty.total.toFixed(1)}
          player={difficulty.average}
          general={generalDifficulty}
          username={username}
        />
        <PlayersDetailsOverviewStatisticsBetsRowPie
          types={score.types}
          high={score.high}
          position={position}
        />
      </div>
    </div>
  );
};

export default PlayersDetailsOverviewStatisticsBets;
