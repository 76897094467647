import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsGamesEscalationTeam from './team';

const TeamsGamesEscalation = (props) => {
  const { game } = props;

  return (
    <Grid fluid className="content-container with-margin">
      <Row>
        <Col sm={12} md={6}>
          <TeamsGamesEscalationTeam
            team={game.team_home}
            strikers={game.strikers_home}
            isHome
          />
        </Col>
        <Col sm={12} md={6}>
          <TeamsGamesEscalationTeam
            team={game.team_away}
            strikers={game.strikers_away}
          />
        </Col>
      </Row>
    </Grid>
  );
};

const mapStateToProps = state => ({
  game: state.games.details && state.games.details.game,
});

export default connect(mapStateToProps)(TeamsGamesEscalation);
