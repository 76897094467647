const initialState = {
  periods: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REPORTS_GENERAL_SET':
      return {
        ...state,
        general: action.general,
      };
    case 'REPORTS_PERIOD_SET':
      state.periods[action.period] = action.data;
      state.periods = { ...state.periods };
      return state;
    default:
      return state;
  }
};
