import React from 'react';
import { connect } from 'react-redux';

import { recordsGetTop5 } from '../../../app/store/actions/records';

import pageTitleService from '../../../app/services/pageTitle';

import { scrollTo } from '../../../routers/appRouter';

import IntervalSelect from '../../elements/statistics/intervalSelect';
import Top5Table from '../../elements/top5/top5Table';
import Player from '../../players/elements/player';

import Top5Bets from './top5Bets';
import Top5Discard from './top5Discard';
import Top5Valuation from './top5Valuation';
import Top5Collectibles from './top5Collectibles';
import RecordsTop5Subscription from './top5Subscription';
import RecordsTop5Achievements from './top5Achievements';

export class RecordsTop5 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      interval: props.match.params.interval,
    };
  }

  async componentDidMount() {
    const { interval } = this.state;
    pageTitleService.setTitle('Top 5');
    await this.props.getTop5(interval);
    scrollTo('menu-secondary', undefined, 100);
  }

  createTable = (data, title, valueTxt, typeData) => {
    if (!data) return (null);
    return (
      <Top5Table
        title={title}
        data={data}
        typeData={typeData}
        entity="user"
        component={Player}
        header={{ entity: 'Jogador', value: valueTxt }}
      />
    );
  }

  render() {
    const { data, match } = this.props;

    if (!data) return (null);

    return (
      <div className="content-container">
        <div className="title-center-menu">
          {'Top 5'}
        </div>
        <div className="right-options">
          <IntervalSelect match={match} onlyInterval />
        </div>
        {data.bets && (<Top5Bets data={data.bets} createTable={this.createTable} />)}
        {data.discard && (<Top5Discard data={data.discard} createTable={this.createTable} />)}
        {data.valuation && (<Top5Valuation data={data.valuation} createTable={this.createTable} />)}
        {data.collectibles && (<Top5Collectibles data={data.collectibles} createTable={this.createTable} />)}
        {data.subscription && (<RecordsTop5Subscription data={data.subscription} createTable={this.createTable} />)}
        {data.achievements && (<RecordsTop5Achievements data={data.achievements} createTable={this.createTable} />)}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTop5: interval => dispatch(recordsGetTop5(interval)),
});

const mapStateToProps = (state, ownProps) => {
  const interval = ownProps.match.params.interval === 'geral' ? 'all' : ownProps.match.params.interval;
  return {
    data: state.records.top5[interval],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordsTop5);
