import React from 'react';

import PlayersDetailsBetsRoundInfo from './info';
import PlayersDetailsBetsRoundTeamNames from './teamNames';
import PlayersDetailsBetsRoundTeam from './team';

export default class PlayersDetailsBetsRoundGameForm extends React.Component {
  onScoreChange = (isHome, value) => {
    const { game } = this.props;
    value = value.replace(/\D/g, '');
    this.props.onScoreChange(game.index, isHome, value);
  };

  onScoreHomeChange = (e) => {
    const scoreHome = e.target.value;
    this.onScoreChange(true, scoreHome);
  };

  onScoreAwayChange = (e) => {
    const scoreAway = e.target.value;
    this.onScoreChange(false, scoreAway);
  };

  render() {
    const { game } = this.props;

    return (
      <div className="game">

        <PlayersDetailsBetsRoundTeamNames game={game} />

        <div className="bet">
          <PlayersDetailsBetsRoundTeam team={game.team_home} type="home" />

          <div className="result-form">
            <input
              type="tel"
              className="text-input"
              value={game.bet.score_home !== undefined ? game.bet.score_home : ''}
              onChange={this.onScoreHomeChange}
              maxLength={2}
            />
            <div>
              {'x'}
            </div>
            <input
              type="tel"
              className="text-input"
              value={game.bet.score_away !== undefined ? game.bet.score_away : ''}
              onChange={this.onScoreAwayChange}
              maxLength={2}
            />
          </div>

          <PlayersDetailsBetsRoundTeam team={game.team_away} type="away" />
        </div>

        <PlayersDetailsBetsRoundInfo game={game} />

      </div>
    );
  }
}
