import React from 'react';

const AdministrationUsersCpf = ({ cpf = '' }) => {
  if (cpf) {
    cpf = cpf.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
    cpf = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
  }

  return (
    <a href={`https://www.situacaocadastral.com.br/?cpf=${cpf}`} rel="noopener noreferrer" target="_blank">
      {cpf}
    </a>
  );
};

export default AdministrationUsersCpf;
