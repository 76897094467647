import React from 'react';
import { Link } from 'react-router-dom';

import GamesUtils from '../../../../app/utils/games';

const GameListResult = ({ game, round }) => (
  <Link to={GamesUtils.getLink(game)}>
    <div className="result">
      <div className="competition">{game.competition.name}</div>
      {game.score_home}
      {' - '}
      {game.score_away}
      {round && (
      <div className="competition">
        {'Rodada '}
        {game.round}
      </div>
      )}
    </div>
  </Link>
);

export default GameListResult;
