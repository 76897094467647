import React from 'react';

export const CampaignInProgressDetailsGameLogo = ({ team, isCurrent }) => (
  <React.Fragment>
    <div className={`mask${(isCurrent ? ' current' : '')}`}>
      <div className="texture" />
    </div>
    <div className="presentation">
      <img src={team.badge.url} alt={team.name} />
    </div>
  </React.Fragment>
);

export default CampaignInProgressDetailsGameLogo;
