import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import PlayersDetailsHeaderProfile from './playersDetailsHeaderProfile';
import PlayersDetailsHeaderBets from './playersDetailsHeaderBets';
import PlayersDetailsHeaderInfo from './playersDetailsHeaderInfo';

import MultipleCarousel from '../../../elements/multipleCarousel';

import PlayerIcons from '../../elements/icons';

const PlayersDetailsHeader = (props) => {
  const {
    player, bet, round, xpPerLevel,
  } = props;

  if (!round) return (null);

  const xpBar = ((player.achievements.xp % xpPerLevel) / xpPerLevel) * 100;

  const Icons = (
    <PlayerIcons
      player={player}
      forceSize={1}
      type="table"
      options={{ prime: true, bet: true }}
    />
  );

  const items = [
    <PlayersDetailsHeaderInfo
      registrationDate={player.created_at}
      lastAuthDate={player.last_auth}
      firstName={player.first_name}
      gender={player.gender}
      city={player.location && player.location.name && player.location.name.split(',')[0].split('-')[0]}
      singleTeam={player.teams.single && player.teams.single.name}
      clubTeam={player.teams.club && player.teams.club.name}
      nationalTeam={player.teams.national && player.teams.national.name}
      managerTeam={player.teams.manager && player.teams.manager.name}
      realTeam={player.real_team && player.real_team.name}
      icons={Icons}
    />,
    <PlayersDetailsHeaderBets
      bet={bet}
      round={round}
    />,
  ];

  return (
    <header className="player-header">
      <div className="content-container">
        <Grid fluid>
          <Row>
            <Col lg={4}>
              <PlayersDetailsHeaderProfile
                picture={player.picture.url}
                status={player.status}
                username={player.username}
                achievements={player.achievements}
                xp={xpBar}
                reputation={player.level.value}
                icons={Icons}
              />
            </Col>
            <Col lg={8} className="carrossel-items">
              <MultipleCarousel
                items={items}
                xs={1}
                sm={1}
                md={1}
                lg={2}
                start={1}
                indicator
              />
            </Col>
          </Row>
        </Grid>
      </div>
    </header>
  );
};

const mapStateToProps = state => ({
  bet: state.roundsBets.users[state.players.details.id]
  && state.roundsBets.users[state.players.details.id][state.rounds.numberClosed],
  round: state.rounds.byNumber[state.rounds.numberClosed],
  xpPerLevel: state.settings.achievement && state.settings.achievement.level
   && state.settings.achievement.level.user && state.settings.achievement.level.user.xp,
});

export default connect(mapStateToProps)(PlayersDetailsHeader);
