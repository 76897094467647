export default {
  invitations: {
    total: 10,
  },
  list: {
    gamesPerPage: 12,
  },
  difficulties_colors: {
    rookie: '#5C3F2C',
    amateur: '#566b6b',
    professional: '#796030',
    world_class: '#43476b',
    legendary: '#2f2a52',
    ultimate: '#2b2b2b',
  },
  block_colors: {
    prime: '#f39c12',
    winner: '#16a085',
    requirement: '#34495e',
  },
  history_result: {
    paused: '#95a5a6',
    active: '#3498db',
    winner: '#16a085',
    loss: '#c0392b',
  },
};
