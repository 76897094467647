import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import TinyBarChart from '../../../../elements/charts/tinyBarChart';

import Colors from '../../../../../data/colors';

const PlayersDetailsOverviewStatisticsDiscard = (props) => {
  const { roundsTotal, discard } = props;
  const {
    position, high, score, total,
  } = discard;

  const colors = [Colors.layout.clouds];

  const data = score.map((value, index) => ({ score: value, str: value, key: index }));
  while (data.length < roundsTotal) data.unshift({ score: 0, str: '-', key: data.length });

  const bars = {
    score: {
      name: 'Pontos',
      stackId: 'score',
    },
  };

  return (
    <div className="discard">
      <div className="title-line">
        {'Descarte'}
      </div>
      <div className="infos">
        {position && (
        <span>
          <FontAwesomeIcon icon={faSlackHash} />
          {' '}
          {position}
          {'º'}
        </span>
        )}
        <span>
          <b>
            {'PTS '}
          </b>
          {total}
        </span>
        <span>
          <FontAwesomeIcon icon={faStar} />
          {' '}
          {high}
        </span>
      </div>
      <div className="chart">
        <TinyBarChart data={data} bars={bars} colors={colors} />
      </div>
      <div className="scores">
        {data.map(scoreRound => (
          <div key={scoreRound.key} style={{ width: `${100 / data.length}%` }}>
            {scoreRound.str}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  roundsTotal: state.settings.users_bets && state.settings.users_bets.discard
    && state.settings.users_bets.discard.rounds,
});

export default connect(mapStateToProps)(PlayersDetailsOverviewStatisticsDiscard);
