import React from 'react';
import { connect } from 'react-redux';

export class AccountPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      error: '',
    };
  }

  onOldPasswordChange = (e) => {
    const oldPassword = e.target.value;
    this.setState(() => ({ oldPassword, error: '' }));
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password, error: '' }));
  };

  onConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState(() => ({ confirmPassword, error: '' }));
  };

  onSubmit = async (e) => {
    const { oldPassword, password, confirmPassword } = this.state;
    const { minPassword } = this.props;

    e.preventDefault();

    if (password !== confirmPassword) {
      this.setState(() => ({ error: 'Senhas não são iguais.' }));
    } else if (password.length < minPassword) {
      this.setState(() => ({ error: `A senha deve conter pelo menos ${minPassword} caracteres` }));
    } else {
      try {
        await this.props.chancePassword(oldPassword, password);
        this.setState(() => ({
          oldPassword: '', password: '', confirmPassword: '', error: '',
        }));
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { code } = err.response.data.error;
          if (code === 20003) {
            return this.setState(() => ({ error: 'A senha atual não corresponde com a que você digitou.' }));
          }
        }
        this.setState(() => ({ error: `Erro na API: ${err}` }));
      }
    }
  };


  hasError = () => {
    const {
      oldPassword, password, confirmPassword, error,
    } = this.state;
    if (error) return error;
    return (!oldPassword || !password || !confirmPassword);
  };

  render() {
    const {
      oldPassword, password, confirmPassword, error,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="section">
          <h4>
            {'Senha Atual'}
          </h4>
          <div className="section-content">
            <input
              type="password"
              placeholder="Digite sua senha atual"
              value={oldPassword}
              onChange={this.onOldPasswordChange}
            />
          </div>
        </div>
        <div className="section">
          <h4>
            {'Nova Senha'}
          </h4>
          <div className="section-content">
            <input
              type="password"
              placeholder="Digite sua nova senha"
              value={password}
              onChange={this.onPasswordChange}
            />
            <input
              type="password"
              placeholder="Digite novamente sua nova senha"
              value={confirmPassword}
              onChange={this.onConfirmPasswordChange}
            />
            <div className="error">
              {error}
            </div>
          </div>
        </div>
        <div>
          <button type="submit" className="button normal" disabled={this.hasError()}>
            {'Alterar Senha'}
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  minPassword: state.settings.users && state.settings.users.password && state.settings.users.password.min,
});

export default connect(mapStateToProps)(AccountPasswordForm);
