const initialState = {
  bets: {
    data: {},
    total: 0,
  },
  discard: {
    data: {},
    total: 0,
  },
  achievements: {
    data: {},
    total: 0,
  },
  top5: {},
  reputation: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RECORDS_RECORD_CLEAN':
      state[action.record] = {
        data: {},
        total: 0,
      };
      return state;
    case 'RECORDS_BETS_SET':
      state.bets.data[action.page] = action.data;
      state.bets.total = action.total;
      state.bets.interval = action.interval;
      return state;
    case 'RECORDS_DISCARD_SET':
      state.discard.data[action.page] = action.data;
      state.discard.total = action.total;
      return state;
    case 'RECORDS_ACHIEVEMENTS_SET':
      state.achievements.data[action.page] = action.data;
      state.achievements.total = action.total;
      return state;
    case 'RECORDS_TOP5_SET':
      state.top5[action.interval] = action.data;
      return state;
    case 'RECORDS_REPUTATION_RESUME_SET':
      state.reputation.resume = action.data;
      return state;
    default:
      return state;
  }
};
