import React from 'react';
import { connect } from 'react-redux';

import { scrollTo } from '../../../../../routers/appRouter';

import { teamsUpdateFormation } from '../../../../../app/store/actions/teams';

import TeamsDetailsManagementEscalationManagerHeader from './header';
import TeamsDetailsManagementEscalationFormation from './formation/formation';
import TeamsDetailsManagementEscalationPlayers from './players/players';

import ObjectHelper from '../../../../../app/helpers/object';
import TeamUtil from '../../../../../app/utils/teams';

import FormationsData from '../../../../../data/formations';

export class TeamsDetailsManagementEscalationClub extends React.Component {
  constructor(props) {
    super(props);

    const { gameMode } = props;
    const formation = FormationsData[gameMode].default;

    this.state = {
      view: 'formation',
      players: ObjectHelper.clone(props.teamPlayers),
      position: '',
      unsaved: false,
      formation,
    };
  }

  getTeamPlayers = () => {
    const { players } = this.state;
    const teamPlayers = {};

    players.forEach((player) => {
      if (player.position) teamPlayers[player.user.id] = player.position;
    });

    return teamPlayers;
  }

  getPositionAbbr = position => TeamUtil.getPositionAbbr(this.props.gameMode, position)

  getPositionName = position => TeamUtil.getPositionName(this.props.gameMode, position)

  selectPosition = (position) => {
    this.setState({ view: 'players', position });
  }

  goToFormation = () => {
    this.setState({ view: 'formation' });
  }

  selectPlayer = (userId) => {
    const { players, position } = this.state;

    const playerOut = players.find(p => p.position === position);
    if (playerOut) delete playerOut.position;

    const playerIn = players.find(p => p.user.id === userId);
    playerIn.position = position;

    this.setState({
      view: 'formation', position: '', players, unsaved: true,
    });

    scrollTo('menu-secondary');
  }

  resetPlayers = () => {
    const { players } = this.state;
    players.forEach((player) => { delete player.position; });
    this.setState({ view: 'formation', players: [...players], unsaved: true });
  }

  saveFormation = async () => {
    const { players } = this.state;

    const formation = players.map(player => ({ ...player, user: player.user.id }));
    await this.props.updateFornamtion(formation);
    this.setState({ unsaved: false });
  }

  render() {
    const {
      formation, players, view, unsaved,
    } = this.state;
    const { gamesRoundsPositions } = this.props;

    return (
      <div className="content-container with-margin escalation-formation">
        <TeamsDetailsManagementEscalationManagerHeader
          view={view}
          unsaved={unsaved}
          onReset={this.resetPlayers}
          onSave={this.saveFormation}
          goToBack={this.goToFormation}
          fixedTatic={TeamUtil.getFormation(this.props.gameMode)}
        />
        {view === 'formation' && (
        <TeamsDetailsManagementEscalationFormation
          players={players}
          formation={formation}
          gamesRoundsPositions={gamesRoundsPositions}
          selectPosition={this.selectPosition}
          getPositionName={this.getPositionAbbr}
        />
        )}
        {view === 'players' && (
        <TeamsDetailsManagementEscalationPlayers
          teamPlayers={this.getTeamPlayers()}
          players={players.map(p => p.user)}
          selectPlayer={this.selectPlayer}
          getPositionName={this.getPositionName}
        />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateFornamtion: players => dispatch(teamsUpdateFormation(players)),
});

const mapStateToProps = (state) => {
  const { gameMode } = state.teams.details;
  const { isProgress, current } = state.rounds;
  const gamesPositions = state.settings.game_modes[gameMode].positions;

  let gamesRoundsPositions;

  if (!isProgress) {
    gamesRoundsPositions = TeamUtil.getTeamGamesAbbrPosition(gamesPositions, current);
  }

  return {
    gameMode,
    gamesRoundsPositions,
    teamPlayers: (state.teams.details.overview && state.teams.details.overview.players) || [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsManagementEscalationClub);
