import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import moment from 'moment-timezone';

import { realTeamsGet } from '../../../../app/store/actions/realTeams';
import { roundsCompetitionsGet } from '../../../../app/store/actions/rounds';

import AdministrationRoundsGame from './administrationRoundsGame';

export class AdministrationRoundsGames extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      games: props.games,
    };
  }

  async componentDidMount() {
    await this.props.getRealTeams();
    await this.props.getCompetitions();
  }

  setData = (index, date) => {
    const { games } = this.state;
    games[index] = {
      ...games[index],
      ...date,
    };

    this.setState({ games });
  };

  canSave = () => {
    const { games } = this.state;
    return !games.some(game => !moment(game.date, 'DD/MM/YYYY HH:mm', true).isValid());
  };

  saveGames = () => {
    const { games } = this.state;
    this.props.saveGames(games);
  };

  render() {
    const { games = [] } = this.state;
    const { realTeams, competitions, buttonLabel } = this.props;

    return (
      <React.Fragment>
        <Grid fluid style={{ maxWidth: '80rem', margin: 'auto' }}>
          <Row>
            {games.map(game => (
              <Col key={game.index} md={6}>
                <AdministrationRoundsGame
                  game={game}
                  realTeams={realTeams}
                  competitions={competitions}
                  setData={this.setData}
                />
              </Col>
            ))}
          </Row>
        </Grid>
        {this.canSave() && (
        <button
          type="button"
          className="button normal light-blue button-bet"
          onClick={this.saveGames}
        >
          {buttonLabel}
        </button>
        )}

      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getRealTeams: () => dispatch(realTeamsGet()),
  getCompetitions: () => dispatch(roundsCompetitionsGet()),
});

const mapStateToProps = state => ({
  realTeams: state.realTeams.teams || [],
  competitions: state.rounds.competitions || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationRoundsGames);
