import React from 'react';

import TutorialWrapper from '../elements/tutorial/tutorial';
import { scrollTo } from '../../routers/appRouter';

export class Terms extends React.Component {
  async componentDidMount() {
    scrollTo('header-bottom');
  }

  render() {
    return (
      <div className="content-container min-container">
        <h3 className="title-center">
          {'Termos de Uso'}
        </h3>
        <TutorialWrapper>
          <div className="section">
            <p>
              {'Ao usar o website Barbolão ("Serviço"), você concorda em ficar vinculado pelos '
          + 'seguintes termos e condições ("Termos de Uso") estabelecidos pelo site.'}
            </p>
          </div>
          <div className="section">
            <h4>
              {'Usuário'}
            </h4>
            <div className="section-content">
              <p>
                {'I. Para realizar o cadastro é necessário informar o seu cpf e telefone. Essa '
            + 'informação não será divulgada. É exigido isso para evitar usuários falsos que '
            + 'atrapalham o que o site oferece.'}
              </p>
              <p>
                {'II. No cadastro será exigido os seguintes dados do usuário: nome, e-mail, sexo, '
            + 'data de nascimento e cidade.'}
              </p>
              <p>
                {'III. Você é responsável por manter seu login e senha seguros.'}
              </p>
              <p>
                {'IV. Caso o usuário não tenha 18 (dezoito) anos completos, ele poderá participar '
            + 'do site com permissão de um responsável. O responsável deverá enviar um contato '
            + 'justificando a situação.'}
              </p>
              <p>
                {'V. Os usuários que infringirem as regras poderão ser imediatamente suspensos, bem '
            + 'como poderão ter seus logins bloqueados, sem qualquer aviso prévio e a exclusivo '
            + 'critério do site.'}
              </p>
            </div>
          </div>
          <div className="section">
            <h4>
              {'Termos Gerais'}
            </h4>
            <div className="section-content">
              <p>
                {'I. Reservamo-nos o direito de modificar ou encerrar o Serviço por qualquer motivo, '
            + 'sem aviso prévio a qualquer momento.'}
              </p>
              <p>
                {'II. Reservamo-nos o direito de alterar estes Termos de Utilização a qualquer momento. '
            + 'Se houver alguma alteração desses Termos de Uso, os usuários serão notificados.'}
              </p>
            </div>
          </div>
        </TutorialWrapper>
      </div>
    );
  }
}

export default Terms;
