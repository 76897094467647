import RestService from '../services/rest';
import PlayersUtils from '../utils/players';

async function getWallMessages(modeGame, date, newer) {
  let url = `messages/wall${(modeGame) ? `/${modeGame}` : ''}?`;
  if (date) url += `date=${date}`;
  if (newer) url += `&newer=${newer}`;
  const wall = await RestService.getAuthenticated(url);
  wall.forEach((post) => { post.from = PlayersUtils.completePlayer(post.from); });
  return wall;
}

async function sendMessage(modeGame, message) {
  const data = {
    message,
  };
  if (modeGame) data.mode_type = modeGame;
  return RestService.postAuthenticated('messages/wall', data);
}

async function removeMessage(modeGame, messageId) {
  const data = {
    message: messageId,
  };
  if (modeGame) data.mode_type = modeGame;
  return RestService.postAuthenticated('messages/wall/remove', data);
}

async function getInboxRooms(skip, limit) {
  const url = `messages/rooms/list?skip=${skip}&limit=${limit}`;
  const data = await RestService.getAuthenticated(url);

  data.data.forEach((room) => {
    room.users = room.users.map(player => PlayersUtils.completePlayer(player));
  });

  return data;
}

async function getInboxRoom(roomId) {
  const url = `messages/rooms/details?room=${roomId}`;
  const data = await RestService.getAuthenticated(url);

  data.room.users = data.room.users.map(player => PlayersUtils.completePlayer(player));

  return data;
}

async function getInboxMessages(roomId, date) {
  let url = `messages/inbox?room=${roomId}`;
  if (date) url += `&date=${date}`;
  return RestService.getAuthenticated(url);
}

async function sendInboxMessage(roomId, message) {
  return RestService.postAuthenticated('messages/inbox', {
    room: roomId,
    message,
  });
}

async function createInboxUserRoom(userId, message) {
  return RestService.postAuthenticated('messages/rooms/user/create', {
    user: userId,
    message,
  });
}

async function createInboxTeamRoom(gameMode, message) {
  return RestService.postAuthenticated('messages/rooms/team/create', {
    mode_type: gameMode,
    message,
  });
}

async function searchInboxSearchRooms(searchText) {
  const players = await RestService.getAuthenticated(`messages/rooms/search?search=${searchText}`);
  return players.map(player => PlayersUtils.completePlayer(player));
}

export default {
  getWallMessages,
  sendMessage,
  removeMessage,
  getInboxRooms,
  getInboxRoom,
  getInboxMessages,
  sendInboxMessage,
  createInboxUserRoom,
  createInboxTeamRoom,
  searchInboxSearchRooms,
};
