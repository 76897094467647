import React from 'react';

import Steps from '../../elements/steps/steps';

import TutorialGeneralBets from './bets';
import TutorialGeneralStatistics from './statistics';
import TutorialGeneralGameModes from './gameModes';

const TutorialGeneral = () => (
  <Steps>
    <TutorialGeneralBets />
    <TutorialGeneralStatistics />
    <TutorialGeneralGameModes />
  </Steps>
);

export default TutorialGeneral;
