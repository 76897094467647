import React from 'react';
import { connect } from 'react-redux';

import ValidatorHelper from '../../../app/helpers/validator';

import TutorialWrapper from '../../elements/tutorial/tutorial';

import { forgotPassword } from '../../../app/store/actions/user';

export class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: '',
    };
  }

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email, error: '' }));
  };

  hasError = () => {
    const { email, error } = this.state;
    return error !== '' || !ValidatorHelper.isEmail(email);
  };

  onSubmit = async (e) => {
    const { email } = this.state;

    e.preventDefault();

    if (!this.hasError()) {
      const err = await this.props.forgotPassword(email);
      if (!err) this.props.nextStep();
    }
  };

  render() {
    const { error, email } = this.state;

    return (
      <div className="contact">
        <TutorialWrapper image="forget.jpg">
          <form onSubmit={this.onSubmit}>
            <h3 className="title-center">
              {'Recuperar Senha'}
            </h3>
            <div className="section">
              <p>
                {'Informe o seu e-mail cadastrado no site e clique em Enviar. Você irá '
                  + 'receber um e-mail com as instruções de como redefinir sua senha.'}
              </p>
              <h4>
                {'E-mail Cadastrado'}
              </h4>
              <div className="section-content">
                <input
                  type="email"
                  placeholder="Digite seu email"
                  value={email}
                  className="all"
                  onChange={this.onEmailChange}
                />
                <div className="error">
                  {error}
                </div>
              </div>
            </div>
            <button className="button normal" type="submit" disabled={this.hasError()}>
              {'Recuperar'}
            </button>
          </form>
        </TutorialWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(forgotPassword(email)),
});

export default connect(undefined, mapDispatchToProps)(ForgotPasswordForm);
