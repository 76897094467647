import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

const TeamsDetailsOverviewBadgesEmpty = (props) => {
  const { text } = props;

  return (
    <div className="empty">
      <FontAwesomeIcon icon={faTrophy} />
      <div>{text}</div>
    </div>
  );
};

export default TeamsDetailsOverviewBadgesEmpty;
