import React from 'react';

import TeamsGamesHeader from '../../games/elements/header/header';

import TeamsUtil from '../../../../app/utils/teams';

const TeamsStatisticsConfrontationHeader = (props) => {
  const { teamHome = {}, teamAway = {} } = props;

  const getBottomText = (teamData) => {
    let text = '';

    if (teamData && teamData.statistics && teamData.statistics.goals_for !== undefined) {
      const goals = teamData.statistics.goals_for;
      text = `${goals} Gol${goals !== 1 ? 's' : ''}`;
    }

    return text;
  };

  return (
    <TeamsGamesHeader
      teamHome={teamHome.team || TeamsUtil.completeTeam()}
      teamAway={teamAway.team || TeamsUtil.completeTeam()}
      scoreHome={teamHome.score}
      scoreAway={teamAway.score}
      teamHomeBottom={getBottomText(teamHome)}
      teamAwayBottom={getBottomText(teamAway)}
    />
  );
};

export default TeamsStatisticsConfrontationHeader;
