import React from 'react';

import Steps from '../elements/steps/steps';

import CartItem from './item';
import CartSuccess from './success';

const CartPage = () => (
  <div className="content-container">
    <Steps>
      <CartItem />
      <CartSuccess />
    </Steps>
  </div>
);

export default CartPage;
