import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { competitionsGetLists, competitionsGetClosedList } from '../../../../app/store/actions/competitions';

import pageTitleService from '../../../../app/services/pageTitle';

import CompetitionsPremiumsBanner from '../../../elements/competitions/premiumsBanner';

import TeamsCompetitionsListStarted from './teamsCompetitionsListStarted';
import TeamsCompetitionsListClosed from './teamsCompetitionsListClosed';

import MenuSecondary from '../../../elements/menuSecondary';

import PreferencesrData from '../../../../data/preferences';

export class TeamsCompetitionsList extends React.Component {
  componentDidMount() {
    pageTitleService.setTitle('Competições');
    this.props.getCompetitions(moment().year());
  }

  handleChangeYear = async (e) => {
    const year = e.target.value;
    this.props.getClosedCompetitions(year);
  }

  render() {
    const {
      year, started, closed, menus, modeData,
    } = this.props;

    const yearList = [];
    for (let i = moment().year(); i >= PreferencesrData.yearStart; i -= 1) yearList.push(i);

    return (
      <div>
        <MenuSecondary menus={menus} tag="Campeonatos" />

        <CompetitionsPremiumsBanner gameMode={modeData.mode} />

        <div className="content-container">

          <TeamsCompetitionsListStarted competitions={started} />

          <h3 className="title-center-menu">
            {'Encerrados'}
          </h3>
          <div className="filter-select types center">
            <select value={year} onChange={this.handleChangeYear}>
              {yearList.map(y => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </select>
          </div>
          {closed && <TeamsCompetitionsListClosed competitions={closed} />}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCompetitions: year => dispatch(competitionsGetLists(ownProps.modeData.mode, ownProps.type, year)),
  getClosedCompetitions: year => dispatch(competitionsGetClosedList(ownProps.modeData.mode, ownProps.type, year)),
});

const mapStateToProps = state => ({
  started: state.competitions.list.started,
  closed: state.competitions.list.closed[state.competitions.list.closedYear],
  year: state.competitions.list.closedYear,
  types: state.settings.competitions && state.settings.competitions.types,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCompetitionsList);
