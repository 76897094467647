import React from 'react';

import Steps from '../../../elements/steps/steps';

import TeamsInitiationCreationName from './teamsInitiationCreationName';
import TeamsInitiationCreationBadge from './teamsInitiationCreationBadge';
import TeamsInitiationCreationComplete from './teamsInitiationCreationComplete';

const TeamsInitiationCreation = (props) => {
  const { modeData } = props;
  const { mode } = modeData;

  return (
    <div className="content-container">
      <Steps>
        <TeamsInitiationCreationName mode={mode} />
        <TeamsInitiationCreationBadge mode={mode} />
        <TeamsInitiationCreationComplete mode={mode} />
      </Steps>
    </div>
  );
};

export default TeamsInitiationCreation;
