import StringHelper from '../helpers/string';
import ReputationData from '../../data/reputation';
import colorsData from '../../data/colors';

function getReputation(level = 0) {
  if (!level) {
    return {
      name: 'Sem reputação',
      color: colorsData.layout.black,
    };
  }

  const index = Math.min(
    Math.floor((level - 1) / ReputationData.subReputation.total),
    ReputationData.levels.length - 1,
  );

  const reputationLevel = ReputationData.levels[index];

  let subReputation;
  let pictureName;

  if (reputationLevel.last) {
    subReputation = level - (index * ReputationData.subReputation.total);
    pictureName = reputationLevel.tag;
  } else {
    subReputation = ((level - 1) % ReputationData.subReputation.total) + 1;
    pictureName = `${reputationLevel.tag}_${Math.floor((subReputation + 1) / 2)}`;
  }

  return {
    name: `${reputationLevel.name} ${StringHelper.romanize(subReputation)}`,
    picture: `/images/reputation/${pictureName}.png`,
    baseName: reputationLevel.name,
    color: reputationLevel.color,
  };
}

function getScoreToNextLevel(value) {
  const { interval } = ReputationData;
  return Math.ceil((value * interval) + (1 - interval));
}

function getScore(bet) {
  const { accurate: accurateScore } = ReputationData;

  let current = 0;
  let hits = 0;
  let miss = 0;
  let accurate = 0;

  if (bet) {
    bet.games.forEach((game) => {
      if (game.score === 0) miss += 1;
      if (game.score === 1) hits += 1;
      if (game.score >= 2) accurate += 1;
    });
    current = (hits - miss) + (accurate * accurateScore);
  }

  return {
    miss,
    hits,
    accurate,
    current,
  };
}

function getColor(value) {
  return colorsData.layout[ReputationData.colors[value]];
}

export default {
  getReputation,
  getScore,
  getScoreToNextLevel,
  getColor,
};
