function getGoalkeeperBonus(games) {
  return games.filter(game => game.score_home + game.score_away === 0).map(game => game.number);
}

function getDefenderBonus(games) {
  return games.filter(game => (game.score_home >= 3 && game.score_away === 0)
  || (game.score_away >= 3 && game.score_home === 0)).map(game => game.number);
}

function getMidfielderBonus(games, bets) {
  return games.filter(game => bets && bets.games[game.number].score >= 2).map(game => game.number);
}

function getForwardBonus(games) {
  return games.filter(game => game.score_home + game.score_away > 4).map(game => game.number);
}

function getCoachBonus(games, managerCoachDifficulty) {
  return games.filter(game => game.difficulty >= managerCoachDifficulty).map(game => game.number);
}

function getBonus(position, games = [], opitions = {}) {
  switch (position) {
    case 'goalkeeper': return getGoalkeeperBonus(games);
    case 'defender': return getDefenderBonus(games);
    case 'midfielder': return getMidfielderBonus(games, opitions.bets);
    case 'forward': return getForwardBonus(games);
    case 'coach': return getCoachBonus(games, opitions.managerCoachDifficulty);
    default: return [];
  }
}

export default {
  getBonus,
};
