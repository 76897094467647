import RestService from '../services/rest';
import PlayersUtils from '../utils/players';
import StatisticsUtil from '../utils/statistics';

async function getTop5(year) {
  let url = 'users/statistics/top';
  if (year) url += `?year=${year}`;
  const data = await RestService.getAuthenticated(url);

  const top5s = StatisticsUtil.getAllTop(data);
  top5s.forEach((players) => {
    players.forEach((player) => {
      player.user = PlayersUtils.completePlayer(player.user);
    });
  });

  return data;
}

export default {
  getTop5,
};
