import React from 'react';

import Paginate from '../../../../../elements/paginate';

import TeamsData from '../../../../../../data/teams';

import TeamsDetailsManagementFriendlySearchTable from './table';

import TeamsUtils from '../../../../../../app/utils/teams';

export class TeamsDetailsManagementFriendlySearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      page: 1,
    };
  }

  onSearchChange = (e) => {
    const search = e.target.value.toLowerCase();
    this.setState(() => ({ search, page: 1 }));
  };

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  render() {
    const { search, page } = this.state;
    const {
      teams, receivedProposals, sentProposals, gameMode,
    } = this.props;

    const totalPage = TeamsData.friendly.total;
    let totalPages = 0;

    let selectTeams;

    if (teams) {
      selectTeams = TeamsUtils.searchTeams(teams
        .filter(team => !receivedProposals.find(t => t.id === team.id)), search);

      selectTeams.forEach((team) => {
        team.mode_type = gameMode;
        team.sent = !!(sentProposals.find(t => t.id === team.id));
      });
      totalPages = Math.ceil(selectTeams.length / totalPage);
    }


    return (
      <div className="friendly-search">
        <div className="title-line">
          {'Enviar Proposta'}
        </div>

        <div className="search-header">
          <input
            type="text"
            placeholder="Busque pelo nome"
            className="text-input filter-input"
            value={search}
            onChange={this.onSearchChange}
          />

          <div className="pagination-header">
            <Paginate
              pageCount={totalPages}
              forcePage={page - 1}
              onPageChange={this.handlePageClick}
            />
          </div>
        </div>

        {selectTeams && (
        <TeamsDetailsManagementFriendlySearchTable
          teams={selectTeams.slice(((page - 1) * totalPage), page * totalPage)}
          onCancel={this.props.cancel}
          onSend={this.props.send}
        />
        )}
      </div>
    );
  }
}

export default TeamsDetailsManagementFriendlySearch;
