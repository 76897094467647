import React from 'react';

const TeamsGamesDetailsHeaderScore = ({ scoreHome, scoreAway }) => (
  <div className="team-vs-team">
    <div className={`value${scoreHome + scoreAway >= 20 ? ' low' : ''}`}>
      {scoreHome}
      <span>
      x
      </span>
      {scoreAway}
    </div>
  </div>
);

export default TeamsGamesDetailsHeaderScore;
