import React from 'react';

import CardTable from '../../../elements/card/table';
import PlayerUtils from '../../../../app/utils/players';

import PlayerIcons from '../icons';
import PlayerTeams from '../teams/teams';

const PlayerTable = (props) => {
  const {
    player, noIcons, reputation, isPrime, className = '',
  } = props;

  return (
    <CardTable
      className={className}
      name={player.username}
      tag={(
        <React.Fragment>
          <div className="label">
            {reputation}
          </div>
          <PlayerTeams teams={player.teams} />
          {!noIcons && <PlayerIcons player={player} type="table" />}
        </React.Fragment>
      )}
      badge={{
        url: player.picture.url,
        title: player.username,
      }}
      tagStyle={isPrime ? 'prime' : ''}
      level={player.achievements.level}
      levelStyle={PlayerUtils.betValue(player)}
      round
    />
  );
};

export default PlayerTable;
