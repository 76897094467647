import React from 'react';

import GuidePenaltiesAdditional from '../penaltiesAdditional';

export const GuideManagerPenalties = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Serão 5 cobranças obrigatórias e caso o empate persista teremos cobranças alternadas até '
          + 'completar todos os jogadores de linha (zagueiros, meio-campistas e atacantes). Caso o '
          + 'empate continue, teremos um '}
          <b>pênalti adicional</b>
          {'.'}
        </p>
        <p>
          {'Caso o jogador tenha pontuado na rodada mais que o goleiro do outro clube, será '}
          <b>gol</b>
          {' (A pontuação será composta pelos 10 jogos).'}
        </p>
        <p>
          {'A ordem das cobranças será do melhor apostador do clube ao pior.'}
        </p>
      </div>
    </div>
    <GuidePenaltiesAdditional />
  </React.Fragment>
);
export default GuideManagerPenalties;
