import React from 'react';
import { connect } from 'react-redux';

import RecordsRoundsContentBets from '../records/rounds/bets/bets';

const WallBets = ({ roundNumber }) => (
  <div className="rounds-statistics-page">
    <div className="content-container">
      <h3 className="title-center">
        {'Melhores Apostadores da Rodada '}
        { roundNumber }
      </h3>
      {roundNumber && <RecordsRoundsContentBets roundNumber={roundNumber} />}
    </div>
  </div>
);

const mapStateToProps = state => ({
  roundNumber: state.rounds.numberClosed,
});

export default connect(mapStateToProps)(WallBets);
