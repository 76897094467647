import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../elements/top5/top5Section';

const RecordsTop5Valuation = (props) => {
  const { value, high, variation } = props.data;

  return (
    <Top5Section title="Valor de Mercado">
      <Grid fluid>
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(value, 'Valor de Mercado', 'Valor')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(high, 'Maior Valor de Mercado', 'Valor')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(variation, 'Última Variação', 'Valor')}
          </Col>
        </Row>
      </Grid>
    </Top5Section>
  );
};

export default RecordsTop5Valuation;
