import MarketRequests from '../../server/market';
import FederationsRequests from '../../server/federation';

import { userGetAuth } from './user';
import { teamsGetTeam } from './teams';
import { addLoading, removeLoading } from './loading';

export const marketGetTeamMarket = () => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());

    try {
      const data = await MarketRequests.getTeamMarket(team.id);
      return data;
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const marketTeamSendProposal = userId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await MarketRequests.teamSendProposal(team.id, userId);
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const marketTeamCancelProposal = userId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await MarketRequests.teamCancelProposal(team.id, userId);
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const marketTeamHirePlayer = userId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await MarketRequests.teamHirePlayer(team.id, userId);
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const marketTeamDismissPlayer = userId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await MarketRequests.teamDismissPlayer(team.id, userId);
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const marketFederationDismissPlayer = userId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await FederationsRequests.federationDismissPlayer(team.id, userId);
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const marketPlayerRejectProposal = teamId => async (dispatch) => {
  dispatch(addLoading());
  try {
    await MarketRequests.playerRejectProposal(teamId);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const marketPlayerAcceptProposal = teamId => async (dispatch) => {
  dispatch(addLoading());
  try {
    await MarketRequests.playerAcceptProposal(teamId);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const marketPlayerBeAvailable = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    await MarketRequests.playerBeAvailable(gameMode);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const marketPlayerNotAvailable = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    await MarketRequests.playerNotAvailable(gameMode);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const marketPlayerSendResign = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    await MarketRequests.playerSendResign(gameMode);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};


export const marketPlayerCancelResign = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    await MarketRequests.playerCancelResign(gameMode);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};


export const marketPlayerResign = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    await MarketRequests.playerResign(gameMode);
    await dispatch(userGetAuth());
  } finally {
    dispatch(removeLoading());
  }
};
