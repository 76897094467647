import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsDetailsManagementEscalationFormationField from './field';
import TeamsDetailsManagementEscalationFormationTable from './table';

export const TeamsDetailsManagementEscalationFormation = (props) => {
  const { formation, players, gamesRoundsPositions } = props;

  return (
    <Grid fluid style={{ padding: 0 }}>
      <Row>
        <Col md={6} lg={5}>
          <TeamsDetailsManagementEscalationFormationField
            formation={formation}
            players={players}
            selectPosition={props.selectPosition}
          />
        </Col>
        <Col md={6} lg={7}>
          <TeamsDetailsManagementEscalationFormationTable
            formation={formation}
            players={players}
            gamesRoundsPositions={gamesRoundsPositions}
            getPositionName={props.getPositionName}
            selectPosition={props.selectPosition}
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default TeamsDetailsManagementEscalationFormation;
