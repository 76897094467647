import RestService from '../services/rest';
import PlayersUtils from '../utils/players';

async function getList() {
  const data = await RestService.getAuthenticated('admin/users/analyze');
  data.forEach((d) => { d.users = d.users.map(user => PlayersUtils.completePlayer(user)); });
  return data;
}

async function getIpDetails(ip) {
  const data = await RestService.getAuthenticated(`admin/users/analyze/${ip}`);
  data.users = data.users.map(user => PlayersUtils.completePlayer(user));
  return data;
}

export default {
  getList,
  getIpDetails,
};
