import React from 'react';
import { connect } from 'react-redux';

import colorsData from '../../../data/colors';
import betsData from '../../../data/bets';

import TutorialWrapper from '../../elements/tutorial/tutorial';

const TutorialGeneralBets = (props) => {
  const { hasRound } = props;

  const scores = [{
    score: '0 Pontos',
    text: 'Não acertar o placar nem o vencedor do jogo.',
    color: colorsData.layout[betsData.colors['0']],
  }, {
    score: '1 Ponto',
    text: 'Acertar o vencedor ou o empate, mas errar o placa.',
    color: colorsData.layout[betsData.colors['1']],
  }, {
    score: '2 Pontos',
    text: 'Acertar o placar exato do jogo.',
    color: colorsData.layout[betsData.colors['2']],
  }, {
    score: '3 Pontos',
    text: 'Acertar o placar exato do jogo, desde que sejam mais de 4 gols.',
    color: colorsData.layout[betsData.colors['3']],
  }];

  return (
    <TutorialWrapper image="general/score.jpg">
      <h3 className="title-center">
        {'Pontuação'}
      </h3>
      <p>
        {hasRound
          ? 'Agora que as apostas já foram salvas, vamos entender como funciona a pontuação das apostas.'
          : 'No momento não há rodadas disponíveis para aposta. Enquanto isso vamos entender como funciona a pontuação.'
        }
      </p>
      <p>
        {'Em cada um dos jogos apostados em cada rodada, o jogador poderá atingir as seguintes pontuações:'}
      </p>
      <div className="scores">
        {scores.map(score => (
          <div key={score.score} className="score">
            <div className="color" style={{ backgroundColor: score.color }} />
            <div className="texts">
              <h5>
                {score.score}
              </h5>
              <p>
                {score.text}
              </p>
            </div>
          </div>
        ))}
      </div>
      <p className="text">
        {'A pontuação final será a soma da pontuação dos 10 jogos.'}
      </p>
      <button className="button normal" type="button" onClick={props.nextStep}>
        {'Próximo'}
      </button>
    </TutorialWrapper>
  );
};

const mapStateToProps = state => ({
  hasRound: state.roundsBets.availables.length > 0,
});

export default connect(mapStateToProps)(TutorialGeneralBets);
