import moment from 'moment-timezone';

import PlayersRequests from '../../server/players';
import RoundsUtils from '../../utils/rounds';
import RoundsBetsUtils from '../../utils/roundsBets';
import { addLoading, removeLoading } from './loading';
import { saveUserBet } from './roundsBets';
import { setRound } from './rounds';

export const setDetails = player => ({
  type: 'PLAYERS_DETAILS_SET',
  player,
});

export const playersGetPlayer = username => async (dispatch, getState) => {
  const player = await PlayersRequests.getPlayer(username);

  let playerDetails = getState().players.details;
  if (playerDetails.id === player.id) {
    playerDetails.overview = player;
    playerDetails.updatedAt = moment();
  } else {
    const userAuthId = getState().user.id;
    playerDetails = {
      id: player.id,
      overview: player,
      isAuthUser: userAuthId && userAuthId === player.id,
      updatedAt: moment(),
    };
  }

  if (player.round) {
    const { bets, round } = player.round;
    if (round) {
      dispatch(setRound(round.number, RoundsUtils.completeRound(round)));
    }
    if (bets) {
      dispatch(saveUserBet(player.id, bets.round, RoundsBetsUtils.completeRoundBet(bets)));
    }
    delete player.round;
  }

  dispatch(setDetails(playerDetails));
};

export const playersStartPlayer = username => async (dispatch, getState) => {
  const player = getState().players.details;

  dispatch(addLoading());
  if (!player || !(player.overview && player.overview.username === username)) dispatch(setDetails());

  try {
    await dispatch(playersGetPlayer(username));
  } finally {
    dispatch(removeLoading());
  }
};

export const playersGetAchievements = () => async (dispatch, getState) => {
  const player = getState().players.details;
  if (player) {
    dispatch(addLoading());
    try {
      player.achievements = await PlayersRequests.getPlayerAchievements(player.id);
      dispatch(setDetails(player));
    } finally {
      dispatch(removeLoading());
    }
  }
};
