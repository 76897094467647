import React from 'react';

export const GuidePlayerStatistics = () => (
  <React.Fragment>
    <div className="section">
      <h4>
        {'Descarte'}
      </h4>
      <div className="section-content">
        <p>
          {'Esse ranking objetiva demonstrar o comportamento do jogador nas últimas 10 rodadas realizadas, '
          + 'descartando-se toda a pontuação anterior.'}
        </p>
        <p>
          {'Ele é usado como critério de desempate em várias competições.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Apostas'}
      </h4>
      <div className="section-content">
        <p>
          {'Esse ranking soma a pontuação das apostas de todas as rodadas de todos jogadores.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Top 5'}
      </h4>
      <div className="section-content">
        <p>
          {'São diversos objetivos que o jogador pode conquistar. Para cada objetivo é mostrado os 5 melhores.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuidePlayerStatistics;
