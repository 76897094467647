import React from 'react';

import Table from '../../elements/table';
import Player from '../../players/elements/player';

export class AdministrationUsersAnalyzeIpBets extends React.Component {
  constructor(props) {
    super(props);

    const { rounds = [] } = props;

    this.state = {
      currentRound: rounds[0] ? rounds[0].number : 0,
    };
  }

  async componentWillReceiveProps(nextProps) {
    const { currentRound } = this.state;
    const { rounds } = nextProps;
    if (!currentRound) {
      await this.setState({ currentRound: Number(rounds[0] ? rounds[0].number : 0) });
    }
  }

  handleChangeRound = async (e) => {
    const { value } = e.target;
    await this.setState({ currentRound: Number(value) });
  }

  getBet = (userId) => {
    const { currentRound } = this.state;
    const { rounds = [] } = this.props;

    const round = rounds.find(r => r.number === currentRound);
    if (!round) return;

    const bet = round.bets[userId];
    if (!bet) return;

    const value = bet.map(game => `${game.teamHome} ${game.scoreHome} x ${game.scoreAway} ${game.teamAway}`);
    return [value.splice(0, 5).join(' | '), value.splice(0, 5).join(' | ')];
  }

  render() {
    const { currentRound } = this.state;
    const { users = [], rounds = [] } = this.props;

    const roundsNumber = rounds.map(round => round.number);

    const tableHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      {
        key: 'user',
        value: (
          <div className="filter-select interval">
            <select value={currentRound} onChange={this.handleChangeRound}>
              {roundsNumber.map(roundNumber => (
                <option key={roundNumber} value={roundNumber}>
                  {`Rodada ${roundNumber}`}
                </option>
              ))}
            </select>
          </div>
        ),
        colspan: 2,
      },
      { key: 'bets', value: 'Apsotas', className: 'number' },
    ];

    const tableData = users.map((user, index) => {
      const betsValues = this.getBet(user.id);

      return {
        key: user.id,
        position: index + 1,
        user: <Player key={user.id} data={user} type="table" />,
        bets: !betsValues ? '-' : (
          <React.Fragment>
            {betsValues[0]}
            <br />
            {betsValues[1]}
          </React.Fragment>
        ),
      };
    });

    return (
      <div className="margin-bottom">
        <Table header={tableHeader} lines={tableData} />
      </div>
    );
  }
}

export default AdministrationUsersAnalyzeIpBets;
