import TeamsStatisticsRequests from '../../server/teamsStatistics';
import { addLoading, removeLoading } from './loading';

import StatisticsData from '../../../data/statistics';

export default (gameMode, page, teamId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const limit = StatisticsData.rankings.perPage;
    const skip = ((page - 1) * limit);

    const strikers = await TeamsStatisticsRequests.getStrikers(gameMode, 'all', skip, limit, teamId);
    return strikers;
  } finally {
    dispatch(removeLoading());
  }
};
