import React from 'react';
import { connect } from 'react-redux';

import {
  removeCart, addCredits, buySubscription, buyChests, buyPremium,
} from '../../app/store/actions/cart';

import TutorialWrapper from '../elements/tutorial/tutorial';

export class CartItem extends React.Component {
  constructor(props) {
    super(props);

    let optionSelected;

    if (props.cart.type === 'chest') optionSelected = props.cart.item.packs[0].total;

    this.state = {
      useCredit: false,
      optionSelected,
      selectAmount: 10,
    };
  }

  buy = async () => {
    const { optionSelected, selectAmount, useCredit } = this.state;
    const { cart } = this.props;

    let result;

    switch (cart.type) {
      case 'subscription': {
        result = await this.props.buySubscription(cart.item.id, useCredit);
        break;
      }
      case 'chest': {
        result = await this.props.buyChests(cart.item.chest.id, optionSelected, useCredit);
        break;
      }
      case 'premium': {
        result = await this.props.buyPremium(cart.item.id, cart.item.teamId, useCredit);
        break;
      }
      default:
        result = await this.props.addCredits(Math.min(500, Math.max(10, Math.round(selectAmount))));
        break;
    }

    if (result) {
      const isPending = result.uri;
      if (isPending) {
        window.location = result.uri;
      } else {
        this.props.nextStep();
      }
    }
  }

  selectOption = (e) => {
    const option = e.target.value;
    this.setState({ optionSelected: Number(option) });
  }

  useCreditChange = (e) => {
    const { checked } = e.target;
    this.setState({ useCredit: checked });
  }

  setAmount = (e) => {
    const amount = e.target.value;
    const regex = /^[a-zA-Z0-9._-]+$/g;
    if (amount === '' || regex.test(amount)) {
      this.setState(() => ({ selectAmount: Number(amount) }));
    }
  }

  render() {
    const { optionSelected, selectAmount, useCredit } = this.state;
    const { cart, userCurrency } = this.props;

    let creditOption = true;
    let amountInput = false;
    let name;
    let amount;
    let image;
    let options = [];

    switch (cart.type) {
      case 'subscription': {
        name = `Prime ${cart.item.name}`;
        ({ amount } = cart.item);
        image = 'images/cart/subscription.png';
        break;
      }
      case 'chest': {
        ({ name } = cart.item.chest);
        const packSelected = cart.item.packs.find(pack => pack.total === optionSelected);
        if (packSelected) amount = (packSelected.total - packSelected.free) * cart.item.chest.amount;
        image = cart.item.chest.image.url;
        options = cart.item.packs.map(pack => ({
          name: `${pack.total} Baús`,
          value: pack.total,
        }));
        break;
      }
      case 'premium': {
        ({ name } = cart.item);
        amount = cart.item.price;
        if (cart.item.userIsPrime) amount *= (1 - cart.item.discount);
        image = 'images/competitions/premium.png';
        break;
      }
      default:
        name = 'Créditos';
        amount = Math.min(500, Math.max(10, Math.round(selectAmount)));
        image = 'images/cart/credit.png';
        creditOption = false;
        amountInput = true;
    }

    return (
      <TutorialWrapper image="cart.jpg">
        <h3 className="title-center">
          {'Carrinho'}
        </h3>

        <div className="section cart">
          <div className="logo-item">
            <img src={image} alt={image} />
          </div>
          <h4>
            {name}
          </h4>
          {options.length > 0 && (
          <div className="options">
            <div className="filter-select types center">
              <select value={optionSelected} onChange={this.selectOption}>
                {options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          )}
          {amountInput && (
          <div className="amount-input">
            <input
              type="number"
              placeholder="Digite seu nome completo"
              className="text-input filter-input"
              value={selectAmount}
              onChange={this.setAmount}
            />
          </div>
          )}
          <div className="value">
            {'R$'}
            {amount}
          </div>
          {creditOption && userCurrency >= amount && (
          <div className="use-credits">
            <input
              id="useCreditCheck"
              className="styled-checkbox"
              type="checkbox"
              checked={useCredit}
              onChange={this.useCreditChange}
            />
            <label htmlFor="useCreditCheck">
              {'Usar meus créditos'}
            </label>
          </div>
          )}
        </div>

        {!amountInput && (
        <button className="button normal" type="button" onClick={this.props.cleanCart}>
          {'Adicionar Créditos'}
        </button>
        )}


        <button className="button normal light-blue" type="button" onClick={this.buy} disabled={!amount || amount <= 0}>
          {'Comprar'}
        </button>
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  cleanCart: () => dispatch(removeCart()),
  addCredits: amount => dispatch(addCredits(amount)),
  buySubscription: (subscriptionId, useCredit) => dispatch(buySubscription(subscriptionId, useCredit)),
  buyChests: (chestId, total, useCredit) => dispatch(buyChests(chestId, total, useCredit)),
  buyPremium: (premiumId, teamId, useCredit) => dispatch(buyPremium(premiumId, teamId, useCredit)),
});

const mapStateToProps = state => ({
  userCurrency: state.activities.notifications.currency,
  cart: state.cart,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
