import React from 'react';
import { connect } from 'react-redux';

import { roundBetsGetUserBet } from '../../app/store/actions/roundsBets';

import LiveBetsGames from './liveBetsGames';

export class LiveBets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roundNumber: 0,
    };
  }

  async componentDidMount() {
    const { currentRound } = this.props;
    if (currentRound) await this.getBets();
  }

  async componentWillReceiveProps(nextProps) {
    const { isIdle, roundUpdated } = nextProps;

    if (roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
      await this.getBets();
    }
  }

  getBets = async () => {
    const { roundNumber } = this.state;
    const { userId, currentRound } = this.props;
    const newRoundNumber = currentRound.closed ? currentRound.number : currentRound.number - 1;

    if (roundNumber !== newRoundNumber) await this.setState({ roundNumber: newRoundNumber });

    await this.props.getBets(userId, newRoundNumber);
  };

  render() {
    const { roundNumber } = this.state;

    if (!roundNumber) return null;

    return (
      <LiveBetsGames roundNumber={roundNumber} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBets: (userId, roundNumber) => dispatch(roundBetsGetUserBet(userId, roundNumber)),
});

const mapStateToProps = state => ({
  userId: state.user.id,
  currentRound: state.rounds.current,
  isIdle: state.activities.idle,
  roundUpdated: state.rounds.updateAt,
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveBets);
