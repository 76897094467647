import React from 'react';
import { connect } from 'react-redux';

import { roundsCreate } from '../../../../app/store/actions/rounds';

import AdministrationRoundsGames from '../games/administrationRoundsGames';

import { history } from '../../../../routers/appRouter';

export class AdministrationRoundsCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      games: [...Array(10).keys()].map((v, index) => ({ index })),
    };
  }

  saveGames = async (games) => {
    try {
      await this.props.createRound(games);
      history.push('/administrativo/rodadas/lista');
    } catch (err) { /* empty */ }
  }

  render() {
    const { games } = this.state;

    return (
      <div className="content-container margin-bottom administration-round">
        <div className="title-center-menu">
          {'Criar Rodada'}
        </div>

        <AdministrationRoundsGames
          games={games}
          buttonLabel="Criar Rodada"
          saveGames={this.saveGames}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createRound: games => dispatch(roundsCreate(games)),
});

export default connect(undefined, mapDispatchToProps)(AdministrationRoundsCreate);
