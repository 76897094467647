import React from 'react';
import { connect } from 'react-redux';
import { competitionsStartGroup, competitionsGetGroup } from '../../../../../app/store/actions/competitions';

import TeamsSelector from '../../../../../app/store/selectors/teams';

import TeamsCompetitionsDetailsInformationGroupData from './teamsCompetitionsDetailsInformationGroupData';

export class TeamsCompetitionsDetailsInformationGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {
    const { group, match } = this.props;
    const { groupId } = match.params;
    if (!group) {
      this.props.startGroup(groupId);
    } else if (group.old) {
      this.props.getGroup(groupId);
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { group } = nextProps;
    const { loading } = this.state;

    if (group && group.old && !loading) {
      await this.setState(() => ({ loading: true }));
      await this.props.getGroup(group.info.id);
      await this.setState(() => ({ loading: false }));
    }
  }

  render() {
    const { group, currentRoundNumber, userTeamId } = this.props;

    if (!group) return (null);

    return (
      <TeamsCompetitionsDetailsInformationGroupData
        group={group}
        currentRoundNumber={currentRoundNumber}
        userTeamIds={[userTeamId]}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startGroup: groupId => dispatch(competitionsStartGroup(groupId)),
  getGroup: groupId => dispatch(competitionsGetGroup(groupId)),
});

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { groupId } = match.params;

  return {
    group: state.competitions.details.groups[groupId],
    currentRoundNumber: state.rounds.current && state.rounds.current.number,
    userTeamId: TeamsSelector.getUserTeamByCompetition(state).id,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCompetitionsDetailsInformationGroup);
