import React from 'react';

import TinyPieChart from '../charts/tinyPieChart';

import colorsData from '../../../data/colors';

const BetsHitsChart = (props) => {
  const { simple, accurate, miss } = props;

  const dataTypes = [
    { name: 'Acertos', value: simple },
    { name: 'Cravadas', value: accurate },
    { name: 'Erros', value: miss },
  ];

  const colors = [
    colorsData.layout.turquoise,
    colorsData.layout.peterRiver,
    '#FF7373',
  ];

  return (
    <div className="bets">
      <div className="chart-bets">
        <TinyPieChart
          data={dataTypes}
          colors={colors}
        />
      </div>
      <div className="types">
        <div className="data">
          <div className="label">
            <div className="square" style={{ background: colors[0] }} />
            {simple}
            {' Acertos'}
          </div>
        </div>
        <div className="data">
          <div className="label">
            <div className="square" style={{ background: colors[1] }} />
            {accurate}
            {' Cravadas'}
          </div>
        </div>
        <div className="data">
          <div className="label">
            <div className="square" style={{ background: colors[2] }} />
            {miss}
            {' Erros'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetsHitsChart;
