import React from 'react';
import { connect } from 'react-redux';

import Paginate from '../../../elements/paginate';

import RecordsRoundsContentBetsTable from './table';

import { recordsGetRoundRankingBets } from '../../../../app/store/actions/records';

export class RecordsRoundsContentBets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      rankings: [],
    };
  }

  async componentDidMount() {
    const rankings = await this.loadRanking(1);
    await this.setState({ rankings });
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    const rankings = await this.loadRanking(pageIndex + 1);
    await this.setState({ rankings: { ...rankings }, page: pageIndex + 1 });
  };

  loadRanking = async (page) => {
    const { roundNumber } = this.props;
    return this.props.getRanking(roundNumber, page);
  };

  render() {
    const { rankings = {}, page } = this.state;
    const { data = [], totalPages = 0, skip = 0 } = rankings;

    if (!totalPages) return (null);

    return (
      <div className="ranking">
        <RecordsRoundsContentBetsTable players={data} skip={skip} />
        <Paginate
          pageCount={totalPages}
          forcePage={page - 1}
          onPageChange={this.handlePageClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getRanking: (roundNumber, page) => dispatch(recordsGetRoundRankingBets(roundNumber, page)),
});

export default connect(undefined, mapDispatchToProps)(RecordsRoundsContentBets);
