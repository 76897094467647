import React from 'react';

import TeamsGameList from '../../../../../elements/games/list/list';

import TeamsStatisticsConfrontationContentStatistics from './statistics';
import TeamsStatisticsConfrontationGamesTypes from './gamesTypes';
import TeamsStatisticsConfrontationCharts from './charts';

const TeamsStatisticsConfrontationContent = (props) => {
  const {
    teamHome, teamAway, games = [], results,
  } = props;

  return (
    <div className="content-container">
      <div className="title-line">
        {'Estatísticas'}
      </div>
      <div className="with-margin">
        <TeamsStatisticsConfrontationContentStatistics
          teamHome={teamHome}
          teamAway={teamAway}
        />
      </div>
      <div className="with-margin">
        <TeamsStatisticsConfrontationGamesTypes
          results={results}
          teamHome={teamHome}
          teamAway={teamAway}
        />
      </div>
      <div className="title-line">
        {'Gráficos'}
      </div>
      <div>
        <TeamsStatisticsConfrontationCharts
          teamHome={teamHome}
          teamAway={teamAway}
        />
      </div>
      <div className="title-line">
        {'Jogos'}
      </div>
      <div>
        {games.map(game => (<TeamsGameList key={game.id} game={game} round />))}
      </div>
    </div>
  );
};

export default TeamsStatisticsConfrontationContent;
