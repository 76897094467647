import React from 'react';
import { connect } from 'react-redux';

import teamsStatisticsGetStrikers from '../../../../../app/store/actions/teamsDetailsStatistics';

import Player from '../../../../players/elements/player';
import Team from '../../../elements/team';

import Ranking from '../../../../elements/statistics/ranking';

export class TeamsDetailsStatisticsStrikers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      strikers: [],
      totalPages: 1,
    };
  }

  getStrikers = async (interval, page) => {
    const { teamId, gameMode } = this.props;
    const data = await this.props.getStrikers(gameMode, page, teamId);
    await this.setState({ strikers: data.data, totalPages: Math.ceil(data.total / data.limit) });
  }

  getPosition = (playerId) => {
    const { players } = this.props;

    const player = players.find(p => (p.user && p.user.id) === playerId);

    return player ? 'Sim' : '-';
  }

  render() {
    const { strikers, totalPages } = this.state;
    const { match, gameMode } = this.props;

    const tableHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      {
        key: 'player', value: 'Artilharia', className: 'name', colspan: 3,
      },
      { key: 'pos_change', tdClassName: 'pos-change', hidden: true },
      { key: 'goals', value: 'Gols Oficiais', tdClassName: 'high' },
      { key: 'friendly_goals', value: 'Gols em Amistosos' },
      { key: 'total', value: 'Rodadas' },
      { key: 'team', value: 'Atualmente' },
    ];

    const tableLines = strikers.map(row => ({
      key: row.user.id,
      position: row.position,
      player: <Player data={row.user} type="table" />,
      goals: row.value,
      friendly_goals: row.friendly_value,
      total: row.total,
      team: <Team data={row.user.teams.find(t => t.mode_type === gameMode)} type="table" />,
    }));

    return (
      <div className="content-container margin-bottom">
        <Ranking
          match={match}
          totalPages={totalPages}
          tableHeader={tableHeader}
          tableLines={tableLines}
          getRanking={this.getStrikers}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getStrikers: (gameMode, page, teamId) => dispatch(teamsStatisticsGetStrikers(gameMode, page, teamId)),
});

const mapStateToProps = state => ({
  teamId: state.teams.details && state.teams.details.id,
  gameMode: state.teams.details && state.teams.details.gameMode,
  players: state.teams.details && state.teams.details.overview && state.teams.details.overview.players,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsStatisticsStrikers);
