import GameModeUtils from './gameMode';
import PlayersUtils from './players';
import GamesUtils from './games';
import CompetitionsUtils from './competitions';
import RoundsUtils from './rounds';

import FormationsData from '../../data/formations';
import colorsData from '../../data/colors';
import translator from '../../data/translator';

import ObjectHelper from '../helpers/object';
import StringHelper from '../helpers/string';

const TeamModel = {
  name: 'Indefinido',
  abbr: 'IND',
  leagues: [],
  colors: ['white'],
  badge: {
    url: '/images/team_placeholder.png',
  },
};

function getRegionName(gameMode, region) {
  if (!GameModeUtils.data[gameMode]) return region;
  if (!GameModeUtils.data[gameMode].regions[region]) return region;
  return GameModeUtils.data[gameMode].regions[region];
}

function getLeagueLabel(leagues) {
  const league = leagues.find(l => l.league === 'league');
  if (!league) return 'Sem Série';
  return `Série ${String.fromCharCode(64 + league.priority)}`;
}

function getLeagueTypeLabel(gameMode, value) {
  if (!GameModeUtils.data[gameMode]) return value;
  if (!GameModeUtils.data[gameMode].leagues[value]) return value;
  return GameModeUtils.data[gameMode].leagues[value].name;
}

function getBackgroundColor(team) {
  if (team.colors[0]) return colorsData.teams.background[team.colors[0]];
  return colorsData.teams.background.black;
}

function getLeagueColor(leagues) {
  const league = leagues.find(l => l.league === 'league');
  return colorsData.numbers[league ? league.priority - 1 : colorsData.numbers.length - 1];
}

function completeTeam(team = {}) {
  team = { ...TeamModel, ...team };

  if (team.user) team.user = PlayersUtils.completePlayer(team.user);
  if (team.president) team.president = PlayersUtils.completePlayer(team.president);
  if (team.coach) team.coach = PlayersUtils.completePlayer(team.coach);
  if (team.manager) team.manager = PlayersUtils.completePlayer(team.manager);
  if (team.marketer) team.marketer = PlayersUtils.completePlayer(team.marketer);

  (team.players || []).forEach((player) => {
    if (player.user) player.user = PlayersUtils.completePlayer(player.user);
  });

  if (team.region) {
    team.region_tag = team.region;
    team.region = getRegionName(team.mode_type, team.region);
  }

  if (team.games) team.games = team.games.map(game => GamesUtils.completeGame(game));
  if (team.bets && team.bets.players) {
    team.bets.players.forEach((player) => { player.user = PlayersUtils.completePlayer(player.user); });
  }
  if (team.competitions) {
    team.competitions.forEach((competition) => {
      competition.details = CompetitionsUtils.completeCompetition(competition.details);
    });
  }
  if (team.achievements && team.achievements.triumphs) {
    team.achievements.triumphs.forEach((triumph) => {
      if (triumph.competition) {
        triumph.competition = CompetitionsUtils.completeCompetition(triumph.competition.competition);
      }
    });
  }

  if (team.round) team.round = RoundsUtils.completeRound(team.round);

  return team;
}

function getLink(team = {}) {
  return `/${translator.uri.gameModes[team.mode_type]}/${team.name}`;
}

function getPosition(gameMode, tag, position = '') {
  if (!GameModeUtils.data[gameMode]) return position;
  let pos = position;
  const lastChar = position[position.length - 1];
  if (lastChar >= '0' && lastChar <= '9') {
    [pos] = position.split('_');
  }
  return GameModeUtils.data[gameMode][tag][pos];
}

function getPositionAbbr(gameMode, position) {
  return getPosition(gameMode, 'positions_abbreviation', position);
}

function getPositionName(gameMode, position = '') {
  return getPosition(gameMode, 'positions', position);
}

function getFormation(gameMode) {
  if (!GameModeUtils.data[gameMode]) return 'Nenhuma';
  return GameModeUtils.data[gameMode].formation;
}

function nameClass(name) {
  const size = name.length;
  if (size > 24) return 'lg';
  if (size > 18) return 'md';
  if (size > 14) return 'sm';
  return 'xs';
}

function getBadgeLeague(leagues) {
  const league = leagues.find(l => l.league === 'league');
  if (league) {
    const name = getLeagueLabel(leagues);
    const badge = `/images/divisions/${league.priority}.png`;
    return { name, badge };
  }
}

function getBadgeRanked(leagues, rankedCompetitions) {
  const league = leagues.find(l => l.league === 'ranked');
  if (league) {
    const { name } = (rankedCompetitions[10 - league.priority] || {});
    const badge = `/images/ranked/${league.priority}.png`;
    return { name, badge };
  }
}

function getManagerPlayers(teamPlayers = []) {
  const positions = { defender: 0, midfielder: 0, forward: 0 };
  return ObjectHelper.clone(teamPlayers).map((player) => {
    if (Object.keys(positions).indexOf(player.position) !== -1) {
      positions[player.position] += 1;
      player.position += `_${positions[player.position]}`;
    }
    return player;
  });
}

function getManagerFormation(teamPlayers = []) {
  const positions = { defender: 0, midfielder: 0, forward: 0 };
  teamPlayers.forEach((player) => {
    if (Object.keys(positions).indexOf(player.position) !== -1) {
      positions[player.position] += 1;
    }
  });
  return `${positions.defender}-${positions.midfielder}-${positions.forward}`;
}

function getMarketPlayers(teamPlayers = []) {
  return ObjectHelper.clone(teamPlayers);
}

function getTeamFormationPlayers(team = {}) {
  let formation;
  let players = [];

  if (team.mode_type === 'manager') {
    players = getManagerPlayers(team.bets && team.bets.players);
    formation = FormationsData[team.mode_type][getManagerFormation(team.bets && team.bets.players)];
    if (!formation) formation = FormationsData[team.mode_type]['4-4-2'];
  } else {
    formation = FormationsData[team.mode_type].default;
    players = getMarketPlayers(team.bets && team.bets.players);
  }
  return { formation, players };
}

function searchTeams(teams = [], search = '') {
  const searchClean = StringHelper.removeAccents(search.toLowerCase());
  return teams.filter((team) => {
    const teamName = StringHelper.removeAccents(team.name.toLowerCase());
    const username = StringHelper.removeAccents(team.user && team.user.username && team.user.username.toLowerCase());
    return teamName.includes(searchClean) || username.includes(searchClean);
  });
}

function getTeamGamesAbbrPosition(gamesPositions, current) {
  if (!current || !gamesPositions) return;

  const gamesRoundsPositions = {};

  Object.keys(gamesPositions).forEach((postiion) => {
    gamesRoundsPositions[postiion] = gamesPositions[postiion]
      .map(index => `${current.games[index].team_home.abbr}-${current.games[index].team_away.abbr}`)
      .join(' | ');
  });

  return gamesRoundsPositions;
}

export default {
  completeTeam,
  getRegionName,
  getLeagueLabel,
  getLeagueTypeLabel,
  getBackgroundColor,
  getLeagueColor,
  getLink,
  getPositionAbbr,
  getPositionName,
  getFormation,
  nameClass,
  getBadgeLeague,
  getBadgeRanked,
  getManagerPlayers,
  getMarketPlayers,
  getTeamFormationPlayers,
  searchTeams,
  getTeamGamesAbbrPosition,
};
