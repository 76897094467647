import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="not-found">
    <div className="content-container">
      <div className="four_zero_four_bg">
        <div className="title">
          {'404'}
        </div>


      </div>
      <div className="contant_box_404">
        <h3>
          {'Parece que você está perdido'}
        </h3>

        <p>
          {'a página que você está procurando não está disponível!'}
        </p>

        <Link className="button normal" to="/">
          {'Home'}
        </Link>
      </div>

    </div>
  </div>
);

export default NotFound;
