import React from 'react';
import { connect } from 'react-redux';

import { usersAnalyzeByIp } from '../../../app/store/actions/usersAnalyze';
import pageTitleService from '../../../app/services/pageTitle';

import { scrollTo } from '../../../routers/appRouter';

import AdministrationUsersAnalyzeIpUsers from './administrationUsersAnalyzeIpUsers';
import AdministrationUsersAnalyzeIpBets from './administrationUsersAnalyzeIpBets';
import AdministrationUsersAnalyzeIpAccess from './administrationUsersAnalyzeIpAccess';

export class AdministrationUsersAnalyzeIp extends React.Component {
  constructor(props) {
    super(props);

    const { match } = props;

    this.state = {
      ip: match.params.ip,
    };
  }

  async componentDidMount() {
    const { ip } = this.state;
    pageTitleService.setTitle(`Análise de Usuários - ${ip}`);
    const data = await this.props.getUsersAnalyzeByIp(ip);
    await this.setState({ data });
    scrollTo('menu-secondary');
  }

  render() {
    const { ip, data = {} } = this.state;

    return (
      <div className="user-analyze-ip-page">
        <div className="content-container margin-bottom">
          <div className="title-center-menu">
            {`Analise Ip: ${ip}`}
          </div>
          <AdministrationUsersAnalyzeIpUsers users={data.users} />
          <AdministrationUsersAnalyzeIpBets users={data.users} rounds={data.bets} />
          <AdministrationUsersAnalyzeIpAccess users={data.users} accesses={data.accesses} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUsersAnalyzeByIp: ip => dispatch(usersAnalyzeByIp(ip)),
});


export default connect(undefined, mapDispatchToProps)(AdministrationUsersAnalyzeIp);
