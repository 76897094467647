import React from 'react';

import Tabs from '../../../elements/tabs/tabs';

const TeamsListLeagueHeader = (props) => {
  const { maxTeams, current } = props;

  const leagues = [];
  for (let i = 1; i <= maxTeams; i += 1) {
    leagues.push({
      name: `Série ${String.fromCharCode(64 + i)}`,
      index: i,
    });
  }
  leagues.push({
    name: 'Sem Série',
    index: 0,
  });

  return (
    <div className="content-container">
      <div className="title-center">
        {leagues[current - 1] ? leagues[current - 1].name : leagues[leagues.length - 1].name}
      </div>
      <Tabs
        items={leagues.map(league => ({
          name: league.name,
          onClick: () => props.goToLeague(league.index),
          active: current === league.index,
        }))}
      />
    </div>
  );
};

export default TeamsListLeagueHeader;
