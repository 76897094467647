import React from 'react';
import { connect } from 'react-redux';

import TutorialWrapper from '../elements/tutorial/tutorial';

import { authenticateLogin } from '../../app/store/actions/authenticate';
import { resetPassword } from '../../app/store/actions/user';

import { history } from '../../routers/appRouter';

export class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      error: '',
    };
  }

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password, error: '' }));
  };

  onConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState(() => ({ confirmPassword, error: '' }));
  };

  hasError = () => {
    const { password, confirmPassword, error } = this.state;
    if (error) return error;
    return (!password || !confirmPassword);
  };

  onSubmit = async (e) => {
    const { password, confirmPassword } = this.state;
    const { minPassword, location } = this.props;
    const { search } = location;
    const params = new URLSearchParams(search);

    e.preventDefault();

    const hash = params.get('hash');
    const email = params.get('email');

    if (password !== confirmPassword) {
      this.setState(() => ({ error: 'Senhas não são iguais.' }));
    } else if (password.length < minPassword) {
      this.setState(() => ({ error: `A senha deve conter pelo menos ${minPassword} caracteres` }));
    } else {
      await this.props.resetPassword(hash, email, password);
      await this.props.login(email, password);
      history.push('/');
    }
  };

  render() {
    const { password, confirmPassword, error } = this.state;

    return (
      <div className="contact">
        <TutorialWrapper image="password.jpg">
          <form onSubmit={this.onSubmit}>
            <h3 className="title-center">
              {'Recuperar Senha'}
            </h3>
            <div className="section">
              <h4>
                {'Nova Senha'}
              </h4>
              <div className="section-content">
                <input
                  type="password"
                  placeholder="Digite sua nova senha"
                  value={password}
                  onChange={this.onPasswordChange}
                />
                <input
                  type="password"
                  placeholder="Digite novamente sua nova senha"
                  value={confirmPassword}
                  onChange={this.onConfirmPasswordChange}
                />
                <div className="error">
                  {error}
                </div>
              </div>
            </div>
            <div>
              <button type="submit" className="button normal" disabled={this.hasError()}>
                {'Redefinir Senha'}
              </button>
            </div>
          </form>
        </TutorialWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetPassword: (hash, email, password) => dispatch(resetPassword(hash, email, password)),
  login: (email, password) => dispatch(authenticateLogin(email, password)),
});

const mapStateToProps = state => ({
  minPassword: state.settings.users && state.settings.users.password && state.settings.users.password.min,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
