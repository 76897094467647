import RestService from '../services/rest';

import ValidatorHelper from '../helpers/validator';

async function authenticate(emailOrUsername, password) {
  const data = { password };

  if (ValidatorHelper.isEmail(emailOrUsername)) data.email = emailOrUsername;
  else data.username = emailOrUsername;

  return RestService.postRest('users/authenticate', data);
}

async function refreshToken(token) {
  return RestService.postRest('users/refresh_token', {}, {
    Authorization: token,
  });
}

async function logout() {
  return RestService.postAuthenticated('users/logout');
}

export default {
  authenticate,
  refreshToken,
  logout,
};
