import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import TeamsGamesList from './list/teamsGamesList';
import TeamsGamesDetails from './details/teamsGamesDetails';

import translator from '../../../data/translator';

export const TeamsGames = (props) => {
  const {
    currentRoundNumber, modeData, match, types = [],
  } = props;
  const { url } = match;

  const menus = types.map(type => ({
    name: translator.p.competitions[type],
    type,
    uri: `${url}/${translator.uri.competitions[type]}`,
    menuNotExact: true,
  }));

  return (
    <Switch>
      <Redirect exact from={url} to={menus[0].uri} />
      {menus.map(menu => (
        <Redirect
          key={menu.name}
          exact
          from={menu.uri}
          to={`${menu.uri}/${currentRoundNumber}`}
        />
      ))}
      {menus.map(menu => (
        <Route
          key={menu.name}
          path={`${menu.uri}/:round`}
          component={p => <TeamsGamesList {...p} menus={menus} type={menu.type} modeData={modeData} />}
        />
      ))}
      <Route
        path={`${url}/:gameId`}
        component={p => <TeamsGamesDetails {...p} modeData={modeData} />}
      />
    </Switch>
  );
};


const mapStateToProps = state => ({
  currentRoundNumber: state.rounds.current && state.rounds.current.number,
  types: state.settings.games && state.settings.games.types,
});

export default connect(mapStateToProps)(TeamsGames);
