import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { collectiblesOpenChest } from '../../../app/store/actions/collectibles';

import CollectiblesChestsChest from './collectiblesChestsChest';

export class CollectiblesChests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillUnmount() {
    clearTimeout(this.updateInterval);
  }

  handleOpenChest = async (chestId) => {
    const collectible = await this.props.openChest(chestId);
    await this.setState(() => ({ newCollectible: collectible }));

    clearTimeout(this.updateInterval);
    this.updateInterval = setTimeout(async () => {
      await this.setState(() => ({ newCollectible: undefined }));
    }, 5000);
  }

  render() {
    const { newCollectible } = this.state;
    const {
      chestsObj, userCollectibles, packs, match,
    } = this.props;
    const { url } = match;

    const chestNames = Object.keys(chestsObj);
    const chests = chestNames.map(chestName => chestsObj[chestName]);

    chests.forEach((chest) => {
      chest.userTotal = 0;
      chest.collectibles.forEach((collectible) => {
        if (userCollectibles.find(c => c.id === collectible)) chest.userTotal += 1;
      });
      chest.closed = 0;
      const userChest = userCollectibles.find(c => c.id === chest.id);
      if (userChest) chest.closed = userChest.amount;
    });

    return (
      <div className="margin-bottom">
        <div className="title-center">
          {'Colecionáveis'}
        </div>
        <Grid fluid>
          <Row>
            {chests.map(chest => (
              <Col lg={6} key={chest.id}>
                <CollectiblesChestsChest
                  chest={chest}
                  url={url}
                  newCollectible={newCollectible
                    && newCollectible.collection_name === chest.collection_name && newCollectible}
                  handleOpenChest={this.handleOpenChest}
                  packs={packs}
                />
              </Col>
            ))}
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openChest: chestId => dispatch(collectiblesOpenChest(chestId)),
});

const mapStateToProps = state => ({
  chestsObj: state.collectibles.chests,
  userCollectibles: state.user.collectibles,
  packs: state.collectibles.packs,
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectiblesChests);
