import React from 'react';

import Table from '../../../../elements/table';
import Player from '../../../../players/elements/player';

import PlayerTableValuation from '../../../../players/table/valuation';
import PlayerTableBet from '../../../../players/table/bet';

const TeamsDetailsManagementMarketPlayers = (props) => {
  const header = [
    { key: 'player', value: 'Jogadores', className: 'name' },
    {
      key: 'value', value: 'Valor', className: 'number', tdClassName: 'high',
    },
    { key: 'bet', value: 'Aposta', className: 'number' },
    { key: 'options', className: 'number' },
  ];

  const data = props.players.map(market => ({
    key: market.user.id,
    player: <Player data={market.user} type="table" />,
    value: <PlayerTableValuation valuation={market.user.valuation} />,
    bet: <PlayerTableBet bets={market.user.bets} />,
    options: (
      <button
        className="button red"
        type="button"
        disabled={!((market.resign_request || market.wo_allow || market.federation || props.marketIsOpen)
          && props.teamPresidentId !== market.user.id && props.teamManagerId !== market.user.id)}
        onClick={() => {
          if (market.federation) {
            return props.federationDismiss(market.user.id);
          }
          return props.dismiss(market.user.id);
        }}
      >
        {'Demitir'}
      </button>
    ),
  }));

  return (
    <div className="content">
      <div className="title-line">
        {'Elenco'}
      </div>

      <Table header={header} lines={data} />
    </div>
  );
};

export default TeamsDetailsManagementMarketPlayers;
