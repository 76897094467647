import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MenuSecondary from '../elements/menuSecondary';

import AccountPlayer from './player/player';
import AccountProfile from './profile/profile';
import AccountPassword from './password/password';
import AccountFavoriteClub from './favoriteClub/favoriteClub';

export const AccountPage = () => {
  const menus = [
    { name: 'Jogador', uri: '/conta/jogador', component: AccountPlayer },
    { name: 'Dados Pessoais', uri: '/conta/perfil', component: AccountProfile },
    { name: 'Senha', uri: '/conta/senha', component: AccountPassword },
    { name: 'Clube Favorito', uri: '/conta/clube-favorito', component: AccountFavoriteClub },
  ];

  return (
    <div>
      <MenuSecondary menus={menus} tag="Minha Conta" />
      <Switch>
        <Redirect exact from="/conta" to={menus[0].uri} />
        {menus.map(menu => (
          <Route
            key={menu.name}
            path={menu.uri}
            component={menu.component}
          />
        ))}
      </Switch>
    </div>
  );
};

export default AccountPage;
