import React from 'react';

import Table from '../../elements/table';
import Player from '../../players/elements/player';

import PlayerTableValuation from '../../players/table/valuation';
import PlayerTableBet from '../../players/table/bet';
import PlayerTableDiscard from '../../players/table/discard';

const AdministrationUsersAnalyzeIpUsers = ({ users = [] }) => {
  const tableHeader = [
    { key: 'position', tdClassName: 'position', hidden: true },
    { key: 'user', value: 'Usuários', colspan: 2 },
    { key: 'value', value: 'Valor', className: 'number' },
    { key: 'bet', value: 'Aposta', className: 'number' },
    { key: 'discard', value: 'Descarte', className: 'number' },
  ];

  const tableData = users.map((user, index) => ({
    key: user.id,
    position: index + 1,
    user: <Player key={user.id} data={user} type="table" />,
    value: <PlayerTableValuation valuation={user.valuation} />,
    bet: <PlayerTableBet bets={user.bets} />,
    discard: <PlayerTableDiscard discard={user.discard} />,
  }));

  return (
    <div className="margin-bottom">
      <Table header={tableHeader} lines={tableData} />
    </div>
  );
};

export default AdministrationUsersAnalyzeIpUsers;
