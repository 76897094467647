import React from 'react';

import StepsButtons from '../../../elements/steps/buttons';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

const TutorialSingleIntroduction = props => (
  <TutorialWrapper image="game_modes/introduction.jpg">
    <StepsButtons {...props}>
      <h3 className="title-center">
        {'Fantasia'}
      </h3>
      <div className="section">
        <p>
          {'Neste modo, você fundará um clube com o nome de sua escolha. Além de poder escolher o escudo '
        + 'que mais lhe agrade.'}
        </p>
        <p>
          {'Durante o ano, o seu Clube participará de diversos campeonatos oficiais, além de campeonatos '
        + 'personalizados e premiums. Será possível, em cada rodada, marcar amistosos com outros clubes.'}
        </p>
      </div>
      <h4>
        {'Jogos entre Clubes'}
      </h4>
      <p className="text">
        {'O número de gols que o Clube fará nos jogos será a sua pontuação nas apostas na rodada.'}
      </p>
    </StepsButtons>
  </TutorialWrapper>
);


export default TutorialSingleIntroduction;
