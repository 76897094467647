import React from 'react';

import StepsButtons from '../../../elements/steps/buttons';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

const TutorialNationalGame = props => (
  <TutorialWrapper image="game_modes/game.jpg">
    <StepsButtons {...props}>
      <h3 className="title-center">
        {'Seleção'}
      </h3>
      <h4>
        {'Jogos entre Seleções'}
      </h4>
      <p>
        {'Nos confrontos entre Seleções, cada jogador enfrentará o jogador da outra seleção que esteja '
        + 'jogando na mesma posição, considerando-se os seguintes jogos, por posição:'}
      </p>
      <ul className="list">
        <li>
          <b>
            {'Goleiro: '}
          </b>
          {'(1º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Zagueiro Esquerdo: '}
          </b>
          {'(2º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Zagueiro Direito: '}
          </b>
          {'(2º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Lateral Esquerdo: '}
          </b>
          {'(3º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Lateral Direito: '}
          </b>
          {'(3º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Volante: '}
          </b>
          {'(4º e 5º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Meia Esquerdo: '}
          </b>
          {'(5º e 6º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Meia Direito: '}
          </b>
          {'(5º e 6º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Armador: '}
          </b>
          {'(6º e 7º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Atacante Esquerdo: '}
          </b>
          {'(8º, 9º e 10º - Jogo da rodada)'}
        </li>
        <li>
          <b>
            {'Atacante Direito: '}
          </b>
          {'(8º, 9º e 10º - Jogo da rodada)'}
        </li>
      </ul>
      <p>
        {'A diferença de pontuação entre os jogadores de cada confronto será o número de gols que o '
        + 'jogador com a maior pontuação fará no confronto.'}
      </p>
      <p className="text">
        {'O “Presidente” da Seleção será responsável por escalar os jogadores em cada rodada.'}
      </p>
    </StepsButtons>
  </TutorialWrapper>
);

export default TutorialNationalGame;
