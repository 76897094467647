import React from 'react';

import CardSimple from '../../../elements/card/simple';

import AdministrationRoundsGameAutocomplete from './administrationRoundsGameAutocomplete';
import AdministrationRoundsGameCompetition from './administrationRoundsGameCompetition';
import AdministrationRoundsGameDate from './administrationRoundsGameDate';

import Team from '../../../teams/elements/team';

export class AdministrationRoundsGame extends React.Component {
  setDate = (date) => {
    const { game } = this.props;
    this.props.setData(game.index, { date });
  }

  setDataId = (data, field) => {
    const { game } = this.props;
    const value = data ? data.id : null;
    this.props.setData(game.index, { [field]: value });
  }

  render() {
    const { realTeams = [], competitions = [], game } = this.props;

    return (
      <div>
        <CardSimple className="game-classificatory round-game">
          <div className="teams">
            <div className="team">
              <AdministrationRoundsGameAutocomplete
                startValue={game.start && game.start.team_home}
                allData={realTeams}
                component={Team}
                placeholder="Selecione o Mandante"
                setData={team => this.setDataId(team, 'team_home')}
              />
            </div>
            <div className="team">
              <AdministrationRoundsGameAutocomplete
                startValue={game.start && game.start.team_away}
                allData={realTeams}
                component={Team}
                placeholder="Selecione o Visitante"
                setData={team => this.setDataId(team, 'team_away')}
              />
            </div>
          </div>
          <div className="competition">
            <AdministrationRoundsGameAutocomplete
              startValue={game.start && game.start.competition}
              allData={competitions}
              component={AdministrationRoundsGameCompetition}
              placeholder="Selecione o Campeonato"
              setData={competition => this.setDataId(competition, 'competition')}
            />
          </div>
          <div className="date">
            <AdministrationRoundsGameDate
              startValue={game.date}
              setData={this.setDate}
            />
          </div>
        </CardSimple>
      </div>
    );
  }
}

export default AdministrationRoundsGame;
