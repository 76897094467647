import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import translator from '../../../../data/translator';

import SimpleBarChart from '../../../elements/charts/simpleBarChart';

const AdministrationReportTransactionsInflow = (props) => {
  const { inflow, types: inflowTypes = [] } = props;
  const {
    net_profit: netProfit, gross_profit: grossProfit, top_spenders: spenders = [], types,
  } = inflow;

  const typesComplete = inflowTypes.map((typeKey) => {
    const typeFound = types.find(t => t.name === typeKey);
    if (typeFound) return typeFound;
    return { name: typeKey, times: 0, value: 0 };
  });
  typesComplete.sort((a, b) => a.name > b.name);

  const dataValue = [{ name: 'Valores' }];
  const dataTimes = [{ name: 'Vezes' }];
  const bars = {};

  typesComplete.forEach((type) => {
    bars[type.name] = {
      name: translator.s.transactions[type.name],
    };
    dataValue[0][type.name] = type.value;
    dataTimes[0][type.name] = type.times;
  });

  return (
    <Grid fluid>
      <Row>
        <Col md={4}>
          <h3>
            {'Receita'}
          </h3>
          <h4>
            {'Líquida: R$'}
            {netProfit.toFixed(2)}
          </h4>
          <h4>
            {'Crédito Inserido: R$'}
            {grossProfit.toFixed(2)}
          </h4>

          <table>

            <tbody>

              <tr>
                <th>
                  {'Jogador'}
                </th>
                <th>
                  {'Valor'}
                </th>
              </tr>

              {spenders.slice(0, 5).map(spender => (
                <tr key={spender.user.id}>
                  <th>
                    {spender.user.username}
                  </th>
                  <th>
                    {'R$'}
                    {spender.value.toFixed(2)}
                  </th>
                </tr>
              ))}

            </tbody>

          </table>
        </Col>

        <Col md={4}>
          <div style={{ height: '28rem' }}>
            <SimpleBarChart data={dataValue} bars={bars} />
          </div>
        </Col>

        <Col md={4}>
          <div style={{ height: '28rem' }}>
            <SimpleBarChart data={dataTimes} bars={bars} />
          </div>
        </Col>

      </Row>
    </Grid>
  );
};

export default AdministrationReportTransactionsInflow;
