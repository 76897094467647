import React from 'react';

import { connect } from 'react-redux';
import Table from './table';

const TableAuth = (props) => {
  const { highlightIds = [], lines } = props;

  highlightIds.forEach((highlightId) => {
    const playerIndex = lines.findIndex(row => highlightId === row.key);
    if (playerIndex !== -1) lines[playerIndex].className = 'highlight';
  });


  return <Table {...props} />;
};

const mapStateToProps = (state) => {
  const teamIds = Object.keys(state.user.teams || {}).map(gameMode => state.user.teams[gameMode].id);
  return { highlightIds: [state.user.id, ...teamIds] };
};

export default connect(mapStateToProps)(TableAuth);
