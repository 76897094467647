import React from 'react';
import { Link } from 'react-router-dom';

import CardSimple from '../../card/simple';

import Team from '../../../teams/elements/team';

import GamesUtils from '../../../../app/utils/games';

const GameClassificatory = (props) => {
  const { game, className = '' } = props;

  if (!game) return (null);

  return (
    <CardSimple className={`game-classificatory ${className}`}>
      <div className="team">
        <Team data={game.team_home} />
      </div>
      <div className="result">
        <Link to={GamesUtils.getLink(game)}>
          <div className={`goals${game.score_home > game.score_away ? ' winner' : ''}`}>
            {game.score_home}
          </div>
          <div className="divisor">-</div>
          <div className={`goals${game.score_home < game.score_away ? ' winner' : ''}`}>
            {game.score_away}
          </div>
        </Link>
      </div>
      <div className="team">
        <Team data={game.team_away} />
      </div>
    </CardSimple>
  );
};

export default GameClassificatory;
