import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { history } from '../../../../routers/appRouter';

import pageTitleService from '../../../../app/services/pageTitle';

import Paginate from '../../../elements/paginate';
import MenuSecondary from '../../../elements/menuSecondary';

import TeamsGamesListRound from './teamsGamesListRound';

import translator from '../../../../data/translator';

export class TeamsGamesList extends React.Component {
  constructor(props) {
    super(props);

    const { currentRoundNumber, match } = this.props;
    const { params } = match;

    this.state = {};

    if (params.round) {
      this.state.roundNumber = Number(params.round);
      this.state.maxRound = Math.max(this.state.roundNumber, currentRoundNumber) + 1;
    }

    this.handleChangeRound = this.handleChangeRound.bind(this);
  }

  componentDidMount() {
    pageTitleService.setTitle('Jogos');
  }

  handleChangeRound = async (data) => {
    const { selected: pageIndex } = data;

    const { match } = this.props;
    const { path } = match;
    const roundNumber = Number(pageIndex + 1);

    const url = path.replace(':round', roundNumber);
    history.push(url);
  };

  render() {
    const { roundNumber, maxRound } = this.state;
    const {
      modeData, type, menus, match,
    } = this.props;
    const { url } = match;

    if (!roundNumber) return (<Redirect exact from={url} to={`${url}/${roundNumber}`} />);

    const rounds = [];
    for (let i = 1; i <= maxRound; i += 1) rounds.push(i);

    return (
      <div className="margin-bottom list-games">
        <MenuSecondary menus={menus} tag="Jogos" />

        <div className="title-center-menu">{`Jogos ${translator.p.competitions[type]}`}</div>

        <Paginate
          pageCount={rounds.length}
          forcePage={roundNumber - 1}
          onPageChange={this.handleChangeRound}
        />

        <TeamsGamesListRound roundNumber={roundNumber} type={type} mode={modeData.mode} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentRoundNumber: state.rounds.current && state.rounds.current.number,
});

export default connect(mapStateToProps)(TeamsGamesList);
