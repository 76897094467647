import React from 'react';

import GuideWrapper from '../wrapper';

import GuideNationalGeneral from './general';
import GuideRoles from '../roles';
import GuideNationalGames from './games';
import GuideCompetitions from '../competitions';
import GuideNationalPenalties from './penalties';

export const GuideNational = () => {
  const menus = [
    { key: 'general', title: 'Geral', component: GuideNationalGeneral },
    { key: 'roles', title: 'Cargos', component: GuideRoles },
    { key: 'games', title: 'Jogos', component: GuideNationalGames },
    { key: 'competitions', title: 'Competições', component: GuideCompetitions },
    { key: 'penalties', title: 'Pênaltis', component: GuideNationalPenalties },
  ];

  return <GuideWrapper title="Regulamento" menus={menus} gameMode="national" />;
};

export default GuideNational;
