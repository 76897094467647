import React from 'react';

import ImageLoad from '../image/imageLoad';

const CardChampion = ({ name, badge }) => (
  <div className="card-all card-champion" key={name}>
    <div className="badge">
      <ImageLoad
        src={badge.url}
        alt={badge.name}
        placeholder="/images/team_placeholder.png"
      />
    </div>
  </div>
);

export default CardChampion;
