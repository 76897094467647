import React from 'react';
import { Link } from 'react-router-dom';

import ImageLoad from '../image/imageLoad';
import Hover from '../hover';

export class BadgeListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hover: !!props.item.forceHover };
  }

  onHover = async (isHover) => {
    await this.setState({ hover: isHover });
  }

  render() {
    const { hover } = this.state;
    const { item = {} } = this.props;

    const itemStyle = {};
    itemStyle.backgroundColor = item.color;

    const tagStyle = {};
    let tagName;

    if (item.tag) {
      tagName = hover ? item.tag.nameHover : item.tag.name;
      if (hover) tagStyle.backgroundColor = item.tag.colorHover;
      else tagStyle.backgroundColor = item.tag.color;
    }

    const badgeElement = (
      <Hover
        forceHover={item.forceHover}
        className="item"
        classHover="hovered"
        styleHover={itemStyle}
        onHover={this.onHover}
      >
        <ImageLoad
          src={item.badge}
          alt={item.name}
          className={(!item.locked ? 'has' : '') + (item.badgeClass ? ` ${item.badgeClass}` : '')}
          placeholder="/images/team_placeholder.png"
        />
        {item.locked && (
          <div className={`lock${item.lockedClass ? ` ${item.lockedClass}` : ''}`} />
        )}
        {item.mark !== undefined && (
        <mark style={itemStyle}>
          {item.mark}
        </mark>
        )}

        {!hover && item.icon !== undefined && (
        <img className="icon" src={item.icon} alt="Icon" />
        )}

        <div className={`tag${(!hover && item.icon) ? ' with-icon' : ''}`} style={tagStyle}>
          {tagName}
        </div>
        <div className="overlay">
          <div className="info">
            <div className="name">
              {item.name}
            </div>
          </div>
        </div>
      </Hover>
    );

    if (item.link) {
      return (
        <Link to={item.link}>
          {badgeElement}
        </Link>
      );
    }

    if (item.onClick) {
      return (
        <div
          onClick={item.onClick}
          onKeyDown={item.onClick}
          role="presentation"
          style={{ cursor: 'pointer' }}
        >
          {badgeElement}
        </div>
      );
    }

    return badgeElement;
  }
}

export default BadgeListItem;
