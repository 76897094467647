import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import RcProgressLine from '../../../../../elements/rcProgress/line';
import TinyBarChart from '../../../../../elements/charts/tinyBarChart';
import Colors from '../../../../../../data/colors';

import ReputationUtils from '../../../../../../app/utils/reputation';

const PlayersDetailsOverviewStatisticsReputationInformation = (props) => {
  const { reputation, playerBet, isRoundProgress } = props;
  const { value } = reputation;

  const score = ReputationUtils.getScore(playerBet);

  let target = ReputationUtils.getScoreToNextLevel(value);
  if (!isRoundProgress) {
    if (score.current >= ReputationUtils.getScoreToNextLevel(value - 1)) {
      target = ReputationUtils.getScoreToNextLevel(value - 1);
    }
  }
  const resultText = isRoundProgress ? 'Rodada Atual' : 'Última Rodada';
  const totalGames = score.miss + score.hits + score.accurate;

  const nextReputation = ReputationUtils.getReputation(value + 1);

  const barsReputation = {
    miss: {
      name: 'Erros',
      stackId: 'miss',
    },
    hits: {
      name: 'Acertos',
      stackId: 'hits',
    },
    accurate: {
      name: 'Cravadas',
      stackId: 'accurate',
    },
  };

  const dataReputation = [{
    miss: score.miss,
    hits: score.hits,
    accurate: score.accurate,
  }];

  return (
    <div className="information">
      <h3 className="result-title">
        {resultText}
      </h3>

      {totalGames > 0 && (
        <div className="score-chart">
          <div className="texts">
            <div>
              {'Erros'}
            </div>
            <div>
              {'Acertos'}
            </div>
            <div>
              {'Cravadas'}
            </div>
          </div>
          <div className="chart">
            <TinyBarChart data={dataReputation} bars={barsReputation} colors={[Colors.layout.clouds]} isVertical />
          </div>
        </div>
      )}

      <div className="progress">
        <RcProgressLine
          percent={Math.max(Math.min(score.current / target, 1) * 100, 0)}
          strokeWidth="5"
          trailWidth="5"
          strokeColor={Colors.layout.clouds}
          trailColor={Colors.layout.asbestos}
        />
      </div>
      <div className="calculation">
        {`4 x (${score.accurate} Cravada${score.accurate !== 1 ? 's' : ''}) 
          + (${score.hits} Acerto${score.hits !== 1 ? 's' : ''}) 
          - (${score.miss} Erro${score.miss !== 1 ? 's' : ''})`}
      </div>
      <div className="next">
        <div className="score">
          <span>
            {score.current}
          </span>
          {'/'}
          {target}
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={faCaretRight} />
        </div>
        <div className="next-badge">
          {isRoundProgress ? (
            <React.Fragment>
              <div className="image">
                <img src={nextReputation.picture} alt={nextReputation.name} />
              </div>
              <div className="name">
                {nextReputation.name}
              </div>
            </React.Fragment>
          ) : (
            <div className="result-icon">
              <FontAwesomeIcon icon={score.current >= target ? faCheckCircle : faTimesCircle} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayersDetailsOverviewStatisticsReputationInformation;
