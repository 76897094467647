import React from 'react';
import { connect } from 'react-redux';

import TeamsListTeams from '../teamsListTeams';

import TeamsUtils from '../../../../app/utils/teams';

import { teamsGetFederation } from '../../../../app/store/actions/teams';

export class TeamsListFederation extends React.Component {
  componentDidMount() {
    const { federations, gameMode } = this.props;
    if (!federations) { this.props.getTeamsFederation(gameMode); }
  }

  render() {
    const { federations, gameMode } = this.props;

    return (
      <div className="teams-list-federation content-container with-margin">
        {federations && federations.map(federation => (
          <div key={federation.id}>
            <div className="title-center">
              {TeamsUtils.getRegionName(gameMode, federation.region)}
            </div>
            {federation.president && (
            <h5>
              {federation.president.username}
            </h5>
            )}
            <div className="box-teams">
              <TeamsListTeams teams={federation.teams} center />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTeamsFederation: gameMode => dispatch(teamsGetFederation(gameMode)),
});

const mapStateToProps = (state, ownProps) => ({
  federations: state.teams.federation[ownProps.gameMode],
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsListFederation);
