import React from 'react';

import GuideWrapper from '../wrapper';

import GuideGeneralRound from './round';
import GuideGeneralScore from './score';
import GuideGeneralRegistration from './registration';
import GuideGeneralWall from './wall';
import GuideGeneralFakes from './fakes';
import GuideGeneralPunishments from './punishments';

export const GuideGeneral = () => {
  const menus = [
    { key: 'round', title: 'Rodadas', component: GuideGeneralRound },
    { key: 'bets', title: 'Pontuação', component: GuideGeneralScore },
    { key: 'registration', title: 'Cadastro', component: GuideGeneralRegistration },
    { key: 'wall', title: 'Mural', component: GuideGeneralWall },
    { key: 'fakes', title: 'Fakes', component: GuideGeneralFakes },
    { key: 'punishments', title: 'Punições', component: GuideGeneralPunishments },
  ];

  return <GuideWrapper title="Regulamento" menus={menus} />;
};

export default GuideGeneral;
