import React from 'react';
import { connect } from 'react-redux';

import { teamsConfrontationGet } from '../../../../../app/store/actions/teamsConfrontation';

import GameUtils from '../../../../../app/utils/games';

import TeamsStatisticsConfrontationContent from '../../../statistics/confrontation/information/content/content';

export class TeamsGamesConfrontation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const { data } = this.state;
    const { game } = this.props;


    if (game && !data) {
      const confrontationData = await this.props.getConfrontation(game.mode_type, game.team_home.id, game.team_away.id);
      GameUtils.completeGamesControntation(confrontationData);
      await this.setState({ data: confrontationData });
    }
  }

  render() {
    const { data } = this.state;

    if (!data) return (null);

    return (
      <div className="with-margin">
        <TeamsStatisticsConfrontationContent
          games={data.games}
          results={data.results}
          teamHome={data.team_home}
          teamAway={data.team_away}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  game: state.games.details && state.games.details.game,
});

const mapDispatchToProps = dispatch => ({
  getConfrontation: (gameMode, firstTeamId, secondTeamId) => dispatch(
    teamsConfrontationGet(gameMode, firstTeamId, secondTeamId),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsGamesConfrontation);
