import React from 'react';

export const GuideNationalGeneral = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Neste modo, o site disponibiliza seleções, que são divididas em '}
          <b>Federações</b>
          {'. Você será um jogador que irá integrar o elenco de uma dessas seleções.'}
        </p>
        <p>
          {'Cada seleção pode ter até '}
          <b>
            {'14 jogadores'}
          </b>
          {' (11 titulares e 3 reservas)'}
          {' e são administrados por um jogador denominado '}
          <b>Presidente</b>
          {'. O presidente poderá nomear auxiliares em sua administração. As posições para '
          + 'disputa de jogos com outras seleções seguem as posições do '}
          <b>Futebol de Campo</b>
          {'.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Participar de uma seleção'}
      </h4>
      <div className="section-content">
        <p>
          {'Para entrar em uma seleção, cabe exclusivamente ao jogador essa escolha. Assim, ele deve aceitar convites '
        + 'que sinta que o ambiente entre os jogadores seja de respeito e camaradagem.'}
        </p>
        <p>
          {'Lembre-se que, em condições normais, a transferência somente é permitida com a abertura do mercado. Será '
        + 'oferecida também a opção de "Aceitar qualquer Seleção", para o caso você não tenha preferências.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuideNationalGeneral;
