import AccountRequests from '../../server/account';
import { userGetAuth } from './user';
import { addLoading, removeLoading } from './loading';
import { showAlertError, showAlertSuccess } from './alert';

export const accountUpdatePicture = file => async (dispatch) => {
  dispatch(addLoading());

  try {
    await AccountRequests.updatePicture(file);
    await dispatch(userGetAuth());
    dispatch(showAlertSuccess('Foto alterada com sucesso!'));
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};

export const accountChancePassword = (oldPassword, password) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await AccountRequests.chancePassword(oldPassword, password);
    dispatch(showAlertSuccess('Senha alterada com sucesso!'));
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};

export const accountUpdateUser = data => async (dispatch) => {
  dispatch(addLoading());

  try {
    await AccountRequests.updateUser(data);
    await dispatch(userGetAuth());
    dispatch(showAlertSuccess('Dados Pessoais alterados com sucesso!'));
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};

export const accountUpdateRealTeam = realTeamId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await AccountRequests.updateRealTeam(realTeamId);
    await dispatch(userGetAuth());
    dispatch(showAlertSuccess('Clube do coração alterado com sucesso!'));
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};
