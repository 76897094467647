export default {
  club: {
    default: {
      goalkeeper: { x: 50, y: 97 },
      defender: { x: 50, y: 75 },
      left_winger: { x: 0, y: 50 },
      right_winger: { x: 100, y: 50 },
      pivot: { x: 50, y: 15 },
    },
    game: {
      goalkeeper: { line: 1, pos: 5 },
      defender: { line: 2, pos: 5 },
      left_winger: { line: 3, pos: 1 },
      right_winger: { line: 3, pos: 9 },
      pivot: { line: 4, pos: 5 },
    },
  },
  national: {
    default: {
      goalkeeper: { x: 50, y: 97 },
      left_defender: { x: 30, y: 80 },
      right_defender: { x: 70, y: 80 },
      left_back: { x: 0, y: 70 },
      right_back: { x: 100, y: 70 },
      holding_midfielder: { x: 50, y: 60 },
      left_midfielder: { x: 0, y: 45 },
      right_midfielder: { x: 100, y: 45 },
      playmaker: { x: 50, y: 30 },
      left_striker: { x: 30, y: 10 },
      right_striker: { x: 70, y: 10 },
    },
    game: {
      goalkeeper: { line: 1, pos: 5 },
      left_defender: { line: 2, pos: 3 },
      right_defender: { line: 2, pos: 7 },
      left_back: { line: 3, pos: 1 },
      right_back: { line: 3, pos: 9 },
      holding_midfielder: { line: 4, pos: 5 },
      left_midfielder: { line: 5, pos: 2 },
      right_midfielder: { line: 5, pos: 8 },
      playmaker: { line: 6, pos: 5 },
      left_striker: { line: 7, pos: 3 },
      right_striker: { line: 7, pos: 7 },
    },
  },
  manager: {
    '3-3-4': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 20, y: 75 },
      defender_2: { x: 50, y: 80 },
      defender_3: { x: 80, y: 75 },
      midfielder_1: { x: 50, y: 60 },
      midfielder_2: { x: 25, y: 40 },
      midfielder_3: { x: 75, y: 40 },
      forward_1: { x: 0, y: 15 },
      forward_2: { x: 30, y: 10 },
      forward_3: { x: 70, y: 10 },
      forward_4: { x: 100, y: 15 },
    },
    '3-4-3': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 20, y: 75 },
      defender_2: { x: 50, y: 80 },
      defender_3: { x: 80, y: 75 },
      midfielder_1: { x: 0, y: 55 },
      midfielder_2: { x: 30, y: 45 },
      midfielder_3: { x: 70, y: 45 },
      midfielder_4: { x: 100, y: 55 },
      forward_1: { x: 20, y: 15 },
      forward_2: { x: 50, y: 10 },
      forward_3: { x: 80, y: 15 },
    },
    '3-5-2': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 20, y: 75 },
      defender_2: { x: 50, y: 80 },
      defender_3: { x: 80, y: 75 },
      midfielder_1: { x: 30, y: 55 },
      midfielder_2: { x: 70, y: 55 },
      midfielder_3: { x: 0, y: 40 },
      midfielder_4: { x: 50, y: 30 },
      midfielder_5: { x: 100, y: 40 },
      forward_1: { x: 30, y: 10 },
      forward_2: { x: 70, y: 10 },
    },
    '4-3-3': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 30, y: 80 },
      defender_2: { x: 70, y: 80 },
      defender_3: { x: 0, y: 70 },
      defender_4: { x: 100, y: 70 },
      midfielder_1: { x: 50, y: 60 },
      midfielder_2: { x: 25, y: 40 },
      midfielder_3: { x: 75, y: 40 },
      forward_1: { x: 10, y: 15 },
      forward_2: { x: 50, y: 10 },
      forward_3: { x: 90, y: 15 },
    },
    '4-4-2': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 30, y: 80 },
      defender_2: { x: 70, y: 80 },
      defender_3: { x: 0, y: 70 },
      defender_4: { x: 100, y: 70 },
      midfielder_1: { x: 30, y: 55 },
      midfielder_2: { x: 70, y: 55 },
      midfielder_3: { x: 15, y: 35 },
      midfielder_4: { x: 85, y: 35 },
      forward_1: { x: 30, y: 10 },
      forward_2: { x: 70, y: 10 },
    },
    '4-5-1': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 30, y: 80 },
      defender_2: { x: 70, y: 80 },
      defender_3: { x: 0, y: 70 },
      defender_4: { x: 100, y: 70 },
      midfielder_1: { x: 50, y: 60 },
      midfielder_2: { x: 25, y: 45 },
      midfielder_3: { x: 75, y: 45 },
      midfielder_4: { x: 0, y: 30 },
      midfielder_5: { x: 100, y: 30 },
      forward_1: { x: 50, y: 10 },
    },
    '5-2-3': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 0, y: 70 },
      defender_2: { x: 25, y: 75 },
      defender_3: { x: 50, y: 80 },
      defender_4: { x: 75, y: 75 },
      defender_5: { x: 100, y: 70 },
      midfielder_1: { x: 30, y: 45 },
      midfielder_2: { x: 70, y: 45 },
      forward_1: { x: 10, y: 15 },
      forward_2: { x: 50, y: 10 },
      forward_3: { x: 90, y: 15 },
    },
    '5-3-2': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 0, y: 70 },
      defender_2: { x: 25, y: 75 },
      defender_3: { x: 50, y: 80 },
      defender_4: { x: 75, y: 75 },
      defender_5: { x: 100, y: 70 },
      midfielder_1: { x: 50, y: 55 },
      midfielder_2: { x: 25, y: 40 },
      midfielder_3: { x: 75, y: 40 },
      forward_1: { x: 30, y: 10 },
      forward_2: { x: 70, y: 10 },
    },
    '5-4-1': {
      coach: { x: 0, y: 97 },
      goalkeeper: { x: 50, y: 97 },
      defender_1: { x: 0, y: 70 },
      defender_2: { x: 25, y: 75 },
      defender_3: { x: 50, y: 80 },
      defender_4: { x: 75, y: 75 },
      defender_5: { x: 100, y: 70 },
      midfielder_1: { x: 30, y: 55 },
      midfielder_2: { x: 70, y: 55 },
      midfielder_3: { x: 10, y: 35 },
      midfielder_4: { x: 90, y: 35 },
      forward_1: { x: 50, y: 10 },
    },
  },
};
