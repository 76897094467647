import React from 'react';

import PlayerTeamsItem from './item';

const PlayerTeams = (props) => {
  const { teams = [], forceSize } = props;

  let size = 0.7;
  if (forceSize) size = forceSize;

  const items = [];

  const gameModes = ['single', 'club', 'national', 'manager'];

  gameModes.forEach((gameMode) => {
    const gameModeTeam = teams.find(team => team.mode_type === gameMode);
    if (gameModeTeam) items.push(<PlayerTeamsItem team={gameModeTeam} key={gameMode} size={size} />);
  });

  return (
    <div className="icons teams">
      {items.map(item => item)}
    </div>
  );
};

export default PlayerTeams;
