const getUserTeamByCompetition = (state) => {
  if (state.competitions.details.general && state.user.teams) {
    if (state.user.teams[state.competitions.details.general.mode_type]) {
      return state.user.teams[state.competitions.details.general.mode_type];
    }
  }

  return {};
};

export default {
  getUserTeamByCompetition,
};
