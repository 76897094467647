import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import moment from 'moment-timezone';

import MenuSecondary from '../../elements/menuSecondary';
import GameModeUtils from '../../../app/utils/gameMode';

import pageTitleService from '../../../app/services/pageTitle';

import TeamsStatisticsAchievements from './achievements/achievements';
import TeamsStatisticsScore from './score/score';
import TeamsStatisticsGames from './games/games';
import TeamsStatisticsStrikers from './strikers/strikers';
import TeamsStatisticsConfrontation from './confrontation/confrontation';
import TeamsStatisticsTop5 from './top5/top5';

import translator from '../../../data/translator';

export class TeamsStatistics extends React.Component {
  async componentDidMount() {
    pageTitleService.setTitle('Estatísticas');
  }

  render() {
    const {
      modeData, match, typesGames = [], typesCompetitions = [],
    } = this.props;
    const { url } = match;

    const pages = GameModeUtils.data[modeData.mode].statistics_pages;

    const menus = [];

    if (pages.indexOf('achievements') !== -1 && typesCompetitions.length > 0) {
      menus.push({
        name: 'Campeões',
        uri: `${url}/campeoes`,
        extra: '/:type/:interval/:page',
        component: TeamsStatisticsAchievements,
        menuNotExact: true,
      });
    }

    if (pages.indexOf('top5') !== -1) {
      menus.push({
        name: 'Top 5',
        uri: `${url}/top5`,
        extra: '/:interval',
        component: TeamsStatisticsTop5,
        menuNotExact: true,
      });
    }

    if (pages.indexOf('score') !== -1) {
      menus.push({
        name: 'Apostas',
        uri: `${url}/apostas`,
        extra: '/:interval/:page',
        component: TeamsStatisticsScore,
        menuNotExact: true,
      });
    }

    if (pages.indexOf('games') !== -1 && typesGames.length > 0) {
      menus.push({
        name: 'Jogos',
        uri: `${url}/jogos`,
        extra: '/:type/:interval/:page',
        component: TeamsStatisticsGames,
        menuNotExact: true,
      });
    }

    if (pages.indexOf('confrontation') !== -1) {
      menus.push({
        name: 'Confrontos', uri: `${url}/confrontos`, extra: '', component: TeamsStatisticsConfrontation,
      });
    }

    if (pages.indexOf('strikers') !== -1) {
      menus.push({
        name: 'Artilharia',
        uri: `${url}/artilharia`,
        extra: '/:interval/:page',
        component: TeamsStatisticsStrikers,
        menuNotExact: true,
      });
    }

    const year = moment().year();

    return (
      <div>
        <MenuSecondary menus={menus} tag="Estatísticas" />
        <Switch>
          {typesCompetitions.length > 0 && (
          <Redirect
            exact
            from={`${url}/campeoes`}
            to={`${url}/campeoes/${translator.uri.competitions[typesCompetitions[0]]}/${year}/1`}
          />
          )}
          <Redirect exact from={url} to={`${url}/campeoes`} />
          <Redirect exact from={`${url}/campeoes`} to={`${url}/campeoes/${year}/1`} />
          <Redirect exact from={`${url}/top5`} to={`${url}/top5/geral`} />
          <Redirect exact from={`${url}/apostas`} to={`${url}/apostas/${year}/1`} />
          {typesGames.length > 0 && (
          <Redirect
            exact
            from={`${url}/jogos`}
            to={`${url}/jogos/${translator.uri.competitions[typesGames[0]]}/${year}/1`}
          />
          )}
          <Redirect exact from={`${url}/artilharia`} to={`${url}/artilharia/${year}/1`} />
          {menus.map(menu => (
            <Route
              key={menu.name}
              path={menu.uri + menu.extra}
              component={p => <menu.component {...p} menus={menus} modeData={modeData} />}
            />
          ))}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  typesGames: state.settings.games && state.settings.games.types,
  typesCompetitions: state.settings.competitions && state.settings.competitions.types,
});

export default connect(mapStateToProps)(TeamsStatistics);
