import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../../elements/top5/top5Section';
import Top5Table from '../../../elements/top5/top5Table';
import Player from '../../../players/elements/player';

export class RecordsRoundsContentTop5 extends React.Component {
  createTable = (data, valueName, title, valueTxt, typeData) => {
    if (!data || !data.users) return (null);
    return (
      <Top5Table
        title={title}
        data={data.users}
        typeData={typeData}
        entity="user"
        component={Player}
        header={{ entity: 'Jogador', value: valueTxt }}
        valueName={valueName}
      />
    );
  }

  render() {
    const { statistics } = this.props;
    const { score, difficulty, hits } = statistics;
    const { simple, accurate, bonus } = hits;

    return (
      <Top5Section>
        <Grid fluid>
          <Row>
            <Col lg={4} md={6}>
              {this.createTable(score, 'score', 'Pontos', 'Pontos')}
            </Col>
            <Col lg={4} md={6}>
              {this.createTable(difficulty, 'difficulty', 'Dificuldade', 'Valor', 'average')}
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6}>
              {this.createTable(simple, 'simple', 'Acertos', 'Vezes')}
            </Col>
            <Col lg={4} md={6}>
              {this.createTable(accurate, 'accurate', 'Cravadas', 'Vezes')}
            </Col>
            <Col lg={4} md={6}>
              {this.createTable(bonus, 'bonus', 'Bônus', 'Vezes')}
            </Col>
          </Row>
        </Grid>
      </Top5Section>
    );
  }
}

export default RecordsRoundsContentTop5;
