import React from 'react';

import Colors from '../../../../../../data/colors';

import PlayersDetailsOverviewStatisticsBetsChartPie from './chartPie';

const PlayersDetailsOverviewStatisticsBetsRowPie = (props) => {
  const { position, types, high } = props;

  const colors = [Colors.layout.clouds, Colors.layout.silver, Colors.layout.concrete];

  return (
    <div className="row">
      <PlayersDetailsOverviewStatisticsBetsChartPie
        types={types}
        position={position}
        colors={colors}
      />
      <div className="types">
        <div className="info">
          <div className="label">
            <div className="square" style={{ background: colors[0] }} />
            {types.simple}
            {' Acertos'}
          </div>
        </div>
        <div className="info">
          <div className="label">
            <div className="square" style={{ background: colors[1] }} />
            {types.accurate}
            {' Cravadas'}
          </div>
        </div>
        <div className="info">
          <div className="label">
            <div className="square" style={{ background: colors[2] }} />
            {types.bonus}
            {' Bônus'}
          </div>
        </div>
      </div>
      <div className="high">
        <div className="value">
          {high}
        </div>
        <div className="label">
          <span>
            {'Maior'}
          </span>
          <br />
          {'Pontuação'}
        </div>
      </div>
    </div>
  );
};

export default PlayersDetailsOverviewStatisticsBetsRowPie;
