import React from 'react';
import { connect } from 'react-redux';

import { teamsConfrontationGet } from '../../../../../app/store/actions/teamsConfrontation';

import GameUtils from '../../../../../app/utils/games';

import TeamsStatisticsConfrontationContent from './content/content';

export class TeamsStatisticsConfrontationInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentWillReceiveProps(nextProps) {
    const { firstTeamId, secondTeamId } = this.state;
    const { firstTeam, secondTeam } = nextProps;
    if (firstTeam && secondTeam && (firstTeamId !== firstTeam.id || secondTeamId !== secondTeam.id)) {
      await this.setState({ firstTeamId: firstTeam.id, secondTeamId: secondTeam.id });
      const data = await this.props.getConfrontation(firstTeam.id, secondTeam.id);
      GameUtils.completeGamesControntation(data);
      await this.props.setData(data);
    }
  }

  reset = async () => {
    await this.props.reset();
    this.props.firstStep();
  }

  render() {
    const { data } = this.props;

    if (!data) return (null);

    return (
      <React.Fragment>
        <div className="content-container information">
          <button className="button normal back-button" type="button" onClick={this.reset}>
            {'Alterar Equipes'}
          </button>
        </div>
        <TeamsStatisticsConfrontationContent
          games={data.games}
          results={data.results}
          teamHome={data.team_home}
          teamAway={data.team_away}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getConfrontation: (firstTeamId, secondTeamId) => dispatch(
    teamsConfrontationGet(ownProps.mode, firstTeamId, secondTeamId),
  ),
});

const mapStateToProps = (state, ownProps) => ({
  teams: state.teams.list[ownProps.mode] || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatisticsConfrontationInformation);
