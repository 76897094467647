export default {
  plans: [
    {
      name: 'Mensal',
      days: 30,
      amount: 10,
      id: '5a49a9467d6a224a4052c5a8',
      features: [
        'Sugestão de Apostas',
        'Apostas Automáticas',
        'Estatísticas Exclusivas',
        'Modo Jornada',
      ],
      collectibles: 0,
    },
    {
      name: 'Trimestral',
      days: 90,
      amount: 25,
      fullAmount: 30,
      collectibles: 1,
      id: '5e0d80540312ab2a83e24d76',
      features: [
        'Sugestão de Apostas',
        'Apostas Automáticas',
        'Estatísticas Exclusivas',
        'Modo Jornada',
        'Modo Cartola',
        'Torneios Primes',
        'Baú com um Escudo',
      ],
    },
    {
      name: 'Anual',
      days: 365,
      amount: 75,
      fullAmount: 120,
      featured: true,
      collectibles: 5,
      id: '5a49a9467d6a224a4052c5aa',
      features: [
        'Sugestão de Apostas',
        'Apostas Automáticas',
        'Estatísticas Exclusivas',
        'Modo Jornada',
        'Modo Cartola',
        'Torneios Primes',
        '5 Baús com Escudos',
      ],
    },
  ],
};
