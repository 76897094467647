import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsCompetitionsListClosedItem from './teamsCompetitionsListClosedItem';

export const teamsCompetitionsListClosed = (props) => {
  const { competitions } = props;

  if (competitions.length === 0) return <div className="competitions-empty">Sem campeonatos no momento</div>;

  return (
    <Grid fluid className="competitions-closed">
      <Row>
        {competitions.map(competition => (
          <Col sm={6} md={4} lg={3} key={competition.id}>
            <TeamsCompetitionsListClosedItem competition={competition} />
          </Col>
        ))}
      </Row>
    </Grid>
  );
};

export default teamsCompetitionsListClosed;
