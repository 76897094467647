import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import MultipleCarousel from '../../multipleCarousel';

import GamesHighlight from '../highlight/highlight';
import GamesHighlightCampaign from '../highlight/highlightCampaign';

const GamesCalendar = ({ games = [], currentRoundNumber }) => {
  games.sort((a, b) => {
    if (a.round !== b.round) {
      return (a.round > b.round) ? 1 : -1;
    }
    if (a.competition.competition.id && b.competition.competition.id) {
      if (a.type === 'official' && b.type === 'official') {
        return (a.score_home + a.score_away < b.score_home + b.score_away) ? 1 : -1;
      }
      if (a.type !== 'official') return 1;
      if (b.type !== 'official') return -1;

      return (a.score_home + a.score_away < b.score_home + b.score_away) ? 1 : -1;
    }
    if (!a.competition.competition.id) return 1;
    if (!b.competition.competition.id) return -1;
    return (a.score_home + a.score_away < b.score_home + b.score_away) ? 1 : -1;
  });

  if (games.length === 0) {
    return (null);
  }


  if (games.length === 1) {
    return (
      <section className="games-calendar">
        <div className="content-container one-game">
          <Grid fluid>
            <Row center="lg">
              <Col lg={6}>
                {games[0].mode_type === 'campaign'
                  ? <GamesHighlightCampaign game={games[0]} />
                  : <GamesHighlight game={games[0]} />
                }
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    );
  }

  let startIndex = 0;
  if (currentRoundNumber) startIndex = games.filter(g => g.round < currentRoundNumber).length;

  return (
    <section className="games-calendar">
      <div
        className={`content-container carousel
          ${(games.length > 2) ? ' indicator-2' : ''}
          ${(games.length > 1) ? ' indicator-1' : ''}
        `}
      >
        <MultipleCarousel
          items={games.map(game => (
            game.mode_type === 'campaign'
              ? <GamesHighlightCampaign game={game} />
              : <GamesHighlight game={game} />))}
          xs={1}
          sm={1}
          md={2}
          lg={2}
          indicator
          skipPage
          start={startIndex}
        />
      </div>
    </section>
  );
};

export default GamesCalendar;
