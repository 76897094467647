import React from 'react';
import moment from 'moment-timezone';

const PlayersDetailsHeaderInfo = (props) => {
  const {
    registrationDate,
    lastAuthDate,
    firstName,
    gender,
    city,
    singleTeam,
    clubTeam,
    nationalTeam,
    managerTeam,
    realTeam,
    icons,
  } = props;

  return (
    <div className="info">
      <div className="item">
        <div className="title">
          {'Nome'}
        </div>
        <div className="value">
          {firstName}
        </div>
      </div>
      {realTeam && (
        <div className="item">
          <div className="title">
            {'Time do coração'}
          </div>
          <div className="value">
            {realTeam}
          </div>
        </div>
      )}
      {city && (
        <div className="item">
          <div className="title">
            {'Cidade'}
          </div>
          <div className="value">
            {city}
          </div>
        </div>
      )}
      <div className="item">
        <div className="title">
          {'Gênero'}
        </div>
        <div className="value">
          {gender === 'male' ? 'Masculino' : 'Feminino'}
        </div>
      </div>
      <div className="item">
        <div className="title">
          {'Último acesso'}
        </div>
        <div className="value">
          {moment(lastAuthDate).format('L H:mm')}
        </div>
      </div>
      <div className="item">
        <div className="title">
          {'Cadastro'}
        </div>
        <div className="value">
          {moment(registrationDate).format('LL')}
        </div>
      </div>
      {singleTeam && (
        <div className="item">
          <div className="title">
            {'Fantasia'}
          </div>
          <div className="value">
            {singleTeam}
          </div>
        </div>
      )}
      {clubTeam && (
        <div className="item">
          <div className="title">
            {'Clube Real'}
          </div>
          <div className="value">
            {clubTeam}
          </div>
        </div>
      )}
      {nationalTeam && (
        <div className="item">
          <div className="title">
            {'Seleção'}
          </div>
          <div className="value">
            {nationalTeam}
          </div>
        </div>
      )}
      {managerTeam && (
        <div className="item">
          <div className="title">
            {'Cartola'}
          </div>
          <div className="value">
            {managerTeam}
          </div>
        </div>
      )}
      <div className="item show-for-desktop">
        <div className="title">
          {'Marcas'}
        </div>
        <div className="value">
          {icons}
        </div>
      </div>
    </div>
  );
};

export default PlayersDetailsHeaderInfo;
