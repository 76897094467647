import React from 'react';

import Steps from '../../elements/steps/steps';

import TutorialAccountWelcome from './welcome';
import TutorialAccountPlayer from './player';
import TutorialAccountFavoriteClub from './favoriteClub';
import TutorialAccountBets from './bets';

const TutorialAccount = () => (
  <Steps>
    <TutorialAccountWelcome />
    <TutorialAccountPlayer />
    <TutorialAccountFavoriteClub />
    <TutorialAccountBets />
  </Steps>
);

export default TutorialAccount;
