function isInvalidToken(error) {
  return error && error.error && error.error.code === 40003;
}

function inMaintenance(error) {
  return error && error.error && error.error.code === 30001;
}

export default {
  isInvalidToken,
  inMaintenance,
};
