import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';

import SoccerField from '../../../../../elements/soccerField';

export const TeamsDetailsManagementEscalationFormationField = (props) => {
  const { formation } = props;

  const getPlayer = (position) => {
    const { players } = props;

    const player = players.find(p => p.position === position);

    if (player) {
      return (
        <React.Fragment>
          <div className="mark">
            <div className="value">
              {player.user.valuation.value && player.user.valuation.value.toFixed(2)}
            </div>
          </div>
          <button
            className="button player"
            type="button"
            onClick={() => props.selectPosition(position)}
          >
            <img src={player.user.picture.url} alt={player.user.username} />
            <FontAwesomeIcon icon={faSync} />
          </button>
        </React.Fragment>
      );
    }

    return (
      <button
        className="button plus-add"
        type="button"
        onClick={() => props.selectPosition(position)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    );
  };

  const positions = Object.keys(formation);

  const players = {};
  positions.forEach((position) => {
    players[position] = getPlayer(position);
  });

  return (
    <div className="formation">
      <SoccerField
        formation={formation}
        players={players}
      />
    </div>
  );
};

export default TeamsDetailsManagementEscalationFormationField;
