import RestService from '../services/rest';

async function setPresident(teamId, userId) {
  return RestService.postAuthenticated('teams/president', {
    team: teamId,
    user: userId,
  });
}

async function unsetPresident(teamId) {
  return RestService.postAuthenticated('teams/president/remove', {
    team: teamId,
  });
}

async function setRole(teamId, role, userId) {
  return RestService.postAuthenticated('teams/role', {
    team: teamId,
    user: userId,
    role,
  });
}

async function unsetRole(teamId, role) {
  return RestService.postAuthenticated('teams/role/remove', {
    team: teamId,
    role,
  });
}

export default {
  setPresident,
  unsetPresident,
  setRole,
  unsetRole,
};
