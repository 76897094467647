import NoticesRequests from '../../server/notices';
import { addLoading, removeLoading } from './loading';

import NoticesData from '../../../data/notices';

export const noticesGetList = page => async (dispatch) => {
  const limit = NoticesData.perPage;
  const skip = ((page - 1) * limit);

  dispatch(addLoading());
  try {
    const data = await NoticesRequests.getNotices(skip, limit);
    return data;
  } finally {
    dispatch(removeLoading());
  }
};


export default noticesGetList;
