const initialState = {
  general: {},
  club: {},
  national: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'WALLS_MESSAGES_SET':
    case 'WALLS_MESSAGES_FIRST_ADD':
    case 'WALLS_MESSAGES_LAST_ADD': {
      const wall = { ...state[action.mode] };
      action.messages.forEach((message) => { wall[message.id] = message; });
      return {
        ...state,
        [action.mode]: { ...wall },
      };
    }
    case 'WALLS_MESSAGES_CLEAN':
      state[action.mode] = {};
      return state;
    case 'WALLS_MESSAGES_REMOVE':
      delete state[action.mode][action.messageId];
      return {
        ...state,
        [action.mode]: { ...state[action.mode] },
      };
    default:
      return state;
  }
};
