export default {
  phrases: [{
    texts: [
      'Olha a chance do Tricolor, Raí na batida de falta.',
      'Capricha garotinho, capricha.',
      'Rolou pra Cafú, pra Raí, pro gol.',
      'Eeeeeeeee que GOOOOOOOOOL!',
    ],
    highlight: ['Tricolor', 'Raí', 'Cafú', 'GOOOOOOOOOL'],
    author: 'Osmar Santos',
  }, {
    texts: [
      'Pode até empatar!',
      'Quem sabe agora?',
      'Capricha Adriano!',
      'Olha o empate! GOOOOOOOOOL!',
    ],
    highlight: ['Adriano', 'GOOOOOOOOOL'],
    author: 'Galvão Bueno',
  }, {
    texts: [
      '43, faltando 2 pra acabar, PETKOVIC na falta prepara.',
      'Atenção!',
      'Pet para bola, bateu, guardou!',
      'É campeão! É TRIIIIII',
    ],
    highlight: ['PETKOVIC', 'Pet', 'TRIIIIII'],
    author: 'Luis Roberto',
  }, {
    texts: [
      'Cafú já partiu na velocidade.',
      'Ele cortou pro meio.',
      'Lá vem o Kleberson.',
      'Rivaldo saiu, pro Ronaldinho.',
      'Pé direito, bateu! GOOOOOOOL!',
    ],
    highlight: ['Cafú', 'Kleberson', 'Rivaldo', 'Ronaldinho', 'GOOOOOOOL'],
    author: 'Galvão Bueno',
  }],
};
