import React from 'react';
import { connect } from 'react-redux';

import TutorialWrapper from '../elements/tutorial/tutorial';

import ValidatorHelper from '../../app/helpers/validator';

import { showAlertError } from '../../app/store/actions/alert';

export class RegistrationAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      username: '',
      password: '',
      cpf: '',
      phone: '',
      confirmEmail: '',
      confirmPassword: '',
      error: {
        email: '',
        username: '',
        password: '',
        cpf: '',
        phone: '',
      },
    };
  }

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email, error: this.cleanError('email') }));
  };

  onConfirmEmailChange = (e) => {
    const confirmEmail = e.target.value;
    this.setState(() => ({ confirmEmail, error: this.cleanError('email') }));
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password, error: this.cleanError('password') }));
  };

  onConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState(() => ({ confirmPassword, error: this.cleanError('password') }));
  };

  onUsernameChange = (e) => {
    const regex = /^[a-zA-Z0-9._-]+$/g;
    const username = e.target.value;
    if (username === '' || regex.test(username)) {
      this.setState(() => ({ username, error: this.cleanError('username') }));
    }
  };

  onCpfChange = (e) => {
    const cpfStr = e.target.value;
    let cpf = cpfStr.replace(/\D/g, '').slice(0, 11);

    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    this.setState(() => ({ cpf, error: this.cleanError('cpf') }));
  };

  onPhoneChange = (e) => {
    const phoneStr = e.target.value;

    let phone = phoneStr.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,5})/);
    phone = !phone[2] ? phone[1] : `(${phone[1]}) ${phone[2]}${phone[3] ? `-${phone[3]}` : ''}`;

    this.setState(() => ({ phone, error: this.cleanError('phone') }));
  };

  onSubmit = async (e) => {
    const {
      error, email, username, password, cpf, phone, confirmEmail, confirmPassword,
    } = this.state;
    const { minUsername, minPassword } = this.props;

    e.preventDefault();

    if (password.length < minPassword) {
      error.password = `A senha deve conter pelo menos ${minPassword} caracteres. `;
    } else if (password !== confirmPassword) {
      error.password = 'Senhas não são iguais. ';
    }

    if (!ValidatorHelper.isEmail(email)) {
      error.email = 'Digite um e-mail válido.';
    } else if (email !== confirmEmail) {
      error.email = 'Os e-mails não são iguais. ';
    }

    if (username.length < minUsername) {
      error.username = `O nome do usuário deve conter pelo menos ${minUsername} caracteres. `;
    }

    const cpfNumber = cpf.replace(/\D/g, '');
    if (!ValidatorHelper.isCpf(cpfNumber)) {
      error.cpf = 'Digite um CPF válido. ';
    }

    const phoneNumber = phone.replace(/\D/g, '');
    if (phoneNumber.length < 10) {
      error.phone = 'Digite um telefone válido. ';
    }

    await this.setState(() => ({ error }));

    if (!this.hasError()) {
      const err = await this.props.saveAccountData({
        email,
        username,
        password,
        cpf: cpfNumber,
        phone: phoneNumber,
      });

      if (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { code } = err.response.data.error;
          switch (code) {
            case 50203:
              error.email = 'E-mail já utilizado.';
              break;
            case 50204:
              error.username = 'Nome de usuário já utilizado.';
              break;
            case 50207:
              error.username = 'Inválido nome de usuário.';
              break;
            case 50223:
              error.cpf = 'CPF já utilizado.';
              break;
            case 50224:
              error.phone = 'Telefone já utilizado.';
              break;
            default:
            // TODO General error
          }
        }
      } else {
        this.props.nextStep();
      }
    }

    if (this.hasError()) {
      this.props.alertError(error.email || error.password || error.username || error.cpf || error.phone);
    }

    await this.setState(() => ({ error }));
  };

  hasError = () => {
    const { error } = this.state;
    return Object.keys(error).filter(field => error[field] !== '').length > 0
    || Object.keys(this.state).filter(field => this.state[field] === '').length > 0;
  };

  cleanError = (field) => {
    const { error } = this.state;
    error[field] = '';
    return error;
  };

  render() {
    const {
      error, email, username, password, cpf, phone, confirmEmail, confirmPassword,
    } = this.state;
    const { maxUsername } = this.props;

    return (
      <TutorialWrapper image="registration/image_2.jpg">
        <form onSubmit={this.onSubmit}>
          <h3 className="title-center">
            {'Conta'}
          </h3>
          <p>
            {'Para realizar o cadastro é necessário que você forneça as seguintes informações:'}
          </p>
          <div className="section">
            <h4>
              {'Autenticação'}
            </h4>
            <div className="section-content">
              <input
                type="email"
                placeholder="Digite seu email"
                value={email}
                onChange={this.onEmailChange}
              />
              <input
                type="email"
                placeholder="Digite novamente seu email"
                value={confirmEmail}
                onChange={this.onConfirmEmailChange}
              />
              <div className="error">
                {error.email}
              </div>
            </div>
            <div className="section-content">
              <input
                type="password"
                placeholder="Digite sua nova senha"
                value={password}
                onChange={this.onPasswordChange}
              />
              <input
                type="password"
                placeholder="Digite novamente sua nova senha"
                value={confirmPassword}
                onChange={this.onConfirmPasswordChange}
              />
              <div className="error">
                {error.password}
              </div>
            </div>
          </div>

          <div className="section">
            <h4>
              {'Nome do Jogador'}
            </h4>
            <p>
              {'Esse é o nome pelo qual você será conhecido no site.'}
            </p>
            <div className="section-content">
              <input
                type="text"
                placeholder="Digite seu nome de usuário"
                value={username}
                maxLength={maxUsername}
                onChange={this.onUsernameChange}
              />
              <div className="tip">
                {'Apenas letras, número, ".", "_" e "-". '}
              </div>
              <div className="error">
                {error.username}
              </div>
            </div>
          </div>
          <div className="section">
            <h4>
              {'Controle de Fakes'}
            </h4>
            <p>
              {'Esta exigência serve para evitar que determinados usuários executem cadastro em duplicidade, '
             + ' o que é terminantemente proibido no site. Esses dados não serão mostrados em nenhum momento.'}
            </p>
            <div className="section-content">
              <input
                type="text"
                placeholder="Digite seu cpf"
                value={cpf}
                onChange={this.onCpfChange}
              />
              <input
                type="text"
                placeholder="Digite seu telefone"
                value={phone}
                onChange={this.onPhoneChange}
              />
              <div className="error">
                {error.cpf}
                {error.phone}
              </div>
            </div>
          </div>
          <button className="button normal" type="button" onClick={this.props.previousStep}>
            {'Voltar'}
          </button>
          <button className="button normal" type="submit" disabled={this.hasError()}>
            {'Próximo'}
          </button>
        </form>
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  alertError: message => dispatch(showAlertError(message)),
});

const mapStateToProps = state => ({
  minUsername: state.settings.users && state.settings.users.username && state.settings.users.username.min,
  maxUsername: state.settings.users && state.settings.users.username && state.settings.users.username.max,
  minPassword: state.settings.users && state.settings.users.password && state.settings.users.password.min,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationAccountForm);
