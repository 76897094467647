import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCircle } from '@fortawesome/free-solid-svg-icons';

import DimensionsData from '../../data/dimensions';

export class MultipleCarousel extends React.Component {
  constructor(props) {
    super(props);

    let state = {
      index: 0,
      previous: false,
      next: props.items && props.items.length > 1,
      visibles: 1,
    };

    if (props.start !== undefined) {
      state = {
        ...state,
        ...this.updateState(props.start, 1),
      };
    }

    this.state = state;
  }


  async componentDidMount() {
    this.setItemsVisibles();
    window.addEventListener('resize', this.setItemsVisibles);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setItemsVisibles);
  }

  setItemsVisibles = async () => {
    const { index, visibles } = this.state;
    const {
      xs = 1, sm = 2, md = 4, lg = 6,
    } = this.props;

    const pageWidth = window.innerWidth;

    let newVisibles = lg;
    if (pageWidth < DimensionsData.xs) newVisibles = xs;
    else if (pageWidth < DimensionsData.sm) newVisibles = sm;
    else if (pageWidth < DimensionsData.md) newVisibles = md;

    if (newVisibles !== visibles) {
      await this.setState(() => ({ visibles: newVisibles, ...this.updateState(index, newVisibles) }));
    }
  }

  previousItem = async () => {
    const { index, visibles } = this.state;
    const { skipPage } = this.props;
    const total = skipPage ? visibles : 1;
    await this.setState(() => ({ ...this.updateState(index - total, visibles) }));
  }

  nextItem = async () => {
    const { index, visibles } = this.state;
    const { skipPage } = this.props;
    const total = skipPage ? visibles : 1;
    await this.setState(() => ({ ...this.updateState(index + total, visibles) }));
  }

  indicatorItem = async (index) => {
    const { visibles } = this.state;
    await this.setState(() => ({ ...this.updateState(index, visibles) }));
  }

  updateState = (index, visibles) => {
    const { items = [], skipPage } = this.props;

    if (skipPage) index = visibles * Math.floor(index / visibles);

    index = Math.max(Math.min(index, items.length - visibles), 0);

    return {
      previous: index !== 0,
      next: index < items.length - visibles,
      index,
    };
  }

  handleTouchStart = (event) => {
    this.hasMoved = false;
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
  }

  handleTouchMove = (event) => {
    if (!this.hasMoved) {
      const moveDistance = 40;
      const moveX = event.touches[0].clientX;
      const moveY = event.touches[0].clientY;
      if (Math.abs(this.startX - moveX) > Math.abs(this.startY - moveY) * 2) {
        if (this.startX > moveX + moveDistance) {
          this.nextItem();
          this.hasMoved = true;
        } else if (this.startX < moveX - moveDistance) {
          this.previousItem();
          this.hasMoved = true;
        }
      }
    }
  }

  render() {
    const {
      previous, next, index, visibles,
    } = this.state;
    const {
      items = [], skipPage = false, indicator = false, arrows = false,
    } = this.props;

    const itemWidth = 100 / visibles;

    const needIndicator = (previous || next) && indicator;
    const needArrows = (previous || next) && arrows;

    const actionStyle = {
      height: needIndicator && 'calc(100% - 5rem)',
    };

    const cItems = items.map((element, key) => ({ key, element }));
    const indicators = [];

    const skipTotal = skipPage ? Math.ceil(items.length / visibles) : (items.length - visibles) + 1;
    const skipAdd = skipPage ? visibles : 1;

    for (let i = 0; i < skipTotal; i += 1) indicators.push(i * skipAdd);

    return (
      <div className="multiple-carrossel">
        {needArrows && (
          <div className="button-action" style={{ ...actionStyle }}>
            <button type="button" className="button" disabled={!previous} onClick={this.previousItem}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </button>
          </div>
        )}
        <div
          className={`items${needArrows ? ' btn_actived' : ''}`}
          style={{ ...actionStyle }}
        >
          <div
            className="inner"
            style={{
              width: `${itemWidth * items.length}%`,
              left: `-${itemWidth * index}%`,
            }}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
          >
            {cItems.map(item => (
              <div
                key={item.key}
                className="item"
                style={{
                  width: `${100 / items.length}%`,
                }}
              >
                {item.element}
              </div>
            ))}
          </div>
        </div>
        {needArrows && (
          <div className="button-action" style={{ ...actionStyle }}>
            <button type="button" className="button" disabled={!next} onClick={this.nextItem}>
              <FontAwesomeIcon icon={faCaretRight} />
            </button>
          </div>
        )}
        {needIndicator && (
          <div className="indicator">
            {indicators.map(itemIndex => (
              <span
                onClick={() => this.indicatorItem(itemIndex)}
                onKeyDown={() => this.indicatorItem(itemIndex)}
                role="presentation"
                key={itemIndex}
                className={`${
                  (index <= itemIndex && index >= (skipPage ? itemIndex - (visibles - 1) : itemIndex))
                    ? ' active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faCircle} />
              </span>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default MultipleCarousel;
