import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MenuSecondary from '../elements/menuSecondary';

import GuideGeneral from './general/general';
import GuidePlayer from './player/player';
import GuideSingle from './single/single';
import GuideClub from './club/club';
import GuideNational from './national/national';
import GuideManager from './manager/manager';
import GuideCampaign from './campaign/campaign';

export const GuidePage = () => {
  const menus = [
    { name: 'Barbolão', uri: '/regulamento/geral', component: GuideGeneral },
    { name: 'Jogador', uri: '/regulamento/jogador', component: GuidePlayer },
    { name: 'Fantasia', uri: '/regulamento/fantasia', component: GuideSingle },
    { name: 'Clube Real', uri: '/regulamento/clube-real', component: GuideClub },
    { name: 'Seleção', uri: '/regulamento/selecao', component: GuideNational },
    { name: 'Cartola', uri: '/regulamento/cartola', component: GuideManager },
    { name: 'Jornada', uri: '/regulamento/jornada', component: GuideCampaign },
  ];

  return (
    <React.Fragment>
      <MenuSecondary menus={menus} tag="Regulamento" />
      <Switch>
        <Redirect exact from="/regulamento" to={menus[0].uri} />
        {menus.map(menu => (
          <Route
            key={menu.name}
            path={menu.uri}
            component={menu.component}
          />
        ))}
      </Switch>
    </React.Fragment>
  );
};

export default GuidePage;
