import InformationsRequests from '../../server/informations';

export const setStatistics = data => ({
  type: 'STATISTICS_SET',
  data,
});

export const statisticsGet = () => async (dispatch) => {
  const data = await InformationsRequests.getStatistics();
  dispatch(setStatistics(data));
};
