import React from 'react';

export class Iframe extends React.Component {
  constructor() {
    super();
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    this.updateIframe();
  }

  componentDidUpdate() {
    this.updateIframe();
  }

  updateIframe() {
    const { content = '', stylesheets = [] } = this.props;
    const iframe = this.iframeRef;
    const document = iframe.contentDocument;
    const head = document.getElementsByTagName('head')[0];
    document.body.innerHTML = content;

    stylesheets.forEach((url) => {
      const ref = document.createElement('link');
      ref.rel = 'stylesheet';
      ref.type = 'text/css';
      ref.href = url;
      head.appendChild(ref);
    });
  }

  render() {
    return <iframe ref={(ref) => { this.iframeRef = ref; }} height="100%" title="Iframe" />;
  }
}

export default Iframe;
