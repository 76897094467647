import React from 'react';

export const GuideCampaignGeneral = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'O Modo Jornada consiste em confrontos de equipes formadas de 1 a 4 jogadores '
        + 'enfrentando equipes aleatórias do site dependendo da competição. Ou seja, não '
        + 'haverá confrontos entre equipes formadas por jogadores do site.'}
        </p>
        <p>
          {'O fato de jogar com mais jogadores trará uma pequena vantagem para a equipe. '
        + 'No entanto, os objetivos serão distintos, ou seja, com 1 jogador haverá um objetivo, '
        + 'com 2 jogadores haverá um objetivo maior, e assim sucessivamente.'}
        </p>
        <p>
          {'Primeiramente você terá que conquistar a '}
          <b>Série D</b>
          {', que é pré-requisito para todas as demais Ligas. Em seguida o clube pelo '
        + 'qual você quer levar à glória. Cada Liga tem sua peculiaridade, podendo ser pontos corridos, '
        + 'mata-mata ou a mescla dos dois, para tentar ao máximo se assemelhar aos torneios reais.'}
        </p>
        <p>
          {'Após passar pela Série D, você deverá escolher a próxima Liga que deseja conquistar. '
        + 'À medida que os objetivos forem sendo alcançados, você poderá buscar objetivos maiores.'}
        </p>
        <p className="text">
          {'Cabe observar que não é o clube que atinge os objetivos, e sim os jogadores do '
        + 'clube. Assim, se um clube composto por 4 jogadores atingir o objetivo, ele será '
        + 'desfeito após a jornada e seus jogadores estarão aptos para a jornada posterior, '
        + 'seja individualmente ou coletivamente.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Pontuação'}
      </h4>
      <div className="section-content">
        <p>
          {'Cada rodada você irá enfrentar uma equipe, e terá dois objetivos de pontuação, um para '}
          <b>empatar</b>
          {' e outro para '}
          <b>vencer</b>
          {' a partida. A pontuação é determinada pela dificuldade da Jornada.'}
        </p>
        <p>
          {'A '}
          <b>Pontuação Final</b>
          {' será a soma das apostas de todos os jogadores participantes na rodada.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Capitão'}
      </h4>
      <div className="section-content">
        <p>
          {'O '}
          <b>Capitão</b>
          {' é o criador da '}
          <b>Jornada</b>
          {'. Ele terá as seguintes atribuições:'}
        </p>
        <ul className="list">
          <li>
            <b>
              {'Enviar convites'}
            </b>
            {' para jogadores aptos a participarem. Não será possível '
            + 'convidar depois de ter iniciado a Jornada.'}
          </li>
          <li>
            <b>
              {'Iniciar a Jornada'}
            </b>
            {' quando já tiver sido definido os jogadores, sendo que o máximo são 4.'}
          </li>
          <li>
            <b>
              {'Abandonar '}
            </b>
            {'a qualquer momento a Jornada, por julgar que não será mais possível conquistá-la.'}
          </li>
        </ul>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Conquista'}
      </h4>
      <div className="section-content">
        <p>
          {'Para conquistar uma jornada será preciso vencer todas as fases dela. '}
          {'As fases podem ter as seguintes características:'}
        </p>
        <ul className="list">
          <li>
            <b>
              {'Pontos Corridos: '}
            </b>
            {'O clube terá uma meta de pontuação para atingir e conquistar a '
            + 'Jornada ou passar para a próxima fase. A vitória vale 3 pontos e o empate '
            + 'vale 1 ponto.'}
          </li>
          <li>
            <b>
              {'Mata-Mata: '}
            </b>
            {'Para cada fase do mata-mata, o clube terá que ser superior no confronto, podendo ter um ou dois jogos. '
            + 'Caso empate o confronto, o que determinará o avanço da equipe será o número de cravadas dos '
            + 'integrantes, ou seja, a equipe deverá ter, no mínimo, tantas cravadas quanto forem o número '
            + 'de jogadores, independentemente de quem tenha feito as cravadas.'}
          </li>
        </ul>
      </div>
    </div>
  </React.Fragment>
);
export default GuideCampaignGeneral;
