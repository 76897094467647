import React from 'react';

import ReputationUtils from '../../../../../app/utils/reputation';
import PlayersUtils from '../../../../../app/utils/players';
import TeamsUtils from '../../../../../app/utils/teams';

import BadgeListItem from '../../../../elements/badgeList/badgeList';

import translator from '../../../../../data/translator';

export const TeamsDetailsOverviewPlayersRoles = (props) => {
  const { team = {} } = props;
  const {
    user, president, coach, manager, marketer,
  } = team;

  const users = [];
  if (user) users.push({ user, tag: translator.s.roles.user });
  if (president) users.push({ user: president, tag: translator.s.roles.president });
  if (coach) users.push({ user: coach, tag: translator.s.roles.coach });
  if (manager) users.push({ user: manager, tag: translator.s.roles.manager });
  if (marketer) users.push({ user: marketer, tag: translator.s.roles.marketer });

  const items = users.map((userItem) => {
    const reputation = ReputationUtils.getReputation(userItem.user.level.value);

    let link;
    if (userItem.user.status !== 'excluded') link = PlayersUtils.getLink(userItem.user.username);

    return {
      id: userItem.tag,
      color: reputation.color,
      name: userItem.user.username,
      tag: {
        name: userItem.tag,
        color: TeamsUtils.getBackgroundColor(team),
        nameHover: reputation.name,
        colorHover: reputation.color,
      },
      badge: userItem.user.picture.url,
      badgeClass: 'player',
      locked: false,
      link,
    };
  });

  return (
    <section className="roles">
      <div className="content-container">
        <BadgeListItem
          items={items}
          gridInfo={{
            lg: 3, md: 3, sm: 4, xs: 6,
          }}
          around
        />
      </div>
    </section>
  );
};

export default TeamsDetailsOverviewPlayersRoles;
