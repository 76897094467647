import React from 'react';

import translator from '../../../data/translator';

import { history } from '../../../routers/appRouter';

export class RankingButtons extends React.Component {
  handleChangeType = (e) => {
    const { value: type } = e.target;

    const { match } = this.props;
    const { path, params } = match;

    let url = path.replace(':type', type).replace(':page', 1);

    Object.keys(params).forEach((paramKey) => {
      url = url.replace(`:${paramKey}`, params[paramKey]);
    });

    history.push(url);
  }

  render() {
    const { types, match } = this.props;
    const { params } = match;

    return (
      <div className="filter-select types">
        <select value={params.type} onChange={this.handleChangeType}>
          {types.map(type => (
            <option key={type} value={translator.uri.competitions[type]}>
              {translator.p.competitions[type]}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default RankingButtons;
