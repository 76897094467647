import React from 'react';

const SoccerField = (props) => {
  const { formation, players = {} } = props;

  const lines = [];

  const positions = Object.keys(formation);

  positions.forEach((position) => {
    const data = formation[position];
    if (!lines[data.line - 1]) lines[data.line - 1] = { line: data.line, positions: [] };
    lines[data.line - 1].positions.push({ position, col: data.pos });
  });

  return (
    <div className="soccer-field">
      <div
        className="positions"
        style={{ height: '100%' }}
      >
        {Object.keys(formation).map(position => (
          <div
            key={position}
            className="position"
            style={{
              left: `${formation[position].x * 0.75}%`,
              top: `calc(${formation[position].y}% + ${(50 - formation[position].y) * 0.05}rem)`,
            }}
          >
            {players[position]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SoccerField;
