import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../elements/top5/top5Section';

const RecordsTop5Discard = (props) => {
  const { value, high } = props.data;

  return (
    <Top5Section title="Descarte">
      <Grid fluid>
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(value, 'Pontuação Atual', 'Pontos')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(high, 'Maior Pontuação', 'Pontos')}
          </Col>
        </Row>
      </Grid>
    </Top5Section>
  );
};

export default RecordsTop5Discard;
