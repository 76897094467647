import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { playersStartPlayer, playersGetPlayer } from '../../../app/store/actions/players';

import pageTitleService from '../../../app/services/pageTitle';
import UsersUtils from '../../../app/utils/users';

import MenuSecondary from '../../elements/menuSecondary';

import PlayersDetailsHeader from './header/playersDetailsHeader';

import PlayersDetailsOverview from './overview/playersDetailsOverview';
import PlayersDetailsBets from './bets/playersDetailsBets';
import PlayersDetailsBetsSelected from './bets/playersDetailsBetsSelected';
import PlayersDetailsAchievements from './achievements/playersDetailsAchievements';
// import PlayersDetailsStatistics from './statistics/playersDetailsStatistics';
import PlayersDetailsAdministration from './administration/playersDetailsAdministration';


import { history, scrollTo } from '../../../routers/appRouter';

export class playersDetails extends React.Component {
  constructor(props) {
    super(props);

    const pathnames = history.location.pathname.split('/');

    this.state = {
      isResume: pathnames.length < 4,
    };
  }

  async componentDidMount() {
    const { isResume } = this.state;
    const { player, username, authUserUsername } = this.props;

    pageTitleService.setTitle(username);

    if (!player) await this.props.startPlayer(username);
    else if (isResume) await this.props.getPlayer(username);

    if (isResume) {
      if (authUserUsername === username) scrollTo();
      else scrollTo('header-bottom');
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { isIdle, roundUpdated, username } = nextProps;

    if (this.props.roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
      await this.props.getPlayer(username);
    }
  }

  render() {
    const {
      numberClosed, player, url, permissions,
    } = this.props;

    const menus = [
      {
        name: 'Resumo', uri: url, component: PlayersDetailsOverview, exact: true,
      },
      {
        name: 'Apostas', uri: `${url}/apostas`, component: PlayersDetailsBetsSelected, exact: true, menuNotExact: true,
      },
      { name: 'Conquistas', uri: `${url}/conquistas`, component: PlayersDetailsAchievements },
      // { name: 'Estatísticas', uri: `${url}/estatisticas`, component: PlayersDetailsStatistics },
    ];

    if (UsersUtils.hasAccess(permissions, 'users')) {
      menus.push({
        name: 'Administração', uri: `${url}/administracao`, component: PlayersDetailsAdministration, exact: true,
      });
    }

    if (!(numberClosed && player)) return null;

    return (
      <React.Fragment>
        <PlayersDetailsHeader player={player} />
        <MenuSecondary menus={menus} tag={player && player.username} />
        <Switch>
          {menus.map(menu => (
            <Route
              key={menu.name}
              exact={menu.exact}
              path={menu.uri}
              component={menu.component}
            />
          ))}
          <Route
            path={`${url}/apostas/:round`}
            component={PlayersDetailsBets}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startPlayer: playerName => dispatch(playersStartPlayer(playerName)),
  getPlayer: playerName => dispatch(playersGetPlayer(playerName)),
});

const mapStateToProps = (state, ownProps) => {
  const { username } = ownProps.match.params;
  const { url } = ownProps.match;

  const player = (state.players.details && state.players.details.overview) || {};

  return {
    username,
    url,
    isIdle: state.activities.idle,
    player: player && player.username === username ? player : null,
    authUserUsername: state.user.username,
    numberClosed: state.rounds.numberClosed,
    roundUpdated: state.rounds.updateAt,
    permissions: state.user.permissions || [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(playersDetails);
