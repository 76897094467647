import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { liveStartResume, liveGetResume } from '../../app/store/actions/live';

import LiveGames from './liveGames';
import LiveBets from './liveBets';

export class LivePage extends React.Component {
  async componentDidMount() {
    const { resume } = this.props;

    if (!resume) await this.props.startResume();
    else await this.props.getResume();
  }

  async componentWillReceiveProps(nextProps) {
    const { isIdle, roundUpdated } = nextProps;

    if (roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
      await this.props.getResume();
    }
  }

  render() {
    const { resume } = this.props;

    return (
      <div className="player-bets">
        <div className="content-container margin-bottom ">
          <LiveBets />
          <Grid fluid className="rounds" style={{ padding: 0 }}>
            <Row>
              {resume && resume.games.map(gameModeGames => (
                <Col md={6} key={gameModeGames.mode_type}>
                  <LiveGames
                    gameMode={gameModeGames.mode_type}
                    games={gameModeGames.games}
                  />
                </Col>
              ))}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startResume: () => dispatch(liveStartResume()),
  getResume: () => dispatch(liveGetResume()),
});

const mapStateToProps = state => ({
  resume: state.live && state.live.resume,
  isIdle: state.activities.idle,
  roundUpdated: state.rounds.updateAt,
});

export default connect(mapStateToProps, mapDispatchToProps)(LivePage);
