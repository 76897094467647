import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import TeamsDetailsHeaderResign from './teamsDetailsHeaderResign';

import UsersSelector from '../../../../app/store/selectors/users';
import RoundsSelector from '../../../../app/store/selectors/rounds';

import TeamsUtils from '../../../../app/utils/teams';
import GameModeUtils from '../../../../app/utils/gameMode';

export class TeamsDetailsHeaderButtons extends React.Component {
  managementEnable() {
    const {
      mode, team, userModeTeams, isMaster,
    } = this.props;

    return GameModeUtils.hasAccess(mode, userModeTeams, team, {
      federationPermission: true,
      isMaster,
    });
  }

  render() {
    const {
      team, url, userModeTeams, mode, isClosedRound,
    } = this.props;

    const infoStyle = {};
    infoStyle.color = TeamsUtils.getBackgroundColor(team);

    const buttonStyle = {};
    buttonStyle.backgroundColor = TeamsUtils.getBackgroundColor(team);

    const marketIsOpen = userModeTeams.market && userModeTeams.market.is_open;
    const notifications = GameModeUtils.getNotifications(userModeTeams, team && team.id, isClosedRound, marketIsOpen);
    const hasNotification = notifications.friendly + notifications.resignRequest + notifications.formation > 0;

    return (
      <div className="buttons">
        {this.managementEnable() && (
        <Link to={`${url}/administrativo`} className="button" style={buttonStyle}>
          {'Administração'}
          {hasNotification && <FontAwesomeIcon icon={faExclamationCircle} />}
        </Link>
        )}

        <TeamsDetailsHeaderResign
          team={team}
          userModeTeams={userModeTeams}
          gameMode={mode}
          style={buttonStyle}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isMaster: UsersSelector.isMaster(state),
  isClosedRound: RoundsSelector.isClosedRound(state),
  userModeTeams: state.user.game_modes_details && state.user.game_modes_details[ownProps.mode],
});

export default connect(mapStateToProps)(TeamsDetailsHeaderButtons);
