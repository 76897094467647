import React from 'react';

import TableAuth from '../../elements/tableAuth';
import Player from '../../players/elements/player';

import PlayerTableValuation from '../../players/table/valuation';
import PlayerTableDiscard from '../../players/table/discard';
import PlayerTableBet from '../../players/table/bet';

export const RecordsReputationTable = (props) => {
  const { title, players } = props;

  const header = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'player', value: title, className: 'name', colspan: 2,
    },
    { key: 'discard', value: 'Descarte', className: 'number' },
    { key: 'valuation', value: 'Valor', className: 'number' },
    { key: 'bet', value: 'Aposta', className: 'number' },
  ];

  const data = players.map(player => ({
    key: player.id,
    position: player.level.position || '-',
    player: <Player data={player} type="table" />,
    discard: <PlayerTableDiscard discard={player.discard} />,
    valuation: <PlayerTableValuation valuation={player.valuation} />,
    bet: <PlayerTableBet bets={player.bets} />,
  }));

  return (<TableAuth header={header} lines={data} />);
};

export default RecordsReputationTable;
