import React from 'react';
import { Link } from 'react-router-dom';

import CompetitionsUtils from '../../../../app/utils/competitions';

const TeamsDetailsAchievementsTypeItem = (props) => {
  const { title = {} } = props;

  return (
    <div className="title">
      <div className="item">
        <Link key={title.competition.id} to={CompetitionsUtils.getLink(title.competition)}>
          <img src={title.competition.logo.url} alt={title.competition.name} />
          <div className="name">
            {title.competition.name}
            {' '}
            {title.competition.year}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default TeamsDetailsAchievementsTypeItem;
