export default {
  friendly: {
    total: 10,
  },
  confrontation: {
    total: 10,
  },
  escalation: {
    total: 16,
  },
  list: {
    league: {
      teamsPerPage: 24,
    },
  },
};
