import React from 'react';
import { connect } from 'react-redux';

import { scrollTo } from '../../../routers/appRouter';

import { roundsGetStatistics } from '../../../app/store/actions/rounds';

import RecordsRoundsContentGeneral from './general/general';
import RecordsRoundsContentGames from './games/games';
import RecordsRoundsContentTop5 from './top5/top5';
import RecordsRoundsContentBets from './bets/bets';

export class RecordsRoundsContent extends React.Component {
  async componentDidMount() {
    const { currentRound } = this.props;
    await this.props.getStatistics(currentRound);
    scrollTo('menu-secondary');
  }

  render() {
    const { round, statistics, view } = this.props;
    const { generalScoreAverage, generalDifficulty } = this.props;

    if (!round || !statistics) return (null);

    return (
      <div className="content-container">
        {view === 'general' && (
        <RecordsRoundsContentGeneral
          games={round.games || []}
          scores={(statistics.bet_scores && statistics.bet_scores.scores) || []}
          simple={(statistics.bet_scores && statistics.bet_scores.simple) || 0}
          accurate={(statistics.bet_scores && statistics.bet_scores.accurate) || 0}
          betsTotal={round.bets_total}
          activeUsers={round.active_users || 0}
          gamesTotal={(round.games || []).length}
          scoreAverage={round.score_average || 0}
          difficulty={round.difficulty || 0}
          generalScoreAverage={generalScoreAverage}
          generalDifficulty={generalDifficulty}
        />
        )}
        {view === 'games' && (
        <RecordsRoundsContentGames
          games={round.games || []}
          statistics={(statistics.bet_scores && statistics.bet_scores.games) || []}
          betsTotal={round.bets_total}
        />
        )}
        {view === 'top5' && (
        <RecordsRoundsContentTop5
          statistics={statistics.bets}
        />
        )}
        {view === 'bets' && (
        <RecordsRoundsContentBets
          roundNumber={round.number}
        />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getStatistics: roundNumber => dispatch(roundsGetStatistics(roundNumber)),
});

const mapStateToProps = (state, ownProps) => ({
  round: state.rounds.byNumber[ownProps.currentRound],
  statistics: state.rounds.statistics[ownProps.currentRound],
  generalScoreAverage: state.statistics.general_score_average || 0,
  generalDifficulty: state.statistics.general_difficulty || 0,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordsRoundsContent);
