import React from 'react';
import { connect } from 'react-redux';

export const TeamsDetailsPortal = (props) => {
  const { url } = props;
  return (
    <div className="team-portal">
      <iframe src={url} title="Portal" />
    </div>
  );
};

const mapStateToProps = state => ({
  url: state.teams.details && state.teams.details.overview
  && state.teams.details.overview.portal && state.teams.details.overview.portal.url,
});

export default connect(mapStateToProps)(TeamsDetailsPortal);
