import React from 'react';

import Colors from '../../../../../../data/colors';

import PlayersDetailsOverviewStatisticsBetsChartBar from './chartBar';

const PlayersDetailsOverviewStatisticsBetsRowBar = (props) => {
  const {
    title, chartLabel, score, player, general, username,
  } = props;


  const colors = [Colors.layout.clouds, Colors.layout.silver];

  return (
    <div className="row">
      <PlayersDetailsOverviewStatisticsBetsChartBar
        title={chartLabel}
        player={player}
        general={general}
        colors={colors}
      />
      <div className="texts">
        <div className="info">
          <div className="label title">
            {title}
          </div>
          <div className="value">
            {score}
          </div>
        </div>
        <div className="info">
          <div className="label">
            <div className="square" style={{ background: colors[0] }} />
            {'Média de '}
            {username}
          </div>
          <div className="value">
            {player.toFixed(2)}
          </div>
        </div>
        <div className="info">
          <div className="label">
            <div className="square" style={{ background: colors[1] }} />
            {'Média Geral'}
          </div>
          <div className="value">
            {general.toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayersDetailsOverviewStatisticsBetsRowBar;
