import React from 'react';

import TeamsDetailsOverviewBadgesItem from './item';
import TeamsDetailsOverviewBadgesCarousel from './carousel';

import TeamsUtils from '../../../../../app/utils/teams';

const TeamsDetailsOverviewBadgesLeagues = (props) => {
  const { leagues = [], rankedCompetitions } = props;

  const items = [];

  const badgeLeague = TeamsUtils.getBadgeLeague(leagues);
  if (badgeLeague) {
    items.push(
      <TeamsDetailsOverviewBadgesItem
        logo={badgeLeague.badge}
        name={badgeLeague.name}
        infos={{ league: 'Divisão' }}
      />,
    );
  }

  const badgeRanked = TeamsUtils.getBadgeRanked(leagues, rankedCompetitions);
  if (badgeRanked) {
    items.push(
      <TeamsDetailsOverviewBadgesItem
        logo={badgeRanked.badge}
        name={badgeRanked.name}
        infos={{ league: 'Rankeado' }}
      />,
    );
  }

  return (
    <TeamsDetailsOverviewBadgesCarousel
      title="Ligas"
      noneText="Sem Ligas"
      items={items}
    />
  );
};

export default TeamsDetailsOverviewBadgesLeagues;
