import React from 'react';

export const GuidePenaltiesAdditional = () => (
  <React.Fragment>
    <div className="section">
      <h4>
        {'Pênalti adicional'}
      </h4>
      <div className="section-content">
        <p>
          {'O pênalti adicional será dado ao clube que tem a melhor campanha na competição. Se a '
          + 'campanha for igual, será dado ao clube com a melhor posição no Ranking que foi usado na '
          + 'criação do competição.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuidePenaltiesAdditional;
