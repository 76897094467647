import React from 'react';

import StatisticsData from '../../../data/statistics';

import Table from '../table';

const ScoreTable = (props) => {
  const data = StatisticsData.scoreTable;
  const {
    scores, betsTotal, total = data.total, noHeader,
  } = props;

  const scoreHeader = [
    { key: 'score', value: 'Placar', className: 'no-first' },
    { key: 'total', value: 'Total' },
  ];

  let scoresTotal = 0;

  const scoreData = scores.slice(0, total).map((row) => {
    const score = `${row.score_home}x${row.score_away}`;
    scoresTotal += row.total;
    return {
      key: score,
      score,
      total: row.total,
    };
  });

  if (betsTotal - scoresTotal > 0) {
    scoreData.push({
      key: 'Outros',
      score: 'Outros',
      total: betsTotal - scoresTotal,
    });
  }

  return (
    <Table header={scoreHeader} lines={scoreData} noHeader={noHeader} />
  );
};

export default ScoreTable;
