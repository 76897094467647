import React from 'react';

import MultipleCarousel from '../../../elements/multipleCarousel';

import ScoreTable from '../../../elements/statistics/scoreTable';

import RecordsRoundsContentGameBets from './gameResultBets';

const RecordsRoundsContentGame = (props) => {
  const { statistics, betsTotal } = props;

  return (
    <div className="result">
      <MultipleCarousel
        items={[
          <RecordsRoundsContentGameBets {...props} />,
          <ScoreTable betsTotal={betsTotal} scores={statistics.scores} noHeader />,
        ]}
        xs={1}
        sm={1}
        md={1}
        lg={1}
        indicator
      />
    </div>
  );
};

export default RecordsRoundsContentGame;
