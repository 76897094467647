import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Top5Section from '../../elements/top5/top5Section';

const RecordsTop5Bets = (props) => {
  const { score, difficulty, best_rounds: bestRounds } = props.data;

  return (
    <Top5Section title="Apostas">
      <Grid fluid>
        {bestRounds && (
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(bestRounds, 'Melhor da Rodada', 'Vezes')}
          </Col>
        </Row>
        )}
        {score && (
        <Row>
          {score.high && (
          <Col lg={4} md={6}>
            {props.createTable(score.high, 'Maior Pontuação', 'Pontos')}
          </Col>
          )}
          <Col lg={4} md={6}>
            {props.createTable(score.value, 'Pontuação', 'Pontos')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(score.average, 'Média de Pontuação', 'Pontos', 'average')}
          </Col>
        </Row>
        )}
        {score && (
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(score.simple, 'Acertos', 'Vezes')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(score.accurate, 'Cravadas', 'Vezes')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(score.bonus, 'Bônus', 'Vezes')}
          </Col>
        </Row>
        )}
        {difficulty && (
        <Row>
          <Col lg={4} md={6}>
            {props.createTable(difficulty.value, 'Pontos de Dificuldade', 'Total', 'average')}
          </Col>
          <Col lg={4} md={6}>
            {props.createTable(difficulty.average, 'Média de Dificuldade', 'Pontos', 'average')}
          </Col>
        </Row>
        )}
      </Grid>
    </Top5Section>
  );
};

export default RecordsTop5Bets;
