import AuthService from '../../services/auth';

import { authenticateComplete } from './authenticate';
import { settingsGet } from './settings';

export const setValue = (attr, value) => ({
  type: 'ACTIVITIES_VALUE_SET',
  attr,
  value,
});

export const activitiesSetIdle = value => (dispatch) => {
  dispatch(setValue('idle', value));
  if (!value) {
    dispatch(authenticateComplete());
  }
};

export const activitiesSetNotificationsItem = (attr, value) => (dispatch, getState) => {
  const { notifications } = getState().activities;
  notifications[attr] = value;
  dispatch(setValue('notifications', notifications));
};

export const activitiesSetAuthenticated = value => (dispatch) => {
  dispatch(setValue('authenticated', value));
};

export const activitiesSetStarting = value => (dispatch) => {
  dispatch(setValue('starting', value));
};

export const activitiesSetMaintenance = value => async (dispatch, getState) => {
  const { maintenance } = getState().activities;
  if (value !== maintenance) {
    dispatch(setValue('maintenance', value));

    if (!value) {
      await dispatch(settingsGet());

      if (AuthService.isAuthenticated()) {
        dispatch(activitiesSetAuthenticated(true));
        await dispatch(authenticateComplete());
      }
    }
  }
};
