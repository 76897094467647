import RestService from '../services/rest';
import RoundsUtils from '../utils/rounds';
import RoundsBetsUtils from '../utils/roundsBets';

async function getAuthUserBets(roundNumber) {
  const userBet = await RestService.getAuthenticated(`rounds/bets?number=${roundNumber || ''}`);
  if (userBet.round) userBet.round = RoundsUtils.completeRound(userBet.round);
  if (userBet.bet) userBet.bet = RoundsBetsUtils.completeRoundBet(userBet.bet);
  return userBet;
}

async function getUserBets(userId, roundNumber) {
  const userBet = await RestService.getAuthenticated(`rounds/bets/details?user=${userId}&number=${roundNumber || ''}`);
  if (userBet.round) userBet.round = RoundsUtils.completeRound(userBet.round);
  if (userBet.bet) userBet.bet = RoundsBetsUtils.completeRoundBet(userBet.bet);
  return userBet;
}

async function bet(roundNumber, scores) {
  return RestService.postAuthenticated('rounds/bets', {
    number: roundNumber,
    games: scores,
  });
}

async function getAvailableRounds() {
  return RestService.getAuthenticated('rounds/availables');
}

export default {
  getAuthUserBets,
  getUserBets,
  bet,
  getAvailableRounds,
};
