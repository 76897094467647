import UsersAdministrationRequests from '../../server/usersAdministration';
import { addLoading, removeLoading } from './loading';

import UsersData from '../../../data/users';

export const usersAdministrationBlock = userId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UsersAdministrationRequests.block(userId);
  } finally {
    dispatch(removeLoading());
  }
};

export const usersAdministrationUnblock = userId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UsersAdministrationRequests.unblock(userId);
  } finally {
    dispatch(removeLoading());
  }
};

export const usersAdministrationActive = userId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UsersAdministrationRequests.activeUser(userId);
  } finally {
    dispatch(removeLoading());
  }
};

export const usersAdministrationDelete = userId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UsersAdministrationRequests.deleteUser(userId);
  } finally {
    dispatch(removeLoading());
  }
};

export const usersAdministrationBlockWall = (userId, isBlocked) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UsersAdministrationRequests.blockWall(userId, isBlocked);
  } finally {
    dispatch(removeLoading());
  }
};


export const usersAdministrationList = (type, page) => async (dispatch) => {
  dispatch(addLoading());
  const limit = UsersData.list.gamesPerPage;
  const skip = ((page - 1) * limit);

  try {
    const users = await UsersAdministrationRequests.getList(type, skip, limit);
    return users;
  } finally {
    dispatch(removeLoading());
  }
};
