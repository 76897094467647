import React from 'react';
import { connect } from 'react-redux';

import Paginate from '../../../../../elements/paginate';

import TeamsCompetitionsDetailsStatisticsRankingTable from './teamsCompetitionsDetailsStatisticsRankingTable';

import { cleanStatistics, competitionsGetRanking } from '../../../../../../app/store/actions/competitions';

export class TeamsCompetitionsDetailsStatisticsRanking extends React.Component {
  constructor(props) {
    super(props);

    const { currentRoundNumber } = props;

    this.state = {
      roundNumber: currentRoundNumber,
      page: 0,
    };
  }

  componentDidMount() {
    this.loadRanking();
  }

  async componentWillReceiveProps(nextProps) {
    const { roundNumber } = this.state;
    const { currentRoundNumber } = nextProps;

    if (roundNumber !== currentRoundNumber) {
      this.setState(() => ({ roundNumber: currentRoundNumber }));
      await this.resetRankings();
      this.loadRanking();
    }
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex });
    this.loadRanking();
  };

  loadRanking = async () => {
    const { rankings } = this.props;
    const { page } = this.state;
    if (!rankings || !rankings[page]) {
      await this.props.getRanking(page);
    }
  };

  render() {
    const { page } = this.state;
    const { rankings, totalPages } = this.props;

    if (!totalPages) return (null);

    return (
      <React.Fragment>
        <TeamsCompetitionsDetailsStatisticsRankingTable rankings={rankings[page]} />
        <Paginate
          pageCount={totalPages}
          forcePage={page}
          onPageChange={this.handlePageClick}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  cleanStatistics: () => dispatch(cleanStatistics()),
  getRanking: page => dispatch(competitionsGetRanking(page)),
});

const mapStateToProps = state => ({
  rankings: state.competitions.details.ranking.data,
  totalPages: state.competitions.details.ranking.pages,
  currentRoundNumber: state.rounds.current && state.rounds.current.number,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsCompetitionsDetailsStatisticsRanking);
