import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrophy, faCrown, faCheckCircle, faFire, faFutbol,
} from '@fortawesome/free-solid-svg-icons';

import PlayersUtils from '../../../app/utils/players';

import PlayersData from '../../../data/players';

const PlayerIcons = (props) => {
  const {
    player, type, options = {}, forceSize,
  } = props;

  let size = 0.7;
  if (forceSize) size = forceSize;

  const items = [];


  if (options.bet) {
    const hasBet = PlayersUtils.betValue(player) !== 'none';
    items.push((
      <div
        className="cateogry"
        key="bet"
        style={{
          width: `${1.7 * size}rem`,
          color: PlayersData.bet[hasBet ? 'made' : 'none'],
        }}
      >
        <FontAwesomeIcon icon={faCheckCircle} title={hasBet ? 'Apostou' : 'Não Apostou'} />
      </div>
    ));
  }

  if (options.prime) {
    const isPrime = PlayersUtils.isPrime(player);
    if (isPrime) {
      items.push((
        <div className="cateogry" key="prime" style={{ width: `${2.1 * size}rem`, color: PlayersData.prime }}>
          <FontAwesomeIcon icon={faCrown} title="Prime" />
        </div>
      ));
    }
  }

  const isScorePermission = PlayersUtils.isScorePermission(player);
  if (isScorePermission) {
    items.push((
      <div className="cateogry" key="score" style={{ width: `${1.7 * size}rem`, color: PlayersData.score }}>
        <FontAwesomeIcon icon={faFutbol} title="Atualizador" />
      </div>
    ));
  }

  const trophiesTotal = player.achievements.trophies.total;
  const trophiesOfficialType = player.achievements.trophies.types.find(t => t.type === 'official');
  const trophiesCustomType = player.achievements.trophies.types.find(t => t.type === 'custom');

  const trophiesOfficialTotal = trophiesOfficialType ? trophiesOfficialType.total : 0;
  const trophiesCustomTotal = trophiesCustomType ? trophiesCustomType.total : 0;
  const trophiesOtherTotal = trophiesTotal - (trophiesOfficialTotal + trophiesCustomTotal);

  const trophies = [];
  for (let i = 0; i < trophiesCustomTotal; i += 1) {
    trophies.push({ key: `custom_${i}`, color: PlayersData.trophies.colors.custom, name: 'Personalizado' });
  }
  for (let i = 0; i < trophiesOtherTotal; i += 1) {
    trophies.push({ key: `other_${i}`, color: PlayersData.trophies.colors.others, name: 'Diverso' });
  }
  for (let i = 0; i < trophiesOfficialTotal; i += 1) {
    trophies.push({ key: `official_${i}`, color: PlayersData.trophies.colors.official, name: 'Oficial' });
  }

  if (trophies.length) {
    switch (type) {
      case 'table':
        items.push((
          <div className="cateogry" key="trophies" style={{ width: `${size * (trophies.length + 1)}rem` }}>
            {trophies.map((trophy, index) => (
              <FontAwesomeIcon
                key={trophy.key}
                icon={faTrophy}
                style={{ left: `${size * index}rem`, color: trophy.color }}
                title={trophy.name}
              />
            ))}
          </div>
        ));
        break;
      default:
        items.push((
          <div className="cateogry" key="trophies">
            {trophies.map((trophy, index) => (
              <FontAwesomeIcon
                key={trophy.key}
                icon={faTrophy}
                style={{ left: `${size * index}rem`, color: trophy.color }}
                title={trophy.name}
              />
            ))}
          </div>
        ));
    }
  }

  const isBestRound = PlayersUtils.isBestRound(player);
  if (isBestRound) {
    items.push((
      <div className="cateogry" key="best-round" style={{ width: `${1.6 * size}rem`, color: PlayersData.betRound }}>
        <FontAwesomeIcon icon={faFire} title="Melhor da Rodada" />
      </div>
    ));
  }

  return (
    <div className="icons all">
      {items.map(item => item)}
    </div>
  );
};

export default PlayerIcons;
