import React from 'react';

import CardTable from '../../../../elements/card/table';

import translator from '../../../../../data/translator';

const CompetitionTable = (props) => {
  const { competition, className = '' } = props;

  return (
    <CardTable
      className={className}
      name={competition.name}
      badge={{
        url: competition.logo.url,
        title: competition.name,
      }}
      tag={translator.s.gameModes[competition.mode_type]}
    />
  );
};

export default CompetitionTable;
