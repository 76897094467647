import React from 'react';

import ImageLoad from '../image/imageLoad';

const TeamHighlight = ({
  name, tag, tagStyle, badge, level, levelStyle, round,
}) => (
  <div className="card-all card-highlight" key={name}>
    <div className={`badge${(round) ? ' round' : ''}`}>
      <ImageLoad
        src={badge.url}
        alt={badge.name}
        placeholder="/images/team_placeholder.png"
      />
      {level && (
      <div className={`level${levelStyle ? ` ${levelStyle}` : ''}`}>
        {level}
      </div>
      )}
    </div>
    <div className="texts">
      <div className="name">
        {name}
      </div>
      <div className={`tag ${tagStyle}`}>
        {tag}
      </div>
    </div>
  </div>
);

export default TeamHighlight;
