import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../../../../../routers/appRouter';

import TeamsCompetitionsDetailsInformationHeaderGroups from './groups';

const TeamsCompetitionsDetailsInformationHeader = (props) => {
  const { phases, group = {} } = props;

  return (
    <div className="info-header">
      <TeamsCompetitionsDetailsInformationHeaderGroups
        phases={phases}
        currentGroup={group.info}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const groupId = history.location.pathname.split('/')[5];

  return {
    phases: state.competitions.details.general.phases,
    group: state.competitions.details.groups[groupId],
  };
};

export default connect(mapStateToProps)(TeamsCompetitionsDetailsInformationHeader);
