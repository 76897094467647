import React from 'react';
import { connect } from 'react-redux';

import translator from '../../../../../data/translator';

import { competitionsGetGroupByCompetition } from '../../../../../app/store/actions/competitions';

import CompetitionsRequests from '../../../../../app/server/competitions';

import TeamsCompetitionsDetailsInformationGroupData
  from '../../../competitions/details/information/teamsCompetitionsDetailsInformationGroupData';

export class TeamsGamesCompetition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const { group } = this.state;
    const { game } = this.props;

    if (game && !group) {
      const competitionId = game.competition.competition.id;
      const groupId = game.competition.group;
      const groupData = await this.props.getGroup(competitionId, groupId);
      await this.setState({ group: groupData });
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { isIdle, roundUpdated, game } = nextProps;

    if (this.props.roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
      const competitionId = game.competition.competition.id;
      const groupId = game.competition.group;
      const groupData = await CompetitionsRequests.getGroup(competitionId, groupId);
      await this.setState({ group: groupData });
    }
  }

  render() {
    const { group } = this.state;
    const { game } = this.props;

    if (!group) return (null);

    return (
      <div className="competitions-details">
        <h3 className="title-center-menu">
          {translator.s.groups[group.info.stage]}
        </h3>
        <TeamsCompetitionsDetailsInformationGroupData
          group={group}
          currentRoundNumber={game.round}
          userTeamIds={[game.team_home.id, game.team_away.id]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  game: state.games.details && state.games.details.game,
  isIdle: state.activities.idle,
  roundUpdated: state.rounds.updateAt,
});

const mapDispatchToProps = dispatch => ({
  getGroup: (competitionId, groupId) => dispatch(competitionsGetGroupByCompetition(competitionId, groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsGamesCompetition);
