import React from 'react';

import Team from '../../../../elements/team';

import TeamsManagerUtils from '../../../../../../app/utils/teamsMananger';

import colorsData from '../../../../../../data/colors';
import betsData from '../../../../../../data/bets';

import TeamsGamesBetsCardHeader from './elements/header';
import TeamsGamesBetsCardInformation from './elements/information';
import TeamsGamesBetsCardInformationMobile from './elements/informationMobile';
import TeamsGamesBetsCardDetails from './elements/details';
import TeamsGamesBetsCardSelectPlayers from './elements/selectPlayers';

export class TeamsGamesBetsCardGames extends React.Component {
  state = {
    homePlayerIndex: 0,
    awayPlayerIndex: 0,
  }

  selectPlayerIndex = async (type, playerIndex) => {
    const obj = {};
    obj[`${type}PlayerIndex`] = playerIndex;
    this.setState(obj);
  }

  render() {
    const { homePlayerIndex, awayPlayerIndex } = this.state;
    const {
      title, position, games, managerCoachDifficulty,
      teamHome = {}, teamAway = {}, homeBets = [], awayBets = [],
    } = this.props;

    const homeScore = homeBets.reduce((score, userBet) => score + (userBet.score || 0), 0);
    const awayScore = awayBets.reduce((score, userBet) => score + (userBet.score || 0), 0);

    const getBonus = usersBets => usersBets.map(bets => (bets.bet ? TeamsManagerUtils.getBonus(position, games, {
      managerCoachDifficulty,
      bets: bets.bet,
    }) : []));

    const homeBetsBonus = getBonus(homeBets);
    const awayBetsBonus = getBonus(awayBets);

    const homeGoals = homeScore > awayScore ? Math.max(1, (awayBets.length - homeBets.length) + 1) : 0;
    const awayGoals = homeScore < awayScore ? Math.max(1, (homeBets.length - awayBets.length) + 1) : 0;

    const homePlayers = homeBets.filter(userBet => userBet.user).map(userBet => userBet.user);
    const awayPlayers = awayBets.filter(userBet => userBet.user).map(userBet => userBet.user);

    let homePlayerScore = 0;
    let awayPlayerScore = 0;

    const details = games.map((game) => {
      const homeBet = (homeBets[homePlayerIndex] && homeBets[homePlayerIndex].bet
        && homeBets[homePlayerIndex].bet.games[game.number]) || {};
      const awayBet = (awayBets[awayPlayerIndex] && awayBets[awayPlayerIndex].bet
        && awayBets[awayPlayerIndex].bet.games[game.number]) || {};

      const setScore = (homeValue, awayValue) => `${homeValue !== undefined
        ? homeValue : ''}-${awayValue !== undefined ? awayValue : ''}`;

      const homeHasBonus = !!((homeBetsBonus[homePlayerIndex] || []).indexOf(game.number) !== -1);
      const awayHasBonus = !!((awayBetsBonus[awayPlayerIndex] || []).indexOf(game.number) !== -1);

      homePlayerScore += homeHasBonus ? 2 * (homeBet.score || 0) : (homeBet.score || 0);
      awayPlayerScore += awayHasBonus ? 2 * (awayBet.score || 0) : (awayBet.score || 0);

      return {
        key: game.number,
        homeName: game.team_home.name,
        awayName: game.team_away.name,
        result: setScore(game.score_home, game.score_away),
        inProgress: game.status === 'in_progress',
        homeValue: setScore(homeBet.score_home, homeBet.score_away),
        awayValue: setScore(awayBet.score_home, awayBet.score_away),
        homeBonus: homeHasBonus,
        awayBonus: awayHasBonus,
        homeColor: homeBet.score && colorsData.layout[betsData.colors[homeBet.score.toString()]],
        awayColor: awayBet.score && colorsData.layout[betsData.colors[awayBet.score.toString()]],
      };
    });

    return (
      <div className="game-bets-card">
        <TeamsGamesBetsCardHeader title={title} />
        <TeamsGamesBetsCardInformation
          home={<Team data={teamHome} type="gameBet" />}
          away={<Team data={teamAway} type="gameBet" />}
          homeScore={homeScore}
          awayScore={awayScore}
          homeGoals={homeGoals}
          awayGoals={awayGoals}
          classScore="big"
        />
        <TeamsGamesBetsCardInformationMobile
          home={<Team data={teamHome} type="text" />}
          away={<Team data={teamAway} type="text" />}
          homeGoals={homeGoals}
          awayGoals={awayGoals}
        />
        <TeamsGamesBetsCardSelectPlayers
          homePlayers={homePlayers}
          awayPlayers={awayPlayers}
          homeTotalPlayers={homeBets.length}
          awayTotalPlayers={awayBets.length}
          homeScore={homePlayerScore}
          awayScore={awayPlayerScore}
          homePlayerIndex={homePlayerIndex}
          awayPlayerIndex={awayPlayerIndex}
          selectPlayerIndex={this.selectPlayerIndex}
        />
        <TeamsGamesBetsCardDetails lines={details} />
      </div>
    );
  }
}

export default TeamsGamesBetsCardGames;
