import React from 'react';

import Player from '../../../../../players/elements/player';
import Table from '../../../../../elements/table';

const TeamsCompetitionsDetailsStatisticsStrikersTable = (props) => {
  const { strikers = [] } = props;

  const strikersHeader = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'player', value: 'Jogador', className: 'name', colspan: 2,
    },
    {
      key: 'score', value: 'Gols', className: 'number', tdClassName: 'high',
    },
  ];

  const strikersData = strikers.map((row, index) => ({
    key: index,
    position: row.position,
    player: <Player data={row.player} type="table" />,
    score: row.score,
  }));

  return (
    <Table header={strikersHeader} lines={strikersData} />
  );
};

export default TeamsCompetitionsDetailsStatisticsStrikersTable;
