const initialState = {
  list: {
    started: [],
    closed: {},
  },
  details: {
    general: {},
    groups: {},
    ranking: {},
    strikers: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'COMPETITIONS_LIST_STARTED_SET':
      state.list.started = action.competitions || [];
      return state;
    case 'COMPETITIONS_LIST_CLOSED_SET':
      state.list.closedYear = action.year;
      state.list.closed[action.year] = action.competitions || [];
      return state;
    case 'COMPETITIONS_LISTS_CLEAN':
      return {
        ...state,
        list: {
          started: [],
          closed: {},
        },
      };
    case 'COMPETITIONS_DETAILS_SET': {
      if (!state.details.general.id || !action.competition || state.details.general.id !== action.competition.id) {
        state.details.groups = {};
        state.details.ranking = {};
        state.details.strikers = {};
      } else {
        Object.keys(state.details.groups).forEach((groupId) => { state.details.groups[groupId].old = true; });
      }
      state.details = {
        ...state.details,
        general: action.competition || {},
        updatedAt: action.updatedAt,
      };
      return { ...state };
    }
    case 'COMPETITIONS_GROUP_SET': {
      state.details.groups[action.group.info.id] = action.group;
      state.details.groups = { ...state.details.groups };
      return { ...state };
    }
    case 'COMPETITIONS_STATISTICS_SET': {
      const type = action.statisticType;
      if (!state.details[type].data) {
        state.details[type] = {
          data: [],
          pages: action.pages,
        };
      }
      state.details[type].data[action.page] = action.data;
      state.details[type].data = [...state.details[type].data];
      return state;
    }
    case 'COMPETITIONS_STATISTICS_CLEAN': {
      const details = {
        ...state.details,
        ranking: {},
        strikers: {},
      };
      return {
        ...state,
        details,
      };
    }
    case 'COMPETITIONS_TYPES_SET': {
      return {
        ...state,
        types: action.types,
      };
    }
    case 'COMPETITIONS_PREMIUMS_SET': {
      return {
        ...state,
        premiums: action.premiums,
      };
    }
    case 'COMPETITIONS_PREMIUMS_CLEAN': {
      delete state.premiums;
      return state;
    }
    default:
      return state;
  }
};
