import React from 'react';

export const GuideGeneralRound = () => (
  <React.Fragment>
    <div className="section">
      <h4>
        {'Apostas'}
      </h4>
      <div className="section-content">
        <p>
          {'Semanalmente serão disponibilizadas 2 rodadas para apostas, sendo uma no meio de semana '
        + '(MDS) e outra no final de semana (FDS), cada uma constando de 10 jogos.'}
        </p>
        <p>
          {'Para realizar as apostas, o usuário deverá preencher seus palpites nos 10 jogos da rodada, '
        + 'e clicar no botão “APOSTAR”. Será encaminhada, automaticamente, uma mensagem para o seu '
        + 'email,  informado no cadastro, mostrando todos os palpites registrados.'}
        </p>
        <p>
          {'As apostas poderão ser alteradas até 1 hora antes do início do primeiro jogo da rodada, '
        + 'quando o site bloqueará automaticamente quaisquer alterações.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Prorrogação'}
      </h4>
      <div className="section-content">
        <p>
          {'Não será considerado o resultado da prorrogação, portanto, o resultado que valerá será o '
          + 'final durante os 90 minutos.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Alteração dos Jogos'}
      </h4>
      <div className="section-content">
        <p>
          {'Os jogos poderão sofrer alteração até 24 horas antes do início da rodada. Caso '
          + 'ocorra, será informado no site e também através das redes sociais.'}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Anulação de Jogos'}
      </h4>
      <div className="section-content">
        <p>
          {'O jogo será anulado nos seguintes casos:'}
        </p>
        <ol className="list-number">
          <li>
            {'Caso o jogo seja adiado (por qualquer motivo que seja), e não seja finalizado até o dia de '
            + 'encerramento da rodada.'}
          </li>
          <li>
            {'Caso o jogo não ocorra mais, se ocorrer W.O. por exemplo.'}
          </li>
          <li>
            {'Caso o jogo for paralisado devido alguma intervenção externa (como falta de energia), e não '
            + 'seja finalizado até o dia de encerramento da rodada.'}
          </li>
          <li>
            {'Caso o jogo não tiver condições de continuar devido à falta de jogadores prevista na regra '
            + 'do futebol (7 jogadores em cada time).'}
          </li>
        </ol>
      </div>
    </div>
  </React.Fragment>
);
export default GuideGeneralRound;
