import React from 'react';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons';

export class AccountProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gender: 'male',
      firstName: '',
      lastName: '',
      location: '',
      birth: '',
      error: {
        firstName: '',
        lastName: '',
        location: '',
        birth: '',
      },
      changed: false,
    };
  }

  async componentDidMount() {
    await this.uploadProps(this.props);
  }

  async componentWillReceiveProps(nextProps) {
    await this.uploadProps(nextProps);
  }

  onGenderChange = (newGender) => {
    this.setState(() => ({ gender: newGender, changed: true }));
  };

  onFirstNameChange = (e) => {
    const regex = /^[a-záâãéêíóôõúç ]+$/i;
    const firstName = e.target.value;
    if (firstName === '' || regex.test(firstName.toLowerCase())) {
      this.setState(() => ({ firstName, error: this.cleanError('firstName'), changed: true }));
    }
  };

  onLastNameChange = (e) => {
    const regex = /^[a-záâãéêíóôõúç ]+$/i;
    const lastName = e.target.value;
    if (lastName === '' || regex.test(lastName.toLowerCase())) {
      this.setState(() => ({ lastName, error: this.cleanError('lastName'), changed: true }));
    }
  };

  onLocationChange = (e) => {
    const location = e.target.value;
    this.setState(() => ({ location, error: this.cleanError('location'), changed: true }));
  };

  onBirthChange = (e) => {
    const birthStr = e.target.value;

    let birth = birthStr.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);

    if (!birth[2].length) birth = `${birth[1]}`;
    else if (!birth[3].length) birth = `${birth[1]}/${birth[2]}`;
    else birth = `${birth[1]}/${birth[2]}/${birth[3]}`;

    this.setState(() => ({ birth, error: this.cleanError('birth'), changed: true }));
  };

  onSubmit = async (e) => {
    const {
      error, firstName, lastName, gender, location, birth,
    } = this.state;

    e.preventDefault();

    if (!firstName) {
      error.firstName = 'Digite o seu primeiro nome. ';
    }

    if (!moment(birth, 'DD/MM/YYYY', true).isValid()) {
      error.birth = 'Digite uma data válida. ';
    } else if (moment(birth, 'DD/MM/YYYY').add(18, 'years').isAfter(moment())) {
      error.birth = 'Você precisa ter 18 anos. ';
    }

    if (!lastName) {
      error.lastName = 'Digite o seu último nome. ';
    }

    if (!location) {
      error.location = 'Digite sua cidade. ';
    }

    await this.setState(() => ({ error, changed: false }));

    if (!this.hasError()) {
      await this.props.getProfileData({
        first_name: firstName,
        last_name: lastName,
        gender,
        birth: moment(birth, 'DD/MM/YYYY'),
        location,
      });
    }
  };

  hasError = () => {
    const { error } = this.state;
    return Object.keys(error).filter(field => error[field] !== '').length > 0
    || Object.keys(this.state).filter(field => this.state[field] === '').length > 0;
  };

  cleanError = (field) => {
    const { error } = this.state;
    error[field] = '';
    return error;
  };

  async uploadProps(props) {
    const {
      gender, firstName, lastName, location, birth,
    } = props;

    const data = {};
    if (gender) data.gender = gender;
    if (firstName) data.firstName = firstName;
    if (lastName) data.lastName = lastName;
    if (location) data.location = location;
    if (birth) data.birth = moment(props.birth).format('DD/MM/YYYY');

    if (Object.keys(data).length > 0) await this.setState(() => (data));
  }

  render() {
    const {
      changed, error, firstName, lastName, gender, location, birth,
    } = this.state;
    const { submitLabelButton } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="section">
          <h4>
            {'Nome Completo'}
          </h4>
          <div className="section-content">
            <input
              type="text"
              placeholder="Digite seu primeiro nome"
              className="text-input"
              value={firstName}
              onChange={this.onFirstNameChange}
            />
            <input
              type="text"
              placeholder="Digite seu último nome"
              className="text-input"
              value={lastName}
              onChange={this.onLastNameChange}
            />
            <div className="error">
              {error.firstName}
              {error.lastName}
            </div>
          </div>
        </div>
        <div className="section">
          <h4>
            {'Dia de Nascimento'}
          </h4>
          <div className="section-content">
            <input
              type="text"
              placeholder="dd/mm/aaaa"
              className="text-input"
              value={birth}
              onChange={this.onBirthChange}
            />
            <div className="error">
              {error.birth}
            </div>
          </div>
        </div>
        <div className="section">
          <h4>
            {'Localização'}
          </h4>
          <div className="section-content">
            <input
              type="text"
              placeholder="Digite sua cidade"
              className="text-input"
              value={location}
              onChange={this.onLocationChange}
            />
            <div className="error">
              {error.location}
            </div>
          </div>
        </div>
        <div className="section">
          <h4>
            {'Gênero'}
          </h4>
          <div className="gender center">
            <button
              type="button"
              onClick={() => this.onGenderChange('male')}
              className={gender === 'male' ? 'selected' : ''}
            >
              <FontAwesomeIcon icon={faMale} />
              <div className="title">
                {'Masculino'}
              </div>
            </button>
            <button
              type="button"
              onClick={() => this.onGenderChange('female')}
              className={gender === 'female' ? 'selected' : ''}
            >
              <FontAwesomeIcon icon={faFemale} />
              <div className="title">
                {'Feminino'}
              </div>
            </button>
          </div>
        </div>
        <div>
          {this.props.previous && (
            <button type="button" className="button normal" onClick={this.props.previous}>
              {'Voltar'}
            </button>
          )}
          <button type="submit" className="button normal light-blue" disabled={this.hasError() || !changed}>
            {submitLabelButton || 'Salvar'}
          </button>
        </div>
      </form>
    );
  }
}

export default AccountProfileForm;
