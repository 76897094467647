import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import TeamsCompetitionsList from './list/teamsCompetitionsList';
import TeamsCompetitionsPremiums from './premiums/teamsCompetitionsPremiums';
import TeamsCompetitionsDetails from './details/teamsCompetitionsDetails';

import translator from '../../../data/translator';

export const TeamsCompetitions = (props) => {
  const { modeData, match, types = [] } = props;
  const { url } = match;

  const menus = types.map(type => ({
    name: translator.p.competitions[type],
    type,
    uri: `${url}/${translator.uri.competitions[type]}`,
  }));

  return (
    <div>
      <Switch>
        <Redirect exact from={url} to={menus[0].uri} />
        <Route
          path={`${url}/premiums-inscricao`}
          exact
          component={p => <TeamsCompetitionsPremiums {...p} modeData={modeData} />}
        />
        {menus.map(menu => (
          <Route
            key={menu.name}
            path={menu.uri}
            component={p => <TeamsCompetitionsList {...p} menus={menus} type={menu.type} modeData={modeData} />}
          />
        ))}
        <Route
          path={`${url}/:id`}
          component={p => <TeamsCompetitionsDetails {...p} modeData={modeData} />}
        />
      </Switch>
    </div>
  );
};


const mapStateToProps = state => ({
  types: state.settings.competitions && state.settings.competitions.types,
});

export default connect(mapStateToProps)(TeamsCompetitions);
