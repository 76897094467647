import React from 'react';
import { Link } from 'react-router-dom';

import TeamsUtils from '../../../../../app/utils/teams';

const TeamsGamesDetailsHeaderBadge = ({ team }) => {
  const Element = team.id ? (
    <Link to={TeamsUtils.getLink(team)}>
      <img src={team.badge.url} alt={team.name} />
    </Link>
  ) : (
    <img src={team.badge.url} alt={team.name} />
  );

  return (
    <div className="badge">
      {Element}
    </div>
  );
};

export default TeamsGamesDetailsHeaderBadge;
