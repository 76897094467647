import TeamsFriendlyRequests from '../../server/teamsFriendly';
import { userGetAuth } from './user';
import { teamsGetTeam } from './teams';
import { addLoading, removeLoading } from './loading';

export const teamsFriendlyGet = () => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());

    try {
      const data = await TeamsFriendlyRequests.proposals(team.id);
      return data;
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsFriendlySendProposal = teamFriendlyId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsFriendlyRequests.sendProposal(team.id, teamFriendlyId);
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsFriendlyCancelProposal = teamFriendlyId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsFriendlyRequests.cancelProposal(team.id, teamFriendlyId);
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsFriendlyAcceptProposal = teamFriendlyId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsFriendlyRequests.acceptProposal(team.id, teamFriendlyId);
      await dispatch(userGetAuth());
      await dispatch(teamsGetTeam(team.gameMode, team.overview.name));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const teamsFriendlyRejectProposal = teamFriendlyId => async (dispatch, getState) => {
  const team = getState().teams.details;
  if (team) {
    dispatch(addLoading());
    try {
      await TeamsFriendlyRequests.rejectProposal(team.id, teamFriendlyId);
    } finally {
      dispatch(removeLoading());
    }
  }
};
