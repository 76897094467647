import React from 'react';

import GuidePenaltiesAdditional from '../penaltiesAdditional';

export const GuideClubPenalties = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Serão 2 cobranças obrigatórias e caso o empate persista teremos cobranças alternadas até '
          + 'completar todos os jogadores de linha. Caso o empate continue, teremos um '}
          <b>pênalti adicional</b>
          {'.'}
        </p>
        <p>
          {'Caso o jogador tenha pontuado na rodada mais que o goleiro do outro clube, será '}
          <b>gol</b>
          {' (A pontuação será composta pelos 10 jogos).'}
        </p>
        <p>
          {'A ordem das cobranças será: Pivô, Ala Direita, Ala Esquerda e Fixo.'}
        </p>
      </div>
    </div>
    <GuidePenaltiesAdditional />
  </React.Fragment>
);
export default GuideClubPenalties;
