import React from 'react';

import SimpleLineChart from '../../../elements/charts/simpleLineChart';

const administrationReportTransactionsMonths = (props) => {
  const { months } = props;

  const data = months.map(month => ({
    name: month.period,
    netProfit: month.transactions.inflow.net_profit,
    grossProfit: month.transactions.inflow.gross_profit,
    outflow: month.transactions.outflow.value,
  }));

  const lines = {
    grossProfit: {
      name: 'Crédtios inseridos',
    },
    netProfit: {
      name: 'Receita Líquida',
    },
    outflow: {
      name: 'Crédito Gasto',
    },
  };

  return (
    <div>
      <h3>
        {'Transações'}
      </h3>
      <div style={{ height: '45rem' }}>
        <SimpleLineChart data={data} lines={lines} />
      </div>
    </div>
  );
};

export default administrationReportTransactionsMonths;
