import React from 'react';
import { connect } from 'react-redux';

import { accountUpdateRealTeam } from '../../../app/store/actions/account';

import AccountFavoriteClubForm from '../../account/favoriteClub/favoriteClubForm';

import TutorialWrapper from '../../elements/tutorial/tutorial';

export class TutorialAccountFavoriteClub extends React.Component {
  updateRealTeam = async (realTeamId) => {
    await this.props.updateRealTeam(realTeamId);
  }

  render() {
    const { realTeam } = this.props;

    return (
      <TutorialWrapper image="account/image_3.jpg">
        <h3 className="title-center">
          {'Clube do Coração'}
        </h3>
        <p>
          {'Informe o clube para o qual você torce e aumente a torcida dele no site.'}
        </p>
        <p>
          {'Escreva o nome do seu clube favorito e selecione. Caso não tenha preferência por qualquer clube, '
            + ' deixe este campo sem preenchimento.'}
        </p>
        <div className="account-favorite-club">
          {(realTeam && realTeam.name) ? (
            <div className="real-team">
              <img src={realTeam.badge && realTeam.badge.url} alt={realTeam.name} />
              <div className="title">
                {realTeam.name}
              </div>
            </div>
          ) : (
            <AccountFavoriteClubForm updateRealTeam={this.updateRealTeam} />
          )}
        </div>
        <button className="button normal" type="button" onClick={this.props.previousStep}>
          {'Voltar'}
        </button>
        {(realTeam && realTeam.name) && (
        <button className="button normal" type="button" onClick={() => this.updateRealTeam()}>
          {'Alterar'}
        </button>
        )}
        <button className="button normal" type="button" onClick={this.props.nextStep}>
          {realTeam && realTeam.name ? 'Continuar' : 'Pular'}
        </button>
      </TutorialWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateRealTeam: realTeamId => dispatch(accountUpdateRealTeam(realTeamId)),
});

const mapStateToProps = state => ({
  realTeam: state.user.real_team,
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorialAccountFavoriteClub);
