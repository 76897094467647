import React from 'react';
import { connect } from 'react-redux';
import {
  wallsStartMessages, wallsGetNewMessages, wallsGetOldMessages, wallsSendMessages, wallRemoveMessage,
} from '../../app/store/actions/walls';
import { activitiesSetNotificationsItem } from '../../app/store/actions/activities';

import UsersUtils from '../../app/utils/users';

import WallFeedMessages from './wallFeedMessages';
import MessagesNewForm from '../messages/new/messagesNewForm';
import MessagesRoomMessage from '../messages/room/messagesRoomMessage';

import CardSimple from '../elements/card/simple';

import MessangesData from '../../data/messages';

import WallsUtils from '../../app/utils/walls';

export class WallFeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished: false,
    };
  }

  componentWillMount() {
    this.updateInterval = setTimeout(() => this.updateMessages(), MessangesData.wall.updateTime);
  }

  componentDidMount() {
    const { wall, mode } = this.props;
    if (Object.keys(wall).length === 0) {
      this.props.reloadMessages(mode);
    } else {
      this.props.getNewMessages(mode);
    }
    this.updateNotificationsIfGeneral(mode);
  }

  componentWillUnmount() {
    clearTimeout(this.updateInterval);
  }

  getOldMessages = async () => {
    const { mode } = this.props;
    const result = await this.props.getOldMessages(mode);
    this.setState(() => ({ finished: (result && result.finished) }));
  };

  sendMessage = async (message) => {
    const { mode } = this.props;
    await this.props.sendMessage(mode, message);
    this.updateNotificationsIfGeneral(mode);
  };

  removeMessage = async (messageId) => {
    await this.props.removeMessage(messageId);
  };

  updateNotificationsIfGeneral = (mode) => {
    if (mode === MessangesData.wall.generalName) {
      this.props.resetWallNotifications();
    }
  }

  updateMessages() {
    const { mode, isIdle } = this.props;
    if (!isIdle) this.props.getNewMessages(mode);
    this.updateNotificationsIfGeneral(mode);

    this.updateInterval = setTimeout(() => this.updateMessages(), MessangesData.wall.updateTime);
  }

  render() {
    const {
      wall, minMessage, maxMessage, userId, mode, permissions, wallDefaultMessage,
    } = this.props;
    const { finished } = this.state;

    const messages = Object.keys(wall).map(id => wall[id]);
    WallsUtils.sortingMessages(messages);

    const canDelete = mode === 'general' && UsersUtils.hasAccess(permissions, 'messagesWall');

    return (
      <div className="content-container with-margin messages-wrapper">
        <div className="list-messages">
          {wallDefaultMessage.date && (
            <MessagesRoomMessage
              userId={0}
              message={{ created_at: wallDefaultMessage.date, message: wallDefaultMessage.message }}
            />
          )}
          <CardSimple>
            <MessagesNewForm
              minMessage={minMessage}
              maxMessage={maxMessage}
              onSubmit={this.sendMessage}
              canSend
              clean
            />
          </CardSimple>
          <WallFeedMessages
            messages={messages}
            userId={userId}
            removeMessage={canDelete && this.removeMessage}
          />
          {!finished && (
          <button className="button normal light-blue" type="button" onClick={this.getOldMessages}>
            {'Mais Mensagens'}
          </button>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  reloadMessages: mode => dispatch(wallsStartMessages(mode)),
  getNewMessages: mode => dispatch(wallsGetNewMessages(mode)),
  getOldMessages: mode => dispatch(wallsGetOldMessages(mode)),
  sendMessage: (mode, message) => dispatch(wallsSendMessages(mode, message)),
  resetWallNotifications: () => dispatch(activitiesSetNotificationsItem('wall', 0)),
  removeMessage: messageId => dispatch(wallRemoveMessage(messageId)),
});

const mapStateToProps = (state, ownProps) => ({
  wall: state.walls[ownProps.mode],
  isIdle: state.activities.idle,
  limitMessages: state.settings.messages && state.settings.messages.wall.limit,
  minMessage: state.settings.messages && state.settings.messages.message.min,
  maxMessage: state.settings.messages && state.settings.messages.message.max,
  userId: state.user.id,
  permissions: state.user.permissions || [],
  wallDefaultMessage: state.settings.wall || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(WallFeed);
