import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { roundBetsGetAvailableRounds } from '../../../../app/store/actions/roundsBets';

export class PlayersDetailsBetsSelected extends React.Component {
  state = {}

  async componentDidMount() {
    const {
      numberClosed, isAuthUser, roundAvailables,
      getAvailableRounds,
    } = this.props;

    let roundNumber = numberClosed;

    if (isAuthUser) {
      if (roundAvailables.length) {
        [roundNumber] = roundAvailables;
      } else {
        const availables = await getAvailableRounds();
        if (availables && availables.length) [roundNumber] = availables;
      }
    }

    await this.setRoundNumber(roundNumber);
  }

  async setRoundNumber(roundNumber) {
    await this.setState(() => ({ roundNumber }));
  }

  render() {
    const { roundNumber } = this.state;
    const { match } = this.props;
    if (roundNumber) return (<Redirect exact from={`${match.url}`} to={`${match.url}/${roundNumber}`} />);
    return (null);
  }
}

const mapDispatchToProps = dispatch => ({
  getAvailableRounds: () => dispatch(roundBetsGetAvailableRounds()),
});

const mapStateToProps = state => ({
  numberClosed: state.rounds.numberClosed,
  isAuthUser: state.players.details.isAuthUser,
  roundAvailables: state.roundsBets.availables,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayersDetailsBetsSelected);
