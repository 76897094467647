import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { transactionsGetList } from '../../app/store/actions/transactions';

import Table from '../elements/table';

import translator from '../../data/translator';

export class TransactionsPage extends React.Component {
  async componentDidMount() {
    await this.props.getList();
  }

  getType = (operation, type) => {
    switch (operation) {
      case 'inflow':
        return translator.s.transactions[type];
      case 'outflow':
        return translator.s.transactionExpenses[type];
      case 'purchase':
        return translator.s.purchases[type];
      default:
        return '';
    }
  }

  getExtra = (type, extra) => {
    switch (type) {
      case 'subscription':
        return `${extra.days} dias de assinatura`;
      case 'competition':
      case 'premium':
        return `${extra.name} (${translator.s.gameModes[extra.game_mode]})`;
      case 'chest':
        return `${extra.name} (${extra.amount}x)`;
      default:
        return '';
    }
  }

  currencyFormat = value => `R$ ${value.toFixed(2)}`

  render() {
    const { transactions, userCurrency } = this.props;

    const transactionsHeader = [
      { key: 'date', value: 'Data' },
      { key: 'operation', value: 'Operação' },
      { key: 'type', value: 'Tipo' },
      { key: 'extra', value: 'Descrição' },
      { key: 'currency', value: 'Valor' },
      { key: 'status', value: 'Estado' },
    ];

    const transactionsData = transactions.map(transaction => ({
      key: transaction.created_at,
      date: moment(transaction.created_at).format('DD-MM-YYYY HH:mm'),
      operation: translator.s.transactionOperations[transaction.operation],
      type: this.getType(transaction.operation, transaction.type),
      extra: this.getExtra(transaction.type, transaction.extra),
      currency: this.currencyFormat(transaction.currency),
      status: translator.s.transactionStatus[transaction.status],
    }));

    return (
      <div className="content-container margin-bottom">
        <div className="title-center">
          {'Compras'}
        </div>
        <div className="transactions-header">
          <h3>
            <span>
              {this.currencyFormat(userCurrency)}
            </span>
          </h3>
          <Link
            to="/carrinho"
            className="button normal"
          >
            {'Adicionar Crédito'}
          </Link>
        </div>
        <Table header={transactionsHeader} lines={transactionsData} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getList: () => dispatch(transactionsGetList()),
});

const mapStateToProps = state => ({
  transactions: state.transactions.list || [],
  userCurrency: (state.user.currency && state.user.currency.currently) || 0,
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
