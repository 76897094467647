import React from 'react';

import CardSimple from '../../elements/card/simple';
import BadgeList from '../../elements/badgeList/badgeList';
import Team from '../../teams/elements/team';
import Player from '../../players/elements/player';

const CampaignListItem = (props) => {
  const { item } = props;
  const { extras } = item;
  const { users = [], realTeam } = extras;

  return (
    <CardSimple>
      <BadgeList
        items={[{
          ...item,
        }]}
        gridInfo={{ lg: 12, md: 12, sm: 12 }}
      />
      <div className="team-content">
        <div className="label">Clube:</div>
        <Team data={realTeam} type="table" />
      </div>
      <div className="team-content">
        <div className="label">
          {'Jogadores: '}
        </div>
        {users.length > 1 ? (
          <div className="strikers">
            {users.map(player => <Player key={player.id} data={player} type="image" />)}
          </div>
        ) : (
          <Player data={users[0]} type="table" />
        )}
      </div>
    </CardSimple>
  );
};

export default CampaignListItem;
