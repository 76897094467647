import React from 'react';
import { connect } from 'react-redux';

import { campaignsCancel } from '../../../../app/store/actions/campaigns';

import LinesHeader from '../../../elements/headers/linesHeader';

import CampaignsData from '../../../../data/campaigns';

import { history } from '../../../../routers/appRouter';

const colors = CampaignsData.difficulties_colors;

export class CampaignInProgressHeader extends React.Component {
  giveUp = async () => {
    const { campaign } = this.props;
    await this.props.cancelCampaign(campaign.id);
    history.push('/jornada/criacao/');
  }

  render() {
    const { userId, campaign } = this.props;
    const { type, users } = campaign;
    const { name, difficulty, logo } = type;

    const color = colors[difficulty];
    const infoStyle = { color };

    const canGiveUp = campaign.status !== 'finished' && users[0] && users[0].id === userId;

    return (
      <LinesHeader color={colors[difficulty]}>
        <div className="info">
          <div className="logo">
            <img src={logo.url} alt={name} />
          </div>
          <div className="name" style={infoStyle}>
            {name}
            <div className="sub-name">{campaign.real_team.name}</div>
          </div>
          <div className="buttons">
            {canGiveUp && (
            <button className="button red" type="button" onClick={this.giveUp}>
              {'Desistir'}
            </button>
            )}
          </div>
        </div>
      </LinesHeader>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.user.id,
});

const mapDispatchToProps = dispatch => ({
  cancelCampaign: campaignId => dispatch(campaignsCancel(campaignId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInProgressHeader);
