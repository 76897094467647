import React from 'react';
import { connect } from 'react-redux';

import GameModeUtils from '../../../../../app/utils/gameMode';

import TeamsCompetitionsDetailsStatisticsRanking from './ranking/teamsCompetitionsDetailsStatisticsRanking';
import TeamsCompetitionsDetailsStatisticsStrikers from './strikers/teamsCompetitionsDetailsStatisticsStrikers';

import Tabs from '../../../../elements/tabs/tabs';

export class TeamsCompetitionsDetailsStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'ranking',
    };
  }

  selectType = async (type) => {
    await this.setState({ type });
  }

  render() {
    const { type } = this.state;
    const { gameMode } = this.props;

    const pages = GameModeUtils.data[gameMode].statistics_pages;
    const hasStrikers = pages.indexOf('strikers') !== -1;

    return (
      <div className="content-container margin-bottom competition-statistics">
        <div className="title-center-menu">
          {hasStrikers ? 'Estatísticas' : 'Ranking'}
        </div>
        {hasStrikers && (
        <Tabs
          items={[{
            name: 'Ranking',
            onClick: () => this.selectType('ranking'),
            active: type === 'ranking',
          }, {
            name: 'Artilharia',
            onClick: () => this.selectType('strikers'),
            active: type === 'strikers',
          }]}
        />
        )}
        {type === 'ranking' && <TeamsCompetitionsDetailsStatisticsRanking />}
        {type === 'strikers' && <TeamsCompetitionsDetailsStatisticsStrikers />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  gameMode: state.competitions.details.general.mode_type,
});

export default connect(mapStateToProps)(TeamsCompetitionsDetailsStatistics);
