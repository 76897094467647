import React from 'react';
import { connect } from 'react-redux';

import { faStopwatch, faList } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

import { roundsFinishRoundGames } from '../../../app/store/actions/rounds';

import CategoriesIcons from '../../elements/categories/icons/icons';

export class AdministrationRounds extends React.Component {
  finishRound = async () => {
    await this.props.finishRoundGames();
  }

  render() {
    const { currentRound } = this.props;
    const isAvailable = currentRound && currentRound.closed
     && !currentRound.finished_games && !currentRound.finished;

    const canFinisheh = currentRound && !currentRound.games.find(
      game => game.status === 'not_started' || game.status === 'in_progress',
    );

    const items = [];

    if (isAvailable && canFinisheh) {
      items.push({
        name: 'Encerrar Rodada',
        description: 'Os jogos serão salvos permanentemente  e a próxima rodada terá início',
        icon: faStopwatch,
        onClick: this.finishRound,
      });
    }

    items.push({
      name: 'Criar Rodada',
      url: 'rodadas/nova',
      description: 'Adicione os jogos da próxima rodada',
      icon: faPlusSquare,
    });

    items.push({
      name: 'Editar Rodadas',
      url: 'rodadas/lista',
      description: 'Edite informações de rodadas não finalizadas',
      icon: faList,
    });

    return (
      <div className="content-container margin-bottom">
        <div className="title-center-menu">
          {'Rodadas'}
        </div>
        <CategoriesIcons items={items} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishRoundGames: () => dispatch(roundsFinishRoundGames()),
});

const mapStateToProps = state => ({
  currentRound: state.rounds.current,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationRounds);
