import React from 'react';

const StepsButtons = props => (
  <div>
    {props.children}
    <div>
      {props.currentStep !== 1 && (
      <button className="button normal" type="button" onClick={props.previousStep}>
        {'Anterior'}
      </button>
      )}
      {props.currentStep < props.totalSteps && (
      <button className="button normal" type="button" onClick={props.nextStep}>
        {'Próximo'}
      </button>
      )}
      {props.currentStep < props.totalSteps && (
      <button className="button normal" type="button" onClick={props.lastStep}>
        {'Pular'}
      </button>
      )}
      {props.activeButton && (
      <button className="button normal light-blue" type="button" onClick={props.activeButton}>
        {'Ativar'}
      </button>
      )}
    </div>
  </div>
);

export default StepsButtons;
