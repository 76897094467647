import React from 'react';
import { Link } from 'react-router-dom';

import GamesUtils from '../../../../app/utils/games';

import CardSimple from '../../card/simple';

import Team from '../../../teams/elements/team';

const GamesTeamHighlight = ({ teamId, game }) => {
  const isHome = game.team_home.id === teamId;

  const team = isHome ? game.team_away : game.team_home;
  const homeScore = isHome ? game.score_home : game.score_away;
  const homeAway = isHome ? game.score_away : game.score_home;

  let resultClass = '';
  let resultSize = 0;

  if (homeScore !== undefined) {
    if (homeScore > homeAway) resultClass = 'win';
    else if (homeScore < homeAway) resultClass = 'lose';
    else resultClass = 'draw';

    if (homeScore >= 10) resultSize += 1;
    if (homeAway >= 10) resultSize += 1;
  }

  return (
    <div className="game-team-highlight">
      <CardSimple>
        <div className={`content ${resultClass}`}>
          <div className="team-content">
            <Team data={team} type="highlight" />
          </div>
          <div className="round">
            {'Rodada '}
            {game.round}
          </div>
          <div className="competition">
            {game.competition.name}
          </div>
          <Link className={`result size-${resultSize}`} to={GamesUtils.getLink(game)}>
            <span className="home">{homeScore}</span>
            <span className="divisor">-</span>
            <span className="away">{homeAway}</span>
          </Link>
        </div>
      </CardSimple>
    </div>
  );
};

export default GamesTeamHighlight;
