import React from 'react';

import SearchServer from '../../../../app/server/search';

import Dropdown from '../../../elements/dropdown';

import Player from '../../../players/elements/player';
import Team from '../../../teams/elements/team';
import Competition from '../../../teams/competitions/elements/competition';

import HeaderTopPrivateSearchDesktop from './searchDesktop';
import HeaderTopPrivateSearchMobile from './searchMobile';

export class HeaderTopPrivateSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      items: [],
    };
  }

  componentDidMount() {
    if (!this.props.mobile) document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    if (!this.props.mobile) document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onSearchTextChange = async (e) => {
    const { searchText } = this.state;
    const { value } = e.target;

    clearTimeout(this.state.searchTimeOut);

    if (value && value !== searchText) {
      const searchTimeOut = setTimeout(async () => {
        const result = await SearchServer.search(value);
        const items = result || {};
        this.fixItems(items);
      }, 400);
      this.setState(() => ({ searchTimeOut }));
    }

    this.setState(() => ({ searchText: value }));
  }

  clearSearchText = async () => {
    await this.setState(() => ({ searchText: '', items: [] }));
  }

  fixItems = async (items) => {
    const itemsFormated = [];

    (items.users || []).forEach((item) => {
      itemsFormated.push({
        key: item.id,
        value: <Player data={item} type="table" noIcons className="light" />,
      });
    });

    (items.teams || []).forEach((item) => {
      itemsFormated.push({
        key: item.id,
        value: <Team data={item} type="table" noIcons className="light" />,
      });
    });

    (items.competitions || []).forEach((item) => {
      itemsFormated.push({
        key: item.id,
        value: <Competition data={item} type="table" className="light" />,
      });
    });

    await this.setState({ items: itemsFormated });
  }

  setSearchRef = (node) => {
    this.searchRef = node;
  }

  handleClickOutside = (event) => {
    const { searchText } = this.state;
    if (searchText && this.searchRef && !this.searchRef.contains(event.target)) {
      this.clearSearchText();
    }
  }

  render() {
    const { items, searchText } = this.state;
    const { className } = this.props;

    const button = (
      <HeaderTopPrivateSearchMobile />
    );

    const search = (
      <HeaderTopPrivateSearchDesktop
        type="text"
        name="search"
        className={searchText ? 'large' : ''}
        placeholder="Buscar..."
        searchText={searchText}
        onChange={this.onSearchTextChange}
      />
    );

    const title = (
      <React.Fragment>
        {'Pesquisar'}
        <input
          className="dropdown-input"
          type="text"
          name="search"
          placeholder="Digite o termo da pesquisa"
          autoComplete="off"
          value={searchText}
          onChange={this.onSearchTextChange}
        />
      </React.Fragment>
    );

    return (
      <div className={`search ${className}`} ref={this.setSearchRef}>
        <Dropdown
          className="search-dropdown"
          title={title}
          button={button}
          links={items.slice(0, 10)}
          search={search}
          clearSearchText={this.clearSearchText}
          forceOpen={!!searchText}
        />
      </div>
    );
  }
}

export default HeaderTopPrivateSearch;
