import React from 'react';

import Steps from '../../../elements/steps/steps';

import TutorialNationalIntroduction from './introduction';
import TutorialNationalGame from './game';
import TutorialNationalActive from './active';

import TutorialGameModeActivation from '../activation';

const TutorialNational = () => (
  <Steps>
    <TutorialNationalIntroduction />
    <TutorialNationalGame />
    <TutorialGameModeActivation
      mode="national"
      pageName="propostas"
      content={<TutorialNationalActive />}
    />
  </Steps>
);

export default TutorialNational;
