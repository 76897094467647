import React from 'react';

import ImageLoad from '../image/imageLoad';

const CardSquad = ({
  name, tag, tagStyle, badge, nameClass = '',
}) => (
  <div className="card-all card-table card-squad" key={name}>
    <div className="badge">
      <ImageLoad
        src={badge.url}
        alt={badge.name}
        placeholder="/images/team_placeholder.png"
      />
    </div>
    <div className="texts">
      <div className={`name ${nameClass}`}>
        {name}
      </div>
      <div className={`tag ${tagStyle}`}>
        {tag}
      </div>
    </div>
  </div>
);

export default CardSquad;
