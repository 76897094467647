import React from 'react';

import Team from '../../elements/team';

const TeamsStatisticsAchievementsHeader = (props) => {
  const { top3 = [] } = props;


  return (
    <div className="top3-header">
      {top3.length === 3 && (
      <div className="podium">

        <div className="teams">
          <div className="team second">
            <Team data={top3[1].team} type="image" />
          </div>
          <div className="team first">
            <Team data={top3[0].team} type="image" />
          </div>
          <div className="team third">
            <Team data={top3[2].team} type="image" />
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default TeamsStatisticsAchievementsHeader;
