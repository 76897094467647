import React from 'react';
import { connect } from 'react-redux';

import { faClipboard } from '@fortawesome/free-solid-svg-icons';

import { scrollTo } from '../../../../../routers/appRouter';

import { teamsManagerGetUsers, teamsManagerUpdateFormation } from '../../../../../app/store/actions/teamsManager';

import GameModeUtil from '../../../../../app/utils/gameMode';
import TeamsUtil from '../../../../../app/utils/teams';
import FormationsData from '../../../../../data/formations';

import PagePlaceholder from '../../../../elements/pagePlaceholder';

import TeamsDetailsManagementEscalationManagerHeader from './header';
import TeamsDetailsManagementEscalationFormation from './formation/formation';
import TeamsDetailsManagementEscalationPlayers from './players/players';

export class TeamsDetailsManagementEscalationManager extends React.Component {
  constructor(props) {
    super(props);

    const { teamFormation } = props;
    let tatic = Object.keys(FormationsData.manager)[0];
    if (teamFormation.length) {
      tatic = `${teamFormation[0]}-${teamFormation[1]}-${teamFormation[2]}`;
    }

    const players = TeamsUtil.getManagerPlayers(props.teamPlayers);

    this.state = {
      view: 'formation',
      players,
      market: [],
      position: '',
      unsaved: false,
      tatic,
    };
  }

  async componentDidMount() {
    const players = await this.props.getUserManager();
    this.updateData(players);
  }

  getTeamPlayers = () => {
    const { players } = this.state;
    const teamPlayers = {};

    players.forEach((player) => {
      if (player.position) teamPlayers[player.user.id] = player.position;
    });

    return teamPlayers;
  }

  getPositionAbbr = position => TeamsUtil.getPositionAbbr('manager', position)

  getPositionName = position => TeamsUtil.getPositionName('manager', position)

  changeTatic = (e) => {
    const tatic = e.target.value;
    const { players } = this.state;

    const formation = FormationsData.manager[tatic];
    const positions = Object.keys(formation);

    players.forEach((player) => {
      if (positions.indexOf(player.position) === -1) {
        const newPosition = positions.slice().reverse()
          .find(position => !players.find(p => p.position === position));
        player.position = newPosition;
      }
    });

    const unsaved = this.getNewUnsaved(players.length);

    this.setState({ tatic, players, unsaved });
  }

  selectPosition = (position) => {
    this.setState({ view: 'players', position });
  }

  goToFormation = () => {
    this.setState({ view: 'formation' });
  }

  teamCost = () => {
    const { players } = this.state;
    return players.reduce((total, player) => total + player.user.valuation.value, 0);
  }

  getNewUnsaved = (playersTotal) => {
    const { teamCurrency } = this.props;

    return GameModeUtil.data.manager.total_players === playersTotal
    && teamCurrency - this.teamCost() >= 0;
  }

  selectPlayer = (userId) => {
    const { players, market, position } = this.state;

    const playerIndexPosition = players.findIndex(p => p.position === position);
    if (playerIndexPosition !== -1) players.splice(playerIndexPosition, 1);

    const playerIndexUser = players.findIndex(p => p.user.id === userId);
    if (playerIndexUser !== -1) players.splice(playerIndexUser, 1);

    const playerIn = market.find(p => p.id === userId);
    players.push({
      position,
      user: playerIn,
    });

    const unsaved = this.getNewUnsaved(players.length);

    this.setState({
      view: 'formation', position: '', players, unsaved,
    });

    scrollTo('menu-secondary');
  }

  resetPlayers = () => {
    this.setState({ view: 'formation', players: [], unsaved: false });
  }

  saveFormation = async () => {
    const { players, tatic } = this.state;

    const formation = players.map(player => ({
      position: player.position.split('_')[0],
      user: player.user.id,
    }));
    await this.props.updateFornamtion(formation, tatic.split('-').map(n => Number(n)));
    this.setState({ unsaved: false });
  }

  updateData(market) {
    this.setState(() => ({ market }));
  }

  render() {
    const {
      market, players, view, unsaved, tatic,
    } = this.state;
    const { teamCurrency, currentRound } = this.props;

    if (currentRound.closed) {
      return (
        <PagePlaceholder
          icon={faClipboard}
          text="Só é possível alterar a escalação com a rodada aberta para apostas."
        />
      );
    }

    const formation = FormationsData.manager[tatic];

    return (
      <div className="content-container with-margin escalation-formation">

        <TeamsDetailsManagementEscalationManagerHeader
          leftCurrency={teamCurrency - this.teamCost()}
          view={view}
          tatic={tatic}
          changeTatic={this.changeTatic}
          unsaved={unsaved}
          onReset={this.resetPlayers}
          onSave={this.saveFormation}
          goToBack={this.goToFormation}
        />
        <div className="content">
          {view === 'formation' && (
            <TeamsDetailsManagementEscalationFormation
              players={players}
              formation={formation}
              selectPosition={this.selectPosition}
              getPositionName={this.getPositionAbbr}
            />
          )}
          {view === 'players' && (
            <TeamsDetailsManagementEscalationPlayers
              teamPlayers={this.getTeamPlayers()}
              players={market}
              selectPlayer={this.selectPlayer}
              getPositionName={this.getPositionName}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUserManager: () => dispatch(teamsManagerGetUsers()),
  updateFornamtion: (players, formation) => dispatch(teamsManagerUpdateFormation(players, formation)),
});

const mapStateToProps = state => ({
  currentRound: state.rounds.current,
  teamPlayers: (state.teams.details.overview && state.teams.details.overview.players) || [],
  teamFormation: (state.teams.details.overview && state.teams.details.overview.formation) || [],
  teamCurrency: (state.teams.details.overview && state.teams.details.overview.currency.total) || 0,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsManagementEscalationManager);
