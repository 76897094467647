import UsersRequests from '../../server/users';
import { addLoading, removeLoading } from './loading';

export const setUsernameList = users => ({
  type: 'USERS_USERNAME_LIST_SET',
  users,
});

export const setVipList = users => ({
  type: 'USERS_VIP_LIST_SET',
  users,
});

export const usersGetUsernameList = () => async (dispatch, getState) => {
  const { usernameList } = getState().users;

  if (!usernameList) {
    dispatch(addLoading());

    try {
      const users = await UsersRequests.usernameList();
      dispatch(setUsernameList(users));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const usersGetVipList = () => async (dispatch, getState) => {
  const { vipList } = getState().users;

  if (!vipList) {
    dispatch(addLoading());

    try {
      const users = await UsersRequests.vipList();
      dispatch(setVipList(users));
    } finally {
      dispatch(removeLoading());
    }
  }
};
