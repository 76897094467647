import React from 'react';
import { connect } from 'react-redux';

import BadgeListItem from '../../elements/badgeList/badgeList';

import { campaignsHistoric } from '../../../app/store/actions/campaigns';

import CampaignsData from '../../../data/campaigns';

import Tabs from '../../elements/tabs/tabs';

export class CampaignHistoric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'all',
      campaigns: [],
    };
  }

  async componentDidMount() {
    const campaigns = await this.props.getCampaings();
    await this.setState({ campaigns });
  }

  selectType = async (view) => {
    await this.setState({ view });
  }

  render() {
    const { view, campaigns } = this.state;

    const views = [
      { value: 'all', name: 'Todos' },
      { value: 'victories', name: 'Vitórias' },
      { value: 'losses', name: 'Derrotas' },
    ];

    const colorsResult = CampaignsData.history_result;
    const colorsDifficulties = CampaignsData.difficulties_colors;

    const items = campaigns.filter((c) => {
      if (view === 'victories' && c.winner) return true;
      if (view === 'losses' && !c.winner) return true;
      return view === 'all';
    }).map((campaign) => {
      const colorDifficulty = colorsDifficulties[campaign.difficulty];

      return {
        id: campaign.id,
        color: colorDifficulty,
        name: campaign.type.name,
        tag: {
          name: campaign.winner ? 'Vitória' : 'Derrota',
          color: colorsResult[campaign.winner ? 'winner' : 'loss'],
          nameHover: campaign.real_team.name,
          colorHover: colorDifficulty,
        },
        badge: campaign.type.logo.url,
        mark: campaign.xp ? campaign.xp : undefined,
        link: `historico/${campaign.id}`,
      };
    });

    return (
      <div className="campaign-historic margin-bottom">
        <div className="title-center-menu">
          {'Histórico'}
        </div>
        <Tabs
          items={views.map(v => ({
            name: v.name,
            onClick: () => this.selectType(v.value),
            active: view === v.value,
          }))}
        />
        <div className="content-container">
          {items.length === 0 && <div className="competitions-empty">Sem jornadas até o momento</div>}
          <BadgeListItem
            items={items}
            gridInfo={{
              lg: 2, md: 3, sm: 4, xs: 6,
            }}
            center
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.campaigns.details,
});

const mapDispatchToProps = dispatch => ({
  getCampaings: () => dispatch(campaignsHistoric()),
});


export default connect(mapStateToProps, mapDispatchToProps)(CampaignHistoric);
