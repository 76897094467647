import React from 'react';

import Player from '../../../players/elements/player';
import TableAuth from '../../../elements/tableAuth';

const RecordsRoundsContentBetsTable = (props) => {
  const { players = [], skip } = props;

  const playersHeader = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'player', value: 'Jogador', className: 'name', colspan: 2,
    },
    {
      key: 'score', value: 'Pontos', className: 'number', tdClassName: 'high',
    },
    {
      key: 'difficulty', value: 'Dificuldade', className: 'number',
    },
  ];

  const playersData = players.map((row, index) => ({
    key: row.user.id,
    position: skip + index + 1,
    player: <Player data={row.user} type="table" />,
    score: row.score || 0,
    difficulty: (row.difficulty || 0).toFixed(2),
  }));

  return (
    <TableAuth header={playersHeader} lines={playersData} />
  );
};

export default RecordsRoundsContentBetsTable;
