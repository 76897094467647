import React from 'react';
import { connect } from 'react-redux';

import {
  marketPlayerBeAvailable, marketPlayerNotAvailable, marketPlayerRejectProposal, marketPlayerAcceptProposal,
}
  from '../../../../app/store/actions/market';

import { history } from '../../../../routers/appRouter';

import translator from '../../../../data/translator';

import TeamsInitiationProposalsSettings from './teamsInitiationProposalsSettings';
import TeamsInitiationProposalsList from './teamsInitiationProposalsList';

export class TeamsInitiationProposals extends React.Component {
  beAvailable = async () => {
    await this.props.beAvailable();
  }

  notAvailable = async () => {
    await this.props.notAvailable();
  }

  reject = async (teamId) => {
    await this.props.rejectProposal(teamId);
  }

  accept = async (teamId, teamName) => {
    const { gameMode } = this.props;

    await this.props.acceptProposal(teamId);

    const baseUri = `/${translator.uri.gameModes[gameMode]}`;
    history.push(`${baseUri}/${teamName}`);
  }

  render() {
    const { isAvailable, proposals } = this.props;

    return (
      <div className="content-container with-margin">
        <TeamsInitiationProposalsList
          proposals={proposals}
          reject={this.reject}
          accept={this.accept}
        />
        <TeamsInitiationProposalsSettings
          isAvailable={isAvailable}
          beAvailable={this.beAvailable}
          notAvailable={this.notAvailable}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  beAvailable: () => dispatch(marketPlayerBeAvailable(ownProps.modeData.mode)),
  notAvailable: () => dispatch(marketPlayerNotAvailable(ownProps.modeData.mode)),
  rejectProposal: teamId => dispatch(marketPlayerRejectProposal(teamId)),
  acceptProposal: teamId => dispatch(marketPlayerAcceptProposal(teamId)),
});

const mapStateToProps = (state, ownProps) => {
  const gameMode = ownProps.modeData.mode;
  let available = false;
  let proposals = [];

  if (state.user.game_modes_details && state.user.game_modes_details[gameMode]) {
    ({ available, proposals } = state.user.game_modes_details[gameMode].market.user);
  }

  return {
    isAvailable: available,
    proposals,
    gameMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsInitiationProposals);
