import React from 'react';
import { connect } from 'react-redux';
import { scrollTo } from '../../../../routers/appRouter';

import TeamsUtils from '../../../../app/utils/teams';

import Tabs from '../../../elements/tabs/tabs';

import TeamsDetailsPlayersPicture from './picture/picture';
import TeamsDetailsPlayersStatistics from './statistics';
import TeamsDetailsPlayersMarket from './market';

export class TeamsDetailsPlayers extends React.Component {
  constructor(props) {
    super(props);

    const menus = [
      { key: 'players', title: 'Jogadores' },
      { key: 'statistics', title: 'Estatísticas' },
      { key: 'market', title: 'Mercado' },
    ];

    this.state = {
      menus,
      view: menus[0],
    };
  }

  async componentDidMount() {
    scrollTo('menu-secondary');
  }

  changeView = async (view) => {
    await this.setState(() => ({ view }));
  }

  render() {
    const { menus, view } = this.state;
    const { team } = this.props;

    return (
      <div className="content-container with-margin">
        <div className="team-players">
          <Tabs
            items={menus.map(menu => ({
              name: menu.title,
              onClick: () => this.changeView(menu),
              active: view.key === menu.key,
            }))}
          />
          {view.key === 'players' && (
          <TeamsDetailsPlayersPicture
            players={team.players}
            gameMode={team.mode_type}
            color={TeamsUtils.getBackgroundColor(team)}
          />
          )}
          {view.key === 'statistics' && (
          <TeamsDetailsPlayersStatistics players={team.players} />
          )}
          {view.key === 'market' && (
          <TeamsDetailsPlayersMarket />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  team: state.teams.details && state.teams.details.overview,
});

export default connect(mapStateToProps)(TeamsDetailsPlayers);
