import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { usersGetUsernameList } from '../../../../../app/store/actions/users';

import Autocomplete from '../../../../elements/autocomplete';
import CardSimple from '../../../../elements/card/simple';
import Player from '../../../../players/elements/player';
import PlayersUtils from '../../../../../app/utils/players';

export class TeamsDetailsManagementRolesPresident extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autocompleteValue: '',
      players: [],
      changing: false,
    };
  }

  async componentDidMount() {
    await this.props.getUsernameList();
  }

  onAutocompleteValueChange = (value, playerSelected) => {
    const { autocompleteValue } = this.state;
    const { usernameList } = this.props;

    const stateUpdate = { autocompleteValue: value, playerSelected };

    if (value !== autocompleteValue) {
      const players = usernameList
        .filter(player => player.username.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        .slice(0, 5);
      stateUpdate.players = players;
    }

    this.setState(stateUpdate);
  }

  showSelect = async () => {
    await this.setState(() => ({ changing: true }));
  }

  changePresident = async () => {
    const { playerSelected } = this.state;
    await this.setState(() => ({ changing: false }));
    await this.props.changePresident(playerSelected.id);
  }

  removePresident = async () => {
    await this.props.removePresident();
  }

  handleChangePlayer = async (value, player) => {
    await this.onAutocompleteValueChange(value, player);
  }

  render() {
    const { autocompleteValue, players, changing } = this.state;
    const { president, icon } = this.props;

    return (
      <CardSimple>
        <FontAwesomeIcon icon={icon} />
        <h4>
          {'Presidente'}
        </h4>

        <div className="content">

          {!changing && (<Player data={president || PlayersUtils.completePlayer()} />)}

          {changing && (
            <Autocomplete
              getItemValue={item => item.username}
              items={players}
              renderItem={(item, isHighlighted) => (
                <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                  {item.username}
                </div>
              )}
              value={autocompleteValue}
              shouldItemRender={(player, value) => player.username.toLowerCase().indexOf(value.toLowerCase()) !== -1}
              onChange={(e, value) => this.onAutocompleteValueChange(value)}
              onSelect={this.handleChangePlayer}
              placeholder="Digite o nome do jogador"
            />
          )}
        </div>

        {!changing ? (
          <button className="button normal" type="button" onClick={this.showSelect}>
            {'Alterar'}
          </button>
        ) : (
          <button className="button normal" type="button" onClick={this.changePresident}>
            {'Alterar'}
          </button>
        )}

        {!changing && president && (
          <button className="button normal red" type="button" onClick={this.removePresident}>
            {'Remover'}
          </button>
        )}

      </CardSimple>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUsernameList: () => dispatch(usersGetUsernameList()),
});

const mapStateToProps = state => ({
  usernameList: state.users.usernameList || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsManagementRolesPresident);
