import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import {
  faCode, faClipboard, faUserTie, faFileSignature,
} from '@fortawesome/free-solid-svg-icons';

import {
  teamsRolesSetPresident, teamsRolesUnsetPresident, teamsRolesSetRole, teamsRolesUnsetRole,
} from '../../../../../app/store/actions/teamsRoles';
import UsersSelector from '../../../../../app/store/selectors/users';

import TeamsDetailsManagementRolesPresident from './teamsDetailsManagementRolesPresident';
import TeamsDetailsManagementRolesbasics from './teamsDetailsManagementRolesBasics';

export class TeamsDetailsManagementRoles extends React.Component {
  changePresident = async (userId) => {
    await this.props.setPresident(userId);
  }

  removePresident = async () => {
    await this.props.unsetPresident();
  }

  changeRole = async (userId, role) => {
    await this.props.setTeamRole(role, userId);
  }

  removeRole = async (role) => {
    await this.props.unsetTeamRole(role);
  }

  canChangePresident() {
    const { userModeTeams, isMaster, teamRegion } = this.props;
    return isMaster || (userModeTeams.federations || []).some(federation => federation.region === teamRegion);
  }

  render() {
    const {
      teamPlayers, teamPresident, teamCoachId, teamManagerId, teamMarketerId,
    } = this.props;

    const players = teamPlayers
      .filter(player => !teamPresident || teamPresident.id !== player.user.id)
      .map(player => player.user);

    return (
      <div className="content-container with-margin roles-management">
        <Grid fluid>
          <Row center="xs">
            <Col sm={6} lg={3}>
              <TeamsDetailsManagementRolesbasics
                teamRole="coach"
                players={players}
                playerId={teamCoachId}
                changeRole={this.changeRole}
                removeRole={this.removeRole}
                icon={faClipboard}
              />
            </Col>
            <Col sm={6} lg={3}>
              <TeamsDetailsManagementRolesbasics
                teamRole="manager"
                players={players}
                playerId={teamManagerId}
                changeRole={this.changeRole}
                removeRole={this.removeRole}
                icon={faFileSignature}
              />
            </Col>
            <Col sm={6} lg={3}>
              <TeamsDetailsManagementRolesbasics
                teamRole="marketer"
                players={players}
                playerId={teamMarketerId}
                changeRole={this.changeRole}
                removeRole={this.removeRole}
                icon={faCode}
              />
            </Col>
            {this.canChangePresident() && (
            <Col sm={6} lg={3}>
              <TeamsDetailsManagementRolesPresident
                president={teamPresident}
                changePresident={this.changePresident}
                removePresident={this.removePresident}
                icon={faUserTie}
              />
            </Col>
            )}
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPresident: userId => dispatch(teamsRolesSetPresident(userId)),
  unsetPresident: () => dispatch(teamsRolesUnsetPresident()),
  setTeamRole: (role, userId) => dispatch(teamsRolesSetRole(role, userId)),
  unsetTeamRole: role => dispatch(teamsRolesUnsetRole(role)),
});

const mapStateToProps = (state) => {
  const { details: team } = state.teams;
  const { game_modes_details: gameModesDetails } = state.user;
  const teamOverview = team.overview || {};

  let userModeTeams;
  if (state.teams.details && state.teams.details.gameMode) {
    userModeTeams = gameModesDetails && gameModesDetails[team.gameMode] && gameModesDetails[team.gameMode];
  }

  return {
    teamId: state.teams.details.id,
    teamPlayers: teamOverview.players || [],
    teamPresident: teamOverview.president,
    teamCoachId: teamOverview.coach && teamOverview.coach.id,
    teamManagerId: teamOverview.manager && teamOverview.manager.id,
    teamMarketerId: teamOverview.marketer && teamOverview.marketer.id,
    teamRegion: teamOverview.region_tag,
    isMaster: UsersSelector.isMaster(state),
    userModeTeams,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsManagementRoles);
