import React from 'react';
import { Line } from 'rc-progress';

const RcProgressLine = props => (
  <Line
    {...props}
    style={{
      width: '100%',
      height: '100%',
      margin: '0 0 0.8rem',
      padding: 0,
    }}
  />
);

export default RcProgressLine;
