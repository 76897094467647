import RoundsBetsRequests from '../../server/reports';
import { addLoading, removeLoading } from './loading';

export const setGeneral = general => ({
  type: 'REPORTS_GENERAL_SET',
  general,
});

export const setPeriod = (period, data) => ({
  type: 'REPORTS_PERIOD_SET',
  period,
  data,
});

export const reportsGetGeneral = () => async (dispatch, getState) => {
  if (!getState().reports.general) {
    dispatch(addLoading());
    try {
      const general = await RoundsBetsRequests.getGeneral();
      dispatch(setGeneral(general));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const reportsGetPeriod = (year, month) => async (dispatch, getState) => {
  const period = `${year}-${month}`;
  if (!getState().reports.periods[period]) {
    dispatch(addLoading());
    try {
      const data = await RoundsBetsRequests.getPeriod(year, month);
      dispatch(setPeriod(period, data));
    } finally {
      dispatch(removeLoading());
    }
  }
};
