import React from 'react';
import { connect } from 'react-redux';

import { teamsPortalSave, teamsPortalDownload } from '../../../../../app/store/actions/teams';

import Editor from '../../../../elements/editor/editor';

import PortalData from '../../../../../data/portal';

export class TeamsDetailsManagementPortal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      canSave: false,
    };
  }

  async componentDidMount() {
    const { teamPortalUrl } = this.props;
    if (teamPortalUrl) {
      const portalFile = await this.props.downloadPortal(teamPortalUrl);
      this.setState({ code: portalFile.code });
    }
  }

  handleChange = (code) => {
    if (code.length <= PortalData.maxCharacters) {
      this.setState({ code, canSave: true });
    }
  }

  savePortal = async () => {
    const { code } = this.state;
    await this.props.savePortal(code);
    await this.setState({ canSave: false });
  }

  render() {
    const { code, canSave } = this.state;

    return (
      <div className="content-container with-margin portal-management">
        <div className="title-center-menu">
          {'Portal'}
        </div>
        <Editor
          placeholder="Digite o código Html"
          value={code}
          updateValue={this.handleChange}
        />
        <button
          type="button"
          className="button normal light-blue button-bet"
          disabled={!canSave}
          onClick={this.savePortal}
        >
          {'Salvar Portal'}
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  downloadPortal: () => dispatch(teamsPortalDownload()),
  savePortal: code => dispatch(teamsPortalSave(code)),
});

const mapStateToProps = state => ({
  teamPortalUrl: state.teams.details.overview.portal && state.teams.details.overview.portal.url,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsManagementPortal);
