import React from 'react';

import MultipleCarousel from '../../../elements/multipleCarousel';

import Team from '../../elements/team';

const TeamsCompetitionsPremiumsCardDetailsCompetition = (props) => {
  const { teams = [], maxTeams } = props;

  const items = [];

  if (teams.length === 0) return (null);

  for (let i = 0; i < teams.length; i += 2) {
    items.push(
      <React.Fragment>
        <Team data={teams[i]} type="table" className="light" />
        {i + 1 < teams.length && <Team data={teams[i + 1]} type="table" className="light" />}
      </React.Fragment>,
    );
  }


  return (
    <div className="teams">
      <MultipleCarousel
        items={items}
        xs={1}
        sm={1}
        md={1}
        lg={2}
        indicator
      />
      <div className="total">
        {teams.length}
        {'/'}
        {maxTeams}
        {' participantes'}
      </div>
    </div>
  );
};

export default TeamsCompetitionsPremiumsCardDetailsCompetition;
