import React from 'react';
import moment from 'moment-timezone';

import CompetitionsUtils from '../../../../app/utils/competitions';
import CampaignsData from '../../../../data/campaigns';

import BadgeListItem from '../../../elements/badgeList/badgeList';

const PlayersDetailsAchievementsList = (props) => {
  const { triumphs = [] } = props;

  const items = triumphs.slice().reverse().map((triumph) => {
    const position = {};

    switch (triumph.category) {
      case 'champion':
        position.number = 1;
        position.name = 'Campeão';
        break;
      case 'second_place':
        position.number = 2;
        position.name = 'Vice Campeão';
        break;
      case 'third_place':
        position.number = 3;
        position.name = 'Terceiro Lugar';
        break;
      default:
        position.number = 4;
        position.name = 'Artilheiro';
        break;
    }

    if (triumph.competition) {
      const { competition } = triumph;

      return {
        id: triumph.date,
        color: CompetitionsUtils.getCompetitionColor(competition),
        name: triumph.name || competition.name,
        tag: {
          name: position.name,
          color: CompetitionsUtils.getPositionColor(position.number),
          nameHover: moment(triumph.date).format('MMMM YYYY'),
          colorHover: CompetitionsUtils.getCompetitionColor(competition),
        },
        badge: competition.logo.url,
        locked: false,
        mark: triumph.xp ? triumph.xp : undefined,
        icon: triumph.mode_type && `/images/game_modes/${triumph.mode_type}.svg`,
        link: CompetitionsUtils.getLink(competition),
      };
    }

    if (triumph.campaign) {
      const { campaign } = triumph;

      const colorsDifficulties = CampaignsData.difficulties_colors;
      const colorDifficulty = colorsDifficulties[campaign.difficulty];

      return {
        id: triumph.date,
        color: colorDifficulty,
        name: triumph.name || campaign.type.name,
        tag: {
          name: position.name,
          color: CompetitionsUtils.getPositionColor(position.number),
          nameHover: moment(triumph.date).format('MMMM YYYY'),
          colorHover: colorDifficulty,
        },
        badge: campaign.type.logo.url,
        locked: false,
        mark: triumph.xp ? triumph.xp : undefined,
        icon: triumph.mode_type && `/images/game_modes/${triumph.mode_type}.svg`,
        link: `/jornada/historico/${campaign.id}`,
      };
    }

    return {
      id: triumph.date,
      color: CompetitionsUtils.getLeagueColor('final'),
      name: triumph.name,
      tag: {
        name: position.name,
        color: CompetitionsUtils.getPositionColor(position.number),
        nameHover: moment(triumph.date).format('MMMM YYYY'),
        colorHover: CompetitionsUtils.getLeagueColor('final'),
      },
      badge: '/images/old_logo.png',
      locked: false,
      mark: triumph.xp ? triumph.xp : undefined,
    };
  });

  return (
    <div className="competitions-items margin-bottom">
      {items.length === 0 && <div className="competitions-empty">Sem conquistas até o momento</div>}
      <BadgeListItem
        items={items}
        gridInfo={{
          lg: 2, md: 3, sm: 4, xs: 6,
        }}
        center
      />
    </div>
  );
};

export default PlayersDetailsAchievementsList;
