import React from 'react';
import { Link } from 'react-router-dom';

import translator from '../../../../data/translator';
import GamesUtils from '../../../../app/utils/games';

import GamesHighlightTeam from './highlightTeam';

const GamesHighlight = ({ game }) => (
  <div className="game-card">
    <div className="mode-header">
      {game.competition.name}
    </div>
    <div className="game">
      <GamesHighlightTeam team={game.team_home} />
      <div className="score">
        <div className="top">
          {translator.s.gameModes[game.mode_type]}
        </div>
        <Link to={GamesUtils.getLink(game)}>
          <div className="middle">
            <div
              className={`value value__left${game.score_home > game.score_away ? ' winner' : ''}`}
            >
              {game.score_home}
            </div>
            <div className="divisor">
              {'x'}
            </div>
            <div
              className={`value value__right${game.score_away > game.score_home ? ' winner' : ''}`}
            >
              {game.score_away}
            </div>
          </div>
        </Link>
        <div className="bottom">
          {'Rodada '}
          {game.round}
        </div>
      </div>
      <GamesHighlightTeam team={game.team_away} />
    </div>
  </div>
);

export default GamesHighlight;
