import { setRound } from './rounds';
import { madeBet } from './user';
import RoundsBetsRequests from '../../server/roundsBets';
import { addLoading, removeLoading } from './loading';
import { showAlertError, showAlertSuccess } from './alert';

export const saveUserBet = (userId, roundNumber, bet) => ({
  type: 'ROUNDS_BETS_USER_BET_SET',
  userId,
  roundNumber,
  bet,
});

export const setAvailablesSuggestion = (availables, suggestion = {}) => ({
  type: 'ROUNDS_BETS_AVAILABLES_SUGGESTION_SET',
  availables,
  suggestion,
});

export const roundBetsGetAuthUserBet = roundNumber => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const data = await RoundsBetsRequests.getAuthUserBets(roundNumber);
    const suggestion = {};
    suggestion[roundNumber] = data.suggestion;
    dispatch(setAvailablesSuggestion(data.availables, suggestion));
    if (data.bet) {
      const userId = getState().user.id;
      dispatch(saveUserBet(userId, roundNumber, data.bet));
    }
    if (data.round) dispatch(setRound(roundNumber, data.round));
    return data.availables;
  } finally {
    dispatch(removeLoading());
  }
};

export const roundBetsGetUserBet = (userId, roundNumber) => async (dispatch) => {
  const data = await RoundsBetsRequests.getUserBets(userId, roundNumber);
  if (data.bet) dispatch(saveUserBet(userId, roundNumber, data.bet));
  if (data.round) dispatch(setRound(roundNumber, data.round));
};

export const roundBetsStartUserBet = (userId, roundNumber) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await dispatch(roundBetsGetUserBet(userId, roundNumber));
  } finally {
    dispatch(removeLoading());
  }
};

export const roundBetsSubmitBet = (roundNumber, scores) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    await RoundsBetsRequests.bet(roundNumber, scores);

    const { user, rounds } = getState();
    const userId = user.id;

    dispatch(saveUserBet(userId, roundNumber, {
      round: roundNumber,
      games: scores,
    }));

    if (rounds.numberClosed + 1 === roundNumber && !rounds.isProgress) dispatch(madeBet());
    dispatch(showAlertSuccess('Sua aposta foi salva com sucesso! Você receberá uma confirmação em seu e-mail.'));
  } catch (err) {
    dispatch(showAlertError());
  } finally {
    dispatch(removeLoading());
  }
};

export const roundBetsGetAvailableRounds = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const availables = await RoundsBetsRequests.getAvailableRounds();
    dispatch(setAvailablesSuggestion(availables));
    return availables;
  } finally {
    dispatch(removeLoading());
  }
};
