const initialState = {
  list: {},
  details: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GAMES_LIST_SET': {
      const {
        gameMode, gameType, roundNumber, page, games, total, isCurrent,
      } = action;
      if (!state.list[gameMode]) state.list[gameMode] = {};
      if (!state.list[gameMode][gameType]) state.list[gameMode][gameType] = {};
      if (!state.list[gameMode][gameType][roundNumber]) {
        state.list[gameMode][gameType][roundNumber] = {
          pages: [],
          total: 0,
          isCurrent,
        };
      }

      state.list[gameMode][gameType][roundNumber].pages[page - 1] = games;
      state.list[gameMode][gameType][roundNumber].pages = [...state.list[gameMode][gameType][roundNumber].pages];
      state.list[gameMode][gameType][roundNumber].total = total;
      return { ...state };
    }
    case 'GAMES_DETAILS_SET':
      state.details = {
        game: action.game,
        roundUpdate: action.roundUpdate,
      };
      return { ...state };
    case 'GAMES_DETAILS_RESET':
      delete state.details[action.gameId];
      return state;
    default:
      return state;
  }
};
