import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
} from '@fortawesome/free-solid-svg-icons';

import Dropdown from '../../elements/dropdown';

import { authenticateLogout } from '../../../app/store/actions/authenticate';
import PlayersUtils from '../../../app/utils/players';

import { history } from '../../../routers/appRouter';

export class HeaderTopMenu extends React.Component {
  handleLogout = async () => {
    await this.props.logout();
    history.push('/');
  };

  render() {
    const {
      username, isActive, isAdmin, notifications,
    } = this.props;

    const button = (
      <button type="button" className="button button--link">
        <FontAwesomeIcon icon={faBars} />
        {notifications.messages + notifications.collectibles > 0 && (
        <mark className="mark-mobile show-for-mobile">{notifications.messages + notifications.collectibles}</mark>
        )}
      </button>
    );

    const links = [];

    links.push({ key: 'player', url: PlayersUtils.getLink(username), value: 'Meu Jogador' });
    links.push({ key: 'records', url: '/registros', value: 'Jogadores' });
    links.push({ key: 'rules', url: '/regulamento', value: 'Regulamento' });
    links.push({ key: 'news', url: '/noticias', value: 'Notícias' });

    if (isActive) {
      links.push({ key: 'wall', url: '/mural', value: 'Mural' });
      links.push({ key: 'transacations', url: '/compras', value: 'Compras' });
      links.push({
        key: 'messages', url: '/mensagens', value: 'Mensagens', notifications: notifications.messages,
      });
      links.push({ key: 'vip', url: '/prime', value: 'Membro Prime' });
      links.push({
        key: 'collectibles', url: '/colecionaveis', value: 'Coleção', notifications: notifications.collectibles,
      });

      if (isAdmin) links.push({ key: 'administration', url: '/administrativo', value: 'Administração' });
    }

    links.push({ key: 'account', url: '/conta', value: 'Conta' });
    links.push({ key: 'logout', onClick: this.handleLogout, value: 'Sair' });

    return (
      <div className="menu">
        <Dropdown
          title={username}
          message={!isActive && 'Aguardando aprovação!'}
          button={button}
          links={links}
          className="menu-dropdown"
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authenticateLogout()),
});

const mapStateToProps = state => ({
  username: state.user && state.user.username,
  isActive: state.user.status === 'active',
  isAdmin: !!state.user.permissions.length,
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTopMenu);
