import React from 'react';

import Table from '../../../../../elements/table';
import Player from '../../../../../players/elements/player';

import PlayerTableValuation from '../../../../../players/table/valuation';
import PlayerTableBet from '../../../../../players/table/bet';
import PlayerTableDiscard from '../../../../../players/table/discard';

export const TeamsDetailsManagementEscalationPlayersTable = (props) => {
  const { players, teamPlayers } = props;

  const header = [
    { key: 'player', value: 'Escalação', className: 'name' },
    { key: 'position', value: 'Posição Atual', className: 'number' },
    {
      key: 'value', value: 'Valor', className: 'number', tdClassName: 'high',
    },
    { key: 'bet', value: 'Aposta', className: 'number' },
    { key: 'discard', value: 'Descarte', className: 'number' },
    { key: 'options', className: 'number' },
  ];

  const data = players.map(player => ({
    key: player.id,
    player: <Player data={player} type="table" />,
    position: props.getPositionName(teamPlayers[player.id]),
    value: <PlayerTableValuation valuation={player.valuation} />,
    bet: <PlayerTableBet bets={player.bets} />,
    discard: <PlayerTableDiscard discard={player.discard} />,
    options: (
      <button type="button" className="button" onClick={() => props.selectPlayer(player.id)}>
        {'Selecionar'}
      </button>
    ),
  }));

  return (<Table header={header} lines={data} />);
};

export default TeamsDetailsManagementEscalationPlayersTable;
