import React from 'react';
import { connect } from 'react-redux';

export const Alert = ({ message, type }) => (
  <React.Fragment>
    {type && (
    <div className={`alert-box ${type}`}>
      {message}
    </div>
    )}
  </React.Fragment>
);

const mapStateToProps = state => ({
  message: state.alert.message,
  type: state.alert.type,
});

export default connect(mapStateToProps)(Alert);
