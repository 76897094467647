import React from 'react';

export const GuideGeneralWall = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {`Fique à vontade para postar e colaborar com comentários e sugestões no site. Saiba também 
          que a sua participação é fundamental e muito importante para nós! Respeitamos a opinião de 
          cada um que interage em nosso site e estamos dispostos a responder a todos, porém, para o 
          bom funcionamento, algumas regras precisam ser estabelecidas.`}
        </p>
        <p>
          {`Todos os comentários serão analisados pela administração do site. As interações que 
          forem consideradas inadequadas ou ofensivas serão ocultadas do mural.`}
        </p>
        <p>
          {`Ocorrerá também a análise do comportamento dos usuários que não respeitem as normas com 
          frequência, com possibilidade de banimento do mural e/ou do site.`}
        </p>
        <p>
          {`Todos os usuários devem cumprir com a Política de Uso e Normas de Boa Conduta. Para 
          manter um bom convívio, o Barbolão reserva-se ao direito de não responder e remover mensagens 
          que se enquadrarem nas seguintes categorias:`}
        </p>
      </div>
    </div>
    <div className="section">
      <h4>
        {'Política de Uso e Normas de Boa Conduta'}
      </h4>
      <div className="section-content">
        <ol className="list-number">
          <li>
            {`Difamação, abuso, assedio, perseguição, ameaça ou conteúdos que violem os direitos legais 
            (tais como direitos de privacidade e publicidade) de outros.`}
          </li>
          <li>
            {'Conteúdos que caracterizem prática de spam e/ou publicidade não autorizada.'}
          </li>
          <li>
            {`Conteúdos difamatórios, preconceituosos, transgressores, obscenos, indecentes, enganosos, 
            com fins lucrativos e/ou políticos, citação de outras marcas e empresas ou informações ilícitas.`}
          </li>
        </ol>
      </div>
      <p>
        {'Agradecemos a compreensão e esperamos que goste e mantenha a paz entre os usuários.'}
      </p>
    </div>
  </React.Fragment>
);
export default GuideGeneralWall;
