import React from 'react';
import { connect } from 'react-redux';

import Steps from '../elements/steps/steps';

import { registrationCheckData, registrationSave } from '../../app/store/actions/registration';
import { authenticateLogin } from '../../app/store/actions/authenticate';

import RegistrationIntroduction from './introduction';
import RegistrationAccountForm from './accountForm';
import RegistrationUserForm from './userForm';
import RegistrationError from './error';

import { history } from '../../routers/appRouter';

export class RegistrationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {
        email: '',
        username: '',
        password: '',
        cpf: '',
        phone: '',
      },
      user: {
        first_name: '',
        last_name: '',
        gender: '',
        birth: '',
        location: '',
      },
    };
  }

  saveAccountData = async (data) => {
    await this.setState(() => ({ account: data }));
    try {
      await this.props.checkData(data);
    } catch (err) {
      return err;
    }
  }

  saveUserData = async (data) => {
    await this.setState(() => ({ user: data }));
    return this.registration();
  }

  registration = async () => {
    const { account, user } = this.state;
    try {
      await this.props.registration({
        ...account,
        ...user,
      });
      await this.props.login(account.email, account.password);
      history.push('/tutorial/conta');
    } catch (err) {
      return err;
    }
  }

  render() {
    return (
      <Steps>
        <RegistrationIntroduction />
        <RegistrationAccountForm saveAccountData={this.saveAccountData} />
        <RegistrationUserForm saveUserData={this.saveUserData} />
        <RegistrationError />
      </Steps>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkData: data => dispatch(registrationCheckData(data)),
  registration: data => dispatch(registrationSave(data)),
  login: (email, password) => dispatch(authenticateLogin(email, password)),
});

export default connect(undefined, mapDispatchToProps)(RegistrationPage);
