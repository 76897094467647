import React from 'react';

import TutorialWrapper from '../elements/tutorial/tutorial';

const RegistrationIntroduction = props => (
  <TutorialWrapper image="registration/image_1.jpg">
    <h3 className="title-center">
      {'Cadastro'}
    </h3>
    <p className="text">
      {'O Barbolão é um site de entretenimento, desenvolvido com o intuito de oferecer '
      + 'divertimento aos seus usuários. Consta, basicamente, de apostas esportivas (palpites) '
      + 'do futebol Nacional e Internacional. Agora você pode fazer parte desta comunidade e '
      + 'testar seus conhecimentos de Futebol, de uma forma diferente.'}
    </p>
    <button className="button normal" type="button" onClick={props.nextStep}>
      {'Começar'}
    </button>
  </TutorialWrapper>
);

export default RegistrationIntroduction;
