import React from 'react';

import Player from '../../players/elements/player';

const AdministrationUsersIp = ({ ip, users = [] }) => {
  if (users.length) {
    return users.slice(0, 3).map(user => (
      <Player key={user.id} data={user} type="image" />
    ));
  }

  return ip;
};

export default AdministrationUsersIp;
