import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { collectiblesUpdateChests } from '../../app/store/actions/collectibles';

import CollectiblesChests from './collectiblesChests/collectiblesChests';
import CollectiblesChestItems from './collectiblesChestItems/collectiblesChestItems';

export class CollectiblesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.updateChests();
  }

  render() {
    const { chests, userCollectibles, match } = this.props;
    const { url } = match;

    return (
      <div className="collectibles">
        <div className="content-container">
          {chests && userCollectibles && (
          <Switch>
            <Route exact path={url} component={CollectiblesChests} />
            <Route path={`${url}/:chestName`} component={CollectiblesChestItems} />
          </Switch>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateChests: () => dispatch(collectiblesUpdateChests()),
});

const mapStateToProps = state => ({
  chests: state.collectibles.chests,
  userCollectibles: state.user.collectibles,
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectiblesPage);
