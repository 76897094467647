import React from 'react';
import { connect } from 'react-redux';

import { teamsGetList } from '../../../../app/store/actions/teams';

import TeamsStatisticsConfrontationSearch from './search';

export class TeamsStatisticsConfrontationFirstTeam extends React.Component {
  async componentDidMount() {
    await this.props.getTeams();
  }

  select = async (team) => {
    await this.props.setFirstTeam(team);
    this.props.nextStep();
  }

  render() {
    const { teams } = this.props;
    return (
      <div className="content-container">
        <div className="title-line title-page">
          {'Primeira equipe'}
        </div>
        <TeamsStatisticsConfrontationSearch teams={teams} select={this.select} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTeams: () => dispatch(teamsGetList(ownProps.mode)),
});

const mapStateToProps = (state, ownProps) => ({
  teams: state.teams.list[ownProps.mode] || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatisticsConfrontationFirstTeam);
