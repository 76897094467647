import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { scrollTo } from '../../../../routers/appRouter';
import { playersGetAchievements } from '../../../../app/store/actions/players';

import translator from '../../../../data/translator';
import Tabs from '../../../elements/tabs/tabs';

import PlayersDetailsAchievementsList from './playersDetailsAchievementsList';

export class PlayersDetailsAchievements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      achievements: {},
      selectedType: 'official',
    };
  }

  async componentDidMount() {
    const { achievements } = this.props;
    if (!achievements) await this.props.getAchievements();
    else this.updateData(achievements);
    scrollTo('menu-secondary');
  }

  componentWillReceiveProps(nextProps) {
    const { achievements } = nextProps;
    if (achievements) this.updateData(achievements);
  }

  selectType = async (type) => {
    await this.setState({ selectedType: type });
  }

  updateData(achievements = {}) {
    this.setState(() => ({ achievements }));
  }

  render() {
    const { achievements, selectedType } = this.state;

    if (!achievements) return (null);

    const { triumphs = [] } = achievements;

    const types = ['official'];
    let hasOld = false;
    let hasCampaign = false;

    const firstDay = moment('2018-01-01', 'YYYY-MM-DD');

    triumphs.forEach((t) => {
      if (!hasOld && moment(t.date).isBefore(firstDay)) hasOld = true;
      else if (t.competition && types.indexOf(t.competition.type) === -1) {
        types.push(t.competition.type);
      } else if (t.mode_type === 'campaign') hasCampaign = true;
    });

    const typesTabs = types.map(type => ({
      name: translator.p.competitions[type],
      onClick: () => this.selectType(type),
      active: type === selectedType,
    }));

    if (hasOld) {
      typesTabs.push({
        name: 'Antigos',
        onClick: () => this.selectType('old'),
        active: selectedType === 'old',
      });
    }

    if (hasCampaign) {
      typesTabs.splice(1, 0, {
        name: 'Campanha',
        onClick: () => this.selectType('campaign'),
        active: selectedType === 'campaign',
      });
    }

    const selectedAchievements = triumphs.filter(t => (
      (selectedType === 'campaign' && t.mode_type === 'campaign')
      || (selectedType === 'old' && moment(t.date).isBefore(firstDay))
      || (t.competition && t.competition.type === selectedType)
    ));

    return (
      <div className="team-achievements content-container">
        <div className="title-center-menu">
          {'Conquistas'}
        </div>
        <Tabs items={typesTabs} />
        <PlayersDetailsAchievementsList triumphs={selectedAchievements} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAchievements: () => dispatch(playersGetAchievements()),
});

const mapStateToProps = state => ({
  achievements: state.players.details && state.players.details.achievements,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayersDetailsAchievements);
