import React from 'react';

import PlayersDetailsBetsRoundInfo from './info';
import PlayersDetailsBetsRoundTeamNames from './teamNames';
import PlayersDetailsBetsRoundTeam from './team';

import betsData from '../../../../../data/bets';
import colorsData from '../../../../../data/colors';

const PlayersDetailsBetsRoundScore = ({ game }) => (
  <div className="game">
    <PlayersDetailsBetsRoundTeamNames game={game} />
    <div className="bet bet-score">
      <PlayersDetailsBetsRoundTeam team={game.team_home} type="home" />

      <div className={`result${game.status === 'in_progress' ? ' in-progress' : ''}`}>
        <div className="value">
          {game.bet.score_home !== undefined ? game.bet.score_home : '-'}
        </div>
        <div className="separator">
          {'x'}
        </div>
        <div className="value">
          {game.bet.score_away !== undefined ? game.bet.score_away : '-'}
        </div>
      </div>

      <PlayersDetailsBetsRoundTeam team={game.team_away} type="away" />
    </div>
    <PlayersDetailsBetsRoundInfo game={game} />

    {(game.status === 'in_progress' || game.status === 'finished') && game.bet.score !== undefined && (
      <div className="score" style={{ background: colorsData.layout[betsData.colors[game.bet.score || 0]] }}>
        {game.bet.score}
        {` Ponto${game.bet.score !== 1 ? 's' : ''}`}
      </div>
    )}

    {(game.status === 'canceled') && (
    <div className="score canceled" style={{ background: colorsData.layout.alizarin }}>
      {'Cancelado'}
    </div>
    )}

  </div>
);

export default PlayersDetailsBetsRoundScore;
