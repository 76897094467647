import React from 'react';
import { Link } from 'react-router-dom';

import translator from '../../../../data/translator';

import GamesHighlightTeam from './highlightTeam';

const GamesHighlightCampaign = ({ game }) => {
  let resultText = 'Derrota';
  let maxScore = game.draw;
  if (game.score >= game.victory) {
    resultText = 'Vitória';
    maxScore = game.victory;
  } else if (game.score >= game.draw) {
    resultText = 'Empate';
    maxScore = game.victory;
  }

  return (
    <div className="game-card">
      <div className="mode-header">
        {`${game.campaignType.name} - ${resultText}`}
      </div>
      <div className="game">
        <GamesHighlightTeam team={game.team_home} noLink />
        <div className="score">
          <div className="top">
            {translator.s.gameModes[game.mode_type]}
          </div>
          <Link to={`/jornada/historico/${game.id}`}>
            <div className="middle">
              <div
                className={`value value__left${game.score >= game.victory ? ' winner' : ''}`}
              >
                {game.score}
              </div>
              <div className="divisor">
                {'/'}
              </div>
              <div className="value value__right info">
                {maxScore}
              </div>
            </div>
          </Link>
          <div className="bottom">
            {'Rodada '}
            {game.round}
          </div>
        </div>
        <GamesHighlightTeam team={game.team_away} noLink />
      </div>
    </div>
  );
};

export default GamesHighlightCampaign;
