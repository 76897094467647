import React from 'react';
import { connect } from 'react-redux';

import GamesUtil from '../../../../app/utils/games';

import { teamsStatisticsGetGames } from '../../../../app/store/actions/teamsStatistics';

import Team from '../../elements/team';
import Ranking from '../../../elements/statistics/ranking';
import RankingTablePositionChange from '../../../elements/ranking/tablePositionChange';
import RankingTableSequence from '../../../elements/ranking/tableSequence';

const TeamsStatisticsGames = (props) => {
  const {
    teams, totalPages, types, type, authTeam, match,
  } = props;

  const tableHeader = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'team', value: 'Ranking de Jogos', className: 'name', colspan: 3,
    },
    { key: 'pos_change', tdClassName: 'pos-change', hidden: true },
    {
      key: 'score', value: 'Pts', className: 'number', tdClassName: 'high',
    },
    { key: 'total', value: 'J', className: 'number' },
    { key: 'wins', value: 'V', className: 'number' },
    { key: 'draws', value: 'E', className: 'number' },
    { key: 'losses', value: 'D', className: 'number' },
    { key: 'goals_for', value: 'Gp', className: 'number' },
    { key: 'goals_against', value: 'Gc', className: 'number' },
    { key: 'goals_difference', value: 'S', className: 'number' },
    { key: 'average', value: '%', className: 'number' },
    { key: 'sequence', value: 'Sequência', tdClassName: 'sequence' },
  ];

  const tableLines = teams.map(row => ({
    key: row.team.id,
    position: row.position,
    team: <Team data={row.team} type="table" />,
    pos_change: <RankingTablePositionChange value={row.position_last - row.position} />,
    score: row.value,
    total: row.total,
    wins: row.games.wins,
    draws: row.games.draws,
    losses: row.games.losses,
    goals_for: row.games.goals_for,
    goals_against: row.games.goals_against,
    goals_difference: row.games.goals_difference,
    average: (row.games.average * 100).toFixed(2),
    sequence: <RankingTableSequence games={row.games.sequence.slice()} />,
  }));

  const authInformation = {};
  if (authTeam) {
    authInformation.type = 'team';
    authInformation.team = authTeam;
    authInformation.rankings = authTeam.rankings;
    authInformation.tag = `games_${type}`;
  }

  return (
    <div className="content-container margin-bottom">
      <div className="title-center-menu">
        {'Jogos'}
      </div>
      <Ranking
        match={match}
        totalPages={totalPages}
        tableHeader={tableHeader}
        tableLines={tableLines}
        authInformation={authInformation}
        getRanking={props.getGames}
        buttonTypes={types}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const typeName = ownProps.match.params.type;
  const gameMode = ownProps.modeData.mode;
  const type = GamesUtil.convertNameType(typeName);
  return {
    getGames: (interval, page) => dispatch(teamsStatisticsGetGames(gameMode, type, interval, page)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const page = ownProps.match.params.page || 1;
  const typeName = ownProps.match.params.type;
  const { mode } = ownProps.modeData;
  const type = GamesUtil.convertNameType(typeName);

  const ranking = state.teamsStatistics.games[mode];
  return {
    teams: (ranking && ranking.data[page]) || [],
    totalPages: (ranking && ranking.total) || 0,
    types: state.settings.games && state.settings.games.types,
    type,
    authTeam: state.user.teams && state.user.teams[mode],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsStatisticsGames);
