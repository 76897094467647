import React from 'react';
import { connect } from 'react-redux';

import GameModeUtils from '../../../../../app/utils/gameMode';

import TeamsGamesBetsCardMarket from './card/cardMarket';

const TeamsGamesBetsMarket = (props) => {
  const { game, round, gamesPositions = {} } = props;
  const gameMode = game.mode_type;

  const positions = Object.keys(GameModeUtils.data[gameMode].positions);

  return (
    <div key={game.id} className="game-bets-market">
      {positions.map(position => (
        <TeamsGamesBetsCardMarket
          key={position}
          title={GameModeUtils.data[gameMode].positions[position]}
          round={round}
          games={gamesPositions[position]}
          homeBets={game.team_home.bets.players.find(player => player.position === position)}
          awayBets={game.team_away.bets.players.find(player => player.position === position)}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { game } = ownProps;
  const gameMode = game.mode_type;

  return {
    gamesPositions: state.settings.game_modes[gameMode].positions,
  };
};

export default connect(mapStateToProps)(TeamsGamesBetsMarket);
