import GamesRequests from '../../server/games';
import { setRound } from './rounds';
import { addLoading, removeLoading } from './loading';

import GamesData from '../../../data/games';

export const setList = (gameMode, type, roundNumber, page, games, total, isCurrent) => ({
  type: 'GAMES_LIST_SET',
  gameMode,
  gameType: type,
  roundNumber,
  page,
  games,
  total,
  isCurrent,
});

export const setDetails = (game, roundUpdate) => ({
  type: 'GAMES_DETAILS_SET',
  game,
  roundUpdate,
});

export const resetDetails = gameId => ({
  type: 'GAMES_DETAILS_RESET',
  gameId,
});

export const gamesGetList = (gameMode, type, roundNumber, page) => async (dispatch, getState) => {
  const limit = GamesData.list.gamesPerPage;
  const skip = ((page - 1) * limit);
  const response = await GamesRequests.listGames(gameMode, type, roundNumber, skip, limit);
  const isCurrent = (getState().rounds.current && getState().rounds.current.number) === roundNumber;
  dispatch(setList(gameMode, type, roundNumber, page, response.data, response.total, isCurrent));
};

export const gamesStartList = (gameMode, type, roundNumber, page) => async (dispatch, getState) => {
  const games = getState().games.list[gameMode]
  && getState().games.list[gameMode][type]
  && getState().games.list[gameMode][type][roundNumber]
  && getState().games.list[gameMode][type][roundNumber].pages[page - 1];

  if (!games) {
    dispatch(addLoading());

    try {
      await dispatch(gamesGetList(gameMode, type, roundNumber, page));
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const gamesGetGame = gameId => async (dispatch, getState) => {
  const currentRound = getState().rounds.current && getState().rounds.current.number;
  const data = await GamesRequests.getGame(gameId);
  dispatch(setRound(data.round.number, data.round));
  dispatch(setDetails(data.game, currentRound));
};

export const gamesStartGame = gameId => async (dispatch) => {
  dispatch(addLoading());

  try {
    await dispatch(gamesGetGame(gameId));
  } finally {
    dispatch(removeLoading());
  }
};

export const gamesResetGame = gameId => async (dispatch) => {
  dispatch(resetDetails(gameId));
};
