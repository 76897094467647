const initialState = {
  user: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'COLLECTIBLES_CHESTS_SET':
      state.chests = {};
      action.chests.forEach((chest) => {
        state.chests[chest.collection_name] = chest;
      });
      return state;
    case 'COLLECTIBLES_PACKS_SET':
      state.packs = action.packs;
      return state;
    case 'COLLECTIBLES_CHEST_ITEMS_SET': {
      const chestName = Object.keys(state.chests).find(cName => (
        state.chests[cName] && state.chests[cName].id === action.chestId));
      if (chestName) state.chests[chestName].items = action.items;
      return state;
    }
    case 'COLLECTIBLES_BASIC_ITEMS_SET': {
      return {
        ...state,
        basic: action.items,
      };
    }
    case 'COLLECTIBLES_USER_ITEMS_SET': {
      state.user[action.collectionType] = action.items;
      return state;
    }
    default:
      return state;
  }
};
