import React from 'react';

import MessagesRoomMessage from '../messages/room/messagesRoomMessage';

const WallFeedMessages = ({ messages, userId, removeMessage }) => (
  <React.Fragment>
    {messages.map(message => (
      <MessagesRoomMessage
        key={message.id}
        id={message.id}
        message={message}
        userId={userId}
        from={message.from}
        removeMessage={removeMessage}
      />
    ))}
  </React.Fragment>
);

export default WallFeedMessages;
