import React from 'react';

import TeamUtil from '../../../../../app/utils/teams';
import PlayersUtils from '../../../../../app/utils/players';

import Table from '../../../../elements/table';
import PlayerTableValuation from '../../../../players/table/valuation';
import Player from '../../../../players/elements/player';

export const TeamsDetailsOverviewPlayersTable = (props) => {
  const { gameMode, players = [], positions = [] } = props;

  const header = [
    { key: 'position', tdClassName: 'position', hidden: true },
    {
      key: 'player', value: 'Escalação', className: 'name', colspan: 2,
    },
    {
      key: 'score', value: 'Pontos', className: 'number', tdClassName: 'high',
    },
    { key: 'bet', value: 'Aposta', className: 'number' },
    { key: 'valuation', value: 'Valor', className: 'number' },
  ];

  const data = positions.map((position) => {
    const playerData = {
      key: position,
      position: TeamUtil.getPositionAbbr(gameMode, position),
      player: <Player data={PlayersUtils.completePlayer()} type="table" />,
      score: '-',
      valuation: '-',
      bet: '-',
    };

    const player = players.find(p => p.position === position);

    if (player) {
      playerData.player = <Player data={player.user} type="table" />;
      playerData.score = player.score !== undefined ? player.score : '-';

      playerData.valuation = <PlayerTableValuation valuation={player.user.valuation} />;

      if (player.bet !== undefined) {
        if (player.bet.score !== undefined) playerData.bet = player.bet.score;
        else playerData.bet = player.bet;
      }
    }

    return playerData;
  });

  return (<Table header={header} lines={data} />);
};

export default TeamsDetailsOverviewPlayersTable;
