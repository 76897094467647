import React from 'react';

import Paginate from '../../elements/paginate';
import Tabs from '../../elements/tabs/tabs';

const RecordsRoundsHeader = props => (
  <div className="content-container">
    <div className="title-center-menu">
      {'Rodada '}
      {props.currentRound}
    </div>
    <Paginate
      pageCount={props.maxRound - 1}
      forcePage={props.currentRound - 1}
      onPageChange={props.handlePageClick}
    />
    <Tabs
      items={props.menus.map(menu => ({
        name: menu.title,
        onClick: () => props.changeView(menu.view),
        active: props.view === menu.view,
      }))}
    />
  </div>
);

export default RecordsRoundsHeader;
