import React from 'react';
import { connect } from 'react-redux';

import { campaignsAccept, campaignsReject } from '../../../app/store/actions/campaigns';

import CampaignInvitationsList from './campaignInvitationsList';

import { history } from '../../../routers/appRouter';

export class CampaignInvitations extends React.Component {
  componentDidMount() {
    this.redirect();
  }

  componentWillReceiveProps() {
    this.redirect();
  }

  reject = async (campaignId) => {
    await this.props.rejectCampaign(campaignId);
    this.redirect();
  }

  accept = async (campaignId) => {
    await this.props.acceptCampaign(campaignId);
    this.redirect();
  }

  redirect = () => {
    const { invitations, campaign } = this.props;
    if (campaign) history.push(`/jornada/${campaign.real_team.name}`);
    else if (invitations && !invitations.length) history.push('/jornada/criacao');
  }

  render() {
    const { invitations, maxUsers } = this.props;

    return (
      <div className="content-container">
        <div className="title-center-menu">
          {'Convites de Jornadas'}
        </div>
        <CampaignInvitationsList
          invitations={invitations || []}
          maxUsers={maxUsers}
          reject={this.reject}
          accept={this.accept}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  acceptCampaign: campaignId => dispatch(campaignsAccept(campaignId)),
  rejectCampaign: campaignId => dispatch(campaignsReject(campaignId)),
});

const mapStateToProps = state => ({
  invitations: state.user.game_modes_details && state.user.game_modes_details.campaign
      && state.user.game_modes_details.campaign.invitations,
  campaign: state.user.game_modes_details && state.user.game_modes_details.campaign
      && state.user.game_modes_details.campaign.last,
  maxUsers: state.settings.campaigns && state.settings.campaigns.max_users,
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInvitations);
