import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';

import TinyPieChart from '../../../../../elements/charts/tinyPieChart';

const PlayersDetailsOverviewStatisticsBetsChartPie = (props) => {
  const { types, position, colors } = props;

  const dataTypes = [
    { name: 'Acertos', value: types.simple },
    { name: 'Cravadas', value: types.accurate },
    { name: 'Bônus', value: types.bonus },
  ];

  return (
    <div className="bet-chart">
      {position ? (
        <React.Fragment>
          <div className="chart">
            <TinyPieChart data={dataTypes} colors={colors} />
          </div>
          <div className="position">
            <FontAwesomeIcon icon={faSlackHash} />
            {' '}
            {position}
            {'º'}
          </div>
        </React.Fragment>
      ) : (
        <div className="empty">
          <FontAwesomeIcon icon={faMinusCircle} />
        </div>
      )}
    </div>
  );
};

export default PlayersDetailsOverviewStatisticsBetsChartPie;
