import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Header from '../components/header/header';

export const PrivateRoute = ({
  isAuthenticated,
  isStarting,
  component: Component,
  ...rest
}) => {
  if (!isStarting) {
    return (
      <React.Fragment>
        <Header />
        <Route
          {...rest}
          component={props => (
            isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to="/" />
            )
          )}
        />
      </React.Fragment>
    );
  }
  return (null);
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.id,
  isStarting: state.activities.starting,
});

export default connect(mapStateToProps)(PrivateRoute);
