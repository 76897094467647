import TeamsConfrontationRequests from '../../server/teamsConfrontation';
import { addLoading, removeLoading } from './loading';

export const teamsConfrontationGetTeamList = (gameMode, teamId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const data = await TeamsConfrontationRequests.getConfrontationTeamsList(gameMode, teamId);
    return data;
  } finally {
    dispatch(removeLoading());
  }
};

export const teamsConfrontationGet = (gameMode, firstTeamId, secondTeamId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const data = await TeamsConfrontationRequests.getConfrontationTeams(gameMode, firstTeamId, secondTeamId);
    return data;
  } finally {
    dispatch(removeLoading());
  }
};
