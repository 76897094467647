import MessagesRequests from '../../server/messages';
import { addLoading, removeLoading } from './loading';

import WallsUtils from '../../utils/walls';

export const setMessages = (mode, messages) => ({
  type: 'WALLS_MESSAGES_SET',
  mode,
  messages,
});

export const addFirstMessages = (mode, messages) => ({
  type: 'WALLS_MESSAGES_FIRST_ADD',
  mode,
  messages,
});

export const addLastMessages = (mode, messages) => ({
  type: 'WALLS_MESSAGES_LAST_ADD',
  mode,
  messages,
});

export const removeMessages = (mode, messageId) => ({
  type: 'WALLS_MESSAGES_REMOVE',
  messageId,
  mode,
});

export const wallsGetMessages = mode => async (dispatch) => {
  const messages = await MessagesRequests.getWallMessages(WallsUtils.getRealModeName(mode));
  dispatch(setMessages(mode, messages));
};

export const wallsStartMessages = mode => async (dispatch) => {
  dispatch(addLoading());

  try {
    await dispatch(wallsGetMessages(mode));
  } finally {
    dispatch(removeLoading());
  }
};

export const wallsGetNewMessages = mode => async (dispatch, getState) => {
  const wall = Object.keys(getState().walls[mode]).map(id => getState().walls[mode][id]);
  WallsUtils.sortingMessages(wall);
  const firstMessage = wall[0];


  if (!firstMessage) return;

  const modeGame = WallsUtils.getRealModeName(mode);
  const messages = await MessagesRequests.getWallMessages(modeGame, firstMessage.created_at, true);
  dispatch(addFirstMessages(mode, messages));
  return {
    newMessages: messages.length,
  };
};

export const wallsGetOldMessages = mode => async (dispatch, getState) => {
  const limitMessages = getState().settings.messages.wall.limit;

  const wall = Object.keys(getState().walls[mode]).map(id => getState().walls[mode][id]);
  WallsUtils.sortingMessages(wall);
  const lastMessage = wall.reverse()[0];

  if (!lastMessage) return;

  dispatch(addLoading());

  try {
    const messages = await MessagesRequests.getWallMessages(WallsUtils.getRealModeName(mode), lastMessage.created_at);
    dispatch(addLastMessages(mode, messages));
    return {
      finished: limitMessages > messages.length,
    };
  } finally {
    dispatch(removeLoading());
  }
};

export const wallsSendMessages = (mode, message) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await MessagesRequests.sendMessage(WallsUtils.getRealModeName(mode), message);
    await dispatch(wallsGetNewMessages(mode));
  } finally {
    dispatch(removeLoading());
  }

  await dispatch(wallsGetNewMessages(mode));
};


export const wallRemoveMessage = messageId => async (dispatch) => {
  dispatch(addLoading());

  try {
    const modeGame = 'general';
    await MessagesRequests.removeMessage(modeGame, messageId);
    dispatch(removeMessages(modeGame, messageId));
  } finally {
    dispatch(removeLoading());
  }
};
