import React from 'react';

import StepsButtons from '../../../elements/steps/buttons';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

const TutorialNationalIntroduction = props => (
  <TutorialWrapper image="game_modes/introduction.jpg">
    <StepsButtons {...props}>
      <h3 className="title-center">
        {'Seleção'}
      </h3>
      <p>
        {'Neste modo, o site disponibiliza seleções, que são divididas em Federações. Você será um jogador '
        + 'que irá integrar o elenco de uma dessas seleções. Cada seleção pode ter até 14 jogadores e '
        + 'são administrados por um jogador denominado “Presidente”. O presidente poderá nomear auxiliares '
        + 'em sua administração. As posições para disputa de jogos com outras seleções seguem as posições '
        + 'do Futebol de Campo.'}
      </p>
      <p>
        {'Para entrar em uma Seleção, cabe exclusivamente ao jogador essa escolha. Assim, aceite convites '
        + 'que você sinta que o ambiente entre os jogadores seja de respeito e camaradagem. Lembre-se que, '
        + 'em condições normais, a transferência somente é permitida com a abertura do mercado. Será '
        + 'oferecida também a opção de "Aceitar qualquer Seleção", para o caso você não tenha preferências.'}
      </p>
      <p className="text">
        {'Durante o ano, as Seleções participarão de diversos campeonatos oficiais, além de campeonatos '
        + 'personalizados e premiums.'}
      </p>
    </StepsButtons>
  </TutorialWrapper>
);

export default TutorialNationalIntroduction;
