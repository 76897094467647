import React from 'react';
import { connect } from 'react-redux';

import PlayersDetailsOverviewStatistics from './statistics/statistics';
import PlayersDetailsOverviewModes from './modes/playersDetailsOverviewModes';
import PlayersDetailsOverviewBanner from './banner/playersDetailsOverviewBanner';

import GamesCalendar from '../../../elements/games/calendar/calendar';

export const PlayersDetailsOverview = ({
  player, playerBet, generalScoreAverage, generalDifficulty,
}) => (
  <div className="player-overview">
    <GamesCalendar
      games={player.games}
    />
    <PlayersDetailsOverviewModes
      teams={player.teams}
    />
    <PlayersDetailsOverviewBanner />
    <PlayersDetailsOverviewStatistics
      discard={player.discard}
      valuation={player.valuation}
      achievements={player.achievements}
      level={player.level}
      bets={player.bets}
      playerBet={playerBet}
      generalScoreAverage={generalScoreAverage}
      generalDifficulty={generalDifficulty}
      username={player.username}
    />
  </div>
);

const mapStateToProps = (state) => {
  const roundNumber = state.rounds.numberClosed;
  const player = state.players && state.players.details && state.players.details.overview;
  const playerId = player && player.id;

  return {
    player,
    playerBet: state.roundsBets.users[playerId] && state.roundsBets.users[playerId][roundNumber],
    generalScoreAverage: state.statistics.general_score_average || 0,
    generalDifficulty: state.statistics.general_difficulty || 0,
  };
};

export default connect(mapStateToProps)(PlayersDetailsOverview);
