import StringHelper from '../helpers/string';

import CollectiblesData from '../../data/collectibles';

function sortingBasic(items) {
  items.forEach((item) => { item.order = StringHelper.deromanize(item.name.split(' ')[1]); });
  items.sort((a, b) => a.order > b.order);
}

function groupUserItems(items) {
  const groups = {};
  items.forEach((item) => {
    const chestName = item.collection_name || 'Especiais';
    if (!groups[chestName]) groups[chestName] = [];
    groups[chestName].push(item);
  });

  sortingBasic(groups[CollectiblesData.basic.name]);
  return groups;
}

export default {
  sortingBasic,
  groupUserItems,
};
