import RestService from '../services/rest';
import PlayersUtils from '../utils/players';

async function getUsers() {
  const players = await RestService.getAuthenticated('teams/manager/market');
  return players.map(player => PlayersUtils.completePlayer(player));
}

async function updateFormation(players, formation) {
  return RestService.postAuthenticated('teams/manager/formation', {
    game_mode: 'manager',
    formation,
    players,
  });
}

export default {
  getUsers,
  updateFormation,
};
