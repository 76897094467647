import React from 'react';
import { connect } from 'react-redux';

import Autocomplete from '../../elements/autocomplete';
import { realTeamsGet } from '../../../app/store/actions/realTeams';

import TeamsUtils from '../../../app/utils/teams';

export class AccountFavoriteClubForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autocompleteValue: '',
      realTeams: [],
    };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) this.props.getRealTeams();
  }

  onAutocompleteValueChange = (value) => {
    const { autocompleteValue } = this.state;
    const { teams } = this.props;

    const stateUpdate = { autocompleteValue: value };

    if (value !== autocompleteValue) {
      const realTeams = TeamsUtils.searchTeams(teams, value).slice(0, 5);
      stateUpdate.realTeams = realTeams;
    }

    this.setState(stateUpdate);
  };

  handleChangeRealTeam = async (value, realTeamSelected) => {
    this.setState({
      autocompleteValue: realTeamSelected.name,
      realTeams: [],
    });

    this.props.updateRealTeam(realTeamSelected.id);
  };

  render() {
    const { autocompleteValue, realTeams } = this.state;

    return (
      <div className="input-autocomplete">
        <Autocomplete
          menuStyle={{ zIndex: 2 }}
          getItemValue={item => item.name}
          items={realTeams}
          renderItem={(item, isHighlighted) => (
            <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
              {item.name}
            </div>
          )
          }
          value={autocompleteValue}
          onChange={(e, value) => this.onAutocompleteValueChange(value)}
          onSelect={this.handleChangeRealTeam}
          placeholder="Selecione o Clube"
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getRealTeams: () => dispatch(realTeamsGet()),
});

const mapStateToProps = state => ({
  isAuthenticated: state.user.id,
  teams: state.realTeams.teams || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountFavoriteClubForm);
