import RestService from '../services/rest';
import GamesUtils from '../utils/games';
import RoundsUtils from '../utils/rounds';

async function listGames(gameMode, type, roundNumber, skip, limit) {
  const url = `games/${gameMode}/list?round=${roundNumber}&limit=${limit}&skip=${skip}&type=${type}`;
  const games = await RestService.getAuthenticated(url);
  games.data = games.data.map(game => GamesUtils.completeGame(game));
  return games;
}

async function getGame(gameId) {
  const data = await RestService.getAuthenticated(`games?game=${gameId}`);
  return {
    game: GamesUtils.completeGame(data && data.game),
    round: RoundsUtils.completeRound(data && data.round),
  };
}

export default {
  listGames,
  getGame,
};
