export default (state = {}, action) => {
  switch (action.type) {
    case 'USERS_USERNAME_LIST_SET':
      return {
        ...state,
        usernameList: action.users,
      };
    case 'USERS_VIP_LIST_SET':
      return {
        ...state,
        vipList: action.users,
      };
    default:
      return state;
  }
};
