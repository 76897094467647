import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CategoriesIconsItem = (props) => {
  const {
    name, url, onClick, description, icon, notification,
  } = props;

  const item = (
    <React.Fragment>
      <div className="item">
        {!!notification && (
          <mark>
            {notification}
          </mark>
        )}
        <FontAwesomeIcon icon={icon} />
        <div className="title">
          {name}
        </div>
        <div className="description">
          {description}
        </div>
      </div>
    </React.Fragment>
  );

  if (url) return <Link to={url}>{item}</Link>;

  if (onClick) {
    return (
      <div
        onClick={onClick}
        onKeyDown={onClick}
        role="presentation"
        style={{ cursor: 'pointer' }}
      >
        {item}
      </div>
    );
  }

  return item;
};

export default CategoriesIconsItem;
