import React from 'react';

export const CampaignInProgressDetailsGameDetails = ({
  title, team, result, users = [], score,
}) => {
  let resultClass = 'loss';
  let resultText = 'Derrota';
  if (result === 'victory') {
    resultClass = 'victory';
    resultText = 'Vitória';
  } else if (result === 'draw') {
    resultClass = 'draw';
    resultText = 'Empate';
  }


  return (
    <React.Fragment>
      <div className={`mask ${resultClass}`}>
        <div className="texture" />
      </div>
      <div className="container">
        <div className={`mask ${resultClass}`}>
          <div className="texture" />
        </div>
        <div className="info">
          <div className="title">{title}</div>
          <div className="top">
            <div className="team">
              <div className="logo">
                <img src={team.badge.url} alt={team.name} />
              </div>
              <div className="name">
                {team.name}
              </div>
            </div>
          </div>
          <div className="result">
            <div className="text">{resultText}</div>
            <div className="score">{score}</div>
          </div>
          <div className="bottom">
            {users.map(user => (
              <div key={user.username} className="user">
                <div className="name">{user.username}</div>
                <div className="score">{user.score}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignInProgressDetailsGameDetails;
