export default {
  mode: 'national',
  active: true,
  prime: false,
  basic: true,
  color: '#16a085',
  pages: [
    'proposals',
    'teams',
    'calendar',
    'games',
    'competitions',
    'market',
    'statistics',
    'guide',
  ],
  statistics_pages: [
    'score',
    'achievements',
    'games',
    'confrontation',
    'top5',
    'strikers',
  ],
  management_pages: [
    'friendly',
    'market',
    'escalation',
    'portal',
    'roles',
  ],
  list: 'federation',
  players: {},
  regions: {
    america: 'Americana',
    africa_asia: 'Afro-Asiática',
    western_europe: 'Euro-Ocidental',
    eastern_europe: 'Euro-Oriental',
  },
  leagues: {
    league: {
      name: 'Séries',
    },
    qualificatory: {
      name: 'Eliminatórias',
    },
    cup: {
      name: 'Copa das Federações',
    },
    world_cup: {
      name: 'Copa do Mundo',
    },
    continental_cup: {
      name: 'Copas Continentais',
    },
    grand_cup: {
      name: 'Liga das Federações',
    },
    final: {
      name: 'Super Copa',
    },
  },
  positions: {
    goalkeeper: 'Goleiro',
    left_defender: 'Zagueiro Esquerdo',
    right_defender: 'Zagueiro Direito',
    left_back: 'Lateral Esquerdo',
    right_back: 'Lateral Direito',
    holding_midfielder: 'Volante',
    left_midfielder: 'Meia Esquerdo',
    right_midfielder: 'Meia Direito',
    playmaker: 'Armador',
    left_striker: 'Atacante Esquerdo',
    right_striker: 'Atacante Direito',
  },
  positions_abbreviation: {
    goalkeeper: 'GOL',
    left_defender: 'ZAE',
    right_defender: 'ZAD',
    left_back: 'LAE',
    right_back: 'LAD',
    holding_midfielder: 'VOL',
    left_midfielder: 'MCE',
    right_midfielder: 'MCD',
    playmaker: 'ARM',
    left_striker: 'ATE',
    right_striker: 'ATD',
  },
  formation: '4-4-2',
};
