import { animateScroll, scroller } from 'react-scroll';

export default (tag, duration = 600, delay = 0) => {
  if (tag) {
    scroller.scrollTo(tag, {
      duration,
      delay,
      smooth: 'easeInOutQuart',
    }, 10);
  } else {
    animateScroll.scrollToTop({
      duration,
    });
  }
};
