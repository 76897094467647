import React from 'react';
import { connect } from 'react-redux';

import TeamsGamesBetsSingle from './single';
import TeamsGamesBetsMarket from './market';
import TeamsGamesBetsManager from './manager';

const TeamsGamesBets = ({ game = {}, round }) => (
  <div className="game-bets">
    <div className="content-container with-margin">
      {game.mode_type === 'single' && (
        <TeamsGamesBetsSingle game={game} round={round} />
      )}
      {game.mode_type === 'club' && (
        <TeamsGamesBetsMarket game={game} round={round} />
      )}
      {game.mode_type === 'national' && (
        <TeamsGamesBetsMarket game={game} round={round} />
      )}
      {game.mode_type === 'manager' && (
        <TeamsGamesBetsManager game={game} round={round} />
      )}
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const game = state.games.details && state.games.details.game;
  const round = (game && state.rounds.byNumber[game.round]) || {};

  return {
    game,
    round,
  };
};

export default connect(mapStateToProps)(TeamsGamesBets);
