import RestService from '../services/rest';

async function list() {
  return RestService.getAuthenticated('transactions/complete');
}

async function addCredits(userId, amount) {
  return RestService.postAuthenticated('transactions/create', {
    user: userId,
    currency: amount,
  });
}

export default {
  list,
  addCredits,
};
