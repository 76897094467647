export default {
  basic: {
    name: 'Básico',
    colors: ['Azul', 'Verde', 'Vermelho', 'Preto'],
  },
  rarity: {
    1: {
      name: 'Comum',
      color: 'silver',
    },
    2: {
      name: 'Incomum',
      color: 'greenSea',
    },
    3: {
      name: 'Raro',
      color: 'belizeHole',
    },
    4: {
      name: 'Lendário',
      color: 'wisteria',
    },
    5: {
      name: 'Exótico',
      color: 'sunFlower',
    },
  },
};
