export default {
  current: {
    updateTime: 20 * 1000,
  },
  progress: {
    max_minutes: 120,
  },
  alert_time: {
    minutes: 240,
  },
};
