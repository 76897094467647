import React from 'react';

import GuideWrapper from '../wrapper';

import GuideCampaignGeneral from './general';
import GuideCampaignDifficulties from './difficulties';
import GuideCampaignCompetitions from './competitions';

export const GuideCampaign = () => {
  const menus = [
    { key: 'general', title: 'Geral', component: GuideCampaignGeneral },
    { key: 'difficulties', title: 'Dificuldades', component: GuideCampaignDifficulties },
    { key: 'competitions', title: 'Jornadas', component: GuideCampaignCompetitions },
  ];

  return <GuideWrapper title="Regulamento" menus={menus} gameMode="campaign" />;
};

export default GuideCampaign;
