import React from 'react';

import Autocomplete from '../../elements/autocomplete';

import MessagesServer from '../../../app/server/messages';

import Player from '../../players/elements/player';

export class MessagesNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      autocompleteValue: '',
      selectedUser: null,
    };
  }

  onAutocompleteValueChange = async (value) => {
    const { autocompleteValue } = this.state;

    clearTimeout(this.state.searchTimeOut);

    const stateUpdate = {
      users: [],
      autocompleteValue: value,
      selectedUser: null,
    };

    if (value && value !== autocompleteValue) {
      stateUpdate.searchTimeOut = setTimeout(async () => {
        await this.getUsers(value);
      }, 400);
    }

    await this.props.selectUser();
    await this.setState(stateUpdate);
  };

  getUsers = async (text) => {
    const users = await MessagesServer.searchInboxSearchRooms(text);
    await this.setState({ users });
  }

  handleSelectUser = async (value, user) => {
    await this.props.selectUser(user);
    await this.setState({ selectedUser: user });
  }

  render() {
    const { users, autocompleteValue, selectedUser } = this.state;


    if (selectedUser) {
      return (
        <React.Fragment>
          <Player data={selectedUser} type="table" />
          <button type="button" className="button normal" onClick={() => this.onAutocompleteValueChange('')}>
            {'Alterar'}
          </button>
        </React.Fragment>
      );
    }

    return (
      <Autocomplete
        getItemValue={item => item.username}
        items={users}
        renderItem={(item, isHighlighted) => (
          <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            {item.username}
          </div>
        )}
        value={autocompleteValue}
        shouldItemRender={(player, value) => player.username.toLowerCase().indexOf(value.toLowerCase()) !== -1}
        onChange={(e, value) => this.onAutocompleteValueChange(value)}
        onSelect={this.handleSelectUser}
        placeholder="Digite o nome do usuário"
      />
    );
  }
}

export default MessagesNewUser;
