import React from 'react';

import translator from '../../../../data/translator';
import CompetitionsUtils from '../../../../app/utils/competitions';

import BadgeListItem from '../../../elements/badgeList/badgeList';

const TeamsCompetitionsListStarted = (props) => {
  const {
    competitions = [],
    gridInfo = {
      lg: 2, md: 3, sm: 4, xs: 6,
    },
  } = props;

  const items = competitions.map(competition => ({
    id: competition.id,
    color: CompetitionsUtils.getCompetitionColor(competition),
    name: competition.name,
    tag: {
      name: translator.s.competitions[competition.type],
      color: CompetitionsUtils.getTypeColor(competition.type),
      nameHover: CompetitionsUtils.getEditionNameText(competition.edition, competition.year),
      colorHover: CompetitionsUtils.getCompetitionColor(competition),
    },
    badge: competition.logo.url,
    locked: false,
    mark: competition.xp ? competition.xp : undefined,
    link: competition.id,
  }));

  return (
    <div>
      <h3 className="title-center-banner">
        {'Andamento'}
      </h3>
      {items.length === 0 && <div className="competitions-empty">Sem campeonatos no momento</div>}
      <BadgeListItem items={items} gridInfo={gridInfo} center />
    </div>
  );
};

export default TeamsCompetitionsListStarted;
