import React from 'react';

export const GuideNationalGames = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Nos confrontos entre Seleções, cada jogador enfrentará o jogador da outra seleção que esteja '
        + 'jogando na mesma posição, considerando-se os seguintes jogos, por posição:'}
        </p>
        <ul className="list">
          <li>
            <b>
              {'Goleiro: '}
            </b>
            {'(1º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Zagueiro Esquerdo: '}
            </b>
            {'(2º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Zagueiro Direito: '}
            </b>
            {'(2º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Lateral Esquerdo: '}
            </b>
            {'(3º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Lateral Direito: '}
            </b>
            {'(3º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Volante: '}
            </b>
            {'(4º e 5º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Meia Esquerdo: '}
            </b>
            {'(5º e 6º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Meia Direito: '}
            </b>
            {'(5º e 6º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Armador: '}
            </b>
            {'(6º e 7º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Atacante Esquerdo: '}
            </b>
            {'(8º, 9º e 10º - Jogo da rodada)'}
          </li>
          <li>
            <b>
              {'Atacante Direito: '}
            </b>
            {'(8º, 9º e 10º - Jogo da rodada)'}
          </li>
        </ul>
        <p>
          {'A diferença de pontuação entre os jogadores de cada confronto será o número de gols que o '
        + 'jogador com a maior pontuação fará no confronto.'}
        </p>
      </div>
    </div>
  </React.Fragment>
);
export default GuideNationalGames;
