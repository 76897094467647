import React from 'react';

import CardChampion from '../../../elements/card/champion';

const TeamChampion = (props) => {
  const { team } = props;

  return (
    <CardChampion
      name={team.name}
      badge={{
        url: team.badge.url,
        title: team.name,
      }}
    />
  );
};

export default TeamChampion;
