const initialState = {
  current: null,
  byNumber: {},
  statistics: {},
  updateAt: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ROUNDS_CURRENT_ROUND_SET':
      return {
        ...state,
        numberClosed: action.current.number - (action.current.closed ? 0 : 1),
        current: action.current,
        isProgress: !!action.current.closed,
      };
    case 'ROUNDS_CURRENT_DATE_UPDATE':
      return {
        ...state,
        updateAt: action.date,
      };
    case 'ROUNDS_ROUND_SET':
      state.byNumber[action.roundNumber] = action.round;
      return { ...state, byNumber: { ...state.byNumber } };
    case 'ROUNDS_STATISTICS_SET':
      state.statistics[action.roundNumber] = action.statistics;
      return state;
    case 'ROUNDS_COMPETITIONS_SET':
      state.competitions = action.competitions;
      return state;
    default:
      return state;
  }
};
