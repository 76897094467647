import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { gamesStartGame, gamesGetGame, gamesResetGame } from '../../../../app/store/actions/games';

import pageTitleService from '../../../../app/services/pageTitle';

import MenuSecondary from '../../../elements/menuSecondary';

import TeamsGamesDetailsHeader from './header/header';
import TeamsGamesBets from './bets/bets';
import TeamsGamesEscalation from './escalation/escalation';
import TeamsGamesConfrontation from './confrontation/confrontation';
import TeamsGamesCompetition from './competition/competition';

import GameModeUtils from '../../../../app/utils/gameMode';

export class TeamsGamesDetails extends React.Component {
  async componentDidMount() {
    const { game, gameId } = this.props;

    if (!game) await this.props.startGame(gameId);
    else await this.props.getGame(gameId);
  }

  async componentWillReceiveProps(nextProps) {
    const { isIdle, roundUpdated, gameId } = nextProps;

    if (this.props.roundUpdated && !isIdle && roundUpdated !== this.props.roundUpdated) {
      await this.props.getGame(gameId);
    }
  }

  render() {
    const {
      game, url, currentRoundNumber, isRoundProgress,
    } = this.props;

    if (!game) return (null);

    let titlePage;
    if (game && game.team_home && game.team_away) {
      titlePage = `${game.team_home.name}
      ${game.score_home !== undefined ? game.score_home : ''}
      x
      ${game.score_away !== undefined ? game.score_away : ''}
      ${game.team_away.name}`;
    }
    pageTitleService.setTitle(titlePage);

    const tag = `${game.team_home.abbr}x${game.team_away.abbr}`;

    const menus = [];

    if (currentRoundNumber > game.round || (currentRoundNumber === game.round && isRoundProgress)) {
      menus.push({ name: 'Apostas', uri: `${url}/detalhes`, component: TeamsGamesBets });

      if (GameModeUtils.data[game.mode_type].positions) {
        menus.push({ name: 'Escalações', uri: `${url}/escalacao`, component: TeamsGamesEscalation });
      }
    }

    menus.push({ name: 'Confronto', uri: `${url}/confronto`, component: TeamsGamesConfrontation });

    if (game.competition.competition.id) {
      menus.push({ name: 'Campeonato', uri: `${url}/campeonato`, component: TeamsGamesCompetition });
    }

    return (
      <div className="game-details">
        <TeamsGamesDetailsHeader game={game} />
        <MenuSecondary menus={menus} tag={tag} />
        <Switch>
          <Redirect exact from={url} to={menus[0].uri} />
          {menus.map(menu => (
            <Route
              key={menu.name}
              path={menu.uri}
              component={menu.component}
            />
          ))}
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startGame: gameId => dispatch(gamesStartGame(gameId)),
  getGame: gameId => dispatch(gamesGetGame(gameId)),
  resetGame: gameId => dispatch(gamesResetGame(gameId)),
});

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { url, params } = match;
  const { gameId } = params;

  const currentRoundNumber = state.rounds.current && state.rounds.current.number;
  const game = state.games.details && state.games.details.game;

  return {
    gameId,
    url,
    game: game && game.id === gameId ? game : null,
    isIdle: state.activities.idle,
    isRoundProgress: state.rounds.isProgress,
    currentRoundNumber,
    roundUpdated: state.rounds.updateAt,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsGamesDetails);
