import React from 'react';

export class MessagesNewForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
    };
  }

  onMessageChange = (e) => {
    const message = e.target.value;
    this.setState(() => ({ message }));
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { clean } = this.props;

    if (this.state.message) {
      await this.props.onSubmit(this.state.message);
      if (clean) await this.setState(() => ({ message: '' }));
    }
  };

  canSend = () => {
    const { message } = this.state;
    const { minMessage, maxMessage } = this.props;
    return message.length >= minMessage && message.length <= maxMessage;
  };

  render() {
    const { canSend, maxMessage } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <textarea
          placeholder="Digite uma mensagem"
          className={`text-input${this.state.message.length ? ' has-content' : ''}`}
          maxLength={maxMessage}
          value={this.state.message}
          onChange={this.onMessageChange}
        />
        <div>
          <button type="submit" className="button normal" disabled={!canSend || !this.canSend()}>
            {'Enviar'}
          </button>
        </div>
      </form>
    );
  }
}

export default MessagesNewForm;
