import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import CompetitionsUtils from '../../../../../../app/utils/competitions';

import Team from '../../../../elements/team';
import Table from '../../../../../elements/table';
import RankingTablePositionChange from '../../../../../elements/ranking/tablePositionChange';

import TeamsCompetitionsDetailsInformationGames from '../games/games';
import TeamsCompetitionsDetailsInformationPositions from '../teamsCompetitionsDetailsInformationPositions';

export class TeamsCompetitionsDetailsInformationClassificatory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      standings: this.getStandings(props.data),
    };
  }

  async componentWillReceiveProps(nextProps) {
    await this.setState({ standings: this.getStandings(nextProps.data) });
  }

  getStandings = data => data.map(row => row);

  render() {
    const { standings } = this.state;
    const {
      games, currentRoundNumber, stagePositions, userTeamIds = [], groupName,
    } = this.props;

    const classificatoryHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      {
        key: 'team', value: groupName, className: 'name', colspan: 3,
      },
      { key: 'pos_change', tdClassName: 'pos-change', hidden: true },
      {
        key: 'score', value: 'Pts', className: 'number', tdClassName: 'high',
      },
      { key: 'games', value: 'J', className: 'number' },
      { key: 'wins', value: 'V', className: 'number' },
      { key: 'draws', value: 'E', className: 'number' },
      { key: 'losses', value: 'D', className: 'number' },
      { key: 'goals_for', value: 'Gp', className: 'number' },
      { key: 'goals_against', value: 'Gc', className: 'number' },
      { key: 'goals_difference', value: 'S', className: 'number' },
      { key: 'average', value: '%', className: 'number' },
    ];

    const classificatoryData = standings.map((row, index) => ({
      key: index,
      position: row.position,
      team: <Team data={row.team} type="table" />,
      pos_change: <RankingTablePositionChange value={row.position_last - row.position} />,
      score: row.score,
      games: row.games,
      wins: row.wins,
      draws: row.draws,
      losses: row.losses,
      goals_for: row.goals_for,
      goals_against: row.goals_against,
      goals_difference: row.goals_difference,
      average: (row.average * 100).toFixed(1),
    }));

    const positionInfo = CompetitionsUtils.getStageByPosition(stagePositions);
    const lineClassNames = {};
    lineClassNames.position = standings.map((row, index) => ({
      className: positionInfo[index] && positionInfo[index].className,
    }));

    userTeamIds.forEach((userTeamId) => {
      const userTeamIndex = standings.findIndex(row => userTeamId === row.team.id);
      if (userTeamIndex !== -1) classificatoryData[userTeamIndex].className = 'highlight';
    });

    return (
      <Grid fluid style={{ padding: 0 }} className="classificatory">
        <Row>
          <Col md={7} lg={8}>
            <Table header={classificatoryHeader} lines={classificatoryData} lineClassNames={lineClassNames} />
            <TeamsCompetitionsDetailsInformationPositions positionInfo={positionInfo} />
          </Col>
          <Col md={5} lg={4}>
            <TeamsCompetitionsDetailsInformationGames
              data={games}
              currentRoundNumber={currentRoundNumber}
              userTeamIds={userTeamIds}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default TeamsCompetitionsDetailsInformationClassificatory;
