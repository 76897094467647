import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { competitionsRankedGames } from '../../../../../../app/store/actions/competitions';

import Team from '../../../../elements/team';
import Table from '../../../../../elements/table';
import Paginate from '../../../../../elements/paginate';
import RankingTablePositionChange from '../../../../../elements/ranking/tablePositionChange';

import CompetitionsData from '../../../../../../data/competitions';
import CompetitionsUtils from '../../../../../../app/utils/competitions';

import TeamsCompetitionsDetailsInformationGames from '../games/games';
import TeamsCompetitionsDetailsInformationPositions from '../teamsCompetitionsDetailsInformationPositions';

export class TeamsCompetitionsDetailsInformationRanked extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.calculateState(props.userTeamIds, props.data, props.games);
  }

  async componentWillReceiveProps(nextProps) {
    const data = this.calculateState(nextProps.userTeamIds, nextProps.data, nextProps.games);
    this.setState(data);
  }

  calculateState = (userTeamIds, data, allGames) => {
    let page = 1;
    const totalPage = CompetitionsData.ranked.limit;

    if (userTeamIds) {
      userTeamIds.forEach((userTeamId) => {
        const userTeamIndex = data.findIndex(row => userTeamId === row.team.id);
        if (userTeamIndex !== -1) page = Math.ceil((userTeamIndex + 1) / totalPage);
      });
    }

    const gameRound = Object.keys(allGames);
    const games = {};

    for (let i = 1; i < gameRound; i += 1) games[i] = {};
    games[gameRound] = allGames[gameRound];

    return {
      totalPage,
      pageTeams: page,
      games,
    };
  }

  selectRoundGame = async (roundNumber) => {
    const { games } = this.state;
    const { groupId } = this.props;
    const newGames = await this.props.getGames(groupId, roundNumber);
    games[roundNumber] = (newGames || {})[roundNumber];
    await this.setState({ games });
  };

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ pageTeams: pageIndex + 1 });
  };

  render() {
    const { pageTeams, totalPage, games } = this.state;
    const {
      multiplier, minTeams, currentRoundNumber,
      data, userTeamIds = [], info = {}, stagePositions,
    } = this.props;

    const totalChange = Math.floor(data.length * multiplier);

    const changePositions = [];

    if (data.length >= minTeams || !info.higher) {
      changePositions.push({
        initial_position: 0,
        total_position: totalChange,
        type: 'promotion',
      });
    }

    if (!info.lower) {
      changePositions.push({
        initial_position: data.length - totalChange,
        total_position: totalChange,
        type: 'relegation',
      });
    }


    const classificatoryHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      {
        key: 'team', value: 'Clube', className: 'name', colspan: 3,
      },
      { key: 'pos_change', tdClassName: 'pos-change', hidden: true },
      {
        key: 'score', value: 'Pts', className: 'number', tdClassName: 'high',
      },
      { key: 'games', value: 'J', className: 'number' },
      { key: 'wins', value: 'V', className: 'number' },
      { key: 'draws', value: 'E', className: 'number' },
      { key: 'losses', value: 'D', className: 'number' },
      { key: 'goals_for', value: 'Gp', className: 'number' },
      { key: 'goals_against', value: 'Gc', className: 'number' },
      { key: 'goals_difference', value: 'S', className: 'number' },
      { key: 'average', value: '%', className: 'number' },
    ];

    const totalPages = Math.ceil(data.length / totalPage);
    const rankedData = data.slice(((pageTeams - 1) * totalPage), pageTeams * totalPage);

    const classificatoryData = rankedData.map((row, index) => ({
      key: index,
      position: row.position,
      team: <Team data={row.team} type="table" />,
      pos_change: <RankingTablePositionChange value={row.position_last - row.position} />,
      score: row.score,
      games: row.games,
      wins: row.wins,
      draws: row.draws,
      losses: row.losses,
      goals_for: row.goals_for,
      goals_against: row.goals_against,
      goals_difference: row.goals_difference,
      average: (row.average * 100).toFixed(1),
    }));

    userTeamIds.forEach((userTeamId) => {
      const userTeamIndex = rankedData.findIndex(row => userTeamId === row.team.id);
      if (userTeamIndex !== -1) classificatoryData[userTeamIndex].className = 'highlight';
    });

    const positionInfo = CompetitionsUtils.getStageByPosition([...stagePositions, ...changePositions]);
    const lineClassNames = {};
    lineClassNames.position = rankedData.map(row => ({
      className: positionInfo[row.position - 1] && positionInfo[row.position - 1].className,
    }));

    return (
      <div className="contenst">
        {info.rounds_left > 0 && (
        <div className="information">
          {info.rounds_total - info.rounds_left}
          {'/'}
          {info.rounds_total}
          {' Rodadas'}
        </div>
        )}

        <Grid fluid style={{ padding: 0 }} className="classificatory">
          <Row>
            <Col md={7} lg={8}>
              <Table header={classificatoryHeader} lines={classificatoryData} lineClassNames={lineClassNames} />
              <TeamsCompetitionsDetailsInformationPositions positionInfo={positionInfo} />
              <Paginate
                pageCount={totalPages}
                forcePage={pageTeams - 1}
                onPageChange={this.handlePageClick}
              />
            </Col>
            <Col md={5} lg={4}>
              <TeamsCompetitionsDetailsInformationGames
                data={games}
                currentRoundNumber={currentRoundNumber}
                maxPage={CompetitionsData.ranked.games}
                userTeamIds={userTeamIds}
                selectRoundGame={this.selectRoundGame}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getGames: (groupId, roundNumber) => dispatch(competitionsRankedGames(groupId, roundNumber)),
});

const mapStateToProps = state => ({
  multiplier: state.settings.competitions_ranked.multiplier,
  minTeams: state.settings.competitions_ranked.min_teams,
});


export default connect(mapStateToProps, mapDispatchToProps)(TeamsCompetitionsDetailsInformationRanked);
