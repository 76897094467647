import React from 'react';

const TeamsCompetitionsPremiumsCardDetailsCompetition = (props) => {
  const { competition = {} } = props;

  let Text = null;

  switch (competition.type) {
    case 'classificatory':
      Text = (
        <p>
          {'Grupo único com '}
          {competition.options.two_turns ? 'dois turnos' : 'turno único'}
          {'.'}
        </p>
      );
      break;
    case 'play_off':
      Text = (
        <p>
          {'Mata-mata com jogos '}
          {competition.options.two_games ? 'de ida e volta' : 'únicos'}
          {'.'}
        </p>
      );
      break;
    case 'two_pahses':
      Text = (
        <React.Fragment>
          <p>
            {'Primeira fase: '}
            {competition.options.total_groups}
            {' Grupos com '}
            {competition.options.two_turns ? 'dois turnos' : 'turno único'}
            {'.'}
          </p>
          <p>
            {'Segunda fase: Mata-mata com os '}
            {competition.options.play_off_teams}
            {' melhores e jogos '}
            {competition.options.two_turns ? 'de ida e volta' : 'únicos'}
            {'.'}
          </p>
        </React.Fragment>
      ); break;
    default:
  }

  return (
    <div className="competition">
      {Text}
    </div>
  );
};

export default TeamsCompetitionsPremiumsCardDetailsCompetition;
