import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Paginate from '../elements/paginate';

import { noticesGetList } from '../../app/store/actions/notices';

import NewsItem from './item';

import NoticesData from '../../data/notices';

export class NewsPage extends React.Component {
  state = {
    page: 0,
    pages: [],
    total: 0,
  }

  async componentDidMount() {
    const response = await this.props.getNotices(1);
    await this.setState({
      page: 1,
      pages: [response.data],
      total: Math.ceil(response.total / NoticesData.perPage),
    });
  }

  handlePageClick = async (data) => {
    const { pages } = this.state;
    const { selected: pageIndex } = data;

    const pageData = pages[pageIndex];

    if (!pageData) {
      const response = await this.props.getNotices(pageIndex + 1);
      pages[pageIndex] = response.data;

      await this.setState({
        page: pageIndex + 1,
        pages,
      });
    } else {
      await this.setState({ page: pageIndex + 1 });
    }
  }

  render() {
    const { page, pages, total } = this.state;

    const notices = pages[page - 1] || [];

    return (
      <div className="content-container news-page">
        <div className="title-center">
          {'Notícias'}
        </div>
        <Grid fluid>
          <Row>
            {notices.map(item => (
              <Col md={6} lg={4} key={item.id}>
                <NewsItem item={item} />
              </Col>
            ))}
          </Row>
        </Grid>
        <Paginate
          pageCount={total}
          forcePage={page - 1}
          onPageChange={this.handlePageClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getNotices: page => dispatch(noticesGetList(page)),
});

export default connect(undefined, mapDispatchToProps)(NewsPage);
