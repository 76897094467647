import RestService from '../services/rest';
import PlayersUtils from '../utils/players';

async function getMyUser() {
  const user = await RestService.getAuthenticated('users');
  return PlayersUtils.completePlayer(user);
}

async function usernameList() {
  return RestService.getAuthenticated('users/list/username');
}

async function vipList() {
  return RestService.getAuthenticated('users/list/subscribers');
}

async function activeGameMode(gameMode) {
  return RestService.postAuthenticated('users/game_modes/active', {
    game_mode: gameMode,
  });
}

async function disableGameMode(gameMode) {
  return RestService.postAuthenticated('users/game_modes/disable', {
    game_mode: gameMode,
  });
}

async function forgotPassword(email) {
  return RestService.postRest('users/forgot_password', { email });
}

async function resetPassword(hash, email, password) {
  return RestService.postRest('users/reset_password', { hash, email, password });
}

export default {
  getMyUser,
  usernameList,
  vipList,
  activeGameMode,
  disableGameMode,
  forgotPassword,
  resetPassword,
};
