import RestService from '../services/rest';

async function subscribe(userId, subscriptionId, useCredit) {
  return RestService.postAuthenticated('subscriptions/plans/subscribe', {
    plan: subscriptionId,
    user: userId,
    use_credit: useCredit,
  });
}

export default {
  subscribe,
};
