import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faBook,
  faEnvelope,
  faUsers,
  faStar,
  faNewspaper,
  faCrown,
  faGift,
} from '@fortawesome/free-solid-svg-icons';

import { faBtc } from '@fortawesome/free-brands-svg-icons';

import PlayersUtils from '../../../app/utils/players';

import HeaderTopPrivateSearch from './search/search';
import HeaderTopMenu from './headerTopMenu';

export const HeaderTopPrivate = ({
  username, wallNews, messagesNews, collectiblesNews,
  currencyNews, isActive, isPending, userAlertBet, primeStage,
}) => (

  <div className="auth">
    <HeaderTopPrivateSearch className="show-for-desktop" />
    <NavLink
      className="show-for-desktop button button--link"
      activeClassName="selected"
      to="/registros"
      title="Jogadores"
    >
      <FontAwesomeIcon icon={faUsers} />
      <div className="item"><span>Jogadores</span></div>
    </NavLink>

    <NavLink
      className="show-for-desktop button button--link"
      activeClassName="selected"
      to="/regulamento"
      title="Regulamento"
    >
      <FontAwesomeIcon icon={faBook} />
      <div className="item"><span>Regulamento</span></div>
    </NavLink>

    <a
      className="show-for-desktop button button--link"
      activeClassName="selected"
      href="https://www.instagram.com/barbolao"
      title="Notícias"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faNewspaper} />
      <div className="item"><span>Notícias</span></div>
    </a>

    {isActive && (
      <React.Fragment>
        <NavLink
          className="show-for-desktop button button--link"
          activeClassName="selected"
          to="/mural"
          title="Mural"
        >
          <FontAwesomeIcon icon={faComments} className={wallNews !== 0 ? 'wall-news' : ''} />
          <div className="item"><span>Mural</span></div>
        </NavLink>
        <NavLink
          className="show-for-desktop button button--link"
          activeClassName={`selected${messagesNews > 0 ? '-alert' : ''}`}
          to="/mensagens"
          title="Mensagens"
        >
          <FontAwesomeIcon icon={faEnvelope} />
          {messagesNews > 0 && (
          <mark className="alert">
            {messagesNews}
          </mark>
          )}
          <div className="item"><span>Mensagens</span></div>
        </NavLink>
        <NavLink
          className="show-for-desktop button button--link"
          activeClassName={`selected${collectiblesNews > 0 ? '-info' : ''}`}
          to="/colecionaveis"
          title="Colecionáveis"
        >
          <FontAwesomeIcon icon={faGift} />
          {collectiblesNews > 0 && (
          <mark className="info">
            {collectiblesNews}
          </mark>
          )}
          <div className="item"><span>Colecionáveis</span></div>
        </NavLink>
        <NavLink
          className="show-for-desktop button button--link"
          activeClassName="selected"
          to="/prime"
          title="Membro Prime"
        >
          {primeStage === 'none' ? (
            <FontAwesomeIcon icon={faStar} className="prime-none" />
          ) : (
            <FontAwesomeIcon icon={faCrown} className={`prime-${primeStage}`} />
          )}
          <div className="item"><span>Prime</span></div>
        </NavLink>
        <NavLink
          className="show-for-desktop button button--link"
          activeClassName="selected"
          to="/compras"
          title="Compras"
        >
          <FontAwesomeIcon icon={faBtc} />
          {' '}
          {currencyNews.toFixed(2)}
        </NavLink>
      </React.Fragment>
    )}

    {isPending && (
    <div className="show-for-desktop pending-message">
      {'Aguardando aprovação!'}
    </div>
    )}

    <NavLink
      className="show-for-desktop button button--link"
      activeClassName="selected"
      to={PlayersUtils.getLink(username)}
      title={username}
    >
      {username}
    </NavLink>

    <NavLink
      className={`button bet-button${(userAlertBet) ? ' bet-button__alert' : ''}`}
      to={`${PlayersUtils.getLink(username)}/apostas`}
      title="Palpitar"
    >
      {'Palpitar'}
    </NavLink>

    <HeaderTopMenu notifications={{
      messages: isActive && messagesNews,
      collectibles: collectiblesNews,
    }}
    />
    <HeaderTopPrivateSearch className="show-for-mobile" mobile />
  </div>

);

const mapStateToProps = state => ({
  username: state.user && state.user.username,
  wallNews: state.activities.notifications.wall,
  messagesNews: state.activities.notifications.messages,
  collectiblesNews: state.activities.notifications.collectibles,
  currencyNews: state.activities.notifications.currency,
  isActive: state.user.status === 'active',
  isPending: state.user.status === 'pending',
  userAlertBet: state.rounds.numberClosed
    && !((state.user.bets && state.user.bets.current_made) || (state.rounds.isProgress)),
  primeStage: PlayersUtils.primeStage(state.user),
});

export default connect(mapStateToProps, null, null, { pure: false })(HeaderTopPrivate);
