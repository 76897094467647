import React from 'react';

import TeamsGamesBetsCardGoals from './goals';

const TeamsGamesBetsCardDetails = ({ lines }) => (
  <div className="bets-details">
    {lines.map(line => (
      <div className="game-result" key={line.key}>
        <div className="bet">
          <div className="score-indicator" style={{ background: line.homeColor }} />
          {line.homeBonus && <div className="score-indicator-bonus" style={{ background: line.homeColor }} />}
          {line.homeValue}
          {line.homeGoals > 0 && <TeamsGamesBetsCardGoals goals={line.homeGoals} className="home" />}
        </div>
        <div className="name home">{line.homeName}</div>
        <div className={`result${line.inProgress ? ' in-progress' : ''}`}>
          {line.result}
        </div>
        <div className="name">{line.awayName}</div>
        <div className="bet">
          {line.awayValue}
          <div className="score-indicator away" style={{ background: line.awayColor }} />
          {line.awayBonus && <div className="score-indicator-bonus away" style={{ background: line.awayColor }} />}
          {line.awayGoals > 0 && <TeamsGamesBetsCardGoals goals={line.awayGoals} className="away" />}
        </div>
      </div>
    ))}
  </div>
);

export default TeamsGamesBetsCardDetails;
