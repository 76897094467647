import React from 'react';

import TeamsUtil from '../../../../../app/utils/teams';

import TeamsGamesEscalationTable from './table';
import TeamsGamesEscalationField from './field';

const TeamsGamesEscalationTeam = (props) => {
  const { team, strikers, isHome } = props;

  if (!team) return (null);

  const { formation, players } = TeamsUtil.getTeamFormationPlayers(team);
  const positions = Object.keys(formation);

  return (
    <React.Fragment>
      <TeamsGamesEscalationTable
        name={team.name}
        players={players}
        gameMode={team.mode_type}
        strikers={strikers}
        positions={positions}
      />
      <div className={`field ${isHome ? 'field-left' : 'field-right'}`}>
        <TeamsGamesEscalationField
          players={players}
          formation={formation}
        />
      </div>
    </React.Fragment>
  );
};

export default TeamsGamesEscalationTeam;
