import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const MessagesHeader = ({ isActive, buttonNew = true, buttonList = true }) => (
  <div className="inbox-header">
    <div className="title-center">
      {'Mensagens'}
    </div>
    <div className="buttons">
      {isActive && buttonNew && (
      <Link className="button normal" to="/mensagens/nova">
        {'Criar nova Mensagem'}
      </Link>
      )}
      {buttonList && (
      <Link className="button normal light-blue" to="/mensagens">
        {'Lista de Mensagens'}
      </Link>
      )}
    </div>
  </div>
);

const mapStateToProps = state => ({
  isActive: state.user.status === 'active',
});

export default connect(mapStateToProps)(MessagesHeader);
