import React from 'react';
import { connect } from 'react-redux';

import GameModeUtils from '../../../../../app/utils/gameMode';

import TeamsGamesBetsCardManager from './card/cardManager';

const TeamsGamesBetsManager = (props) => {
  const { game, round, managerCoachDifficulty } = props;
  const gameMode = game.mode_type;

  const positions = Object.keys(GameModeUtils.data[gameMode].positions);

  return (
    <div key={game.id} className="game-bets-market">
      {positions.map(position => (
        <TeamsGamesBetsCardManager
          key={position}
          managerCoachDifficulty={managerCoachDifficulty}
          title={GameModeUtils.data[gameMode].positions[position]}
          position={position}
          games={round.games}
          teamHome={game.team_home}
          teamAway={game.team_away}
          homeBets={game.team_home.bets && game.team_home.bets.players.filter(player => player.position === position)}
          awayBets={game.team_away.bets && game.team_away.bets.players.filter(player => player.position === position)}
        />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  managerCoachDifficulty: state.settings.game_modes.manager.coach_difficulty,
});

export default connect(mapStateToProps)(TeamsGamesBetsManager);
