import React from 'react';

import StepsButtons from '../../../elements/steps/buttons';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

const TutorialCampaingIntroduction = props => (
  <TutorialWrapper image="game_modes/introduction.jpg">
    <StepsButtons {...props}>
      <h3 className="title-center">
        {'Jornada'}
      </h3>
      <p>
        {'Neste modo, você será desafiado a conquistar diversas ligas existentes no mundo inteiro, '
        + 'desde campeonatos regionais até o Mundial de clubes. Para isso você poderá contar com ajuda de '
        + 'até outros três jogadores ou simplesmente jogar sozinho.'}
      </p>
      <p>
        {'Primeiramente você terá que escolher a liga que você deseja conquistar. Em seguida o clube pelo '
        + 'qual você quer levar a glória. Cada liga tem sua peculiaridade, podendo ser pontos corridos, '
        + 'mata-mata ou a mescla dos dois, para tentar ao máximo se assemelhar aos torneios reais.'}
      </p>
      <p className="text">
        {'Cada rodada você irá enfrentar uma equipe, e terá dois objetivos de pontuação, um para empatar e '
        + 'outro para vencer a partida. Quanto mais jogadores na jornada, maior a chance de conquistar os '
        + 'objetivos. A jornada poderá ser encerrada a qualquer momento para iniciar uma nova.'}
      </p>
    </StepsButtons>
  </TutorialWrapper>
);

export default TutorialCampaingIntroduction;
