import React from 'react';

import Paginate from '../../../../../elements/paginate';
import TeamsData from '../../../../../../data/teams';

import TeamsDetailsManagementEscalationPlayersTable from './table';

export class TeamsDetailsManagementEscalationPlayers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sorting: 'lastBetD',
      search: '',
      page: 1,
    };
  }

  changeSorting = async (e) => {
    const sorting = e.target.value;
    await this.setState({ sorting, page: 1 });
  }

  changeSearch = async (e) => {
    const search = e.target.value;
    await this.setState({ search, page: 1 });
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  render() {
    const { sorting, search, page } = this.state;
    const { players, teamPlayers } = this.props;

    let selectPlayers = players;
    if (search) {
      selectPlayers = players.filter(player => (player.username.toLowerCase().includes(search.toLowerCase())));
    }

    switch (sorting) {
      case 'lastBetD':
        players.sort((a, b) => {
          if (!a.bets.score.last) return 1;
          if (!b.bets.score.last) return -1;
          return b.bets.score.last - a.bets.score.last;
        });
        break;
      case 'valuationD':
        players.sort((a, b) => {
          if (!a.valuation.value) return 1;
          if (!b.valuation.value) return -1;
          return b.valuation.value - a.valuation.value;
        });
        break;
      case 'discardD':
        players.sort((a, b) => {
          if (!a.discard.total) return 1;
          if (!b.discard.total) return -1;
          return b.discard.total - a.discard.total;
        });
        break;
      case 'lastBetC':
        players.sort((a, b) => {
          if (!a.bets.score.last) return 1;
          if (!b.bets.score.last) return -1;
          return a.bets.score.last - b.bets.score.last;
        });
        break;
      case 'valuationC':
        players.sort((a, b) => {
          if (!a.valuation.value) return 1;
          if (!b.valuation.value) return -1;
          return a.valuation.value - b.valuation.value;
        });
        break;
      case 'discardC':
        players.sort((a, b) => {
          if (!a.discard.total) return 1;
          if (!b.discard.total) return -1;
          return a.discard.total - b.discard.total;
        });
        break;
      default:
    }

    const totalPage = TeamsData.escalation.total;
    const totalPages = Math.ceil(players.length / totalPage);

    selectPlayers = selectPlayers.slice(((page - 1) * totalPage), page * totalPage);

    return (
      <div className="players-list">
        <div className="players-list-header">
          <div className="filter">
            <div className="filter-select">
              <select value={sorting} onChange={this.changeSorting}>
                <option value="lastBetD">
                  {'Aposta Desc.'}
                </option>
                <option value="valuationD">
                  {'Mercado Desc.'}
                </option>
                <option value="discardD">
                  {'Descarte Desc.'}
                </option>
                <option value="lastBetC">
                  {'Aposta Cres.'}
                </option>
                <option value="valuationC">
                  {'Mercado Cres.'}
                </option>
                <option value="discardC">
                  {'Descarte Cres.'}
                </option>
              </select>
            </div>
            <input className="filter-input" placeholder="Buscar por nome" onChange={this.changeSearch} />
          </div>
          <Paginate
            pageCount={totalPages}
            forcePage={page - 1}
            onPageChange={this.handlePageClick}
          />
        </div>
        <TeamsDetailsManagementEscalationPlayersTable
          players={selectPlayers}
          teamPlayers={teamPlayers}
          selectPlayer={this.props.selectPlayer}
          getPositionName={this.props.getPositionName}
        />
      </div>
    );
  }
}

export default TeamsDetailsManagementEscalationPlayers;
