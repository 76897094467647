import React from 'react';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment } from '@fortawesome/free-solid-svg-icons';

import StringHelper from '../../app/helpers/string';

const NewsItem = ({ item }) => (
  <a href={item.link} rel="noopener noreferrer" target="_blank">
    <div className="item">
      <div
        className="image"
        style={{
          backgroundImage: `url(${item.image})`,
        }}
      >
        <div className="info">
          <FontAwesomeIcon icon={faHeart} />
          {item.likes}
          <FontAwesomeIcon icon={faComment} />
          {item.comments}
        </div>
      </div>

      <div className="texts">
        <div className="title">
          {moment(item.date).format('LL')}
        </div>
        <div className="description">
          {StringHelper.removeHashTags(item.text)}
        </div>
      </div>
    </div>
  </a>
);

export default NewsItem;
