import React from 'react';

import GuidePenaltiesAdditional from '../penaltiesAdditional';

export const GuideSinglePenalties = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Serão 5 cobranças obrigatórias e caso o empate persista teremos cobranças '
          + 'alternadas até a décima cobrança. Caso o empate continue, teremos um '}
          <b>pênalti adicional</b>
          {'.'}
        </p>
        <p>
          {'Cada jogo apostado na rodada será encarregado de uma cobrança. Caso o clube '
          + 'tenha pontuado no jogo, será '}
          <b>gol</b>
          {'.'}
        </p>
        <p>
          {'A primeira cobrança será representado pelo décimo jogo, a segunda pelo nono, '
          + 'e assim até o primeiro.'}
        </p>
      </div>
    </div>
    <GuidePenaltiesAdditional />
  </React.Fragment>
);
export default GuideSinglePenalties;
