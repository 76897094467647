import React from 'react';

import TeamsDetailsHeaderInfo from './teamsDetailsHeaderInfo';

import LinesHeader from '../../../elements/headers/linesHeader';
import TeamsUtils from '../../../../app/utils/teams';

const TeamsDetailsHeader = (props) => {
  const { url, team, mode } = props;

  const color = TeamsUtils.getBackgroundColor(team);

  return (
    <LinesHeader color={color}>
      <TeamsDetailsHeaderInfo
        team={team}
        url={url}
        mode={mode}
      />
    </LinesHeader>
  );
};

export default TeamsDetailsHeader;
