function getInterval(value) {
  if (value === 'geral') return 'all';
  return `${value}_year`;
}

function getIntervalTop5(value) {
  if (value === 'geral') return 'all';
  return value;
}

function getAllTop(data) {
  if (Array.isArray(data)) {
    return [data];
  }

  let tops = [];

  if (data && typeof data === 'object') {
    Object.keys(data).forEach((type) => {
      tops = tops.concat(getAllTop(data[type]));
    });
  }

  return tops;
}

export default {
  getInterval,
  getIntervalTop5,
  getAllTop,
};
