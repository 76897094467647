import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import TeamsDetailsOverviewBadgesAchievements from './achievements';
import TeamsDetailsOverviewBadgesCompetitions from './competitions';
import TeamsDetailsOverviewBadgesLeagues from './leagues';

export const TeamsDetailsOverviewBadges = (props) => {
  const {
    achievements = {}, competitions = [], leagues = {}, rankedCompetitions,
  } = props;
  return (
    <section className="badges">
      <Grid className="content-container">
        <Row>
          <Col sm={12} lg={4}>
            <TeamsDetailsOverviewBadgesAchievements achievements={achievements} />
          </Col>
          <Col sm={12} lg={4}>
            <TeamsDetailsOverviewBadgesCompetitions competitions={competitions} />
          </Col>
          <Col sm={12} lg={4}>
            <TeamsDetailsOverviewBadgesLeagues leagues={leagues} rankedCompetitions={rankedCompetitions} />
          </Col>
        </Row>
      </Grid>
    </section>
  );
};

export default TeamsDetailsOverviewBadges;
