import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadCry } from '@fortawesome/free-solid-svg-icons';

export const CampaignInProgressTeamInformationLoss = () => (
  <div className="info loss">
    <div className="title">Derrota</div>
    <div className="icon">
      <FontAwesomeIcon icon={faSadCry} />
    </div>
    <div className="text">Não foi dessa vez!</div>
  </div>
);

export default CampaignInProgressTeamInformationLoss;
