import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

export const CampaignInProgressTeamInformationPausedWaiting = () => (
  <div className="info">
    <div className="bets-none">
      <FontAwesomeIcon icon={faHourglassHalf} />
      <div className="text">
        {'Aguardando início'}
        <br />
        {'da Jornada!'}
      </div>
    </div>
  </div>
);

export default CampaignInProgressTeamInformationPausedWaiting;
