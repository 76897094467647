import React from 'react';

import MultipleCarousel from '../../../../elements/multipleCarousel';

import TeamsDetailsOverviewBadgesEmpty from './empty';

const TeamsDetailsOverviewBadgesCarousel = (props) => {
  const { title, items = [], noneText } = props;

  return (
    <div className="archievements">
      <div className="title-line">
        {title}
      </div>
      <div className="carousel">
        {items.length === 0 ? (
          <TeamsDetailsOverviewBadgesEmpty text={noneText} />
        ) : (
          <MultipleCarousel
            items={items}
            xs={1}
            sm={1}
            md={1}
            lg={1}
            indicator
          />
        )}
      </div>
    </div>
  );
};

export default TeamsDetailsOverviewBadgesCarousel;
