import React from 'react';

import GuidePenaltiesAdditional from '../penaltiesAdditional';

export const GuideNationalPenalties = () => (
  <React.Fragment>
    <div className="section">
      <div className="section-content">
        <p>
          {'Serão 5 cobranças obrigatórias e caso o empate persista teremos cobranças alternadas até '
          + 'completar todos os jogadores de linha. Caso o empate continue, teremos um '}
          <b>pênalti adicional</b>
          {'.'}
        </p>
        <p>
          {'Caso o jogador tenha pontuado na rodada mais que o goleiro da outra seleção, será '}
          <b>gol</b>
          {' (A pontuação será composta pelos 10 jogos).'}
        </p>
        <p>
          {'A ordem das cobranças será: Atacante Direito, Atacante Esquerdo, Armador, Meia Direito, '
          + 'Meia Esquerdo, Volante, Lateral Direito, Lateral Esquerdo, Zagueiro Direito e Zagueiro Esquerdo.'}
        </p>
      </div>
    </div>
    <GuidePenaltiesAdditional />
  </React.Fragment>
);
export default GuideNationalPenalties;
