import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { campaignsGetList } from '../../../app/store/actions/campaigns';

import CampaignsData from '../../../data/campaigns';
import translator from '../../../data/translator';

import Paginate from '../../elements/paginate';
import Tabs from '../../elements/tabs/tabs';

import CampaignListItem from './campaignListItem';

export class CampaignList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'active',
      page: 1,
      end: true,
      campaigns: [],
    };
  }

  async componentDidMount() {
    await this.getCampaigns();
  }

  getCampaigns = async () => {
    const { status, page } = this.state;
    const campaigns = await this.props.getList(status, page);
    await this.setState({ campaigns, end: campaigns.length < CampaignsData.list.gamesPerPage });
  }

  selectType = async (status) => {
    await this.setState({ status, page: 1 });
    await this.getCampaigns();
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
    await this.getCampaigns();
  };

  render() {
    const {
      page, end, status, campaigns,
    } = this.state;

    const views = [
      { value: 'active', name: 'Ativas' },
      { value: 'finished', name: 'Finalizadas' },
    ];

    const colorsResult = CampaignsData.history_result;
    const colorsDifficulties = CampaignsData.difficulties_colors;

    const items = campaigns.map((campaign) => {
      const colorDifficulty = colorsDifficulties[campaign.difficulty];
      const nameDifficulty = translator.s.campaign[campaign.difficulty];

      let tagName;
      let tagColor;

      switch (campaign.status) {
        case 'paused':
          tagName = 'Não iniciado';
          tagColor = colorsResult.paused;
          break;
        case 'active':
          tagName = `${campaign.phases > 1 ? `${campaign.phase + 1}ª Fase - `
            : ''}${campaign.phases[campaign.phase].game_round + 1}ª Jogo`;
          tagColor = colorsResult.active;
          break;
        case 'canceled':
        case 'finished':
          tagName = campaign.winner ? 'Vitória' : 'Derrota';
          tagColor = colorsResult[campaign.winner ? 'winner' : 'loss'];
          break;
        default:
      }

      return {
        id: campaign.id,
        color: colorDifficulty,
        name: campaign.type.name,
        tag: {
          name: tagName,
          color: tagColor,
          nameHover: nameDifficulty,
          colorHover: colorDifficulty,
        },
        badge: campaign.type.logo.url,
        mark: campaign.xp ? campaign.xp : undefined,
        link: `historico/${campaign.id}`,
        extras: {
          realTeam: campaign.real_team,
          users: campaign.users,
        },
      };
    });

    return (
      <div className="campaign-historic margin-bottom">
        <div className="title-center-menu">
          {'Jornadas'}
        </div>
        <Tabs
          items={views.map(v => ({
            name: v.name,
            onClick: () => this.selectType(v.value),
            active: status === v.value,
          }))}
        />
        <div className="content-container">
          {items.length === 0 && <div className="competitions-empty">Sem jornadas até o momento</div>}

          <Grid fluid className="competitions-closed">
            <Row>
              {items.map(item => (
                <Col sm={6} md={4} lg={3} key={item.id}>
                  <CampaignListItem
                    item={item}
                  />
                </Col>
              ))}
            </Row>
          </Grid>

          <Paginate
            pageCount={page + (!end ? 1 : 0)}
            forcePage={page - 1}
            onPageChange={this.handlePageClick}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.campaigns.details,
});

const mapDispatchToProps = dispatch => ({
  getList: (status, page) => dispatch(campaignsGetList(status, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
