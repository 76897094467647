import React from 'react';

import RcProgressLine from '../../elements/rcProgress/line';
import Table from '../../elements/table';
import Paginate from '../../elements/paginate';

import RecordsData from '../../../data/records';
import colorsData from '../../../data/colors';

export class RecordsSupportersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
    };
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex });
  }

  render() {
    const { page } = this.state;
    const { teams } = this.props;

    const totalPerPage = RecordsData.supporters.table.total_perPage;

    const total = teams.reduce((a, b) => a + b.fans, 0);
    const maxFans = teams.length ? teams[0].fans : 0;

    const totalPages = teams.length / totalPerPage;

    const supportersHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      {
        key: 'team', value: 'Times', tdClassName: 'name high', colspan: 2,
      },
      { key: 'fans', value: 'Torcida', tdClassName: 'high' },
      { key: 'percentage', value: 'Porcentagem' },
    ];

    const supportersData = teams.slice(totalPerPage * page, totalPerPage * (page + 1)).map((row, index) => ({
      position: index + (page * totalPerPage) + 1,
      key: row.id,
      team: row.name,
      fans: row.fans,
      percentage: (
        <div className="precentage-supporters">
          <div className="value">
            {`${Math.ceil((row.fans * 100) / total)}%`}
          </div>
          <div className="line">
            <RcProgressLine
              percent={Math.ceil((row.fans * 100) / maxFans)}
              strokeWidth="5"
              trailWidth="5"
              strokeColor={colorsData.teams.background[row.colors[0]]}
              trailColor={colorsData.silver}
            />
          </div>
        </div>
      ),
    }));

    return (
      <div className="table-supporters">
        <Table header={supportersHeader} lines={supportersData} />
        <Paginate
          pageCount={totalPages}
          forcePage={page}
          onPageChange={this.handlePageClick}
        />
      </div>
    );
  }
}

export default RecordsSupportersTable;
