import CalendarsRequests from '../../server/calendars';
import { addLoading, removeLoading } from './loading';

export const setEvents = (gameMode, events) => ({
  type: 'CALENDARS_EVENTS_SET',
  gameMode,
  events,
});

export const calendarsGetEvents = gameMode => async (dispatch) => {
  dispatch(addLoading());
  try {
    const events = await CalendarsRequests.getEvents(gameMode);
    dispatch(setEvents(gameMode, events));
  } finally {
    dispatch(removeLoading());
  }
};
