import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../../../routers/appRouter';

import translator from '../../../../data/translator';

import { marketPlayerSendResign, marketPlayerCancelResign, marketPlayerResign }
  from '../../../../app/store/actions/market';

export class TeamsDetailsHeaderResign extends React.Component {
  resignOption() {
    const { userId, team, userModeTeams } = this.props;
    if (team.president && team.president.id === userId) return false;
    if (team.manager && team.manager.id === userId) return false;
    if (team.players && !team.players.find(p => p.user.id === userId)) return false;

    const { market } = userModeTeams;
    if (!market) return false;
    if (market.is_open) return 'market_open';

    const { user } = market;
    if (user.resign_request) return 'resign_cancel';
    return 'resign_request';
  }

  async cancel() {
    await this.props.cancelResign();
  }

  async request() {
    await this.props.sendResign();
  }

  async resign() {
    const { gameMode } = this.props;

    await this.props.resign();

    const baseUri = `/${translator.uri.gameModes[gameMode]}`;
    history.push(`${baseUri}/propostas`);
  }

  render() {
    const { team, style = {} } = this.props;

    const resignOption = this.resignOption();

    return (
      <React.Fragment>
        {resignOption && resignOption === 'market_open' && (
        <button
          className="button red"
          type="button"
          onClick={() => this.resign(team.id)}
        >
          {'Sair do Clube'}
        </button>
        )}
        {resignOption && resignOption === 'resign_cancel' && (
        <button className="button red" type="button" onClick={() => this.cancel(team.id)}>
          {'Cancelar Pedido de Demissão'}
        </button>
        )}
        {resignOption && resignOption === 'resign_request' && (
        <button className="button" style={style} type="button" onClick={() => this.request(team.id)}>
          {'Pedir Demissão'}
        </button>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendResign: () => dispatch(marketPlayerSendResign(ownProps.gameMode)),
  cancelResign: () => dispatch(marketPlayerCancelResign(ownProps.gameMode)),
  resign: () => dispatch(marketPlayerResign(ownProps.gameMode)),
});

const mapStateToProps = state => ({
  userId: state.user.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsDetailsHeaderResign);
