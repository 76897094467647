import React from 'react';
import { connect } from 'react-redux';

import Paginate from '../../../elements/paginate';

import { gamesGetList, gamesStartList } from '../../../../app/store/actions/games';

import GamesData from '../../../../data/games';

import TeamsGamesListRoundGames from './teamsGamesListRoundGames';

export class TeamsGamesListRound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  async componentDidMount() {
    await this.loadGames();
  }

  async componentWillReceiveProps(nextProps) {
    const { roundUpdated } = nextProps;

    if (this.props.roundUpdated && roundUpdated !== this.props.roundUpdated) {
      await this.loadGames();
    }
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
    await this.loadGames();
  };

  loadGames = async () => {
    const { page } = this.state;
    const {
      isRoundProgress, isIdle, gamePages, type, roundNumber, mode, gameIsCurrent,
    } = this.props;

    if (!gamePages || !gamePages[page - 1]) {
      await this.props.getGamesList(mode, type, roundNumber, page);
    } else if (isRoundProgress && !isIdle && gameIsCurrent) {
      await this.props.updateGamesList(mode, type, roundNumber, page);
    }
  };

  render() {
    const { page } = this.state;
    const {
      gamesTotal, type, roundNumber, mode,
    } = this.props;

    const totalPage = Math.ceil((gamesTotal || 0) / GamesData.list.gamesPerPage);

    return (
      <React.Fragment>
        <TeamsGamesListRoundGames
          mode={mode}
          type={type}
          roundNumber={roundNumber}
          page={page}
        />
        {totalPage > 1 && (
        <Paginate
          pageCount={totalPage}
          forcePage={page - 1}
          onPageChange={this.handlePageClick}
        />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateGamesList: (gameMode, type, roundNumber, page) => dispatch(gamesGetList(gameMode, type, roundNumber, page)),
  getGamesList: (gameMode, type, roundNumber, page) => dispatch(gamesStartList(gameMode, type, roundNumber, page)),
});

const mapStateToProps = (state, ownProps) => {
  const { type, roundNumber, mode } = ownProps;

  const gameInfo = state.games.list[mode]
  && state.games.list[mode][type]
  && state.games.list[mode][type][roundNumber];

  return {
    isIdle: state.activities.idle,
    isRoundProgress: state.rounds.isProgress,
    gamePages: gameInfo && gameInfo.pages,
    gamesTotal: gameInfo && gameInfo.total,
    gameIsCurrent: gameInfo && gameInfo.isCurrent,
    roundUpdated: state.rounds.updateAt,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsGamesListRound);
