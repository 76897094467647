import React from 'react';

import CompetitionsUtils from '../../../../../app/utils/competitions';

import TeamsCompetitionsDetailsInformationClassificatory
  from './classificatory/teamsCompetitionsDetailsInformationClassificatory';
import TeamsCompetitionsDetailsInformationPlayOff from './playoff/playoff';
import TeamsCompetitionsDetailsInformationContest
  from './contest/teamsCompetitionsDetailsInformationContest';
import TeamsCompetitionsDetailsInformationRanked
  from './ranked/teamsCompetitionsDetailsInformationRanked';

const TeamsCompetitionsDetailsInformationGroupData = (props) => {
  const { group, currentRoundNumber, userTeamIds } = props;

  let groupData;

  const groupName = CompetitionsUtils.getGroupName(group.info);

  switch (group.info.type) {
    case 'classificatory': {
      groupData = (
        <TeamsCompetitionsDetailsInformationClassificatory
          stagePositions={group.info.stage_positions}
          data={group.classificatory}
          games={group.games}
          currentRoundNumber={currentRoundNumber}
          userTeamIds={userTeamIds}
          groupName={groupName}
        />
      );
      break;
    }
    case 'play_off': {
      groupData = (
        <TeamsCompetitionsDetailsInformationPlayOff
          playOff={group.play_off}
          info={group.info.play_off}
          currentRoundNumber={currentRoundNumber}
        />
      );
      break;
    }
    case 'contest': {
      groupData = (
        <TeamsCompetitionsDetailsInformationContest
          stagePositions={group.info.stage_positions}
          data={group.contest}
          info={group.info.contest}
          currentRoundNumber={currentRoundNumber}
          userTeamIds={userTeamIds}
        />
      );
      break;
    }
    case 'ranked': {
      groupData = (
        <TeamsCompetitionsDetailsInformationRanked
          stagePositions={group.info.stage_positions}
          groupId={group.info.id}
          data={group.ranked}
          info={group.info.ranked}
          games={group.games}
          currentRoundNumber={currentRoundNumber}
          userTeamIds={userTeamIds}
        />
      );
      break;
    }
    default: {
      groupData = null;
    }
  }

  return (
    <div className="content-container margin-bottom">
      {groupData}
    </div>
  );
};

export default TeamsCompetitionsDetailsInformationGroupData;
