const RealTeamModel = {
  name: 'Indefinido',
  abbr: 'IND',
  badge: {
    url: '/images/team_placeholder.png',
  },
};

function completeRealTeam(realTeam = {}) {
  realTeam = { ...RealTeamModel, ...realTeam };
  return realTeam;
}

function filterRealTeam(teams = [], states = [], countries = []) {
  if (states.length) teams = teams.filter(team => states.indexOf(team.state) !== -1);
  if (countries.length) teams = teams.filter(team => countries.indexOf(team.country) !== -1);

  return teams;
}

export default {
  completeRealTeam,
  filterRealTeam,
};
