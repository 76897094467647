import React from 'react';
import { connect } from 'react-redux';

import MultipleCarousel from '../../../../../elements/multipleCarousel';
import ReputationUtils from '../../../../../../app/utils/reputation';

import PlayersDetailsOverviewStatisticsReputationBadge from './badge';
import PlayersDetailsOverviewStatisticsReputationInformation from './information';

const PlayersDetailsOverviewStatisticsReputation = (props) => {
  const { reputation } = props;
  const { value, position } = reputation;

  const currentReputation = ReputationUtils.getReputation(value);

  return (
    <div className="reputation">
      <div className="title-line">
        {'Reputação'}
      </div>
      <div className="carousel">
        <MultipleCarousel
          items={[
            <PlayersDetailsOverviewStatisticsReputationBadge
              currentReputation={currentReputation}
              position={position}
            />,
            <PlayersDetailsOverviewStatisticsReputationInformation {...props} />,
          ]}
          xs={1}
          sm={2}
          md={2}
          lg={2}
          indicator
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isRoundProgress: state.rounds.isProgress,
});

export default connect(mapStateToProps)(PlayersDetailsOverviewStatisticsReputation);
