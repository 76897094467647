import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiaspora } from '@fortawesome/free-brands-svg-icons';
import { faReply } from '@fortawesome/free-solid-svg-icons';

import CardSimple from '../../elements/card/simple';

const MessagesListRoom = (props) => {
  const { room, userId } = props;

  const name = room.users.length === 0 ? 'Sistema' : room.users.map(u => u.username).join(', ');

  return (
    <Link to={`/mensagens/${props.room.id}`}>
      <CardSimple>

        <div className={`line${!room.read ? ' new' : ''}`}>
          <div className="users">
            {name}
          </div>
          <div className="message">
            {!room.read && (<FontAwesomeIcon icon={faDiaspora} />)}
            {props.room.last_message.from === userId && (<FontAwesomeIcon icon={faReply} />)}
            {props.room.last_message.message}
          </div>
          <div className="date">
            {moment(props.room.last_message.created_at).fromNow()}
          </div>
        </div>

      </CardSimple>
    </Link>
  );
};

export default MessagesListRoom;
