import React from 'react';
import { connect } from 'react-redux';

import { campaignsGetById } from '../../../app/store/actions/campaigns';

import CampaignInProgressHeader from '../inProgress/header/header';
import CampaignInProgressDetails from '../inProgress/details/details';

export class CampaignDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {},
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    const campaign = await this.props.getDetails(id);
    await this.setState({ campaign });
  }

  render() {
    const { campaign = {} } = this.state;

    if (!campaign.id) return (null);

    return (
      <React.Fragment>
        <CampaignInProgressHeader campaign={campaign} />
        <CampaignInProgressDetails campaign={campaign} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getDetails: campaignId => dispatch(campaignsGetById(campaignId)),
});

export default connect(undefined, mapDispatchToProps)(CampaignDetails);
