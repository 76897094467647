import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { scrollTo } from '../../../routers/appRouter';

import MessangesData from '../../../data/messages';

import { inboxGetRoom, inboxGetMessages, inboxSendMessages } from '../../../app/store/actions/inbox';
import { userGetAuth } from '../../../app/store/actions/user';

import MessagesHeader from '../messagesHeader';
import MessagesRoomHeader from './messagesRoomHeader';
import MessagesRoomMessage from './messagesRoomMessage';
import MessagesRoomForm from './messagesRoomForm';

export class MessagesRoom extends React.Component {
  constructor(props) {
    super(props);

    const messages = {};
    let lastDate;
    const { room, match } = props;
    const { roomId } = match.params;

    if (room && room.last_message) {
      messages[room.last_message.id] = room.last_message;
      lastDate = room.last_message.created_at;
    }

    this.state = {
      roomId,
      messages,
      lastDate,
      finished: true,
    };
  }

  componentWillMount() {
    this.updateInterval = setTimeout(() => this.newerMessages(), MessangesData.inbox.messages.updateTime);
  }

  async componentDidMount() {
    const { roomId } = this.state;
    const { room } = this.props;
    if (room) {
      await this.getMessages(roomId);
      if (!room.read) this.props.updateNotification();
    } else {
      await this.getRoom(roomId);
    }
    scrollTo('header-bottom');
  }

  componentWillUnmount() {
    clearTimeout(this.updateInterval);
  }

  async getRoom(roomId) {
    const data = await this.props.getRoom(roomId);

    if (data && data.messages) {
      await this.updateMessages(data.messages);
    }
  }

  async getMessages(roomId, date) {
    const messages = await this.props.getMessages(roomId, date);
    if (messages) await this.updateMessages(messages);
  }

  getMoreMessages = async () => {
    const { roomId, lastDate } = this.state;
    await this.getMessages(roomId, lastDate);
  }

  newerMessages = () => {
    const { roomId } = this.state;
    const { isIdle } = this.props;
    if (!isIdle) this.getMessages(roomId);
    this.updateInterval = setTimeout(() => this.newerMessages(), MessangesData.inbox.messages.updateTime);
  }

  sendMessage = async (messageText) => {
    const { roomId } = this.state;
    const messages = await this.props.sendMessage(roomId, messageText);
    this.updateMessages(messages, true);
  }

  updateMessages = async (newMessages, newer) => {
    if (newMessages.length > 0) {
      const { messages, lastDate } = this.state;
      newMessages.forEach((message) => { messages[message.id] = message; });

      const finished = newMessages.length < MessangesData.inbox.messages.perPage;

      const newLastDate = newer ? lastDate : newMessages[newMessages.length - 1].created_at;

      await this.setState({ messages, finished, lastDate: newLastDate });
    } else {
      await this.setState({ finished: true });
    }
  }

  render() {
    const {
      room, isActive, minMessage, maxMessage, userId,
    } = this.props;
    const { messages, finished } = this.state;

    const messagesList = Object.keys(messages).map(messageKey => messages[messageKey]);
    messagesList.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

    return (
      <div className="list-messages">
        <MessagesHeader buttonList />
        {room && room.users.length > 1 && (<MessagesRoomHeader room={room} />)}
        {room && room.users.length > 0 && (
        <MessagesRoomForm
          minMessage={minMessage}
          maxMessage={maxMessage}
          onSubmit={this.sendMessage}
          isActive={isActive}
        />
        )}
        {room && messagesList.map(message => (
          <MessagesRoomMessage
            key={message.id}
            message={message}
            userId={userId}
            from={room.users.find(u => u.id === message.from)}
          />
        ))}
        {!finished && (
        <button type="button" className="button normal light-blue" onClick={this.getMoreMessages}>
          {'Mais Mensagens'}
        </button>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getRoom: roomId => dispatch(inboxGetRoom(roomId)),
  getMessages: (roomId, date) => dispatch(inboxGetMessages(roomId, date)),
  sendMessage: (roomId, message) => dispatch(inboxSendMessages(roomId, message)),
  updateNotification: () => dispatch(userGetAuth()),
});

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { roomId } = match.params;

  return {
    room: state.inbox.rooms[roomId],
    isIdle: state.activities.idle,
    isActive: state.user.status === 'active',
    minMessage: state.settings.messages && state.settings.messages.message && state.settings.messages.message.min,
    maxMessage: state.settings.messages && state.settings.messages.message && state.settings.messages.message.max,
    userId: state.user.id,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesRoom);
