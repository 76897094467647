import React from 'react';

import GuideWrapper from '../wrapper';

import GuideClubGeneral from './general';
import GuideRoles from '../roles';
import GuideClubGames from './games';
import GuideCompetitions from '../competitions';
import GuideClubPenalties from './penalties';

export const GuideClub = () => {
  const menus = [
    { key: 'general', title: 'Geral', component: GuideClubGeneral },
    { key: 'roles', title: 'Cargos', component: GuideRoles },
    { key: 'games', title: 'Jogos', component: GuideClubGames },
    { key: 'competitions', title: 'Competições', component: GuideCompetitions },
    { key: 'penalties', title: 'Pênaltis', component: GuideClubPenalties },
  ];

  return <GuideWrapper title="Regulamento" menus={menus} gameMode="club" />;
};

export default GuideClub;
