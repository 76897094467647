import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TutorialWrapper from '../../../elements/tutorial/tutorial';

import translator from '../../../../data/translator';

const TeamsInitiationCreationComplete = (props) => {
  const { mode, team } = props;

  if (!team) return (null);

  const uri = `/${translator.uri.gameModes[mode]}/${team.name}`;
  return (
    <TutorialWrapper image={`game_modes/${mode}.jpg`}>
      <h3 className="title-center">
        {team.name}
      </h3>
      <div className="real-team">
        <img src={team.badge.url} alt={team.name} />
      </div>
      <div className="text-center">
        {'Parabéns! Seu clube foi criado. Boa sorte!'}
      </div>
      <Link to={uri} className="button normal light-blue">
        {'Página do Clube'}
      </Link>
    </TutorialWrapper>
  );
};

const mapStateToProps = state => ({
  team: state.teams.details.overview,
});

export default connect(mapStateToProps)(TeamsInitiationCreationComplete);
