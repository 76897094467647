import React from 'react';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faFutbol } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';

const PlayersDetailsBetsRoundInfo = ({ game }) => (
  <div className="info">
    <div className="fixture-match-info">
      <FontAwesomeIcon icon={faSlackHash} />
      {'Jogo '}
      {game.number + 1}
      {game.suggestion && (
        <React.Fragment>
          <FontAwesomeIcon icon={faStar} />
          {'Sugestão: '}
          {game.suggestion.score_home}
          {'x'}
          {game.suggestion.score_away}
        </React.Fragment>
      )}
      {game.score_home !== undefined && game.score_away !== undefined && (
        <React.Fragment>
          <FontAwesomeIcon icon={faFutbol} />
          {'Resultado Real: '}
          {game.score_home}
          {'x'}
          {game.score_away}
        </React.Fragment>
      )}
      <br className="show-for-mobile" />
      <FontAwesomeIcon icon={faCalendar} />
      {moment(game.date).format('LLL')}
    </div>
  </div>
);

export default PlayersDetailsBetsRoundInfo;
