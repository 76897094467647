export default (state = {}, action) => {
  switch (action.type) {
    case 'REAL_TEAMS_SET':
      return {
        ...state,
        teams: action.realTeams,
      };
    default:
      return state;
  }
};
