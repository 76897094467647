import React from 'react';

export default class ImageLoad extends React.Component {
  constructor(props) {
    super(props);
    this.state = { imageUrl: props.placeholder };
  }

  handleImageLoaded = async () => {
    const { src } = this.props;
    await this.setState(() => ({ imageUrl: src }));
  }

  handleImageErrored = async () => {
    const { placeholder } = this.props;
    await this.setState(() => ({ placeholder }));
  }

  render() {
    const { imageUrl } = this.state;
    const { alt, className } = this.props;

    return (
      <img
        src={imageUrl}
        alt={alt}
        className={className}
        onLoad={this.handleImageLoaded}
        onError={this.handleImageErrored}
      />
    );
  }
}
