import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { faSlackHash } from '@fortawesome/free-brands-svg-icons';

import BetsHitsChart from '../../../elements/statistics/betsHitsChart';
import Player from '../../../players/elements/player';

const RecordsRoundsContentGameBets = (props) => {
  const { game, statistics, betsTotal } = props;

  return (
    <div className="info">
      <div className="numbers">
        <FontAwesomeIcon icon={faSlackHash} />
        {'Jogo '}
        {game.number + 1}
        {game.difficulty !== undefined && (
          <React.Fragment>
            <FontAwesomeIcon icon={faFire} />
            {'Dificuldade '}
            {game.difficulty.toFixed(2)}
          </React.Fragment>
        )}
      </div>

      {game.status === 'finished' ? (
        <React.Fragment>
          <BetsHitsChart
            simple={statistics.simple}
            accurate={statistics.accurate}
            miss={betsTotal - (statistics.simple + statistics.accurate)}
          />

          {statistics.accurate_users.length > 0 ? (
            <div className="accurates">
              <div className="sub-title">
                {'Alguns usuários que cravaram: '}
              </div>
              {statistics.accurate_users.map(user => (
                <Player key={user.id} data={user} type="image" />
              ))}
            </div>
          ) : (
            <div className="accurates">
              <div className="sub-title space">
                {'Nenhum usuário cravou esse jogo'}
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="accurates canceled">
          <div className="sub-title space">
            {'Jogo Cancelado'}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordsRoundsContentGameBets;
