import React from 'react';
import moment from 'moment-timezone';

import Table from '../../elements/table';
import Player from '../../players/elements/player';

import Paginate from '../../elements/paginate';

import AdministrationData from '../../../data/administration';

export class AdministrationUsersAnalyzeIpAccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  handlePageClick = async (data) => {
    const { selected: pageIndex } = data;
    await this.setState({ page: pageIndex + 1 });
  };

  render() {
    const { page } = this.state;
    const { users = [], accesses = [] } = this.props;

    const tableHeader = [
      { key: 'position', tdClassName: 'position', hidden: true },
      { key: 'user', value: 'Acessos', colspan: 2 },
      { key: 'start', value: 'Log-in' },
      { key: 'end', value: 'Log-out' },
    ];

    const totalPerPage = AdministrationData.userAnalyze.accesses.perPage;
    const totalPages = Math.ceil(accesses.length / totalPerPage);

    const tableData = accesses.slice(((page - 1) * totalPerPage), page * totalPerPage).map((access, index) => {
      const user = users.find(u => u.id === access.user);

      return {
        key: index,
        position: index + 1,
        user: <Player key={user.id} data={user} type="table" />,
        start: moment(access.date).format('LLL'),
        end: moment(access.expiresDate).format('LLL'),
      };
    });

    return (
      <div className="margin-bottom">
        <Table header={tableHeader} lines={tableData} />
        <Paginate
          pageCount={totalPages}
          forcePage={page - 1}
          onPageChange={this.handlePageClick}
        />
      </div>
    );
  }
}

export default AdministrationUsersAnalyzeIpAccess;
