export default {
  subReputation: {
    total: 5,
  },
  accurate: 4,
  interval: 1 / 3,
  levels: [
    { name: 'Várzea', tag: 'noob', color: '#5C3F2C' },
    { name: 'Aspirante', tag: 'aspirant', color: '#5C3F2C' },
    { name: 'Juvenil', tag: 'juvenile', color: '#566b6b' },
    { name: 'Amador', tag: 'amateur', color: '#566b6b' },
    { name: 'Profissional', tag: 'professional', color: '#796030' },
    { name: 'Estrela', tag: 'star', color: '#796030' },
    { name: 'Lenda', tag: 'legend', color: '#43476b' },
    { name: 'Galáctico', tag: 'galactic', color: '#43476b' },
    { name: 'Vidente', tag: 'seer', color: '#2f2a52' },
    { name: 'Profeta', tag: 'prophet', color: '#2f2a52' },
    {
      name: 'Mitológico', tag: 'mythological', color: '#27182f', last: true,
    },
  ],
  colors: {
    miss: 'pomegranate',
    hits: 'turquoise',
    accurate: 'peterRiver',
  },
  limit: 10,
};
