import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const TeamsCompetitionsPremiumsCardButtons = (props) => {
  const {
    teams, price, discount, userTeam, userIsPrime,
  } = props;

  const canParticipate = userTeam && !teams.find(team => team.id === userTeam.id);
  const priceDiscount = userIsPrime && discount;

  return (
    <div className="buttons">
      <div className={`price${priceDiscount ? ' is-prime' : ''}${!discount ? ' only-price' : ''}`}>
        {'R$'}
        {price}
      </div>
      {discount > 0 && (
      <div className={`price-plus${priceDiscount ? ' is-prime' : ''}`}>
        <FontAwesomeIcon icon={faStar} />
        {' R$'}
        {price * (1 - discount)}
      </div>
      )}
      <button
        type="submit"
        className="button normal light-blue"
        disabled={!canParticipate}
        onClick={props.selectPremium}
      >
        {canParticipate ? 'Participar' : 'Já Inscrito'}
      </button>
    </div>
  );
};

export default TeamsCompetitionsPremiumsCardButtons;
